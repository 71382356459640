import { useContext } from 'react';
import { Box, Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../features/auth/authSlice';
import { PriceGridContext } from '../../../context/PriceGridContext';
import { useAppDispatch } from '../../../app/hooks';
import { handleModal } from '../../../features/modal/modalSlice';

const StackHeader = (props: any) => {
  const storeDispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const { setModalType } = useContext(PriceGridContext);
  const { setShowStack, handlePrint, handleExport } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        mr: {
          xs: 0,
          sm: 2,
        },
        marginLeft: 'auto',
      }}
    >
      <Button sx={{ mr: 1 }} variant="contained" color="primary" onClick={() => setShowStack(false)}>
        Back
      </Button>
      <Button sx={{ mr: 1 }} variant="contained" color="primary" onClick={() => handlePrint()}>
        Print Grid
      </Button>
      <Button sx={{ mr: 1 }} variant="contained" color="primary" onClick={() => handleExport()}>
        Export CSV
      </Button>
      {user.type !== 'Developer' ? (
        <Button
          sx={{ mr: 1 }}
          variant="contained"
          color="success"
          onClick={() => {
            storeDispatch(handleModal(true));
            setModalType('stack');
          }}
        >
          Save
        </Button>
      ) : null}
    </Box>
  );
};

export default StackHeader;
