import { useState, useContext } from 'react';
import { Box, Popper, TextField, Autocomplete } from '@mui/material';
import { useSelector } from 'react-redux';

import { IStatus } from '../../../types/project';
import { IRealtorInfo } from '../../../types/CreateDealForm';
import { unitStatusTitles } from '../../../utils/Constants';
import { numToCurrency, stringSplit } from '../../../utils/Functions';
import { selectProject } from '../../../features/project/projectSlice';
import { useAppDispatch } from '../../../app/hooks';
import { showErrorSnackbar } from '../../../features/snackbar/snackbarSlice';
import { accessAllowed } from '../../../features/project/projectHooks';
import { selectUser } from '../../../features/auth/authSlice';
import { PriceGridContext } from '../../../context/PriceGridContext';
import { handleModal } from '../../../features/modal/modalSlice';

const StackUnitCard = (props: ChildProps) => {
  const project = useSelector(selectProject);
  const user = useSelector(selectUser);
  const storeDispatch = useAppDispatch();
  const { setModalType, setUnit } = useContext(PriceGridContext);
  const { unit, handleChange, handleBlur, handleSelect, handleSearchInput, handleInputChange, oldUnit, realtors } = props;
  const [openAllocation, setOpenAllocation] = useState<boolean>(false);
  const [openPrice, setOpenPrice] = useState<boolean>(false);
  const [openStatus, setOpenStatus] = useState<boolean>(false);
  let unitStatusArray: IStatus[] = [...project.status, ...unitStatusTitles];
  let pendingClick: any;

  const suiteStatus = () => {
    let statusObject = unitStatusArray.find((status: IStatus) => unit.status === status.code);
    let statusStyle = {
      backgroundColor: statusObject?.color,
      textAlign: 'center' as const,
    };
    return statusStyle;
  };

  const PopperMy = function (props: any) {
    return <Popper {...props} style={{ width: 250 }} placement="bottom-start" />;
  };

  const handleDoubleClick = (e: any, type: string) => {
    e.preventDefault();
    e.stopPropagation();
    clearTimeout(pendingClick);
    if (!accessAllowed(user, project._id!, 'editUnits')) return storeDispatch(showErrorSnackbar('You currently do not have permissions'));
    if (type === 'allocation') {
      setOpenAllocation(!openAllocation);
    } else if (type === 'price') {
      setOpenPrice(!openPrice);
    } else if (type === 'status') {
      setOpenStatus(!openStatus);
    }
  };

  const handleClick = (e: any) => {
    clearTimeout(pendingClick);
    pendingClick = setTimeout(function () {
      setUnit(unit);
      setModalType('floorPlan');
      storeDispatch(handleModal(true));
    }, 300);
  };

  const blur = (type: string) => {
    setOpenAllocation(false);
    setOpenPrice(false);
    setOpenStatus(false);
    handleBlur(unit._id, type);
  };

  const handleFontSize = (allocation: any) => {
    if (allocation) {
      if (allocation.fullName.length > 18) {
        return '9px';
      } else return '12px';
    } else return '12px';
  };

  return (
    <Box
      className="suiteHeight"
      sx={{
        height: '100%',
      }}
    >
      <Box
        className="unitSuiteCard"
        sx={{
          color: '#00142a',
          boxShadow: '0px 0px 1px 1px grey',
          height: '100%',
          fontSize: '12px',
          backgroundColor: Object.keys(unit).length !== 0 ? '#fff' : 'transparent',
          border: Object.keys(unit).length !== 0 ? '1px solid #000' : 'none',
          display: Object.keys(unit).length !== 0 ? 'block' : 'none',
        }}
      >
        {!openStatus ? (
          <Box
            onDoubleClick={(e) => handleDoubleClick(e, 'status')}
            onClick={(e) => handleClick(e)}
            className="borderBox"
            sx={{
              height: '17px',
              backgroundColor: '#000',
              color: '#fff',
              textAlign: 'center',
              cursor: 'pointer',
            }}
          >
            <Box
              className="level"
              sx={{
                position: 'absolute',
                top: 0,
                left: 2,
                fontSize: '8px',
                color: '#fff',
              }}
            >
              {unit.level}
            </Box>
            {unit.custom ? (
              <Box
                className="custom"
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 2,
                  fontSize: '7px',
                  color: '#fff',
                }}
              >
                {stringSplit(unit.custom).map((string: string, index: number) => {
                  return <div key={index}>{string}</div>;
                })}
              </Box>
            ) : null}
            {unit.tier ? (
              <Box
                className="tier"
                sx={{
                  position: 'absolute',
                  top: 8,
                  right: 2,
                  fontSize: '7px',
                  color: '#fff',
                }}
              >
                {unit.tier}
              </Box>
            ) : null}
            <strong>
              {unit.suite} {unit.status ? `(${unit.status})` : null}
            </strong>
          </Box>
        ) : (
          <select
            className="textInput"
            style={{
              width: '100%',
              height: '16px',
            }}
            onDoubleClick={(e) => handleDoubleClick(e, 'status')}
            onBlur={() => blur('status')}
            onChange={(e: any) => handleSelect(e, unit._id)}
            value={unit.status}
            name="status"
            id="status"
          >
            {unitStatusArray.map((status: any) => (
              <option value={status.status}>{status.code}</option>
            ))}
          </select>
        )}
        <Box className="unit-price" style={{ ...suiteStatus(), height: '17px' }}>
          {unit.basePrice ? numToCurrency.format(unit.basePrice / unit.size) : null}
        </Box>
        <Box className="unit-price" style={{ ...suiteStatus(), height: '17px' }} onDoubleClick={(e) => handleDoubleClick(e, 'price')}>
          {!openPrice ? (
            <div style={{ color: oldUnit && oldUnit.basePrice !== unit.basePrice ? 'red' : '#000' }}>
              {oldUnit && oldUnit.basePrice !== unit.basePrice ? (
                <strong>
                  <span
                    style={{
                      textDecoration: 'line-through',
                      fontSize: '10px',
                      color: '#000',
                    }}
                  >
                    {numToCurrency.format(oldUnit.basePrice)}
                  </span>
                  {unit.basePrice ? numToCurrency.format(unit.basePrice) : null}
                </strong>
              ) : (
                <strong>{unit.basePrice ? numToCurrency.format(unit.basePrice) : null}</strong>
              )}
            </div>
          ) : (
            <input
              key={unit._id}
              className="textInput"
              name={'basePrice'}
              style={{
                width: '100%',
                height: '16px',
              }}
              type="number"
              value={unit.basePrice}
              onChange={(e) => handleChange(e, unit._id, 'basePrice')}
              onBlur={() => blur('basePrice')}
            />
          )}
        </Box>
        <div
          className="borderBottomBox"
          style={{ ...suiteStatus(), borderBottom: '2px solid #000', height: '17px' }}
          onDoubleClick={(e) => handleDoubleClick(e, 'allocation')}
        >
          {!openAllocation ? (
            <div
              className="unit-allocation-container"
              style={{
                color:
                  oldUnit &&
                  (oldUnit.tempAllocation !== unit.tempAllocation ||
                  (oldUnit.allocation && unit.allocation
                    ? oldUnit.allocation.fullName !== unit.allocation.fullName
                    : oldUnit.allocation !== unit.allocation)
                    ? 'red'
                    : '#000'),
                fontSize: handleFontSize(unit.allocation),
              }}
            >
              <strong className="unit-allocation">
                {unit.allocation ? unit.allocation.fullName : unit.tempAllocation ? `${unit.tempAllocation}` : null}
              </strong>
            </div>
          ) : (
            <Autocomplete
              className="input unit-allocation"
              sx={{
                width: '100% !important',
                '& .MuiFormLabel-asterisk': {
                  color: 'red',
                },
                '& .MuiFormControl-marginNormal': {
                  marginTop: 0,
                  marginBottom: 0,
                },
                '& .MuiAutocomplete-inputRoot': {
                  height: '15px',
                  padding: '0 !important',
                  fontSize: '10px',
                  borderRadius: 0,
                  '& .MuiAutocomplete-input': {
                    padding: '0 !important',
                    textAlign: 'center',
                    color: 'red',
                    fontWeight: 'bold',
                  },
                },
                zIndex: 100,
              }}
              PopperComponent={PopperMy}
              id={'search'}
              disableClearable
              freeSolo={true}
              options={realtors?.map((realtor: any) => realtor.fullName)}
              getOptionLabel={(option: any) => option.fullName || option}
              // getOptionLabel={(option: any) => `${option.fullName}`}
              // getOptionSelected={(option, value) => {
              //   return option.fullName === value;
              // }}
              onChange={handleSearchInput(unit._id)}
              onInputChange={(e: any) => handleInputChange(e, unit._id)}
              renderInput={(params) => <TextField variant="outlined" required={false} {...params} size="small" />}
              onBlur={() => blur('allocation')}
            />
          )}
        </div>
      </Box>
    </Box>
  );
};

interface ChildProps {
  unit: any;
  oldUnit: any;
  handleChange: (e: any, id: string, type: string) => void;
  handleBlur: any;
  handleSelect: any;
  handleSearchInput: any;
  handleInputChange: any;
  setEditedStack?: any;
  editedStack?: any;
  getTotalChanges?: any;
  realtors: IRealtorInfo[];
}

export default StackUnitCard;
