import { useState, useMemo } from 'react';
import { gql, useQuery, useLazyQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { Typography, Box, Button } from '@mui/material';
import StandardTable from '../tables/StandardTable';
import { selectProject } from '../../features/project/projectSlice';
import { numToCurrency, downloadExcel, downloadPdf, normalToCamel, sortSuites } from '../../utils/Functions';
import { adjustmentTypes } from '../../utils/Constants';
import { useAppDispatch } from '../../app/hooks';
import { showErrorSnackbar } from '../../features/snackbar/snackbarSlice';

const ConstructionSummary = () => {
  const storeDispatch = useAppDispatch();
  const project = useSelector(selectProject);
  const [construction, setConstruction] = useState([]);

  const [getDeals] = useLazyQuery(SUITES, {
    onCompleted: (data) => {},
    onError: (err) => {
      storeDispatch(showErrorSnackbar(err.message));
    },
  });

  useQuery(CONSTRUCTION, {
    variables: { project: project._id },
    onCompleted: (data) => {
      setConstruction(data.getConstructionSummary.construction);
    },
  });

  const columns = useMemo(() => {
    return [
      {
        Header: 'Type',
        accessor: (rowData: any) => rowData._id,
      },
      {
        Header: 'Count',
        accessor: (rowData: any) => rowData.count,
      },
      {
        Header: 'Total Value',
        accessor: (rowData: any) => numToCurrency.format(rowData.totalPrice),
      },
      {
        Header: 'Unit Count',
        accessor: (rowData: any) => rowData.unitCount,
      },
    ];
  }, []);

  const downloadSuites = async () => {
    let data = await getDeals({ variables: { project: project._id, type: 'constructionDeals' } });

    let constructionLength = data.data.getDeals.map((data: any) => {
      if (data.construction.length) {
        return {
          floorPlanCount: data.construction.reduce((acc: any, cur: any) => (cur.type === 'Floor Plan Change' ? ++acc : acc), 0),
          decorCount: data.construction.reduce((acc: any, cur: any) => (cur.type === 'Decor Request' ? ++acc : acc), 0),
        };
      } else
        return {
          floorPlanCount: 0,
          decorCount: 0,
        };
    });

    let floorPlanIndex = await Math.max(...constructionLength.map((i: any) => i.floorPlanCount));
    let decorIndex = await Math.max(...constructionLength.map((i: any) => i.decorCount));

    let floorPlanObject: any[] = [];
    let decorObject: any[] = [];

    for (let i = 0; i < floorPlanIndex; i++) {
      floorPlanObject.push(`Floor Plan Type ${i + 1}`);
      floorPlanObject.push(`Floor Plan Name ${i + 1}`);
      floorPlanObject.push(`Floor Plan Value ${i + 1}`);
      floorPlanObject.push(`Floor Plan Description ${i + 1}`);
      floorPlanObject.push(`Floor Plan Requires Change ${i + 1}`);
      floorPlanObject.push(`Floor Plan Completed ${i + 1}`);
    }

    for (let i = 0; i < decorIndex; i++) {
      decorObject.push(`Decor Type ${i + 1}`);
      decorObject.push(`Decor Name ${i + 1}`);
      decorObject.push(`Decor Value ${i + 1}`);
      decorObject.push(`Decor Description ${i + 1}`);
      decorObject.push(`Decor Requires Change ${i + 1}`);
      decorObject.push(`Decor Completed ${i + 1}`);
    }

    let headers: any = [...floorPlanObject, ...decorObject].map((label: any) => {
      return {
        label: label,
        id: normalToCamel(label),
      };
    });

    headers.unshift({
      label: 'Suite',
      id: 'suite',
    });

    let widths: any = [...floorPlanObject, ...decorObject].map((label: any) => {
      return {
        [normalToCamel(label)]: 15,
      };
    });

    widths.unshift({
      suite: 15,
    });

    let newData = data.data.getDeals.map((data: any) => {
      let object = [...floorPlanObject, ...decorObject].reduce((acc, curr) => ((acc[normalToCamel(curr)] = ''), acc), {});

      data.construction
        .filter((data: any) => data.type === 'Floor Plan Change')
        .forEach((data: any, index: number) => {
          object[`floorPlanType${index + 1}`] = data.type;
          object[`floorPlanValue${index + 1}`] = !data.tbd && data.value ? numToCurrency.format(data.value) : 'TBD';
          object[`floorPlanDescription${index + 1}`] = data.description;
          object[`floorPlanName${index + 1}`] = data.name;
          object[`floorPlanRequiresChange${index + 1}`] = data.requiresChange;
          object[`floorPlanCompleted${index + 1}`] = data.completed;
          object[`floorPlanTbd${index + 1}`] = data.tbd;
        });

      data.construction
        .filter((data: any) => data.type === 'Decor Request')
        .forEach((data: any, index: number) => {
          object[`decorType${index + 1}`] = data.type;
          object[`decorValue${index + 1}`] = !data.tbd && data.value ? numToCurrency.format(data.value) : 'TBD';
          object[`decorDescription${index + 1}`] = data.description;
          object[`decorName${index + 1}`] = data.name;
          object[`decorRequiresChange${index + 1}`] = data.requiresChange;
          object[`decorCompleted${index + 1}`] = data.completed;
          object[`floorPlanTbd${index + 1}`] = data.tbd;
        });

      object.suite = data.unit.suite;

      return object;
    });

    let sheetTitle = `${project.name} - Construction Suite Summaries`;

    let sortedSuites = sortSuites(newData, 'suite');

    downloadExcel([sortedSuites], [headers], [], [[widths]], [sheetTitle], sheetTitle);
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography gutterBottom variant="h5">
        <strong>Construction</strong>
      </Typography>
      <Box sx={{ my: 2 }}>
        <StandardTable columns={columns} data={construction} download={downloadSuites} />
      </Box>
    </Box>
  );
};

const SUITES = gql`
  query getDeals($project: MongoID!, $type: String!) {
    getDeals(project: $project, type: $type) {
      _id
      unit {
        _id
        suite
      }
      construction {
        _id
        name
        description
        type
        value
        requiresChange
        tbd
        completed
      }
      adjustments {
        _id
        name
        description
        type
        value
      }
    }
  }
`;

const CONSTRUCTION = gql`
  query getConstructionSummary($project: MongoID!) {
    getConstructionSummary(project: $project) {
      construction {
        _id
        count
        totalPrice
        unitCount
      }
    }
  }
`;

export default ConstructionSummary;
