import { useState, useMemo } from 'react';
import { gql, useMutation } from '@apollo/client';
import Dropzone, { useDropzone } from 'react-dropzone';
import { HexColorPicker } from 'react-colorful';
import { Button, Grid, Box, FormControl, Select, InputLabel, MenuItem } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import imageCompression from 'browser-image-compression';

import SwitchButton from '../common/formControls/SwitchButton';
import PdfCard from '../common/PdfCard';
import TextInput from '../common/formControls/TextInput';
import LoadingWrapper from '../common/LoadingWrapper';
import PortalImages from '../common/PortalImages';
import VideoList from '../common/VideoList';
import { Flex, FlexEnd } from '../../commonStyles';
import { useSelector } from 'react-redux';
import { selectPortal } from '../../features/portal/portalSlice';
import { useAppDispatch } from '../../app/hooks';
import { showSuccessSnackbar, showErrorSnackbar } from '../../features/snackbar/snackbarSlice';
import { selectProject } from '../../features/project/projectSlice';
import { baseStyle, activeStyle, acceptStyle, rejectStyle } from '../../utils/Constants';
import TextEditor from '../common/textEditor/TextEditor';

const Portal = (props: ChildProps) => {
  const { title } = props;
  const storeDispatch = useAppDispatch();
  const portal = useSelector(selectPortal);
  const project = useSelector(selectProject);

  const [showPrimaryColor, setShowPrimaryColor] = useState(false);
  const [showSecondaryColor, setShowSecondaryColor] = useState(false);
  const [colorful, setColorful] = useState('');
  const [information, setInformation] = useState<any>(portal.information);
  const [promo, setPromo] = useState<any>(portal.promo ? portal.promo : '');
  const [active, setActive] = useState<boolean>(portal.active);
  const [vipActive, setVipActive] = useState<boolean>(portal.vipActive);
  const [showHotlist, setShowHotlist] = useState<boolean>(portal.showHotlist);
  const [showOptionsPrice, setShowOptionsPrice] = useState<boolean>(portal.showOptionsPrice);
  const [showOptions, setShowOptions] = useState<boolean>(portal.showOptions);
  const [showUnitTypes, setShowUnitTypes] = useState<boolean>(portal.showUnitTypes);
  const [showCustom, setShowCustom] = useState<boolean>(portal.showCustom);
  const [createWorksheets, setCreateWorksheets] = useState<boolean>(portal.createWorksheets);
  const [sortHotlist, setSortHotlist] = useState<string>(portal.sortHotlist);
  const [officialSite, setOfficialSite] = useState<string>(portal.officialSite);
  const [instaUrl, setInstaUrl] = useState<string>(portal.instaUrl);
  const [subText, setSubText] = useState<string>(portal.subText);
  const [primaryColor, setPrimaryColor] = useState<string>(portal.primaryColor);
  const [secondaryColor, setSecondaryColor] = useState<string>(portal.secondaryColor);
  const [videos, setVideos] = useState<IMedia[]>(portal?.videos);

  const [renderings, setRenderings] = useState<any>(portal.renderings);
  const [pdfs, setPdfs] = useState<any>(portal.pdfs);
  const [photos, setPhotos] = useState<any>(portal.photos);
  const [logos, setLogos] = useState<any>(portal.logos ? portal.logos : []);
  const [aerialPhotos, setAerialPhotos] = useState<any>(portal.aerialPhotos ? portal.aerialPhotos : []);
  const [bRoll, setBRoll] = useState<any>(portal.bRoll ? portal.bRoll : []);

  const { isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: 'image/jpeg, image/png, application/pdf',
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const [updatePortal] = useMutation(UPDATEPORTAL, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Portal Updated!'));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [createMedia, { loading: renderingLoading }] = useMutation(CREATEMEDIA, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Media has been uploaded!'));
      setPdfs(data.createMedia.pdfs);
      setRenderings(data.createMedia.renderings);
      setLogos(data.createMedia.logos);
      setAerialPhotos(data.createMedia.aerialPhotos);
      setBRoll(data.createdMedia.bRoll);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [deleteMedia] = useMutation(DELETEMEDIA, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Media has been deleted!'));
      setPdfs(data.deleteMedia.pdfs);
      setRenderings(data.deleteMedia.renderings);
      setPhotos(data.deleteMedia.photos);
      setLogos(data.deleteMedia.logos);
      setAerialPhotos(data.deleteMedia.aerialPhotos);
      setBRoll(data.deleteMedia.bRoll);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [renameMedia] = useMutation(RENAMEMEDIA, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar(`Renamed to ${data.mediaRename}`));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const submitContent = async (content: string, index: number) => {
    let informationArray = information.map((information: string, numIndex: number) => {
      if (index === numIndex) {
        return content;
      } else return information;
    });
    updatePortal({
      variables: {
        _id: portal._id,
        record: {
          information: informationArray,
        },
      },
    });
  };

  const deleteContent = async (content: string, index: number) => {
    let informationArray = information.filter((information: any, numIndex: number) => index !== numIndex);
    updatePortal({
      variables: {
        _id: portal._id,
        record: {
          information: informationArray,
        },
      },
    });
    setInformation(informationArray);
  };

  const submitPromo = async (content: string, index: number) => {
    updatePortal({
      variables: {
        _id: portal._id,
        record: {
          promo: content,
        },
      },
    });
  };

  const handleVideoInput = (event: any, numIndex: number) => {
    let allVideos = videos.map((video: IMedia, index: number) => {
      if (numIndex === index) {
        return {
          ...video,
          [event.target.name]: event.target.value,
        };
      } else return video;
    });
    setVideos(allVideos);
  };

  // Text

  // Color

  const handleHexColor = (e: any, type: string) => {
    if (type === 'primaryColor') {
      setPrimaryColor(e);
    } else if (type === 'secondaryColor') {
      setSecondaryColor(e);
    }
  };

  const handleColorPicker = (e: any) => {
    if (e.target.name === 'primaryColor') {
      setShowPrimaryColor(!showPrimaryColor);
    } else if (e.target.name === 'secondaryColor') {
      setShowSecondaryColor(!showSecondaryColor);
    }
  };

  const handleColorPickerBlur = (color: string) => {
    setShowPrimaryColor(false);
    setShowSecondaryColor(false);
  };

  const addInformation = () => {
    setInformation([...portal.information, 'Type Information Here']);
  };

  const updateBrokerPortal = (e: any) => {
    e.preventDefault();

    const { _id, brochurePutUrl, brochureGetUrl, brochure, renderings, photos, pdfs, ...newPortal } = portal;

    updatePortal({
      variables: {
        _id: _id,
        record: {
          active,
          vipActive,
          showHotlist,
          showCustom,
          showOptionsPrice,
          showUnitTypes,
          showOptions,
          sortHotlist,
          createWorksheets,
          officialSite,
          instaUrl,
          subText,
          primaryColor,
          secondaryColor,
          videos,
          information,
        },
      },
    });
  };

  const handleDrop = async (acceptedFiles: any, type: string) => {
    if (acceptedFiles.length === 0) {
      storeDispatch(showErrorSnackbar('This file type is not allowed'));
      return;
    }
    const files = acceptedFiles;
    let allFiles: any[] = [];
    if (files.length) {
      for (let i = 0; i < files.length; i++) {
        if (type === 'renderings' || type === 'photos' || type === 'logos' || type === 'aerialPhotos') {
          let filePromise = new Promise(async (resolve) => {
            let uploadFile = files[i];
            const options = {
              maxSizeMB: 0.1,
              maxWidthOrHeight: 1920,
              useWebWorker: true,
            };
            uploadFile = await imageCompression(files[i], options);
            uploadFile.name = `thumb-${uploadFile.name}`;
            const fileReader = new FileReader();
            fileReader.readAsDataURL(uploadFile);
            fileReader.onloadend = async () =>
              resolve({
                url: fileReader.result,
                file: uploadFile,
                name: uploadFile.name,
              });
          });
          allFiles.push(filePromise);
        }
        let filePromise = new Promise(async (resolve) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(files[i]);
          fileReader.onloadend = async () =>
            resolve({
              url: fileReader.result,
              file: files[i],
              name: files[i].name,
            });
        });
        allFiles.push(filePromise);
      }
    }

    Promise.all(allFiles).then((fileContents) => {
      if (type === 'renderings') {
        setRenderings([...renderings, ...fileContents]);
      }
      if (type === 'photos') {
        setPhotos([...photos, ...fileContents]);
      }
      if (type === 'pdfs') {
        setPdfs([...pdfs, ...fileContents]);
      }
      if (type === 'logos') {
        setLogos([...logos, ...fileContents]);
      }
      if (type === 'aerialPhotos') {
        setAerialPhotos([...aerialPhotos, ...fileContents]);
      }
      if (type === 'bRoll') {
        setBRoll([...bRoll, ...fileContents]);
      }
    });
  };

  const saveMedia = async (type: string) => {
    if (type === 'renderings') {
      if (renderings.length > 0) {
        await renderings.forEach(async (file: any) => {
          if (file.file) {
            await createMedia({ variables: { id: portal._id, name: file.file.name, file: file.file, type: 'renderings' } });
          }
        });
      }
    } else if (type === 'pdfs') {
      if (pdfs.length > 0) {
        await pdfs.forEach(async (file: any) => {
          if (file.file) {
            await createMedia({ variables: { id: portal._id, name: file.file.name, file: file.file, type: 'pdfs' } });
          }
        });
      }
    } else if (type === 'photos') {
      if (photos.length > 0) {
        await photos.forEach(async (file: any) => {
          if (file.file) {
            await createMedia({ variables: { id: portal._id, name: file.file.name, file: file.file, type: 'photos' } });
          }
        });
      }
    } else if (type === 'logos') {
      if (logos.length > 0) {
        await logos.forEach(async (file: any) => {
          if (file.file) {
            await createMedia({ variables: { id: portal._id, name: file.file.name, file: file.file, type: 'logos' } });
          }
        });
      }
    } else if (type === 'aerialPhotos') {
      if (aerialPhotos.length > 0) {
        await aerialPhotos.forEach(async (file: any) => {
          if (file.file) {
            await createMedia({ variables: { id: portal._id, name: file.file.name, file: file.file, type: 'aerialPhotos' } });
          }
        });
      }
    } else if (type === 'bRoll') {
      if (bRoll.length > 0) {
        await bRoll.forEach(async (file: any) => {
          if (file.file) {
            await createMedia({ variables: { id: portal._id, name: file.file.name, file: file.file, type: 'bRoll' } });
          }
        });
      }
    }
  };

  const deleteRenderings = (id: number, title: string, numIndex: number) => {
    if (id) {
      deleteMedia({ variables: { _id: portal._id, documentId: id, deleteFile: true, type: 'renderings' } });
    } else {
      let removeRenderings = renderings.filter((renderings: IMedia, index: number) => numIndex !== index);
      setRenderings(removeRenderings);
    }
  };

  const deletePdfs = (id: number, title: string, numIndex: number) => {
    if (id) {
      deleteMedia({ variables: { _id: portal._id, documentId: id, deleteFile: true, type: 'pdfs' } });
    } else {
      let removePdf = pdfs.filter((pdf: IMedia, index: number) => numIndex !== index);
      setPdfs(removePdf);
    }
  };

  const deletePhotos = (id: number, title: string, numIndex: number) => {
    if (id) {
      deleteMedia({ variables: { _id: portal._id, documentId: id, deleteFile: true, type: 'photos' } });
    } else {
      let removePhotos = photos.filter((photos: IMedia, index: number) => numIndex !== index);
      setPhotos(removePhotos);
    }
  };

  const deleteLogos = (id: number, title: string, numIndex: number) => {
    if (id) {
      deleteMedia({ variables: { _id: portal._id, documentId: id, deleteFile: true, type: 'logos' } });
    } else {
      let removeLogos = logos.filter((logos: IMedia, index: number) => numIndex !== index);
      setLogos(removeLogos);
    }
  };

  const deleteAerialPhotos = (id: number, title: string, numIndex: number) => {
    if (id) {
      deleteMedia({ variables: { _id: portal._id, documentId: id, deleteFile: true, type: 'aerialPhotos' } });
    } else {
      let removeAerialPhotos = aerialPhotos.filter((logos: IMedia, index: number) => numIndex !== index);
      setAerialPhotos(removeAerialPhotos);
    }
  };

  const deleteBRoll = (id: number, title: string, numIndex: number) => {
    if (id) {
      deleteMedia({ variables: { _id: portal._id, documentId: id, deleteFile: true, type: 'bRoll' } });
    } else {
      let removeBRoll = bRoll.filter((logos: IMedia, index: number) => numIndex !== index);
      setBRoll(removeBRoll);
    }
  };

  const deleteLinks = (numIndex: number) => {
    let filteredVideos = portal.videos.filter((video: IMedia, index: number) => index !== numIndex);
    setVideos(filteredVideos);
  };

  const handleEditLogos = (value: string, id: string, numIndex: number) => {
    if (logos[numIndex].file) {
      let images = logos.map((image: any, index: number) => {
        if (numIndex === index) {
          const newFile = new File([image.file], value, { type: image.file.type });
          return {
            ...image,
            file: newFile,
          };
        } else return image;
      });
      setLogos(images);
    } else {
      renameMedia({
        variables: { _id: portal._id, project: project._id, originalName: logos[numIndex].name, name: value, type: 'logos' },
      }).then((res: any) => {
        let images = logos.map((image: any, index: number) => {
          if (numIndex === index) {
            return {
              ...image,
              name: res.data.mediaRename,
            };
          } else return image;
        });
        setLogos(images);
      });
    }
  };

  const handleEditAerial = (value: string, id: string, numIndex: number) => {
    if (aerialPhotos[numIndex].file) {
      let images = aerialPhotos.map((image: any, index: number) => {
        if (numIndex === index) {
          const newFile = new File([image.file], value, { type: image.file.type });
          return {
            ...image,
            file: newFile,
          };
        } else return image;
      });
      setAerialPhotos(images);
    } else {
      renameMedia({
        variables: { _id: portal._id, project: project._id, originalName: aerialPhotos[numIndex].name, name: value, type: 'aerial' },
      }).then((res: any) => {
        let images = aerialPhotos.map((image: any, index: number) => {
          if (numIndex === index) {
            return {
              ...image,
              name: res.data.mediaRename,
            };
          } else return image;
        });
        setAerialPhotos(images);
      });
    }
  };

  const handleEditRenderings = (value: string, id: string, numIndex: number) => {
    if (renderings[numIndex].file) {
      let images = renderings.map((image: any, index: number) => {
        if (numIndex === index) {
          const newFile = new File([image.file], value, { type: image.file.type });
          return {
            ...image,
            file: newFile,
          };
        } else return image;
      });
      setRenderings(images);
    } else {
      renameMedia({
        variables: { _id: portal._id, project: project._id, originalName: renderings[numIndex].name, name: value, type: 'renderings' },
      }).then((res: any) => {
        let images = renderings.map((image: any, index: number) => {
          if (numIndex === index) {
            return {
              ...image,
              name: res.data.mediaRename,
            };
          } else return image;
        });
        setRenderings(images);
      });
    }
  };

  const handleEditNeighbourhood = (value: string, id: string, numIndex: number) => {
    if (photos[numIndex].file) {
      let images = photos.map((image: any, index: number) => {
        if (numIndex === index) {
          const newFile = new File([image.file], value, { type: image.file.type });
          return {
            ...image,
            file: newFile,
          };
        } else return image;
      });
      setPhotos(images);
    } else {
      renameMedia({
        variables: { _id: portal._id, project: project._id, originalName: photos[numIndex].name, name: value, type: 'photos' },
      }).then((res: any) => {
        let images = photos.map((image: any, index: number) => {
          if (numIndex === index) {
            return {
              ...image,
              name: res.data.mediaRename,
            };
          } else return image;
        });
        setPhotos(images);
      });
    }
  };

  const handleEditDocuments = (value: string, id: string, numIndex: number) => {
    if (pdfs[numIndex].file) {
      let images = pdfs.map((image: any, index: number) => {
        if (numIndex === index) {
          const newFile = new File([image.file], value, { type: image.file.type });
          return {
            ...image,
            file: newFile,
          };
        } else return image;
      });
      setPdfs(images);
    } else {
      renameMedia({
        variables: { _id: portal._id, project: project._id, originalName: pdfs[numIndex].name, name: value, type: 'pdfs' },
      }).then((res: any) => {
        let images = pdfs.map((image: any, index: number) => {
          if (numIndex === index) {
            return {
              ...image,
              name: res.data.mediaRename,
            };
          } else return image;
        });
        setPdfs(images);
      });
    }
  };

  return renderingLoading ? (
    <LoadingWrapper title="Saving..." modal={true} />
  ) : (
    <form id="portal-form" onSubmit={updateBrokerPortal} autoComplete="off">
      <h2>{title}</h2>
      <Box>
        <Grid container spacing={2} sx={{ mb: 4 }}>
          <Grid item lg={3}>
            <SwitchButton state={active} handleChange={() => setActive(!active)} title={'Public Portal'} />
          </Grid>
          <Grid item lg={3}>
            <SwitchButton state={vipActive} handleChange={() => setVipActive(!vipActive)} title={'VIP Portal'} />
          </Grid>
          <Grid item lg={3}>
            <SwitchButton state={showHotlist} handleChange={() => setShowHotlist(!showHotlist)} title={'Show Hotlist'} />
          </Grid>
          <Grid item lg={3}>
            <SwitchButton state={showUnitTypes} handleChange={() => setShowUnitTypes(!showUnitTypes)} title={'Show Only Unit Type'} />
          </Grid>
          <Grid item lg={3}>
            <SwitchButton state={showOptions} handleChange={() => setShowOptions(!showOptions)} title={'Allow Options'} />
          </Grid>
          <Grid item lg={3}>
            <SwitchButton state={showCustom} handleChange={() => setShowCustom(!showCustom)} title={'Show Custom Field'} />
          </Grid>
          <Grid item lg={3}>
            <SwitchButton
              state={showOptionsPrice}
              handleChange={() => setShowOptionsPrice(!showOptionsPrice)}
              title={'Show Options Price'}
            />
          </Grid>
          <Grid item lg={3}>
            <SwitchButton
              state={createWorksheets}
              handleChange={() => setCreateWorksheets(!createWorksheets)}
              title={'Allow Creating Worksheets'}
            />
          </Grid>
          <Grid item lg={3}>
            <FormControl sx={{ width: '100%' }}>
              <InputLabel id="demo-simple-select-label">Default Sort Hotlist</InputLabel>
              <Select
                label="Default Sort Hotlist"
                sx={{ width: '400px' }}
                labelId="default-sort-hotlist"
                id="default-sort-hotlist"
                value={sortHotlist}
                onChange={(e: any) => {
                  setSortHotlist(e.target.value);
                }}
              >
                <MenuItem value={'low'}>Price Low</MenuItem>
                <MenuItem value={'high'}>Price High</MenuItem>
                <MenuItem value={'modelType'}>Model Type (A-Z)</MenuItem>
                <MenuItem value={'unitType'}>Unit Type (A-Z)</MenuItem>
                <MenuItem value={'size'}>Size</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Box sx={{ mb: 4 }}>
          <TextInput
            title={'Official Site Link URL'}
            name={'officialSite'}
            handleTextInput={(e: any) => setOfficialSite(e.target.value)}
            value={officialSite}
            required
          />
        </Box>
        <Box sx={{ mb: 4 }}>
          <TextInput
            title={'Instagram Link URL'}
            name={'instaUrl'}
            handleTextInput={(e: any) => setInstaUrl(e.target.value)}
            value={instaUrl}
            required
          />
        </Box>
        <Box sx={{ mb: 4 }}>
          <TextInput
            title={'Text that goes below project name on the list of projects'}
            name={'subText'}
            handleTextInput={(e: any) => setSubText(e.target.value)}
            value={subText}
            required
          />
        </Box>
        <Box sx={{ mb: 4, position: 'relative' }}>
          {showPrimaryColor ? (
            <Box
              sx={{
                position: 'absolute',
                zIndex: 100,
                top: '80px',
              }}
            >
              <HexColorPicker color={colorful} onChange={(e) => handleHexColor(e, 'primaryColor')} />
            </Box>
          ) : null}
          <TextInput
            background={primaryColor}
            title={'Primary Colour'}
            handleTextInput={(e: React.ChangeEvent<HTMLInputElement>) => setPrimaryColor(e.target.value)}
            name={'primaryColor'}
            value={primaryColor}
            focus={true}
            blur={true}
            handleFocus={(e: any) => handleColorPicker(e)}
            handleBlur={() => handleColorPickerBlur('primaryColor')}
          />
        </Box>
        <Box sx={{ mb: 4, position: 'relative' }}>
          {showSecondaryColor ? (
            <Box
              sx={{
                position: 'absolute',
                zIndex: 100,
                top: '80px',
              }}
            >
              <HexColorPicker color={colorful} onChange={(e) => handleHexColor(e, 'secondaryColor')} />
            </Box>
          ) : null}
          <TextInput
            background={secondaryColor}
            title={'Secondary Colour'}
            handleTextInput={(e: React.ChangeEvent<HTMLInputElement>) => setSecondaryColor(e.target.value)}
            name={'secondaryColor'}
            value={secondaryColor}
            focus={true}
            blur={true}
            handleFocus={(e: any) => handleColorPicker(e)}
            handleBlur={() => handleColorPickerBlur('secondaryColor')}
          />
        </Box>
      </Box>
      <Box>
        <h3>Promos</h3>
        <Box sx={{ mb: 4 }}>
          <TextEditor initContent={promo} index={0} renderButtons={[{ name: 'Save', function: submitPromo }]} />
        </Box>
      </Box>
      <Box>
        <h3>Broker Portal Information</h3>
        {information.map((html: any, index: number) => {
          return (
            <div key={index}>
              <Box sx={{ mb: 4 }}>
                <TextEditor
                  initContent={html}
                  index={index}
                  renderButtons={[
                    { name: 'Save', function: submitContent },
                    { name: 'Delete', function: deleteContent },
                  ]}
                />
              </Box>
            </div>
          );
        })}
        <Box sx={{ my: 2 }}>
          <Button onClick={() => addInformation()} color="primary" variant={'contained'}>
            Add Information Section
          </Button>
        </Box>
      </Box>
      <PortalImages
        title={'Logos'}
        type={'logos'}
        handleDrop={handleDrop}
        saveMedia={saveMedia}
        images={logos.filter((rendering: IMedia) => rendering && !rendering.name.includes('thumb'))}
        deleteImages={deleteLogos}
        handleEdit={handleEditLogos}
      />
      <PortalImages
        title={'Renderings (Images)'}
        type={'renderings'}
        handleDrop={handleDrop}
        saveMedia={saveMedia}
        images={renderings.filter((rendering: IMedia) => rendering && !rendering.name.includes('thumb'))}
        deleteImages={deleteRenderings}
        handleEdit={handleEditRenderings}
      />
      <PortalImages
        title={'Aerial Photos'}
        type={'aerialPhotos'}
        handleDrop={handleDrop}
        saveMedia={saveMedia}
        images={aerialPhotos.filter((rendering: IMedia) => rendering && !rendering.name.includes('thumb'))}
        deleteImages={deleteAerialPhotos}
        handleEdit={handleEditAerial}
      />
      <PortalImages
        title={'Neighbourhood Photos'}
        type={'photos'}
        handleDrop={handleDrop}
        saveMedia={saveMedia}
        images={photos.filter((rendering: IMedia) => rendering && !rendering.name.includes('thumb'))}
        deleteImages={deletePhotos}
        handleEdit={handleEditNeighbourhood}
      />
      <VideoList
        title={'Rendering Animations'}
        type={'bRoll'}
        handleDrop={handleDrop}
        saveMedia={saveMedia}
        videos={bRoll}
        deleteVideos={deleteBRoll}
      />
      <Box>
        <h3>Video Links</h3>
        {videos.map((video: IMedia, index: number) => {
          return (
            <Flex>
              <div style={{ width: '100%', margin: '10px 0' }}>
                <div>
                  <TextInput
                    title={'Video Title'}
                    name={'name'}
                    handleTextInput={(e: any) => handleVideoInput(e, index)}
                    value={video.name}
                    required
                  />
                </div>
                <div style={{ marginTop: '10px' }}>
                  <TextInput
                    title={'Video Link (Youtube, Vimeo)'}
                    name={'getUrl'}
                    handleTextInput={(e: any) => handleVideoInput(e, index)}
                    value={video.getUrl}
                    required
                  />
                </div>
              </div>
              <div style={{ alignSelf: 'center' }}>
                <CloseIcon
                  color="secondary"
                  style={{ cursor: 'pointer', width: '50px', alignSelf: 'center' }}
                  onClick={() => deleteLinks(index)}
                />
              </div>
            </Flex>
          );
        })}
        <Box sx={{ mt: 1 }}>
          <Button onClick={() => setVideos([...videos, { name: '', getUrl: '' }])} color="primary" variant={'contained'}>
            Add New Video Link
          </Button>
        </Box>
      </Box>
      <Box>
        <h3>Documents (Wire Transfer Requirements, Mortgage Pre-approval Requirements)</h3>
        <Box sx={{ mb: 4 }}>Upload any documents here</Box>
        <div>
          <Dropzone
            onDrop={(files) => handleDrop(files, 'pdfs')}
            accept="image/jpg, image/jpeg, image/png, application/pdf, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation"
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps({ style })}>
                  <input {...getInputProps()} />
                  <p>Drag and Drop or Upload Documents</p>
                </div>
              </section>
            )}
          </Dropzone>
        </div>
        <div>
          <Grid container spacing={2}>
            {pdfs.length > 0
              ? pdfs.map((attachment: any, index: number) => {
                  return (
                    <Grid key={index} item lg={3} md={4} sm={6} xs={12}>
                      <PdfCard
                        file={attachment.getUrl ? attachment.getUrl : attachment.url}
                        title={attachment.name ? attachment.name : attachment.file.name}
                        id={attachment._id}
                        handleDelete={deletePdfs}
                        download={true}
                        index={index}
                        handleEdit={handleEditDocuments}
                      />
                    </Grid>
                  );
                })
              : null}
          </Grid>
          <Button onClick={() => saveMedia('pdfs')} name={'update'} color="success" variant="contained">
            Save Documents
          </Button>
        </div>
      </Box>
      <FlexEnd>
        <Button sx={{ my: 3 }} form="portal-form" type={'submit'} variant="contained" color="success">
          Update Broker Portal
        </Button>
      </FlexEnd>
    </form>
  );
};

interface ChildProps {
  title: string;
  params?: any;
  location?: any;
  history?: any;
  updateProject?: any;
}

interface IMedia {
  url?: any;
  file?: any;
  name: string;
  putUrl?: string;
  getUrl?: string;
  size?: number;
}

const UPDATEPORTAL = gql`
  mutation portalUpdateById($_id: MongoID!, $record: UpdateByIdPortalInput!) {
    portalUpdateById(_id: $_id, record: $record) {
      record {
        project {
          _id
        }
        active
        vipActive
        showHotlist
        showCustom
        createWorksheets
        showOptionsPrice
        showUnitTypes
        showOptions
        sortHotlist
        password
        information
        renderings {
          _id
          getUrl
          name
        }
        photos {
          _id
          getUrl
          name
        }
        pdfs {
          _id
          getUrl
          name
        }
        logos {
          _id
          name
          getUrl
        }
        aerialPhotos {
          _id
          name
          getUrl
        }
        bRoll {
          _id
          name
          getUrl
        }
        officialSite
        subText
        videos {
          name
          getUrl
        }
        instaUrl
      }
    }
  }
`;

const CREATEMEDIA = gql`
  mutation createMedia($id: MongoID!, $name: String!, $file: Upload!, $type: String!) {
    createMedia(id: $id, name: $name, file: $file, type: $type) {
      renderings {
        _id
        name
        getUrl
      }
      photos {
        _id
        getUrl
        name
      }
      pdfs {
        _id
        name
        getUrl
      }
      logos {
        _id
        name
        getUrl
      }
      aerialPhotos {
        _id
        name
        getUrl
      }
      bRoll {
        _id
        name
        getUrl
      }
    }
  }
`;

const DELETEMEDIA = gql`
  mutation deleteMedia($_id: MongoID!, $documentId: MongoID!, $deleteFile: Boolean!, $type: String!) {
    deleteMedia(_id: $_id, documentId: $documentId, deleteFile: $deleteFile, type: $type) {
      renderings {
        _id
        name
        getUrl
      }
      photos {
        _id
        getUrl
        name
      }
      pdfs {
        _id
        name
        getUrl
      }
      logos {
        _id
        name
        getUrl
      }
      aerialPhotos {
        _id
        name
        getUrl
      }
      bRoll {
        _id
        name
        getUrl
      }
    }
  }
`;

const RENAMEMEDIA = gql`
  mutation mediaRename($_id: MongoID!, $project: MongoID!, $originalName: String!, $name: String!, $type: String!) {
    mediaRename(_id: $_id, project: $project, originalName: $originalName, name: $name, type: $type)
  }
`;

export default Portal;
