import { Paper, Box, Button, CardMedia, Badge } from '@mui/material';
import React from 'react';
import { FlexBetween } from '../../../commonStyles';
import { ICollection, IUpgrade, IUpgradeTemplate } from '../../../types/colourSelection';
import { IUnit } from '../../../types/unit';
import { numToCurrency } from '../../../utils/Functions';

const UpgradeCard = (props: ChildProps) => {
  const { upgrade, selectedCollection, selectedUpgrades, deleteUpgrade, addUpgrade, unit } = props;

  const currentCollection = () => {
    if (
      selectedCollection?.upgradeTemplates.find(
        (upgradeTemplate: IUpgradeTemplate) => upgradeTemplate && upgradeTemplate._id === upgrade._id
      )
    ) {
      return true;
    } else return false;
  };

  const currentUpgrade = () => {
    let selected = selectedUpgrades.find((upgrades: IUpgrade) => upgrades.upgradeTemplate && upgrades.upgradeTemplate?._id === upgrade._id);
    if (selected) {
      return selected;
    } else return null;
  };

  const displayedPrice = () => {
    if (upgrade.freeUpgrades.includes(unit.suite)) {
      return '$0';
    } else return numToCurrency.format(upgrade.price);
  };

  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        backgroundColor: currentUpgrade() ? '#e3ffe3' : '#fff',
        border: currentUpgrade() ? '3px solid #29d529' : null,
        opacity: upgrade.lock ? 0.5 : 1,
      }}
      elevation={12}
    >
      <Badge
        sx={{ height: '100%', '& .MuiBadge-badge': { fontSize: 15, height: 24, minWidth: 24 } }}
        invisible={currentUpgrade() && currentUpgrade()?.quantity! > 1 ? false : true}
        color={'primary'}
        badgeContent={currentUpgrade() && currentUpgrade()?.quantity! > 1 ? `x${currentUpgrade()?.quantity}` : '0'}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%',
          }}
        >
          <Box>
            {upgrade.getUrl ? (
              <CardMedia component="img" alt={upgrade.name} height="200" sx={{ objectFit: 'contain' }} image={upgrade.getUrl} />
            ) : (
              <Box sx={{ height: '200px', backgroundColor: '#b3b3b3', position: 'relative', display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ alignSelf: 'center' }}>
                  <em>Image Unavailable</em>
                </Box>
              </Box>
            )}
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', p: 2 }}>
            <Box sx={{ mb: 2 }}>
              <Box>
                <strong>{upgrade.name}</strong>
              </Box>
              {currentCollection() ? (
                <Box>
                  <em>Current Collection</em>
                </Box>
              ) : null}
              <Box>{displayedPrice()}</Box>
              {upgrade.description ? (
                <Box sx={{ mt: 2 }}>
                  <em>{upgrade.description}</em>
                </Box>
              ) : null}
            </Box>
            <Box sx={{ mt: 'auto' }}>
              {currentUpgrade() ? (
                <FlexBetween>
                  <Button disabled={upgrade.lock} onClick={() => addUpgrade(upgrade, null)} variant="contained" color="success">
                    Add
                  </Button>
                  <Button disabled={upgrade.lock} onClick={() => deleteUpgrade(upgrade, null)} variant="contained" color="error">
                    Remove
                  </Button>
                </FlexBetween>
              ) : (
                <Button disabled={upgrade.lock} onClick={() => addUpgrade(upgrade, null)} variant="contained" color="success">
                  Add
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Badge>
    </Paper>
  );
};

interface ChildProps {
  upgrade: IUpgradeTemplate;
  selectedCollection?: ICollection | null;
  selectedUpgrades: IUpgrade[];
  deleteUpgrade: (upgradeTemplate: IUpgradeTemplate, selectedUpgrade: IUpgrade | null) => void;
  addUpgrade: (upgradeTemplate: IUpgradeTemplate, selectedUpgrade: IUpgrade | null) => void;
  unit: IUnit;
}

export default UpgradeCard;
