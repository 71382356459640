import { Grid } from '@mui/material';

import UpgradeCard from './UpgradeCard';
import { IUpgradeTemplate } from '../../../types/colourSelection';

const Upgrades = (props: ChildProps) => {
  const { upgrades, handleEditUpgrade } = props; 
  return (
    <Grid container spacing={2}>
      {upgrades.map((upgrade: IUpgradeTemplate, index: number) => {
        return (
          <Grid key={index} onClick={() => handleEditUpgrade(upgrade)} item lg={3} md={4} sm={6} xs={12}>
            <UpgradeCard upgrade={upgrade} />
          </Grid>
        )
      })}
    </Grid>
  )
};

interface ChildProps {
  upgrades: IUpgradeTemplate[];
  handleEditUpgrade: any;
}

export default Upgrades;
