import { useContext } from 'react';
import { Box, TextField } from '@mui/material';
import { useParams } from 'react-router-dom';

import { MergeContext } from '../../context/MergeContext';
import FileUpload from '../common/FileUpload';
import Dropdown from '../common/formControls/Dropdown';
import { mergeType as types } from '../../utils/Constants';
import { useAppDispatch } from '../../app/hooks';
import { showErrorSnackbar } from '../../features/snackbar/snackbarSlice';
import { IUnit } from '../../types/unit';

const MergeInputs = (props: ChildProps) => {
  const { setFile } = props;
  const {
    setPdfs,
    setMergeName,
    mergeName,
    setMergeType,
    mergeType,
    suites,
    selectedSuite,
    setSelectedSuite,
    setMergeFields,
    setSignFields,
    setTotalPages,
  } = useContext(MergeContext);
  const { mergeid } = useParams();
  const storeDispatch = useAppDispatch();

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files![0];
    if (file.type !== 'application/pdf') {
      storeDispatch(showErrorSnackbar('Only PDFs are allowed!'));
      return;
    }
    const fileReader = new FileReader();
    fileReader.onloadend = async () => {
      setPdfs(fileReader.result);
      setFile(file);
    };
    if (file) {
      fileReader.readAsDataURL(file);
    }
  };

  const handleSuite = (e: any) => {
    let selectedSuite = suites.find((suite: IUnit) => suite.suite === e.target.value);
    if (selectedSuite) {
      setSelectedSuite(selectedSuite);
      setPdfs(selectedSuite.getUrl);
      setMergeFields([]);
      setSignFields([]);
      if (selectedSuite.floorPlan) {
        setMergeFields(selectedSuite.floorPlan.mergeFields);
        setSignFields(selectedSuite.floorPlan.signFields);
        setTotalPages(1);
      }
    }
  };

  return (
    <Box>
      {mergeid !== 'floorplans' ? (
        <Box>
          <Box>
            <TextField
              fullWidth
              title={'Name of Merge (Ex. APS)'}
              id={'title'}
              name={'title'}
              onChange={(e: any) => setMergeName(e.target.value)}
              value={mergeName}
              required={true}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <Dropdown
              title={'Template Type'}
              menuList={types}
              name={'type'}
              handleSelect={(e: any) => setMergeType(e.target.value)}
              value={mergeType}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <FileUpload onChange={onChange} title={'Upload Document'} />
          </Box>
        </Box>
      ) : (
        <Box>
          <Dropdown
            id={'suites'}
            title={'Select Suite'}
            menuList={suites.map((suite: IUnit) => suite.suite)}
            name={'suite'}
            handleSelect={(e: any) => handleSuite(e)}
            value={selectedSuite}
          />
        </Box>
      )}
    </Box>
  );
};

interface ChildProps {
  setFile: any;
}

export default MergeInputs;
