import { Grid, Paper, Typography, Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectUser } from '../../features/auth/authSlice';
import { FlexBetween } from '../../commonStyles';

const ResourceCard = (props: ChildProps) => {
  const { title, subtitle, button, buttonText, secondButton, secondButtonText, color } = props;
  const user = useSelector(selectUser);

  return (
    <Grid item lg={3} md={4} sm={6} xs={12}>
      <Paper elevation={12} sx={{ p: 2, height: '100%' }}>
        <Typography variant={'h3'}>
          <strong>{title}</strong>
        </Typography>
        <p>{subtitle}</p>
        <FlexBetween>
          <Button
            disabled={user.type === 'Sales' || user.type === 'Realtor'}
            onClick={button}
            sx={{ mt: 2 }}
            color={color ? color : 'error'}
            variant="contained"
          >
            {buttonText}
          </Button>
          {secondButton && secondButtonText ? (
            <Button
              disabled={user.type === 'Sales' || user.type === 'Realtor'}
              onClick={secondButton}
              sx={{ mt: 2 }}
              color="primary"
              variant="contained"
            >
              {secondButtonText}
            </Button>
          ) : null}
        </FlexBetween>
      </Paper>
    </Grid>
  );
};

interface ChildProps {
  title: string;
  subtitle: string;
  button: any;
  buttonText: string;
  secondButtonText?: string;
  secondButton?: any;
  color?: any;
}

export default ResourceCard;
