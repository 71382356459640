import { useState, useContext } from 'react';
import { Box, Pagination, Tooltip } from '@mui/material';
import CopyAllIcon from '@mui/icons-material/CopyAll';

import { MergeContext } from '../../context/MergeContext';
import { useAppDispatch } from '../../app/hooks';
import TextInput from '../common/formControls/TextInput';
import { FlexBetween } from '../../commonStyles';
import { showErrorSnackbar } from '../../features/snackbar/snackbarSlice';
import { CopyAll } from '@mui/icons-material';

const MergeButtons = (props: ChildProps) => {
  const storeDispatch = useAppDispatch();
  const { pageNum, setPageNum, setDocumentLoaded } = useContext(MergeContext);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const { totalPages, copySignFields } = props;

  const submitPageNum = (pageNum: number) => {
    setDocumentLoaded(false);
    if (pageNum === 0 || pageNum > totalPages) {
      storeDispatch(showErrorSnackbar('Invalid Page Number'));
      return;
    }
    setPageNumber(pageNum);
    setPageNum(pageNum);
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      sx={{
        height: '100%',
        textAlign: 'center',
        alignItems: 'center',
        margin: '10px auto',
        '& .react-pdf__Page__canvas': {
          margin: '0 auto',
        },
        '& .react-pdf__message--loading': {
          display: 'flex',
          justifyContent: 'center',
          alignSelf: 'center',
        },
      }}
    >
      <Pagination
        count={totalPages}
        page={pageNumber}
        siblingCount={window.innerWidth < 600 ? 0 : 3}
        showFirstButton
        showLastButton
        onChange={(e: any, value: number) => submitPageNum(value)}
      />
      {copySignFields ? 
      <Tooltip title="Copy all Sign Fields From Previous Page">
        <CopyAllIcon onClick={() => copySignFields()} sx={{ cursor: 'pointer' }} />
      </Tooltip>
      : null}
    </Box>
  );
};

interface ChildProps {
  totalPages: number;
  copySignFields?: any;
}

export default MergeButtons;
