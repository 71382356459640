import React, { useState, useEffect } from 'react';
import { useMutation, gql } from '@apollo/client';
import {
  Box,
  Grid,
  Typography,
  TextField,
  Paper,
  Divider,
  Button,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Pagination,
} from '@mui/material';
import { useSelector } from 'react-redux';

import Dropdown from '../common/formControls/Dropdown';
import { selectProject, setMergeTemplate } from '../../features/project/projectSlice';
import { useAppDispatch } from '../../app/hooks';
import { showSuccessSnackbar } from '../../features/snackbar/snackbarSlice';
import { IAPSTemplate, IMergeTemplates } from '../../types/project';
import { selectMerges } from '../../features/merge/mergeSlice';
import { FlexBetween } from '../../commonStyles';
import { IMerge } from '../../types/merge';
import { setProject } from '../../features/project/projectSlice';
import { useMergeQuery } from '../../features/merge/mergeHooks';
import LoadingWrapper from '../common/LoadingWrapper';

const MergeTemplateSettings = (props: ChildProps) => {
  const { title } = props;
  const storeDispatch = useAppDispatch();
  const project = useSelector(selectProject);
  const merges = useSelector(selectMerges);
  const [mergeTemplates, setMergeTemplates] = useState(project.mergeTemplates);
  const [mergeFieldPage, setMergeFieldPage] = useState<number>(1);
  const [defaultMerge, setDefaultMerge] = useState<IMerge | null>(null);
  const { loading } = useMergeQuery(project._id, ['APS'], 0, 11);

  useEffect(() => {
    if (merges.length) {
      let defaultMerge = merges.find((merge: IMerge) => merge.default);
      if (defaultMerge) {
        setDefaultMerge(defaultMerge);
      }
    }
  }, [merges]);

  const [updateDefaultMerge] = useMutation(UPDATEDEFAULTMERGE, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Default APS has been updated'));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [updateProject] = useMutation(UPDATEPROJECT, {
    onCompleted: (data) => {
      storeDispatch(setProject(data.projectUpdateById.record));
      storeDispatch(showSuccessSnackbar('Merge Templates has been updated!'));
      storeDispatch(setMergeTemplate(data.projectUpdateById.record.mergeTemplate));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const handleDefaultMerge = (event: React.ChangeEvent<{ value: unknown }>) => {
    let defaultMerge = merges.find((merge: IMerge) => merge._id === event.target.value);
    if (defaultMerge) {
      for (const merge of merges) {
        if (merge.default) {
          updateDefaultMerge({ variables: { _id: merge._id, record: { default: false } } });
        } else if (defaultMerge._id === merge._id) {
          updateDefaultMerge({ variables: { _id: defaultMerge._id, record: { default: true } } });
        }
      }
      setDefaultMerge(defaultMerge);
    }
  };

  const handleTextInput = (event: any, apsTemplate: any, name: string) => {
    let selectedAmendment = mergeTemplates.find((merge: any) => name === merge.name);
    let apsAmendment = selectedAmendment?.apsTemplates
      .filter((apsTemplate: any) => apsTemplate.apsTemplate.type !== 'Archive')
      .find((template: any) => template.apsTemplate._id === apsTemplate.apsTemplate._id || template.name === apsTemplate.name);

    let apsTemplates = selectedAmendment?.apsTemplates;

    if (apsAmendment) {
      apsTemplates = selectedAmendment?.apsTemplates.map((apsTemplate: any) => {
        if (apsTemplate.apsTemplate._id === apsAmendment?.apsTemplate?._id || apsTemplate.name === apsAmendment?.name) {
          return {
            ...apsTemplate,
            pageNumber: parseInt(event.target.value),
          };
        } else return apsTemplate;
      });
    }

    let mergeArray = mergeTemplates.map((merge: any, index: number) => {
      if (name === merge.name) {
        return {
          ...merge,
          apsTemplates: apsTemplates,
        };
      } else return merge;
    });

    setMergeTemplates(mergeArray);
  };

  const handleDropdownInput = (event: any, apsTemplate: any, name: string) => {
    let selectedAmendment = mergeTemplates.find((merge: any) => name === merge.name);
    let apsAmendment = selectedAmendment?.apsTemplates
      .filter((apsTemplate: any) => apsTemplate.apsTemplate.type !== 'Archive')
      .find((template: any) => template.apsTemplate._id === apsTemplate.apsTemplate._id || template.name === apsTemplate.name);

    let apsTemplates = selectedAmendment?.apsTemplates;

    if (apsAmendment) {
      apsTemplates = selectedAmendment?.apsTemplates.map((apsTemplate: any) => {
        if (apsTemplate.apsTemplate._id === apsAmendment?.apsTemplate?._id || apsTemplate.name === apsAmendment?.name) {
          return {
            ...apsTemplate,
            attachToAps: event.target.value,
          };
        } else return apsTemplate;
      });
    }

    let mergeArray = mergeTemplates.map((merge: any, index: number) => {
      if (name === merge.name) {
        return {
          ...merge,
          apsTemplates: apsTemplates,
        };
      } else return merge;
    });

    setMergeTemplates(mergeArray);
  };

  const updateMergeTemplates = (event: any) => {
    event.preventDefault();
    updateProject({
      variables: {
        _id: project._id,
        record: {
          mergeTemplates: mergeTemplates.map((mergeTemplateObject: IMergeTemplates) => {
            return {
              ...mergeTemplateObject,
              apsTemplates: mergeTemplateObject.apsTemplates.map((apsTemplates: IAPSTemplate) => {
                return {
                  ...apsTemplates,
                  apsTemplate: apsTemplates?.apsTemplate ? apsTemplates.apsTemplate._id : null,
                };
              }),
              mergeTemplate: mergeTemplateObject.mergeTemplate?._id,
            };
          }),
        },
      },
    });
  };

  return loading ? (
    <LoadingWrapper title="Loading" modal={false} />
  ) : (
    <div>
      <h2>{title}</h2>
      <FlexBetween>
        <Box>
          <FormControl sx={{ width: '100%' }}>
            <InputLabel id="demo-simple-select-label">Default APS</InputLabel>
            <Select
              label="Default APS"
              sx={{ width: '400px' }}
              labelId="demo-simple-select-label"
              id="default-aps"
              value={defaultMerge ? defaultMerge._id : ''}
              onChange={(e: any) => {
                handleDefaultMerge(e);
              }}
            >
              {merges.map((merge: IMerge, index: number) => {
                return (
                  <MenuItem key={index} value={merge._id}>
                    {merge.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ my: 2 }}>
          <Button form="merge" type="submit" variant="contained" color="success">
            Save Merge Settings
          </Button>
        </Box>
      </FlexBetween>

      <form
        id="merge"
        onSubmit={updateMergeTemplates}
        style={{
          border: '1px solid #000',
          padding: '30px 20px',
          position: 'relative',
          borderRadius: '8px',
        }}
      >
        {mergeTemplates && mergeTemplates.length > 0 ? (
          <>
            <Grid
              sx={{
                '& .MuiFormLabel-root.Mui-disabled': {
                  color: 'rgba(0, 0, 0)',
                },
                '& .MuiInputBase-root.Mui-disabled': {
                  color: 'rgba(0, 0, 0)',
                },
              }}
              container
              spacing={2}
            >
              {mergeTemplates
                .slice(mergeFieldPage === 1 ? mergeFieldPage - 1 : (mergeFieldPage - 1) * 8, mergeFieldPage * 8)
                .map((merge: any, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      <Grid sx={{ height: '100%' }} item xs={12} sm={12} md={3} lg={3} xl={3}>
                        <Paper elevation={20} sx={{ p: 2 }}>
                          <Typography variant="h6" component="div" sx={{ mr: 1, mb: 2 }}>
                            <strong>{merge.mergeTemplate ? merge.mergeTemplate.name : merge.name}</strong>
                          </Typography>
                          {merge.apsTemplates
                            .filter((apsTemplate: any) => apsTemplate.apsTemplate.type !== 'Archive')
                            .map((apsTemplate: any, index: number) => {
                              return (
                                <Box sx={{ mb: 1 }} key={index}>
                                  <Box sx={{ mb: 1 }}>
                                    <Dropdown
                                      id={'attachToAPS'}
                                      title={`For ${
                                        apsTemplate.apsTemplate ? apsTemplate.apsTemplate.name : apsTemplate.name
                                      } merge, attach to APS as separate document`}
                                      menuList={['none', 'back']}
                                      name={'attachToAPS'}
                                      handleSelect={(e: any) => handleDropdownInput(e, apsTemplate, merge.name)}
                                      value={apsTemplate.attachToAps}
                                    />
                                  </Box>
                                  <TextField
                                    fullWidth
                                    title={`Set Page Number to Appear in ${apsTemplate.name}`}
                                    type={'number'}
                                    name={'pageNumber'}
                                    onChange={(e: any) => handleTextInput(e, apsTemplate, merge.name)}
                                    value={apsTemplate.pageNumber}
                                    required={true}
                                  />
                                  <Divider sx={{ my: 2 }} />
                                </Box>
                              );
                            })}
                        </Paper>
                      </Grid>
                    </React.Fragment>
                  );
                })}
            </Grid>
            <Box sx={{ mt: 2 }}>
              <Pagination
                sx={{ '& .MuiPagination-ul': { justifyContent: 'center' } }}
                color="secondary"
                onChange={(e, page) => setMergeFieldPage(page)}
                count={Math.ceil(mergeTemplates.length / 8)}
              />
            </Box>
          </>
        ) : (
          <div>
            <em>There are currently no merge templates for this project</em>
          </div>
        )}
      </form>
    </div>
  );
};

interface ChildProps {
  title: string;
}

const UPDATEDEFAULTMERGE = gql`
  mutation mergeTemplateUpdateById($_id: MongoID!, $record: UpdateByIdMergeTemplateInput!) {
    mergeTemplateUpdateById(_id: $_id, record: $record) {
      record {
        mergeFields {
          key
          index
          pageNumber
          x
          y
          fontSize
          format
          wrap
        }
        signFields {
          key
          index
          pageNumber
          x
          y
        }
        type
        name
        default
      }
    }
  }
`;

const UPDATEPROJECT = gql`
  mutation projectUpdateById($_id: MongoID!, $record: UpdateByIdProjectInput!) {
    projectUpdateById(_id: $_id, record: $record) {
      record {
        _id
        name
        developerName
        email
        trackingPhone
        addresses {
          streetAddress
          city
          province
          postalCode
        }
        options {
          name
          totalAvailable
          price
          allowedUnits
          type
        }
        depositStructures {
          name
          deposits {
            name
            type
            amount
            daysDue
            dateType
            dueDate
          }
          default
        }
        mergeTemplates {
          name
          mergeTemplate {
            _id
          }
          apsTemplates {
            name
            apsTemplate {
              _id
              name
              type
            }
            pageNumber
            attachToAps
          }
        }
        status {
          name
          code
          color
        }
        executors {
          name
          email
        }
        mortgageLimit
        mortgageMinDay
        hideOccupancy
        defaultZeroValue
        lawyer
        tagLine
        salesOffice
        adjustments {
          name
          type
        }
        commissionIncludeOptions
        emailTemplates {
          _id
          name
          subject
          html
        }
        logoGetUrl
        logoPutUrl
        imageGetUrl
        imagePutUrl
        coopStructures {
          name
          coopRates {
            type
            days
            date
            description
            percentage
            fixedAmount
          }
        }
        firstTentativeOccupancy
        finalTentativeOccupancy
        firmOccupancy
        outsideOccupancy
        active
        decorModels {
          modelType
          allowed
        }
        portal {
          primaryColor
        }
        combinedProjects {
          _id
          name
        }
      }
    }
  }
`;

export default MergeTemplateSettings;
