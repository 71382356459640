/* Dependency Imports */
import { useEffect } from 'react';
import { Box, Button, Paper } from '@mui/material';
import { useEditor, EditorContent, Extension } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import FontFamily from '@tiptap/extension-font-family';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import LinkExtension from '@tiptap/extension-link';
import Color from '@tiptap/extension-color';
import Placeholder from '@tiptap/extension-placeholder';
import CharacterCount from '@tiptap/extension-character-count';
import Highlight from '@tiptap/extension-highlight';
import Typography from '@tiptap/extension-typography';

import { gql, useLazyQuery } from '@apollo/client';
import './editor.css';
import IFrame from './iframe';
import { LineHeight } from './lineHeight';
import { Indent } from './indent';
import { TextIndent } from './textIndent';

/* Project Imports */
import FontSize from './font-size';
import MenuBar from './MenuBar';
import { UploadImage } from './uploadImage';
import { ImageLink } from './imageLink';

const TextEditor = ({
  initContent,
  renderButtons,
  index,
  handleChange,
}: {
  initContent: any;
  renderButtons?: { name: string; function: (content: string, index: number) => void }[];
  index: number;
  handleChange?: any;
}) => {
  /* Redux */

  /* Hooks */

  const DisplayNone = Extension.create({
    addOptions() {
      return {
        alignments: ['flex', 'none', 'inline, block'],
        defaultAlignment: 'block',
      };
    },

    addGlobalAttributes() {
      return [
        {
          // Extend the following extensions
          types: ['heading', 'paragraph'],
          // … with those attributes
          attributes: {
            display: {
              default: 'block',
              renderHTML: (attributes) => {
                return {
                  style: `display: ${
                    attributes.textAlign === 'center' ? 'block' : attributes.display
                  }; margin-block-start: 0; margin-block-end: 0`,
                };
              },
              parseHTML: (element: any) => element.style.display || 'block',
            },
          },
        },
      ];
    },
  });

  const editor = useEditor({
    onUpdate({ editor }) {
      handleChange(editor.getJSON(), index);
    },
    editorProps: {
      clipboardTextParser: (text, $context) => {
        const trimmed = text.trim();
        const parser = ($context as any).path[0].type.schema.cached.domParser;

        const putInIFrame = trimmed.substring(0, 1) === '<' && trimmed.substring(trimmed.length - 1, trimmed.length) === '>';
        if (!putInIFrame) {
          return;
        }

        const doc = document.cloneNode(false);
        const div = (doc as any).createElement('div');

        const iframe = `<iframe />`;
        div.innerHTML = iframe;
        let retval: any = parser.parseSlice(div, { preserveWhiteSpace: true, context: $context });
        retval.content.content[0].attrs['srcdoc'] = text;
        return retval;
      },
    },
    extensions: [
      StarterKit.configure({
        codeBlock: false,
      }),
      DisplayNone,
      Underline,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      TextStyle,
      FontFamily,
      FontSize,
      UploadImage.configure({
        inline: true,
      }),
      ImageLink,
      LinkExtension.configure({
        openOnClick: false,
        HTMLAttributes: {
          style: 'text-decoration: none; color: #000; font-weight: 700;',
        },
      }),
      Table.configure({
        resizable: true,
        HTMLAttributes: {
          style: 'padding: 0; border-spacing: 0; border-collapse: collapse; box-sizing: border-box',
          cellpadding: '0',
          cellspacing: '0',
          border: '0',
        },
      }),
      TableRow.configure({
        HTMLAttributes: {
          style: 'padding: 0 5px; margin: 0 auto; border-spacing: 0; border-collapse: collapse; box-sizing: border-box',
          cellpadding: '0',
          cellspacing: '0',
          border: '0',
        },
      }),
      TableHeader.configure({
        HTMLAttributes: {
          style:
            'background-color: #f1f3f5; border: 2px solid #ced4da; padding: 0 5px; margin: 0 auto; border-spacing: 0; border-collapse: collapse; box-sizing: border-box',
          cellpadding: '0',
          cellspacing: '0',
          border: '0',
        },
      }),
      TableCell.configure({
        HTMLAttributes: {
          style:
            'padding: 0 5px; margin: 0 auto; border: 2px solid #ced4da; border-spacing: 0; border-collapse: collapse; box-sizing: border-box',
          cellpadding: '0',
          cellspacing: '0',
          border: '0',
        },
      }),
      Color,
      Highlight.configure({
        multicolor: true,
      }),
      Placeholder.configure({
        placeholder: 'Enter text...',
      }),
      CharacterCount,
      Typography,
      IFrame,
      LineHeight,
      Indent,
      TextIndent,
    ],
    content: initContent,
  });

  /* States */

  const handleBeforeSave = (button: any, index: number) => {
    editor?.commands.setMeta('name', 'viewport');
    editor?.commands.setMeta('content', 'width=device-width, initial-scale=1');
    button.function(editor?.getHTML() || '', index);
  };

  return (
    <Paper>
      <Box sx={{ px: 2, display: 'flex', justifyContent: 'flex-end' }}>
        {renderButtons?.map((button, i) => (
          <Button
            color={!i ? 'success' : 'error'}
            key={i}
            variant="contained"
            sx={{ mt: 1, mr: 2, mb: 2 }}
            onClick={() => handleBeforeSave(button, index)}
          >
            {button.name}
          </Button>
        ))}
      </Box>
      <MenuBar editor={editor} imgQuery={{}} />
      <EditorContent
        style={{
          padding: '10px',
        }}
        editor={editor}
      />
      {editor && <Box sx={{ color: '#868e96', mt: 2, ml: 1, pb: 1 }}>{editor.storage.characterCount.words()} words</Box>}
    </Paper>
  );
};

/* Components */

/* Types */

/* GQL */

export default TextEditor;
