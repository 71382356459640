import { styled } from '@mui/material/styles';
import { Box, Stepper, Step, StepLabel, StepIconProps } from '@mui/material';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

const CustomStepper = (props: ChildProps) => {
  const { iconList, handleStep, active } = props;

  function ColorlibStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    return (
      <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
        <div>{iconList[String(props.icon)]}</div>
      </ColorlibStepIconRoot>
    );
  }

  return (
    <Box
      sx={{
        p: 2,
        backgroundColor: '#412920',
        '& .MuiStepper-alternativeLabel': {
          backgroundColor: '#412920',
        },
        '& .MuiStepLabel-label': {
          color: '#fff',
        },
      }}
    >
      <Stepper alternativeLabel activeStep={active} connector={<ColorlibConnector />}>
        {props.stepList.map((label: string, index: number) => (
          <Step onClick={() => handleStep(index)} key={label}>
            <StepLabel
              sx={{
                '& .Mui-completed': {
                  color: '#82ff82 !important',
                },
                '& .Mui-active': {
                  color: '#a9b6e7 !important',
                },
              }}
              StepIconComponent={ColorlibStepIcon}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.completed && {
    backgroundImage: 'linear-gradient( 136deg, rgb(114,251,0) 0%, rgb(114,251,0) 50%, rgb(114,251,0) 100%)',
  }),
  ...(ownerState.active && {
    backgroundImage: 'linear-gradient( 136deg, rgb(136,178,247) 0%, rgb(136,178,247) 50%, rgb(136,178,247) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
}));

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: 'linear-gradient( 136deg, rgb(114,251,0) 0%, rgb(114,251,0) 50%, rgb(114,251,0) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    backgroundImage: 'linear-gradient(136deg, #00142a 0%, #00142a 50%, #00142a 100%)',
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: 'linear-gradient( 136deg, rgb(114,251,0) 0%, rgb(114,251,0) 50%, rgb(114,251,0) 100%)',
    },
    color: 'green',
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
}));

interface ChildProps {
  stepList: any;
  iconList: any;
  active: number;
  handleStep: (index: number) => void;
}

export default CustomStepper;
