import React from 'react';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import { selectProject } from '../../features/project/projectSlice';
import { unitStatusTitles } from '../../utils/Constants';
import { IStatus } from '../../types/project';

Chart.register(ArcElement, Tooltip, Legend);
const DoughnutChart = (props: ChildProps) => {
  const { statusData } = props;
  const project = useSelector(selectProject);
  const allStatus: IStatus[] = [...project.status, ...unitStatusTitles];

  const data = {
    labels: statusData?.allTotals.map((status: any) => {
      let selectedStatus = allStatus.find((all: IStatus) => all.code === status._id);
      if (selectedStatus) {
        return `${selectedStatus.name} (${status.count}) (${((status.count / statusData.total) * 100).toFixed(1)}%)`;
      } else return '#000';
    }),
    datasets: [
      {
        data: statusData.allTotals.map((status: any) => status.count),
        backgroundColor: statusData.allTotals.map((status: any) => {
          let selectedStatus = allStatus.find((all: IStatus) => all.code === status._id);
          if (selectedStatus) {
            return selectedStatus.color;
          } else return '#000';
        }),
        borderColor: ['black'],
        borderWidth: 2,
      },
    ],
  };

  const getMortgage = () => {
    let getFirmTotal = statusData.statusTotals
      .filter((all: any) => all._id === 'F' || all._id === 'PF' || all._id === 'BHHS')
      .reduce((sum: any, record: any) => sum + record.count, 0);

    if (getFirmTotal) {
      return `${statusData.mortgageTotal} / ${getFirmTotal} (${((statusData.mortgageTotal / getFirmTotal) * 100).toFixed(2)}%)`;
    } else return 'N/A';
  };

  return (
    <Box sx={{ height: '100%' }}>
      <Box
        sx={{
          pt: 2,
          textAlign: 'center',
        }}
      >
        <Typography variant={'h5'} gutterBottom>
          Unit Distribution
        </Typography>
      </Box>
      <Box
        sx={{
          height: '80%',
          position: 'relative',
        }}
      >
        <Doughnut
          data={data}
          options={{
            maintainAspectRatio: false,
            layout: {
              padding: {
                left: 30,
              },
              autoPadding: true,
            },
            plugins: {
              legend: {
                display: true,
                position: window.innerWidth < 500 ? 'bottom' : 'left',
                title: { display: true, text: '', font: { size: 12 } },
                labels: { boxWidth: 20, boxHeight: 10, font: { size: 12, weight: 'bold' }, padding: 12, color: '#000' },
              },
            },
          }}
        />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          bottom: 5,
          padding: '10px',
        }}
      >
        <strong>Mortgage: {getMortgage()}</strong>
      </Box>
    </Box>
  );
};

interface ChildProps {
  statusData: any;
}

export default DoughnutChart;
