import { useState, useContext } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { DraggableField } from '../common/dnd/Context';
import { MergeContext } from '../../context/MergeContext';
import uniqueId from 'lodash.uniqueid';

const MergeFieldList = (props: ChildProps) => {
  const { ref } = useContext(MergeContext);
  const { fieldName, start, end, getCursorPosition, mergeItems } = props;

  const handleDragEnd = (event: any) => {
    getCursorPosition(
      ref,
      event,
      event.active.data.current.merge.name ? event.active.data.current.merge.name : event.active.data.current.merge.key,
      event.active.data.current.merge.type,
      event.active.data.current.merge.index,
      event.active.data.current.merge.fontSize,
      event.active.data.current.merge.format ? event.active.data.current.merge.format : '',
      false,
      0
    );
  };

  let newMergeItems = mergeItems.map((mergeItem: any) => {
    return {
      ...mergeItem,
      mergeId: uniqueId(),
    };
  });

  return (
    <Box>
      <Typography sx={{ my: 1 }}>
        <strong>{fieldName}</strong>
      </Typography>
      <Grid container spacing={2}>
        {newMergeItems.slice(start, end).map((mergeItem: any, index: number) => {
          return (
            <Grid sx={{ textAlign: 'left' }} key={index} item lg={4} md={6} sm={12} xs={12}>
              <DraggableField
                reset={true}
                mergeItem={mergeItem}
                handleDragEnd={handleDragEnd}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

interface ChildProps {
  fieldName: string;
  start: number;
  end: number;
  getCursorPosition: any;
  mergeItems: any;
}

export default MergeFieldList;
