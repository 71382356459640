import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { IMergeDataInfo } from '../types/merge';
import { IUnit, IUnitData } from '../types/unit';
import { useSelector } from 'react-redux';
import { selectProject } from '../features/project/projectSlice';
import { sortSuites } from '../utils/Functions';

const MergeContext = React.createContext<any>(null);

const MergeProvider = (props: any) => {
  const project = useSelector(selectProject);
  const { mergeid } = useParams();
  const [pdfs, setPdfs] = useState<string | []>('');
  const [putUrl, setPutUrl] = useState<string>('');
  const [ref, setRef] = useState<any>(null);
  const [pageNum, setPageNum] = useState<number>(1);
  const [documentLoaded, setDocumentLoaded] = useState<boolean>(false);
  const [suites, setSuites] = useState<IUnit[]>([]);
  const [selectedSuite, setSelectedSuite] = useState<IUnit>(suites[0]);

  const [mergeId, setMergeId] = useState<string>('0');
  const [mergeName, setMergeName] = useState<string>('');
  const [mergeFields, setMergeFields] = useState<any[]>([]);
  const [signFields, setSignFields] = useState<any[]>([]);
  const [mergeType, setMergeType] = useState<string>('');
  const [totalPages, setTotalPages] = useState<number>(0);

  const { loading } = useQuery<IMergeDataInfo>(GETMERGETEMPLATE, {
    variables: { _id: mergeid },
    skip: !mergeid || mergeid === 'floorplans',
    onCompleted: (data) => {
      setMergeId(data.mergeTemplateById?._id!);
      setMergeName(data.mergeTemplateById.name);
      setMergeFields(data.mergeTemplateById.mergeFields);
      setSignFields(data.mergeTemplateById.signFields);
      setMergeType(data.mergeTemplateById?.type!);
      setTotalPages(data.mergeTemplateById?.totalPages!);
      setPdfs(data.mergeTemplateById?.getUrl!);
      setPutUrl(data.mergeTemplateById?.putUrl!);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  useQuery<IUnitData>(UNITS, {
    variables: { filter: { project: project._id } },
    skip: mergeid !== 'floorplans',
    onCompleted: (data) => {
      let units = data.unitMany.filter((unit: any) => unit.getUrl);
      let sortedUnits = sortSuites(units, 'suite');
      setSelectedSuite(sortedUnits[0]);
      setPdfs(sortedUnits[0].getUrl ? units[0].getUrl : '');
      setSuites(sortedUnits);
      setMergeFields(sortedUnits[0].floorPlan ? sortedUnits[0].floorPlan.mergeFields : []);
      setSignFields(sortedUnits[0].floorPlan ? sortedUnits[0].floorPlan.signFields : []);
      setTotalPages(1);
    },
  });

  return (
    <MergeContext.Provider
      value={{
        mergeId,
        setMergeId,
        mergeName,
        setMergeName,
        signFields,
        setSignFields,
        mergeFields,
        setMergeFields,
        mergeType,
        setMergeType,
        setPdfs,
        pdfs,
        setRef,
        ref,
        setPageNum,
        pageNum,
        documentLoaded,
        setDocumentLoaded,
        suites,
        setSuites,
        selectedSuite,
        setSelectedSuite,
        totalPages,
        setTotalPages,
        putUrl,
      }}
    >
      {loading ? null : props.children}
    </MergeContext.Provider>
  );
};

const UNITS = gql`
  query unitMany($filter: FilterFindManyUnitInput) {
    unitMany(filter: $filter, limit: 10000) {
      _id
      suite
      getUrl
      history {
        type
        description
        timestamp
        user {
          _id
          fullName
        }
        _id
      }
      floorPlan {
        _id
        name
        type
        default
        mergeFields {
          key
          index
          pageNumber
          x
          y
          fontSize
          format
          wrap
        }
        signFields {
          key
          index
          pageNumber
          x
          y
        }
        totalPages
      }
    }
  }
`;

const GETMERGETEMPLATE = gql`
  query mergeTemplateById($_id: MongoID!) {
    mergeTemplateById(_id: $_id) {
      _id
      name
      mergeFields {
        pageNumber
        index
        key
        fontSize
        format
        x
        y
        wrap
      }
      signFields {
        key
        index
        pageNumber
        x
        y
        name
      }
      default
      totalPages
      getUrl
      putUrl
      type
    }
  }
`;

export { MergeContext, MergeProvider };
