import { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Box } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ColourSelection from '../unitMain/colourSelection/ColourSelection';
import { IDeal } from '../../types/CreateDealForm';
import { useAppDispatch } from '../../app/hooks';
import { showErrorSnackbar } from '../../features/snackbar/snackbarSlice';
import { IUnit } from '../../types/unit';
import { IProject } from '../../types/project';

const DecorSelection = () => {
  const storeDispatch = useAppDispatch();
  const location = useLocation();
  const { projectid } = useParams();
  const navigate = useNavigate();
  const [deal, setDeal] = useState<IDeal | null>(null);
  const [unit, setUnit] = useState<IUnit | null>(null);
  const [project, setProject] = useState<IProject | null>(null);

  useQuery(GETDEAL, {
    variables: { _id: location.state ? location.state.dealId : '0' },
    onCompleted: (data) => {
      setDeal(data.dealById);
      setUnit(data.dealById.unit);
      setProject(data.dealById.project);
    },
    onError: (err: any) => {
      console.log(err, 'err');
      navigate(`/${projectid}/signing-portal`);
      storeDispatch(showErrorSnackbar('You currently do not have permissions to view this page'));
    },
  });

  return (
    <div>
      {project ? (
        <Box sx={{ p: 3, height: '100vh' }}>
          <ColourSelection
            client={true}
            unit={unit}
            filteredDeal={deal}
            updateDealDocuments={null}
            documents={null}
            setDocuments={null}
            project={project}
          />
        </Box>
      ) : null}
    </div>
  );
};

const GETDEAL = gql`
  query dealById($_id: MongoID!) {
    dealById(_id: $_id) {
      _id
      project {
        _id
        portal {
          primaryColor
        }
        decorModels {
          allowed
          modelType
        }
      }
      unit {
        _id
        suite
        unit
        level
        modelType
        basePrice
        size
        status
        getUrl
        putUrl
        bathroom
        exposure
        status
        outdoorSize
        outdoorType
        unitType
        originalPrice
        tier
        basePrice
        custom
      }
      purchasers {
        _id
        email
        firstName
        lastName
        identifications {
          _id
          name
          getUrl
        }
        corp
        sin
        dob
        streetAddress
        city
        province
        country
        postalCode
        occupation
        employer
        directors
        businessNumber
        signingOfficers {
          fullName
          dob
          sin
          primaryPhone
          streetAddress
          email
        }
        purchaserType
        primaryPhone
        idType
        idNumber
        idExpiry
        unit
        idJurisdiction
        getUrl
        putUrl
      }
      realtor {
        _id
        email
        firstName
        lastName
        brokerage
        streetAddress
        city
        province
        country
        postalCode
        brokeragePhone
        brokerageFax
        directPhone
        coopRates {
          type
          days
          date
          description
          percentage
          fixedAmount
        }
      }
      deposit {
        _id
        name
        amount
        dueDate
        accountNumber
        chequeNumber
        chequeDate
        chequeType
        chequeAmount
        deal {
          _id
        }
      }
      additionalDeposits {
        _id
        name
        amount
        dueDate
        accountNumber
        chequeNumber
        chequeDate
        chequeType
        chequeAmount
        deal {
          _id
        }
      }
      pendingDeposits {
        _id
        name
        amount
        dueDate
        accountNumber
        chequeNumber
        chequeAmount
        chequeDate
        chequeType
        deal {
          _id
        }
      }
      pendingOptions {
        name
        purchaseAmount
        amount
      }
      pendingUnit {
        basePrice
        suite
        _id
      }
      joinWaitlist
      options {
        name
        purchaseAmount
        amount
      }
      documents {
        _id
        name
        project {
          _id
        }
        status
        isAPS
        type
        dsEnvelopeId
        getUrl
        createdAt
        updatedAt
      }
      basePrice
      totalPrice
      tags
      mortgage {
        _id
        revisions
        lender
        amount
        getUrl
        putUrl
      }
      solicitor {
        solicitor
        firm
        streetAddress
        city
        province
        postalCode
        email
        primaryPhone
      }
      miscellaneous {
        _id
        name
        getUrl
      }
      rescission {
        dateRescinded
        reason
      }
      cancelled {
        dateCancelled
        type
        reason
      }
      salesRep {
        _id
        fullName
      }
      depositImages {
        _id
        name
        getUrl
      }
      thirdParty {
        _id
        fullName
        streetAddress
        dob
        primaryPhone
        email
        occupation
        corpNumber
        relationship
      }
      worksheet {
        _id
        status
      }
      adjustments {
        name
        type
        value
        description
        approval
      }
      construction {
        name
        type
        value
        description
        approval
        requiresChange
        tbd
        completed
      }
      upgradeSetOne {
        date
        salesRep {
          _id
          fullName
        }
        collectionTemplate {
          _id
          name
          upgradeTemplates {
            _id
            name
            price
            mainCategory
            subCategory
            lock
            hide
            unitsAllowed
            modelsAllowed
            sessions
            freeUpgrades
            upgradeType
          }
          primaryColor
          secondaryColor
          schemes
          unitsAllowed
          modelsAllowed
        }
        upgradeSet {
          _id
          name
          price
          mainCategory
          subCategory
          quantity
          upgradeTemplate {
            _id
            name
            price
            mainCategory
            subCategory
            lock
            hide
            unitsAllowed
            modelsAllowed
            sessions
            freeUpgrades
            getUrl
          }
        }
      }
      upgradeSetTwo {
        date
        salesRep {
          _id
          fullName
        }
        collectionTemplate {
          _id
          name
          upgradeTemplates {
            _id
            name
            price
            mainCategory
            subCategory
            lock
            hide
            unitsAllowed
            modelsAllowed
            sessions
            freeUpgrades
            upgradeType
          }
          primaryColor
          secondaryColor
          schemes
          unitsAllowed
          modelsAllowed
        }
        upgradeSet {
          _id
          name
          price
          mainCategory
          subCategory
          quantity
          upgradeTemplate {
            _id
            name
            price
            mainCategory
            subCategory
            lock
            hide
            unitsAllowed
            modelsAllowed
            sessions
            freeUpgrades
            getUrl
          }
        }
      }
      upgradeSetThree {
        date
        salesRep {
          _id
          fullName
        }
        collectionTemplate {
          _id
          name
          upgradeTemplates {
            _id
            name
            price
            mainCategory
            subCategory
            lock
            hide
            unitsAllowed
            modelsAllowed
            sessions
            freeUpgrades
            upgradeType
          }
          primaryColor
          secondaryColor
          schemes
          unitsAllowed
          modelsAllowed
        }
        upgradeSet {
          _id
          name
          price
          mainCategory
          subCategory
          quantity
          upgradeTemplate {
            _id
            name
            price
            mainCategory
            subCategory
            lock
            hide
            unitsAllowed
            modelsAllowed
            sessions
            freeUpgrades
            getUrl
          }
        }
      }
      draft
      preparedDate
      signDate
      executeDate
      firmDate
      createdAt
    }
  }
`;

export default DecorSelection;
