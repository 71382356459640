import React from 'react';
import { Box } from '@mui/material';

const Faqs = () => {
  return (
    <Box sx={{ p: 3 }}>
      <h1>Faqs/Help</h1>

      <h2>Create Project</h2>
      <p>1. Upload all information before launching the project including building images and logos. This includes executor, deposit info, options info, Coops, status (Include HL, HL2, HL3 as well)</p>

      <h2>Create/Edit Merges</h2>
      <p>1. If uploading the APS, upload pdf and save right away. You will get redirect to project settings, head to merge templates and insert the correct page number the merge field will be on. Also upload all relevant schedules pertaining to the APS and place page numbers. Afterwards, you can place the merge fields.</p>
      <p>2. To add merge fields, drag the hold the mouse down after clicking and drag the words from the left side and place it on the PDF</p>
      <p>3. When creating a new merge, upload the pdf, and immediately save the merge template before placing merge fields. In Project Settings, apply the correct page number to where the floorplans should appear in the APS. Then proceed to add merge fields to the APS</p>
      <p>4. Sign Fields cannot be deleted until the merge template is saved. (Currently being worked on)</p>
      <p>5. Protip: periodically save the merge fields after every page.</p>
      <p>6. You can change the font size and date type by clicking on the bottom arrow of the merge field words, then drag it on to the pdf</p>
      
      <h2>Worksheets</h2>
      <p>1. If a deal has been cancelled and the user restores the archived worksheet corresponding to the deal, the connection between that deal and the worksheet will be severed.</p>
      <p>2. There can only be one approved worksheet per unit at a time.</p>
      <p>3. Admins cannot archive worksheets</p>
      <p>4. Editing information on the worksheet will change the details on the worksheet that the realtor has submitted</p>
      <p>5. Realtors can only submit one worksheet per unit</p>

      <h2>Portal</h2>
      <p>1. When uploading renderings or images, you can click on "Save Renderings' or 'Save Pdfs', you will not need to click on update broker portal. The update broker portal is only for updating text on the portal (Or everything else that is not files)</p>
      <p>2. Compress all files before uploading. Images that are large in size will cause lag.</p>
      <p>3. Active Portal On will make the portal available for public. Realtors with accounts will always have access to every project as they need to be able to view their deals on it.</p>
      <p>4. Disabling the hotlist will remove the hotlist for public and all realtors with accounts.</p>

      <h2>Broker Coops</h2>
      <p>1. If sending multiple broker coops to a single realtor, create the broker coop template first, then create the broker coop documents for all their deals.(DO NOT SEND THE INDIVIDUALLY COOPS). After creating all the coops for all the deals. Search their realtor in the search bar and click on broker coops. Select all the coops that need to be sent. This will put all the coops in one pdf document for the realtor to sign.</p>
      <p>2. Sending 1 coops at a time is easy. Just create the document for the deal and send.</p>

      <h2>Deals</h2>
      <p>1. You can click void on a deal to cancel it. Deal to Active Deals. The void button means to cancel a deal, this means the deal has not been executed. You can void at any time before it goes conditional.</p>
      <p>2. The rescind button will show up on Deal to Active Deals. When they deal is conditional or firm. You can rescind the deal, please put a reason for the rescission as well.</p>
      <p>3. Changing the floorplan of the deal will change all the floorplans for the previous cancelled deals.</p>
      <p>4. You can edit the Coop values for the realtors on a per deal basis. You will also be able to add a realtor to the deal if you accidently chose public on deal creation. If you add the realtor after deal has been sent, you will need to send the realtor a copy of the APS manually. That's your problem.</p>
      <p>5. Unit transfer is self explanatory. You will need to create the unit transfer template to proceed. After going through the unit transfer process, you will see the unit transfer document show up on APS/Amendments section. Preview the unit transfer before it gets sent out. After it gets executed, the unit will automatically transfer and the current unit will go back to Available.</p>

      <h2>Price Grid</h2>
      <p>1. You will only be able to download the stacking view of the grid.</p>
      <p>2. There are three places to edit a unit information. You can click on the unit then flag then edit unit information. Or you can click on "Select Units" select all the units you want to edit, then click on edit selected. Or you can edit directly on the stacking view. (Provided there is one for the project). By double clicking the price, status or allocation.</p>
      <p>3. <strong>If you want to allocate a unit to show when the realtor accesses their account, you will have to click on the realtor name from the drop down menu.</strong></p>
      <p>4. The price grid will live update for all changes made.</p>
      <p>5. If you need to create a deal for a unit, you will have to change the status from "P" to "A". In case you were already creating the deal and exited the page.</p>
    </Box>
  )
}

export default Faqs
