import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { selectProject } from '../../features/project/projectSlice';
import { selectUser } from '../../features/auth/authSlice';
import { accessAllowed } from '../../features/project/projectHooks';

const RestrictedRoute = ({component: Component, restriction, children, ...rest} : any) => {
  const project = useSelector(selectProject);
  const user = useSelector(selectUser);

  if (user && !accessAllowed(user, project._id!, restriction)) {
    return <Navigate to={`/${project._id}/dashboard`} />
  } else if (!user) {
    return <Navigate to="/" />
  } else return children
};

export default RestrictedRoute;