import { useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { gql, useMutation } from '@apollo/client';
import { Box, Button, Typography, Checkbox, FormControlLabel } from '@mui/material';
import { FlexEnd } from '../../../commonStyles';
import { useAppDispatch } from '../../../app/hooks';
import { selectProject } from '../../../features/project/projectSlice';
import { showErrorSnackbar, showSuccessSnackbar } from '../../../features/snackbar/snackbarSlice';
import { IDraft } from '../../../types/unit';
import { PriceGridContext } from '../../../context/PriceGridContext';
import { useCreateActivity } from '../../../features/activity/activityHooks';
import { selectUser } from '../../../features/auth/authSlice';

const PublishPriceList = () => {
  const storeDispatch = useAppDispatch();
  const createActivity = useCreateActivity();
  const project = useSelector(selectProject);
  const user = useSelector(selectUser);
  const { selectedPriceList, setSelectedPriceList, units, setModalType } = useContext(PriceGridContext);

  const [confirmDraft, setConfirmDraft] = useState<boolean>(false);

  const [publishPriceList] = useMutation(PUBLISH, {
    onCompleted: (data) => {
      createActivity({
        project: project._id,
        user: user._id,
        deal: null,
        title: `Published ${selectedPriceList?.name}`,
        content: `Published ${selectedPriceList?.name}`,
      });
      storeDispatch(showSuccessSnackbar('Draft has been published!'));
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar('There is an error publishing draft'));
      console.log(err, 'err');
    },
  });

  const publishDraft = () => {
    if (!confirmDraft) return storeDispatch(showErrorSnackbar('Checkbox is not checked'));
    const unitArray = units.map((unit: IDraft) => {
      return {
        _id: unit.unitId,
        status: unit.status,
        allocation: null,
        allocatedDate: null,
        basePrice: unit.basePrice,
        history: null,
        modelType: unit.modelType,
        unitType: unit.unitType,
        size: unit.size,
        exposure: unit.exposure,
        bathroom: unit.bathroom,
        removeAllocations: false,
      };
    });
    publishPriceList({ variables: { project: project._id, unitArray } });
  };

  return (
    <Box>
      <Typography sx={{ mb: 2 }} variant={'h5'}>
        <strong>Publish Draft</strong>
      </Typography>
      <Box sx={{ my: 2 }}>
        <strong style={{ color: 'red ' }}>WARNING!</strong> By publishing this draft, all changes will be overwritten. Only statuses and
        pricing will be transferred over.
      </Box>
      <Box>
        <FormControlLabel
          control={<Checkbox color="primary" checked={confirmDraft} onClick={() => setConfirmDraft(!confirmDraft)} />}
          label={'By clicking this checkbox, you confirm that this draft is finalized and ready to be published.'}
        />
      </Box>
      <FlexEnd sx={{ mt: 2 }}>
        <Button
          variant="contained"
          color="info"
          sx={{ mr: 1 }}
          onClick={() => {
            if (selectedPriceList) {
              setSelectedPriceList(null);
            }
            setModalType('');
          }}
        >
          Cancel
        </Button>
        <Button variant="contained" color="success" onClick={() => publishDraft()}>
          Publish Draft
        </Button>
      </FlexEnd>
    </Box>
  );
};

const PUBLISH = gql`
  mutation unitUpdateManyInfo($unitArray: [NewUnitInput!], $project: MongoID!) {
    unitUpdateManyInfo(unitArray: $unitArray, project: $project) {
      _id
      allocation {
        fullName
        _id
      }
      allocatedDate
      tempAllocation
      basePrice
      status
      modelType
      unitType
      size
      exposure
      bathroom
      outdoorSize
      worksheets {
        _id
      }
    }
  }
`;

export default PublishPriceList;
