import { useState } from 'react';
import { Grid, Autocomplete, TextField } from '@mui/material';
import TextInput from '../../common/formControls/TextInput';
import { IRealtorInfo, IRealtorsArray } from '../../../types/CreateDealForm';
import { gql, useLazyQuery } from '@apollo/client';

const RealtorForm = (props: ChildProps) => {
  const { realtorInfo, handleTextInput, index, aliases, handleSearchInput } = props;
  const [realtors, setRealtors] = useState<IRealtorInfo[]>([]);

  const [getRealtors, { loading: realtorLoading }] = useLazyQuery<IRealtorsArray>(REALTORS, {
    onCompleted: (data) => {
      setRealtors(data.realtorMany);
    },
  });


  const handleSearch = (e: any, value: string) => {
    if (value) {
      getRealtors({ variables: { filter: { search: value } } });
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
        <TextInput
          title={'Realtor First Name'}
          id={'firstName'}
          name={'firstName'}
          handleTextInput={(e: any) => handleTextInput(e, index)}
          value={realtorInfo.firstName}
          required={true}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
        <TextInput
          title={'Realtor Last Name'}
          id={'lastName'}
          name={'lastName'}
          handleTextInput={(e: any) => handleTextInput(e, index)}
          value={realtorInfo.lastName}
          required={true}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
        <TextInput
          title={'Brokerage Name'}
          id={'brokerage'}
          name={'brokerage'}
          handleTextInput={(e: any) => handleTextInput(e, index)}
          value={realtorInfo.brokerage}
          required={true}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
        <TextInput
          title={'Realtor Email'}
          type={'email'}
          id={'email'}
          name={'email'}
          handleTextInput={(e: any) => handleTextInput(e, index)}
          value={realtorInfo.email}
          required={true}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
        <TextInput
          title={'Brokerage Address'}
          id={'streetAddress'}
          name={'streetAddress'}
          handleTextInput={(e: any) => handleTextInput(e, index)}
          value={realtorInfo.streetAddress}
          required={true}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
        <TextInput
          title={'Brokerage City'}
          id={'city'}
          name={'city'}
          handleTextInput={(e: any) => handleTextInput(e, index)}
          value={realtorInfo.city}
          required={true}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
        <TextInput
          title={'Brokerage Province'}
          id={'province'}
          name={'province'}
          handleTextInput={(e: any) => handleTextInput(e, index)}
          value={realtorInfo.province}
          required={true}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
        <TextInput
          title={'Brokerage Country'}
          id={'country'}
          name={'country'}
          handleTextInput={(e: any) => handleTextInput(e, index)}
          value={realtorInfo.country}
          required={true}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
        <TextInput
          title={'Brokerage Postal Code'}
          id={'postalCode'}
          name={'postalCode'}
          handleTextInput={(e: any) => handleTextInput(e, index)}
          value={realtorInfo.postalCode}
          required={true}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
        <TextInput
          title={'Brokerage Phone Number'}
          id={'brokeragePhone'}
          name={'brokeragePhone'}
          handleTextInput={(e: any) => handleTextInput(e, index)}
          value={realtorInfo.brokeragePhone}
          required={true}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
        <TextInput
          title={'Realtor Phone Number'}
          id={'directPhone'}
          name={'directPhone'}
          handleTextInput={(e: any) => handleTextInput(e, index)}
          value={realtorInfo.directPhone}
          required={true}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
        <TextInput
          title={'Brokerage Fax'}
          id={'brokerageFax'}
          name={'brokerageFax'}
          handleTextInput={(e: any) => handleTextInput(e, index)}
          value={realtorInfo.brokerageFax}
          required={false}
        />
      </Grid>
      {aliases ? (
        <Grid item xs={12} lg={6}>
          <Autocomplete
            sx={{
              width: '100%',
              '& .MuiFormLabel-asterisk': {
                color: 'red',
              },
            }}
            multiple
            id={'search'}
            disableClearable
            freeSolo={false}
            options={realtors}
            getOptionLabel={(option) => {
              return `${option.fullName} (${option.email})`;
            }}
            onInputChange={(e, value, reason) => {
              handleSearch(e, value);
            }}
            noOptionsText={realtorLoading ? `Loading...` : 'No Options'}
            isOptionEqualToValue={(option, value) => option === value}
            value={realtorInfo.aliases}
            onChange={handleSearchInput}
            renderInput={(params) => <TextField required={false} {...params} size="medium" label={'Realtor Aliases'} />}
          />
        </Grid>
      ) : null}
    </Grid>
  );
};

interface ChildProps {
  realtorInfo: IRealtorInfo;
  handleTextInput: any;
  index?: number;
  aliases?: boolean;
  handleSearchInput?: any;
}

const REALTORS = gql`
  query realtorMany($filter: FilterFindManyRealtorInput) {
    realtorMany(filter: $filter, limit: 10000) {
      _id
      email
      fullName
      firstName
      lastName
      disable
    }
  }
`;

export default RealtorForm;
