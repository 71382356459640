import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../app/store";

const initialState: ModalState = {
  open: false,
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    handleModal: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    },
  },
});

export const { handleModal } = modalSlice.actions;

export const selectOpenModal = (state: RootState) => state.modal.open;

interface ModalState {
  open: boolean;
}

export default modalSlice.reducer;
