/* Dependency Imports */
import { useState, useEffect } from 'react';
import { Box, Button, ToggleButton, ToggleButtonGroup, Paper, Grid } from '@mui/material';
import { gql, useLazyQuery } from '@apollo/client';

/* Project Imports */
import { ISchedule, IScheduleMany } from '../../../types/schedule';
import { convertAllDates, capitalizeFirstLetter } from '../../../utils/Functions';
import { useSelector } from 'react-redux';
import { selectProject } from '../../../features/project/projectSlice';
import Typography from '@mui/material/Typography/Typography';
import { FlexBetween } from '../../../commonStyles';
import CreateSchedule from './CreateSchedule';

const today = new Date();
today.setHours(0, 0, 0, 0);

const AppointmentSettings = () => {
  /* Props */
  const project = useSelector(selectProject);
  const [schedules, setSchedules] = useState<ISchedule[]>([]);
  const [tabValue, setTabValue] = useState<string>('regular');
  const [selectedSchedule, setSelectedSchedule] = useState<ISchedule | null>(null);
  const [scheduleView, setScheduleView] = useState<string>('list');

  /* Contexts */

  /* States */
  // const [locations, setLocations] = useState<string[]>(project.appointmentSettings.locations);
  // const [questions, setQuestions] = useState<IQuestion[]>([]);

  /* Effects */
  // useEffect(() => {
  //   setLocations(project.appointmentSettings.locations);
  //   setQuestions(project.appointmentSettings.questions);
  // }, [project.appointmentSettings]);

  /* Queries */
  const [getSchedules] = useLazyQuery<IScheduleMany>(GETSCHEDULES, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      setSchedules(data.scheduleMany);
    },
    onError: (error) => {
      console.log(error, 'err');
    },
  });

  useEffect(() => {
    getSchedules({ variables: { filter: { project: project._id, type: tabValue }, sort: 'DATE_START_ASC' } });
  }, [tabValue]);

  const handleTabChange = (e: any, value: string) => {
    if (value !== null) {
      setTabValue(value);
    }
  };

  const handleScheduleView = (type: string, schedule: ISchedule | null = null) => {
    setSelectedSchedule(schedule);
    setScheduleView(type);
  };

  const getDates = (schedule: ISchedule, type: string) => {
    let schedules: any[] = schedule.schedules.map((schedule: any) => new Date(schedule.date));
    if (type === 'max') {
      return convertAllDates(new Date(Math.max.apply(null, schedules)), 'PP');
    } else {
      return convertAllDates(new Date(Math.min.apply(null, schedules)), 'PP');
    }
  };

  return (
    <Box sx={{ py: 2 }}>
      <Box>
        {scheduleView === 'list' ? (
          <Box>
            <Typography variant={'h5'} gutterBottom>
              <strong>Appointments</strong>
            </Typography>
            <FlexBetween>
              <ToggleButtonGroup
                value={tabValue}
                exclusive
                onChange={handleTabChange}
                aria-label="text alignment"
                sx={{
                  '& .MuiToggleButton-root.Mui-selected': {
                    backgroundColor: '#00142a',
                    color: '#fff',
                  },
                  '& .MuiToggleButton-root.Mui-selected:hover': {
                    backgroundColor: '#00142a',
                    color: '#fff',
                  },
                }}
              >
                <ToggleButton value="regular" aria-label="centered">
                  Regular
                </ToggleButton>
                <ToggleButton value="colourSelection" aria-label="right aligned">
                  Decor
                </ToggleButton>
              </ToggleButtonGroup>
              <Button onClick={() => handleScheduleView('createEdit', null)} variant="contained" color="primary">
                Create {tabValue === 'colourSelection' ? 'Decor' : 'Regular'} Schedule
              </Button>
            </FlexBetween>
            <Grid spacing={2} container sx={{ mt: 2 }}>
              {schedules.map((schedule: any) => {
                return (
                  <Grid item sm={6} md={4} lg={3} xl={2}>
                    <Paper
                      elevation={3}
                      sx={{
                        transition: 'transform 0.5s ease-in-out',
                        '&:hover': {
                          transform: 'scale(1.03)',
                        },
                      }}
                    >
                      <Box onClick={() => handleScheduleView('createEdit', schedule)} sx={{ p: 2, cursor: 'pointer' }}>
                        <Typography variant={'h3'}>
                          <strong>{schedule.name}</strong>
                        </Typography>
                        <Box>
                          {getDates(schedule, 'min')} - {getDates(schedule, 'max')}
                        </Box>
                        <Box>
                          Active: <strong style={{ color: schedule.active ? 'green' : 'red' }}>{schedule.active ? 'True' : 'False'}</strong>
                        </Box>
                        <Box>
                          Allow Guests:{' '}
                          <strong style={{ color: schedule.allowGuests ? 'green' : 'red' }}>
                            {schedule.allowGuests ? 'True' : 'False'}
                          </strong>
                        </Box>
                        <Box>
                          Notification:{' '}
                          <strong style={{ color: schedule.notification ? 'green' : 'red' }}>
                            {schedule.notification ? 'True' : 'False'}
                          </strong>
                        </Box>
                        <Box>
                          Public Access:{' '}
                          <strong style={{ color: schedule.public ? 'green' : 'red' }}>{schedule.public ? 'True' : 'False'}</strong>
                        </Box>
                        <Box>
                          Realtor Access: <strong>{capitalizeFirstLetter(schedule.access)}</strong>
                        </Box>
                      </Box>
                    </Paper>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        ) : (
          <CreateSchedule
            schedules={schedules}
            setSchedules={setSchedules}
            tabValue={tabValue}
            schedule={selectedSchedule}
            setScheduleView={setScheduleView}
          />
        )}
      </Box>
    </Box>
  );
};

/* Queries */
const GETSCHEDULES = gql`
  query scheduleMany($filter: FilterFindManyScheduleInput, $sort: SortFindManyScheduleInput!) {
    scheduleMany(filter: $filter, sort: $sort) {
      _id
      active
      allowGuests
      maxGuests
      notification
      public
      name
      schedules {
        date
        length
        timeBetweenAppointments
        timeStart
        timeEnd
        numberOfAppointments
      }
      questions {
        _id
        name
        question
        type
        subQuestion
        display
        choices {
          choice
          followUp {
            question
            _id
          }
        }
      }
      locations
      type
      access
      mainText
      endText
      emailMainText
      emailEndText
      emailSubject
      cancelMainText
      cancelEndText
      cancelSubject
      confirmMainText
      confirmEndText
      confirmSubject
      emails
      hideLocation
      shortUrl
      virtual
    }
  }
`;

export default AppointmentSettings;
