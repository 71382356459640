import React, { useState } from 'react';
import { useTable } from 'react-table';
import { Checkbox } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

function Table({ columns, data, children, handleNestedData, handleChange, checkbox }: any) {
  const [open, setOpen] = useState(false);
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  });

  const toggleRowOpen = (id: boolean) => {
    handleNestedData(id);
    if (open === id) {
      setOpen(false);
    } else {
      setOpen(id);
    }
  };

  // Render the UI for your table
  return (
    <table
      {...getTableProps({
        style: {
          width: '100%',
          borderCollapse: 'collapse',
        },
      })}
    >
      <thead>
        {headerGroups.map((headerGroup: any) => (
          <React.Fragment key={headerGroup.headers.length + '_hfrag'}>
            <tr
              {...headerGroup.getHeaderGroupProps({
                style: {
                  textAlign: 'left',
                },
              })}
            >
              <th style={{ width: '50px' }} colSpan={1}></th>
              {headerGroup.headers.map((column: any) => (
                <th
                  style={{
                    padding: '10px',
                  }}
                  {...column.getHeaderProps()}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          </React.Fragment>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row: any, i: any) => {
          prepareRow(row);
          return (
            <React.Fragment key={i + '_frag'}>
              <tr {...row.getRowProps()}>
                {handleNestedData ? (
                  <td
                    style={{
                      border: '1px solid #000',
                      borderSpacing: '0px',
                      padding: '0 10px',
                    }}
                  >
                    <span id={row.original.id} onClick={() => toggleRowOpen(row.original.id)}>
                      {open === row.original.id ? (
                        <ChevronLeftIcon style={{ cursor: 'pointer', textAlign: 'center', paddingTop: '5px' }} />
                      ) : (
                        <ChevronRightIcon style={{ cursor: 'pointer', textAlign: 'center', paddingTop: '5px' }} />
                      )}
                    </span>
                  </td>
                ) : checkbox ? (
                  <td
                    style={{
                      border: '1px solid #000',
                      borderSpacing: '0px',
                      padding: '0 10px',
                    }}
                    colSpan={1}
                  >
                    <Checkbox
                      checked={row.original.checked}
                      onChange={(e: any) => handleChange(e, row.id)}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  </td>
                ) : (
                  <td
                    style={{
                      border: '1px solid #000',
                      borderSpacing: '0px',
                      padding: '20px 0',
                    }}
                    colSpan={1}
                  ></td>
                )}
                {row.cells.map((cell: any) => {
                  return (
                    <td
                      style={{
                        border: '1px solid #000',
                        borderSpacing: '0px',
                        padding: '0 10px',
                      }}
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
              {open === row.original.id && (
                <tr>
                  <td colSpan={7}>
                    {children}
                    {/* React.cloneElement(children, {props: "any necessary props here"}) */}
                  </td>
                </tr>
              )}
            </React.Fragment>
          );
        })}
      </tbody>
    </table>
  );
}

const TreeTable = (props: ChildProps) => {
  const { data, columns, nestedData, nestedColumns, handleNestedData, handleChange, checkbox } = props;
  const [expanded, setExpanded] = useState({});

  return (
    <Table columns={columns} data={data} handleNestedData={handleNestedData}>
      {nestedData.length > 0 ? (
        <div style={{ paddingLeft: '50px', margin: '20px 0' }}>
          <Table columns={nestedColumns} data={nestedData} handleChange={handleChange} checkbox={checkbox}>
            Content!
          </Table>
        </div>
      ) : null}
    </Table>
  );
};

interface ChildProps {
  data: any;
  columns: any;
  nestedColumns: any;
  nestedData: any;
  handleNestedData: any;
  handleChange?: any;
  checkbox: boolean;
  // headers: string[];
  // defaultSort?: string;
  // formattedHeaders?: string[];
  // filter?: any;
  // setFilter?: any;
  // rows: T[];
  // span?: { [key: string]: number };
  // rowOnClick?: (row: T) => void;
  // cellOnClick?: (row: T, header: any) => void;
  // sticky?: string[];
  // checked?: string;
  // setChecked?: (selected: any) => void;
  // setParentRows?: (rows: any) => void;
  // cellBackgrounds?: { [key: string]: string }[];
  // allColumns: any;
  // width: number;
  // headerWidth: number;
  // hideHeaders: boolean;
}

export default TreeTable;
