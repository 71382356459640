import { useContext } from 'react';
import { Button, styled, Box } from '@mui/material';
import { numToCurrency, convertAllDates, numToCurrencyDecimal } from '../../utils/Functions';
import { CreateDealContext } from '../../context/CreateDealContext';
import { IDealOption, IDealDeposit } from '../../types/CreateDealForm';
import { IPurchaserInfo } from '../../types/CreateDealForm';
import CustomMerge from './CustomMerge';

const FormSummary = () => {
  const {
    purchaserInfo,
    realtorInfo,
    suiteInfo,
    noRealtor,
    selectedUnit,
    activeStep,
    setOpenCustom,
    openCustom,
    worksheetNotes,
    worksheetId,
    rental,
  } = useContext(CreateDealContext);

  let totalOptions = suiteInfo.options.reduce((a: any, b: any) => {
    return a + b.amount;
  }, 0);

  return (
    <div>
      {selectedUnit ? (
        !openCustom ? (
          <Box
            sx={{
              minWidth: '340px',
              padding: '10px',
              fontSize: '14px',
              backgroundColor: '#fff',
              boxShadow: '3px 0 5px -2px rgba(0,0,0,0.5)',
              position: 'sticky',
              left: 0,
              top: 0,
              height: '100vh',
              minHeight: 'calc(100vh - 64px)',
              overflow: 'auto',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Box>
              <InfoDisplay
                sx={{
                  textAlign: 'center',
                  fontSize: '16px',
                  fontWeight: 'bold',
                }}
              >
                Suite {selectedUnit.suite}
              </InfoDisplay>
              {worksheetNotes ? (
                <InfoDisplay sx={{ maxWidth: '340px' }}>
                  Worksheet Notes: <strong>{worksheetNotes}</strong>
                </InfoDisplay>
              ) : null}
              <InfoDisplay>
                <em>
                  <strong>Suite Info</strong>
                </em>
              </InfoDisplay>
              <InfoDisplay>
                Level: <strong>{selectedUnit.level}</strong>
              </InfoDisplay>
              <InfoDisplay>
                Unit: <strong>{selectedUnit.unit}</strong>
              </InfoDisplay>
              <InfoDisplay>
                Model: <strong>{selectedUnit.modelType}</strong>
              </InfoDisplay>
              <InfoDisplay>
                Type: <strong>{selectedUnit.unitType}</strong>
              </InfoDisplay>
              {selectedUnit.maintenanceFee ? (
                <InfoDisplay>
                  Maintenance Fee: <strong>{numToCurrencyDecimal.format(selectedUnit.maintenanceFee)}</strong>
                </InfoDisplay>
              ) : null}
              <InfoDisplay>
                Unit Price: <strong>{numToCurrency.format(worksheetId ? selectedUnit.unitPrice : selectedUnit.basePrice)}</strong>
              </InfoDisplay>
              {worksheetId ? (
                <InfoDisplay>
                  Worksheet Price: <strong>{numToCurrency.format(selectedUnit.basePrice)}</strong>
                </InfoDisplay>
              ) : null}
              <InfoDisplay>
                Total Price: <strong>{numToCurrency.format(selectedUnit.basePrice + totalOptions)}</strong>
              </InfoDisplay>
              {selectedUnit.custom ? (
                <InfoDisplay>
                  Custom: <strong>{selectedUnit.custom}</strong>
                </InfoDisplay>
              ) : null}
              {selectedUnit.rental ? (
                <InfoDisplay>
                  Rental: <strong>{numToCurrency.format(rental)}</strong>
                </InfoDisplay>
              ) : null}
              {purchaserInfo.map((purchaser: IPurchaserInfo, index: number) => {
                return (
                  <div key={index}>
                    <InfoDisplay>
                      <em>
                        <strong>{purchaser.corp ? 'Corporation' : 'Purchaser'}</strong>
                      </em>
                    </InfoDisplay>
                    <InfoDisplay>
                      First Name: <strong>{purchaser.firstName}</strong>
                    </InfoDisplay>
                    {!purchaser.corp ? (
                      <InfoDisplay>
                        Last Name: <strong>{purchaser.lastName}</strong>
                      </InfoDisplay>
                    ) : null}
                    <InfoDisplay>
                      Address:{' '}
                      <strong>
                        {purchaser.unit ? `${purchaser.unit}` : ''} {purchaser.streetAddress ? `${purchaser.streetAddress},` : ''}{' '}
                        {purchaser.city ? `${purchaser.city},` : ''} {purchaser.province ? `${purchaser.province},` : ''}{' '}
                        {purchaser.postalCode}
                      </strong>
                    </InfoDisplay>
                    <InfoDisplay>
                      Phone Number: <strong>{purchaser.primaryPhone}</strong>
                    </InfoDisplay>
                    <InfoDisplay>
                      Email: <strong>{purchaser.email}</strong>
                    </InfoDisplay>
                    <InfoDisplay>
                      Dob: <strong>{convertAllDates(purchaser.dob, 'yyyy/MM/dd')}</strong>
                    </InfoDisplay>
                    {!purchaser.corp ? (
                      <InfoDisplay>
                        SIN: <strong>{purchaser.sin ? purchaser.sin : 'None'}</strong>
                      </InfoDisplay>
                    ) : null}
                  </div>
                );
              })}
              <InfoDisplay>
                <em>
                  <strong>Realtor Info</strong>
                </em>
              </InfoDisplay>
              {!noRealtor.noRealtor ? (
                <>
                  <InfoDisplay>
                    Name:{' '}
                    <strong>
                      {realtorInfo.firstName} {realtorInfo.lastName}
                    </strong>
                  </InfoDisplay>
                  <InfoDisplay>
                    Email: <strong>{realtorInfo.email}</strong>
                  </InfoDisplay>
                </>
              ) : (
                <>
                  <InfoDisplay>
                    No Realtor Type: <strong>{noRealtor.noRealtorType}</strong>
                  </InfoDisplay>
                </>
              )}
              <InfoDisplay>
                <em>
                  <strong>Deposit/Options Info</strong>
                </em>
              </InfoDisplay>
              {suiteInfo.options.length > 0 ? (
                suiteInfo.options.map((option: IDealOption, index: number) => {
                  return (
                    <InfoDisplay key={index}>
                      <div>
                        {option.name}:{' '}
                        <strong>
                          {option.purchaseAmount}x at {numToCurrency.format(option!.amount!)}
                        </strong>
                      </div>
                    </InfoDisplay>
                  );
                })
              ) : (
                <InfoDisplay>No Options Added</InfoDisplay>
              )}
              {suiteInfo.deposit.length > 0
                ? suiteInfo.deposit.map((deposit: IDealDeposit, index: number) => {
                    return (
                      <InfoDisplay key={index}>
                        <div>
                          {deposit.name}: <strong>{numToCurrency.format(deposit.amount!)}</strong>
                        </div>
                      </InfoDisplay>
                    );
                  })
                : null}
            </Box>
            {activeStep === 4 ? (
              <InfoDisplay sx={{ mb: 1, ml: 1 }}>
                <Button onClick={() => setOpenCustom(!openCustom)} variant="contained" color="primary">
                  Add Custom Text
                </Button>
              </InfoDisplay>
            ) : null}
          </Box>
        ) : (
          <Box
            sx={{
              minWidth: '340px',
              padding: '10px',
              fontSize: '14px',
              backgroundColor: '#fff',
              boxShadow: '3px 0 5px -2px rgba(0,0,0,0.5)',
              left: 0,
              top: 0,
              height: '100vh',
              minHeight: 'calc(100vh - 64px)',
              overflow: 'auto',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <CustomMerge />
          </Box>
        )
      ) : null}
    </div>
  );
};

const InfoDisplay = styled(Box)`
  word-break: break-word;
  margin-bottom: 10px;
`;

export default FormSummary;
