import { Box, Paper } from '@mui/material';

import { IUpgradeTemplate } from '../../../types/colourSelection';
import { numToCurrency } from '../../../utils/Functions';
import { IUnit } from '../../../types/unit';

const UpgradeCard = (props: ChildProps) => {
  const { upgrade } = props;

  return (
    <Paper sx={{ p: 2, cursor: 'pointer', height: '100%' }} elevation={12}>
      <Box>
        <strong>{upgrade.name}</strong>
      </Box>
      <Box>
        <strong>{numToCurrency.format(upgrade.price)}</strong>
      </Box>
      <Box sx={{ mt: 1 }}>
        Main: <strong>{upgrade.mainCategory}</strong>
      </Box>
      <Box>
        Sub: <strong>{upgrade.subCategory}</strong>
      </Box>
      {upgrade.unitsAllowed.length && !upgrade.modelsAllowed.length ? (
        <Box>
          Allowed Unit Types: <strong>{upgrade.unitsAllowed.map((unitType: string) => unitType).join(', ')}</strong>
        </Box>
      ) : null}
      {upgrade.modelsAllowed.length ? (
        <Box>
          Allowed Model Types: <strong>{upgrade.modelsAllowed.map((modelType: string) => modelType).join(', ')}</strong>
        </Box>
      ) : null}
      {upgrade.suitesAllowed.length ? (
        <Box>
          Allowed Suites: <strong>{upgrade.suitesAllowed.map((unit: IUnit) => unit.suite).join(', ')}</strong>
        </Box>
      ) : null}
    </Paper>
  );
};

interface ChildProps {
  upgrade: IUpgradeTemplate;
}

export default UpgradeCard;
