import { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Flex } from '../../../../commonStyles';
import Dropdown from '../../../common/formControls/Dropdown';
import { selectMerges } from '../../../../features/merge/mergeSlice';
import { useAppDispatch } from '../../../../app/hooks';
import { IMerge } from '../../../../types/merge';
import { CreateDealContext } from '../../../../context/CreateDealContext';
import { IMergeTemplates } from '../../../../types/project';
import { showErrorSnackbar } from '../../../../features/snackbar/snackbarSlice';
import { selectProject } from '../../../../features/project/projectSlice';
import { IAutoDocuments } from '../../../../types/CreateDealForm';

const CreateForm = () => {
  const {
    selectedAps,
    setSelectedAps,
    selectedMergeTemplates,
    setSelectedMergeTemplates,
    setAutoDocuments,
    autoDocuments
  } = useContext(CreateDealContext);
  const storeDispatch = useAppDispatch();
  const merges = useSelector(selectMerges);
  const project = useSelector(selectProject);

  const [apsNames, setAPSNames] = useState<string[]>([]);
  const [mergeNames, setMergeNames] = useState<string[]>([]);
  const [mergeArray, setMergeArray] = useState<IMergeTemplates[]>([]);

  useEffect(() => {
    arrayOfMerges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merges]);

  useEffect(() => {
    if (selectedAps) {
      let documentsAttachToAps = project.mergeTemplates
        .filter((mergeTemplates: any) => {
          let documentTemplates = mergeTemplates.apsTemplates.find(
            (apsTemplate: any) => apsTemplate.apsTemplate._id === selectedAps._id && apsTemplate.attachToAps === 'back'
          );
          return documentTemplates;
        })
        .map((documents: any) => {
          return {
            name: documents.name,
            mergeTemplate: documents.mergeTemplate._id,
            file: null,
            totalPages: documents.mergeTemplate.totalPages,
          };
        });
      setAutoDocuments(documentsAttachToAps);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAps]);

  const arrayOfMerges = () => {
    let aps = merges
      .filter((mergeFields: IMerge) => mergeFields.type === 'APS')
      .reverse()
      .sort((b: any, a: any) => a.default - b.default);
    let apsNames = aps.map((merge: IMerge) => merge.name);
    let otherNames = merges.filter((mergeFields: IMerge) => mergeFields.type !== 'APS').map((merge: any) => merge.name);

    setSelectedAps(aps[0]);
    setAPSNames(apsNames);
    setMergeNames(otherNames);
  };


  const handleDropdownInput = (event: React.ChangeEvent<{ name?: string; value: string }>) => {
    let mergeArray = merges.find((mergeTemplate: IMerge) => mergeTemplate.name === event.target.value);
    let checkMergeArray = selectedMergeTemplates.some((merge: IMerge) => mergeArray?.name === merge.name);
    if (checkMergeArray) {
      storeDispatch(showErrorSnackbar('This template has already been added!'));
    } else {
      setSelectedMergeTemplates([...selectedMergeTemplates, mergeArray]);
    }
  };

  const handleAPSDropdownInput = (event: React.ChangeEvent<{ name?: string; value: string }>) => {
    let mergeArray = merges.find((mergeTemplate: IMerge) => mergeTemplate.name === event.target.value);
    setSelectedAps(mergeArray);
  };

  const handleDeleteMergeTemplate = (name: string) => {
    let selectedMergeArray = selectedMergeTemplates.filter((mergeTemplate: IMerge) => mergeTemplate.name !== name);
    let mergeTemplateArray = mergeArray.filter((merge: IMergeTemplates) => merge.name !== name);
    setMergeArray(mergeTemplateArray);
    setSelectedMergeTemplates(selectedMergeArray);
  };

  return (
    <div>
      <h2>Attach Amendments/Schedules</h2>
      <Flex sx={{ mt: 2 }}>
        <Box
          sx={{
            flexGrow: 1,
            mr: 1,
          }}
        >
          <Dropdown
            title={'Attached APS'}
            menuList={apsNames}
            name={'options'}
            handleSelect={(e: any) => handleAPSDropdownInput(e)}
            value={selectedAps ? selectedAps.name : ''}
          />
        </Box>
      </Flex>
      <Flex sx={{ mt: 2 }}>
        <Box
          sx={{
            flexGrow: 1,
            mr: 1,
          }}
        >
          <Dropdown
            title={'Attach Amendments or Schedules'}
            menuList={mergeNames}
            name={'options'}
            handleSelect={(e: any) => handleDropdownInput(e)}
            value={''}
          />
        </Box>
      </Flex>
      <Flex sx={{ my: 1 }}>
        {selectedMergeTemplates.map((merge: IMerge, index: number) => {
          if (merge.name === 'Floorplan') {
            return null;
          } else {
            return (
              <Box
                key={index}
                sx={{
                  padding: '10px',
                  border: '1px solid #00142a',
                  borderRadius: '8px',
                  display: 'flex',
                  flexWrap: 'wrap',
                  marginRight: '10px',
                }}
              >
                <span>{merge.name}</span>
                <CloseIcon sx={{ cursor: 'pointer', ml: 1 }} color="secondary" onClick={() => handleDeleteMergeTemplate(merge.name)} />
              </Box>
            );
          }
        })}
      </Flex>
      {autoDocuments.length > 0 ? (
        <>
          <h2>Documents that will be automatically added to the end of the APS envelope</h2>
          <Flex sx={{ my: 1 }}>
            {autoDocuments.map((document: IAutoDocuments, numIndex: number) => {
              return (
                <Box
                  key={numIndex}
                  sx={{
                    padding: '10px',
                    border: '1px solid #00142a',
                    borderRadius: '8px',
                    display: 'flex',
                    flexWrap: 'wrap',
                    marginRight: '10px',
                  }}
                >
                  <span>{document.name}</span>
                  <CloseIcon
                    sx={{ cursor: 'pointer', ml: 1 }}
                    color="secondary"
                    onClick={() => setAutoDocuments(autoDocuments.filter((document: string, index: number) => index !== numIndex))}
                  />
                </Box>
              );
            })}
          </Flex>
        </>
      ) : null}
    </div>
  );
};

export default CreateForm;
