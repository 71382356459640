import { useMemo } from 'react';
import Dropzone, { useDropzone } from 'react-dropzone';
import { Box, Button, Grid } from '@mui/material';
import PdfCard from './PdfCard';
import { baseStyle, activeStyle, acceptStyle, rejectStyle } from '../../utils/Constants';

const PortalImages = (props: ChildProps) => {
  const { handleDrop, saveMedia, images, deleteImages, title, type, handleEdit } = props;

  const { isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: 'image/gif, image/jpeg, image/png, application/pdf',
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <Box
      sx={{
        border: '1px solid #000',
        padding: '30px 20px',
        position: 'relative',
        borderRadius: '8px',
        marginBottom: '20px',
      }}
    >
      <h3>{title}</h3>
      <Box sx={{ mb: 3 }}>
        Upload any images here <strong>(Please compress images before uploading!!)</strong>
      </Box>
      <div>
        <Dropzone onDrop={(files) => handleDrop(files, type)} accept="image/gif, image/jpg, image/jpeg, image/png, application/pdf">
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <p>Drag and Drop or Upload Images</p>
              </div>
            </section>
          )}
        </Dropzone>
      </div>
      <div>
        <Grid
          container
          spacing={2}
        >
          {images.length > 0
            ? images.map((attachment: any, index: number) => {
                return (
                  <Grid key={index} item lg={3} md={4} sm={6} xs={12}>
                    <PdfCard
                      file={attachment.getUrl ? attachment.getUrl : attachment.url}
                      title={attachment.name ? attachment.name : attachment.file.name}
                      id={attachment._id}
                      handleDelete={deleteImages}
                      download={true}
                      index={index}
                      handleEdit={handleEdit}
                    />
                  </Grid>
                );
              })
            : null}
        </Grid>
        <Button sx={{ mt: 1 }} onClick={() => saveMedia(type)} name={'update'} color="success" variant="contained">
          Save {type}
        </Button>
    </div>
    </Box>
  );
};

interface ChildProps {
  title: string;
  type: string;
  handleDrop: any;
  saveMedia: (type: string) => void;
  images: any;
  deleteImages: any;
  handleEdit?: any;
}

export default PortalImages;
