import React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useProjectQuery } from '../../features/project/projectHooks';
import { usePortalColorQuery } from '../../features/portal/portalHooks';
import Header from '../menus/Header';
import { useSelector } from 'react-redux';
import { selectProject } from '../../features/project/projectSlice';

const DashboardOutlet = () => {
  const { projectid } = useParams();
  const project = useSelector(selectProject);

  const { loading } = useProjectQuery(projectid!, project._id);
  usePortalColorQuery(projectid!, project._id);

  return loading ? null : project ? (
    <div>
      <Header />
      <Outlet />
    </div>
  ) : null;
};

export default DashboardOutlet;
