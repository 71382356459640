import { Box, FormLabel, FormControl, FormGroup, FormControlLabel, Switch } from '@mui/material';

const EditColumns = (props: ChildProps) => {
  const { columns, setColumns } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setColumns({ ...columns, [event.target.name]: event.target.checked });
  };

  return (
    <Box
      sx={{
        backgroundColor: '#fff',
        border: '3px solid #000',
        paddingTop: '10px',
        paddingBottom: '40px',
        paddingRight: '30px',
        paddingLeft: '30px',
        height: '650px',
        width: '500px',
      }}
    >
      <Box
        sx={{
          justifyContent: 'center',
          display: 'flex',
          mb: 2,
        }}
      >
        <h1
          style={{
            textAlign: 'center',
            marginBottom: '0px',
            marginTop: '0px',
          }}
        >
          Edit Selected
        </h1>
      </Box>
      <FormControl component="fieldset">
        <FormLabel component="legend" sx={{ mb: 2 }}>
          Select the columns that you want displayed.
        </FormLabel>
        <FormGroup>
          <FormControlLabel
            control={<Switch checked={columns.unitNumber} onChange={handleChange} name="unitNumber" />}
            label="Unit Number"
          />
          <FormControlLabel
            control={<Switch checked={columns.originalPrice} onChange={handleChange} name="originalPrice" />}
            label="Original Price"
          />
          <FormControlLabel
            control={<Switch checked={columns.originalPPSF} onChange={handleChange} name="originalPPSF" />}
            label="Original Price Per Square Foot"
          />
          <FormControlLabel control={<Switch checked={columns.newPrice} onChange={handleChange} name="newPrice" />} label="New Price" />
          <FormControlLabel
            control={<Switch checked={columns.newPPSF} onChange={handleChange} name="newPPSF" />}
            label="New Price Per Square Foot"
          />
          <FormControlLabel control={<Switch checked={columns.status} onChange={handleChange} name="status" />} label="Status" />
          <FormControlLabel control={<Switch checked={columns.size} onChange={handleChange} name="size" />} label="Size" />
          <FormControlLabel
            control={<Switch checked={columns.outdoorSize} onChange={handleChange} name="outdoorSize" />}
            label="Outdoor Size"
          />
          <FormControlLabel control={<Switch checked={columns.exposure} onChange={handleChange} name="exposure" />} label="Exposure" />
          <FormControlLabel control={<Switch checked={columns.level} onChange={handleChange} name="level" />} label="Level" />
          <FormControlLabel control={<Switch checked={columns.modelType} onChange={handleChange} name="modelType" />} label="Model Type" />
          <FormControlLabel control={<Switch checked={columns.unitType} onChange={handleChange} name="unitType" />} label="Unit Type" />
          <FormControlLabel
            control={<Switch checked={columns.outdoorType} onChange={handleChange} name="outdoorType" />}
            label="Outdoor Type"
          />
          <FormControlLabel control={<Switch checked={columns.bathroom} onChange={handleChange} name="bathroom" />} label="Bathroom" />
        </FormGroup>
      </FormControl>
    </Box>
  );
};

interface ChildProps {
  columns: {
    unitNumber: boolean;
    originalPrice: boolean;
    originalPPSF: boolean;
    newPrice: boolean;
    newPPSF: boolean;
    status: boolean;
    size: boolean;
    outdoorSize: boolean;
    exposure: boolean;
    level: boolean;
    modelType: boolean;
    unitType: boolean;
    outdoorType: boolean;
    bathroom: boolean;
  };

  setColumns: (obj: {
    unitNumber: boolean;
    originalPrice: boolean;
    originalPPSF: boolean;
    newPrice: boolean;
    newPPSF: boolean;
    status: boolean;
    size: boolean;
    outdoorSize: boolean;
    exposure: boolean;
    level: boolean;
    modelType: boolean;
    unitType: boolean;
    outdoorType: boolean;
    bathroom: boolean;
  }) => void;
}

export default EditColumns;
