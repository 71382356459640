import { Dispatch, SetStateAction } from 'react';
import { Box, Button, Typography, Checkbox, FormControlLabel, Switch, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import GooglePlaces from '../../common/formControls/GooglePlaces';
import { FlexBetween } from '../../../commonStyles';

const Location = (props: ChildProps) => {
  const { locations, setLocations, hideLocation, setHideLocation, urlLink, setUrlLink, virtual, setVirtual } = props;

  const handleDetails = async (
    city: string,
    province: string,
    postalCode: string,
    country: string,
    streetAddress: string,
    type: number
  ) => {
    let updatelocations = locations.map((address: string, index: number) => {
      if (type === index) {
        return `${streetAddress}, ${city}, ${province}, ${postalCode}`;
      } else return address;
    });
    setLocations(updatelocations);
  };

  const handleStreetAddress = async (value: string, type: number) => {
    let updatelocations = locations.map((address: string, index: number) => {
      if (type === index) {
        return value;
      } else return address;
    });
    setLocations(updatelocations);
  };

  return (
    <Box>
      <FlexBetween sx={{ mb: 2 }}>
        <Box sx={{ display: 'flex' }}>
          <Typography variant={'h5'} sx={{ mb: 0 }} gutterBottom>
            <strong>Locations</strong>
          </Typography>
          <FormControlLabel
            sx={{ alignSelf: 'center', ml: 1 }}
            control={
              <Switch
                checked={virtual}
                onChange={() => {
                  setVirtual(!virtual);
                  setUrlLink('');
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label="Virtual"
          />
          {virtual ? (
            <FormControlLabel
              sx={{ alignSelf: 'center', ml: 1 }}
              control={
                <Switch
                  checked={urlLink ? true : false}
                  onChange={() => {
                    if (!urlLink) {
                      setUrlLink('zoom');
                      setLocations(['Creating Zoom Link on Submission']);
                    } else {
                      setUrlLink('');
                      setLocations(['']);
                    }
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label="Create Zoom Link"
            />
          ) : null}
        </Box>
        <Button onClick={() => setLocations([...locations, ''])} variant="contained" color="primary">
          Add Location
        </Button>
      </FlexBetween>
      {locations.length === 1 ? (
        <FormControlLabel
          style={{
            marginTop: '10px',
            marginBottom: '15px',
            display: 'flex',
            alignItems: 'center',
          }}
          key={`hideLocation`}
          control={<Checkbox checked={hideLocation} onChange={(e: any) => setHideLocation(!hideLocation)} />}
          label={`Hide ${virtual ? 'URL' : 'Location'} Until Reservation`}
        />
      ) : null}
      {locations.map((address: string, index: number) => {
        return virtual ? (
          <Box>
            <TextField
              title={'Virtual URL'}
              name={'virtualUrl'}
              fullWidth
              value={address}
              label={'Virtual URL'}
              required
              disabled={urlLink ? true : false}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleStreetAddress(e.target.value, index)}
            />
          </Box>
        ) : (
          <FlexBetween sx={{ mt: 2 }}>
            <Box sx={{ flexGrow: 1 }}>
              <GooglePlaces
                disabled={false}
                name={'streetAddress'}
                handleDetails={handleDetails}
                handleStreetAddress={handleStreetAddress}
                address={address}
                type={index}
                id={'appointment'}
              />
            </Box>
            <CloseIcon
              sx={{ cursor: 'pointer', alignSelf: 'center', ml: 2 }}
              onClick={() => setLocations(locations.filter((address: string, numIndex: number) => index !== numIndex))}
            />
          </FlexBetween>
        );
      })}
    </Box>
  );
};

interface ChildProps {
  locations: string[];
  setLocations: Dispatch<SetStateAction<string[]>>;
  hideLocation: boolean;
  setHideLocation: Dispatch<SetStateAction<boolean>>;
  urlLink: string;
  setUrlLink: Dispatch<SetStateAction<string>>;
  virtual: boolean;
  setVirtual: Dispatch<SetStateAction<boolean>>;
}

export default Location;
