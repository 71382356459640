import React from 'react';
import { Button } from '@mui/material';

const FileUpload = (props: ChildProps) => {
  return (
    <div>
      <input style={{ display: 'none' }} id="upload-document" name="upload-document" onChange={props.onChange} type="file" />
      <label htmlFor="upload-document">
        <Button color="primary" variant="contained" component="span">
          {props.title}
        </Button>
      </label>
    </div>
  );
};

interface ChildProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  title: string;
}

export default FileUpload;
