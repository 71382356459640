import { useState, useReducer, useEffect, useMemo } from 'react';
import { Buffer } from 'buffer';
import Dropzone, { useDropzone } from 'react-dropzone';
import axios from 'axios';
import {
  Box,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  Button,
  TextField,
  Autocomplete,
  Grid,
  InputAdornment,
  FormControlLabel,
  Switch,
  Checkbox
} from '@mui/material';
import { useMutation, gql, useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { HexColorPicker } from 'react-colorful';

import { useAppDispatch } from '../../../app/hooks';
import { selectProject } from '../../../features/project/projectSlice';
import TextInput from '../../common/formControls/TextInput';
import Upgrades from './Upgrades';
import Collections from './Collections';
import { FlexBetween, Flex } from '../../../commonStyles';
import { capitalizeFirstLetter, numToCurrency, sortSuites, uploadUpgrades } from '../../../utils/Functions';
import { GlobalModal } from '../../../features/modal/Modal';
import { handleModal } from '../../../features/modal/modalSlice';
import { mainCategories, subCategories, upgradeTypes, schemes } from '../../../utils/Constants';
import { showSuccessSnackbar, showErrorSnackbar } from '../../../features/snackbar/snackbarSlice';
import { IUnitData, IUnit } from '../../../types/unit';
import LoadingWrapper from '../../common/LoadingWrapper';
import { IUpgradeTemplate, ICollection } from '../../../types/colourSelection';
import { baseStyle, activeStyle, acceptStyle, rejectStyle } from '../../../utils/Constants';
import PdfCard from '../../common/PdfCard';
import Models from './Models';

export const upgradesReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'ADDALL': {
      return action.payload;
    }
    case 'ADD':
      return [...state, action.payload];
    case 'ADDMULTIPLE':
      return [...state, ...action.payload];
    case 'UPDATEROW': {
      return state.map((state: any, index: number) => {
        if (index === action.payload.index) {
          return action.payload.value;
        } else return state;
      });
    }
    case 'UPDATEALL': {
      return state.map((state: any, index: number) => {
        let selectedSuite = action.payload.value.find((deal: any) => deal.unit.suite === state.unit.suite);
        if (selectedSuite) {
          return selectedSuite;
        } else return state;
      });
    }
    case 'UPDATE':
      return state.map((state: any, index: number) => {
        if (state._id === action.payload.id) {
          return action.payload.value;
        } else return state;
      });
    case 'DELETE':
      return state.filter((state: any, index: number) => state._id !== action.payload.id);
    default:
      throw new Error();
  }
};

export const collectionsReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'ADDALL': {
      return action.payload;
    }
    case 'ADD':
      return [...state, action.payload];
    case 'UPDATEROW': {
      return state.map((state: any, index: number) => {
        if (index === action.payload.index) {
          return action.payload.value;
        } else return state;
      });
    }
    case 'UPDATEALL': {
      return state.map((state: any, index: number) => {
        let selectedSuite = action.payload.value.find((deal: any) => deal.unit.suite === state.unit.suite);
        if (selectedSuite) {
          return selectedSuite;
        } else return state;
      });
    }
    case 'UPDATE':
      return state.map((state: any, index: number) => {
        if (state._id === action.payload.id) {
          return action.payload.value;
        } else return state;
      });
    case 'DELETE':
      return state.filter((state: any, index: number) => state._id !== action.payload.id);
    default:
      throw new Error();
  }
};

const ColourSelection = () => {
  const storeDispatch = useAppDispatch();
  const project = useSelector(selectProject);

  const [upgrades, setUpgrades] = useState<IUpgradeTemplate[]>([]);
  const [upgradesState, upgradesDispatch] = useReducer(upgradesReducer, []);
  const [collectionsState, collectionsDispatch] = useReducer(collectionsReducer, []);
  const [value, setValue] = useState<string>('upgrade');
  const [excel, setExcel] = useState<boolean>(false);
  const [modelTypes, setModelTypes] = useState<string[]>([]);
  const [unitTypes, setUnitTypes] = useState<string[]>([]);
  const [suites, setSuites] = useState<IUnit[]>([]);

  const [id, setId] = useState<string | null>(null);
  const [selectedUpgrades, setSelectedUpgrades] = useState<IUpgradeTemplate[]>([]);

  const [name, setName] = useState<string>('');
  const [price, setPrice] = useState<string>('');
  const [costPrice, setCostPrice] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [mainCategory, setMainCategory] = useState<string>('');
  const [upgradeType, setUpgradeType] = useState<string>('');
  const [subCategory, setSubCategory] = useState<string>('');
  const [unitsAllowed, setUnitsAllowed] = useState<string[]>([]);
  const [modelsAllowed, setModelsAllowed] = useState<string[]>([]);
  const [suitesAllowed, setSuitesAllowed] = useState<string[]>([]);
  const [schemesAllowed, setSchemesAllowed] = useState<string[]>([]);
  const [sessions, setSessions] = useState<string[]>([]);
  const [colorful, setColorful] = useState('');
  const [showPrimaryColor, setShowPrimaryColor] = useState(false);
  const [showSecondaryColor, setShowSecondaryColor] = useState(false);
  const [primaryColor, setPrimaryColor] = useState<string>('');
  const [secondaryColor, setSecondaryColor] = useState<string>('');
  const [freeUpgrades, setFreeUpgrades] = useState<string[]>([]);
  const [lock, setLock] = useState<boolean>(false);
  const [hide, setHide] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [filterMain, setFilterMain] = useState<string[]>([]);
  const [file, setFile] = useState<any>({
    getUrl: null,
    putUrl: null,
    file: null,
  });

  const { isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: 'image/jpeg, image/png, application/pdf',
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  useQuery<IUnitData>(UNITS, {
    variables: { filter: { project: project._id } },
    onCompleted: (data) => {
      const uniqueModels = [...new Set(data.unitMany.map((item) => item.modelType))];
      const uniqueUnitTypes = [...new Set(data.unitMany.map((item) => item.unitType))];
      const sortedDeals: any[] = sortSuites([...data.unitMany], 'suite');

      setModelTypes(uniqueModels);
      setUnitTypes(uniqueUnitTypes);
      setSuites(sortedDeals);
    },
  });

  const { loading } = useQuery(UPGRADETEMPLATES, {
    variables: { filter: { project: project._id } },
    onCompleted: (data) => {
      setUpgrades(data.upgradeTemplateMany);
      upgradesDispatch({
        type: 'ADDALL',
        payload: data.upgradeTemplateMany,
      });
    },
  });

  useQuery(COLLECTIONS, {
    variables: { filter: { project: project._id } },
    onCompleted: (data) => {
      collectionsDispatch({
        type: 'ADDALL',
        payload: data.collectionTemplateMany,
      });
    },
  });

  const [addUpgrade] = useMutation(ADDUPGRADE, {
    onCompleted: (data) => {
      setUpgrades([...upgrades, data.createUpgradeTemplateWithUpload]);
      upgradesDispatch({
        type: 'ADD',
        payload: data.createUpgradeTemplateWithUpload,
      });

      storeDispatch(handleModal(false));
      storeDispatch(showSuccessSnackbar('Upgrade has been added'));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [addMultipleUpgrades] = useMutation(CREATEUPGRADES, {
    onCompleted: (data) => {
      setUpgrades([...upgrades, ...data.upgradeTemplateCreateMany.records]);
      upgradesDispatch({
        type: 'ADDMULTIPLE',
        payload: [...data.upgradeTemplateCreateMany.records],
      });

      storeDispatch(handleModal(false));
      storeDispatch(showSuccessSnackbar('Upgrades have been added'));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [updateUpgrade] = useMutation(EDITUPGRADE, {
    onCompleted: (data) => {
      upgradesDispatch({
        type: 'UPDATE',
        payload: {
          id: data.upgradeTemplateUpdateById.record._id,
          value: data.upgradeTemplateUpdateById.record,
        },
      });
      storeDispatch(handleModal(false));
      storeDispatch(showSuccessSnackbar('Upgrade have been updated!'));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [deleteUpgrade] = useMutation(DELETEUPGRADE, {
    onCompleted: (data) => {
      setUpgrades(upgrades.filter((upgrade) => upgrade._id !== data.upgradeTemplateRemoveById.record._id));
      upgradesDispatch({
        type: 'DELETE',
        payload: {
          id: data.upgradeTemplateRemoveById.record._id,
        },
      });
      storeDispatch(handleModal(false));
      storeDispatch(showSuccessSnackbar('Upgrade have been deleted!'));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [addCollection] = useMutation(ADDCOLLECTION, {
    onCompleted: (data) => {
      collectionsDispatch({
        type: 'ADD',
        payload: data.collectionTemplateCreateOne.record,
      });

      storeDispatch(handleModal(false));
      storeDispatch(showSuccessSnackbar('Collection has been added'));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [updateCollection] = useMutation(EDITCOLLECTION, {
    onCompleted: (data) => {
      collectionsDispatch({
        type: 'UPDATE',
        payload: {
          id: data.collectionTemplateUpdateById.record._id,
          value: data.collectionTemplateUpdateById.record,
        },
      });
      storeDispatch(handleModal(false));
      storeDispatch(showSuccessSnackbar('Collection have been updated!'));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [deleteCollection] = useMutation(DELETECOLLECTION, {
    onCompleted: (data) => {
      collectionsDispatch({
        type: 'DELETE',
        payload: {
          id: data.collectionTemplateRemoveById.record._id,
        },
      });
      storeDispatch(handleModal(false));
      storeDispatch(showSuccessSnackbar('Collection have been deleted!'));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [deleteUpgradeImage] = useMutation(DELETEUPGRADEIMAGE, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Image has been deleted!'));
      setFile({
        getUrl: null,
        file: null,
      });
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  // Color Picker
  const handleHexColor = (e: any, type: string) => {
    if (type === 'primaryColor') {
      setPrimaryColor(e);
    } else if (type === 'secondaryColor') {
      setSecondaryColor(e);
    }
  };

  const handleColorPicker = (e: any) => {
    if (e.target.name === 'primaryColor') {
      setShowPrimaryColor(!showPrimaryColor);
    } else if (e.target.name === 'secondaryColor') {
      setShowSecondaryColor(!showSecondaryColor);
    }
  };

  const handleColorPickerBlur = (color: string) => {
    setShowPrimaryColor(false);
    setShowSecondaryColor(false);
  };

  useEffect(() => {
    handleSearch();
  }, [filterMain, search]);

  const handleSearch = () => {
    let filteredUpgrades = upgradesState;

    if (filterMain.length && search) {
      filteredUpgrades = upgrades.filter(
        (upgrade: IUpgradeTemplate) =>
          filterMain.includes(upgrade.mainCategory) && upgrade.name.toLowerCase().indexOf(search.toLowerCase()) > -1
      );
    } else if (filterMain.length) {
      filteredUpgrades = upgrades.filter((upgrade: IUpgradeTemplate) => filterMain.includes(upgrade.mainCategory));
    } else if (search) {
      filteredUpgrades = upgrades.filter((upgrade: IUpgradeTemplate) => upgrade.name.toLowerCase().indexOf(search.toLowerCase()) > -1);
    } else {
      filteredUpgrades = upgrades;
    }

    upgradesDispatch({
      type: 'ADDALL',
      payload: filteredUpgrades,
    });
  };

  const handleEditUpgrade = (upgrade: IUpgradeTemplate) => {
    setId(upgrade._id);
    setName(upgrade.name);
    setUpgradeType(upgrade.upgradeType);
    setPrice(upgrade.price.toString());
    setCostPrice(upgrade.costPrice.toString());
    setDescription(upgrade.description);
    setMainCategory(upgrade.mainCategory);
    setSubCategory(upgrade.subCategory);
    setUnitsAllowed(upgrade.unitsAllowed);
    setLock(upgrade.lock);
    setHide(upgrade.hide);
    setModelsAllowed(upgrade.modelsAllowed);
    setSchemesAllowed(upgrade.schemes)
    setSuitesAllowed(upgrade.suitesAllowed.map((unit: IUnit) => unit.suite));
    setSessions(upgrade.sessions);
    setFreeUpgrades(upgrade.freeUpgrades);
    setExcel(false);
    storeDispatch(handleModal(true));
    setFile({
      getUrl: upgrade.getUrl,
      putUrl: upgrade.putUrl,
      file: null,
    });
  };

  const handleEditCollection = (collection: ICollection) => {
    setId(collection._id);
    setName(collection.name);
    setUnitsAllowed(collection.unitsAllowed);
    setSchemesAllowed(collection.schemes);
    setModelsAllowed(collection.modelsAllowed);
    setSuitesAllowed(collection.suitesAllowed.map((unit: IUnit) => unit.suite));
    setSelectedUpgrades(collection.upgradeTemplates);
    setExcel(false);
    setPrimaryColor(collection.primaryColor);
    setSecondaryColor(collection.secondaryColor);
    setSessions([collection.session]);
    storeDispatch(handleModal(true));
  };

  const handleAdd = () => {
    setId(null);
    setName('');
    setPrice('');
    setCostPrice('');
    setMainCategory('');
    setSubCategory('');
    setDescription('');
    setUnitsAllowed([]);
    setModelsAllowed([]);
    setSchemesAllowed([]);
    setSuitesAllowed([]);
    setSessions([]);
    setFreeUpgrades([]);
    setSelectedUpgrades([]);
    setUpgradeType('');
    setFile({
      getUrl: null,
      file: null,
    });
    setExcel(false);
    storeDispatch(handleModal(true));
  };

  const handleChange = (event: any, decorType: string) => {
    if (decorType !== null) {
      setValue(decorType);
    }
  };

  const submitForm = async (e: any) => {
    e.preventDefault();
    let allSuites = suites.filter((unit: IUnit) => suitesAllowed.includes(unit.suite)).map((unit: IUnit) => unit._id);
    let newObject = {
      name,
      price: parseInt(price, 10),
      costPrice: parseInt(costPrice, 10),
      mainCategory,
      subCategory,
      unitsAllowed,
      modelsAllowed,
      suitesAllowed: allSuites,
      sessions,
      description,
      freeUpgrades,
      lock,
      hide,
      upgradeType,
      project: project._id,
    };

    if (file.file && id) {
      const buffer = Buffer.from(file.getUrl.replace(/^data:image\/\w+;base64,/, ''), 'base64');
      const options = {
        headers: { 'Content-Type': file.file.type },
        maxContentLength: Infinity,
        maxBodyLength: Infinity,
      };
      await axios.put(file.putUrl, buffer, options);
    }

    if (id) {
      updateUpgrade({ variables: { _id: id, record: newObject } });
    } else {
      addUpgrade({ variables: { record: newObject, file: file.file } });
    }
  };

  const submitCollectionForm = (e: any) => {
    e.preventDefault();
    let allSuites = suites.filter((unit: IUnit) => suitesAllowed.includes(unit.suite)).map((unit: IUnit) => unit._id);
    
    let newObject = {
      name,
      upgradeTemplates: selectedUpgrades.map((upgrade: IUpgradeTemplate) => upgrade._id),
      unitsAllowed,
      modelsAllowed,
      suitesAllowed: allSuites,
      project: project._id,
      schemes: schemesAllowed,
      session: sessions[0],
      primaryColor: primaryColor,
      secondaryColor: secondaryColor,
    };

    if (id) {
      updateCollection({ variables: { _id: id, record: newObject } });
    } else {
      addCollection({ variables: { record: newObject } });
    }
  };

  const handleDrop = (acceptedFiles: any) => {
    if (acceptedFiles.length === 0) {
      storeDispatch(showErrorSnackbar('This file type is not allowed'));
      return;
    }
    const acceptedFile = acceptedFiles[0];
    const fileReader = new FileReader();
    if (acceptedFile) {
      fileReader.readAsDataURL(acceptedFile);
    }
    fileReader.onloadend = async () => {
      setFile({
        getUrl: fileReader.result,
        putUrl: file.putUrl,
        file: acceptedFile,
      });
    };
  };

  const deleteImage = (id: number, title: string, numIndex: number) => {
    if (file.file) {
      setFile({
        getUrl: null,
        putUrl: null,
        file: null,
      });
    } else {
      deleteUpgradeImage({ variables: { _id: id } });
    }
  };

  const handleNewSession = (session: string) => {
    setSessions([session]);
  };

  const modalDisplay = () => {
    if (value === 'upgrade' && excel) {
      return (
        <Box>
          <Box>
            <p>To add upgrades, you will need the following header columns in the following order:</p>
            <ul>
              <li>Column A: Name</li>
              <li>Column B: Price</li>
              <li>Column C: Cost Price</li>
              <li>Column D: Main Category</li>
              <li>Column E: Sub Category</li>
              <li>
                Column F: Upgrade Type (Upgrade type only takes the following values: STD, UPG1, UPG2, UPG3, UPG4, UPG5, ALaCarte, Credit,
                Coordination). This is case sensitive.
              </li>
              <li>Column G: Models Allowed (Optional)</li>
              <li>Column H: Units Allowed (Optional)</li>
              <li>Column I: Free Upgrades (Optional)</li>
            </ul>
            <p>1. Please have all the columns in text format except Price and Cost Price.</p>
            <p>2. For Models Allowed, Units Allowed and Free Upgrades. Please have the cells comma separated. (Ex. C511, C234)</p>
          </Box>
          <FlexBetween>
            <Button onClick={() => storeDispatch(handleModal(false))} variant="contained" color="info">
              Cancel
            </Button>
            <Button component="label" variant="contained" color="success">
              Upload By Excel
              <input
                onClick={(e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
                  const elem = e.target as HTMLInputElement;
                  elem.value = '';
                }}
                accept=".csv, .xls, .xlsx"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => upload(e)}
                hidden
                type="file"
              />
            </Button>
          </FlexBetween>
        </Box>
      );
    } else if (value === 'upgrade') {
      return (
        <form onSubmit={submitForm}>
          <Typography variant={'h5'} gutterBottom>
            {id ? 'Edit' : 'Add'} {capitalizeFirstLetter(value)}
          </Typography>
          <Box>
            <TextField
              title={'Name'}
              name={'name'}
              fullWidth
              disabled={id ? true : false}
              value={name}
              label={'Name'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
              required
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <TextField
              title={'Description'}
              name={'description'}
              fullWidth
              value={description}
              label={'Description'}
              inputProps={{ maxLength: 200 }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDescription(e.target.value)}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <TextField
              title={'Advertised Price'}
              name={'price'}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
              disabled={id ? true : false}
              fullWidth
              value={price}
              label={'Advertised Price'}
              type={'number'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPrice(e.target.value)}
              required
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <TextField
              title={'Base Price'}
              name={'costPrice'}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
              fullWidth
              value={costPrice}
              label={'Base Price'}
              type={'number'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCostPrice(e.target.value)}
              required
            />
          </Box>
          <Flex>
            <Box sx={{ mt: 2 }}>
              <FormControlLabel
                onClick={(e) => e.stopPropagation()}
                control={<Switch checked={lock} onChange={() => setLock(!lock)} />}
                label={'Lock'}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <FormControlLabel
                onClick={(e) => e.stopPropagation()}
                control={<Switch checked={hide} onChange={() => setHide(!hide)} />}
                label={'Hide'}
              />
            </Box>
          </Flex>
          <Box sx={{ mt: 2 }}>
            <Autocomplete
              multiple
              options={schemes}
              getOptionLabel={(option: string) => option}
              isOptionEqualToValue={(option, value) => option === value}
              disableClearable={false}
              freeSolo={false}
              value={schemesAllowed}
              onChange={(event: any, newValue: any | null) => setSchemesAllowed(newValue)}
              renderInput={(params) => <TextField {...params} label="Schemes" size="small" />}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <Autocomplete
              options={upgradeTypes}
              getOptionLabel={(option: any) => option}
              disableClearable={false}
              freeSolo={false}
              value={upgradeType}
              isOptionEqualToValue={(option, value) => option === value}
              onChange={(event: any, newValue: any | null) => setUpgradeType(newValue)}
              renderInput={(params) => <TextField required {...params} label="Upgrade Type" size="small" />}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <Autocomplete
              options={mainCategories.sort()}
              getOptionLabel={(option: any) => option}
              disableClearable={false}
              freeSolo={false}
              value={mainCategory}
              isOptionEqualToValue={(option, value) => option === value}
              onChange={(event: any, newValue: any | null) => setMainCategory(newValue)}
              renderInput={(params) => <TextField required {...params} label="Main Category" size="small" />}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <Autocomplete
              options={subCategories.sort()}
              getOptionLabel={(option: any) => option}
              disableClearable={false}
              freeSolo={false}
              value={subCategory}
              isOptionEqualToValue={(option, value) => option === value}
              onChange={(event: any, newValue: any | null) => setSubCategory(newValue)}
              renderInput={(params) => <TextField required {...params} label="Sub Category" size="small" />}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <Autocomplete
              multiple
              options={unitTypes.sort()}
              getOptionLabel={(option: string) => option}
              isOptionEqualToValue={(option, value) => option === value}
              disableClearable={false}
              freeSolo={false}
              value={unitsAllowed}
              onChange={(event: any, newValue: any | null) => {
                setUnitsAllowed(newValue.map((option: string) => option));
              }}
              renderInput={(params) => <TextField {...params} label="Unit Types Allowed" size="small" />}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <Autocomplete
              multiple
              options={modelTypes.sort()}
              getOptionLabel={(option: string) => option}
              isOptionEqualToValue={(option, value) => option === value}
              disableClearable={false}
              freeSolo={false}
              value={modelsAllowed}
              onChange={(event: any, newValue: any | null) => {
                setModelsAllowed(newValue.map((option: string) => option));
              }}
              renderInput={(params) => <TextField {...params} label="Model Types Allowed" size="small" />}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <Autocomplete
              multiple
              options={suites.map((unit: IUnit) => unit.suite)}
              getOptionLabel={(option: string) => option}
              isOptionEqualToValue={(option, value) => option === value}
              disableClearable={false}
              freeSolo={false}
              value={suitesAllowed}
              onChange={(event: any, newValue: any | null) => {
                setSuitesAllowed(newValue.map((option: string) => option));
              }}
              renderInput={(params) => <TextField {...params} label="Suites Allowed" size="small" />}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <Autocomplete
              multiple
              options={['Upgrade Set One', 'Upgrade Set Two', 'Upgrade Set Three']}
              getOptionLabel={(option: string) => option}
              isOptionEqualToValue={(option, value) => option === value}
              disableClearable={false}
              freeSolo={false}
              value={sessions}
              onChange={(event: any, newValue: any | null) => {
                setSessions(newValue.map((option: string) => option));
              }}
              renderInput={(params) => <TextField {...params} label="Session Types" size="small" />}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <Autocomplete
              multiple
              options={[...new Set(suites.map((item: any) => item.suite))]}
              getOptionLabel={(option: string) => option}
              isOptionEqualToValue={(option, value) => option === value}
              disableClearable={false}
              freeSolo={false}
              value={freeUpgrades}
              onChange={(event: any, newValue: any | null) => {
                setFreeUpgrades(newValue.map((option: string) => option));
              }}
              renderInput={(params) => <TextField {...params} label="Suites that get this as free upgrade" size="small" />}
            />
          </Box>
          <Box>
            {file.getUrl ? (
              <Grid sx={{ mt: 1 }} container spacing={2}>
                <Grid item sm={4}>
                  <PdfCard file={file.getUrl} title={name} id={id!} handleDelete={deleteImage} download={true} index={0} />
                </Grid>
              </Grid>
            ) : (
              <Dropzone onDrop={(files) => handleDrop(files)} accept="image/jpg, image/jpeg, image/png, application/pdf">
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps({ style })}>
                      <input {...getInputProps()} />
                      <p>Drag and Drop or Upload Image</p>
                    </div>
                  </section>
                )}
              </Dropzone>
            )}
          </Box>
          <FlexBetween sx={{ mt: 2 }}>
            <Button onClick={() => storeDispatch(handleModal(false))} variant="contained" color="info">
              Cancel
            </Button>
            <Box>
              {id ? (
                <Button onClick={() => deleteUpgrade({ variables: { _id: id } })} sx={{ mr: 1 }} variant="contained" color="error">
                  Delete Upgrade
                </Button>
              ) : null}
              <Button type="submit" variant="contained" color="success">
                {id ? 'Save' : 'Add'} Upgrade
              </Button>
            </Box>
          </FlexBetween>
        </form>
      );
    } else if (value === 'collection') {
      return (
        <form onSubmit={submitCollectionForm}>
          <Typography variant={'h5'} gutterBottom>
            {id ? 'Edit' : 'Add'} {capitalizeFirstLetter(value)}
          </Typography>
          <Box>
            <TextField
              title={'Name'}
              name={'name'}
              fullWidth
              value={name}
              label={'Name'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
              required
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <Autocomplete
              multiple
              options={schemes}
              getOptionLabel={(option: string) => option}
              isOptionEqualToValue={(option, value) => option === value}
              disableClearable={false}
              freeSolo={false}
              value={schemesAllowed}
              onChange={(event: any, newValue: any | null) => setSchemesAllowed(newValue)}
              renderInput={(params) => <TextField {...params} label="Schemes" size="small" />}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <Autocomplete
              multiple
              options={unitTypes.sort()}
              getOptionLabel={(option: string) => option}
              isOptionEqualToValue={(option, value) => option === value}
              disableClearable={false}
              freeSolo={false}
              value={unitsAllowed}
              onChange={(event: any, newValue: any | null) => {
                setUnitsAllowed(newValue.map((option: string) => option));
              }}
              renderInput={(params) => <TextField {...params} label="Unit Types Allowed" size="small" />}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <Autocomplete
              multiple
              options={modelTypes.sort()}
              getOptionLabel={(option: string) => option}
              isOptionEqualToValue={(option, value) => option === value}
              disableClearable={false}
              freeSolo={false}
              value={modelsAllowed}
              onChange={(event: any, newValue: any | null) => {
                setModelsAllowed(newValue.map((option: string) => option));
              }}
              renderInput={(params) => <TextField {...params} label="Model Types Allowed" size="small" />}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <Autocomplete
              multiple
              options={suites.map((unit: IUnit) => unit.suite)}
              getOptionLabel={(option: string) => option}
              isOptionEqualToValue={(option, value) => option === value}
              disableClearable={false}
              freeSolo={false}
              value={suitesAllowed}
              onChange={(event: any, newValue: any | null) => {
                setSuitesAllowed(newValue.map((option: string) => option));
              }}
              renderInput={(params) => <TextField {...params} label="Suites Allowed" size="small" />}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <Autocomplete
              options={['Upgrade Set One', 'Upgrade Set Two', 'Upgrade Set Three']}
              getOptionLabel={(option: string) => option}
              isOptionEqualToValue={(option, value) => option === value}
              disableClearable={false}
              freeSolo={false}
              value={sessions[0]}
              onChange={(event: any, newValue: any | null) => {
                if (newValue) {
                  handleNewSession(newValue);
                }
              }}
              renderInput={(params) => <TextField {...params} label="Session Types" size="small" />}
            />
          </Box>
          <Box sx={{ mt: 2, position: 'relative' }}>
            {showPrimaryColor ? (
              <Box
                sx={{
                  position: 'absolute',
                  zIndex: 100,
                  top: '80px',
                }}
              >
                <HexColorPicker color={colorful} onChange={(e) => handleHexColor(e, 'primaryColor')} />
              </Box>
            ) : null}
            <TextInput
              background={primaryColor}
              title={'Primary Colour'}
              handleTextInput={(e: React.ChangeEvent<HTMLInputElement>) => setPrimaryColor(e.target.value)}
              name={'primaryColor'}
              value={primaryColor}
              focus={true}
              blur={true}
              handleFocus={(e: any) => handleColorPicker(e)}
              handleBlur={() => handleColorPickerBlur('primaryColor')}
            />
          </Box>
          <Box sx={{ mt: 2, position: 'relative' }}>
            {showSecondaryColor ? (
              <Box
                sx={{
                  position: 'absolute',
                  zIndex: 100,
                  top: '80px',
                }}
              >
                <HexColorPicker color={colorful} onChange={(e) => handleHexColor(e, 'secondaryColor')} />
              </Box>
            ) : null}
            <TextInput
              background={secondaryColor}
              title={'Secondary Colour'}
              handleTextInput={(e: React.ChangeEvent<HTMLInputElement>) => setSecondaryColor(e.target.value)}
              name={'secondaryColor'}
              value={secondaryColor}
              focus={true}
              blur={true}
              handleFocus={(e: any) => handleColorPicker(e)}
              handleBlur={() => handleColorPickerBlur('secondaryColor')}
            />
          </Box>
          {sessions.length ? (
            <Grid container spacing={2}>
              {upgradesState.filter((upgrade: IUpgradeTemplate) => {
                if (schemesAllowed.length) {
                  return upgrade.sessions.includes(sessions[0]) && upgrade.schemes.includes(schemesAllowed[0]);
                } else {
                  return upgrade.sessions.includes(sessions[0])
                }
              }).map((upgrade: IUpgradeTemplate, index: number) => {
                return (
                  <Grid item sm={12} md={4}>
                    <FormControlLabel
                      style={{
                        marginTop: '10px',
                        marginBottom: '15px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      key={index}
                      control={
                        <Checkbox
                          checked={selectedUpgrades.map((upgrade: IUpgradeTemplate) => upgrade._id).includes(upgrade._id)}
                          onChange={(e: any) => {
                            if (selectedUpgrades.includes(upgrade)) {
                              setSelectedUpgrades(selectedUpgrades.filter((selected: any) => selected._id !== upgrade._id))
                            } else {
                              setSelectedUpgrades([...selectedUpgrades, upgrade])
                            }
                          }}
                        />
                      }
                      label={`${upgrade.mainCategory} - ${upgrade.subCategory} - ${upgrade.name} (${(upgrade.upgradeType)}) (${numToCurrency.format(upgrade.price)})`}
                    />
                  </Grid>
                )
              })}
            </Grid>
          ) : null}
          <p>
            <strong>Decor Items</strong>
          </p>
          <Box sx={{ my: 2 }}>
            {selectedUpgrades.map((column: any, index: number) => {
              return (
                <FlexBetween
                  key={index}
                  sx={{
                    p: 1,
                    width: '100%',
                    border: '1px solid #000',
                    borderRadius: '4px',
                    mb: 1,
                  }}
                >
                  <Box>
                    <Box>
                      {index + 1}. {column.subCategory} - {column.name} - {numToCurrency.format(column.price)}
                    </Box>
                    {column.freeUpgrades.length ? (
                      <Box sx={{ mt: 1 }}>
                        Free: <strong>{column.freeUpgrades.map((unit: string) => unit).join(', ')}</strong>
                      </Box>
                    ) : null}
                  </Box>
                  <Box sx={{ alignSelf: 'center' }}>
                    <CloseIcon
                      sx={{ cursor: 'pointer', ml: 1 }}
                      color="secondary"
                      onClick={() => setSelectedUpgrades(selectedUpgrades.filter((columns: any, numIndex: number) => numIndex !== index))}
                    />
                  </Box>
                </FlexBetween>
              );
            })}
          </Box>
          <FlexBetween sx={{ mt: 2 }}>
            <Button onClick={() => storeDispatch(handleModal(false))} variant="contained" color="info">
              Cancel
            </Button>
            <Box>
              {id ? (
                <Button onClick={() => deleteCollection({ variables: { _id: id } })} sx={{ mr: 1 }} variant="contained" color="error">
                  Delete {capitalizeFirstLetter(value)}
                </Button>
              ) : null}
              <Button type="submit" variant="contained" color="success">
                {id ? 'Edit' : 'Add'} {capitalizeFirstLetter(value)}
              </Button>
            </Box>
          </FlexBetween>
        </form>
      );
    }
  };

  const handleExcel = (type: boolean) => {
    setExcel(type);
    storeDispatch(handleModal(true));
  };

  const upload = async (e: any) => {
    if (!e.target.files || !e.target.files[0]) {
      storeDispatch(showErrorSnackbar('A file was not selected.'));
      return;
    }

    const file = e.target.files[0];

    e.target.files = null;

    let { error, upgrades } = await uploadUpgrades(file);

    if (error.length) {
      storeDispatch(showErrorSnackbar(error));
      return;
    }

    let name = upgrades.every((upgrade) => upgrade.hasOwnProperty('name'));
    if (!name) return storeDispatch(showErrorSnackbar('Missing Name'));
    let costPrice = upgrades.every((upgrade) => upgrade.hasOwnProperty('costPrice'));
    if (!costPrice) return storeDispatch(showErrorSnackbar('Missing Cost Price'));
    let price = upgrades.every((upgrade) => upgrade.hasOwnProperty('price'));
    if (!price) return storeDispatch(showErrorSnackbar('Missing Price'));
    let mainCategory = upgrades.every((upgrade) => upgrade.hasOwnProperty('mainCategory'));
    if (!mainCategory) return storeDispatch(showErrorSnackbar('Missing Main Category'));
    let subCategory = upgrades.every((upgrade) => upgrade.hasOwnProperty('subCategory'));
    if (!subCategory) return storeDispatch(showErrorSnackbar('Missing SubCategory'));
    if (!subCategory) return storeDispatch(showErrorSnackbar('Missing Upgrade Type'));

    let createUpgrades = upgrades.map((upgrade: any) => {
      let modelsAllowed = upgrade.hasOwnProperty('modelsAllowed')
        ? upgrade.modelsAllowed.includes(',')
          ? upgrade.modelsAllowed.split(',').map((string: string) => string.trim())
          : [upgrade.modelsAllowed]
        : [];
      let sessions = upgrade.hasOwnProperty('sessions')
        ? upgrade.sessions.includes(',')
          ? upgrade.sessions.split(',').map((string: string) => string.trim())
          : [upgrade.sessions]
        : [];
      let unitsAllowed = upgrade.hasOwnProperty('unitsAllowed')
        ? upgrade.unitsAllowed.includes(',')
          ? upgrade.unitsAllowed.split(',').map((string: string) => string.trim())
          : [upgrade.unitsAllowed]
        : [];
      let suitesAllowed = upgrade.hasOwnProperty('suitesAllowed')
        ? upgrade.suitesAllowed.includes(',')
          ? upgrade.suitesAllowed.split(',').map((string: string) => string.trim())
          : [upgrade.suitesAllowed]
        : [];
      let freeUpgrades = upgrade.hasOwnProperty('freeUpgrades')
        ? upgrade.freeUpgrades.includes(',')
          ? upgrade.freeUpgrades.split(',').map((string: string) => string.trim())
          : [`${upgrade.freeUpgrades}`]
        : [];

      let allSuites = suites.filter((unit: IUnit) => suitesAllowed.includes(unit.suite)).map((unit: IUnit) => unit._id);
        
      return {
        name: upgrade.name,
        price: upgrade.price,
        costPrice: upgrade.costPrice,
        description: upgrade.description,
        mainCategory: upgrade.mainCategory,
        subCategory: upgrade.subCategory,
        modelsAllowed: modelsAllowed,
        suitesAllowed: allSuites,
        sessions: sessions,
        unitsAllowed: unitsAllowed,
        freeUpgrades: freeUpgrades,
        upgradeType: upgrade.upgradeType,
        project: project._id,
      };
    });

    addMultipleUpgrades({ variables: { records: createUpgrades } });
  };

  return (
    <Box sx={{ py: 2 }}>
      <GlobalModal>{modalDisplay()}</GlobalModal>
      <Typography variant={'h5'} gutterBottom>
        <strong>Colour Selection</strong>
      </Typography>
      <FlexBetween>
        <ToggleButtonGroup
          value={value}
          exclusive
          onChange={handleChange}
          aria-label="text alignment"
          sx={{
            marginBottom: '10px',
            '& .MuiToggleButton-root.Mui-selected': {
              backgroundColor: '#00142a',
              color: '#fff',
            },
            '& .MuiToggleButton-root.Mui-selected:hover': {
              backgroundColor: '#00142a',
              color: '#fff',
            },
          }}
        >
          <ToggleButton value="upgrade" aria-label="centered">
            Upgrades
          </ToggleButton>
          <ToggleButton value="collection" aria-label="right aligned">
            Collections
          </ToggleButton>
          <ToggleButton value="models" aria-label="right aligned">
            Models Setup
          </ToggleButton>
        </ToggleButtonGroup>
        <Box>
          {value !== 'models' ? (
            <Button onClick={() => handleAdd()} variant="contained" color="primary">
              Add {capitalizeFirstLetter(value)}
            </Button>
          ) : null}
          {value === 'upgrade' ? (
            <Button onClick={() => handleExcel(true)} component="label" sx={{ ml: 1 }} variant="contained" color="primary">
              Upload From Excel
            </Button>
          ) : null}
        </Box>
      </FlexBetween>
      {value === 'upgrade' ? (
        <FlexBetween>
          <Autocomplete
            sx={{ width: '500px', height: '100%' }}
            multiple
            options={mainCategories.sort()}
            getOptionLabel={(option: any) => option}
            disableClearable={false}
            freeSolo={false}
            value={filterMain}
            isOptionEqualToValue={(option, value) => option === value}
            onChange={(event: any, newValue: any | null) => setFilterMain(newValue)}
            renderInput={(params) => <TextField required {...params} label="Filter" size="small" />}
          />
          <TextField
            title={'Search'}
            name={'search'}
            value={search}
            label={'Search'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
            required
          />
        </FlexBetween>
      ) : null}
      <Box sx={{ mt: 2 }}>
        {loading ? (
          <LoadingWrapper title="Loading..." modal={false} />
        ) : (
          <>
            {value === 'upgrade' ? <Upgrades handleEditUpgrade={handleEditUpgrade} upgrades={upgradesState} /> : null}
            {value === 'collection' ? <Collections collections={collectionsState} handleEditCollection={handleEditCollection} /> : null}
            {value === 'models' ? <Models modelTypes={modelTypes} /> : null}
          </>
        )}
      </Box>
    </Box>
  );
};

const UNITS = gql`
  query unitMany($filter: FilterFindManyUnitInput) {
    unitMany(filter: $filter, limit: 10000) {
      _id
      suite
      modelType
      unitType
    }
  }
`;

const UPGRADETEMPLATES = gql`
  query upgradeTemplateMany($filter: FilterFindManyUpgradeTemplateInput) {
    upgradeTemplateMany(filter: $filter, limit: 10000) {
      project {
        _id
      }
      _id
      name
      price
      costPrice
      mainCategory
      subCategory
      lock
      hide
      schemes
      unitsAllowed
      modelsAllowed
      suitesAllowed {
        _id
        suite
      }
      sessions
      description
      freeUpgrades
      upgradeType
      getUrl
      putUrl
    }
  }
`;

const COLLECTIONS = gql`
  query collectionTemplateMany($filter: FilterFindManyCollectionTemplateInput) {
    collectionTemplateMany(filter: $filter, limit: 10000) {
      _id
      name
      upgradeTemplates {
        _id
        name
        price
        costPrice
        freeUpgrades
        lock
        hide
        subCategory
        mainCategory
      }
      primaryColor
      secondaryColor
      session
      schemes
      unitsAllowed
      modelsAllowed
      suitesAllowed {
        _id
        suite
      }
    }
  }
`;

const ADDUPGRADE = gql`
  mutation createUpgradeTemplateWithUpload($record: CreateOneUpgradeTemplateInput!, $file: Upload) {
    createUpgradeTemplateWithUpload(record: $record, file: $file) {
      project {
        _id
      }
      _id
      name
      price
      costPrice
      mainCategory
      subCategory
      schemes
      unitsAllowed
      lock
      hide
      modelsAllowed
      suitesAllowed {
        _id
        suite
      }
      sessions
      description
      freeUpgrades
      upgradeType
      getUrl
      putUrl
    }
  }
`;

const CREATEUPGRADES = gql`
  mutation upgradeTemplateCreateMany($records: [CreateManyUpgradeTemplateInput!]!) {
    upgradeTemplateCreateMany(records: $records) {
      records {
        project {
          _id
        }
        _id
        name
        price
        costPrice
        description
        lock
        hide
        mainCategory
        subCategory
        schemes
        unitsAllowed
        modelsAllowed
        suitesAllowed {
          _id
          suite
        }
        sessions
        freeUpgrades
        upgradeType
        getUrl
        putUrl
      }
    }
  }
`;

const EDITUPGRADE = gql`
  mutation upgradeTemplateUpdateById($_id: MongoID!, $record: UpdateByIdUpgradeTemplateInput!) {
    upgradeTemplateUpdateById(_id: $_id, record: $record) {
      record {
        project {
          _id
        }
        _id
        name
        price
        costPrice
        description
        mainCategory
        subCategory
        lock
        hide
        schemes
        unitsAllowed
        modelsAllowed
        suitesAllowed {
          _id
          suite
        }
        sessions
        freeUpgrades
        upgradeType
        getUrl
        putUrl
      }
    }
  }
`;

const DELETEUPGRADE = gql`
  mutation upgradeTemplateRemoveById($_id: MongoID!) {
    upgradeTemplateRemoveById(_id: $_id) {
      record {
        project {
          _id
        }
        _id
        name
        price
        costPrice
        description
        lock
        hide
        mainCategory
        subCategory
        schemes
        unitsAllowed
        modelsAllowed
        suitesAllowed {
          _id
          suite
        }
        sessions
        freeUpgrades
        upgradeType
        getUrl
        putUrl
      }
    }
  }
`;

const ADDCOLLECTION = gql`
  mutation collectionTemplateCreateOne($record: CreateOneCollectionTemplateInput!) {
    collectionTemplateCreateOne(record: $record) {
      record {
        _id
        name
        upgradeTemplates {
          _id
          name
          price
          costPrice
          freeUpgrades
          subCategory
          mainCategory
        }
        schemes
        unitsAllowed
        modelsAllowed
        suitesAllowed {
          _id
          suite
        }
      }
    }
  }
`;

const EDITCOLLECTION = gql`
  mutation collectionTemplateUpdateById($_id: MongoID!, $record: UpdateByIdCollectionTemplateInput!) {
    collectionTemplateUpdateById(_id: $_id, record: $record) {
      record {
        _id
        name
        upgradeTemplates {
          _id
          name
          price
          freeUpgrades
          subCategory
          mainCategory
        }
        schemes
        unitsAllowed
        modelsAllowed
        suitesAllowed {
          _id
          suite
        }
      }
    }
  }
`;

const DELETECOLLECTION = gql`
  mutation collectionTemplateRemoveById($_id: MongoID!) {
    collectionTemplateRemoveById(_id: $_id) {
      record {
        _id
        name
        upgradeTemplates {
          _id
          name
          price
        }
        schemes
        unitsAllowed
        modelsAllowed
        suitesAllowed {
          _id
          suite
        }
      }
    }
  }
`;

const DELETEUPGRADEIMAGE = gql`
  mutation deleteUpgradeTemplateImage($_id: MongoID!) {
    deleteUpgradeTemplateImage(_id: $_id) {
      _id
      project {
        _id
      }
      name
      price
      mainCategory
      subCategory
      schemes
      unitsAllowed
      modelsAllowed
      suitesAllowed {
        _id
        suite
      }
      sessions
      lock
      hide
      freeUpgrades
      upgradeType
      getUrl
      putUrl
    }
  }
`;

export default ColourSelection;
