import { useRef } from 'react';
import { TextField, InputAdornment } from '@mui/material';

const TextInput = (props: ChildProps) => {
  const textFieldRef = useRef<any>(null);

  const value = () => {
    if (props.type === "number") {
      if (props.value === 0) {
        return 0
      } else if (props.value !== 0) {
        return props.value
      }
    } else {
      if (props.value) {
        return props.value
      } else return ''
    }
  }
  
  const handleKeyDown = (e: any) => {
    if (((e.keyCode === 38 || e.keyCode === 40) && props.type === "number") || (e.keyCode === 189 && props.nonNegative) || (e.keyCode === 190 && props.intOnly)) {
      e.preventDefault();
    }
  }

  return (
    <TextField
      id={props.id}
      type={props.type}
      name={props.name}
      variant={props.variant}
      placeholder={props.placeholder}
      sx={
        {
          width: '100%',
          '& .MuiFormLabel-asterisk': {
            color: 'red',
          },
          backgroundColor: props.background,
          color: props.background ? '#000' : 'red',
          '.MuiFormControl-marginNormal': {
            marginTop: 0,
            marginBottom: 0,
          }
        }
      }
      label={props.title}
      disabled={props.disabled ? true : false}
      required={props.required ? props.required : false}
      value={!props.controlled ? value() : null}
      onChange={props.handleTextInput}
      multiline={props.multiline}
      rows={props.rows}
      // rowsMax={props.rowsMax}
      InputProps={{
        startAdornment: props.adornment ? <InputAdornment position={'start'}>{props.adornment}</InputAdornment> : null,
        endAdornment: props.endAdornment ? <InputAdornment position={'end'}>{props.endAdornment}</InputAdornment> : null,
        inputProps: { 
          min: props.min,
          style: {textTransform: props.lowercase ? "lowercase" : "none"},
          maxLength: props.maxLength
        },
      }}
      inputProps={{
        step: props.step,
      }}
      onFocus={props.focus ? props.handleFocus : null}
      onBlur={props.blur ? props.handleBlur : null}
      onKeyDown={handleKeyDown}
      onWheel={(e: any) => e.target.blur()}
      helperText={props.helperText}
      error={props.error}
      defaultValue={props.controlled ? value() : ''}
      ref={textFieldRef}
    />
  );
};

interface ChildProps {
  id?: string;
  type?: string;
  name?: string;
  inputColor?: string;
  value?: any;
  required?: boolean;
  disabled?: boolean;
  title?: string;
  handleTextInput?: any;
  background?: string;
  variant?: any;
  label?: string;
  styles?: any;
  adornment?: any;
  endAdornment?: any;
  handleFocus?: any;
  handleBlur?: any;
  focus?: boolean;
  blur?: boolean;
  helperText?: string;
  error?: boolean;
  step?: string;
  multiline?: boolean;
  rows?: number;
  rowsMax?: string | number;
  placeholder?: string;
  min?: string | number;
  defaultValue?: any;
  lowercase?: boolean;
  controlled?: boolean;
  intOnly?: boolean;
  nonNegative?: boolean;
  maxLength?: number;
}

export default TextInput;
