import { useState, useMemo } from 'react';
import { Typography, Tooltip, Box, Button, FormControlLabel, Checkbox } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { gql, useQuery, useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';

import { outstandingChequeTypes } from '../../utils/Constants';
import {
  capitalizeFirstLetter,
  convertAllDates,
  customSort,
  numberWithCommas,
  getInitials,
  downloadExcel,
  downloadPdf,
  normalToCamel,
  numToCurrency,
} from '../../utils/Functions';
import { IDeal, IDealDeposit, IDealRealtor, IPurchaserInfo } from '../../types/CreateDealForm';
import { IComment } from '../../types/comment';
import { useSelector } from 'react-redux';
import { selectProject } from '../../features/project/projectSlice';
import { selectUser } from '../../features/auth/authSlice';
import { useAppDispatch } from '../../app/hooks';
import { showErrorSnackbar, showSuccessSnackbar } from '../../features/snackbar/snackbarSlice';
import InfoIcon from '@mui/icons-material/Info';
import { Flex, FlexBetween } from '../../commonStyles';
import { IDocuments } from '../../types/docusign';
import VirtualizedTable from '../tables/VirtualizedTable';
import LoadingWrapper from '../common/LoadingWrapper';
import { GlobalModal } from '../../features/modal/Modal';
import CommentsTable from '../common/CommentsTable';
import { handleModal } from '../../features/modal/modalSlice';
import Dropdown from '../common/formControls/Dropdown';
import { chequeTypes } from '../../utils/Constants';

const Outstandings = (props: ChildProps) => {
  const { userType } = props;
  const storeDispatch = useAppDispatch();
  const project = useSelector(selectProject);
  const user = useSelector(selectUser);
  const [activeDeals, setActiveDeals] = useState<IDealComment[]>([]);
  const [allDeals, setAllDeals] = useState<IDealComment[]>([]);
  const [selectedDeal, setSelectedDeal] = useState<any>(null);
  const [selectedDeposit, setSelectedDeposit] = useState<any>(null);
  const [type, setType] = useState<string>('');
  const [checked, setChecked] = useState<boolean>(false);

  const [updateDeposit] = useMutation(UPDATEDEPOSIT, {
    onCompleted: (data) => {
      let updatedActiveDeals = activeDeals.map((deal: any) => {
        if (deal._id === selectedDeal._id) {
          let currentDeposit = deal.deposit;
          if (deal.pendingDeposits.length) {
            currentDeposit = deal.pendingDeposits;
          }
          let deposit = currentDeposit.map((deposit: any) => {
            if (deposit._id === selectedDeposit._id) {
              return data.depositUpdateById.record;
            } else return deposit;
          });
          return {
            ...deal,
            deposit: deposit,
          };
        } else return deal;
      });
      setActiveDeals(updatedActiveDeals);
      setType('');
      storeDispatch(handleModal(false));
      setSelectedDeal(null);
      setSelectedDeposit(null);
      storeDispatch(showSuccessSnackbar('Deposit has been updated!'));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const { loading } = useQuery(DEALS, {
    skip: userType === 'sales',
    variables: { project: project._id },
    onCompleted: async (data) => {
      let sortedRows = await customSort(data.getOutstandings, 'unit', 'suite', true);
      setActiveDeals(sortedRows);
      setAllDeals(sortedRows);
    },
    onError: (error) => {
      storeDispatch(showErrorSnackbar(error.toString()));
    },
  });

  const { loading: salesLoading } = useQuery(DEALS, {
    skip: userType !== 'sales',
    variables: { project: project._id, salesRep: user._id },
    onCompleted: async (data) => {
      let sortedRows = await customSort(data.getOutstandings, 'unit', 'suite', true);
      setActiveDeals(sortedRows);
    },
    onError: (error) => {
      storeDispatch(showErrorSnackbar(error.toString()));
    },
  });

  const getStatusDate = (deal: IDeal) => {
    let date;
    switch (deal.unit.status) {
      case 'F':
        date = deal.firmDate;
        break;
      case 'S':
        date = deal.signDate;
        break;
      case 'C':
        date = deal.executeDate;
        break;
      default:
        date = deal.createdAt;
        break;
    }
    return convertAllDates(date, 'PP');
  };

  const comment = (comment: any) => {
    return (
      <Box>
        <div>{comment ? comment.comment : ''}</div>
        {comment.user.fullName && comment.updatedAt ? (
          <div style={{ marginTop: '10px', fontSize: '9px' }}>
            <em>
              <span style={{ whiteSpace: 'nowrap' }}>{comment ? getInitials(comment.user.fullName) : ''}</span> -{' '}
              <span style={{ whiteSpace: 'nowrap' }}>{comment.updatedAt ? convertAllDates(comment.updatedAt, 'P') : ''}</span>
            </em>
          </div>
        ) : null}
      </Box>
    );
  };

  const getAmendmentBackground = (amendments: IDocuments[]) => {
    if (!amendments.length) return null;
    let allCompleted = true;
    for (const amendment of amendments) {
      if (amendment.status === 'Sent') return outstandingChequeTypes.notReceived;
      if (amendment.status === 'Signed') return outstandingChequeTypes.replacement;
      if (amendment.status !== 'Completed') allCompleted = false;
    }
    return allCompleted ? outstandingChequeTypes.received : outstandingChequeTypes.notReceived;
  };

  const getCheckTypeCell = (deposit: any) => {
    if (deposit.amount === 0) return;
    return (
      <div>
        <div style={{ whiteSpace: 'nowrap' }}>
          Status: {deposit.chequeType ? capitalizeFirstLetter(deposit.chequeType) : <b>Not Received</b>}
        </div>
        <div style={{ whiteSpace: 'nowrap' }}>Amount: ${numberWithCommas(deposit.amount)}</div>
        <div style={{ whiteSpace: 'nowrap' }}>
          {deposit.chequeType && deposit.chequeType !== 'wire' ? 'Cheque Date' : 'Due Date'}:{' '}
          {deposit.chequeType && deposit.chequeType !== 'wire'
            ? deposit.chequeDate
              ? new Date(deposit.chequeDate).toLocaleDateString('en-US', { month: 'long', year: 'numeric', day: 'numeric' })
              : 'Not Received'
            : new Date(deposit.dueDate).toLocaleDateString('en-US', { month: 'long', year: 'numeric', day: 'numeric' })}
        </div>
      </div>
    );
  };

  const columns = useMemo(() => {
    const handleRealtorSort = (rowA: any, rowB: any, id: string, desc: any) => {
      if (!rowA.original.realtor.length) {
        return -1;
      }
      if (rowA.original.realtor.length && rowB.original.realtor.length) {
        const a = rowA.original.realtor[0].fullName.toLowerCase();
        const b = rowB.original.realtor[0].fullName.toLowerCase();

        if (a > b) return 1;

        if (b > a) return -1;

        return 0;
      }
    };
    const handlePurchasersSort = (rowA: any, rowB: any, id: string, desc: any) => {
      if (rowA.original.purchasers.length && rowB.original.purchasers.length) {
        const a = rowA.original.purchasers[0].fullName.toLowerCase();
        const b = rowB.original.purchasers[0].fullName.toLowerCase();

        if (a > b) return 1;

        if (b > a) return -1;

        return 0;
      }
    };
    let columns = [
      {
        Header: 'Suite',
        accessor: (rowData: any) => rowData.unit.suite,
        Cell: (props: any) => {
          return (
            <Box sx={{ p: 2 }}>
              <Link
                rel="noopener noreferrer"
                target="_blank"
                style={{ textDecoration: 'none', color: 'inherit' }}
                to={`/${project._id}/dashboard/${props.cell.row.original.unit._id}`}
              >
                <strong style={{ cursor: 'pointer' }}>{props.value}</strong>
              </Link>
            </Box>
          );
        },
      },
      {
        Header: 'Status',
        accessor: (rowData: any) => rowData.unit.status,
        Cell: (props: any) => {
          return <Box sx={{ p: 2 }}>{props.value}</Box>;
        },
      },
      {
        Header: 'Status Date',
        accessor: (rowData: any) => getStatusDate(rowData),
        Cell: (props: any) => {
          return <Box sx={{ p: 2 }}>{props.value}</Box>;
        },
      },
      {
        Header: 'Purchasers',
        width: 450,
        sortType: handlePurchasersSort,
        accessor: (rowData: any) => {
          let purchasers = rowData.purchasers.map((purchaser: IPurchaserInfo, index: number) => {
            return (
              <Box key={index} sx={{ mb: 1 }}>
                <Box>{purchaser.fullName}</Box>
                <Box>{purchaser.email}</Box>
                <Box>{purchaser.primaryPhone}</Box>
              </Box>
            );
          });
          return <FlexBetween sx={{ flexWrap: 'wrap' }}>{purchasers}</FlexBetween>;
        },
        Cell: (props: any) => {
          return <Box sx={{ p: 2, whiteSpace: 'pre-line' }}>{props.value}</Box>;
        },
      },
      {
        Header: 'Realtor',
        width: '300',
        sortType: handleRealtorSort,
        accessor: (rowData: any) => {
          if (rowData.realtor.length) {
            let realtors = rowData.realtor.map((realtor: IDealRealtor, index: number) => {
              return (
                <Box key={index} sx={{ mb: 1 }}>
                  <Box>{realtor.fullName}</Box>
                  <Box>{realtor.email}</Box>
                  <Box>{realtor.directPhone}</Box>
                </Box>
              );
            });
            return <FlexBetween sx={{ flexWrap: 'wrap' }}>{realtors}</FlexBetween>;
          } else {
            if (rowData.tags.includes('Friends and Family')) {
              return 'Friends and Family';
            } else return 'Public';
          }
        },
        Cell: (props: any) => {
          return <Box sx={{ p: 2, whiteSpace: 'pre-line' }}>{props.value}</Box>;
        },
      },
      {
        Header: 'Mortgage',
        accessor: (rowData: any) => {
          if (
            (rowData.mortgage.amount && rowData.mortgage.getUrl && rowData.mortgage.lender && !rowData.mortgage.revisions.length) ||
            rowData.mortgage.revisions.includes('No MPA Required')
          ) {
            return <CheckIcon />;
          } else {
            let currentDeposit = rowData.deposit;
            if (rowData.pendingDeposits.length) {
              currentDeposit = rowData.pendingDeposits;
            }
            let totalDeposits = currentDeposit.reduce((a: any, b: any) => {
              return a + b.amount;
            }, 0);
            return (
              <Box>
                <Box>{`$${numberWithCommas(rowData.totalPrice - totalDeposits)}`}</Box>
                <Box>
                  {rowData.mortgage.revisions.map((revision: string, index: number) => {
                    return (
                      <Box key={index} sx={{ fontSize: '10px' }}>
                        {revision}
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            );
          }
        },
        Cell: (props: any) => {
          let backgroundColor =
            (props.row.original.mortgage.amount &&
              props.row.original.mortgage.getUrl &&
              props.row.original.mortgage.lender &&
              !props.row.original.mortgage.revisions.length) ||
            props.row.original.mortgage.revisions.includes('No MPA Required')
              ? 'rgb(146, 255, 146)'
              : 'rgb(255, 136, 136)';
          return (
            <Box
              sx={{
                backgroundColor: backgroundColor,
                height: '100%',
              }}
            >
              <Box sx={{ textAlign: 'center', position: 'relative', top: '50%', transform: 'translateY(-50%)' }}>{props.value}</Box>
            </Box>
          );
        },
      },
      {
        Header: 'Mortgage Notes',
        width: 400,
        accessor: (rowData: any) => {
          if (rowData.comments.length) {
            let mortgageComment = rowData.comments.find((comment: IComment) => comment.type === 'mortgage' && comment.pinned);
            if (!mortgageComment) {
              mortgageComment = rowData.comments.filter((comment: IComment) => comment.type === 'mortgage');
              if (mortgageComment.length) {
                mortgageComment = mortgageComment.reduce((a: any, b: any) => (a.createdAt > b.createdAt ? a : b));
              } else {
                mortgageComment = null;
              }
            }

            if (mortgageComment) {
              return (
                <Box sx={{ p: 2, cursor: 'pointer', height: '100%' }} onClick={() => viewModal(rowData, 'mortgage')}>
                  {comment(mortgageComment)}
                </Box>
              );
            } else return <Box onClick={() => viewModal(rowData, 'mortgage')} sx={{ height: '100%', cursor: 'pointer' }}></Box>;
          } else return <Box onClick={() => viewModal(rowData, 'mortgage')} sx={{ height: '100%', cursor: 'pointer' }}></Box>;
        },
      },
      {
        Header: 'Amendments',
        width: 400,
        accessor: (rowData: any) => {
          if (rowData.documents.length) {
            let amendments = rowData.documents.map((document: any, index: number) => {
              return (
                <Box key={index} sx={{ fontSize: '11px' }}>
                  {document.name} - {document.status}
                </Box>
              );
            });
            return <Box>{amendments}</Box>;
          } else return '';
        },
        Cell: (props: any) => {
          let backgroundColor = getAmendmentBackground(props.row.original.documents);
          return (
            <Box
              sx={{
                height: '100%',
                backgroundColor: backgroundColor,
              }}
            >
              <Box sx={{ position: 'relative', p: 2 }}>{props.value}</Box>
            </Box>
          );
        },
      },
      {
        Header: 'Amendment Notes',
        width: 400,
        accessor: (rowData: any) => {
          if (rowData.comments.length) {
            let amendmentComment = rowData.comments.find((comment: IComment) => comment.type === 'amendment' && comment.pinned);
            if (!amendmentComment) {
              amendmentComment = rowData.comments.filter((comment: IComment) => comment.type === 'amendment');
              if (amendmentComment.length) {
                amendmentComment = amendmentComment.reduce((a: any, b: any) => (a.createdAt > b.createdAt ? a : b));
              } else {
                amendmentComment = null;
              }
            }

            if (amendmentComment) {
              return (
                <Box sx={{ p: 2, cursor: 'pointer', height: '100%' }} onClick={() => viewModal(rowData, 'amendment')}>
                  {comment(amendmentComment)}
                </Box>
              );
            } else return <Box onClick={() => viewModal(rowData, 'amendment')} sx={{ height: '100%', cursor: 'pointer' }}></Box>;
          } else return <Box onClick={() => viewModal(rowData, 'amendment')} sx={{ height: '100%', cursor: 'pointer' }}></Box>;
        },
      },
    ];

    let deposits = activeDeals.map((deal: IDealComment) => deal.deposit.length);
    let largestIndex = Math.max(...deposits);
    let selectedDeal = activeDeals.find((deal: any) => deal.deposit.length === largestIndex);

    let depositColumns: any[] = [];

    if (selectedDeal) {
      let currentDeposit = selectedDeal.deposit;
      if (selectedDeal.pendingDeposits.length) {
        currentDeposit = selectedDeal.pendingDeposits;
      }
      depositColumns = currentDeposit.map((deposit: any, index: number) => {
        return {
          Header: `${deposit.name}`,
          width: 300,
          accessor: (rowData: any) => {
            let rowDeposit = rowData.deposit;
            if (rowData.pendingDeposits.length) {
              rowDeposit = rowData.pendingDeposits;
            }
            if (rowDeposit[index]) {
              return (
                <Box
                  sx={{ height: '100%', cursor: 'pointer', p: 2 }}
                  onClick={() => viewModal(rowData, 'depositChange', rowDeposit[index])}
                >
                  {getCheckTypeCell(rowDeposit[index])}
                </Box>
              );
            } else return '';
          },
          Cell: (props: any) => {
            let rowDeposit = props.row.original.deposit;
            if (props.row.original.pendingDeposits.length) {
              rowDeposit = props.row.original.pendingDeposits;
            }
            let backgroundColor = '#fff';
            if (rowDeposit[index] && rowDeposit[index].amount) {
              backgroundColor = outstandingChequeTypes[rowDeposit[index].chequeType];
              if (!backgroundColor) {
                backgroundColor = '#ff8888';
              }
            }

            return (
              <Box
                sx={{
                  height: '100%',
                  backgroundColor: backgroundColor,
                }}
              >
                <Box sx={{ position: 'relative', p: 2 }}>{props.value}</Box>
              </Box>
            );
          },
        };
      });
    }

    let additionalDeposits = [
      {
        Header: `Additional Deposits`,
        accessor: (rowData: any) => {
          let additional = rowData.additionalDeposits.map((deposit: any, index: number) => {
            return (
              <div key={index}>
                {deposit.name} - {deposit.chequeType ? capitalizeFirstLetter(deposit.chequeType) : 'Not Received'}
              </div>
            );
          });
          return additional;
        },
      },
      {
        Header: 'Deposit Notes',
        width: 400,
        accessor: (rowData: any) => {
          if (rowData.comments.length) {
            let depositComment = rowData.comments.find((comment: IComment) => comment.type === 'deposit' && comment.pinned);
            if (!depositComment) {
              depositComment = rowData.comments.filter((comment: IComment) => comment.type === 'deposit');
              if (depositComment.length) {
                depositComment = depositComment.reduce((a: any, b: any) => (a.createdAt > b.createdAt ? a : b));
              } else {
                depositComment = null;
              }
            }

            if (depositComment) {
              return (
                <Box sx={{ p: 2, cursor: 'pointer', height: '100%' }} onClick={() => viewModal(rowData, 'deposit')}>
                  {comment(depositComment)}
                </Box>
              );
            } else return <Box onClick={() => viewModal(rowData, 'deposit')} sx={{ height: '100%', cursor: 'pointer' }}></Box>;
          } else return <Box onClick={() => viewModal(rowData, 'deposit')} sx={{ height: '100%', cursor: 'pointer' }}></Box>;
        },
      },
    ];

    return [...columns, ...depositColumns, ...additionalDeposits];
  }, [activeDeals]);

  const tooltip = () => {
    return (
      <Flex>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {Object.keys(outstandingChequeTypes).map((key, index: number) => (
            <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <div
                style={{
                  backgroundColor: outstandingChequeTypes[key],
                  width: '20px',
                  height: '10px',
                  border: '1px solid black',
                  marginRight: '10px',
                }}
              ></div>
              <span>{capitalizeFirstLetter(key)}</span>
            </Box>
          ))}
        </div>
      </Flex>
    );
  };

  const handleCompleted = () => {
    let deals: any = activeDeals.filter((deal: IDeal) => {
      let amendments = deal.documents.every((document: any) => document.status === 'Completed');
      let currentDeposit = deal.deposit;
      if (deal.pendingDeposits.length) {
        currentDeposit = deal.pendingDeposits;
      }
      let deposits = currentDeposit.every(
        (deposit: any) =>
          deposit.amount && (deposit.chequeType === 'received' || deposit.chequeType === 'distributed' || deposit.chequeType === 'cleared')
      );

      return (
        !deposits ||
        !amendments ||
        !deal.mortgage.amount ||
        !deal.mortgage.getUrl ||
        !deal.mortgage.lender ||
        deal.mortgage.revisions.length
      );
    });
    if (checked) {
      setActiveDeals(allDeals);
    } else {
      setActiveDeals(deals);
    }
    setChecked(!checked);
  };

  const viewModal = (deal: any, type: string, deposit: any = null) => {
    if (deposit) {
      setSelectedDeposit(deposit);
    }
    setSelectedDeal(deal);
    setType(type);
    storeDispatch(handleModal(true));
  };

  const handleComments = (comments: IComment[]) => {
    let updatedActiveDeals = activeDeals.map((deal: any) => {
      if (deal._id === selectedDeal._id) {
        return {
          ...deal,
          comments: comments,
        };
      } else return deal;
    });
    setActiveDeals(updatedActiveDeals);
    storeDispatch(handleModal(false));
    setSelectedDeal(null);
  };

  const download = (type: string, data: any) => {
    let deposits = activeDeals.map((deal: IDealComment) => deal.deposit.length);
    let largestIndex = Math.max(...deposits);
    let selectedDeal = activeDeals.find((deal: any) => deal.deposit.length === largestIndex);

    let unique = selectedDeal?.deposit.map((deposit: any) => deposit.name);

    let allData = data.map((deal: any) => {
      let totalDeposits = deal.deposit.reduce((a: any, b: any) => {
        return a + b.amount;
      }, 0);

      let mortgageComment = deal.comments.find((comment: IComment) => comment.type === 'mortgage' && comment.pinned);
      if (!mortgageComment) {
        mortgageComment = deal.comments.filter((comment: IComment) => comment.type === 'mortgage');
        if (mortgageComment.length) {
          mortgageComment = mortgageComment.reduce((a: any, b: any) => (a.createdAt > b.createdAt ? a : b));
        } else {
          mortgageComment = null;
        }
      }

      let amendmentComment = deal.comments.find((comment: IComment) => comment.type === 'amendment' && comment.pinned);
      if (!amendmentComment) {
        amendmentComment = deal.comments.filter((comment: IComment) => comment.type === 'amendment');
        if (amendmentComment.length) {
          amendmentComment = amendmentComment.reduce((a: any, b: any) => (a.createdAt > b.createdAt ? a : b));
        } else {
          amendmentComment = null;
        }
      }

      let depositComment = deal.comments.find((comment: IComment) => comment.type === 'deposit' && comment.pinned);
      if (!depositComment) {
        depositComment = deal.comments.filter((comment: IComment) => comment.type === 'deposit');
        if (depositComment.length) {
          depositComment = depositComment.reduce((a: any, b: any) => (a.createdAt > b.createdAt ? a : b));
        } else {
          depositComment = null;
        }
      }

      let depositObjects = unique?.map((unique: string, index: number) => {
        let selectedDeposit = deal.deposit.find((deposit: any) => deposit.name === unique);
        return {
          [normalToCamel(unique)]: selectedDeposit
            ? `${capitalizeFirstLetter(selectedDeposit.chequeType)} - ${numToCurrency.format(selectedDeposit.amount)} ${
                selectedDeposit.chequeDate
                  ? convertAllDates(selectedDeposit.chequeDate, 'PP')
                  : convertAllDates(selectedDeposit.dueDate, 'PP')
              }`
            : '',
        };
      });

      let mergeObjects = depositObjects?.reduce(function (result, currentObject) {
        for (var key in currentObject) {
          if (currentObject.hasOwnProperty(key)) {
            result[key] = currentObject[key];
          }
        }
        return result;
      }, {});

      let outstandingObject = {
        suite: deal.unit.suite,
        status: deal.unit.status,
        statusDate: getStatusDate(deal),
        purchasers: deal.purchasers
          .map((purchaser: any, index: number) => {
            return `${purchaser.fullName} ${purchaser.email} ${purchaser.primaryPhone}\r\n`;
          })
          .join(','),
        realtor: deal.realtor.length
          ? deal.realtor
              .map((realtor: any, index: number) => {
                return `${realtor.fullName} ${realtor.email} ${realtor.directPhone}\r\n`;
              })
              .join(', ')
          : 'N/A',
        mortgage:
          deal.mortgage.amount && deal.mortgage.getUrl && deal.mortgage.lender && !deal.mortgage.revisions.length
            ? 'Complete'
            : `${numToCurrency.format(deal.totalPrice - totalDeposits)}`,
        mortgageRevisions: deal.mortgage.revisions.length
          ? deal.mortgage.revisions
              .map((revision: string) => {
                return `${revision}`;
              })
              .join(', ')
          : '',
        mortgageNotes: mortgageComment ? mortgageComment.comment : '',
        amendments: deal.documents
          .map((document: any, index: number) => {
            return `${document.name} - ${document.status}\r\n`;
          })
          .join(','),
        amendmentNotes: amendmentComment ? amendmentComment.comment : '',
        depositNotes: depositComment ? depositComment.comment : '',
      };

      return { ...outstandingObject, ...mergeObjects };
    });

    let headers = [
      {
        label: 'Suite',
        id: 'suite',
      },
      {
        label: 'Status',
        id: 'status',
      },
      {
        label: 'Status Date',
        id: 'statusDate',
      },
      {
        label: 'Purchasers',
        id: 'purchasers',
      },
      {
        label: 'Realtor',
        id: 'realtor',
      },
      {
        label: 'Mortgage',
        id: 'mortgage',
      },
      {
        label: 'Mortgage Revisions',
        id: 'mortgageRevisions',
      },
      {
        label: 'Mortgage Notes',
        id: 'mortgageNotes',
      },
      {
        label: 'Amendments',
        id: 'amendments',
      },
      {
        label: 'Amendment Notes',
        id: 'amendmentNotes',
      },
      {
        label: 'Deposit Notes',
        id: 'depositNotes',
      },
    ];

    let depositHeaders = unique?.map((deposit: any) => {
      return {
        label: deposit,
        id: normalToCamel(deposit),
      };
    });

    let allHeaders = [...headers, ...depositHeaders!];

    let excelWidths = unique?.map((unique: any) => {
      return {
        [normalToCamel(unique)]: 15,
      };
    });

    let pdfWidths = unique?.map((unique: any) => {
      return {
        [normalToCamel(unique)]: 300,
      };
    });

    let mergeObjects = pdfWidths?.reduce(function (result, currentObject) {
      for (var key in currentObject) {
        if (currentObject.hasOwnProperty(key)) {
          result[key] = currentObject[key];
        }
      }
      return result;
    }, {});

    let mergeExcelObjects = excelWidths?.reduce(function (result, currentObject) {
      for (var key in currentObject) {
        if (currentObject.hasOwnProperty(key)) {
          result[key] = currentObject[key];
        }
      }
      return result;
    }, {});

    let exWidths = {
      suite: 15,
      status: 15,
      statusDate: 15,
      purchasers: 15,
      realtor: 15,
      mortgage: 15,
      mortgageRevisions: 15,
      mortgageNotes: 15,
      amendments: 15,
      amendmentNotes: 15,
      depositNotes: 15,
    };

    let fixedWidths = {
      suite: 200,
      status: 200,
      statusDate: 200,
      purchasers: 200,
      realtor: 200,
      mortgage: 200,
      mortgageRevisions: 200,
      mortgageNotes: 400,
      amendments: 200,
      amendmentNotes: 400,
      depositNotes: 400,
    };

    let allExcelWidths = {
      ...exWidths,
      ...mergeExcelObjects,
    };

    let allPdfWidths = {
      ...fixedWidths,
      ...mergeObjects,
    };

    let sheetTitle = `${project.name} - Outstandings`;

    if (type === 'excel') {
      downloadExcel([allData], [allHeaders], [], [[allExcelWidths]], [sheetTitle], sheetTitle);
    } else {
      downloadPdf([allData], [allHeaders], [], [allPdfWidths], [sheetTitle], sheetTitle);
    }
  };

  const handleDropdownInput = (e: any) => {
    setSelectedDeposit({
      ...selectedDeposit,
      chequeType: e.target.value,
    });
  };

  const saveDeposit = () => {
    let record: IDealDeposit = {
      chequeType: selectedDeposit.chequeType,
    };

    if (selectedDeposit.chequeType === 'cleared') {
      record.clearedDate = new Date();
    }

    updateDeposit({
      variables: {
        _id: selectedDeposit._id,
        record: record,
      },
    });
  };

  const modalView = () => {
    switch (type) {
      case 'deposit':
      case 'mortgage':
      case 'amendment':
        return (
          <CommentsTable
            title={`${selectedDeal?.unit.suite} - ${capitalizeFirstLetter(type)} Notes`}
            deal={selectedDeal}
            unit={selectedDeal?.unit!}
            setComments={handleComments}
            commentType={'deal'}
            comments={selectedDeal?.comments.filter((comment: IComment) => comment.type === type)}
            commentCategory={type}
          />
        );
      case 'depositChange':
        return (
          <Box>
            <Typography variant="h2" component="div" gutterBottom>
              {selectedDeal.unit.suite} - {selectedDeposit?.name}
              <Box sx={{ my: 2 }}>
                <Dropdown
                  id={'dropdown'}
                  title={'Type'}
                  menuList={chequeTypes.map((chequeType: any) => chequeType.value.toLowerCase())}
                  name={'chequeInfo'}
                  handleSelect={(e: any) => handleDropdownInput(e)}
                  value={selectedDeposit?.chequeType}
                />
              </Box>
              <Button color="success" variant="contained" onClick={() => saveDeposit()}>
                Update
              </Button>
            </Typography>
          </Box>
        );
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      <GlobalModal>{modalView()}</GlobalModal>
      <Flex sx={{ mb: 2 }}>
        <Typography sx={{ mr: 1 }} variant={'h5'}>
          <strong>{userType === 'sales' ? `${user.firstName}'s` : ''} Outstandings</strong>
        </Typography>
        <Tooltip title={tooltip()}>
          <InfoIcon sx={{ alignSelf: 'center' }} />
        </Tooltip>
      </Flex>
      {loading ? (
        <LoadingWrapper title={'Outstandings are loading...'} modal={false} />
      ) : (
        <Box>
          <Box>
            <FormControlLabel
              value="end"
              control={
                <Checkbox required={true} checked={checked} onChange={handleCompleted} inputProps={{ 'aria-label': 'primary checkbox' }} />
              }
              label="Show/Hide Completed Files"
              labelPlacement="end"
            />
          </Box>
          <VirtualizedTable
            itemHeight={150}
            tableHeight={window.innerWidth <= 1280 ? 525 : 750}
            data={activeDeals}
            columns={columns}
            noPadding={true}
            loading={loading || salesLoading}
            hide={true}
            filter={true}
            download={download}
          />
        </Box>
      )}
    </Box>
  );
};

interface ChildProps {
  userType?: string;
}

interface IDealComment extends IDeal {
  comments: IComment[];
}

const UPDATEDEPOSIT = gql`
  mutation depositUpdateById($_id: MongoID!, $record: UpdateByIdDepositInput!) {
    depositUpdateById(_id: $_id, record: $record) {
      record {
        _id
        name
        amount
        dueDate
        chequeDate
        chequeType
        chequeAmount
        clearedDate
      }
    }
  }
`;

const DEALS = gql`
  query getOutstandings($project: MongoID!, $salesRep: MongoID) {
    getOutstandings(project: $project, salesRep: $salesRep) {
      _id
      project {
        _id
      }
      unit {
        _id
        status
        suite
      }
      purchasers {
        fullName
        email
        primaryPhone
      }
      documents {
        status
        name
      }
      realtor {
        email
        fullName
        directPhone
      }
      deposit {
        _id
        name
        amount
        dueDate
        chequeDate
        chequeType
        chequeAmount
      }
      pendingDeposits {
        _id
        name
        amount
        dueDate
        chequeDate
        chequeType
        chequeAmount
      }
      options {
        amount
      }
      tags
      mortgage {
        revisions
        lender
        amount
        getUrl
      }
      additionalDeposits {
        name
        chequeType
      }
      comments {
        _id
        type
        pinned
        comment
        updatedAt
        createdAt
        user {
          fullName
        }
      }
      createdAt
      signDate
      executeDate
      firmDate
      basePrice
      totalPrice
    }
  }
`;

export default Outstandings;
