import { useMemo } from 'react';
import { Box, Paper } from '@mui/material';

import { numToCurrency } from '../../utils/Functions';
import StandardTable from '../tables/StandardTable';

const TotalTable = (props: ChildProps) => {
  const { totalData, adjustments } = props;

  const columns = useMemo(() => {
    let totalColumns = [
      {
        Header: 'Type of Unit',
        accessor: (rowData: any) => {
          return <Box sx={{ fontWeight: rowData.type === 'Total' ? 'bold' : 'normal' }}>{rowData.type}</Box>;
        },
      },
      {
        Header: 'Sold Unit Revenue',
        accessor: (rowData: any) => {
          return (
            <Box sx={{ fontWeight: rowData.type === 'Total' ? 'bold' : 'normal' }}>{numToCurrency.format(rowData.soldUnitRevenue)}</Box>
          );
        },
      },
      {
        Header: 'Sold Unit Revenue Net HST',
        accessor: (rowData: any) => {
          return (
            <Box sx={{ fontWeight: rowData.type === 'Total' ? 'bold' : 'normal' }}>
              {numToCurrency.format(rowData.soldUnitRevenueNetHst)}
            </Box>
          );
        },
      },
      {
        Header: 'Sold Options Revenue',
        accessor: (rowData: any) => {
          return (
            <Box sx={{ fontWeight: rowData.type === 'Total' ? 'bold' : 'normal' }}>{numToCurrency.format(rowData.soldOptionsRevenue)}</Box>
          );
        },
      },
      {
        Header: 'Overall Sold Revenue',
        accessor: (rowData: any) => {
          return (
            <Box sx={{ fontWeight: rowData.type === 'Total' ? 'bold' : 'normal' }}>{numToCurrency.format(rowData.overallSoldRevenue)}</Box>
          );
        },
      },
      {
        Header: 'Overall Sold Revenue Net HST',
        accessor: (rowData: any) => {
          return (
            <Box sx={{ fontWeight: rowData.type === 'Total' ? 'bold' : 'normal' }}>
              {numToCurrency.format(rowData.overallSoldRevenueNetHst)}
            </Box>
          );
        },
      },
      {
        Header: 'Overall Total Revenue PPSF',
        accessor: (rowData: any) => <Box sx={{ fontWeight: rowData.type === 'Total' ? 'bold' : 'normal' }}>{numToCurrency.format(rowData.overallTotalRevenuePPSF)}</Box>,
      },
      {
        Header: 'Overall Total Revenue',
        accessor: (rowData: any) => <Box sx={{ fontWeight: rowData.type === 'Total' ? 'bold' : 'normal' }}>{numToCurrency.format(rowData.overallTotalRevenue)}</Box>,
      },
      {
        Header: 'Overall Total Revenue Net HST',
        accessor: (rowData: any) => <Box sx={{ fontWeight: rowData.type === 'Total' ? 'bold' : 'normal' }}>{numToCurrency.format(rowData.overallTotalRevenueNetHST)}</Box>,
      },
    ];

    let adjustmentsSummary: any[] = [];

    if (adjustments && adjustments.residential.length) {
      adjustmentsSummary = [
        {
          Header: 'Adjustments Total',
          accessor: (rowData: any) => <Box sx={{ fontWeight: rowData.type === 'Total' ? 'bold' : 'normal' }}>{numToCurrency.format(rowData.adjustments)}</Box>,
        },
        {
          Header: 'Adjustments Total Net HST',
          accessor: (rowData: any) => <Box sx={{ fontWeight: rowData.type === 'Total' ? 'bold' : 'normal' }}>{numToCurrency.format(rowData.adjustmentsNetHST)}</Box>,
        },
        {
          Header: 'Overall Total Revenue + Adjustments Total',
          accessor: (rowData: any) => <Box sx={{ fontWeight: rowData.type === 'Total' ? 'bold' : 'normal' }}>{numToCurrency.format(rowData.overallRevenueAdjustments)}</Box>,
        },
        {
          Header: 'Overall Total Revenue + Adjustments Total Net HST',
          accessor: (rowData: any) => <Box sx={{ fontWeight: rowData.type === 'Total' ? 'bold' : 'normal' }}>{numToCurrency.format(rowData.overallRevenueAdjustmentsNetHst)}</Box>,
        },
      ]
    }


    return [...totalColumns, ...adjustmentsSummary]
  }, [adjustments]);

  return (
    <Paper elevation={5}>
      <StandardTable data={totalData} columns={columns} />
    </Paper>
  );
};

interface ChildProps {
  totalData: any;
  unitType: string;
  adjustments: any;
}

export default TotalTable;
