import { FormControl, Grid, InputLabel, Select, MenuItem, Box, Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectProject } from '../../../../features/project/projectSlice';
import Coop from '../../../createProject/Coop';
import { ICoopRate, ICoopStructure } from '../../../../types/project';
import { FlexEnd } from '../../../../commonStyles';

const Commission = (props: ChildProps) => {
  const project = useSelector(selectProject);
  const { coopRates, setCoopRates } = props;

  const addCoopRate = () => {
    setCoopRates([
      ...coopRates,
      {
        type: '',
        days: 0,
        date: null,
        description: '',
        percentage: '1',
        fixedAmount: '0',
      },
    ]);
  };

  const handleCoopStructure = (event: React.ChangeEvent<HTMLInputElement>) => {
    let selectedCoopStructure = project.coopStructures.find((coopStructure: ICoopStructure) => coopStructure.name === event.target.value);
    if (selectedCoopStructure) {
      setCoopRates(selectedCoopStructure.coopRates);
    }
  };

  const handleCoopRateInput = (event: any, numberIndex: number, type: string) => {
    let selectedCoopRates = coopRates.map((coopRate: ICoopRate, index: number) => {
      if (index === numberIndex) {
        return {
          ...coopRate,
          [event.target.name]:
            event.target.name === 'days' || event.target.name === 'percentage' || event.target.name === 'fixedAmount'
              ? parseFloat(event.target.value)
              : event.target.value,
        };
      } else return coopRate;
    });
    setCoopRates(selectedCoopRates);
  };

  const handleCoopDate = (newValue: any, numberIndex: number) => {
    let selectedCoopRates = coopRates.map((coopRate: ICoopRate, index: number) => {
      if (index === numberIndex) {
        return {
          ...coopRate,
          date: newValue,
        };
      } else return coopRate;
    });
    setCoopRates(selectedCoopRates);
  };

  const handleDeleteCoopRate = (numberIndex: number) => {
    setCoopRates(coopRates.filter((coopRate: ICoopRate, index: number) => index !== numberIndex));
  };

  return (
    <Box sx={{ mt: 2 }}>
      <FormControl sx={{ width: '500px' }}>
        <InputLabel id="id-display-label">Select Coop Structure</InputLabel>
        <Select label="Select Coop Structure" name="coopStructure" onChange={(e: any) => handleCoopStructure(e)}>
          {project.coopStructures.map((coopStructure: ICoopStructure, index: number) => {
            return (
              <MenuItem key={index} value={coopStructure.name}>
                {coopStructure.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      {coopRates.length ? (
        <>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            {coopRates.map((coopRate: ICoopRate, numIndex: number) => {
              return (
                <Coop
                  key={numIndex}
                  index={numIndex}
                  coopRate={coopRate}
                  handleCoopType={handleCoopRateInput}
                  handleCoopInput={handleCoopRateInput}
                  handleDeleteCoop={handleDeleteCoopRate}
                  handleCoopDate={handleCoopDate}
                />
              );
            })}
          </Grid>
          <FlexEnd sx={{ mt: 1 }}>
            <Button color="primary" variant="contained" onClick={() => addCoopRate()}>
              Add Coop Rate
            </Button>
          </FlexEnd>
        </>
      ) : null}
    </Box>
  );
};

interface ChildProps {
  coopRates: ICoopRate[];
  setCoopRates: any;
}

export default Commission;
