import { useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { Box, Button, FormControl, TextField, Autocomplete, Typography } from '@mui/material';

import { showSuccessSnackbar } from '../../features/snackbar/snackbarSlice';
import { IRealtorInfo } from '../../types/CreateDealForm';
import RealtorForm from '../common/forms/RealtorForm';
import { IRealtorsArray } from '../../types/CreateDealForm';
import { FlexBetween } from '../../commonStyles';
import { useAppDispatch } from '../../app/hooks';
import { formatPhoneNumber } from '../../utils/Functions';

const AddRealtor = () => {
  const storeDispatch = useAppDispatch();
  const [realtor, setRealtor] = useState<IRealtorInfo | {}>({});
  const [errors, setErrors] = useState<any>({});
  const [searchInfo, setSearchInfo] = useState<IRealtorInfo[]>([]);

  useQuery<IRealtorsArray>(REALTORS, {
    onCompleted: (data) => setSearchInfo(data.realtorMany),
  });

  const [saveNewRealtor] = useMutation(CREATEREALTOR, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Realtor has been created'));
      setRealtor({});
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const handleRealtorSubmit = (e: any) => {
    e.preventDefault();
    saveNewRealtor({ variables: { record: realtor } });
  };

  const handleTextInput = async (event: any) => {
    let value = event.target.value;
      if (event.target.name === 'brokeragePhone' || event.target.name === 'directPhone' || event.target.name === 'brokerageFax') {
        value = formatPhoneNumber(event.target.value);
      }
    setRealtor({
      ...realtor,
      [event.target.id]: value,
    });
    if (event.target.value === '') {
      setErrors({
        ...errors,
        [event.target.id]: `${event.target.name} cannot be empty`,
      });
    } else {
      setErrors({
        ...errors,
        [event.target.id]: null,
      });
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography sx={{ mb: 2 }} variant={'h5'}>
        <strong>Create Realtor</strong>
      </Typography>
      <Box
        sx={{
          border: '1px solid #000',
          padding: '20px',
          position: 'relative',
          borderRadius: '8px',
        }}
      >
        <FormControl
          sx={{
            width: '400px',
            '& .MuiFormLabel-asterisk': {
              color: 'red',
            },
          }}
        >
          <Autocomplete
            sx={{
              '& .MuiFormLabel-asterisk': {
                color: 'red',
              },
            }}
            id={'search'}
            disableClearable
            freeSolo={false}
            options={searchInfo}
            getOptionLabel={(option: any) => `${option.firstName} ${option.lastName} - ${option.email}`}
            // getOptionSelected={(option, value) => option === value}
            renderInput={(params) => (
              <TextField required={false} {...params} size="small" label={'Search By Name or Email'} margin="normal" />
            )}
          />
        </FormControl>
        <form onSubmit={handleRealtorSubmit} autoComplete="off">
          <RealtorForm realtorInfo={realtor} handleTextInput={handleTextInput} />
          <FlexBetween sx={{ flexDirection: 'row-reverse', mt: 3 }}>
            <Button type="submit" color="success" variant="contained">
              Create Realtor
            </Button>
          </FlexBetween>
        </form>
      </Box>
    </Box>
  );
};

const REALTORS = gql`
  query realtorMany($filter: FilterFindManyRealtorInput) {
    realtorMany(filter: $filter, limit: 10000) {
      _id
      email
      firstName
      lastName
    }
  }
`;

const CREATEREALTOR = gql`
  mutation realtorCreateOne($record: CreateOneRealtorInput!) {
    realtorCreateOne(record: $record) {
      record {
        email
        firstName
        lastName
        streetAddress
        brokerage
        brokerageFax
        brokeragePhone
        city
        province
        postalCode
        directPhone
      }
    }
  }
`;

export default AddRealtor;
