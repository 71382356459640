// export function validateEmail(email: string) {
//   if (!email) {
//     return 'Email address is Required';
//   } else if (!/\S+@\S+\.\S+/.test(email)) {
//     return 'Email address is Invalid';
//   } else {
//     return null;
//   }
// }

export function validateName(name: string) {
  const letters = /^[A-Za-z]+$/;
}

export function validate(values: any) {
  let errors = {} as form;
  if (!values.email) {
    errors.email = 'Email address is required';
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'Email address is invalid';
  }
  return errors;
}

interface form {
  firstName?: string;
  email?: string;
}

export function validateEmail(email: string) {
  let re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
  return re.test(email);
}

export function validateMultipleEmails(emails: string) {
  let splitEmails = emails.split(',');
  
  let invalidEmails = [];
  let validEmails = [];
  
  for (let i = 0; i < splitEmails.length; i++) { 
    if(!validateEmail(splitEmails[i].trim())) {
      invalidEmails.push(splitEmails[i].trim())
    } else {
      validEmails.push(splitEmails[i].trim())
    }
  }

  if (invalidEmails.length > 0) {
    return []
  } else return validEmails 
}

// function validateEmail(email) {
//   var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
//   return re.test(email);
// }


// var string = "abc@abc.com;abc@abc.com; abc@a@bc.com ; abc@abc.com ;abc@abc.com;"

// var emails = string.split(';');

// var invalidEmails = [];

// for (i = 0; i < emails.length; i++) { 
//   if(!validateEmail(emails[i].trim())) {
//     invalidEmails.push(emails[i].trim())
//   }
// }

// alert(invalidEmails);
