import { useMemo } from 'react';
import { gql, useMutation } from '@apollo/client';
import { Box, Paper, Grid, TextField, Button } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Dropzone, { useDropzone } from 'react-dropzone';

import { IAssignment } from '../../../types/assignment';
import { useAppDispatch } from '../../../app/hooks';
import { showErrorSnackbar, showSuccessSnackbar } from '../../../features/snackbar/snackbarSlice';
import PdfCard from '../../common/PdfCard';
import { FlexEnd } from '../../../commonStyles';
import { baseStyle, activeStyle, acceptStyle, rejectStyle } from '../../../utils/Constants';

const AssignmentInformation = (props: ChildProps) => {
  const storeDispatch = useAppDispatch();
  const { information, informationDispatch, assignment } = props;

  const { isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: 'image/jpeg, image/png, application/pdf',
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const [deleteDocuments] = useMutation(DELETEMISCELLANEOUS, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Document has been deleted'));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [addDocuments] = useMutation(ADDMISCELLANEOUS, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Document has been added'));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const handleAddDocuments = async () => {
    await information.miscellaneous.forEach(async (file: any) => {
      if (file.file) {
        addDocuments({ variables: { _id: assignment?._id, name: file.name, file: file.file } });
      }
    });
  };

  const handleDrop = (acceptedFiles: any) => {
    if (acceptedFiles.length === 0) {
      storeDispatch(showErrorSnackbar('This file type is not allowed'));
      return;
    }
    const file = acceptedFiles[0];
    const fileReader = new FileReader();
    if (file) {
      fileReader.readAsDataURL(file);
    }
    fileReader.onloadend = async () => {
      informationDispatch({
        type: 'UPDATE',
        payload: {
          field: 'miscellaneous',
          value: [
            ...information.miscellaneous,
            {
              name: file.name,
              url: fileReader.result,
              file: file,
            },
          ],
        },
      });
    };
  };

  const handleDelete = (numIndex: number, id: string) => {
    if (information.miscellaneous.length && information.miscellaneous[numIndex]._id) {
      deleteDocuments({
        variables: { _id: information._id, documentId: information.miscellaneous[numIndex]._id },
      });
    }
    let filterMiscellaneous = information.miscellaneous.filter((miscellaneous: IMiscellaneous, index: number) => {
      return index !== numIndex;
    });
    informationDispatch({
      type: 'UPDATE',
      payload: {
        field: 'miscellaneous',
        value: filterMiscellaneous,
      },
    });
  };

  return (
    <Box sx={{ mb: 2 }}>
      <Paper elevation={12} sx={{ p: 2 }}>
        <h2 style={{ margin: 0 }}>
          <strong>Agreement Information</strong>
        </h2>
        <Grid sx={{ mt: 1 }} container spacing={2}>
          <Grid item xs={12} sm={3}>
            <TextField
              title={'Total Price'}
              type="number"
              name={'price'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                informationDispatch({
                  type: 'UPDATE',
                  payload: { field: 'price', value: parseInt(e.target.value, 10) },
                })
              }
              value={information.price}
              label={'Total Assignment Price'}
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                inputFormat="yyyy/MM/dd"
                label={'Agreement Completion Date'}
                value={information.signDate}
                onChange={(newValue) =>
                  informationDispatch({
                    type: 'UPDATE',
                    payload: { field: 'signDate', value: newValue },
                  })
                }
                renderInput={(params) => <TextField required fullWidth {...params} />}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        {assignment ? (
          <>
            <Dropzone onDrop={(files) => handleDrop(files)} accept="image/jpg, image/jpeg, image/png, application/pdf">
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps({ style })}>
                    <input {...getInputProps()} />
                    <p>Drag and Drop or Upload Assignments or Documents</p>
                  </div>
                </section>
              )}
            </Dropzone>
            <div>
              <Grid
                container
                spacing={2}
                style={{
                  margin: 0,
                  width: '100%',
                }}
              >
                {information.miscellaneous.length > 0
                  ? information.miscellaneous.map((attachment: any, index: number) => {
                      return (
                        <Grid key={index} item lg={3} md={4} sm={6} xs={12}>
                          <PdfCard
                            file={attachment.getUrl ? attachment.getUrl : attachment.url}
                            title={attachment.name ? attachment.name : attachment.file.name}
                            id={index}
                            handleDelete={handleDelete}
                            download={true}
                            index={index}
                          />
                        </Grid>
                      );
                    })
                  : null}
              </Grid>
            </div>
            <FlexEnd>
              <Button color="success" variant="contained" onClick={() => handleAddDocuments()}>
                Save Documents
              </Button>
            </FlexEnd>
          </>
        ) : null}
      </Paper>
    </Box>
  );
};

interface ChildProps {
  information: any;
  informationDispatch: any;
  assignment: IAssignment | null;
}

interface IMiscellaneous {
  url: string;
  file: any;
  name: string;
  putUrl: string;
  getUrl: string;
  size?: number;
}

const DELETEMISCELLANEOUS = gql`
  mutation deleteMiscellaneous($_id: MongoID!, $documentId: MongoID!) {
    deleteMiscellaneous(_id: $_id, documentId: $documentId) {
      _id
      miscellaneous {
        _id
        name
      }
    }
  }
`;

const ADDMISCELLANEOUS = gql`
  mutation addMiscellaneous($_id: MongoID!, $name: String!, $file: Upload!) {
    addMiscellaneous(_id: $_id, name: $name, file: $file) {
      _id
      miscellaneous {
        _id
        name
        getUrl
      }
    }
  }
`;

export default AssignmentInformation;
