import React from 'react';
import subYears from 'date-fns/subYears';
import { Autocomplete, Box, Grid, Button, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import TextInput from '../formControls/TextInput';
import GooglePlaces from '../formControls/GooglePlaces';
import { IPurchaserInfo, ISigningOfficer } from '../../../types/CreateDealForm';
import { idTypes, idIssuedBy, endvestor, proofTypes } from '../../../utils/Constants';
import SigningOfficerForm from './SigningOfficerForm';
import { Flex } from '../../../commonStyles';

const PurchaserForm = (props: ChildProps) => {
  const {
    pInfo,
    handleTextInput,
    handleDateChange,
    handleEndvestorInput,
    handleIdTypeInput,
    handleIdIssuedByInput,
    handleDeleteSigningOfficer,
    type,
    disabled,
    handleDetails,
    handleStreetAddress,
    handleSigningOfficerInput,
    addSigningOfficer,
    handleDob,
    controlled,
    required,
  } = props;

  return (
    <>
      <Grid
        sx={{
          '& .MuiFormLabel-root.Mui-disabled': {
            color: 'rgba(0, 0, 0)',
          },
          '& .MuiInputBase-root.Mui-disabled': {
            color: 'rgba(0, 0, 0)',
          },
        }}
        container
        spacing={2}
      >
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <TextInput
            title={pInfo.corp ? 'Corporation Name/Number' : 'Purchaser First Name'}
            name={'firstName'}
            handleTextInput={(e: any) => handleTextInput(e, type)}
            value={pInfo.firstName}
            disabled={disabled}
            controlled={controlled ? false : true}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <TextInput
            title={pInfo.corp ? 'Corporation Structure (ex: Inc, Holdings)' : 'Purchaser Last Name'}
            name={'lastName'}
            handleTextInput={(e: any) => handleTextInput(e, type)}
            value={pInfo.lastName}
            required={true}
            disabled={disabled}
            controlled={controlled ? false : true}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              inputFormat="yyyy/MM/dd"
              label={'DOB (YYYY/MM/DD)'}
              value={pInfo.dob}
              onChange={(newValue) => handleDateChange(newValue, 'dob', type)}
              maxDate={!pInfo.corp ? subYears(new Date(), 18) : new Date()}
              renderInput={(params) => <TextField {...params} fullWidth />}
              disabled={disabled}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <TextInput
            title={'Unit/Apt Number'}
            name={'unit'}
            handleTextInput={(e: any) => handleTextInput(e, type)}
            value={pInfo.unit}
            disabled={disabled}
            controlled={controlled ? false : true}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <GooglePlaces
            disabled={disabled}
            name={'streetAddress'}
            handleDetails={handleDetails}
            handleStreetAddress={handleStreetAddress}
            address={pInfo.streetAddress}
            type={type!}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <TextInput
            title={'City'}
            name={'city'}
            handleTextInput={(e: any) => handleTextInput(e, type)}
            value={pInfo.city}
            required={true}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <TextInput
            title={'Province/State'}
            name={'province'}
            handleTextInput={(e: any) => handleTextInput(e, type)}
            value={pInfo.province}
            required={true}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <TextInput
            title={'Country'}
            name={'country'}
            handleTextInput={(e: any) => handleTextInput(e, type)}
            value={pInfo.country}
            required={true}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <TextInput
            title={'Postal/Zip Code'}
            name={'postalCode'}
            handleTextInput={(e: any) => handleTextInput(e, type)}
            value={pInfo.postalCode}
            required={true}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <Autocomplete
            options={idTypes}
            title={'ID Type'}
            id="idType"
            freeSolo={false}
            value={pInfo.idType}
            onChange={(e: any) => handleIdTypeInput(e, 'idType', type)}
            renderInput={(params) => <TextField name="idType" {...params} label="ID Type" />}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <TextInput
            title={pInfo.corp ? 'Corporation ID Number' : 'ID Number'}
            name={'idNumber'}
            handleTextInput={(e: any) => handleTextInput(e, type)}
            value={pInfo.idNumber}
            required={required ? false : true}
            disabled={disabled}
            controlled={controlled ? false : true}
          />
        </Grid>
        {!pInfo.corp ? (
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <Autocomplete
              options={idIssuedBy}
              title={'ID Issued By'}
              freeSolo={false}
              value={pInfo.idJurisdiction}
              disabled={disabled}
              onChange={(e) => handleIdIssuedByInput(e, type)}
              renderInput={(params) => <TextField {...params} label="ID Issued By" />}
            />
          </Grid>
        ) : null}
        {!pInfo.corp && pInfo.idType !== 'Citizenship Card' ? (
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                inputFormat="yyyy/MM/dd"
                label={'ID Expiry (YYYY/MM/DD)'}
                value={pInfo.idExpiry}
                onChange={(newValue) => handleDateChange(newValue, 'idExpiry', type)}
                minDate={new Date()}
                renderInput={(params) => <TextField fullWidth {...params} />}
                disabled={disabled}
              />
            </LocalizationProvider>
          </Grid>
        ) : null}
        {!pInfo.corp && pInfo.idType !== 'Passport' && pInfo.idType !== 'Permanent Resident Card' ? (
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <Autocomplete
              options={proofTypes}
              title={'Proof Type'}
              freeSolo={false}
              value={pInfo.proofType}
              onChange={(e: any) => handleIdTypeInput(e, 'proofType', type)}
              renderInput={(params) => <TextField name="proofType" {...params} label="Proof Type" />}
            />
          </Grid>
        ) : null}
        {!pInfo.corp && pInfo.idType !== 'Passport' && pInfo.idType !== 'Permanent Resident Card' ? (
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <TextInput
              title={'Proof ID Number'}
              name={'proofNumber'}
              handleTextInput={(e: any) => handleTextInput(e, type)}
              value={pInfo.proofNumber}
              required={false}
              disabled={disabled}
              controlled={controlled ? false : true}
            />
          </Grid>
        ) : null}
        {!pInfo.corp && pInfo.idType !== 'Passport' && pInfo.idType !== 'Permanent Resident Card' ? (
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                inputFormat="yyyy/MM/dd"
                label={'Proof Expiry (YYYY/MM/DD)'}
                value={pInfo.proofExpiry}
                onChange={(newValue) => handleDateChange(newValue, 'proofExpiry', type)}
                minDate={new Date()}
                renderInput={(params) => <TextField fullWidth {...params} />}
                disabled={disabled}
              />
            </LocalizationProvider>
          </Grid>
        ) : null}
        {!pInfo.corp ? (
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <TextInput
              title={'SIN (xxx xxx xxx)'}
              name={'sin'}
              handleTextInput={(e: any) => handleTextInput(e, type)}
              value={pInfo.sin}
              disabled={disabled}
              controlled={controlled ? false : true}
            />
          </Grid>
        ) : null}
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <TextInput
            title={'Phone Number (xxx-xxx-xxxx)'}
            name={'primaryPhone'}
            type="tel"
            handleTextInput={(e: any) => handleTextInput(e, type)}
            value={pInfo.primaryPhone}
            required={true}
            disabled={disabled}
            controlled={controlled ? false : true}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <TextInput
            title={'Email'}
            type={'email'}
            name={'email'}
            handleTextInput={(e: any) => handleTextInput(e, type)}
            value={pInfo.email}
            required={true}
            disabled={disabled}
            controlled={controlled ? false : true}
          />
        </Grid>
        {!pInfo.corp ? (
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <TextInput
              title={'Occupation'}
              name={'occupation'}
              handleTextInput={(e: any) => handleTextInput(e, type)}
              value={pInfo.occupation}
              required={required ? false : true}
              disabled={disabled}
              controlled={controlled ? false : true}
            />
          </Grid>
        ) : null}
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <TextInput
            title={'Employer'}
            name={'employer'}
            handleTextInput={(e: any) => handleTextInput(e, type)}
            value={pInfo.employer}
            required={required ? false : true}
            disabled={disabled}
            controlled={controlled ? false : true}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <Autocomplete
            options={endvestor}
            title={'Endvestor'}
            freeSolo={false}
            value={pInfo.purchaserType}
            disabled={disabled}
            onChange={(e) => handleEndvestorInput(e, type)}
            renderInput={(params) => <TextField required {...params} label="Endvestor" />}
          />
        </Grid>
        {pInfo.corp ? (
          <>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <TextInput
                title={'Directors'}
                name={'directors'}
                handleTextInput={(e: any) => handleTextInput(e, type)}
                value={pInfo.directors}
                required={required ? false : true}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <TextInput
                title={'Business Number'}
                name={'businessNumber'}
                handleTextInput={(e: any) => handleTextInput(e, type)}
                value={pInfo.businessNumber}
                disabled={disabled}
              />
            </Grid>
          </>
        ) : null}
      </Grid>
      {pInfo.corp ? (
        <div>
          {pInfo.signingOfficers.map((signingOfficer: ISigningOfficer, index: number) => {
            return (
              <Box sx={{ mt: 2 }}>
                <Flex>
                  <Box sx={{ mr: 1 }}>
                    <strong>Signing Officers</strong>
                  </Box>
                  <Box sx={{ alignSelf: 'center' }}>
                    <CloseIcon sx={{ cursor: 'pointer' }} color="secondary" onClick={() => handleDeleteSigningOfficer(index, type)} />
                  </Box>
                </Flex>
                <Box sx={{ mt: 2 }}>
                  <SigningOfficerForm
                    type={type}
                    index={index}
                    signingOfficer={signingOfficer}
                    handleSigningOfficerInput={handleSigningOfficerInput}
                    handleDob={handleDob}
                  />
                </Box>
              </Box>
            );
          })}
          <Button sx={{ mt: 1 }} variant="contained" color="primary" onClick={() => addSigningOfficer(type)}>
            Add Signing Officer
          </Button>
        </div>
      ) : null}
    </>
  );
};

interface ChildProps {
  pInfo: IPurchaserInfo;
  handleTextInput: any;
  handleDateChange: any;
  handleEndvestorInput: any;
  handleIdTypeInput: any;
  handleIdIssuedByInput: any;
  handleDetails: (city: string, province: string, postalCode: string, country: string, streetAddress: string, type: number) => void;
  handleStreetAddress?: any;
  handleSigningOfficerInput?: any;
  handleDeleteSigningOfficer?: any;
  addSigningOfficer?: any;
  handleDob?: any;
  disabled?: boolean;
  type?: number;
  controlled?: boolean;
  required?: boolean;
}

export default PurchaserForm;
