import React, { useContext, useState, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Box, Grid, Button, Checkbox, FormControlLabel } from '@mui/material';

import { FlexBetween, FlexEnd } from '../../../../commonStyles';
import { UnitContext } from '../../../../context/UnitContext';
import { DealContext } from '../../../../context/DealContext';
import { numToCurrency, createPdf } from '../../../../utils/Functions';
import { useSelector } from 'react-redux';
import { selectProject } from '../../../../features/project/projectSlice';
import { selectUser } from '../../../../features/auth/authSlice';
import { IOption } from '../../../../types/project';
import DealDeposits from '../../DealDeposits';
import Dropdown from '../../../common/formControls/Dropdown';
import TextInput from '../../../common/formControls/TextInput';
import { accessAllowed } from '../../../../features/project/projectHooks';
import { IMergeData, IMerge } from '../../../../types/merge';
import { useAppDispatch } from '../../../../app/hooks';
import { showErrorSnackbar } from '../../../../features/snackbar/snackbarSlice';
import { IDealDeposit } from '../../../../types/CreateDealForm';

const PendingOptions = (props: ChildProps) => {
  const { setErrors, errors, totalOptionsPrice } = props;
  const storeDispatch = useAppDispatch();
  const project = useSelector(selectProject);
  const user = useSelector(selectUser);
  const [selectedOption, setSelectedOption] = useState('');
  const { unit, filteredDeal } = useContext(UnitContext);
  const {
    document,
    pendingOptions,
    optionValue,
    handleOptionDropdown,
    handleOptionInput,
    keepDeposits,
    setKeepDeposits,
    depositList,
    selectedDeposit,
    setSelectedDeposit,
    pendingDeposits,
    setPendingDeposits,
    addPendingDeposits,
    setPendingOptions,
    createDepositAmendment,
    rental,
    setRental,
  } = useContext(DealContext);
  const [optionMerges, setOptionMerges] = useState<any[]>([]);

  useQuery<IMergeData>(MERGETEMPLATES, {
    variables: { filter: { project: project._id, type: 'Options' } },
    onCompleted: (data) => {
      setOptionMerges(data.mergeTemplateMany);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  useEffect(() => {
    if (unit.hasOwnProperty('rental') && unit.rental !== null) {
      setRental(
        pendingOptions.reduce((a: any, b: any) => {
          return a + b.rental * b.purchaseAmount;
        }, unit.rental)
      );
    }
  }, [pendingOptions]);

  const handleOptionsSubmit = async (e: any) => {
    e.preventDefault();
    if (!selectedOption) {
      storeDispatch(showErrorSnackbar(`No Amendment Selected`));
      return;
    }
    if (errors) {
      storeDispatch(showErrorSnackbar(`Invalid Date`));
      return;
    }

    let optionsAmendment = optionMerges.find((merge: IMerge) => {
      return merge.name === selectedOption;
    });

    if (!optionsAmendment) {
      storeDispatch(showErrorSnackbar(`No option amendment found`));
      return;
    }

    let pdf = await createPdf(
      optionsAmendment,
      project,
      { ...unit, rental: filteredDeal.pendingRental ? filteredDeal.pendingRental : unit.rental ? unit.rental : null },
      filteredDeal.purchasers,
      filteredDeal.deposit,
      filteredDeal.options,
      null,
      { ...unit, rental: rental },
      pendingOptions,
      pendingDeposits,
      filteredDeal,
      null,
      false,
      [],
      [],
      true
    );

    let sign = optionsAmendment?.signFields.map((sign: any) => {
      return {
        index: sign.index,
        key: sign.key,
        pageNumber: sign.pageNumber,
        x: sign.x,
        y: sign.y,
      };
    });

    const args = {
      status: 'Prepared',
      name: optionsAmendment?.name,
      project: project._id,
      deal: filteredDeal._id,
      signFields: sign,
      // signers,
      type: 'Options',
      isAPS: false,
    };

    let newDeposit = pendingDeposits.map((pendingDeposits: IDealDeposit, index: number) => {
      let chequeType =
        pendingDeposits.dateType === '90 Days Before Firm Occupancy' ||
        pendingDeposits.dateType === '120 Days Before Firm Occupancy' ||
        pendingDeposits.dateType === '180 Days Before Firm Occupancy' ||
        pendingDeposits.dateType === 'Floating Date'
          ? ' floating'
          : '';
      if (
        filteredDeal.deposit[index] &&
        pendingDeposits.amount === filteredDeal.deposit[index].amount &&
        pendingDeposits.name === filteredDeal.deposit[index].name &&
        new Date(pendingDeposits.dueDate!).setHours(0, 0, 0, 0) === new Date(filteredDeal.deposit[index].dueDate).setHours(0, 0, 0, 0)
      ) {
        return {
          accountNumber: filteredDeal.deposit[index].accountNumber,
          chequeAmount: filteredDeal.deposit[index].chequeAmount,
          chequeDate: filteredDeal.deposit[index].chequeDate,
          chequeNumber: filteredDeal.deposit[index].chequeNumber,
          chequeType: filteredDeal.deposit[index].chequeType ? filteredDeal.deposit[index].chequeType : chequeType,
          amount: filteredDeal.deposit[index].amount,
          deal: filteredDeal._id,
          dueDate: filteredDeal.deposit[index].dueDate,
          dateType: pendingDeposits.dateType ? pendingDeposits.dateType : null,
          name: filteredDeal.deposit[index].name,
        };
      } else {
        return {
          accountNumber: pendingDeposits.accountNumber,
          amount: pendingDeposits.amount,
          deal: filteredDeal._id,
          dueDate: pendingDeposits.dueDate,
          name: pendingDeposits.name,
          chequeType: chequeType,
          dateType: pendingDeposits.dateType ? pendingDeposits.dateType : null,
        };
      }
    });

    let newOptions = pendingOptions
      .map((pendingOption: any) => {
        return {
          ...pendingOption,
          purchaseAmount: parseFloat(pendingOption.purchaseAmount),
        };
      })
      .map(({ rental, ...attrs }: any) => attrs);

    let removeEmptyOptions: any[] = [];

    if (newOptions.length > 0) {
      removeEmptyOptions = newOptions.filter((options: any) => options.purchaseAmount).map(({ rental, ...attrs }: any) => attrs);
    }

    setPendingOptions(removeEmptyOptions);

    await createDepositAmendment({
      variables: {
        type: 'option',
        dealId: filteredDeal._id,
        records: pendingDeposits.length ? newDeposit : [],
        record: args,
        file: pdf,
        pendingBasePrice: null,
        pendingRental: rental ? parseInt(rental, 10) : null,
        depositName: selectedDeposit,
        options: removeEmptyOptions,
      },
    });
  };

  const rentalCalculation = () => {
    if (pendingOptions.length) {
      let totalRental = pendingOptions.reduce((a: any, b: any) => {
        return a + b.rental * b.purchaseAmount;
      }, 0);
      return totalRental;
    } else return 0;
  };

  return (
    <form onSubmit={handleOptionsSubmit} autoComplete="off">
      <Box>
        {pendingOptions.length > 0 && document === 'Options' && filteredDeal.pendingOptions.length === 0 ? (
          <>
            <h3>Option Amendment</h3>
            <Box sx={{ mt: 2 }}>
              <div>
                Unit Price: <strong>{numToCurrency.format(unit.basePrice)}</strong>
              </div>
              <div>
                Total Options Price: <strong>{numToCurrency.format(totalOptionsPrice('options'))}</strong>
              </div>
              <div>
                Total Price: <strong>{numToCurrency.format(totalOptionsPrice('total'))}</strong>
              </div>
            </Box>
            <Grid sx={{ mt: 2 }} container spacing={2}>
              {project.options.map((option: IOption, index: number) => {
                return (
                  <React.Fragment key={index}>
                    <Grid
                      sx={{
                        alignSelf: 'center',
                        fontSize: '20px',
                      }}
                      item
                      xs={12}
                      sm={3}
                    >
                      <div>
                        <strong>{option.name}</strong>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <Dropdown
                        title={`Amount`}
                        menuList={['0', '1', '2', '3']}
                        name={'purchaseAmount'}
                        handleSelect={(e: any) => handleOptionDropdown(e, index, option.name)}
                        value={optionValue(option.name, 'purchaseAmount', index)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextInput
                        title={`${option.name} Total Amount`}
                        type="number"
                        name={'totalAmount'}
                        handleTextInput={(e: any) => handleOptionInput(e, option.name)}
                        value={optionValue(option.name, 'amount', index)}
                        adornment={'$'}
                        required={false}
                      />
                    </Grid>
                  </React.Fragment>
                );
              })}
              {unit.hasOwnProperty('rental') && unit.rental !== null ? (
                <>
                  <Grid
                    sx={{
                      alignSelf: 'center',
                      fontSize: '20px',
                    }}
                    item
                    xs={12}
                    sm={3}
                  >
                    <div>
                      <strong>Rental</strong>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <TextInput
                      title={`Total Rental Guarantee Amount`}
                      type="number"
                      id={'rental'}
                      handleTextInput={(e: any) => {
                        setRental(e.target.value);
                      }}
                      helperText={
                        rental &&
                        pendingOptions.length &&
                        rental ===
                          pendingOptions.reduce(
                            (a: any, b: any) => {
                              return a + b.rental * b.purchaseAmount;
                            },
                            filteredDeal.pendingRental ? filteredDeal.pendingRental : unit.rental
                          )
                          ? `This Rental Guarantee has increase by ${numToCurrency.format(rentalCalculation())}`
                          : ''
                      }
                      value={rental}
                      min={'0'}
                      adornment={'$'}
                      required={false}
                    />
                  </Grid>
                </>
              ) : null}
            </Grid>
            <Box sx={{ mt: 2 }}>
              <FormControlLabel
                control={<Checkbox color="primary" checked={keepDeposits} onClick={() => setKeepDeposits(!keepDeposits)} />}
                label={'Keep Deposit Structure Amounts'}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <Dropdown
                title={'Deposit Type'}
                menuList={depositList()}
                name={'depositType'}
                handleSelect={(event: any) => setSelectedDeposit(event.target.value)}
                value={selectedDeposit}
              />
            </Box>
            <DealDeposits
              deposit={pendingDeposits}
              basePrice={totalOptionsPrice('total')}
              handleDepositsArray={(array: any[]) => setPendingDeposits(array)}
              disable={false}
              setErrors={setErrors}
            />
            <Box sx={{ mt: 2 }}>
              <Dropdown
                title={'Select Options Amendment'}
                menuList={optionMerges.map((option: IOption) => option.name)}
                name={'options'}
                handleSelect={(e: any) => setSelectedOption(e.target.value)}
                value={selectedOption ? selectedOption : ''}
              />
            </Box>
            {accessAllowed(user, project._id, 'editDeals') &&
            !filteredDeal.cancelled.dateCancelled &&
            !filteredDeal.rescission.dateRescinded ? (
              <FlexBetween sx={{ mt: 2 }}>
                <div>
                  <Button onClick={() => addPendingDeposits()} name={'update'} color="primary" variant="contained">
                    Add Pending Deposit
                  </Button>
                </div>
                <FlexEnd>
                  <Button type="submit" name={'update'} color="success" variant="contained">
                    Create Options Amendment
                  </Button>
                </FlexEnd>
              </FlexBetween>
            ) : null}
          </>
        ) : null}
      </Box>
    </form>
  );
};

const MERGETEMPLATES = gql`
  query mergeTemplateMany($filter: FilterFindManyMergeTemplateInput) {
    mergeTemplateMany(filter: $filter) {
      _id
      project {
        _id
      }
      name
      mergeFields {
        key
        index
        pageNumber
        x
        y
        fontSize
        format
        wrap
      }
      signFields {
        key
        index
        pageNumber
        x
        y
        name
      }
      default
      totalPages
      type
      getUrl
      putUrl
    }
  }
`;

interface ChildProps {
  errors: any;
  setErrors: any;
  totalOptionsPrice: any;
}

export default PendingOptions;
