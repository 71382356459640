import { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, Button, Modal, Fade, Box } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import SnoozeIcon from '@mui/icons-material/Snooze';
import { useSelector } from 'react-redux';

import { selectProject } from '../../features/project/projectSlice';
import { numToCurrency, numToCurrencyDecimal, checkRealtorPublic, convertAllDates } from '../../utils/Functions';
import ExtendedConditionalModal from './ExtendConditionalModal';
import PageTitle from '../common/PageTitle';
import { IUnit, IUnitHistory } from '../../types/unit';
import TreeTable from '../common/TreeTable';
import { Flex } from '../../commonStyles';
import LoadingWrapper from '../common/LoadingWrapper';
import { IDeal } from '../../types/CreateDealForm';
import { useAppDispatch } from '../../app/hooks';
import { showSuccessSnackbar } from '../../features/snackbar/snackbarSlice';

const FirmingTable = () => {
  const project = useSelector(selectProject);
  const storeDispatch = useAppDispatch();
  const { projectid } = useParams();

  const [selectedDeals, setSelectedDeals] = useState<IRow[]>([]);
  const [selecting, setSelecting] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [rows, setRows] = useState<IRow[]>([]);
  const [nestedData, setNestedData] = useState<any>([]);
  const [extendConditionalOpen, setExtendConditionalOpen] = useState(false);
  const [firmingRows, setFirmingRows] = useState<IDeal[]>([]);

  const handleFirmingCount = (firmingRows: any) => {
    // All Firm Dates
    let filteredUnique = firmingRows.map((unit: any) => new Date(unit.deal.firmDate));
    let sortUnique = filteredUnique.sort((a: any, b: any) => a - b);
    let localDate = sortUnique.map((date: Date) => convertAllDates(new Date(date), 'P'));
    const unique: any = [...new Set(localDate.filter((deal: any) => deal))];

    if (unique.length > 0) {
      let row = unique
        .map((date: string) => {
          let dateRow = firmingRows.filter(
            (unit: any) => date === convertAllDates(new Date(unit.deal.firmDate), 'P') && unit.status === 'C'
          );

          return {
            id: date === '12/31/1969' ? 'N/A' : date,
            suites: dateRow.length,
            revenue: numToCurrency.format(dateRow.map((unit: any) => unit.basePrice).reduce((a: any, b: any) => a + b, 0)),
            ppsf: numToCurrencyDecimal.format(
              dateRow.map((unit: any) => unit.basePrice).reduce((a: any, b: any) => a + b, 0) /
                dateRow
                  .map((unit: any) => unit.size)
                  .reduce((a: any, b: any) => a + b, 0)
                  .toFixed(2)
            ),
          };
        })
        .filter((suites: any) => suites.suites);
      setRows(row);
    }
  };

  const { loading } = useQuery(PRESALE, {
    variables: { project: project._id, type: 'Conditional', page: 1, perPage: 10000, search: '' },
    onCompleted: (data) => {
      setFirmingRows(data.getDashboardDeals.units);
      handleFirmingCount(data.getDashboardDeals.units);
    },
  });

  const [updateFirms] = useMutation(UPDATEFIRM, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Conditionals have been turned to firms'));
      let updatedNestedData = nestedData.filter((nestedData: any) => !selectedDeals.find((deal: any) => deal._id === nestedData._id));
      setNestedData(updatedNestedData);

      let rows = firmingRows.map((rows: any) => {
        let updatedFirms = data.updateFirms.find((firmed: any) => firmed === rows.deal._id);
        if (updatedFirms) {
          return {
            ...rows,
            status: 'F',
          };
        } else return rows;
      });

      let newFirmingRows = rows.filter((firmingRows: any) => firmingRows.status === 'C');

      setSelectedDeals([]);
      setSelecting(false);
      handleFirmingCount(newFirmingRows);
      setFirmingRows(newFirmingRows);
      setOpenConfirm(false);
    },
    onError: (err: any) => {
      console.log(err, 'err');
    },
  });

  const columns = [
    {
      Header: 'Upcoming Firm Date',
      accessor: (rowData: any) => {
        return rowData.id;
      },
    },
    {
      Header: 'Suite Count',
      accessor: (rowData: any) => {
        return rowData.suites;
      },
    },
    {
      Header: 'Revenue',
      accessor: (rowData: any) => {
        return rowData.revenue;
      },
    },
    {
      Header: 'PPSF',
      accessor: (rowData: any) => {
        return rowData.ppsf !== '$NaN' ? rowData.ppsf : '$0';
      },
    },
  ];

  const nestedColumns = [
    {
      Header: 'Suite',
      accessor: (rowData: any) => {
        return (
          <Link
            rel="noopener noreferrer"
            target="_blank"
            style={{ textDecoration: 'none', color: 'inherit' }}
            to={`/${projectid}/dashboard/${rowData._id}`}
          >
            <strong>{rowData.suite}</strong>
          </Link>
        );
      },
    },
    {
      Header: 'Signing Date',
      accessor: (rowData: any) => {
        return rowData.deal.signDate ? convertAllDates(rowData.deal.signDate, 'PP') : 'N/A';
      },
    },
    {
      Header: 'Base Price',
      accessor: (rowData: any) => {
        return numToCurrency.format(rowData.basePrice);
      },
    },
    {
      Header: '1st Deposit Cheque',
      accessor: (rowData: any) => {
        if (rowData.deal && rowData.deal.deposit) {
          let pendingDeposits =
            rowData.deal.pendingDeposits.length &&
            rowData.deal.pendingDeposits[0] &&
            rowData.deal.pendingDeposits[0].chequeAmount &&
            (rowData.deal.pendingDeposits[0].chequeType === 'received' || rowData.deal.pendingDeposits[0].chequeType === 'wire')
              ? true
              : false;
          let deposit =
            rowData.deal.deposit[0] &&
            rowData.deal.deposit[0].chequeAmount &&
            (rowData.deal.deposit[0].chequeType === 'received' || rowData.deal.deposit[0].chequeType === 'wire')
              ? true
              : false;
          if (pendingDeposits || deposit) {
            return <CheckCircleIcon color="success" />;
          } else return <HighlightOffIcon color="error" />;
        }
      },
    },
    {
      Header: 'Sales Rep',
      accessor: (rowData: any) => {
        return rowData.deal.salesRep ? rowData.deal.salesRep.fullName : 'N/A';
      },
    },
    {
      Header: 'Realtor',
      accessor: (rowData: any) => {
        if (rowData.deal) {
          if (rowData.deal.realtor.length) {
            return rowData.deal.realtor[0].fullName;
          } else {
            return checkRealtorPublic(rowData.deal.tags);
          }
        } else {
          if (rowData.allocation) {
            return rowData.allocation.fullName;
          } else if (rowData.tempAllocation) {
            return rowData.tempAllocation;
          } else return '';
        }
      },
    },
    {
      Header: 'Unit Type',
      accessor: (rowData: any) => rowData.unitType,
    },
    {
      Header: 'Model Type',
      accessor: (rowData: any) => rowData.modelType,
    },
    {
      Header: 'Size',
      accessor: (rowData: any) => rowData.size,
    },
  ];

  const submitChanges = () => {
    let dealIds = selectedDeals.map((data: any) => data.deal._id);
    updateFirms({ variables: { project: project._id, dealIds: dealIds } });
  };

  const handleFirmDateChange = (data: any) => {
    let newFirmingRows = firmingRows.map((rows: any) => {
      if (rows.deal._id === data._id) {
        return {
          allocatedDate: data.unit.allocatedDate,
          allocation: data.unit.allocation,
          basePrice: data.unit.basePrice,
          createdAt: data.unit.createdAt,
          deal: {
            createdAt: data.createdAt,
            firmDate: data.firmDate,
            realtor: data.realtor,
            signDate: data.signDate,
            tags: data.tags,
            _id: data._id,
          },
          modelType: data.unit.modelType,
          size: data.unit.size,
          status: data.unit.status,
          suite: data.unit.suite,
          tempAllocation: data.unit.tempAllocation,
          unit: data.unit.unit,
          unitType: data.unit.unitType,
          worksheets: data.unit.worksheets,
          _id: data.unit._id,
        };
      } else return rows;
    });
    setSelecting(false);
    setNestedData(
      nestedData
        .map((nested: any) => {
          return { ...nested, checked: false };
        })
        .filter((rows: any) => rows._id !== data._id)
    );
    handleFirmingCount(newFirmingRows);
    setFirmingRows(newFirmingRows);
  };

  useEffect(() => {
    if (selectedDeals.length > 0) {
      setSelecting(true);
    } else {
      setSelecting(false);
    }
  }, [selectedDeals]);

  const handleNestedData = (id: string) => {
    let filteredUnique = firmingRows
      .filter((unit: any) => convertAllDates(unit.deal.firmDate, 'P') === id && unit.status === 'C')
      .sort((a: any, b: any) => a.id - b.id);
    setNestedData(filteredUnique);
  };

  const handleChange = (e: any, id: string) => {
    const filteredUnique = nestedData.map((data: any, index: number) => {
      if (index.toString() === id) {
        return {
          ...data,
          checked: !data.checked,
        };
      } else return data;
    });

    const selectedDeals = filteredUnique.filter((data: any) => data.checked);

    setSelectedDeals(selectedDeals);
    setNestedData(filteredUnique);
  };

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        p: 2,
      }}
    >
      <Flex>
        <Box
          sx={{
            pt: 2,
            pb: 1,
            width: '40%',
          }}
        >
          <PageTitle title={'Conditional'} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            padding: '5px',
            marginLeft: 'auto',
            mr: 0,
            // [theme.breakpoints.down('sm')]: {
            //   width: 'auto',
            //   justifyContent: 'flex-start',
            //   marginLeft: 0,
            // },
          }}
        >
          {selecting ? (
            <Flex>
              <Button
                variant="contained"
                color="primary"
                startIcon={<SnoozeIcon />}
                sx={{
                  mt: 1,
                  height: '50px',
                  float: 'right',
                  maxWidth: '120px',
                  mr: 1,
                }}
                onClick={() => {
                  setExtendConditionalOpen(true);
                }}
              >
                Extend
              </Button>
              <Button
                variant="contained"
                color="primary"
                startIcon={<CheckBoxRoundedIcon />}
                sx={{
                  mt: 1,
                  height: '50px',
                  float: 'right',
                  maxWidth: '120px',
                  mr: 1,
                }}
                onClick={() => {
                  setOpenConfirm(true);
                }}
              >
                Firm
              </Button>
            </Flex>
          ) : null}
          <Modal
            open={extendConditionalOpen}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClose={() => setExtendConditionalOpen(false)}
          >
            <Fade in={extendConditionalOpen}>
              <div>
                <ExtendedConditionalModal
                  handleFirmDateChange={handleFirmDateChange}
                  displayedRows={selectedDeals}
                  onClose={() => setExtendConditionalOpen(false)}
                />
              </div>
            </Fade>
          </Modal>
        </Box>
      </Flex>
      <Box
        sx={{
          height: 'calc(100% - 80px)',
          overflowX: 'auto',
        }}
      >
        {!loading ? (
          <>
            {rows.length > 0 ? (
              <TreeTable
                data={rows}
                columns={columns}
                nestedColumns={nestedColumns}
                nestedData={nestedData}
                handleNestedData={handleNestedData}
                handleChange={handleChange}
                checkbox={true}
              />
            ) : (
              <em>There are currently no conditional units needed to be firmed</em>
            )}
          </>
        ) : (
          <LoadingWrapper title="Conditional Units are loading..." modal={false} />
        )}
      </Box>
      <Dialog
        open={openConfirm}
        onClose={() => {
          setOpenConfirm(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Confirm Changes?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Confirm the changes for the selected units?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenConfirm(false);
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button onClick={submitChanges} color="primary" autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

interface IRow {
  actualID: number;
  dealId: string;
  id: string;
  unit: IUnit;
  dateSigned: string;
  dateFirmed: string;
  basePrice: string;
  history: IUnitHistory[];
}

const PRESALE = gql`
  query getDashboardDeals($project: MongoID!, $type: String!, $page: Int!, $perPage: Int!, $search: String) {
    getDashboardDeals(project: $project, type: $type, page: $page, perPage: $perPage, search: $search) {
      units {
        _id
        allocation {
          _id
          fullName
        }
        createdAt
        tempAllocation
        suite
        unit
        modelType
        unitType
        size
        basePrice
        status
        allocatedDate
        worksheets {
          updatedAt
        }
        deal {
          pendingDeposits {
            chequeAmount
            chequeType
          }
          deposit {
            chequeAmount
            chequeType
          }
          salesRep {
            fullName
          }
          createdAt
          signDate
          firmDate
          tags
          realtor {
            fullName
            realtor {
              _id
            }
          }
          _id
        }
      }
      count
    }
  }
`;

const UPDATEFIRM = gql`
  mutation updateFirms($project: MongoID!, $dealIds: [MongoID]) {
    updateFirms(project: $project, dealIds: $dealIds)
  }
`;

export default FirmingTable;
