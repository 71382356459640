import React from 'react';
import { Box, Paper } from '@mui/material';

import { ICollection } from '../../../types/colourSelection';

const CollectionCard = (props: ChildProps) => {
  const { collection } = props;
  return (
    <Paper sx={{ p: 2, cursor: 'pointer', height: '100%' }} elevation={12}>
      <Box>
        <strong>{collection.name}</strong>
      </Box>
      <Box sx={{ mt: 1 }}>
        {collection.unitsAllowed.length && !collection.modelsAllowed.length ? (
          <Box>
            Allowed Unit Types: <strong>{collection.unitsAllowed.map((unitType: string) => unitType).join(', ')}</strong>
          </Box>
        ) : null}
        {collection.modelsAllowed.length ? (
          <Box>
            Allowed Model Types: <strong>{collection.modelsAllowed.map((modelType: string) => modelType).join(', ')}</strong>
          </Box>
        ) : null}
      </Box>
    </Paper>
  );
};

interface ChildProps {
  collection: ICollection;
}

export default CollectionCard;
