import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { IUnit } from '../../types/unit';
import { numToCurrency, capitalizeFirstLetter } from '../../utils/Functions';
import { ProjectContext } from '../../context/ProjectContext';

const UnitModal = (props: ChildProps) => {
  const { projectId } = useContext(ProjectContext);
  const { pageTitle, units, type } = props;

  return (
    <Box
      sx={{
        backgroundColor: '#fff',
        border: '3px solid #000',
        paddingTop: '10px',
        paddingBottom: '40px',
        paddingRight: '30px',
        paddingLeft: '30px',
        height: '670px',
        width: '1000px',
        // [theme.breakpoints.down("sm")]: {
        //   width: '90%',
        //   justifyContent: 'flex-start',
        //   marginLeft: 0,
        //   height: '600px'
        // },
        // '@media (max-height: 600px)': {
        //   height: '560px',
        // },
      }}
    >
      <Box
        sx={{
          justifyContent: 'center',
          display: 'flex',
          marginBottom: '15px',
        }}
      >
        <h1 style={{ textAlign: 'center' }}>{pageTitle}</h1>
      </Box>
      <TableContainer
        component={Paper}
        sx={{
          height: '88%',
        }}
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Suite Number</TableCell>
              {type === 'realtor' ? <TableCell>Status</TableCell> : null}
              <TableCell>Unit Price</TableCell>
              <TableCell>Unit Type</TableCell>
              <TableCell>Model Type</TableCell>
              <TableCell>Size</TableCell>
              <TableCell>PPSF</TableCell>
              {type === 'Cancelled' ? <TableCell>Cancelled Type</TableCell> : null}
              {type === 'Cancelled' ? <TableCell>Cancelled Date</TableCell> : null}
              {type === 'Rescinded' ? <TableCell>Rescinded Date</TableCell> : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {units.map((row) => (
              <TableRow key={row.suite}>
                <TableCell component="th" scope="row">
                  <Link
                    style={{
                      textDecoration: 'none',
                      color: '#000',
                    }}
                    to={`/${projectId}/dashboard/${row._id}`}
                  >
                    <strong>{row.suite}</strong>
                  </Link>
                </TableCell>
                {type === 'realtor' ? <TableCell>{row.status}</TableCell> : null}
                <TableCell align="left">{numToCurrency.format(row.basePrice)}</TableCell>
                <TableCell align="left">{row.unitType}</TableCell>
                <TableCell align="left">{row.modelType}</TableCell>
                <TableCell align="left">{row.size}</TableCell>
                <TableCell align="left">{numToCurrency.format(row.basePrice / row.size)}</TableCell>
                {type === 'Cancelled' ? <TableCell>{capitalizeFirstLetter(row.cancelledType!)}</TableCell> : null}
                {type === 'Cancelled' ? <TableCell>{row.cancelled}</TableCell> : null}
                {type === 'Rescinded' ? <TableCell>{row.rescission}</TableCell> : null}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

interface ChildProps {
  pageTitle: string;
  units: IUnitDeal[];
  type?: string;
}

interface IUnitDeal extends IUnit {
  cancelled?: string;
  cancelledType?: string;
  rescission?: string;
}

export default UnitModal;
