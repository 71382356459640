import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    background: {
      default: '#ffffff',
    },
    primary: {
      main: '#00142a',
      light: '#ffd1c0',
    },
    secondary: {
      main: '#412920',
    },
    warning: {
      main: '#FF4500',
    },
    success: {
      main: '#008000',
    },
    error: {
      main: '#DC143C',
    },
    info: {
      main: '#708090',
    },
  },
  spacing: 8,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .Mui-disabled': {
            color: '#000',
            opacity: 1,
            WebkitTextFillColor: '#000 !important',
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiIconButton-root': {
            color: 'inherit',
          },
          '& .MuiInputAdornment-root': {
            color: 'inherit',
          },
          '& .MuiInputLabel-root': {
            color: 'inherit',
          },
          '& .MuiInputLabel-root.Mui-focused': {
            color: 'inherit',
          },
          '& .MuiOutlinedInput-root': {
            color: 'inherit',
          },
          '& .MuiOutlinedInput-root.Mui-focused': {
            '& > fieldset': {
              borderColor: 'inherit',
            },
          },
          '& .MuiOutlinedInput-root:hover': {
            '& > fieldset': {
              borderColor: 'inherit',
            },
          },
          '& .Mui-disabled': {
            color: '#000',
            opacity: 1,
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontSize: '2.5rem',
          '@media (max-width: 600px)': {
            fontSize: '1.5rem',
          },
        },
        h2: {
          fontSize: '1.5rem',
          '@media (max-width:600px)': {
            fontSize: '1rem',
          },
        },
        h3: {
          fontSize: '1rem',
          '@media (max-width:600px)': {
            fontSize: '0.7rem',
          },
        },
        h4: {
          fontSize: '1rem',
          '@media (max-width:600px)': {
            fontSize: '1.5rem',
          },
        },
        h5: {
          fontSize: '1.65rem',
          '@media (max-width:600px)': {
            fontSize: '1.3rem',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          padding: '6px 9px',
          '@media (max-width:600px)': {
            fontSize: '0.5rem',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          backgroundColor: '#00142a',
          color: '#fff',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: '#fff',
          '&.Mui-selected': {
            color: '#ffd1c0',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: 'red',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          '@media (max-width:600px)': {
            fontSize: '0.5rem',
          },
        },
      },
    },
  },
});
