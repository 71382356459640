import { useState, useEffect, useMemo } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { camelToTitle, numToCurrency } from '../../utils/Functions';
import { Link, useNavigate } from 'react-router-dom';
import { IUnit, IUnitData } from '../../types/unit';
import { useSelector } from 'react-redux';
import { selectProject } from '../../features/project/projectSlice';
import StandardTable from '../tables/StandardTable';
import { downloadExcel, downloadPdf } from '../../utils/Functions';
import { GlobalModal } from '../../features/modal/Modal';
import { useAppDispatch } from '../../app/hooks';
import { handleModal } from '../../features/modal/modalSlice';
import { allocationSummaryColumn } from '../../utils/CustomSettings';

const RealtorsSummary = () => {
  const storeDispatch = useAppDispatch();
  const navigate = useNavigate();
  const project = useSelector(selectProject);
  const [count, setCount] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [allocations, setAllocations] = useState<any>([]);
  const [allocationName, setAllocationName] = useState<string>('');
  const [units, setUnits] = useState<IUnit[]>([]);

  const [getUnits, { loading: unitLoading }] = useLazyQuery<IUnitData>(UNITS, {
    onCompleted: (data: any) => {
      setUnits(data.unitMany);
    },
  });

  const [getAllocations, { loading }] = useLazyQuery(ALLOCATIONS, {
    onCompleted: (data) => {
      setCount(data.getAllocationSummary.count);
      setAllocations(data.getAllocationSummary.allocations);
    },
  });

  useEffect(() => {
    getAllocations({
      variables: { search: '', project: project._id },
    });
  }, [pageNumber, project._id]);

  const handleGlobalFilterValue = (value: string) => {
    setPageNumber(1);
    getAllocations({
      variables: { search: value, project: project._id },
    });
  };

  let unitTypes = [
    'studio',
    'jrOneBr',
    'oneBr',
    'oneBrInbound',
    'oneBrMedia',
    'oneBrFlex',
    'oneBrDen',
    'oneBrDenInbound',
    'twoBr',
    'twoBrInbound',
    'twoBrMedia',
    'twoBrFlex',
    'twoBrDen',
    'twoBrDenInbound',
    'juniorTwo',
    'threeBr',
    'threeBrInbound',
    'threeBrMedia',
    'threeBrFlex',
    'threeBrDen',
    'th',
  ];

  const getUnitType = (unitType: string) => {
    switch (unitType) {
      case 'studio':
        return 'Studio';
      case 'jrOneBr':
        return 'JR1BR';
      case 'oneBr':
        return '1BR';
      case 'oneBrInbound':
        return '1BR(I)';
      case 'oneBrDen':
        return '1BR+D';
      case 'oneBrMedia':
        return '1BR+M';
      case 'oneBrFlex':
        return '1BR+F';
      case 'oneBrDenInbound':
        return '1BR+D(I)';
      case 'oneBrMediaInbound':
        return '1BR+M(I)';
      case 'twoBr':
        return '2BR';
      case 'twoBrInbound':
        return '2BR(I)';
      case 'twoBrDen':
        return '2BR+D';
      case 'twoBrMedia':
        return '2BR+M';
      case 'twoBrFlex':
        return '2BR+F';
      case 'juniorTwo':
        return 'JR2';
      case 'twoBrDenInbound':
        return '2BR+D(I)';
      case 'twoBrMediaInbound':
        return '2BR+M(I)';
      case 'threeBr':
        return '3BR';
      case 'threeBrInbound':
        return '3BR(I)';
      case 'threeBrDen':
        return '3BR+D';
      case 'threeBrMedia':
        return '3BR+M';
      case 'threeBrFlex':
        return '3BR+F';
      case 'th':
        return 'TH';
      default:
        return 'N/A';
    }
  };

  const unitModalColumns = useMemo(() => {
    return [
      {
        Header: 'Suite',
        accessor: (rowData: any) => {
          return (
            <Box sx={{ cursor: 'pointer' }} onClick={() => {
              storeDispatch(handleModal(false))
              navigate(`/${project._id}/dashboard/${rowData._id}`)
            }}>
              <strong>{rowData.suite}</strong>
            </Box>
          );
        },
      },
      {
        Header: 'Status',
        accessor: (rowData: any) => <strong>{rowData.status}</strong>,
      },
      {
        Header: 'Base Price',
        accessor: (rowData: any) => numToCurrency.format(rowData.basePrice),
      },
      {
        Header: 'Unit Type',
        accessor: (rowData: any) => rowData.unitType,
      },
      {
        Header: 'Exposure',
        accessor: (rowData: any) => rowData.exposure,
      },
      {
        Header: 'Model Type',
        accessor: (rowData: any) => rowData.modelType,
      },
      {
        Header: 'Size',
        accessor: (rowData: any) => rowData.size,
      },
      {
        Header: 'Bathrooms',
        accessor: (rowData: any) => rowData.bathroom,
      },
      {
        Header: 'PPSF',
        accessor: (rowData: any) => numToCurrency.format(rowData.basePrice / rowData.size),
      },
    ];
  }, [project._id]);

  const columns = useMemo(() => {
    let unitColumns = [
      {
        Header: 'Allocation',
        accessor: (rowData: any) => {
          return (
            <Box onClick={() => handleUnitModal(rowData)}>
              <strong style={{ cursor: 'pointer' }}>{rowData._id.fullName}</strong>
            </Box>
          );
        },
      },
      {
        Header: 'Total Allocations',
        accessor: (rowData: any) => `${rowData.count} (${rowData.worksheetCount})`,
      },
      {
        Header: 'Total Revenue',
        accessor: (rowData: any) => rowData.totalRevenue,
        Cell: (props: any) => {
          return <Box sx={{ p: 2 }}>{numToCurrency.format(props.value)}</Box>;
        },
      },
      {
        Header: 'PPSF',
        accessor: (rowData: any) => numToCurrency.format(rowData.totalRevenue / rowData.size),
      },
    ];

    let selectedUnitTypes: any[] = [];

    if (allocations.length) {
      let checkUnitType = unitTypes
        .map((unitType: string) => {
          let allocationCheck = allocations.every((allocation: any) => allocation[unitType] === 0);

          if (allocationCheck) {
            return null;
          } else return unitType;
        })
        .filter((unitType) => unitType !== null);

      selectedUnitTypes = checkUnitType.map((checkUnit: any) => {
        return {
          Header: getUnitType(checkUnit)!,
          accessor: (rowData: any) => {
            return (
              <Box>
                <strong style={{ cursor: 'pointer' }}>{rowData[checkUnit]}</strong>
              </Box>
            );
          },
        };
      });
    }

    let customColumn = allocationSummaryColumn(project._id);

    return [...unitColumns, ...customColumn, ...selectedUnitTypes];
  }, [allocations, unitTypes]);

  const handleUnitModal = (data: any) => {
    setAllocationName(data._id.fullName);
    getUnits({ variables: { filter: { allocation: data._id._id, project: project._id } } });
    storeDispatch(handleModal(true));
  };

  const unitDownload = (type: string, data: any) => {
    let unitHeaders = [
      {
        label: 'Suite',
        id: 'suite',
      },
      {
        label: 'Status',
        id: 'status',
      },
      {
        label: 'Base Price',
        id: 'basePrice',
      },
      {
        label: 'Unit Type',
        id: 'unitType',
      },
      {
        label: 'Exposure',
        id: 'exposure',
      },
      {
        label: 'Model Type',
        id: 'modelType',
      },
      {
        label: 'Size',
        id: 'size',
      },
      {
        label: 'Bathrooms',
        id: 'bathroom',
      },
      {
        label: 'PPSF',
        id: 'ppsf',
      },
    ];

    let widths = {
      suite: 15,
      basePrice: 15,
      status: 15,
      unitType: 15,
      exposure: 15,
      modelType: 15,
      size: 15,
      bathroom: 15,
      ppsf: 15,
    };

    let pdfWidths = {
      suite: 200,
      basePrice: 200,
      status: 200,
      unitType: 200,
      exposure: 200,
      modelType: 200,
      size: 200,
      bathroom: 200,
      ppsf: 200,
    };

    let updatedData = data.map((data: IUnit) => {
      return {
        ...data,
        ppsf: numToCurrency.format(data.basePrice / data.size),
      };
    });

    let sheetTitle = `${project.name} - ${allocationName} Units`;

    if (type === 'excel') {
      downloadExcel([updatedData], [unitHeaders], [], [[widths]], [sheetTitle], sheetTitle);
    } else {
      downloadPdf([updatedData], [unitHeaders], [], [pdfWidths], [sheetTitle], sheetTitle);
    }
  };

  const download = (type: string, data: any) => {
    let checkUnitType = unitTypes
      .map((unitType: string) => {
        let allocationCheck = allocations.every((allocation: any) => allocation[unitType] === 0);

        if (!allocationCheck) return unitType;
      })
      .filter((unitType) => unitType);

    let allData = data.map((data: any) => {
      let dataObject = checkUnitType.reduce((obj: any, item: any) => ((obj[item] = data[item]), obj), {});

      return {
        allocation: data._id.fullName,
        totalAllocations: `${data.count} (${data.worksheetCount})`,
        totalRevenue: data.totalRevenue,
        ppsf: numToCurrency.format(data.totalRevenue / data.size),
        ...dataObject,
      };
    });

    let excelObject = checkUnitType.reduce((obj: any, item: any) => ((obj[item] = 15), obj), {});
    let pdfObject = checkUnitType.reduce((obj: any, item: any) => ((obj[item] = 200), obj), {});

    let widths = {
      allocation: 15,
      totalAllocations: 15,
      totalRevenue: 15,
      ppsf: 15,
    };

    let pdfWidth = {
      allocation: 200,
      totalAllocations: 200,
      totalRevenue: 200,
      ppsf: 200,
    };

    let excelWidths = { ...widths, ...excelObject };
    let pdfWidths = { ...pdfWidth, ...pdfObject };

    let objectHeaders = checkUnitType.map((data: any) => {
      return {
        label: camelToTitle(data),
        id: data,
      };
    });

    let headers = [
      {
        label: 'Allocation',
        id: 'allocation',
      },
      {
        label: 'Total Allocations',
        id: 'totalAllocations',
      },
      {
        label: 'Total Revenue',
        id: 'totalRevenue',
      },
      {
        label: 'PPSF',
        id: 'ppsf',
      },
    ];

    let allHeaders = [...headers, ...objectHeaders];

    let sheetTitle = `${project.name} - Realtor Summary`;

    if (type === 'excel') {
      downloadExcel([allData], [allHeaders], [], [[excelWidths]], [sheetTitle], sheetTitle);
    } else {
      downloadPdf([allData], [allHeaders], [], [pdfWidths], [sheetTitle], sheetTitle);
    }
  };

  return (
    <Box
      sx={{
        m: 2,
      }}
    >
      <>
        <div style={{ maxWidth: '100%' }}>
          <Typography variant={'h5'} gutterBottom>
            Allocations
          </Typography>
          <Box sx={{ mb: 2 }}>
            <StandardTable
              data={allocations}
              columns={columns}
              count={count}
              loading={loading}
              download={download}
              handleGlobalFilterValue={handleGlobalFilterValue}
            />
          </Box>
        </div>
        <GlobalModal>
          <Typography sx={{ mb: 2 }} variant={'h5'} gutterBottom>
            {allocationName}'s Units
          </Typography>
          <StandardTable data={units} columns={unitModalColumns} loading={unitLoading} download={unitDownload} />
        </GlobalModal>
      </>
    </Box>
  );
};

const ALLOCATIONS = gql`
  query getAllocationSummary($search: String, $project: MongoID!) {
    getAllocationSummary(search: $search, project: $project) {
      count
      allocations {
        _id {
          _id
          fullName
        }
        worksheetCount
        count
        totalRevenue
        size
        tripRevenue
        studio
        jrOneBr
        oneBr
        oneBrInbound
        oneBrDen
        oneBrMedia
        oneBrFlex
        oneBrDenInbound
        oneBrMediaInbound
        twoBr
        twoBrInbound
        twoBrDen
        twoBrMedia
        twoBrFlex
        juniorTwo
        twoBrDenInbound
        twoBrMediaInbound
        threeBr
        threeBrInbound
        threeBrDen
        threeBrMedia
        threeBrFlex
        th
      }
    }
  }
`;

const UNITS = gql`
  query unitMany($filter: FilterFindManyUnitInput) {
    unitMany(filter: $filter, limit: 10000) {
      _id
      suite
      status
      modelType
      unitType
      bathroom
      size
      outdoorType
      basePrice
      exposure
    }
  }
`;

// const DEALS = gql`
//   query dealMany($filter: FilterFindManyDealInput) {
//     dealMany(filter: $filter, limit: 100000) {
//       _id
//       signDate
//       firmDate
//       executeDate
//       project {
//         _id
//       }
//       unit {
//         status
//       }
//       realtor {
//         _id
//         realtor {
//           _id
//         }
//         email
//         firstName
//         lastName
//         fullName
//         brokerage
//         streetAddress
//         city
//         province
//         country
//         postalCode
//         directPhone
//       }
//       rescission {
//         dateRescinded
//       }
//       cancelled {
//         dateCancelled
//       }
//       basePrice
//       tags
//     }
//   }
// `;

export default RealtorsSummary;
