import React from 'react';
import { Box, Typography} from '@mui/material';
import { IUnitMarketing } from './FloorplanContainer';
import { numToCurrency } from '../../utils/Functions';

const UnitInfo = (props: ChildProps) => {
  const { unit } = props;
  return (
    <Box sx={{ p: 2, width: '100%' }}>
      <Box>
        <Typography variant={'h2'} gutterBottom>
          <strong>Suite: {unit.suite}</strong>
        </Typography>
        <Box>
          Unit Price: <strong>{numToCurrency.format(unit.basePrice)}</strong>
        </Box>
        <Box>
          Level: <strong>{unit.level}</strong>
        </Box>
        <Box>
          Unit: <strong>{unit.unit}</strong>
        </Box>
        <Box>
          Unit Type: <strong>{unit.unitType}</strong>
        </Box>
        <Box>
          Model Type: <strong>{unit.modelType}</strong>
        </Box>
        <Box>
          Exposure: <strong>{unit.exposure}</strong>
        </Box>
        <Box>
          Bathrooms: <strong>{unit.bathroom}</strong>
        </Box>
        <Box>
          Size: <strong>{unit.size}</strong>
        </Box>
      </Box>
    </Box>
  );
};

interface ChildProps {
  unit: IUnitMarketing;
}

export default UnitInfo;
