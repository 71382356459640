import { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { Box, CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';

import { selectProject } from '../../features/project/projectSlice';
import { numToCurrency, numToCurrencyDecimal, checkRealtorPublic, convertAllDates } from '../../utils/Functions';
import PageTitle from '../common/PageTitle';
import { IUnit, IUnitHistory } from '../../types/unit';
import TreeTable from '../common/TreeTable';
import { Flex } from '../../commonStyles';
import LoadingWrapper from '../common/LoadingWrapper';
import { IDeal } from '../../types/CreateDealForm';

const FirmedTable = (props: ChildProps) => {
  const project = useSelector(selectProject);
  const [rows, setRows] = useState<IRow[]>([]);
  const [nestedData, setNestedData] = useState<any>([]);
  const [firmedRows, setFirmedRows] = useState<IDeal[]>([]);
  const { projectid } = useParams();

  const handleFirmedCount = (firmedRows: any) => {
    // All Firm Dates
    let filteredUnique = firmedRows.map((unit: any) => new Date(unit.deal.firmDate));
    let sortUnique = filteredUnique.sort((a: any, b: any) => a - b);
    let localDate = sortUnique.map((date: Date) => convertAllDates(new Date(date), 'P'));
    const unique: any = [...new Set(localDate.filter((deal: any) => deal))];

    if (unique.length > 0) {
      let row = unique.map((date: string) => {
        let dateRow = firmedRows.filter((unit: any) => {
          return date === convertAllDates(new Date(unit.deal.firmDate), 'P') && unit.status === 'F';
        });

        return {
          id: date === '12/31/1969' ? 'N/A' : date,
          suites: dateRow.length,
          revenue: numToCurrency.format(dateRow.map((unit: any) => unit.basePrice).reduce((a: any, b: any) => a + b, 0)),
          ppsf: numToCurrencyDecimal.format(
            dateRow.map((unit: any) => unit.basePrice).reduce((a: any, b: any) => a + b, 0) /
              dateRow
                .map((unit: any) => unit.size)
                .reduce((a: any, b: any) => a + b, 0)
                .toFixed(2)
          ),
        };
      });
      setRows(row);
    }
  };

  const { loading } = useQuery(PRESALE, {
    fetchPolicy: 'network-only',
    variables: { project: projectid, type: 'Firm', page: 1, perPage: 10000, search: '' },
    onCompleted: (data) => {
      setFirmedRows(data.getDashboardDeals.units);
      handleFirmedCount(data.getDashboardDeals.units);
    },
  });

  const columns = [
    {
      Header: 'Firm Date',
      accessor: (rowData: any) => {
        return rowData.id;
      },
    },
    {
      Header: 'Suite Count',
      accessor: (rowData: any) => {
        return rowData.suites;
      },
    },
    {
      Header: 'Revenue',
      accessor: (rowData: any) => {
        return rowData.revenue;
      },
    },
    {
      Header: 'PPSF',
      accessor: (rowData: any) => {
        return rowData.ppsf !== '$NaN' ? rowData.ppsf : '$0';
      },
    },
  ];

  const nestedColumns = [
    {
      Header: 'Suite',
      accessor: (rowData: any) => {
        return (
          <Link
            rel="noopener noreferrer"
            target="_blank"
            style={{ textDecoration: 'none', color: 'inherit' }}
            to={`/${projectid}/dashboard/${rowData._id}`}
          >
            <strong>{rowData.suite}</strong>
          </Link>
        );
      },
    },
    {
      Header: 'Signing Date',
      accessor: (rowData: any) => {
        return rowData.deal.signDate ? convertAllDates(rowData.deal.signDate, 'PP') : 'N/A';
      },
    },
    {
      Header: 'Base Price',
      accessor: (rowData: any) => {
        return numToCurrency.format(rowData.basePrice);
      },
    },
    {
      Header: 'Realtor',
      accessor: (rowData: any) => {
        if (rowData.deal.realtor.length) {
          return rowData.deal.realtor[0].fullName
        } else return <strong>{checkRealtorPublic(rowData.deal.tags)}</strong>;
      },
    },
    {
      Header: 'Unit Type',
      accessor: (rowData: any) => rowData.unitType,
    },
    {
      Header: 'Model Type',
      accessor: (rowData: any) => rowData.modelType,
    },
    {
      Header: 'Size',
      accessor: (rowData: any) => rowData.size,
    },
  ];

  const handleNestedData = (id: string) => {
    let filteredUnique = firmedRows
      .filter((unit: any) => convertAllDates(unit.deal.firmDate, 'P') === id && unit.status === 'F')
      .sort((a: any, b: any) => a.id - b.id);
    setNestedData(filteredUnique);
  };

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        p: 2,
      }}
    >
      <Flex>
        <Box
          sx={{
            pt: 2,
            pb: 1,
            width: '40%',
          }}
        >
          <PageTitle title={'Firmed'} />
        </Box>
      </Flex>
      <Box
        sx={{
          height: 'calc(100% - 80px)',
          overflowX: 'auto',
        }}
      >
        {!loading ? (
          <>
            {rows.length > 0 ? (
              <TreeTable
                data={rows}
                columns={columns}
                nestedColumns={nestedColumns}
                nestedData={nestedData}
                handleNestedData={handleNestedData}
                checkbox={false}
              />
            ) : (
              <em>There are currently no firmed units</em>
            )}
          </>
        ) : (
          <LoadingWrapper title="Firmed Units are loading..." modal={false} />
        )}
      </Box>
    </Box>
  );
};

interface ChildProps {
  allFirmedRows: any;
}

interface IRow {
  actualID: number;
  dealId: string;
  id: string;
  unit: IUnit;
  dateSigned: string;
  dateFirmed: string;
  basePrice: string;
  history: IUnitHistory[];
}

const PRESALE = gql`
  query getDashboardDeals($project: MongoID!, $type: String!, $page: Int!, $perPage: Int!, $search: String) {
    getDashboardDeals(project: $project, type: $type, page: $page, perPage: $perPage, search: $search) {
      units {
        _id
        allocation {
          _id
          fullName
        }
        createdAt
        tempAllocation
        suite
        unit
        modelType
        unitType
        size
        basePrice
        status
        allocatedDate
        worksheets {
          updatedAt
        }
        deal {
          pendingDeposits {
            chequeAmount
            chequeType
          }
          deposit {
            chequeAmount
            chequeType
          }
          salesRep {
            fullName
          }
          createdAt
          signDate
          firmDate
          tags
          realtor {
            fullName
            realtor {
              _id
            }
          }
          _id
        }
      }
      count
    }
  }
`;

export default FirmedTable;
