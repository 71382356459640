import { useQuery, gql } from '@apollo/client';

import { useAppDispatch } from '../../app/hooks';
import { showErrorSnackbar } from '../snackbar/snackbarSlice';
import { setProjects, setProject } from './projectSlice';
import { IUser } from '../../types/user';
import { IProjectAccess } from '../../types/user';
import { ICoopRate } from '../../types/project';

// General Info Projects
export const statusReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'ADD':
      return [
        ...state,
        {
          name: '',
          code: '',
          color: '',
        },
      ];
    case 'UPDATE':
      return state.map((state: any, index: number) => {
        if (index === action.payload.index) {
          return {
            ...state,
            [action.payload.field]: action.payload.value,
          };
        } else return state;
      });
    case 'DELETE':
      return state.filter((state: any, index: number) => index !== action.payload.index);
    default:
      throw new Error();
  }
};

export const executorReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'ADD':
      return [
        ...state,
        {
          name: '',
          email: '',
        },
      ];
    case 'UPDATE':
      return state.map((state: any, index: number) => {
        if (index === action.payload.index) {
          return {
            ...state,
            [action.payload.field]: action.payload.value,
          };
        } else return state;
      });
    case 'DELETE':
      return state.filter((state: any, index: number) => index !== action.payload.index);
    default:
      throw new Error();
  }
};

export const coopReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'ADDCOOPSTRUCTURE':
      return [
        ...state,
        {
          name: '',
          coopRates: [
            {
              type: '',
              days: 0,
              date: null,
              description: '',
              percentage: 1,
              fixedAmount: 0,
            },
          ],
        },
      ];
    case 'ADDCOOPRATE':
      return state.map((state: any, index: number) => {
        if (index === action.payload.index) {
          return {
            ...state,
            coopRates: [
              ...state.coopRates,
              {
                type: '',
                days: 0,
                date: null,
                description: '',
                percentage: 1,
                fixedAmount: 0,
              },
            ],
          };
        } else return state;
      });
    case 'UPDATENAME':
      return state.map((state: any, index: number) => {
        if (index === action.payload.index) {
          return {
            ...state,
            name: action.payload.value,
          };
        } else return state;
      });
    case 'UPDATE':
      return state.map((state: any, index: number) => {
        if (index === action.payload.structureIndex) {
          return {
            ...state,
            coopRates: state.coopRates.map((coopRate: ICoopRate, index: number) => {
              if (index === action.payload.index) {
                return {
                  ...coopRate,
                  [action.payload.field]: action.payload.value,
                };
              } else return coopRate;
            }),
          };
        } else return state;
      });
    case 'DELETECOOPRATE':
      return state.map((state: any, index: number) => {
        if (index === action.payload.structureIndex) {
          return {
            ...state,
            coopRates: state.coopRates.filter((coopRate: ICoopRate, index: number) => index !== action.payload.index),
          };
        } else return state;
      });
    case 'DELETECOOPSTRUCTURE':
      return state.filter((state: any, index: number) => index !== action.payload.index);
    default:
      throw new Error();
  }
};

export const adjustmentsReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'ADD':
      return [
        ...state,
        {
          name: '',
          conditionField: '',
          conditions: [],
          type: '',
        },
      ];
    case 'UPDATE':
      return state.map((state: any, index: number) => {
        if (index === action.payload.index) {
          return {
            ...state,
            [action.payload.field]: action.payload.value,
          };
        } else return state;
      });
    case 'DELETE':
      return state.filter((state: any, index: number) => index !== action.payload.index);
    default:
      throw new Error();
  }
};

export const optionsReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'ADD':
      return [
        ...state,
        {
          name: '',
          totalAvailable: 0,
          price: 0,
          type: 'residential',
          allowedUnits: [],
        },
      ];
    case 'UPDATE':
      return state.map((state: any, index: number) => {
        if (index === action.payload.index) {
          return {
            ...state,
            [action.payload.field]: action.payload.value,
          };
        } else return state;
      });
    case 'DELETE':
      return state.filter((state: any, index: number) => index !== action.payload.index);
    default:
      throw new Error();
  }
};

export const depositsReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'ADD':
      return [
        ...state,
        {
          name: '',
          deposits: [
            {
              name: 'Initial', // 1st Deposit, 2nd Deposit etc
              type: '', // Fixed or Percent
              amount: 0, // 5,000 or 2.5%
              dateType: '',
              daysDue: 0, // 30 days
              dueDate: new Date(), // Specific Date
            },
          ],
          default: false,
        },
      ];
    case 'UPDATE':
      return state.map((state: any, index: number) => {
        if (index === action.payload.index) {
          return {
            ...state,
            [action.payload.field]: action.payload.value,
          };
        } else return state;
      });
    case 'DELETE':
      return state.filter((state: any, index: number) => index !== action.payload.index);
    case 'COPY':
      return [...state, action.payload];
    default:
      throw new Error();
  }
};

export const accessAllowed = (user: IUser, projectId: string, restriction: string) => {
  let project = user.projectAccess.find((projectAccess: IProjectAccess) => {
    return projectAccess.project._id === projectId;
  });

  if (project?.access.includes(restriction)) {
    return true;
  } else return false;
};

export const useProjectsQuery = () => {
  const dispatch = useAppDispatch();

  return useQuery(GETPROJECTS, {
    onCompleted: (data) => {
      dispatch(setProjects(data.projectMany));
    },
    onError: (err) => {
      dispatch(showErrorSnackbar(err.message));
    },
  });
};

export const useProjectQuery = (id: string, project: string) => {
  const dispatch = useAppDispatch();

  return useQuery(GETPROJECT, {
    skip: project === id,
    variables: { _id: id },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.projectById.name) {
        document.title = `RDS Real Estate - ${data.projectById.name}`;
      } else {
        document.title = `RDS Real Estate`;
      }
      dispatch(setProject(data.projectById));
    },
    onError: (err) => {
      dispatch(showErrorSnackbar(err.message));
    },
  });
};

const GETPROJECTS = gql`
  query projectMany {
    projectMany {
      _id
      name
    }
  }
`;

const GETPROJECT = gql`
  query projectById($_id: MongoID!) {
    projectById(_id: $_id) {
      _id
      name
      developerName
      email
      trackingPhone
      salesTrackingPhone
      addresses {
        streetAddress
        city
        province
        postalCode
        country
      }
      options {
        name
        totalAvailable
        price
        allowedUnits
        type
        rental
      }
      depositStructures {
        name
        deposits {
          name
          type
          amount
          daysDue
          dateType
          dueDate
        }
        default
      }
      mergeTemplates {
        name
        mergeTemplate {
          name
          _id
          totalPages
        }
        apsTemplates {
          name
          apsTemplate {
            type
            _id
            name
          }
          pageNumber
          attachToAps
        }
      }
      status {
        name
        code
        color
      }
      executors {
        name
        email
        cc
      }
      mortgageLimit
      mortgageMinDay
      hideOccupancy
      defaultZeroValue
      lawyer
      tagLine
      salesOffice
      adjustments {
        name
        type
        conditionField
        conditions {
          amount
          value
          values
          condition
        }
      }
      commissionIncludeOptions
      emailTemplates {
        _id
        name
        subject
        html
      }
      logoGetUrl
      logoPutUrl
      imageGetUrl
      imagePutUrl
      developerLogoGetUrl
      developerLogoPutUrl
      partnerLogoGetUrl
      partnerLogoPutUrl
      coopStructures {
        name
        coopRates {
          type
          days
          date
          description
          percentage
          fixedAmount
        }
      }
      firstTentativeOccupancy
      finalTentativeOccupancy
      firmOccupancy
      outsideOccupancy
      active
      tags
      acknowledgement
      decorModels {
        modelType
        allowed
      }
      portal {
        _id
        active
        vipActive
        showHotlist
        sortHotlist
        showCustom
        createWorksheets
        showOptionsPrice
        showUnitTypes
        showOptions
        password
        information
        promo
        renderings {
          _id
          getUrl
          name
        }
        photos {
          _id
          getUrl
          name
        }
        pdfs {
          _id
          getUrl
          name
        }
        logos {
          _id
          name
          getUrl
        }
        aerialPhotos {
          _id
          name
          getUrl
        }
        bRoll {
          _id
          name
          getUrl
        }
        officialSite
        instaUrl
        primaryColor
        secondaryColor
        brochure
        brochureGetUrl
        brochurePutUrl
        subText
        videos {
          name
          getUrl
        }
        project {
          _id
        }
      }
      combinedProjects {
        _id
        name
      }
    }
  }
`;
