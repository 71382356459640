import React, { useState, useContext } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { UnitContext } from '../../context/UnitContext';
import { IDealOption } from '../../types/CreateDealForm';
import TextInput from '../common/formControls/TextInput';

const DealOptions = (props: ChildProps) => {
  const { options, disabled } = props;
  const { filteredDeal, setFilteredDeal } = useContext(UnitContext);

  const handleTextInput = (event: any, numIndex: number, type: string) => {
    let optionsArray = filteredDeal.options.map((option: IDealOption, index: number) => {
      if (numIndex === index) {
        if (event.target.name === 'amount' || event.target.name === 'purchaseAmount') {
          return {
            ...option,
            [event.target.name]: parseInt(event.target.value, 10),
          };
        } else {
          return {
            ...option,
            [event.target.name]: event.target.value,
          };
        }
      } else {
        return option;
      }
    });
    setFilteredDeal({
      ...filteredDeal,
      options: optionsArray,
    });
  };

  return (
    <div>
      {options.length > 0 ? (
        <div>
          <TableContainer sx={{ mt: 2 }} component={Paper}>
            <Table
              sx={{
                minWidth: 650,
                '& .MuiFormLabel-root.Mui-disabled': {
                  color: 'rgba(0, 0, 0, 0.6)',
                },
                '& .MuiInputBase-root.Mui-disabled': {
                  color: 'rgba(0, 0, 0, 0.6)',
                },
              }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <strong>Option Name</strong>
                  </TableCell>
                  <TableCell align="left">
                    <strong>Purchase Amount</strong>
                  </TableCell>
                  <TableCell align="left">
                    <strong>Total Amount</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {options.map((option: IDealOption, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          <TextInput
                            name={'name'}
                            title={'Deposit Name'}
                            handleTextInput={(e: HTMLInputElement) => handleTextInput(e, index, 'string')}
                            value={option.name}
                            required={true}
                            disabled={
                              (!disabled && !filteredDeal.documents.length) ||
                              (filteredDeal.documents[0].type === 'APS' && filteredDeal.documents[0].status === 'Completed' && !filteredDeal.documents[0].dsEnvelopeId)
                                ? false
                                : true
                            }
                          />
                        </TableCell>
                        <TableCell align="left">
                          <TextInput
                            name={'purchaseAmount'}
                            type={'number'}
                            title={'Amount of Option Purchased'}
                            handleTextInput={(e: HTMLInputElement) => handleTextInput(e, index, 'number')}
                            value={option.purchaseAmount}
                            required={true}
                            disabled={
                              (!disabled && !filteredDeal.documents.length) ||
                              (filteredDeal.documents[0].type === 'APS' && filteredDeal.documents[0].status === 'Completed' && !filteredDeal.documents[0].dsEnvelopeId)
                                ? false
                                : true
                            }
                          />
                        </TableCell>
                        <TableCell align="left">
                          <TextInput
                            name={'amount'}
                            type={'number'}
                            title={'Total Amount'}
                            handleTextInput={(e: HTMLInputElement) => handleTextInput(e, index, 'number')}
                            value={option.amount}
                            adornment={'$'}
                            required={true}
                            disabled={
                              (!disabled && !filteredDeal.documents.length) ||
                              (filteredDeal.documents[0].type === 'APS' && filteredDeal.documents[0].status === 'Completed' && !filteredDeal.documents[0].dsEnvelopeId)
                                ? false
                                : true
                            }
                          />
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ) : (
        <div>There are no options purchased for this unit.</div>
      )}
    </div>
  );
};

interface ChildProps {
  options: IDealOption[];
  disabled: boolean;
}

export default DealOptions;
