import { useState, useEffect, useContext } from 'react';
import { Box, List, ListItem, ListItemText, Checkbox, Divider, Accordion, Slider, AccordionSummary, AccordionDetails } from '@mui/material';
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { PriceGridContext } from '../../../context/PriceGridContext';
import { selectProject } from '../../../features/project/projectSlice';
import PageTitle from '../../common/PageTitle';
import { IStatus } from '../../../types/project';
import { unitStatusTitles } from '../../../utils/Constants';
import { numToCurrency, sortSuites } from '../../../utils/Functions';
import { IUnit } from '../../../types/unit';

function valuetext(value: number) {
  return `${value}`;
}

const FilterMenu = (props: ChildProps) => {
  const { units, setFilteredUnits, setSelectingUnits, setNoUnits } = useContext(PriceGridContext);
  const project = useSelector(selectProject);

  const allSizes: number[] = units.map((unit: IUnit) => unit.size);
  const minSize = Math.min(...allSizes);
  const maxSize = Math.max(...allSizes);

  const allOutdoorSizes: number[] = units.map((unit: IUnit) => unit.outdoorSize);
  const minOutdoorSize = Math.min(...allOutdoorSizes);
  const maxOutdoorSize = Math.max(...allOutdoorSizes);

  const allPrices: number[] = units.map((unit: IUnit) => unit.basePrice);
  const minPrice = Math.min(...allPrices);
  const maxPrice = Math.max(...allPrices);

  const [lowerPrice, setLowerPrice] = useState(minPrice);
  const [upperPrice, setUpperPrice] = useState(maxPrice);
  const [lowerOutdoorSize, setLowerOutdoorSize] = useState(minOutdoorSize);
  const [upperOutdoorSize, setUpperOutdoorSize] = useState(maxOutdoorSize);
  const [lowerSize, setLowerSize] = useState(minSize);
  const [upperSize, setUpperSize] = useState(maxSize);
  const allStatus: IStatus[] = [...project.status, ...unitStatusTitles];

  const [sizeValue, setSizeValue] = useState<number[]>([minSize, maxSize]);
  const handleSizeChange = (event: any, newValue: number | Number[]) => {
    setSizeValue(newValue as number[]);
  };
  const handleSizeChangeOnCommit = () => {
    setLowerSize(sizeValue[0]);
    setUpperSize(sizeValue[1]);
  };

  const [outdoorSizeValue, setOutdoorSizeValue] = useState<number[]>([minOutdoorSize, maxOutdoorSize]);

  const handleOutdoorSizeChange = (event: any, newValue: number | number[]) => {
    setOutdoorSizeValue(newValue as number[]);
  };

  const handleOutdoorSizeChangeOnCommit = () => {
    setLowerOutdoorSize(outdoorSizeValue[0]);
    setUpperOutdoorSize(outdoorSizeValue[1]);
  };

  // PRICE
  // price filtering constants

  // keeping track of lower and upper bound of filter
  const [priceValue, setPriceValue] = useState<number[]>([minPrice, maxPrice]);

  const handlePriceChange = (event: any, newValue: number | number[]) => {
    setPriceValue(newValue as number[]);
  };

  const handlePriceChangeOnCommit = () => {
    setLowerPrice(priceValue[0]);
    setUpperPrice(priceValue[1]);
  };

  // MODELS
  // model filtering constants
  const allModels = units.map((unit: IUnit) => unit.modelType);
  const modelTypes = allModels.filter((v: any, i: any, a: any) => a.indexOf(v) === i);
  modelTypes.sort();
  const [modelsChecked, setModelsChecked] = useState(['none']);
  const actualModelLength = modelTypes.length;
  for (let i = modelTypes.length; i % 3 !== 0; i++) {
    modelTypes.push('');
  }
  const handleModelsToggle = (value: string) => () => {
    const currentIndex = modelsChecked.indexOf(value);
    const newChecked = [...modelsChecked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setModelsChecked(newChecked);
  };

  // END OF MODELS

  // Allocations

  const allUnitAllocations = units
    .filter((unit: IUnit) => unit.allocation)
    .map((unit: any) => {
      return unit.allocation.fullName;
    });
  const unitAllocations = allUnitAllocations.filter((v: any, i: any, a: any) => a.indexOf(v) === i);

  unitAllocations.sort();
  const [unitAllocationsChecked, setUnitAllocationsChecked] = useState(['none']);
  const actualUnitAllocationsLength = unitAllocations.length;
  for (let i = unitAllocations.length; i % 3 !== 0; i++) {
    unitAllocations.push('');
  }
  const handleUnitAllocationsToggle = (value: any) => () => {
    const currentIndex = unitAllocationsChecked.indexOf(value);
    const newChecked = [...unitAllocationsChecked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setUnitAllocationsChecked(newChecked);
  };

  // Unit Numbers

  const allUnitNumbers = units.map((unit: IUnit) => unit.unit);
  const unitNumbers = allUnitNumbers.filter((v: any, i: any, a: any) => a.indexOf(v) === i);
  unitNumbers.sort(function (a: string, b: string) {
    return parseFloat(a) - parseFloat(b);
  });
  const [unitNumbersChecked, setUnitNumbersChecked] = useState(['none']);
  const actualUnitNumbersLength = unitNumbers.length;
  for (let i = unitNumbers.length; i % 3 !== 0; i++) {
    unitNumbers.push('');
  }
  const handleUnitNumbersToggle = (value: string) => () => {
    const currentIndex = unitNumbersChecked.indexOf(value);
    const newChecked = [...unitNumbersChecked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setUnitNumbersChecked(newChecked);
  };

  // LEVELS
  const allLevels = units.map((unit: IUnit) => unit.level);
  const levels = allLevels.filter((v: any, i: any, a: any) => a.indexOf(v) === i);
  levels.sort(function (a: string, b: string) {
    return parseFloat(a) - parseFloat(b);
  });
  const [levelsChecked, setLevelsChecked] = useState(['none']);
  const actualLevelsLength = levels.length;
  for (let i = levels.length; i % 3 !== 0; i++) {
    levels.push('');
  }
  const handleLevelsToggle = (value: string) => () => {
    const currentIndex = levelsChecked.indexOf(value);
    const newChecked = [...levelsChecked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setLevelsChecked(newChecked);
  };
  // END OF LEVELS

  // NUMBER OF BATHROOMS
  const allBathrooms = units.map((unit: IUnit) => unit.bathroom);
  const bathrooms = allBathrooms.filter((v: any, i: any, a: any) => a.indexOf(v) === i);
  bathrooms.sort(function (a: number, b: number) {
    return a - b;
  });
  const [bathroomsChecked, setBathroomsChecked] = useState([-1]);
  const actualBathroomsLength = bathrooms.length;
  for (let i = bathrooms.length; i % 3 !== 0; i++) {
    bathrooms.push(-1);
  }
  const handleBathroomsToggle = (value: number) => () => {
    const currentIndex = bathroomsChecked.indexOf(value);
    const newChecked = [...bathroomsChecked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setBathroomsChecked(newChecked);
  };
  // END OF BATHROOMS

  const allExposures = units.map((unit: IUnit) => unit.exposure);
  const exposures = allExposures.filter((v: any, i: any, a: any) => a.indexOf(v) === i);
  exposures.sort();
  const [exposuresChecked, setExposuresChecked] = useState(['none']);
  const actualExposuresLength = exposures.length;
  for (let i = exposures.length; i % 3 !== 0; i++) {
    exposures.push('');
  }
  const handleExposuresToggle = (value: string) => () => {
    const currentIndex = exposuresChecked.indexOf(value);
    const newChecked = [...exposuresChecked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setExposuresChecked(newChecked);
  };

  const allOutdoorTypes = units.map((unit: IUnit) => unit.outdoorType);
  const outdoorTypes = allOutdoorTypes.filter((v: any, i: any, a: any) => a.indexOf(v) === i);
  outdoorTypes.sort();
  const [outdoorTypesChecked, setOutdoorTypesChecked] = useState(['none']);
  const actualOutdoorTypeLength = outdoorTypes.length;
  for (let i = outdoorTypes.length; i % 3 !== 0; i++) {
    outdoorTypes.push('');
  }
  const handleOutdoorTypesToggle = (value: string) => () => {
    const currentIndex = outdoorTypesChecked.indexOf(value);
    const newChecked = [...outdoorTypesChecked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setOutdoorTypesChecked(newChecked);
  };

  const allUnitTypes = units.map((unit: IUnit) => unit.unitType);
  const unitTypes = allUnitTypes.filter((v: any, i: any, a: any) => a.indexOf(v) === i);
  unitTypes.sort();
  const [unitTypesChecked, setUnitTypesChecked] = useState(['none']);
  const actualUnitTypeLength = unitTypes.length;
  for (let i = unitTypes.length; i % 3 !== 0; i++) {
    unitTypes.push('');
  }
  const handleUnitTypesToggle = (value: string) => () => {
    const currentIndex = unitTypesChecked.indexOf(value);
    const newChecked = [...unitTypesChecked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setUnitTypesChecked(newChecked);
  };

  const status = allStatus.map((status) => status.code);
  status.sort();
  const [statusChecked, setStatusChecked] = useState(['none']);
  const actualStatusLength = status.length;
  for (let i = status.length; i % 3 !== 0; i++) {
    status.push('');
  }
  const handleStatusToggle = (value: string) => () => {
    const currentIndex = statusChecked.indexOf(value);
    const newChecked = [...statusChecked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setStatusChecked(newChecked);
  };

  useEffect(
    () => {
      filterUnits(
        lowerPrice,
        upperPrice,
        modelsChecked,
        unitNumbersChecked,
        levelsChecked,
        bathroomsChecked,
        exposuresChecked,
        lowerOutdoorSize,
        upperOutdoorSize,
        outdoorTypesChecked,
        lowerSize,
        upperSize,
        unitTypesChecked,
        statusChecked,
        unitAllocationsChecked
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      lowerPrice,
      upperPrice,
      modelsChecked,
      unitNumbersChecked,
      levelsChecked,
      bathroomsChecked,
      exposuresChecked,
      lowerOutdoorSize,
      upperOutdoorSize,
      outdoorTypesChecked,
      lowerSize,
      upperSize,
      unitTypesChecked,
      statusChecked,
      unitAllocationsChecked,
    ]
  );

  const filterUnits = (
    minPrice: number,
    maxPrice: number,
    checkedModels: string[],
    checkedUnitNumbers: string[],
    checkedLevels: string[],
    checkedBathrooms: number[],
    checkedExposures: string[],
    minOutdoorSize: number,
    maxOutdoorSize: number,
    checkedOutdoorTypes: string[],
    minSize: number,
    maxSize: number,
    checkedUnitTypes: string[],
    checkedStatus: string[],
    checkedUnitAllocations: any[]
  ) => {
    const filterConditions = (unit: IUnit) => {
      return (
        unit.basePrice >= minPrice &&
        unit.basePrice <= maxPrice &&
        (checkedModels.length === 1 || checkedModels.includes(unit.modelType)) &&
        (checkedUnitNumbers.length === 1 || checkedUnitNumbers.includes(unit.unit)) &&
        (checkedLevels.length === 1 || checkedLevels.includes(unit.level)) &&
        (checkedBathrooms.length === 1 || checkedBathrooms.includes(unit.bathroom)) &&
        (checkedExposures.length === 1 || checkedExposures.includes(unit.exposure)) &&
        unit.outdoorSize >= minOutdoorSize &&
        unit.outdoorSize <= maxOutdoorSize &&
        (checkedOutdoorTypes.length === 1 || checkedOutdoorTypes.includes(unit.outdoorType)) &&
        unit.size >= minSize &&
        unit.size <= maxSize &&
        (checkedUnitTypes.length === 1 || checkedUnitTypes.includes(unit.unitType)) &&
        (checkedStatus.length === 1 || checkedStatus.includes(unit.status)) &&
        (checkedUnitAllocations.length === 1 || checkedUnitAllocations.includes(unit.allocation ? unit.allocation?.fullName! : ''))
      );
    };
    const newDisplayedUnits = units.filter((unit: IUnit) => filterConditions(unit)).map((unit: IUnit) => unit._id);
    if (newDisplayedUnits.length === units.length) return;
    const newFilteredUnits = sortSuites(units.filter((unit: IUnit) => filterConditions(unit)), 'suite');
    const rowCount: any[] = [...new Set(newFilteredUnits.map((item: IUnit) => item.row))].sort((a: any,b: any) => a - b);

    let startingRow = 0;
    let startingCol = 1;
    let newUnits = newFilteredUnits.map((unit: IUnit, index: number) => {
      let row = rowCount.findIndex((num: number) => num === unit.row);
      if (row !== startingRow) {
        startingCol = 1;
        startingRow++;
      } else {
        if (index) {
          startingCol++;
        }
      }
      return {
        ...unit,
        row: row + 1,
        col: startingCol,
      };
    });

    if (!newUnits.length) {
      setNoUnits(true);
    } else {
      setNoUnits(false);
      setFilteredUnits(newUnits);
      setSelectingUnits(false);
    }
  };

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row-reverse',
          pl: 1,
        }}
      >
        <Box
          sx={{
            marginTop: '15px',
            marginRight: 'auto',
          }}
        >
          <PageTitle title={'Filters'} />
        </Box>
        <CloseIcon
          sx={{
            marginRight: 'auto',
            marginBottom: '10px',
            marginTop: '23px',
            cursor: 'pointer',
          }}
          onClick={props.handleClose}
        />
      </Box>
      <Divider />
      <Box sx={{ m: 2 }}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panl1a-content" id="panl1a-header">
            Realtors
          </AccordionSummary>
          <AccordionDetails>
            <List
              sx={{
                columnCount: 3,
              }}
            >
              {unitAllocations.map((value: any, index: any) => {
                const labelId = `checkbox-list-label-${value}`;
                if (index < actualUnitAllocationsLength) {
                  return (
                    <Box
                      key={index}
                      sx={{
                        marginLeft: '5px',
                        marginRight: '5px',
                      }}
                    >
                      <ListItem key={index} role={undefined} dense button onClick={handleUnitAllocationsToggle(value!)}>
                        <Checkbox
                          edge="start"
                          checked={unitAllocationsChecked.indexOf(value!) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                        <ListItemText
                          id={labelId}
                          primary={value}
                          sx={{
                            width: '35px',
                            fontSize: '0.8em',
                          }}
                          disableTypography={true}
                        />
                      </ListItem>
                    </Box>
                  );
                } else {
                  return (
                    <Box
                      key={index}
                      sx={{
                        visibility: 'hidden',
                      }}
                    >
                      <ListItem
                        style={{ background: 'transparent', boxShadow: 'none' }}
                        key={index}
                        role={undefined}
                        dense
                        button
                        onClick={handleUnitAllocationsToggle(value)}
                      >
                        <Checkbox
                          style={{ background: 'transparent', boxShadow: 'none' }}
                          edge="start"
                          checked={unitAllocationsChecked.indexOf(value!) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </ListItem>
                    </Box>
                  );
                }
              })}
            </List>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panl1a-content" id="panl1a-header">
            Base Price
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ my: 1 }}>
              <Slider
                sx={{
                  width: '315px',
                }}
                min={minPrice}
                max={maxPrice}
                value={priceValue}
                // ValueLabelComponent={ValueLabelComponent}
                onChange={handlePriceChange}
                onChangeCommitted={handlePriceChangeOnCommit}
                step={100}
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
                getAriaValueText={valuetext}
              />
              <Box>
                Listing all units with a base price between <strong>{numToCurrency.format(priceValue[0])}</strong> and{' '}
                <strong>{numToCurrency.format(priceValue[1])}</strong>.
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panl1a-content" id="panl1a-header">
            Unit Numbers
          </AccordionSummary>
          <AccordionDetails>
            <List
              sx={{
                columnCount: 3,
              }}
            >
              {unitNumbers.map((value: any, index: number) => {
                const labelId = `checkbox-list-label-${value}`;
                if (index < actualUnitNumbersLength) {
                  return (
                    <Box
                      key={index}
                      sx={{
                        marginLeft: '5px',
                        marginRight: '5px',
                      }}
                    >
                      <ListItem key={index} role={undefined} dense button onClick={handleUnitNumbersToggle(value)}>
                        <Checkbox
                          edge="start"
                          checked={unitNumbersChecked.indexOf(value) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                        <ListItemText
                          id={labelId}
                          primary={value}
                          sx={{
                            width: '35px',
                            fontSize: '0.8em',
                          }}
                          disableTypography={true}
                        />
                      </ListItem>
                    </Box>
                  );
                } else {
                  return (
                    <Box
                      key={index}
                      sx={{
                        visibility: 'hidden',
                      }}
                    >
                      <ListItem
                        style={{ background: 'transparent', boxShadow: 'none' }}
                        key={index}
                        role={undefined}
                        dense
                        button
                        onClick={handleUnitNumbersToggle(value)}
                      >
                        <Checkbox
                          style={{ background: 'transparent', boxShadow: 'none' }}
                          edge="start"
                          checked={unitNumbersChecked.indexOf(value) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </ListItem>
                    </Box>
                  );
                }
              })}
            </List>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panl1a-content" id="panl1a-header">
            Outdoor Size
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ my: 1 }}>
              <Slider
                sx={{
                  width: '315px',
                }}
                min={minOutdoorSize}
                max={maxOutdoorSize}
                value={outdoorSizeValue}
                // ValueLabelComponent={ValueLabelComponent}
                onChange={handleOutdoorSizeChange}
                onChangeCommitted={handleOutdoorSizeChangeOnCommit}
                step={1}
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
                getAriaValueText={valuetext}
              />
              <Box>
                Listing all units with an outdoor size between <strong>{outdoorSizeValue[0]} </strong>sq ft and
                <strong> {outdoorSizeValue[1]} </strong>sq ft.
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panl1a-content" id="panl1a-header">
            Size
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ my: 1 }}>
              <Slider
                sx={{
                  width: '315px',
                }}
                min={minSize}
                max={maxSize}
                value={sizeValue}
                // ValueLabelComponent={ValueLabelComponent}
                onChange={handleSizeChange}
                onChangeCommitted={handleSizeChangeOnCommit}
                step={10}
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
                getAriaValueText={valuetext}
              />
              <Box>
                Listing all units with a size between <strong>{sizeValue[0]}</strong> sq ft and <strong>{sizeValue[1]}</strong> sq ft.
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panl1a-content" id="panl1a-header">
            Model Names
          </AccordionSummary>
          <AccordionDetails
            sx={
              {
                // [theme.breakpoints.down('sm')]: {
                //   padding: '0',
                // },
              }
            }
          >
            <List
              sx={{
                columnCount: 3,
              }}
            >
              <Box
                sx={{
                  marginLeft: '10px',
                  justifyContent: 'center',
                  // [theme.breakpoints.down('sm')]: {
                  //   marginLeft: 0,
                  // },
                }}
              >
                {modelTypes.map((value: any, index: number) => {
                  const labelId = `checkbox-list-label-${value}`;
                  if (index < actualModelLength) {
                    return (
                      <Box
                        key={index}
                        sx={{
                          marginLeft: '5px',
                          marginRight: '5px',
                        }}
                      >
                        <ListItem key={index} role={undefined} dense button onClick={handleModelsToggle(value)}>
                          <Checkbox
                            edge="start"
                            checked={modelsChecked.indexOf(value) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                          <ListItemText
                            id={labelId}
                            primary={value}
                            sx={{
                              width: '35px',
                              fontSize: '0.8em',
                            }}
                            disableTypography={true}
                          />
                        </ListItem>
                      </Box>
                    );
                  } else {
                    return (
                      <Box
                        key={index}
                        sx={{
                          visibility: 'hidden',
                        }}
                      >
                        <ListItem
                          style={{ background: 'transparent', boxShadow: 'none' }}
                          key={index}
                          role={undefined}
                          dense
                          button
                          onClick={handleModelsToggle(value)}
                        >
                          <Checkbox
                            style={{ background: 'transparent', boxShadow: 'none' }}
                            edge="start"
                            checked={modelsChecked.indexOf(value) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </ListItem>
                      </Box>
                    );
                  }
                })}
              </Box>
            </List>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panl1a-content" id="panl1a-header">
            Levels
          </AccordionSummary>
          <AccordionDetails>
            <List
              sx={{
                columnCount: 3,
              }}
            >
              {levels.map((value: any, index: number) => {
                const labelId = `checkbox-list-label-${value}`;
                if (index < actualLevelsLength) {
                  return (
                    <Box
                      key={index}
                      sx={{
                        marginLeft: '5px',
                        marginRight: '5px',
                      }}
                    >
                      <ListItem key={index} role={undefined} dense button onClick={handleLevelsToggle(value)}>
                        <Checkbox
                          edge="start"
                          checked={levelsChecked.indexOf(value) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                        <ListItemText
                          id={labelId}
                          primary={value}
                          sx={{
                            width: '35px',
                            fontSize: '0.8em',
                          }}
                          disableTypography={true}
                        />
                      </ListItem>
                    </Box>
                  );
                } else {
                  return (
                    <Box
                      key={index}
                      sx={{
                        visibility: 'hidden',
                      }}
                    >
                      <ListItem
                        style={{ background: 'transparent', boxShadow: 'none' }}
                        key={index}
                        role={undefined}
                        dense
                        button
                        onClick={handleLevelsToggle(value)}
                      >
                        <Checkbox
                          style={{ background: 'transparent', boxShadow: 'none' }}
                          edge="start"
                          checked={levelsChecked.indexOf(value) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </ListItem>
                    </Box>
                  );
                }
              })}
            </List>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panl1a-content" id="panl1a-header">
            Number of Bathrooms
          </AccordionSummary>
          <AccordionDetails>
            <List
              sx={{
                columnCount: 3,
              }}
            >
              {bathrooms.map((value: any, index: number) => {
                const labelId = `checkbox-list-label-${value}`;
                if (index < actualBathroomsLength) {
                  return (
                    <Box
                      key={index}
                      sx={{
                        width: '35px',
                        fontSize: '0.8em',
                      }}
                    >
                      <ListItem key={index} role={undefined} dense button onClick={handleBathroomsToggle(value)}>
                        <Checkbox
                          edge="start"
                          checked={bathroomsChecked.indexOf(value) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                        <ListItemText
                          id={labelId}
                          primary={value}
                          sx={{
                            width: '35px',
                            fontSize: '0.8em',
                          }}
                          disableTypography={true}
                        />
                      </ListItem>
                    </Box>
                  );
                } else {
                  return (
                    <Box
                      key={index}
                      sx={{
                        visibility: 'hidden',
                      }}
                    >
                      <ListItem
                        style={{ background: 'transparent', boxShadow: 'none' }}
                        key={index}
                        role={undefined}
                        dense
                        button
                        onClick={handleBathroomsToggle(value)}
                      >
                        <Checkbox
                          style={{ background: 'transparent', boxShadow: 'none' }}
                          edge="start"
                          checked={bathroomsChecked.indexOf(value) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </ListItem>
                    </Box>
                  );
                }
              })}
            </List>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panl1a-content" id="panl1a-header">
            Exposures
          </AccordionSummary>
          <AccordionDetails
            sx={
              {
                // [theme.breakpoints.down('sm')]: {
                //   padding: '0',
                // },
              }
            }
          >
            <List
              sx={{
                columnCount: 3,
              }}
            >
              {exposures.map((value: any, index: number) => {
                const labelId = `checkbox-list-label-${value}`;
                if (index < actualExposuresLength) {
                  return (
                    <Box
                      key={index}
                      sx={{
                        marginLeft: '5px',
                        marginRight: '5px',
                      }}
                    >
                      <ListItem key={index} role={undefined} dense button onClick={handleExposuresToggle(value)}>
                        <Checkbox
                          edge="start"
                          checked={exposuresChecked.indexOf(value) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                        <ListItemText
                          id={labelId}
                          primary={value}
                          sx={{
                            width: '35px',
                            fontSize: '0.8em',
                          }}
                          disableTypography={true}
                        />
                      </ListItem>
                    </Box>
                  );
                } else {
                  return (
                    <Box
                      key={index}
                      sx={{
                        visibility: 'hidden',
                      }}
                    >
                      <ListItem
                        style={{ background: 'transparent', boxShadow: 'none' }}
                        key={index}
                        role={undefined}
                        dense
                        button
                        onClick={handleExposuresToggle(value)}
                      >
                        <Checkbox
                          style={{ background: 'transparent', boxShadow: 'none' }}
                          edge="start"
                          checked={exposuresChecked.indexOf(value) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </ListItem>
                    </Box>
                  );
                }
              })}
            </List>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panl1a-content" id="panl1a-header">
            Outdoor Type
          </AccordionSummary>
          <AccordionDetails>
            <List
              sx={{
                columnCount: 3,
              }}
            >
              {outdoorTypes.map((value: any, index: number) => {
                const labelId = `checkbox-list-label-${value}`;
                if (index < actualOutdoorTypeLength) {
                  return (
                    <Box
                      key={index}
                      sx={{
                        marginLeft: '5px',
                        marginRight: '5px',
                      }}
                    >
                      <ListItem key={index} role={undefined} dense button onClick={handleOutdoorTypesToggle(value)}>
                        <Checkbox
                          edge="start"
                          checked={outdoorTypesChecked.indexOf(value) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                        <ListItemText
                          id={labelId}
                          primary={value}
                          sx={{
                            width: '35px',
                            fontSize: '0.8em',
                          }}
                          disableTypography={true}
                        />
                      </ListItem>
                    </Box>
                  );
                } else {
                  return (
                    <Box
                      key={index}
                      sx={{
                        visibility: 'hidden',
                      }}
                    >
                      <ListItem
                        style={{ background: 'transparent', boxShadow: 'none' }}
                        key={index}
                        role={undefined}
                        dense
                        button
                        onClick={handleOutdoorTypesToggle(value)}
                      >
                        <Checkbox
                          style={{ background: 'transparent', boxShadow: 'none' }}
                          edge="start"
                          checked={outdoorTypesChecked.indexOf(value) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </ListItem>
                    </Box>
                  );
                }
              })}
            </List>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panl1a-content" id="panl1a-header">
            Unit Type
          </AccordionSummary>
          <AccordionDetails>
            <List
              sx={{
                columnCount: 3,
              }}
            >
              {unitTypes.map((value: any, index: number) => {
                const labelId = `checkbox-list-label-${value}`;
                if (index < actualUnitTypeLength) {
                  return (
                    <Box
                      key={index}
                      sx={{
                        marginLeft: '5px',
                        marginRight: '5px',
                      }}
                    >
                      <ListItem key={index} role={undefined} dense button onClick={handleUnitTypesToggle(value)}>
                        <Checkbox
                          edge="start"
                          checked={unitTypesChecked.indexOf(value) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                        <ListItemText
                          id={labelId}
                          primary={value}
                          sx={{
                            width: '35px',
                            fontSize: '0.8em',
                          }}
                          disableTypography={true}
                        />
                      </ListItem>
                    </Box>
                  );
                } else {
                  return (
                    <Box
                      key={index}
                      sx={{
                        visibility: 'hidden',
                      }}
                    >
                      <ListItem
                        style={{ background: 'transparent', boxShadow: 'none' }}
                        key={index}
                        role={undefined}
                        dense
                        button
                        onClick={handleUnitTypesToggle(value)}
                      >
                        <Checkbox
                          style={{ background: 'transparent', boxShadow: 'none' }}
                          edge="start"
                          checked={unitTypesChecked.indexOf(value) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </ListItem>
                    </Box>
                  );
                }
              })}
            </List>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panl1a-content" id="panl1a-header">
            Status
          </AccordionSummary>
          <AccordionDetails>
            <List
              sx={{
                columnCount: 3,
              }}
            >
              {status.map((value: any, index: number) => {
                const labelId = `checkbox-list-label-${value}`;
                if (index < actualStatusLength) {
                  return (
                    <Box
                      key={index}
                      sx={{
                        marginLeft: '5px',
                        marginRight: '5px',
                      }}
                    >
                      <ListItem key={index} role={undefined} dense button onClick={handleStatusToggle(value)}>
                        <Checkbox
                          edge="start"
                          checked={statusChecked.indexOf(value) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                        <ListItemText
                          id={labelId}
                          primary={value}
                          sx={{
                            width: '35px',
                            fontSize: '0.8em',
                          }}
                          disableTypography={true}
                        />
                      </ListItem>
                    </Box>
                  );
                } else {
                  return (
                    <Box
                      key={index}
                      sx={{
                        visibility: 'hidden',
                      }}
                    >
                      <ListItem
                        style={{ background: 'transparent', boxShadow: 'none' }}
                        key={index}
                        role={undefined}
                        dense
                        button
                        onClick={handleStatusToggle(value)}
                      >
                        <Checkbox
                          style={{ background: 'transparent', boxShadow: 'none' }}
                          edge="start"
                          checked={statusChecked.indexOf(value) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </ListItem>
                    </Box>
                  );
                }
              })}
            </List>
          </AccordionDetails>
        </Accordion>
      </Box>
    </div>
  );
};

interface ChildProps {
  handleClose: () => void;
}

// interface LabelProps {
//   children: React.ReactElement;
//   open: boolean;
//   value: number;
// }

export default FilterMenu;
