import React, { Fragment, useState } from 'react';
import { Box, Button, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Skeleton, Checkbox, Drawer, Toolbar } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TableViewIcon from '@mui/icons-material/TableView';
import { FixedSizeList } from 'react-window';
import { useTable, useBlockLayout, useSortBy, useGlobalFilter, useAsyncDebounce } from 'react-table';

import { FlexBetween } from '../../commonStyles';
import scrollbarWidth from '../reports/scrollbarWidth';

function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }: any) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value: any) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <Box
      sx={{
        border: '1px solid #000',
        p: 1,
      }}
    >
      Search:{' '}
      <input
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        style={{
          fontSize: '1.1rem',
          border: '0',
        }}
      />
    </Box>
  );
}

function VirtualTable({ columns, data, itemHeight, filter, tableHeight, noPadding, loading, hide, download }: any) {
  // Use the state and functions returned from useTable to build your UI

  const [open, setOpen] = useState<boolean>(false);

  const defaultColumn = React.useMemo(
    () => ({
      width: 150,
    }),
    []
  );

  const scrollBarSize = React.useMemo(() => scrollbarWidth(), []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    totalColumnsWidth,
    preGlobalFilteredRows,
    setGlobalFilter,
    state,
    allColumns,
  } = useTable(
    { columns, data, defaultColumn, autoResetGlobalFilter: false, autoResetHiddenColumns: false },
    useBlockLayout,
    useGlobalFilter,
    useSortBy
  );

  const RenderRow = React.useCallback(
    ({ index, style }: any) => {
      const row = rows[index];
      prepareRow(row);
      return (
        <TableRow
          {...row.getRowProps({
            style,
          })}
        >
          {row.cells.map((cell: any) => {
            return loading ? (
              <TableCell {...cell.getCellProps()}>
                <Skeleton animation="wave" variant="text" height={30} />
              </TableCell>
            ) : (
              <TableCell sx={{ position: 'relative', p: noPadding ? 0 : 2 }} {...cell.getCellProps()}>
                {cell.render('Cell')}
              </TableCell>
            );
          })}
        </TableRow>
      );
    },
    [prepareRow, rows]
  );

  // Render the UI for your table
  return (
    <>
      {hide ? (
        <>
          <Button sx={{ mb: 2 }} variant="contained" color="secondary" onClick={() => setOpen(true)}>
            Show/Hide Columns
          </Button>
          <Drawer
            anchor={'right'}
            open={open}
            onClose={() => setOpen(false)}
            sx={{
              paper: {
                width: 250,
              },
            }}
          >
            {allColumns.map((column: any, index: number) => {
              return (
                <Fragment key={index}>
                  <Box sx={{ p: 1 }}>
                    <Checkbox {...column.getToggleHiddenProps()} />
                    {column.Header}
                  </Box>
                </Fragment>
              );
            })}
          </Drawer>
        </>
      ) : null}
      {download && !filter ? (
        <Box sx={{ borderLeft: '1px solid #000', borderTop: '1px solid #000', borderRight: '1px solid #000' }}>
          <Toolbar sx={{ width: '100%', pl: 1, pr: 1 }}>
            <FlexBetween sx={{ alignItems: 'center', display: 'flex', width: '100% ' }}>
              <Box sx={{ ml: 'auto' }}>
                <PictureAsPdfIcon sx={{ mr: 2, cursor: 'pointer', color: 'error.main' }} onClick={() => download('pdf', data)} />
                <TableViewIcon sx={{ cursor: 'pointer', color: 'success.main' }} onClick={() => download('excel', data)} />
              </Box>
            </FlexBetween>
          </Toolbar>
        </Box>
      ) : null}
      {filter ? (
        <Box sx={{ minWidth: 'auto', overflowX: 'auto', position: 'relative' }}>
          <GlobalFilter preGlobalFilteredRows={preGlobalFilteredRows} globalFilter={state.globalFilter} setGlobalFilter={setGlobalFilter} />
          {download ? (
            <Box sx={{ ml: 'auto', position: 'absolute', right: 15, top: 8 }}>
              <PictureAsPdfIcon sx={{ mr: 2, cursor: 'pointer', color: 'error.main' }} onClick={() => download('pdf', data)} />
              <TableViewIcon sx={{ cursor: 'pointer', color: 'success.main' }} onClick={() => download('excel', data)} />
            </Box>
          ) : null}
        </Box>
      ) : null}
      <Box {...getTableProps()} sx={{ minWidth: 'auto', overflowX: 'auto' }}>
        <TableHead>
          {headerGroups.map((headerGroup: any) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <TableCell
                  sx={{ verticalAlign: 'top', backgroundColor: 'primary.main', color: '#fff' }}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render('Header')}
                  {column.id !== 'selection' ? (
                    <TableSortLabel active={column.isSorted} direction={column.isSortedDesc ? 'desc' : 'asc'} />
                  ) : null}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.length > 0 ? (
            <FixedSizeList height={tableHeight} itemCount={rows.length} itemSize={itemHeight} width={totalColumnsWidth + scrollBarSize}>
              {RenderRow}
            </FixedSizeList>
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} sx={{ textAlign: 'left' }}>
                No Results Found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Box>
    </>
  );
}

const VirtualizedTable = (props: ChildProps) => {
  const { columns, data, itemHeight, filter, tableHeight, noPadding, loading, hide, download } = props;

  return (
    <VirtualTable
      columns={columns}
      data={data}
      itemHeight={itemHeight}
      filter={filter}
      tableHeight={tableHeight}
      noPadding={noPadding}
      loading={loading}
      hide={hide}
      download={download}
    />
  );
};

interface ChildProps {
  columns: any;
  data: any;
  itemHeight: number;
  filter?: boolean;
  tableHeight: number;
  noPadding?: boolean;
  loading?: boolean;
  hide?: boolean;
  download?: any;
}

export default VirtualizedTable;
