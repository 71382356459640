import { useEffect, useState, Fragment, useRef } from 'react';
import { Box, Grid, Typography, Button, Divider, TextField, InputAdornment } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Dropdown from '../../common/formControls/Dropdown';
import { ICollection, IUpgrade, IUpgradeTemplate, IDecor } from '../../../types/colourSelection';
import { numToCurrency, numToCurrencyDecimal } from '../../../utils/Functions';
import { mainCategories as categories } from '../../../utils/Constants';
import { Flex, FlexBetween } from '../../../commonStyles';
import { IUser } from '../../../types/user';
import ControlledInput from '../../common/ControlledInput';

const ColourSelectionDrawer = (props: ChildProps) => {
  const {
    selectedCollection,
    selectedUpgrades,
    handlePrice,
    handleSave,
    users,
    handleSalesDropdown,
    openModal,
    createDecorDocument,
    addUpgrade,
    deleteUpgrade,
    client,
  } = props;
  const [rows, setRows] = useState<IRow[]>([]);
  const [editView, setEditView] = useState<boolean>(false);
  const [credits, setCredits] = useState<number | null>(null);
  const [coordination, setCoordination] = useState<number | null>(null);

  const [cursor, setCursor] = useState<any>(null);
  const ref = useRef(null);

  useEffect(() => {
    const input: any = ref.current;
    if (input) {
      input.setSelectionRange(cursor, cursor);
    }
  }, [ref, cursor]);

  useEffect(() => {
    let mainCategories = [...new Set(selectedUpgrades.upgradeSet.map((item) => item.mainCategory))]
      .filter((category: string) => category !== 'Miscellaneous')
      .sort(function (a, b) {
        return categories.indexOf(a) - categories.indexOf(b);
      });

    let credits = selectedUpgrades.upgradeSet.filter((upgrade: IUpgrade) => upgrade.subCategory === 'Credit').reduce((a: any, b: any) => a + b.price, 0)
    let coordination = selectedUpgrades.upgradeSet.filter((upgrade: IUpgrade) => upgrade.subCategory === 'Coordination').reduce((a: any, b: any) => a + b.price, 0)

    let mainCategoryUpgrades: IRow[] = mainCategories.map((mainCategory) => {
      let row = selectedUpgrades.upgradeSet
        .filter((upgrades: IUpgrade) => mainCategory === upgrades.mainCategory)
        .map((upgrade: IUpgrade) => {
          let matchingSubMainCategory = selectedCollection?.upgradeTemplates.find(
            (upgradeTemplate: IUpgradeTemplate) =>
              upgradeTemplate.mainCategory === upgrade.mainCategory && upgradeTemplate.subCategory === upgrade.subCategory
          );

          return {
            name: upgrade.name,
            subCategory: upgrade.subCategory,
            mainCategory: upgrade.mainCategory,
            template: upgrade.upgradeTemplate,
            id: upgrade._id ? upgrade._id : upgrade.upgradeTemplate?._id,
            selectedUpgrade: upgrade.upgradeTemplate
              ? upgrade.upgradeTemplate?._id === matchingSubMainCategory?._id
                ? '-'
                : upgrade.name
              : upgrade.name,
            collectionUpgrade: matchingSubMainCategory ? matchingSubMainCategory.name : '-',
            price: upgrade.price,
            quantity: upgrade.quantity,
          };
        });

      return {
        mainCategory: mainCategory,
        upgrades: row,
      };
    });

    setCoordination(coordination!);
    setCredits(credits!);
    setRows(mainCategoryUpgrades);
  }, [selectedUpgrades]);

  return (
    <Box
      sx={{
        width: '768px',
        '@media (max-width: 600px)': {
          width: '300px',
        },
      }}
    >
      <Box sx={{ p: 2, backgroundColor: '#00142a', color: '#fff' }}>
        <FlexBetween>
          <Typography variant={'h4'} sx={{ fontSize: '0.9rem' }} gutterBottom>
            Decor Selection
          </Typography>
          <Box>
            {selectedUpgrades.upgradeSet.length && !client ? (
              <Button onClick={() => openModal('custom')} sx={{ ml: 1 }} variant="contained" color="info">
                Custom
              </Button>
            ) : null}
            {selectedUpgrades.upgradeSet.length && !client ? (
              <Button sx={{ ml: 1 }} onClick={() => createDecorDocument('preview')} variant="contained" color="info">
                Print
              </Button>
            ) : null}
            {selectedUpgrades.upgradeSet.length && !client ? (
              <Button sx={{ ml: 1 }} onClick={() => createDecorDocument('previewPrice')} variant="contained" color="info">
                Print w/ Price
              </Button>
            ) : null}
            {selectedUpgrades.upgradeSet.length ? (
              <Button sx={{ ml: 1 }} onClick={() => setEditView(!editView)} variant="contained" color="info">
                {editView ? 'Back' : 'Edit'}
              </Button>
            ) : null}
            {selectedUpgrades.upgradeSet.length ? (
              <Button onClick={() => handleSave()} sx={{ ml: 1 }} variant="contained" color="success">
                Save
              </Button>
            ) : null}
            {selectedUpgrades.upgradeSet.length && !client ? (
              <Button onClick={() => openModal('amendment')} sx={{ ml: 1 }} variant="contained" color="success">
                Create Amendment
              </Button>
            ) : null}
          </Box>
        </FlexBetween>
      </Box>
      <Box sx={{ p: 2 }}>
        {editView ? (
          <Dropdown
            title={'Decor Specialist'}
            includeEmpty={true}
            menuList={users.map((user: IUser) => user.fullName)}
            name={'salesRep'}
            handleSelect={(e: any) => handleSalesDropdown(e)}
            value={selectedUpgrades.salesRep?.fullName}
          />
        ) : (
          <Box>
            Decor Specialist: <strong>{selectedUpgrades.salesRep ? selectedUpgrades.salesRep.fullName : 'NOT SELECTED'}</strong>
          </Box>
        )}
      </Box>
      <Box sx={{ p: 2 }}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid
            alignItems="center"
            justifyContent="center"
            sx={{ fontSize: '11px', textAlign: 'left', textDecoration: 'underline' }}
            item
            sm={3}
          >
            <strong>{selectedCollection ? selectedCollection?.name : 'Main Category'}</strong>
          </Grid>
          <Grid
            alignItems="center"
            justifyContent="center"
            sx={{ fontSize: '11px', textAlign: 'left', textDecoration: 'underline' }}
            item
            sm={3}
          >
            <strong>Sub Category</strong>
          </Grid>
          <Grid
            alignItems="center"
            justifyContent="center"
            sx={{ fontSize: '11px', textAlign: 'left', textDecoration: 'underline' }}
            item
            sm={3}
          >
            <strong>Upgrade</strong>
          </Grid>
          <Grid
            alignItems="center"
            justifyContent="center"
            sx={{ fontSize: '11px', textAlign: 'center', textDecoration: 'underline' }}
            item
            sm={3}
          >
            <strong>Price</strong>
          </Grid>
          {rows.map((row: IRow) => {
            return (
              <>
                <Grid sx={{ fontSize: '11px', textAlign: 'left' }} item sm={12}>
                  <strong>{row.mainCategory}</strong>
                </Grid>
                {row.upgrades.map((upgrade: IMainCategoryUpgrade, index: number) => {
                  return (
                    <Fragment key={index}>
                      <Grid
                        alignItems="left"
                        justifyContent="left"
                        sx={{
                          pl: 1,
                          fontSize: '11px',
                          textAlign: 'left',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                        }}
                        item
                        sm={3}
                      >
                        <Box>
                          {upgrade.collectionUpgrade}{' '}
                          {upgrade.collectionUpgrade !== '-' && upgrade.selectedUpgrade && upgrade.quantity > 1
                            ? `x${upgrade.quantity}`
                            : null}
                        </Box>
                      </Grid>
                      <Grid
                        alignItems="center"
                        justifyContent="center"
                        sx={{ fontSize: '11px', textAlign: 'left', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
                        item
                        sm={3}
                      >
                        {upgrade.subCategory}
                      </Grid>
                      <Grid alignItems="center" justifyContent="center" sx={{ fontSize: '11px', textAlign: 'left' }} item sm={3}>
                        {upgrade.selectedUpgrade}{' '}
                        {upgrade.collectionUpgrade === '-' && upgrade.selectedUpgrade && upgrade.quantity > 1
                          ? `x${upgrade.quantity}`
                          : null}
                        {editView && !client ? (
                          <Flex>
                            <KeyboardArrowUpIcon
                              onClick={() => addUpgrade(upgrade.template, upgrade)}
                              sx={{ color: 'success.main', cursor: 'pointer' }}
                            />
                            <KeyboardArrowDownIcon
                              onClick={() => deleteUpgrade(upgrade.template, upgrade)}
                              sx={{ color: 'error.main', cursor: 'pointer' }}
                            />
                          </Flex>
                        ) : null}
                      </Grid>
                      {editView && !client ? (
                        <Grid alignItems="center" justifyContent="center" sx={{ fontSize: '11px', textAlign: 'center' }} item sm={3}>
                          <ControlledInput
                            sx={{
                              '& .MuiOutlinedInput-input': {
                                p: '11.5px 14px',
                              },
                            }}
                            fullWidth
                            label={'Price'}
                            title={'Price'}
                            name={'price'}
                            type="text"
                            inputMode="numeric"
                            required
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              handlePrice(e, upgrade.name!, upgrade.mainCategory, upgrade.subCategory);
                            }}
                            value={upgrade.price}
                            InputProps={{
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                          />
                        </Grid>
                      ) : (
                        <Grid alignItems="center" justifyContent="center" sx={{ fontSize: '11px', textAlign: 'center' }} item sm={3}>
                          {numToCurrency.format(upgrade.price)}
                        </Grid>
                      )}
                    </Fragment>
                  );
                })}
              </>
            );
          })}
          <Grid alignItems="center" justifyContent="center" sx={{ fontSize: '14px', textAlign: 'left' }} item sm={12}>
            <Divider />
          </Grid>
          {coordination ? (
            <>
              <Grid alignItems="center" justifyContent="center" sx={{ fontSize: '14px', textAlign: 'left' }} item sm={3}>
                <strong>Coordination Fee</strong>
              </Grid>
              <Grid alignItems="center" justifyContent="center" sx={{ fontSize: '11px', textAlign: 'center' }} item sm={3}></Grid>
              <Grid alignItems="center" justifyContent="center" sx={{ fontSize: '11px', textAlign: 'center' }} item sm={3}></Grid>
              <Grid alignItems="center" justifyContent="center" sx={{ fontSize: '14px', textAlign: 'center' }} item sm={3}>
                <strong>{numToCurrencyDecimal.format(coordination)}</strong>
              </Grid>
            </>
          ) : null}
          <Grid alignItems="center" justifyContent="center" sx={{ fontSize: '14px', textAlign: 'left' }} item sm={3}>
            <strong>Sub Total</strong>
          </Grid>
          <Grid alignItems="center" justifyContent="center" sx={{ fontSize: '11px', textAlign: 'center' }} item sm={3}></Grid>
          <Grid alignItems="center" justifyContent="center" sx={{ fontSize: '11px', textAlign: 'center' }} item sm={3}></Grid>
          <Grid alignItems="center" justifyContent="center" sx={{ fontSize: '14px', textAlign: 'center' }} item sm={3}>
            <strong>
              {numToCurrencyDecimal.format(
                selectedUpgrades.upgradeSet
                  .filter((upgrade: any) => upgrade.name !== 'Credit')
                  .reduce((accum, item) => accum + item.price, 0)
              )}
            </strong>
          </Grid>
          {credits ? (
            <>
              <Grid alignItems="center" justifyContent="center" sx={{ fontSize: '14px', textAlign: 'left' }} item sm={3}>
                <strong>Credit</strong>
              </Grid>
              <Grid alignItems="center" justifyContent="center" sx={{ fontSize: '11px', textAlign: 'center' }} item sm={3}></Grid>
              <Grid alignItems="center" justifyContent="center" sx={{ fontSize: '11px', textAlign: 'center' }} item sm={3}></Grid>
              
                <Grid alignItems="center" justifyContent="center" sx={{ fontSize: '14px', textAlign: 'center' }} item sm={3}>
                  <strong>-{numToCurrencyDecimal.format(credits)}</strong>
                </Grid>
              
            </>
          ) : null}
          {credits ? (
            <>
              <Grid alignItems="center" justifyContent="center" sx={{ fontSize: '14px', textAlign: 'left' }} item sm={4}>
                <strong>Sub Total After Credit</strong>
              </Grid>
              <Grid alignItems="center" justifyContent="center" sx={{ fontSize: '11px', textAlign: 'center' }} item sm={2}></Grid>
              <Grid alignItems="center" justifyContent="center" sx={{ fontSize: '11px', textAlign: 'center' }} item sm={3}></Grid>
              <Grid alignItems="center" justifyContent="center" sx={{ fontSize: '14px', textAlign: 'center' }} item sm={3}>
                <strong>
                  {numToCurrencyDecimal.format(
                    selectedUpgrades.upgradeSet
                      .filter((upgrade: any) => upgrade.mainCategory !== 'Miscellaneous')
                      .reduce((accum, item) => accum + item.price, 0) +
                      (coordination ? coordination : 0) -
                      (credits ? credits : 0)
                  )}
                </strong>
              </Grid>
            </>
          ) : null}
          <Grid alignItems="center" justifyContent="center" sx={{ fontSize: '14px', textAlign: 'left' }} item sm={3}>
            <strong>HST (13% Tax)</strong>
          </Grid>
          <Grid alignItems="center" justifyContent="center" sx={{ fontSize: '11px', textAlign: 'center' }} item sm={3}></Grid>
          <Grid alignItems="center" justifyContent="center" sx={{ fontSize: '11px', textAlign: 'center' }} item sm={3}></Grid>
          <Grid alignItems="center" justifyContent="center" sx={{ fontSize: '14px', textAlign: 'center' }} item sm={3}>
            <strong>
              {numToCurrencyDecimal.format(
                (selectedUpgrades.upgradeSet
                  .filter((upgrade: any) => upgrade.mainCategory !== 'Miscellaneous')
                  .reduce((accum, item) => accum + item.price, 0) +
                  (coordination ? coordination : 0) -
                  (credits ? credits : 0)) *
                  0.13
              )}
            </strong>
          </Grid>
          <Grid alignItems="center" justifyContent="center" sx={{ fontSize: '14px', textAlign: 'left' }} item sm={12}>
            <Divider />
          </Grid>
          <Grid alignItems="center" justifyContent="center" sx={{ fontSize: '14px', textAlign: 'left' }} item sm={3}>
            <strong>Total</strong>
          </Grid>
          <Grid alignItems="center" justifyContent="center" sx={{ fontSize: '11px', textAlign: 'center' }} item sm={3}></Grid>
          <Grid alignItems="center" justifyContent="center" sx={{ fontSize: '11px', textAlign: 'center' }} item sm={3}></Grid>
          <Grid alignItems="center" justifyContent="center" sx={{ fontSize: '14px', textAlign: 'center' }} item sm={3}>
            <strong>
              {numToCurrencyDecimal.format(
                (selectedUpgrades.upgradeSet
                  .filter((upgrade: any) => upgrade.mainCategory !== 'Miscellaneous')
                  .reduce((accum, item) => accum + item.price, 0) +
                  (coordination ? coordination : 0) -
                  (credits ? credits : 0)) *
                  1.13
              )}
            </strong>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

interface ChildProps {
  upgradeType: string;
  selectedCollection?: ICollection | null;
  selectedUpgrades: IDecor;
  handlePrice: (e: React.ChangeEvent<HTMLInputElement>, id: string, mainCategory: any, subCategory: any) => void;
  handleSave: () => void;
  handleSalesDropdown: (event: React.ChangeEvent<{ name?: string; value: string }>) => void;
  users: IUser[];
  openModal: any;
  createDecorDocument: any;
  addUpgrade: any;
  deleteUpgrade: any;
  client: boolean;
}

interface IRow {
  mainCategory: string;
  upgrades: IMainCategoryUpgrade[];
}

interface IMainCategoryUpgrade {
  name: string;
  subCategory: string;
  selectedUpgrade: string;
  collectionUpgrade: string;
  price: number;
  id?: string;
  quantity: number;
  mainCategory: string;
  template: IUpgradeTemplate | null;
}

export default ColourSelectionDrawer;
