import { useState, useEffect } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { TextField, Box, Button, Grid, Paper } from '@mui/material';
import { Container } from '../../../commonStyles';
import SummaryCard from './SummaryCard';
import LoadingLogo from '../../common/LoadingLogo';

const Summaries = () => {
  const [dateStart, setDateStart] = useState<Date | number | null>(new Date().setHours(0, 0, 0, 0));
  const [dateEnd, setDateEnd] = useState<Date | null>(new Date());

  const [projectSummaries, setProjectSummaries] = useState<any[]>([]);
  const [totalSummary, setTotalSummary] = useState<any>();

  const [getDailySummary, { loading }] = useLazyQuery(DEALS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    onCompleted: (data) => {
      setProjectSummaries(data.getDailySummary.deals.filter((deal: any) => deal.statuses.length));
      let project = {
        name: 'OVERALL TOTAL',
        portal: { primaryColor: '#00142a' },
      };
      let cancelled = [...data.getDailySummary.deals].reduce((a, b) => a + b.cancelled, 0);
      let rescission = [...data.getDailySummary.deals].reduce((a, b) => a + b.rescission, 0);
      let newSales = [...data.getDailySummary.deals].reduce((a, b) => a + b.newSales, 0);
      let newDeals = [...data.getDailySummary.deals].reduce((a, b) => a + b.newDeals, 0);
      let statusData = [...data.getDailySummary.deals].flatMap((data: any) => data.statuses);
      let statuses = Object.values(
        [...statusData].reduce((value, object) => {
          if (value[object._id]) {
            value[object._id].count += object.count;
            value[object._id].size += object.size;
            value[object._id].revenue += object.revenue;
            value[object._id].revenueNet += object.revenueNet;
          } else {
            value[object._id] = { ...object };
          }
          return value;
        }, {})
      );

      setTotalSummary({
        project,
        statuses,
        cancelled,
        rescission,
        newSales,
        newDeals,
      });
    },
  });

  useEffect(() => {
    getDailySummary({ variables: { projects: [], dateStart, dateEnd } });
  }, []);

  const handleDailySummary = () => {
    getDailySummary({ variables: { projects: [], dateStart, dateEnd } });
  };

  return (
    <Container>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12} sm={4}>
            <DateTimePicker
              label={'Start Date (YYYY/MM/DD)'}
              value={dateStart}
              onChange={(newValue) => {
                setDateStart(newValue);
              }}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <DateTimePicker
              label={'End Date (YYYY/MM/DD)'}
              value={dateEnd}
              onChange={(newValue) => {
                setDateEnd(newValue);
              }}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button sx={{ height: '100%' }} variant="contained" color="primary" onClick={() => handleDailySummary()}>
              Search
            </Button>
          </Grid>
        </Grid>
      </LocalizationProvider>
      {loading ? (
        <Box
          sx={{
            textAlign: 'center',
          }}
        >
          <LoadingLogo />
        </Box>
      ) : projectSummaries.length ? (
        <Box>
          <Box sx={{ border: '1px solid #000', mb: 2 }}>
            <SummaryCard projectSummary={totalSummary} dateStart={dateStart} dateEnd={dateEnd} total={true} />
          </Box>
          <Grid container spacing={2}>
            {projectSummaries.map((projectSummary: any, index: number) => {
              return (
                <Grid key={index} item xs={12} sm={6} md={4} xl={3}>
                  <Paper sx={{ height: '100%', borderRadius: 0, border: '1px solid #000' }} elevation={24}>
                    <SummaryCard key={index} projectSummary={projectSummary} dateStart={dateStart} dateEnd={dateEnd} total={false} />
                  </Paper>
                </Grid>
              );
            })}
          </Grid>
          <Box>{/* <StandardTable /> */}</Box>
        </Box>
      ) : (
        <Box sx={{ textAlign: 'center' }}>
          <em>No Activity Found! Please revise your search dates!</em>
        </Box>
      )}
    </Container>
  );
};

const DEALS = gql`
  query getDailySummary($projects: [MongoID], $dateStart: Date!, $dateEnd: Date!) {
    getDailySummary(projects: $projects, dateStart: $dateStart, dateEnd: $dateEnd) {
      deals {
        project {
          _id
          name
          createdAt
          portal {
            primaryColor
          }
        }
        cancelled
        rescission
        newSales
        newDeals
        statuses {
          _id
          count
          size
          revenue
          revenueNet
        }
      }
    }
  }
`;

export default Summaries;
