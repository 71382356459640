import { useState, useMemo, useEffect } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Box, Button, ToggleButton, ToggleButtonGroup, Typography, Autocomplete, TextField } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectProject } from '../../features/project/projectSlice';
import { ICollection, IUpgrade } from '../../types/colourSelection';
import StandardTable from '../tables/StandardTable';
import { numToCurrencyDecimal, downloadExcel, downloadPdf, normalToCamel, sortSuites, downloadDecorExcel } from '../../utils/Functions';
import { unitTypes as allUnitTypes } from '../../utils/Constants';
import { Flex, FlexBetween } from '../../commonStyles';
import { useAppDispatch } from '../../app/hooks';
import { showErrorSnackbar } from '../../features/snackbar/snackbarSlice';
import { IDeal } from '../../types/CreateDealForm';
import LoadingLogo from '../common/LoadingLogo';
import { GlobalModal } from '../../features/modal/Modal';
import { handleModal } from '../../features/modal/modalSlice';
import { selectUser } from '../../features/auth/authSlice';

const ColourSelection = () => {
  const project = useSelector(selectProject);
  const user = useSelector(selectUser);
  const storeDispatch = useAppDispatch();

  const [view, setView] = useState<string>('total');
  const [collections, setCollections] = useState([]);
  const [totals, setTotals] = useState<IDecorTotal[]>([]);
  const [categories, setCategories] = useState<ICategoryTotal[]>([]);
  const [mainCategories, setMainCategories] = useState<string[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string>(mainCategories[0]);
  const [subCategories, setSubCategories] = useState<any[]>([]);
  const [unitTypes, setUnitTypes] = useState<any[]>([]);
  const [modelTypes, setModelTypes] = useState<any[]>([]);
  const [decorSpecialist, setDecorSpecialist] = useState<any>([]);
  const [decorSpecialistUnits, setDecorSpecialistUnits] = useState<any>([]);
  const [selectedSpecialist, setSelectedSpecialist] = useState<string>('');
  const [dateStart, setDateStart] = useState<Date | number | null>(new Date().setHours(0, 0, 0, 0));
  const [dateEnd, setDateEnd] = useState<Date | null>(new Date());

  const [getDecorSummary, { loading }] = useLazyQuery(DEALS, {
    onCompleted: (data) => {
      let uniqueMainCategories: any[] = [...new Set(data.getDecorSummary.categorySummary!.map((item: any) => item._id.mainCategory))];

      let decorArray = [];
      if (data.getDecorSummary.decorSummary.upgradeSetOneTotal.revenue) {
        decorArray.push({ name: 'UPG Session 1', ...data.getDecorSummary.decorSummary.upgradeSetOneTotal });
      }
      if (data.getDecorSummary.decorSummary.upgradeSetTwoTotal.revenue) {
        decorArray.push({ name: 'UPG Session 2', ...data.getDecorSummary.decorSummary.upgradeSetTwoTotal });
      }
      if (data.getDecorSummary.decorSummary.upgradeSetThreeTotal.revenue) {
        decorArray.push({ name: 'UPG Session 3', ...data.getDecorSummary.decorSummary.upgradeSetThreeTotal });
      }

      if (decorArray.length) {
        decorArray.push({
          name: 'Total',
          count: decorArray.reduce((a: any, b: any) => {
            return a + b.count;
          }, 0),
          creditCount: decorArray.reduce((a: any, b: any) => {
            return a + b.creditCount;
          }, 0),
          creditSum: decorArray.reduce((a: any, b: any) => {
            return a + b.creditSum;
          }, 0),
          deviationCount: decorArray.reduce((a: any, b: any) => {
            return a + b.deviationCount;
          }, 0),
          deviationSum: decorArray.reduce((a: any, b: any) => {
            return a + b.deviationSum;
          }, 0),
          revenue: decorArray.reduce((a: any, b: any) => {
            return a + b.revenue;
          }, 0),
          totalCost: decorArray.reduce((a: any, b: any) => {
            return a + b.totalCost;
          }, 0),
        });
      }

      setMainCategories(uniqueMainCategories.sort());
      setSelectedCategory(uniqueMainCategories.sort()[0]);
      setCategories(data.getDecorSummary.categorySummary);
      setDecorSpecialist(data.getDecorSummary.specialistSummary);
      setTotals(decorArray);

      let subCategories = data.getDecorSummary.categorySummary.filter(
        (category: ICategoryTotal) => category._id.mainCategory === uniqueMainCategories.sort()[0]
      );

      let splitSubCategories: any[] = Object.values(
        subCategories.reduce((acc: any, item: any) => {
          acc[item._id.subCategory] = [...(acc[item._id.subCategory] || []), item];
          return acc;
        }, {})
      );

      let sub = splitSubCategories.map((subCategories: any) => {
        return {
          subCategory: subCategories[0]._id.subCategory,
          subCategoryTotal: subCategories,
        };
      });

      let unitTypeTable = [];
      let modelTypeTable = [];

      if (data.getDecorSummary.unitDecorSummary.unitDecorSummaryOne.length) {
        let splitUnitTypesOne: any[] = Object.values(
          data.getDecorSummary.unitDecorSummary.unitDecorSummaryOne.reduce((acc: any, item: any) => {
            acc[item._id.unitType] = [...(acc[item._id.unitType] || []), item];
            return acc;
          }, {})
        );

        let subUnitTypes = splitUnitTypesOne.map((unitTypes: any) => {
          return {
            unitType: unitTypes[0]._id.unitType,
            unitTypeTotal: {
              creditCount: unitTypes.reduce((a: any, b: any) => {
                return a + b.creditCount;
              }, 0),
              creditSum: unitTypes.reduce((a: any, b: any) => {
                return a + b.creditSum;
              }, 0),
              deviationCount: unitTypes.reduce((a: any, b: any) => {
                return a + b.deviationCount;
              }, 0),
              deviationSum: unitTypes.reduce((a: any, b: any) => {
                return a + b.deviationSum;
              }, 0),
              revenue: unitTypes.reduce((a: any, b: any) => {
                return a + b.revenue;
              }, 0),
              totalCost: unitTypes.reduce((a: any, b: any) => {
                return a + b.totalCost;
              }, 0),
              totalCount: unitTypes.reduce((a: any, b: any) => {
                return a + b.totalCount;
              }, 0),
            },
          };
        });

        subUnitTypes.push({
          unitType: 'Total',
          unitTypeTotal: {
            creditCount: subUnitTypes.reduce((a: any, b: any) => {
              return a + b.unitTypeTotal.creditCount;
            }, 0),
            creditSum: subUnitTypes.reduce((a: any, b: any) => {
              return a + b.unitTypeTotal.creditSum;
            }, 0),
            deviationCount: subUnitTypes.reduce((a: any, b: any) => {
              return a + b.unitTypeTotal.deviationCount;
            }, 0),
            deviationSum: subUnitTypes.reduce((a: any, b: any) => {
              return a + b.unitTypeTotal.deviationSum;
            }, 0),
            revenue: subUnitTypes.reduce((a: any, b: any) => {
              return a + b.unitTypeTotal.revenue;
            }, 0),
            totalCost: subUnitTypes.reduce((a: any, b: any) => {
              return a + b.unitTypeTotal.totalCost;
            }, 0),
            totalCount: subUnitTypes.reduce((a: any, b: any) => {
              return a + b.unitTypeTotal.totalCount;
            }, 0),
          },
        });

        unitTypeTable.push({
          name: 'UPG Session 1',
          unitTypeArray: subUnitTypes,
        });
      }

      if (data.getDecorSummary.unitDecorSummary.unitDecorSummaryTwo.length) {
        let splitUnitTypesTwo: any[] = Object.values(
          data.getDecorSummary.unitDecorSummary.unitDecorSummaryTwo.reduce((acc: any, item: any) => {
            acc[item._id.unitType] = [...(acc[item._id.unitType] || []), item];
            return acc;
          }, {})
        );

        let subUnitTypes = splitUnitTypesTwo.map((unitTypes: any) => {
          return {
            unitType: unitTypes[0]._id.unitType,
            unitTypeTotal: {
              creditCount: unitTypes.reduce((a: any, b: any) => {
                return a + b.creditCount;
              }, 0),
              creditSum: unitTypes.reduce((a: any, b: any) => {
                return a + b.creditSum;
              }, 0),
              deviationCount: unitTypes.reduce((a: any, b: any) => {
                return a + b.deviationCount;
              }, 0),
              deviationSum: unitTypes.reduce((a: any, b: any) => {
                return a + b.deviationSum;
              }, 0),
              revenue: unitTypes.reduce((a: any, b: any) => {
                return a + b.revenue;
              }, 0),
              totalCost: unitTypes.reduce((a: any, b: any) => {
                return a + b.totalCost;
              }, 0),
              totalCount: unitTypes.reduce((a: any, b: any) => {
                return a + b.totalCount;
              }, 0),
            },
          };
        });

        subUnitTypes.push({
          unitType: 'Total',
          unitTypeTotal: {
            creditCount: subUnitTypes.reduce((a: any, b: any) => {
              return a + b.unitTypeTotal.creditCount;
            }, 0),
            creditSum: subUnitTypes.reduce((a: any, b: any) => {
              return a + b.unitTypeTotal.creditSum;
            }, 0),
            deviationCount: subUnitTypes.reduce((a: any, b: any) => {
              return a + b.unitTypeTotal.deviationCount;
            }, 0),
            deviationSum: subUnitTypes.reduce((a: any, b: any) => {
              return a + b.unitTypeTotal.deviationSum;
            }, 0),
            revenue: subUnitTypes.reduce((a: any, b: any) => {
              return a + b.unitTypeTotal.revenue;
            }, 0),
            totalCost: subUnitTypes.reduce((a: any, b: any) => {
              return a + b.unitTypeTotal.totalCost;
            }, 0),
            totalCount: subUnitTypes.reduce((a: any, b: any) => {
              return a + b.unitTypeTotal.totalCount;
            }, 0),
          },
        });

        unitTypeTable.push({
          name: 'UPG Session 2',
          unitTypeArray: subUnitTypes,
        });
      }

      if (data.getDecorSummary.unitDecorSummary.unitDecorSummaryThree.length) {
        let splitUnitTypesThree: any[] = Object.values(
          data.getDecorSummary.unitDecorSummary.unitDecorSummaryThree.reduce((acc: any, item: any) => {
            acc[item._id.unitType] = [...(acc[item._id.unitType] || []), item];
            return acc;
          }, {})
        );

        let subUnitTypes = splitUnitTypesThree.map((unitTypes: any) => {
          return {
            unitType: unitTypes[0]._id.unitType,
            unitTypeTotal: {
              creditCount: unitTypes.reduce((a: any, b: any) => {
                return a + b.creditCount;
              }, 0),
              creditSum: unitTypes.reduce((a: any, b: any) => {
                return a + b.creditSum;
              }, 0),
              deviationCount: unitTypes.reduce((a: any, b: any) => {
                return a + b.deviationCount;
              }, 0),
              deviationSum: unitTypes.reduce((a: any, b: any) => {
                return a + b.deviationSum;
              }, 0),
              revenue: unitTypes.reduce((a: any, b: any) => {
                return a + b.revenue;
              }, 0),
              totalCost: unitTypes.reduce((a: any, b: any) => {
                return a + b.totalCost;
              }, 0),
              totalCount: unitTypes.reduce((a: any, b: any) => {
                return a + b.totalCount;
              }, 0),
            },
          };
        });

        subUnitTypes.push({
          unitType: 'Total',
          unitTypeTotal: {
            creditCount: subUnitTypes.reduce((a: any, b: any) => {
              return a + b.unitTypeTotal.creditCount;
            }, 0),
            creditSum: subUnitTypes.reduce((a: any, b: any) => {
              return a + b.unitTypeTotal.creditSum;
            }, 0),
            deviationCount: subUnitTypes.reduce((a: any, b: any) => {
              return a + b.unitTypeTotal.deviationCount;
            }, 0),
            deviationSum: subUnitTypes.reduce((a: any, b: any) => {
              return a + b.unitTypeTotal.deviationSum;
            }, 0),
            revenue: subUnitTypes.reduce((a: any, b: any) => {
              return a + b.unitTypeTotal.revenue;
            }, 0),
            totalCost: subUnitTypes.reduce((a: any, b: any) => {
              return a + b.unitTypeTotal.totalCost;
            }, 0),
            totalCount: subUnitTypes.reduce((a: any, b: any) => {
              return a + b.unitTypeTotal.totalCount;
            }, 0),
          },
        });

        unitTypeTable.push({
          name: 'UPG Session 3',
          unitTypeArray: subUnitTypes,
        });
      }

      if (data.getDecorSummary.modelDecorSummary.modelDecorSummaryOne.length) {
        let splitUnitTypesOne: any[] = Object.values(
          data.getDecorSummary.modelDecorSummary.modelDecorSummaryOne.reduce((acc: any, item: any) => {
            acc[item._id.unitType] = [...(acc[item._id.unitType] || []), item];
            return acc;
          }, {})
        );

        let subUnitTypes = splitUnitTypesOne.map((unitTypes: any) => {
          return {
            unitType: unitTypes[0]._id.unitType,
            unitTypeTotal: {
              creditCount: unitTypes.reduce((a: any, b: any) => {
                return a + b.creditCount;
              }, 0),
              creditSum: unitTypes.reduce((a: any, b: any) => {
                return a + b.creditSum;
              }, 0),
              deviationCount: unitTypes.reduce((a: any, b: any) => {
                return a + b.deviationCount;
              }, 0),
              deviationSum: unitTypes.reduce((a: any, b: any) => {
                return a + b.deviationSum;
              }, 0),
              revenue: unitTypes.reduce((a: any, b: any) => {
                return a + b.revenue;
              }, 0),
              totalCost: unitTypes.reduce((a: any, b: any) => {
                return a + b.totalCost;
              }, 0),
              totalCount: unitTypes.reduce((a: any, b: any) => {
                return a + b.totalCount;
              }, 0),
            },
          };
        });

        subUnitTypes.push({
          unitType: 'Total',
          unitTypeTotal: {
            creditCount: subUnitTypes.reduce((a: any, b: any) => {
              return a + b.unitTypeTotal.creditCount;
            }, 0),
            creditSum: subUnitTypes.reduce((a: any, b: any) => {
              return a + b.unitTypeTotal.creditSum;
            }, 0),
            deviationCount: subUnitTypes.reduce((a: any, b: any) => {
              return a + b.unitTypeTotal.deviationCount;
            }, 0),
            deviationSum: subUnitTypes.reduce((a: any, b: any) => {
              return a + b.unitTypeTotal.deviationSum;
            }, 0),
            revenue: subUnitTypes.reduce((a: any, b: any) => {
              return a + b.unitTypeTotal.revenue;
            }, 0),
            totalCost: subUnitTypes.reduce((a: any, b: any) => {
              return a + b.unitTypeTotal.totalCost;
            }, 0),
            totalCount: subUnitTypes.reduce((a: any, b: any) => {
              return a + b.unitTypeTotal.totalCount;
            }, 0),
          },
        });

        modelTypeTable.push({
          name: 'UPG Session 1',
          unitTypeArray: subUnitTypes,
        });
      }

      if (data.getDecorSummary.modelDecorSummary.modelDecorSummaryTwo.length) {
        let splitUnitTypesTwo: any[] = Object.values(
          data.getDecorSummary.modelDecorSummary.modelDecorSummaryTwo.reduce((acc: any, item: any) => {
            acc[item._id.unitType] = [...(acc[item._id.unitType] || []), item];
            return acc;
          }, {})
        );

        let subUnitTypes = splitUnitTypesTwo.map((unitTypes: any) => {
          return {
            unitType: unitTypes[0]._id.unitType,
            unitTypeTotal: {
              creditCount: unitTypes.reduce((a: any, b: any) => {
                return a + b.creditCount;
              }, 0),
              creditSum: unitTypes.reduce((a: any, b: any) => {
                return a + b.creditSum;
              }, 0),
              deviationCount: unitTypes.reduce((a: any, b: any) => {
                return a + b.deviationCount;
              }, 0),
              deviationSum: unitTypes.reduce((a: any, b: any) => {
                return a + b.deviationSum;
              }, 0),
              revenue: unitTypes.reduce((a: any, b: any) => {
                return a + b.revenue;
              }, 0),
              totalCost: unitTypes.reduce((a: any, b: any) => {
                return a + b.totalCost;
              }, 0),
              totalCount: unitTypes.reduce((a: any, b: any) => {
                return a + b.totalCount;
              }, 0),
            },
          };
        });

        subUnitTypes.push({
          unitType: 'Total',
          unitTypeTotal: {
            creditCount: subUnitTypes.reduce((a: any, b: any) => {
              return a + b.unitTypeTotal.creditCount;
            }, 0),
            creditSum: subUnitTypes.reduce((a: any, b: any) => {
              return a + b.unitTypeTotal.creditSum;
            }, 0),
            deviationCount: subUnitTypes.reduce((a: any, b: any) => {
              return a + b.unitTypeTotal.deviationCount;
            }, 0),
            deviationSum: subUnitTypes.reduce((a: any, b: any) => {
              return a + b.unitTypeTotal.deviationSum;
            }, 0),
            revenue: subUnitTypes.reduce((a: any, b: any) => {
              return a + b.unitTypeTotal.revenue;
            }, 0),
            totalCost: subUnitTypes.reduce((a: any, b: any) => {
              return a + b.unitTypeTotal.totalCost;
            }, 0),
            totalCount: subUnitTypes.reduce((a: any, b: any) => {
              return a + b.unitTypeTotal.totalCount;
            }, 0),
          },
        });

        modelTypeTable.push({
          name: 'UPG Session 2',
          unitTypeArray: subUnitTypes,
        });
      }

      if (data.getDecorSummary.modelDecorSummary.modelDecorSummaryThree.length) {
        let splitUnitTypesThree: any[] = Object.values(
          data.getDecorSummary.modelDecorSummary.modelDecorSummaryThree.reduce((acc: any, item: any) => {
            acc[item._id.unitType] = [...(acc[item._id.unitType] || []), item];
            return acc;
          }, {})
        );

        let subUnitTypes = splitUnitTypesThree.map((unitTypes: any) => {
          return {
            unitType: unitTypes[0]._id.unitType,
            unitTypeTotal: {
              creditCount: unitTypes.reduce((a: any, b: any) => {
                return a + b.creditCount;
              }, 0),
              creditSum: unitTypes.reduce((a: any, b: any) => {
                return a + b.creditSum;
              }, 0),
              deviationCount: unitTypes.reduce((a: any, b: any) => {
                return a + b.deviationCount;
              }, 0),
              deviationSum: unitTypes.reduce((a: any, b: any) => {
                return a + b.deviationSum;
              }, 0),
              revenue: unitTypes.reduce((a: any, b: any) => {
                return a + b.revenue;
              }, 0),
              totalCost: unitTypes.reduce((a: any, b: any) => {
                return a + b.totalCost;
              }, 0),
              totalCount: unitTypes.reduce((a: any, b: any) => {
                return a + b.totalCount;
              }, 0),
            },
          };
        });

        subUnitTypes.push({
          unitType: 'Total',
          unitTypeTotal: {
            creditCount: subUnitTypes.reduce((a: any, b: any) => {
              return a + b.unitTypeTotal.creditCount;
            }, 0),
            creditSum: subUnitTypes.reduce((a: any, b: any) => {
              return a + b.unitTypeTotal.creditSum;
            }, 0),
            deviationCount: subUnitTypes.reduce((a: any, b: any) => {
              return a + b.unitTypeTotal.deviationCount;
            }, 0),
            deviationSum: subUnitTypes.reduce((a: any, b: any) => {
              return a + b.unitTypeTotal.deviationSum;
            }, 0),
            revenue: subUnitTypes.reduce((a: any, b: any) => {
              return a + b.unitTypeTotal.revenue;
            }, 0),
            totalCost: subUnitTypes.reduce((a: any, b: any) => {
              return a + b.unitTypeTotal.totalCost;
            }, 0),
            totalCount: subUnitTypes.reduce((a: any, b: any) => {
              return a + b.unitTypeTotal.totalCount;
            }, 0),
          },
        });

        modelTypeTable.push({
          name: 'UPG Session 3',
          unitTypeArray: subUnitTypes,
        });
      }

      setCollections(data.getDecorSummary.collectionSummary);
      setModelTypes(modelTypeTable);
      setUnitTypes(unitTypeTable);
      setSubCategories(sub);
    },
  });

  const [getSpecialistDecor] = useLazyQuery(GETDEALS, {
    onCompleted: (data) => {
      let ordered = [...data.getDecorSpecialistSummary].sort((a: any, b: any) => {
        return allUnitTypes.indexOf(a._id.unitType) - allUnitTypes.indexOf(b._id.unitType);
      });
      setDecorSpecialistUnits(ordered);
      storeDispatch(handleModal(true));
    },
  });

  const [getDeals] = useLazyQuery(DECORDEALS, {
    onCompleted: (data) => {},
    onError: (err) => {
      storeDispatch(showErrorSnackbar(err.message));
    },
  });

  const [getUnits] = useLazyQuery(UNITS, {
    onCompleted: (data) => {},
    onError: (err) => {
      storeDispatch(showErrorSnackbar(err.message));
    },
  });

  useEffect(() => {
    getDecorSummary({ variables: { project: project._id, dateStart, dateEnd } });
  }, []);

  const collectionColumns = useMemo(() => {
    return [
      {
        Header: 'Collection',
        accessor: (rowData: any) => <strong>{rowData._id.name}</strong>,
      },
      {
        Header: 'Count',
        accessor: (rowData: any) => <strong>{rowData.count}</strong>,
      },
    ];
  }, []);

  const totalColumns = useMemo(() => {
    return [
      {
        Header: 'Name',
        accessor: (rowData: any) => <strong>{rowData.name}</strong>,
      },
      {
        Header: 'Count',
        accessor: (rowData: any) => <strong>{rowData.count}</strong>,
      },
      {
        Header: 'Total Revenue',
        accessor: (rowData: any) => {
          return <Box>{numToCurrencyDecimal.format(rowData.revenue * 1.13)} </Box>;
        },
      },
      {
        Header: 'Total Revenue Net HST',
        accessor: (rowData: any) => {
          let profit = ((rowData.revenue - rowData.totalCost) / rowData.revenue) * 100;
          return (
            <Box>
              <Box>{numToCurrencyDecimal.format(rowData.revenue)}</Box>
              <Box>
                {rowData.revenue > rowData.totalCost ? (
                  <Box sx={{ display: 'inline', color: profit >= 0 ? 'green' : 'red' }}>{`(${(
                    ((rowData.revenue - rowData.totalCost) / rowData.revenue) *
                    100
                  ).toFixed(2)}%)`}</Box>
                ) : null}
              </Box>
            </Box>
          );
        },
      },
      {
        Header: 'Average Revenue',
        accessor: (rowData: any) => <strong>{numToCurrencyDecimal.format(rowData.revenue / rowData.count)}</strong>,
      },
      {
        Header: 'Total Cost',
        accessor: (rowData: any) => numToCurrencyDecimal.format(rowData.totalCost),
      },
      {
        Header: 'Credit Count',
        accessor: (rowData: any) => rowData.creditCount,
      },
      {
        Header: 'Credits Applied',
        accessor: (rowData: any) => numToCurrencyDecimal.format(rowData.creditSum),
      },
      {
        Header: 'Deviation Count',
        accessor: (rowData: any) => rowData.deviationCount,
      },
      {
        Header: 'Deviation Revenue',
        accessor: (rowData: any) => numToCurrencyDecimal.format(rowData.deviationSum),
      },
    ];
  }, []);

  const categoryColumns = useMemo(() => {
    return [
      {
        Header: 'Name',
        accessor: (rowData: any) => {
          return <strong>{rowData._id.name}</strong>;
        },
      },
      {
        Header: 'Count',
        accessor: (rowData: any) => rowData.totalCount,
      },
      {
        Header: 'Revenue',
        accessor: (rowData: any) => numToCurrencyDecimal.format(rowData.revenue),
      },
      {
        Header: 'Cost',
        accessor: (rowData: any) => numToCurrencyDecimal.format(rowData.cost),
      },
    ];
  }, []);

  const unitTypeSpecialistColumns = useMemo(() => {
    return [
      {
        Header: 'Unit Type',
        accessor: (rowData: any) => {
          return <strong>{rowData._id.unitType}</strong>;
        },
      },
      {
        Header: 'Count',
        accessor: (rowData: any) => <strong>{rowData.totalCount}</strong>,
      },
      {
        Header: 'Total Revenue',
        accessor: (rowData: any) => {
          return <Box>{numToCurrencyDecimal.format(rowData.revenue * 1.13)} </Box>;
        },
      },
      {
        Header: 'Total Revenue Net HST',
        accessor: (rowData: any) => {
          let profit = ((rowData.revenue - rowData.totalCost) / rowData.revenue) * 100;
          return (
            <Box>
              <Box>{numToCurrencyDecimal.format(rowData.revenue)}</Box>
              <Box>
                {rowData.revenue > rowData.totalCost ? (
                  <Box sx={{ display: 'inline', color: profit >= 0 ? 'green' : 'red' }}>{`(${(
                    ((rowData.revenue - rowData.totalCost) / rowData.revenue) *
                    100
                  ).toFixed(2)}%)`}</Box>
                ) : null}
              </Box>
            </Box>
          );
        },
      },
      {
        Header: 'Average Revenue',
        accessor: (rowData: any) => {
          return <Box>{numToCurrencyDecimal.format(rowData.revenue / rowData.totalCount)}</Box>;
        },
      },
      {
        Header: 'Total Cost',
        accessor: (rowData: any) => numToCurrencyDecimal.format(rowData.totalCost),
      },
      {
        Header: 'Credit Count',
        accessor: (rowData: any) => rowData.creditCount,
      },
      {
        Header: 'Credits Applied',
        accessor: (rowData: any) => numToCurrencyDecimal.format(rowData.creditSum),
      },
      {
        Header: 'Deviation Count',
        accessor: (rowData: any) => rowData.deviationCount,
      },
      {
        Header: 'Deviation Revenue',
        accessor: (rowData: any) => numToCurrencyDecimal.format(rowData.deviationSum),
      },
    ];
  }, []);

  const unitTypeColumns = useMemo(() => {
    return [
      {
        Header: 'Unit Type',
        accessor: (rowData: any) => {
          return <strong>{rowData.unitType}</strong>;
        },
      },
      {
        Header: 'Count',
        accessor: (rowData: any) => <strong>{rowData.unitTypeTotal.totalCount}</strong>,
      },
      {
        Header: 'Total Revenue',
        accessor: (rowData: any) => {
          return <Box>{numToCurrencyDecimal.format(rowData.unitTypeTotal.revenue * 1.13)} </Box>;
        },
      },
      {
        Header: 'Total Revenue Net HST',
        accessor: (rowData: any) => {
          let profit = ((rowData.unitTypeTotal.revenue - rowData.unitTypeTotal.totalCost) / rowData.unitTypeTotal.revenue) * 100;
          return (
            <Box>
              <Box>{numToCurrencyDecimal.format(rowData.unitTypeTotal.revenue)}</Box>
              <Box>
                {rowData.unitTypeTotal.revenue > rowData.unitTypeTotal.totalCost ? (
                  <Box sx={{ display: 'inline', color: profit >= 0 ? 'green' : 'red' }}>{`(${(
                    ((rowData.unitTypeTotal.revenue - rowData.unitTypeTotal.totalCost) / rowData.unitTypeTotal.revenue) *
                    100
                  ).toFixed(2)}%)`}</Box>
                ) : null}
              </Box>
            </Box>
          );
        },
      },
      {
        Header: 'Average Revenue',
        accessor: (rowData: any) => {
          return <Box>{numToCurrencyDecimal.format(rowData.unitTypeTotal.revenue / rowData.unitTypeTotal.totalCount)}</Box>;
        },
      },
      {
        Header: 'Total Cost',
        accessor: (rowData: any) => numToCurrencyDecimal.format(rowData.unitTypeTotal.totalCost),
      },
      {
        Header: 'Credit Count',
        accessor: (rowData: any) => rowData.unitTypeTotal.creditCount,
      },
      {
        Header: 'Credits Applied',
        accessor: (rowData: any) => numToCurrencyDecimal.format(rowData.unitTypeTotal.creditSum),
      },
      {
        Header: 'Deviation Count',
        accessor: (rowData: any) => rowData.unitTypeTotal.deviationCount,
      },
      {
        Header: 'Deviation Revenue',
        accessor: (rowData: any) => numToCurrencyDecimal.format(rowData.unitTypeTotal.deviationSum),
      },
    ];
  }, []);

  const viewDecorSpecialist = (rowData: any) => {
    setSelectedSpecialist(rowData.fullName);
    getSpecialistDecor({ variables: { project: project._id, user: rowData._id, type: rowData.type } });
  };

  const specialistColumns = useMemo(() => {
    return [
      {
        Header: 'Decor Specialist',
        accessor: (rowData: any) => {
          return (
            <Box sx={{ cursor: 'pointer' }} onClick={() => viewDecorSpecialist(rowData)}>
              <strong>{rowData.fullName}</strong>
            </Box>
          );
        },
      },
      {
        Header: 'Count',
        accessor: (rowData: any) => rowData.count,
      },
      {
        Header: 'Total Revenue',
        accessor: (rowData: any) => numToCurrencyDecimal.format(rowData.revenue),
      },
      {
        Header: 'Average Revenue Per Sq Ft',
        accessor: (rowData: any) => numToCurrencyDecimal.format(rowData.revenue / rowData.size),
      },
      {
        Header: 'Average Revenue',
        accessor: (rowData: any) => numToCurrencyDecimal.format(rowData.revenue / rowData.count),
      },
    ];
  }, []);

  const handleView = (event: any, view: string) => {
    // setWorksheets([]);
    if (view !== null) {
      setView(view);
    }
  };

  const handleSelectedCategory = (value: string) => {
    setSelectedCategory(value);

    let subCategories = categories.filter((category: ICategoryTotal) => category._id.mainCategory === value);

    let splitSubCategories: any[] = Object.values(
      subCategories.reduce((acc: any, item: any) => {
        acc[item._id.subCategory] = [...(acc[item._id.subCategory] || []), item];
        return acc;
      }, {})
    );

    let sub = splitSubCategories.map((subCategories: any) => {
      return {
        subCategory: subCategories[0]._id.subCategory,
        subCategoryTotal: subCategories,
      };
    });

    setSubCategories(sub);
  };

  const downloadTotal = (type: string, data: any) => {
    let headers = [
      {
        label: 'Name',
        id: 'name',
      },
      {
        label: 'Count',
        id: 'count',
      },
      {
        label: 'Total Revenue',
        id: 'totalRevenue',
      },
      {
        label: 'Total Revenue Net HST',
        id: 'totalRevenueNetHST',
      },
      {
        label: 'Average Revenue',
        id: 'averageRevenue',
      },
      {
        label: 'Total Cost',
        id: 'totalCost',
      },
      {
        label: 'Credit Count',
        id: 'creditCount',
      },
      {
        label: 'Credits Applied',
        id: 'creditRevenue',
      },
      {
        label: 'Deviation Count',
        id: 'deviationCount',
      },
      {
        label: 'Deviation Revenue',
        id: 'deviationRevenue',
      },
    ];

    let widths = {
      name: 15,
      count: 15,
      totalRevenue: 15,
      totalRevenueNetHST: 15,
      averageRevenue: 15,
      totalCost: 15,
      creditCount: 15,
      creditRevenue: 15,
      deviationCount: 15,
      deviationRevenue: 15,
    };

    let pdfWidths = {
      name: 200,
      count: 200,
      totalRevenue: 200,
      totalRevenueNetHST: 200,
      averageRevenue: 200,
      totalCost: 200,
      creditCount: 200,
      creditRevenue: 200,
      deviationCount: 200,
      deviationRevenue: 200,
    };

    let newData = data.map((data: any) => {
      return {
        name: data.name,
        count: data.count,
        totalRevenue: numToCurrencyDecimal.format(data.revenue),
        totalRevenueNetHST: numToCurrencyDecimal.format(data.revenue / 1.13),
        averageRevenue: numToCurrencyDecimal.format(data.revenue / data.count),
        totalCost: numToCurrencyDecimal.format(data.totalCost),
        creditCount: data.creditCount,
        creditRevenue: numToCurrencyDecimal.format(data.creditSum),
        deviationCount: data.deviationCount,
        deviationRevenue: numToCurrencyDecimal.format(data.deviationSum),
      };
    });

    let sheetTitle = `${project.name} - Decor Total`;

    if (type === 'excel') {
      downloadExcel([newData], [headers], [], [[widths]], [sheetTitle], sheetTitle);
    } else {
      downloadPdf([newData], [headers], [], [pdfWidths], [sheetTitle], sheetTitle);
    }
  };

  const downloadUnitTypes = (type: string, data: any) => {
    let headers = [
      {
        label: 'Unit Type',
        id: 'unitType',
      },
      {
        label: 'Count',
        id: 'count',
      },
      {
        label: 'Total Revenue',
        id: 'totalRevenue',
      },
      {
        label: 'Total Revenue Net HST',
        id: 'totalRevenueNetHST',
      },
      {
        label: 'Total Cost',
        id: 'totalCost',
      },
      {
        label: 'Credit Count',
        id: 'creditCount',
      },
      {
        label: 'Credits Applied',
        id: 'creditRevenue',
      },
      {
        label: 'Deviation Count',
        id: 'deviationCount',
      },
      {
        label: 'Deviation Revenue',
        id: 'deviationRevenue',
      },
    ];

    let widths = {
      unitType: 15,
      count: 15,
      totalRevenue: 15,
      totalRevenueNetHST: 15,
      totalCost: 15,
      creditCount: 15,
      creditRevenue: 15,
      deviationCount: 15,
      deviationRevenue: 15,
    };

    let pdfWidths = {
      unitType: 200,
      count: 15,
      totalRevenue: 200,
      totalRevenueNetHST: 200,
      totalCost: 200,
      creditCount: 200,
      creditRevenue: 200,
      deviationCount: 200,
      deviationRevenue: 200,
    };

    let newData = data.map((data: any) => {
      return {
        unitType: data.unitType,
        count: data.unitTypeTotal.totalCount,
        totalRevenue: numToCurrencyDecimal.format(data.unitTypeTotal.revenue),
        totalRevenueNetHST: numToCurrencyDecimal.format(data.unitTypeTotal.revenue / 1.13),
        totalCost: data.unitTypeTotal.totalCost,
        creditCount: data.creditCount,
        creditRevenue: numToCurrencyDecimal.format(data.creditSum),
        deviationCount: data.unitTypeTotal.deviationCount,
        deviationRevenue: numToCurrencyDecimal.format(data.unitTypeTotal.deviationSum),
      };
    });

    let sheetTitle = `${project.name} - Decor Total`;

    if (type === 'excel') {
      downloadExcel([newData], [headers], [], [[widths]], [sheetTitle], sheetTitle);
    } else {
      downloadPdf([newData], [headers], [], [pdfWidths], [sheetTitle], sheetTitle);
    }
  };

  const downloadSuites = async () => {
    let data = await getDeals({ variables: { project: project._id, type: 'decorDeals' } });
    let units = await getUnits({ variables: { filter: { project: project._id, upgrades: true } } });

    let upgradeData = [...data.data.getDeals, ...units.data.unitMany].map((deals: IDeal) => {
      let upgradeSetOne = deals.upgradeSetOne ? deals.upgradeSetOne.upgradeSet.map((upgrade: IUpgrade) => upgrade.name) : [];
      let upgradeSetTwo = deals.upgradeSetTwo ? deals.upgradeSetTwo.upgradeSet.map((upgrade: IUpgrade) => upgrade.name) : [];
      let upgradeSetThree = deals.upgradeSetThree ? deals.upgradeSetThree.upgradeSet.map((upgrade: IUpgrade) => upgrade.name) : [];

      return [...new Set([...upgradeSetOne, ...upgradeSetTwo, ...upgradeSetThree].map((item) => item))];
    });

    let headers: any = [...new Set(upgradeData.flat().sort())].map((label: any) => {
      return {
        label: label,
        id: normalToCamel(label),
      };
    });

    headers.unshift({
      label: 'Total Price',
      id: 'totalPrice',
    });

    headers.unshift({
      label: 'Suite',
      id: 'suite',
    });

    let widths: any = [...new Set(upgradeData.flat().sort())].map((label: any) => {
      return {
        [normalToCamel(label)]: 15,
      };
    });

    widths.unshift({
      totalPrice: 15,
      suite: 15,
    });

    let newData = [...data.data.getDeals, ...units.data.unitMany].map((data: any) => {
      let upgradeSetOne = [];
      let upgradeSetTwo = [];
      let upgradeSetThree = [];
      if (data.upgradeSetOne) {
        upgradeSetOne = data.upgradeSetOne.upgradeSet.map((upgrade: IUpgrade) => {
          return {
            name: upgrade.name,
            price: upgrade.price,
          };
        });
      }
      if (data.upgradeSetTwo) {
        upgradeSetTwo = data.upgradeSetTwo.upgradeSet.map((upgrade: IUpgrade) => {
          return {
            name: upgrade.name,
            price: upgrade.price,
          };
        });
      }
      if (data.upgradeSetThree) {
        upgradeSetThree = data.upgradeSetThree.upgradeSet.map((upgrade: IUpgrade) => {
          return {
            name: upgrade.name,
            price: upgrade.price,
          };
        });
      }

      let upgrades = [...new Set([...upgradeSetOne, ...upgradeSetTwo, ...upgradeSetThree].map((item) => item))];
      let dealData = [...new Set(upgrades.flat())];

      let newObject: any = {
        suite: data.unit ? data.unit.suite : data.suite,
      };

      [...new Set(upgradeData.flat())].forEach((label: any) => {
        let selectedData = dealData.find((upgrade: IUpgrade) => upgrade.name === label);

        let price: any = '-';

        if (selectedData) {
          price = dealData
            .filter((upgrade: IUpgrade) => selectedData.name === upgrade.name)
            .reduce((a: any, b: any) => {
              return a + b.price;
            }, 0);
        }

        if (selectedData) {
          if (label === 'Credit') {
            newObject[normalToCamel(label)] = dealData
              .filter((upgrade: IUpgrade) => upgrade.name === 'Credit')
              .reduce((a: any, b: any) => {
                return a + b.price;
              }, 0);
          } else {
            let count = dealData.filter((upgrade: IUpgrade) => selectedData.name === upgrade.name).length;
            newObject[normalToCamel(label)] = `${numToCurrencyDecimal.format(price)} ${count > 1 ? `(${count})` : ''}`;
          }
        } else {
          newObject[normalToCamel(label)] = '-';
        }
      });

      let totalPrice = upgrades.reduce((a: any, b: any) => a + b.price, 0);

      newObject.totalPrice = upgrades.length ? numToCurrencyDecimal.format(totalPrice) : '-';

      return newObject;
    });

    let sheetTitle = `${project.name} - Decor Suites`;

    let sortedSuites = sortSuites(newData, 'suite');

    downloadExcel([sortedSuites], [headers], [], [[widths]], [sheetTitle], sheetTitle);
  };

  const downloadSuiteData = async () => {
    let data = await getDeals({ variables: { project: project._id, type: 'decorDeals' } });
    let units = await getUnits({ variables: { filter: { project: project._id, upgrades: true } } });

    let upgradeData = [...data.data.getDeals, ...units.data.unitMany].map((deals: IDeal) => {
      let upgradeSetOne = deals.upgradeSetOne ? deals.upgradeSetOne.upgradeSet.map((upgrade: IUpgrade) => upgrade) : [];
      let upgradeSetTwo = deals.upgradeSetTwo ? deals.upgradeSetTwo.upgradeSet.map((upgrade: IUpgrade) => upgrade) : [];
      let upgradeSetThree = deals.upgradeSetThree ? deals.upgradeSetThree.upgradeSet.map((upgrade: IUpgrade) => upgrade) : [];

      return {
        upgradeSetOne: upgradeSetOne,
        upgradeSetTwo: upgradeSetTwo,
        upgradeSetThree: upgradeSetThree,
      };
    });

    // Upgrade Set One
    let upgradeSetOneHeaders = upgradeData.map((upgradeSetOne: any) => upgradeSetOne.upgradeSetOne).flat();
    let uniqueHeadersOne = [
      ...upgradeSetOneHeaders
        .reduce((upgrade: any, { mainCategory, subCategory, name }: any) => {
          return upgrade.set(`${mainCategory}-${subCategory}-${name}`, { mainCategory, subCategory, name });
        }, new Map())
        .values(),
    ].sort((a: any, b: any) => {
      return a.mainCategory.localeCompare(b.mainCategory) || a.subCategory.localeCompare(b.subCategory) || a.name.localeCompare(b.name);
    });

    // Upgrade Set Two
    let upgradeSetTwoHeaders = upgradeData.map((upgradeSetTwo: any) => upgradeSetTwo.upgradeSetTwo).flat();
    let uniqueHeadersTwo = [
      ...upgradeSetTwoHeaders
        .reduce((upgrade: any, { mainCategory, subCategory, name }: any) => {
          return upgrade.set(`${mainCategory}-${subCategory}-${name}`, { mainCategory, subCategory, name });
        }, new Map())
        .values(),
    ].sort((a: any, b: any) => {
      return a.mainCategory.localeCompare(b.mainCategory) || a.subCategory.localeCompare(b.subCategory) || a.name.localeCompare(b.name);
    });

    // Upgrade Set Three
    let upgradeSetThreeHeaders = upgradeData.map((upgradeSetThree: any) => upgradeSetThree.upgradeSetThree).flat();
    let uniqueHeadersThree = [
      ...upgradeSetThreeHeaders
        .reduce((upgrade: any, { mainCategory, subCategory, name }: any) => {
          return upgrade.set(`${mainCategory}-${subCategory}-${name}`, { mainCategory, subCategory, name });
        }, new Map())
        .values(),
    ].sort((a: any, b: any) => {
      return a.mainCategory.localeCompare(b.mainCategory) || a.subCategory.localeCompare(b.subCategory) || a.name.localeCompare(b.name);
    });

    let headers = [uniqueHeadersOne, uniqueHeadersTwo, uniqueHeadersThree];

    let newData = [...data.data.getDeals, ...units.data.unitMany].map((data: any) => {
      let upgradeSetOne = [];
      let upgradeSetTwo = [];
      let upgradeSetThree = [];
      if (data.upgradeSetOne) {
        upgradeSetOne = data.upgradeSetOne.upgradeSet.map((upgrade: IUpgrade) => {
          return {
            name: upgrade.name,
            price: upgrade.price,
            mainCategory: upgrade.mainCategory,
            subCategory: upgrade.subCategory,
            quantity: upgrade.quantity,
          };
        });
      }
      if (data.upgradeSetTwo) {
        upgradeSetTwo = data.upgradeSetTwo.upgradeSet.map((upgrade: IUpgrade) => {
          return {
            name: upgrade.name,
            price: upgrade.price,
            mainCategory: upgrade.mainCategory,
            subCategory: upgrade.subCategory,
            quantity: upgrade.quantity,
          };
        });
      }
      if (data.upgradeSetThree) {
        upgradeSetThree = data.upgradeSetThree.upgradeSet.map((upgrade: IUpgrade) => {
          return {
            name: upgrade.name,
            price: upgrade.price,
            mainCategory: upgrade.mainCategory,
            subCategory: upgrade.subCategory,
            quantity: upgrade.quantity,
          };
        });
      }

      let newObject: any = {
        suite: data.unit ? data.unit.suite : data.suite,
      };

      newObject.upgradeSetOne = {
        upgrades: upgradeSetOne,
        totalPrice: upgradeSetOne.length ? upgradeSetOne.reduce((a: any, b: any) => a + b.price, 0) : '-',
        salesRep: data.upgradeSetOne && data.upgradeSetOne.salesRep ? data.upgradeSetOne.salesRep.fullName : '-',
        date: data.upgradeSetOne && data.upgradeSetOne.date ? data.upgradeSetOne.date : '-',
      };
      newObject.upgradeSetTwo = {
        upgrades: upgradeSetTwo,
        totalPrice: upgradeSetTwo.length ? upgradeSetTwo.reduce((a: any, b: any) => a + b.price, 0) : '-',
        salesRep: data.upgradeSetTwo && data.upgradeSetTwo.salesRep ? data.upgradeSetTwo.salesRep.fullName : '-',
        date: data.upgradeSetTwo && data.upgradeSetTwo.date ? data.upgradeSetTwo.date : '-',
      };
      newObject.upgradeSetThree = {
        upgrades: upgradeSetThree,
        totalPrice: upgradeSetThree ? upgradeSetThree.reduce((a: any, b: any) => a + b.price, 0) : '-',
        salesRep: data.upgradeSetThree && data.upgradeSetThree.salesRep ? data.upgradeSetThree.salesRep.fullName : '-',
        date: data.upgradeSetThree && data.upgradeSetThree.date ? data.upgradeSetThree.date : '-',
      };

      return newObject;
    });

    let sheetTitle = `${project.name} - Decor Summary`;

    let sortedSuites = sortSuites(newData, 'suite');

    downloadDecorExcel(sortedSuites, headers, sheetTitle);
  };

  const handleDecorSummary = () => {
    getDecorSummary({ variables: { project: project._id, dateStart, dateEnd } });
  };

  return !loading ? (
    <Box sx={{ p: 2 }}>
      <GlobalModal>
        <Typography sx={{ mb: 1 }} variant={'h5'}>
          <strong>{selectedSpecialist}</strong>
        </Typography>
        <StandardTable columns={unitTypeSpecialistColumns} data={decorSpecialistUnits} />
      </GlobalModal>
      <FlexBetween>
        <Typography gutterBottom variant="h5">
          <strong>Decor Report</strong>
        </Typography>
        {categories.length ? (
          <Box>
            <Button sx={{ mr: 1 }} onClick={() => downloadSuites()} variant="contained" color="success">
              Suite Total
            </Button>
            <Button onClick={() => downloadSuiteData()} variant="contained" color="success">
              Suite Summary
            </Button>
          </Box>
        ) : null}
      </FlexBetween>
      <>
        <ToggleButtonGroup
          value={view}
          exclusive
          onChange={handleView}
          aria-label="text alignment"
          sx={{
            marginBottom: '10px',
            '& .MuiToggleButton-root.Mui-selected': {
              backgroundColor: '#00142a',
              color: '#fff',
            },
            '& .MuiToggleButton-root.Mui-selected:hover': {
              backgroundColor: '#00142a',
              color: '#fff',
            },
          }}
        >
          <ToggleButton value="total" aria-label="centered">
            Total
          </ToggleButton>
          <ToggleButton value="categories" aria-label="centered">
            Categories
          </ToggleButton>
          <ToggleButton value="unitType" aria-label="centered">
            Unit Types
          </ToggleButton>
          <ToggleButton value="modelType" aria-label="centered">
            Model Types
          </ToggleButton>
          {user.type !== 'Developer' ? (
            <ToggleButton value="decorSpecialist" aria-label="centered">
              Decor Specialist
            </ToggleButton>
          ) : null}
        </ToggleButtonGroup>
        {view === 'total' ? (
          <Flex sx={{ mt: 2 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Box sx={{ mr: 2 }}>
                <DateTimePicker
                  label={'Start Date (YYYY/MM/DD)'}
                  value={dateStart}
                  onChange={(newValue) => {
                    setDateStart(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
              <Box sx={{ mr: 2 }}>
                <DateTimePicker
                  label={'End Date (YYYY/MM/DD)'}
                  value={dateEnd}
                  onChange={(newValue) => {
                    setDateEnd(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
              <Button variant="contained" color="primary" onClick={() => handleDecorSummary()}>
                Search
              </Button>
            </LocalizationProvider>
          </Flex>
        ) : null}
        <Box>
          {view === 'total' ? (
            <>
              <Box sx={{ mt: 2 }}>
                <StandardTable download={downloadTotal} columns={totalColumns} data={totals} />
              </Box>
              <Box sx={{ mt: 2 }}>
                <StandardTable columns={collectionColumns} data={collections} />
              </Box>
            </>
          ) : null}
          {view === 'categories' ? (
            <>
              <Box sx={{ mt: 2 }}>
                <Autocomplete
                  options={mainCategories.sort()}
                  getOptionLabel={(option: any) => option}
                  disableClearable={false}
                  freeSolo={false}
                  value={selectedCategory}
                  isOptionEqualToValue={(option, value) => option === value}
                  onChange={(event: any, newValue: any | null) => handleSelectedCategory(newValue)}
                  renderInput={(params) => <TextField required {...params} sx={{ width: '300px' }} label="Main Category" size="small" />}
                />
              </Box>
              <Box sx={{ mt: 2 }}>
                {subCategories.map((sub: any, index: number) => {
                  return (
                    <Box key={index} sx={{ mt: 2 }}>
                      <Typography gutterBottom variant="h2">
                        {sub.subCategory}
                      </Typography>
                      <Box>
                        <StandardTable columns={categoryColumns} data={sub.subCategoryTotal} />
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </>
          ) : null}
          {view === 'unitType' ? (
            <Box sx={{ mt: 2 }}>
              {unitTypes.map((unitType: any) => {
                return (
                  <Box sx={{ mt: 2 }}>
                    <Typography gutterBottom variant="h2">
                      {unitType.name}
                    </Typography>
                    <StandardTable download={downloadUnitTypes} columns={unitTypeColumns} data={unitType.unitTypeArray} />
                  </Box>
                );
              })}
            </Box>
          ) : null}
          {view === 'modelType' ? (
            <Box sx={{ mt: 2 }}>
              {modelTypes.map((modelType: any) => {
                return (
                  <Box sx={{ mt: 2 }}>
                    <Typography gutterBottom variant="h2">
                      {modelType.name}
                    </Typography>
                    <StandardTable download={downloadUnitTypes} columns={unitTypeColumns} data={modelType.unitTypeArray} />
                  </Box>
                );
              })}
            </Box>
          ) : null}
          {view === 'decorSpecialist' ? (
            <Box sx={{ mt: 2 }}>
              {decorSpecialist.specialistSetOneTotal.length ? (
                <>
                  <Typography gutterBottom variant="h2">
                    Upgrade Set One
                  </Typography>
                  <StandardTable columns={specialistColumns} data={decorSpecialist.specialistSetOneTotal} />
                </>
              ) : null}
              {decorSpecialist.specialistSetTwoTotal.length ? (
                <>
                  <Typography sx={{ mt: 2 }} gutterBottom variant="h2">
                    Upgrade Set Two
                  </Typography>
                  <StandardTable columns={specialistColumns} data={decorSpecialist.specialistSetTwoTotal} />
                </>
              ) : null}
              {decorSpecialist.specialistSetThreeTotal.length ? (
                <>
                  <Typography sx={{ mt: 2 }} gutterBottom variant="h2">
                    Upgrade Set Three
                  </Typography>
                  <StandardTable columns={specialistColumns} data={decorSpecialist.specialistSetThreeTotal} />
                </>
              ) : null}
            </Box>
          ) : null}
        </Box>
      </>
    </Box>
  ) : (
    <Box
      sx={{
        position: 'absolute',
        left: '50%',
        top: '50%',
        '-webkit-transform': 'translate(-50%, -50%)',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <LoadingLogo />
    </Box>
  );
};

interface IDecorTotal {
  _id?: ICollection;
  totalCount?: number;
  deviationSum: number;
  deviationCount: number;
  revenue: number;
  totalCost: number;
}

interface ICategoryTotal {
  _id: ICategory;
  revenue: number;
  totalCost: number;
}

interface ICategory {
  mainCategory: string;
  subCategory: string;
  name: string;
}

const DECORDEALS = gql`
  query getDeals($project: MongoID!, $type: String!) {
    getDeals(project: $project, type: $type) {
      _id
      unit {
        _id
        suite
      }
      upgradeSetOne {
        date
        salesRep {
          _id
          fullName
        }
        upgradeSet {
          name
          quantity
          price
          mainCategory
          subCategory
        }
        collectionTemplate {
          name
        }
      }
      upgradeSetTwo {
        date
        salesRep {
          _id
          fullName
        }
        upgradeSet {
          name
          quantity
          price
          mainCategory
          subCategory
        }
        collectionTemplate {
          name
        }
      }
      upgradeSetThree {
        date
        salesRep {
          _id
          fullName
        }
        upgradeSet {
          name
          quantity
          price
          mainCategory
          subCategory
        }
        collectionTemplate {
          name
        }
      }
    }
  }
`;

const DEALS = gql`
  query getDecorSummary($project: MongoID!, $dateStart: Date!, $dateEnd: Date!) {
    getDecorSummary(project: $project, dateStart: $dateStart, dateEnd: $dateEnd) {
      collectionSummary {
        _id {
          _id
          name
        }
        count
      }
      specialistSummary {
        specialistSetOneTotal {
          _id
          fullName
          type
          count
          size
          deviationSum
          deviationCount
          creditSum
          creditCount
          revenue
        }
        specialistSetTwoTotal {
          _id
          fullName
          type
          count
          size
          deviationSum
          deviationCount
          creditSum
          creditCount
          revenue
        }
        specialistSetThreeTotal {
          _id
          fullName
          type
          count
          size
          deviationSum
          deviationCount
          creditSum
          creditCount
          revenue
        }
      }
      decorSummary {
        upgradeSetOneTotal {
          count
          creditSum
          creditCount
          deviationSum
          deviationCount
          revenue
          totalCost
        }
        upgradeSetTwoTotal {
          count
          creditSum
          creditCount
          deviationSum
          deviationCount
          revenue
          totalCost
        }
        upgradeSetThreeTotal {
          count
          creditSum
          creditCount
          deviationSum
          deviationCount
          revenue
          totalCost
        }
      }
      categorySummary {
        _id {
          mainCategory
          subCategory
          name
        }
        totalCount
        revenue
        cost
      }
      unitDecorSummary {
        unitDecorSummaryOne {
          _id {
            unitType
          }
          creditSum
          creditCount
          deviationSum
          deviationCount
          revenue
          totalCost
          totalCount
        }
        unitDecorSummaryTwo {
          _id {
            unitType
          }
          creditSum
          creditCount
          deviationSum
          deviationCount
          revenue
          totalCost
          totalCount
        }
        unitDecorSummaryThree {
          _id {
            unitType
          }
          creditSum
          creditCount
          deviationSum
          deviationCount
          revenue
          totalCost
          totalCount
        }
      }
      modelDecorSummary {
        modelDecorSummaryOne {
          _id {
            unitType
          }
          creditSum
          creditCount
          deviationSum
          deviationCount
          revenue
          totalCost
          totalCount
        }
        modelDecorSummaryTwo {
          _id {
            unitType
          }
          creditSum
          creditCount
          deviationSum
          deviationCount
          revenue
          totalCost
          totalCount
        }
        modelDecorSummaryThree {
          _id {
            unitType
          }
          creditSum
          creditCount
          deviationSum
          deviationCount
          revenue
          totalCost
          totalCount
        }
      }
    }
  }
`;

const GETDEALS = gql`
  query getDeals($project: MongoID!, $type: String!) {
    getDeals(project: $project, type: $type) {
      _id {
        unitType
      }
      creditSum
      creditCount
      deviationSum
      deviationCount
      revenue
      totalCost
      totalCount
    }
  }
`;

const UNITS = gql`
  query unitMany($filter: FilterFindManyUnitInput) {
    unitMany(filter: $filter, limit: 10000) {
      _id
      suite
      upgradeSetOne {
        date
        salesRep {
          _id
          fullName
        }
        upgradeSet {
          name
          quantity
          price
          mainCategory
          subCategory
        }
        collectionTemplate {
          name
        }
      }
      upgradeSetTwo {
        date
        salesRep {
          _id
          fullName
        }
        upgradeSet {
          name
          quantity
          price
          mainCategory
          subCategory
        }
        collectionTemplate {
          name
        }
      }
      upgradeSetThree {
        date
        salesRep {
          _id
          fullName
        }
        upgradeSet {
          name
          quantity
          price
          mainCategory
          subCategory
        }
        collectionTemplate {
          name
        }
      }
    }
  }
`;

export default ColourSelection;
