import { useState } from 'react';
import { Box, Typography } from '@mui/material';

import ProjectsSummary from './ProjectsSummary';
import BasicTabs from '../common/BasicTabs';
import Summaries from './summary/Summaries';
import Project from './Project';

const AllProjects = () => {
  const [value, setValue] = useState(0);

  const tabs = [
    {
      label: 'Summaries',
      component: <Summaries />,
    },
    {
      label: 'Overall Report',
      component: <ProjectsSummary />,
    },
    {
      label: 'Project Details',
      component: <Project />,
    },
  ];

  return (
    <Box
      sx={{
        p: 2,
        height: '100vh',
        position: 'relative',
      }}
    >
      <Typography variant="h2" sx={{ mb: 2 }}>
        <strong>Manager Report</strong>
      </Typography>
      <BasicTabs tabs={tabs} sub={true} value={value} setValue={setValue} color={'#fff'} />
    </Box>
  );
};

export default AllProjects;
