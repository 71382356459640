import { useState } from 'react';
import { List, ListItem, Divider, ListItemText, ListItemSecondaryAction, Button } from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import { IMerge, IMergeData } from '../../types/merge';
import { IDeal, IDealArray, IPurchaserInfo } from '../../types/CreateDealForm';
import LoadingWrapper from '../common/LoadingWrapper';
import { IProject } from '../../types/project';
import { createPdf, combinePdfs, download } from '../../utils/Functions';

const Fintrac = (props: ChildProps) => {
  const { project } = props;
  const [deals, setDeals] = useState<IDeal[]>([]);
  const [fintrac, setFintrac] = useState<IMerge[]>([]);

  useQuery<IMergeData>(GETMERGE, {
    variables: { filter: { OR: [{ name: 'Fintrac' }, { name: 'Corp Fintrac' }] } },
    onCompleted: (data) => {
      setFintrac(data.mergeTemplateMany);
    },
  });

  const { loading } = useQuery<IDealArray>(GETDEALS, {
    variables: {
      filter: {
        AND: [{ cancelled: { dateCancelled: null } }, { draft: false }, { rescission: { dateRescinded: null } }],
        project: project._id,
      },
    },
    onCompleted: (data) => {
      let firmedDeals = data.dealMany
        .filter((deal: IDeal) => deal.unit.status === 'F')
        .sort((a, b) => {
          let suiteNameA = a.unit.suite.toLowerCase();
          let suiteNameB = b.unit.suite.toLowerCase();
          if (suiteNameA < suiteNameB) return -1;
          if (suiteNameA > suiteNameB) return 1;
          return 0;
        });
      setDeals(firmedDeals);
    },
  });

  const generateFintrac = async (deal: IDeal) => {
    let fintracs = await Promise.all(
      deal.purchasers.map(async (purchaser: IPurchaserInfo) => {
        let purchaserArray = [purchaser];
        let fintracForm = fintrac.find((mergeTemplate: any) => mergeTemplate.name === 'Fintrac');
        if (purchaser.corp) {
          fintracForm = fintrac.find((mergeTemplate: any) => mergeTemplate.name === 'Corp Fintrac');
        }

        let pdf = await createPdf(
          fintracForm,
          project,
          deal.unit,
          purchaserArray,
          deal.deposit,
          deal.options,
          deal.realtor,
          null,
          null,
          null,
          deal,
          null,
          true
        );
        return pdf;
      })
    );

    if (fintracs.length > 0) {
      let mergedPdf = await combinePdfs(fintracs);
      download(mergedPdf, `${project.name} - ${deal.unit.suite} - Fintrac`, 'application/pdf');
    }
  };

  const generateAllFintrac = async () => {
    let fintracs = await Promise.all(
      deals.map(async (deal: IDeal) => {
        let purchaserFintrac = await Promise.all(
          deal.purchasers.map((purchaser: IPurchaserInfo) => {
            let newDeal = { ...deal };
            newDeal.purchasers = [purchaser];
            let fintracForm = fintrac[1];
            if (purchaser.corp) {
              fintracForm = fintrac[0];
            }
            return createPdf(
              fintracForm,
              project,
              newDeal.unit,
              newDeal.purchasers,
              newDeal.deposit,
              newDeal.options,
              newDeal.realtor,
              null,
              null,
              null,
              newDeal,
              null,
              true
            );
          })
        );
        return [...purchaserFintrac];
      })
    );

    let purchaserFintracs = await fintracs.reduce((flat: any, next: any) => flat.concat(next), []);

    if (fintracs.length > 0) {
      let mergedPdf = await combinePdfs(purchaserFintracs);
      download(mergedPdf, `${project.name} - ALL FINTRACS`, 'application/pdf');
    }
  };

  return (
    <div>
      {loading ? (
        <LoadingWrapper title="Fintracs are loading..." modal={false} />
      ) : (
        <div>
          <Button sx={{ mt: 1, mr: 1, mb: 1 }} onClick={() => generateAllFintrac()} variant="contained" color="secondary">
            Download All Fintracs
          </Button>
          <List component="nav" aria-label="mergeTemplates">
            {deals.length > 0 || !fintrac ? (
              deals.map((deal: IDeal) => {
                return (
                  <>
                    <ListItem>
                      <ListItemText primary={`${deal.unit.suite}`} />
                      <ListItemSecondaryAction>
                        <Button onClick={() => generateFintrac(deal)} sx={{ mr: 1 }} variant="contained" color="secondary">
                          Download Fintrac
                        </Button>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                  </>
                );
              })
            ) : (
              <div>There are no fintracs prepared for this project</div>
            )}
          </List>
        </div>
      )}
    </div>
  );
};

interface ChildProps {
  project: IProject;
}

const GETMERGE = gql`
  query mergeTemplateMany($filter: FilterFindManyMergeTemplateInput) {
    mergeTemplateMany(filter: $filter) {
      _id
      project {
        _id
      }
      name
      mergeFields {
        key
        index
        pageNumber
        x
        y
        fontSize
        format
        wrap
      }
      signFields {
        key
        index
        pageNumber
        x
        y
      }
      type
      getUrl
      putUrl
    }
  }
`;

const GETDEALS = gql`
  query dealMany($filter: FilterFindManyDealInput) {
    dealMany(filter: $filter, limit: 10000) {
      _id
      unit {
        _id
        suite
        unit
        level
        modelType
        basePrice
        size
        status
        getUrl
        putUrl
        bathroom
        exposure
        status
        outdoorSize
        outdoorType
        unitType
        originalPrice
        basePrice
        history {
          type
          description
          timestamp
          _id
        }
        type
      }
      project {
        _id
      }
      purchasers {
        _id
        email
        firstName
        lastName
        corp
        sin
        dob
        streetAddress
        city
        province
        country
        postalCode
        occupation
        employer
        directors
        businessNumber
        signingOfficers {
          fullName
          dob
          sin
          primaryPhone
          streetAddress
          email
        }
        purchaserType
        primaryPhone
        idType
        idNumber
        idExpiry
        unit
        idJurisdiction
        getUrl
        putUrl
      }
      realtor {
        _id
        email
        firstName
        lastName
        fullName
        brokerage
        streetAddress
        city
        province
        country
        postalCode
        brokeragePhone
        brokerageFax
        directPhone
      }
      deposit {
        name
        amount
        dueDate
        accountNumber
        chequeNumber
        chequeDate
        chequeType
        chequeAmount
      }
      options {
        name
        purchaseAmount
        amount
      }
      basePrice
      totalPrice
      tags
      mortgage {
        lender
        revisions
        amount
        getUrl
        putUrl
      }
      salesRep {
        fullName
      }
      solicitor {
        solicitor
        firm
        streetAddress
        city
        province
        postalCode
        email
        primaryPhone
      }
      thirdParty {
        _id
        fullName
        streetAddress
        dob
        primaryPhone
        email
        occupation
        corpNumber
        relationship
      }
      worksheet {
        _id
        status
      }
      preparedDate
      signDate
      executeDate
      firmDate
      createdAt
    }
  }
`;

export default Fintrac;
