import React from 'react';
import { Typography } from '@mui/material';

const PageTitle = (props: ChildProps) => {
  return (
    <div>
      <Typography variant={'h5'} gutterBottom>
        {props.title}
      </Typography>
    </div>
  );
};

interface ChildProps {
  title: String;
}

export default PageTitle;
