import { useContext } from 'react';
import { Box, FormLabel, FormControl, FormGroup, FormControlLabel, Switch } from '@mui/material';
import { PriceGridContext } from '../../../context/PriceGridContext';

const EditUnitDisplay = () => {
  const { displayedInfo, setDisplayedInfo } = useContext(PriceGridContext);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDisplayedInfo({ ...displayedInfo, [event.target.name]: event.target.checked });
  };

  return (
    <Box
      sx={{
        backgroundColor: '#fff',
        border: '3px solid #000',
        paddingTop: '10px',
        paddingBottom: '40px',
        paddingRight: '30px',
        paddingLeft: '30px',
        height: '670px',
        width: '500px',
        '@media (max-height: 600px)': {
          height: '560px',
        },
      }}
    >
      <Box
        sx={{
          justifyContent: 'center',
          display: 'flex',
          marginBottom: '15px',
        }}
      >
        <h1
          style={{
            textAlign: 'center',
            marginBottom: '0px',
            marginTop: '0px',
          }}
        >
          Change View
        </h1>
      </Box>
      <FormControl component="fieldset">
        <FormLabel
          component="legend"
          sx={{
            marginBottom: '15px',
            '@media (max-height: 600px)': {
              marginBottom: '0px',
            },
          }}
        >
          Select the information that you want displayed on the price grid.
        </FormLabel>
        <FormGroup>
          <FormControlLabel
            control={<Switch checked={displayedInfo.unitNumber} onChange={handleChange} name="unitNumber" />}
            label="Unit Number"
          />
          <FormControlLabel
            control={<Switch checked={displayedInfo.unitType} onChange={handleChange} name="unitType" />}
            label="Unit Type"
          />
          <FormControlLabel
            control={<Switch checked={displayedInfo.modelType} onChange={handleChange} name="modelType" />}
            label="Model Type"
          />
          <FormControlLabel
            control={<Switch checked={displayedInfo.allocation} onChange={handleChange} name="allocation" />}
            label="Allocations"
          />
          <FormControlLabel
            control={<Switch checked={displayedInfo.bathrooms} onChange={handleChange} name="bathrooms" />}
            label="Bathrooms"
          />
          <FormControlLabel
            control={<Switch checked={displayedInfo.basePrice} onChange={handleChange} name="basePrice" />}
            label="Base Price"
          />
          <FormControlLabel
            control={<Switch checked={displayedInfo.pricePerSquareFoot} onChange={handleChange} name="pricePerSquareFoot" />}
            label="Price Per Square Foot"
          />
          <FormControlLabel control={<Switch checked={displayedInfo.size} onChange={handleChange} name="size" />} label="Size" />
          <FormControlLabel
            control={<Switch checked={displayedInfo.outdoorSize} onChange={handleChange} name="outdoorSize" />}
            label="Outdoor Size"
          />
          <FormControlLabel
            control={<Switch checked={displayedInfo.outdoorType} onChange={handleChange} name="outdoorType" />}
            label="Outdoor Type"
          />
          <FormControlLabel control={<Switch checked={displayedInfo.level} onChange={handleChange} name="level" />} label="Level" />
          <FormControlLabel
            control={<Switch checked={displayedInfo.exposure} onChange={handleChange} name="exposure" />}
            label="Exposure"
          />
        </FormGroup>
      </FormControl>
    </Box>
  );
};

export default EditUnitDisplay;
