import { useState } from 'react';
import { Box, Paper, Tooltip, Typography, Modal, Fade, Button, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Droppable, Draggable } from '@hello-pangea/dnd';
import { FlexBetween } from '../../commonStyles';
import TaskDetails from './TaskDetails';
import { ITask } from '../../types/task';
import TaskDraggable from './TaskDraggable';
import CommentsTable from '../common/CommentsTable';
import { IComment } from '../../types/comment';
import { useAppDispatch } from '../../app/hooks';
import { showSuccessSnackbar, showErrorSnackbar } from '../../features/snackbar/snackbarSlice';

const TaskCard = (props: ChildProps) => {
  const storeDispatch = useAppDispatch();
  const { title, data, updateTask, value, deleteAttachment, createTask } = props;
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalType, setModalType] = useState<string>('');
  const [task, setTask] = useState<ITask | null>(null);
  const [comments, setComments] = useState<IComment[]>([]);
  const [reason, setReason] = useState<string>('');

  const handleComments = async (comments: IComment[], taskId: string) => {
    setComments(comments);
    let taskComments = task?.comments.map((comment: IComment) => comment._id);
    await updateTask({
      variables: {
        _id: task?._id,
        record: {
          comments: [taskComments, taskId].flat(),
        },
      },
    }).then((res: any) => {
      if (res.data) {
        storeDispatch(showSuccessSnackbar('Comments Updated!'));
      }
    });
  };

  const archiveTask = async (task: ITask, type: string) => {
    if (type === 'archive') {
      if (task?.status !== 'completed' && !reason) return storeDispatch(showErrorSnackbar('Need a Reason'));
    }
    await updateTask({
      variables: {
        _id: task?._id,
        record: {
          status: task?.status === 'archived' ? 'newTasks' : 'archived',
          reason: reason,
        },
      },
    }).then((res: any) => {
      if (res.data) {
        storeDispatch(showSuccessSnackbar('Task Updated!'));
        setModalOpen(false);
        setModalType('');
        setReason('');
      }
    });
  };

  return (
    <Paper sx={{ backgroundColor: '#fff' }} elevation={24}>
      <Modal
        open={modalOpen}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          m: 3,
        }}
        onClose={() => setModalOpen(false)}
      >
        <Fade in={modalOpen}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: {
                xs: 450,
                sm: 700,
                md: 800,
                lg: 1200,
              },
              maxHeight: {
                xs: 550,
                sm: 600,
                md: 600,
                lg: 650,
                xl: 850,
              },
              overflowY: 'auto',
              backgroundColor: '#fff',
              padding: '20px',
              border: '2px solid #000',
            }}
          >
            {modalType === 'archive' ? (
              <Box>
                <Typography variant="h2">
                  <strong>{task?.status === 'archived' ? 'Unarchive' : 'Archive'}</strong>
                  <TextField
                    sx={{ mt: 2 }}
                    fullWidth
                    multiline
                    rows={5}
                    name={'reason'}
                    onChange={(e: any) => setReason(e.target.value)}
                    value={reason}
                    required={true}
                    label={'Archive Reason'}
                    placeholder={''}
                  />
                  <FlexBetween sx={{ mt: 2 }}>
                    <Button onClick={() => setModalOpen(false)} variant="contained" color="info">
                      Cancel
                    </Button>
                    <Button onClick={() => archiveTask(task!, 'archive')} variant="contained" color="success">
                      {task?.status === 'archived' ? 'Unarchive' : 'Archive'}
                    </Button>
                  </FlexBetween>
                </Typography>
              </Box>
            ) : null}
            {modalType === 'details' ? (
              <TaskDetails
                task={task}
                setModalOpen={setModalOpen}
                createTask={createTask}
                updateTask={updateTask}
                deleteAttachment={deleteAttachment}
              />
            ) : null}
            {modalType === 'comments' ? (
              <CommentsTable
                title={`${task?.title}`}
                setComments={handleComments}
                commentType={'task'}
                task={task!}
                comments={comments}
                commentCategory={'task'}
              />
            ) : null}
          </Box>
        </Fade>
      </Modal>
      <Box sx={{ p: 2 }}>
        <FlexBetween>
          <Typography sx={{ alignSelf: 'center' }} variant="h3">
            <strong>{title}</strong>
          </Typography>
          <Box sx={{ display: 'flex', alignSelf: 'center' }}>
            {title === 'New Tasks' && value === 1 ? (
              <Box sx={{ alignSelf: 'center' }}>
                <Tooltip title="Create Task">
                  <AddIcon
                    onClick={() => {
                      setModalType('details');
                      setModalOpen(true);
                      setTask(null);
                    }}
                    sx={{ alignSelf: 'center', cursor: 'pointer' }}
                  />
                </Tooltip>
              </Box>
            ) : null}
          </Box>
        </FlexBetween>
      </Box>
      <Box sx={{ height: '100%' }}>
        <Droppable droppableId={title}>
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              <Box sx={{ py: data.length ? 2 : 0 }}>
                {data.map((task: ITask, index: number) => (
                  <Draggable key={task._id!} draggableId={task._id!} index={index}>
                    {(provided) => (
                      <Box sx={{ px: 2, mb: 1 }} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <TaskDraggable
                          task={task}
                          setTask={setTask}
                          setModalOpen={setModalOpen}
                          setModalType={setModalType}
                          setComments={setComments}
                          archiveTask={archiveTask}
                        />
                      </Box>
                    )}
                  </Draggable>
                ))}
              </Box>
              {provided.placeholder}
              {!data.length ? (
                <Box sx={{ p: 2 }}>
                  <em>No Tasks in this category.</em>
                </Box>
              ) : null}
            </div>
          )}
        </Droppable>
      </Box>
    </Paper>
  );
};

interface ChildProps {
  title: string;
  data: any;
  updateTask: any;
  deleteAttachment: any;
  createTask: any;
  value?: number;
}

export default TaskCard;
