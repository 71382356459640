import { Grid } from '@mui/material';
import { ICollection } from '../../../types/colourSelection'
import CollectionCard from './CollectionCard';

const Collections = (props: ChildProps) => {
  const { collections, handleEditCollection } = props; 
  return (
    <Grid container spacing={2}>
      {collections.map((collection: ICollection, index: number) => {
        return (
          <Grid key={index} onClick={() => handleEditCollection(collection)} item lg={3} md={4} sm={6} xs={12}>
            <CollectionCard collection={collection} />
          </Grid>
        )
      })}
    </Grid>
  )
}

interface ChildProps {
  collections: ICollection[];
  handleEditCollection: any;
}

export default Collections