import { useEffect, useState, useMemo } from 'react';
import { withRouter } from '../../utils/WithRouter';
import { gql, useLazyQuery } from '@apollo/client';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import { selectProject } from '../../features/project/projectSlice';
import StandardTable from '../tables/StandardTable';
import Dropdown from '../common/formControls/Dropdown';
import { IUnit } from '../../types/unit';
import { numToCurrency, dateDiffInDays, checkRealtorPublic } from '../../utils/Functions';
import { FlexBetween } from '../../commonStyles';
import { downloadExcel, downloadPdf } from '../../utils/Functions';

const AllocationTable = () => {
  const navigate = useNavigate();

  const project = useSelector(selectProject);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [count, setCount] = useState<number>(0);
  const [units, setUnits] = useState<IRow[]>([]);
  const [searchType, setSearchType] = useState<string>('All');
  const { projectid } = useParams();

  const [getPresale, { loading }] = useLazyQuery(PRESALE, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setUnits(data.getDashboardDeals.units);
      setCount(data.getDashboardDeals.count);
    },
  });

  useEffect(() => {
    getPresale({
      variables: { project: projectid, type: searchType, page: pageNumber, perPage: 15, search: '' },
    });
  }, [pageNumber, projectid, searchType]);

  const columns = useMemo(() => {
    return [
      {
        Header: 'Suite',
        accessor: (rowData: any) => {
          return (
            <Link
              style={{ textDecoration: 'none', color: 'inherit' }}
              rel="noopener noreferrer"
              target="_blank"
              to={`/${projectid}/dashboard/${rowData._id}`}
            >
              <strong style={{ cursor: 'pointer' }}>{rowData.suite}</strong>
            </Link>
          );
        },
      },
      {
        Header: 'Status',
        accessor: (rowData: any) => rowData.status,
      },
      {
        Header: '1st Deposit Cheque',
        accessor: (rowData: any) => {
          if (rowData.deal && rowData.deal.deposit.length) {
            let pendingDeposits =
              rowData.deal.pendingDeposits.length &&
              rowData.deal.pendingDeposits[0] &&
              rowData.deal.pendingDeposits[0].chequeAmount &&
              (rowData.deal.pendingDeposits[0].chequeType === 'received' || rowData.deal.pendingDeposits[0].chequeType === 'wire')
                ? true
                : false;
            let deposit =
              rowData.deal.deposit[0] &&
              rowData.deal.deposit[0].chequeAmount &&
              (rowData.deal.deposit[0].chequeType === 'received' || rowData.deal.deposit[0].chequeType === 'wire')
                ? true
                : false;
            if (pendingDeposits || deposit) {
              return <CheckCircleIcon color="success" />;
            } else return <HighlightOffIcon color="error" />;
          } else return 'N/A';
        },
      },
      {
        Header: 'Days Passed Since Last Status Change',
        accessor: (rowData: any) => {
          return getDaysPassed(rowData.status, rowData, rowData.deal);
        },
      },
      {
        Header: 'Realtor',
        accessor: (rowData: any) => {
          if (rowData.deal) {
            if (rowData.deal.realtor.length) {
              return rowData.deal.realtor[0].fullName
            } else {
              return checkRealtorPublic(rowData.deal.tags);
            }
          } else {
            if (rowData.allocation) {
              return rowData.allocation.fullName;
            } else if (rowData.tempAllocation) {
              return rowData.tempAllocation;
            } else return '';
          }
        },
      },
      {
        Header: 'Model',
        accessor: (rowData: any) => rowData.modelType,
      },
      {
        Header: 'Unit Type',
        accessor: (rowData: any) => rowData.unitType,
      },
      {
        Header: 'Unit Price',
        accessor: (rowData: any) => numToCurrency.format(rowData.basePrice),
      },
    ];
  }, [projectid]);

  const handleGlobalFilterValue = (value: string) => {
    setPageNumber(1);
    getPresale({
      variables: { project: projectid, type: searchType, page: pageNumber, perPage: 15, search: value },
    });
  };

  const getDaysPassed = (status: string, unit: IUnit, deal: any) => {
    if (status === 'W') {
      return 'N/A';
    } else if (status === 'WA') {
      return unit.worksheets[0].updatedAt ? dateDiffInDays(new Date(unit.worksheets[0].updatedAt), new Date()) : '0';
    } else if (status === 'O' || status === 'IP') {
      if (deal) {
        return deal.createdAt ? dateDiffInDays(new Date(deal.createdAt), new Date()) : '0';
      } else return 0;
    } else if (status === 'S') {
      if (deal) {
        return deal.signDate ? dateDiffInDays(new Date(deal.signDate), new Date()) : '0';
      } else return 0;
    } else {
      return unit.allocatedDate ? dateDiffInDays(new Date(unit.allocatedDate), new Date()) : '0';
    }
  };

  const download = async (type: string, data: any) => {
    let allData = data;
    allData = await getPresale({
      variables: { project: projectid, type: searchType, page: pageNumber, perPage: 1000, search: '' },
    });
    allData = allData.data.getDashboardDeals.units;
    let unitHeaders = [
      {
        label: 'Suite',
        id: 'suite',
      },
      {
        label: 'Status',
        id: 'status',
      },
      {
        label: '1st Deposit',
        id: 'deposit',
      },
      {
        label: 'Days Passed',
        id: 'daysPassed',
      },
      {
        label: 'Realtor',
        id: 'realtor',
      },
      {
        label: 'Model Type',
        id: 'modelType',
      },
      {
        label: 'Unit Type',
        id: 'unitType',
      },
      {
        label: 'Unit Price',
        id: 'unitPrice',
      },
    ];

    let widths = {
      suite: 15,
      status: 15,
      deposit: 15,
      daysPassed: 15,
      realtor: 15,
      modelType: 15,
      unitType: 15,
      unitPrice: 15,
    };

    let pdfWidths = {
      suite: 200,
      status: 200,
      deposit: 200,
      daysPassed: 200,
      realtor: 200,
      modelType: 200,
      unitType: 200,
      unitPrice: 200,
    };

    let updatedData = allData.map((data: any) => {
      return {
        suite: data.suite,
        status: data.status,
        deposit:
          data.deal && data.deal.deposit
            ? data.deal.deposit[0].chequeAmount &&
              (data.deal.deposit[0].chequeType === 'received' || data.deal.deposit[0].chequeType === 'wire')
              ? true
              : false
            : false,
        daysPassed: getDaysPassed(data.status, data, data.deal),
        realtor: data.deal ? (data.deal.realtor.length ? data.deal.realtor[0].fullName : 'Public') : '',
        modelType: data.modelType,
        unitType: data.unitType,
        unitPrice: numToCurrency.format(data.basePrice),
      };
    });

    let sheetTitle = `${project.name} - ${searchType}`;

    if (type === 'excel') {
      downloadExcel([updatedData], [unitHeaders], [], [[widths]], [sheetTitle], sheetTitle);
    } else {
      downloadPdf([updatedData], [unitHeaders], [], [pdfWidths], [sheetTitle], sheetTitle);
    }
  };

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
      }}
    >
      <FlexBetween
        sx={{
          alignItems: 'center',
          p: 2,
        }}
      >
        <Typography variant={'h5'} gutterBottom>
          Presale
        </Typography>
        <Dropdown
          title={'Select Type'}
          menuList={['All', 'Out For Signing', 'Signed', 'Designated']}
          name={'projectType'}
          handleSelect={(e: any) => setSearchType(e.target.value)}
          value={searchType ? searchType : ''}
        />
      </FlexBetween>
      <Box>
        <StandardTable
          download={download}
          data={units}
          columns={columns}
          loading={loading}
          handleGlobalFilterValue={handleGlobalFilterValue}
          count={count}
        />
        {count > 15 ? (
          <Box sx={{ textAlign: 'center', mt: 1 }}>
            <Button disabled={pageNumber === 1} onClick={() => setPageNumber(pageNumber - 1)}>
              {'<'}
            </Button>
            <span>{pageNumber}</span>
            <Button disabled={pageNumber === Math.ceil(count / 15)} onClick={() => setPageNumber(pageNumber + 1)}>
              {'>'}
            </Button>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

interface IRow {
  id: string;
  status: string;
  numberOfDaysPassed: string | number;
  realtorName?: string;
  modelType: string;
  basePrice: string;
}

const PRESALE = gql`
  query getDashboardDeals($project: MongoID!, $type: String!, $page: Int!, $perPage: Int!, $search: String) {
    getDashboardDeals(project: $project, type: $type, page: $page, perPage: $perPage, search: $search) {
      units {
        _id
        allocation {
          _id
          fullName
        }
        createdAt
        tempAllocation
        suite
        unit
        modelType
        unitType
        size
        basePrice
        status
        allocatedDate
        worksheets {
          updatedAt
        }
        deal {
          pendingDeposits {
            chequeAmount
            chequeType
          }
          deposit {
            chequeAmount
            chequeType
          }
          salesRep {
            fullName
          }
          createdAt
          signDate
          firmDate
          tags
          realtor {
            fullName
            realtor {
              _id
            }
          }
          _id
        }
      }
      count
    }
  }
`;

export default withRouter(AllocationTable);
