import { subYears } from "date-fns";

export const purchaserReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'ADD':
      return [
        ...state,
        {
          project: action.payload,
          firstName: '',
          lastName: '',
          corp: false,
          primaryPhone: '',
          email: '',
          dob: subYears(new Date(), 18),
          streetAddress: '',
          city: '',
          province: '',
          country: '',
          postalCode: '',
          idType: '',
          idNumber: '',
          idJurisdiction: '',
          idExpiry: new Date(),
          proofExpiry: null,
          proofNumber: '',
          proofType: '',
          sin: '',
          occupation: '',
          employer: '',
          purchaserType: '',
          directors: '',
          businessNumber: '',
          signingOfficers: [],
          identifications: [],
        },
      ];
    case 'UPDATE':
      return state.map((state: any, index: number) => {
        if (index === action.payload.index) {
          return {
            ...state,
            [action.payload.field]: action.payload.value,
          };
        } else return state;
      });
    case 'UPDATEADDRESS':
      return state.map((state: any, index: number) => {
        if (index === action.payload.index) {
          return {
            ...state,
            ...action.payload.value
          };
        } else return state;
      });
    case 'DELETE':
      return state.filter((state: any, index: number) => index !== action.payload.index);
    case 'SWAP':
      return action.payload;
    default:
      throw new Error();
  }
};

export const realtorReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'ADD':
      return {
        firstName: '',
        lastName: '',
        email: '',
        directPhone: '',
        streetAddress: '',
        city: '',
        province: '',
        country: '',
        postalCode: '',
        brokerage: '',
        brokeragePhone: '',
        brokerageFax: ''
      }
    case 'UPDATE':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    case 'UPDATEADDRESS':
      return {
        ...state,
        ...action.payload.value
      };
    case 'DELETE':
      return state.filter((state: any, index: number) => index !== action.payload.index);
    case 'SWAP':
      return action.payload;
    default:
      throw new Error();
  }
};
