import { useState } from 'react';
import {
  DndContext,
  useDraggable,
  useSensor,
  MouseSensor,
  TouchSensor,
  KeyboardSensor,
  PointerActivationConstraint,
  Modifiers,
  useSensors,
  useDndMonitor,
} from '@dnd-kit/core';

import type { Coordinates } from '@dnd-kit/utilities';
import { Draggable } from './Draggable';

const defaultCoordinates = {
  x: 0,
  y: 0,
};

export function DraggableField({
  mergeItem,
  activationConstraint,
  handle,
  label = 'Go ahead, drag me.',
  modifiers,
  style,
  buttonStyle,
  handleDragEnd,
  showWrap,
  reset,
}: Props) {
  const [{ x, y }, setCoordinates] = useState<Coordinates>(defaultCoordinates);
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint,
  });
  const touchSensor = useSensor(TouchSensor, {
    activationConstraint,
  });
  const keyboardSensor = useSensor(KeyboardSensor, {});
  const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor);

  function Component() {
    // Monitor drag and drop events that happen on the parent `DndContext` provider
    useDndMonitor({
      onDragStart(event) {},
      onDragMove(event) {},
      onDragOver(event) {},
      onDragEnd(event) {
        handleDragEnd(event, mergeItem);
      },
      onDragCancel(event) {},
    });
    return <div></div>;
  }

  return (
    <DndContext
      sensors={sensors}
      onDragEnd={({ delta }) => {
        // if (!reset) {
        //   setCoordinates(({x, y}) => {
        //     return {
        //       x: x + delta.x,
        //       y: y + delta.y,
        //     };
        //   });
        // }
      }}
      modifiers={modifiers}
    >
      <Component />
      <DraggableItem
        label={label}
        handle={handle}
        top={y}
        left={x}
        style={style}
        buttonStyle={buttonStyle}
        mergeItem={mergeItem}
        reset={reset}
        showWrap={showWrap}
      />
    </DndContext>
  );
}

export function DraggableItem({ label, style, top, left, handle, buttonStyle, mergeItem, reset, showWrap }: DraggableItemProps) {
  const { attributes, isDragging, listeners, setNodeRef, transform } = useDraggable({
    id: mergeItem.mergeId,
    data: {
      merge: mergeItem,
    },
  });

  return (
    <Draggable
      ref={setNodeRef}
      dragging={isDragging}
      handle={handle}
      label={label}
      listeners={listeners}
      style={{ ...style, top, left }}
      buttonStyle={buttonStyle}
      transform={transform}
      mergeItem={mergeItem}
      reset={reset}
      showWrap={showWrap}
      {...attributes}
    />
  );
}

interface DraggableItemProps {
  label: string;
  handle?: boolean;
  style?: React.CSSProperties;
  buttonStyle?: React.CSSProperties;
  top?: number;
  left?: number;
  mergeItem: any;
  showWrap?: any;
  reset: boolean;
}

interface Props {
  activationConstraint?: PointerActivationConstraint;
  handle?: boolean;
  modifiers?: Modifiers;
  buttonStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  label?: string;
  mergeItem: any;
  handleDragEnd: any;
  reset: boolean;
  showWrap?: any;
}
