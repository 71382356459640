import { useState } from 'react';
import { Paper, Box, Skeleton, Typography } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import { useSelector } from 'react-redux';

import { selectPortal } from '../../features/portal/portalSlice';
import { numToCurrency, unitContent } from '../../utils/Functions';
import { Flex, FlexBetween } from '../../commonStyles';

const FloorplanCard = (props: ChildProps) => {
  const { unit, handleOpen, index, type, handleFavourites } = props;
  const portal = useSelector(selectPortal);

  const [loading, setLoading] = useState<boolean>(true);

  return (
    <Paper
      sx={{ position: 'relative', height: '100%', opacity: unit.status && unit.status !== 'HL' && unit.status !== 'SHL' ? 0.4 : 1 }}
      elevation={12}
    >
      {unit.status && unit.status !== 'HL' && unit.status !== 'SHL' ? (
        <Box sx={{ position: 'absolute', top: '50%', left: '50%' }}>
          <Typography sx={{ color: 'red', transform: 'translateX(-50%) translateY(-50%) rotate(-45deg)' }} variant={'h1'} gutterBottom>
            Sold!
          </Typography>
        </Box>
      ) : null}
      <FlexBetween sx={{ p: 2, backgroundColor: portal.primaryColor ? portal.primaryColor : '#00142a', color: '#fff' }}>
        <Flex sx={{ flexDirection: 'column' }}>
          <Box>Suite {unit.suite}</Box>
          <Box>{numToCurrency.format(unit.basePrice)}</Box>
        </Flex>
        <Flex sx={{ flexDirection: 'column', position: 'relative' }}>
          {type === 'favourite' ? (
            <Box sx={{ position: 'absolute', top: -15, right: -15 }}>
              <StarIcon onClick={() => handleFavourites(unit)} sx={{ color: 'yellow', cursor: 'pointer' }} />
            </Box>
          ) : null}
          <Box sx={{ display: 'inline', mr: 1 }}>{unit.modelType}</Box>
          <Box sx={{ display: 'inline' }}>{unit.unitType}</Box>
        </Flex>
      </FlexBetween>
      <Box onClick={() => handleOpen(index, type)} sx={{ cursor: 'pointer' }}>
        {loading ? (
          <Box sx={{ width: '100%', height: '100%' }}>
            <Skeleton variant="rectangular" animation="wave" height={400} />
          </Box>
        ) : null}
        <img
          style={{
            objectFit: 'cover',
            maxHeight: '100%',
            maxWidth: '100%',
          }}
          onLoad={() => setLoading(false)}
          src={unit.marketingFloorPlan}
          alt={unit.suite}
          loading="lazy"
        />
      </Box>
    </Paper>
  );
};

interface ChildProps {
  unit: any;
  handleOpen: any;
  index: number;
  type: string;
  handleFavourites?: any;
}

export default FloorplanCard;
