import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { gql, useQuery, useLazyQuery } from '@apollo/client';
import { Popper, Box, Paper } from '@mui/material';

import { selectUser } from '../features/auth/authSlice';
import { IUnit, IUnitData, IPriceList } from '../types/unit';
import { selectProject } from '../features/project/projectSlice';
import { IProjectAccess } from '../types/user';
import { numToCurrency, numberWithCommas } from '../utils/Functions';
import { GlobalModal } from '../features/modal/Modal';
import CreateUnit from '../components/priceGrid/popups/CreateUnit';
import EditSelected from '../components/priceGrid/popups/EditSelected';
import EditUnitDisplay from '../components/priceGrid/popups/EditUnitDisplay';
import Draft from '../components/priceGrid/popups/Draft';
import PublishPriceList from '../components/priceGrid/popups/PublishPriceList';
import SaveStack from '../components/priceGrid/popups/SaveStack';
import FloorplanModal from '../components/common/FloorplanModal';

const PriceGridContext = React.createContext<any>(null);

const PriceGridProvider = (props: any) => {
  const user = useSelector(selectUser);
  const project = useSelector(selectProject);
  const [editOpen, setEditOpen] = useState(false);
  const [unit, setUnit] = useState<IUnit | null>(null);
  const [units, setUnits] = useState<IUnit[]>([]);
  const [selectedUnits, setSelectedUnits] = useState<IUnit[]>([]);
  const [selectingUnits, setSelectingUnits] = useState<boolean>(false);
  const [filteredUnits, setFilteredUnits] = useState<IUnit[]>([]);
  const [noUnits, setNoUnits] = useState<boolean>(false);
  const [draft, setDraft] = useState<boolean>(false);
  const [displayedInfo, setDisplayedInfo] = useState({
    unitNumber: true,
    unitType: true,
    modelType: true,
    modelName: false,
    basePrice: true,
    allocation: true,
    pricePerSquareFoot: true,
    size: true,
    bathrooms: false,
    outdoorSize: false,
    outdoorType: false,
    level: false,
    exposure: true,
    tier: false,
    rental: true,
  });
  const [priceLists, setPriceLists] = useState<IPriceList[]>([]);
  const [selectedPriceList, setSelectedPriceList] = useState<IPriceList | null>(null);
  const [priceListName, setPriceListName] = useState<string>('');
  const [oldStack, setOldStack] = useState<any>([]); // To Compare old stack to new stack info
  const [allCards, setAllCards] = useState<any>([]);
  const [stackRow, setStackRow] = useState<number>(0);
  const [stack, setStack] = useState([]);
  const [editedStack, setEditedStack] = useState<any>([]); // New Unit Info
  const [editedStackInfo, setEditedStackInfo] = useState<any>([]); // New Stack Info

  const [modalType, setModalType] = useState<string>('');
  const [open, setOpen] = React.useState(false);

  let selectedProject = user.projectAccess.find((projectAccess: IProjectAccess) => projectAccess.project._id === project._id);

  useEffect(() => {
    if (selectedUnits.length) {
      handlePopup();
    }
  }, [selectedUnits]);

  useEffect(() => {
    if ((user.type !== 'Sales' || selectedProject.access.includes('viewAllUnits')) && !draft) {
      getAllUnits();
    }
  }, [draft]);

  // Non Sales View
  const [getAllUnits, { loading }] = useLazyQuery<IUnitData>(UNITS, {
    fetchPolicy: 'cache-first',
    variables: { filter: { project: project._id } },
    onCompleted: (data) => {
      setDisplayedInfo({
        ...displayedInfo,
        rental: data.unitMany.some((unit: IUnit) => unit.rental),
        tier: data.unitMany.some((unit: IUnit) => unit.tier),
      });
      setUnits(data.unitMany);
    },
  });

  // Sales View
  useQuery<IUnitData>(UNITS, {
    skip: user.type !== 'Sales' || selectedProject.access.includes('viewAllUnits'),
    variables: {
      filter: {
        project: project._id,
        OR: [{ status: 'HL' }, { status: 'PL' }, { status: 'SHL' }, { status: 'HL2' }, { status: 'HL3' }],
      },
    },
    onCompleted: (data) => {
      let hotListData = [...data.unitMany];
      let sortedData = hotListData.sort((a, b) => b.modelType.localeCompare(a.modelType));
      if (window.innerWidth < 600) {
        hotListData = sortedData.map((unit: IUnit, index: number) => {
          return {
            ...unit,
            row: index + 1 > 2 ? Math.ceil((index + 1) / 3) : 1,
            col: index + 1 > 2 ? ((index + 1) % 3 === 0 ? 3 : (index + 1) % 3) : index + 1,
          };
        });
      } else {
        const rowCount: any[] = [...new Set(hotListData.map((item: IUnit) => item.row))].sort((a, b) => a - b);
        let startingRow = 0;
        let startingCol = 1;
        hotListData = hotListData
          .sort((a, b) => a.row - b.row || a.col - b.col)
          .map((unit: IUnit, index: number) => {
            let row = rowCount.findIndex((num: number) => num === unit.row);
            if (row !== startingRow) {
              startingCol = 1;
              startingRow++;
            } else {
              if (index) {
                startingCol++;
              }
            }
            return {
              ...unit,
              row: row + 1,
              col: startingCol,
            };
          });
      }

      setDisplayedInfo({
        ...displayedInfo,
        tier: hotListData.some((unit: IUnit) => unit.tier),
        modelName: hotListData.some((unit: IUnit) => unit.modelName),
      });
      setUnits(hotListData);
    },
  });

  const handlePopup = () => {
    if (selectedUnits.length) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };

  const dialogContent = () => {
    switch (modalType) {
      case 'create':
        return <CreateUnit />;
      case 'editUnits':
        return <EditSelected />;
      case 'editUnitDisplay':
        return <EditUnitDisplay />;
      case 'stack':
        return <SaveStack />;
      case 'floorPlan':
        return <FloorplanModal unitId={unit?._id} priceListDraft={draft} />;
      case 'priceList':
        return <Draft />;
      case 'publishPriceList':
        return <PublishPriceList />;
    }
  };

  return (
    <PriceGridContext.Provider
      value={{
        units,
        setUnits,
        selectingUnits,
        setSelectingUnits,
        selectedUnits,
        setSelectedUnits,
        displayedInfo,
        setDisplayedInfo,
        editOpen,
        setEditOpen,
        filteredUnits,
        setFilteredUnits,
        noUnits,
        setNoUnits,
        selectedProject,
        handlePopup,
        draft,
        setDraft,
        loading,
        setModalType,
        selectedPriceList,
        setSelectedPriceList,
        priceLists,
        setPriceLists,
        priceListName,
        setPriceListName,
        oldStack,
        setOldStack,
        allCards,
        setAllCards,
        stackRow,
        setStackRow,
        stack,
        setStack,
        editedStack,
        setEditedStack,
        editedStackInfo,
        setEditedStackInfo,
        unit,
        setUnit,
      }}
    >
      {props.children}
      <GlobalModal>{dialogContent()}</GlobalModal>
      <Popper
        disablePortal={true}
        anchorEl={null}
        id={open ? 'transition' : undefined}
        keepMounted={true}
        open={open && selectedUnits.length > 0}
        transition
        style={{ position: 'fixed', bottom: -30, right: 20, top: 'unset', left: 'unset', width: '250px', height: '200px' }}
        sx={{
          '@media (max-width: 600px)': {
            display: 'none',
          },
        }}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 50],
              enabled: true,
            },
          },
        ]}
      >
        <Paper elevation={12} sx={{ border: 1, p: 1 }}>
          <Box>Count: {selectedUnits.length}</Box>
          <Box>
            Average Size: {numberWithCommas(Math.ceil(selectedUnits.reduce((a: any, b: any) => a + b.size, 0) / selectedUnits.length))}
          </Box>
          <Box>Total Size: {numberWithCommas(selectedUnits.reduce((a: any, b: any) => a + b.size, 0))}</Box>
          <Box>
            Average Revenue: {numToCurrency.format(selectedUnits.reduce((a: any, b: any) => a + b.basePrice, 0) / selectedUnits.length)}
          </Box>
          <Box>Total Revenue: {numToCurrency.format(selectedUnits.reduce((a: any, b: any) => a + b.basePrice, 0))}</Box>
          <Box>
            PPSF:{' '}
            {numToCurrency.format(
              selectedUnits.reduce((a: any, b: any) => a + b.basePrice, 0) / selectedUnits.reduce((a: any, b: any) => a + b.size, 0)
            )}
          </Box>
        </Paper>
      </Popper>
    </PriceGridContext.Provider>
  );
};

const UNITS = gql`
  query unitMany($filter: FilterFindManyUnitInput) {
    unitMany(filter: $filter, limit: 10000) {
      _id
      suite
      modelType
      modelName
      unitType
      bathroom
      size
      unit
      outdoorType
      basePrice
      level
      exposure
      outdoorSize
      col
      row
      stackCol
      stackRow
      status
      allocation {
        fullName
        _id
      }
      getUrl
      allocatedDate
      tempAllocation
      custom
      flag
      tier
      rental
      type
      projectName
    }
  }
`;

export { PriceGridContext, PriceGridProvider };
