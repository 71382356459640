import { useState, useContext, useEffect } from 'react';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { Box, Button } from '@mui/material';

import TextInput from '../common/formControls/TextInput';
import { MergeContext } from '../../context/MergeContext';
import { IMergeArray } from '../../types/merge';
import { CreateDealContext } from '../../context/CreateDealContext';
import { getMergeValues, signField } from '../../utils/Functions';
import { useSelector } from 'react-redux';
import { selectProject } from '../../features/project/projectSlice';
import { selectMerges } from '../../features/merge/mergeSlice';
import { IAutoDocuments } from '../../types/CreateDealForm';
import { IMerge } from '../../types/merge';

const CustomMerge = () => {
  const merges = useSelector(selectMerges);
  const [mergeText, setMergeText] = useState<string>('');
  const [mergeSize, setMergeSize] = useState<number>(9);
  const [customMerges, setCustomMerges] = useState<any>([]);
  const [mergeCoordinates, setMergeCoordinates] = useState<any>([]);
  const project = useSelector(selectProject);
  const { ref, pageNum, documentLoaded, setDocumentLoaded } = useContext(MergeContext);
  const {
    selectedUnit,
    purchaserInfo,
    suiteInfo,
    realtorInfo,
    selectedMergeTemplates,
    setOpenCustom,
    openCustom,
    activeStep,
    setApsPdf,
    setTotalPdf,
    setDisplayPdf,
    pdfBlob,
    autoDocuments,
  } = useContext(CreateDealContext);

  useEffect(() => {
    if (documentLoaded) {
      const timer = setTimeout(() => {
        fillText();
      }, 1000);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentLoaded]);

  const saveCustom = () => {
    setCustomMerges([
      ...customMerges,
      {
        name: mergeText,
        fontSize: mergeSize,
        index: 0,
        type: 'merge',
        format: '',
      },
    ]);
    setMergeText('');
    setMergeSize(9);
  };

  const getCursorPosition = (
    canvas: any,
    event: any,
    mergeTitle: string,
    mergeType: string,
    mergeIndex: number,
    mergeSize: number = 12,
    format: string
  ) => {
    if (!canvas) {
      return;
    }
    var context = canvas.current?.getContext('2d');
    const canvasHeight = context.canvas.height / context.canvas.clientHeight;
    const canvasWidth = context.canvas.width / context.canvas.clientWidth;
    const rect = canvas.current?.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top - 1;
    let xValue = x * canvasWidth;
    let yValue = y * canvasHeight;
    let yMerge = context.canvas.clientHeight - y;
    let mergeValue;
    context.textBaseline = 'middle';
    context.font = `${mergeSize.toString()}px serif`;
    context.fillStyle = 'red';

    context.fillText(mergeTitle, xValue, Math.abs(yValue));
    setMergeCoordinates([
      ...mergeCoordinates,
      {
        pageNumber: pageNum - 1,
        key: mergeTitle,
        format: '',
        fontSize: mergeSize,
        index: mergeIndex,
        x: x,
        y: y - 20,
      },
    ]);
  };

  const fillText = () => {
    mergeCoordinates.forEach(async (merge: IMergeArray, index: number) => {
      if (merge.pageNumber === pageNum) {
        if (ref) {
          let context = ref.current.getContext('2d');
          const canvasHeight = context.canvas.height / context.canvas.clientHeight;
          const canvasWidth = context.canvas.width / context.canvas.clientWidth;
          let mergeValue;
          context.textBaseline = 'middle';
          context.font = `${merge.fontSize}px serif`;
          context.fillStyle = 'red';
          if (merge.index === 0) {
            mergeValue = merge.key;
          } else {
            mergeValue = `${merge.key}(${merge.index})`;
          }
          let newMergeY = merge.y * canvasHeight + 20;
          let newMergeX = merge.x * canvasWidth;
          if (context.canvas.height === context.canvas.clientHeight && context.canvas.width === context.canvas.clientWidth) {
            newMergeY = merge.y + 20;
            newMergeX = merge.x;
          }
          context.fillText(mergeValue, newMergeX, newMergeY);
          setDocumentLoaded(true);
        }
      }
    });
  };

  const modifyPdf = async () => {
    let uint8Array = new Uint8Array(await pdfBlob.arrayBuffer());
    const apsDoc = await PDFDocument.load(uint8Array);
    let helveticaFont = await apsDoc.embedFont(StandardFonts.Helvetica);

    let pages = apsDoc.getPages();

    for (const mergeField of mergeCoordinates) {
      let mergePages = pages[mergeField.pageNumber];
      let mergeValue = getMergeValues(
        mergeField,
        project,
        selectedUnit,
        null,
        purchaserInfo,
        suiteInfo.deposit,
        suiteInfo.options,
        realtorInfo,
        null,
        null,
        null,
        selectedMergeTemplates
      );
      mergePages.drawText(mergeValue!, {
        x: mergeField.x,
        y: mergePages.getHeight() - mergeField.y - 23,
        size: mergeField.fontSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      });
    }

    let pdfMergeDoc = await apsDoc.save();
    await setApsPdf(pdfMergeDoc);

    if (autoDocuments.length > 0) {
      let documents = await Promise.all(
        autoDocuments.map(async (document: IAutoDocuments) => {
          let autoMergeTemplate = merges.find((mergeTemplate: IMerge) => mergeTemplate.name === document.name);

          let autoDocument = await fetch(autoMergeTemplate?.getUrl!).then(async (res) => await res.arrayBuffer());
          const documentPdf = await PDFDocument.load(autoDocument);
          let helveticaFont = await documentPdf.embedFont(StandardFonts.Helvetica);

          let pages = documentPdf.getPages();
          await autoMergeTemplate?.mergeFields.forEach(async (mergeField: any) => {
            let mergePages = pages[mergeField.pageNumber];
            let mergeValue = getMergeValues(
              mergeField,
              project,
              selectedUnit,
              null,
              purchaserInfo,
              suiteInfo.deposit,
              suiteInfo.options,
              realtorInfo,
              null,
              null,
              null,
              null
            );

            if (
              mergeField.key === 'P1Check' ||
              (mergeField.key === 'P2Check' && purchaserInfo.length > 1) ||
              (mergeField.key === 'P3Check' && purchaserInfo.length > 2) ||
              (mergeField.key === 'P4Check' && purchaserInfo.length > 3)
            ) {
              mergePages.drawLine({
                start: { x: mergeField.x + 3, y: mergePages.getHeight() - mergeField.y - 3 },
                end: { x: mergeField.x + 8, y: mergePages.getHeight() - mergeField.y + 4 },
                thickness: 1,
                color: rgb(0, 0, 0),
                opacity: 0.75,
              });
              mergePages.drawLine({
                start: { x: mergeField.x, y: mergePages.getHeight() - mergeField.y + 1 },
                end: { x: mergeField.x + 3, y: mergePages.getHeight() - mergeField.y - 3 },
                thickness: 1,
                color: rgb(0, 0, 0),
                opacity: 0.75,
              });
            } else if (
              mergeField.key !== 'P1Check' &&
              mergeField.key !== 'P2Check' &&
              mergeField.key !== 'P3Check' &&
              mergeField.key !== 'P4Check'
            ) {
              mergePages.drawText(mergeValue, {
                x: mergeField.x,
                y: mergePages.getHeight() - mergeField.y - 3,
                size: mergeField.fontSize,
                font: helveticaFont,
                color: rgb(0, 0, 0),
              });
            }
          });

          let copiedPages = await apsDoc.copyPages(documentPdf, documentPdf.getPageIndices());
          copiedPages.forEach((page: any) => apsDoc.addPage(page));

          const documentDoc = await documentPdf.save();

          await autoMergeTemplate?.signFields
            .filter((signField: any) => {
              if (purchaserInfo.length === 1) {
                return signField.index < 1;
              } else return signField;
            })
            .forEach(async (mergeField: any) => {
              let pages = copiedPages[mergeField.pageNumber];
              let mergeValue = getMergeValues(
                mergeField,
                project,
                selectedUnit,
                null,
                purchaserInfo,
                suiteInfo.deposit,
                suiteInfo.options,
                realtorInfo,
                null,
                null,
                null,
                null
              );

              pages.drawText(`${signField(mergeField.index)}${mergeValue}`, {
                x: mergeField.x,
                y: pages.getHeight() - mergeField.y - 3,
                size: 8,
                font: helveticaFont,
                color: rgb(1, 0, 0),
              });
            });

          return {
            file: documentDoc,
            name: document.name,
            mergeTemplate: document.mergeTemplate,
            signFields: autoMergeTemplate?.signFields!,
          };
        })
      );
    }
    pdfMergeDoc = await apsDoc.save();
    await setTotalPdf(pdfMergeDoc);

    await setDisplayPdf(pdfMergeDoc);

    setOpenCustom(false);
  };

  return (
    <div>
      <h3>Add Custom Text</h3>
      <Box sx={{ mb: 1 }}>
        <TextInput title={'Merge Text'} name={'text'} handleTextInput={(e: any) => setMergeText(e.target.value)} value={mergeText} />
      </Box>
      <Box sx={{ mb: 1 }}>
        <TextInput
          title={'Font Size'}
          type="number"
          name={'text'}
          handleTextInput={(e: any) => setMergeSize(e.target.value)}
          value={mergeSize}
        />
      </Box>
      <div>
        <Button onClick={() => saveCustom()} variant="contained" color="primary">
          Save Custom Merge
        </Button>
      </div>
      <Box sx={{ mt: 1 }}>
        {customMerges.map((mergeItem: any, index: number) => {
          return (
            <Box sx={{ mb: 1 }}>
              <span
                key={index}
                draggable={true}
                onDragEnd={(e) => getCursorPosition(ref, e, mergeItem.name, mergeItem.type, mergeItem.index, mergeItem.fontSize, '')}
                style={{
                  fontSize: '10px',
                }}
              >
                {mergeItem.name}
              </span>
            </Box>
          );
        })}
      </Box>
      {activeStep === 4 ? (
        <div>
          <Box sx={{ mb: 1 }}>
            <Button onClick={() => modifyPdf()} variant="contained" color="primary">
              Generate PDF
            </Button>
          </Box>
          <div>
            <Button onClick={() => setOpenCustom(!openCustom)} variant="contained" color="primary">
              Go Back
            </Button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default CustomMerge;
