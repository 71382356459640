import { Extension } from "@tiptap/core";

export interface LineHeightOptions {
  types: string[];
  lineHeights: number[];
  defaultHeight: number;
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    lineHeight: {
      setLineHeight: (alignment: number) => ReturnType;
    };
  }
}

export const LineHeight = Extension.create<LineHeightOptions>({
  name: "lineHeight",

  addOptions() {
    return {
      types: ["heading", "paragraph"],
      lineHeights: [1.5, 2, 2.5, 3],
      defaultHeight: 1.5,
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          lineHeight: {
            default: this.options.defaultHeight,
            parseHTML: (element) => element.style.lineHeight || this.options.defaultHeight,
            renderHTML: (attributes) => {
              return { style: `line-height: ${attributes.lineHeight}` };
            },
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      setLineHeight:
        (height: number) =>
        ({ commands }: any) => {
          const distances = this.options.lineHeights.map((lineHeight) => Math.abs(lineHeight - height));
          let minDistanceIndex = 0;
          for (let i = 0; i < distances.length; ++i) {
            if (distances[i] < distances[minDistanceIndex]) {
              minDistanceIndex = i;
            }
          }
          const closestOption = this.options.lineHeights[minDistanceIndex];
          return this.options.types.every((type) => commands.updateAttributes(type, { lineHeight: closestOption }));
        },
    };
  },
});
