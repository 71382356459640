import React, { useEffect, useState } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { Box, List, ListItem, Drawer, Divider, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useParams, useSearchParams } from 'react-router-dom';

import { usePortalColorQuery } from '../../features/portal/portalHooks';
import FloorplanContainer from './FloorplanContainer';
import Deals from './Deals';
import { FlexBetween } from '../../commonStyles';
import { useProjectQuery } from '../../features/project/projectHooks';
import { useSelector } from 'react-redux';
import { selectProject } from '../../features/project/projectSlice';
import LoadingWrapper from '../common/LoadingWrapper';
import Decor from './Decor';

const InPerson = () => {
  const [open, setOpen] = useState(false);
  const [view, setView] = useState('Floor Plans');
  const [worksheet, setWorksheet] = useState(0);
  const [searchParams] = useSearchParams();
  let documentId = searchParams.get('document');
  let envelopeId = searchParams.get('envelope');
  const { projectid } = useParams();
  const project = useSelector(selectProject);
  useProjectQuery(projectid!, project._id);
  usePortalColorQuery(projectid!, project._id);

  const [redirectToDocusign, { loading: redirectLoading }] = useLazyQuery(DOCUSIGN, {
    onCompleted: (data) => {
      if (data) {
        window.location.href = data.redirectToDocusign;
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  useEffect(() => {
    if (documentId) {
      redirectToDocusign({ variables: { project: projectid, document: documentId, envelope: envelopeId } });
    }
  }, []);

  const menuItems = ['Floor Plans', 'Prepared Documents', 'Decor'];

  const handleMenuOnClick = (view: string) => {
    if (view === 'Floor Plans') {
      setWorksheet(worksheet + 1);
    }
    setView(view);
    setOpen(false);
  };

  return (
    <Box>
      {documentId && envelopeId ? (
        <LoadingWrapper modal={true} title={'Loading Document...'} />
      ) : (
        <>
          <FlexBetween sx={{ py: 1, px: 2 }}>
            <MenuIcon onClick={() => setOpen(!open)} sx={{ cursor: 'pointer', color: 'primary.main' }} />
            <Typography variant={'h5'} gutterBottom>
              <strong>{project.name}</strong>
            </Typography>
          </FlexBetween>
          <Drawer
            anchor="left"
            open={open}
            onClose={() => setOpen(false)}
            sx={{
              paper: {
                width: 450,
              },
            }}
          >
            <Box sx={{ backgroundColor: '#00142a', pt: 1.5, px: 1, py: 1, display: 'flex', justifyContent: 'center' }}>
              <img height={50} src="https://rdsimages.s3.ca-central-1.amazonaws.com/rds-logo-transparent.png" alt="Logo" />
            </Box>
            <Divider />
            <List>
              {menuItems.map((menuItem: string, index: number) => {
                return (
                  <ListItem
                    sx={{
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor: 'rgba(0, 32, 68)',
                        color: '#fff',
                      },
                    }}
                    key={index}
                    onClick={() => handleMenuOnClick(menuItem)}
                  >
                    {menuItem}
                  </ListItem>
                );
              })}
            </List>
          </Drawer>
          {view === 'Floor Plans' ? <FloorplanContainer worksheet={worksheet} /> : null}
          {view === 'Prepared Documents' ? <Deals /> : null}
          {view === 'Decor' ? <Decor /> : null}
        </>
      )}
    </Box>
  );
};

const DOCUSIGN = gql`
  query redirectToDocusign($project: MongoID!, $document: MongoID!, $envelope: MongoID!) {
    redirectToDocusign(project: $project, document: $document, envelope: $envelope)
  }
`;

export default InPerson;
