import React from 'react';
import { Box } from '@mui/material';
import Logo from '../../assets/logos/logo-blue-transparent.png';
import './LoadingLogo.css';


const LoadingLogo = () => {
  return (
    <Box className='spinHorizontal'>
      <img className='imageRotateHorizontal' src={Logo} alt="RDS" height="150" width="150" />
    </Box>
  )
}

export default LoadingLogo