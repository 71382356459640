import { useMemo, useContext } from 'react';
import Dropzone, { useDropzone } from 'react-dropzone';
import { Box, Grid, Button } from '@mui/material';
import { baseStyle, activeStyle, acceptStyle, rejectStyle } from '../../../../utils/Constants';
import { UnitContext } from '../../../../context/UnitContext';
import PdfCard from '../../../common/PdfCard';
import { accessAllowed } from '../../../../features/project/projectHooks';
import { useSelector } from 'react-redux';
import { selectProject } from '../../../../features/project/projectSlice';
import { selectUser } from '../../../../features/auth/authSlice';

const Miscellaneous = (props: ChildProps) => {
  const project = useSelector(selectProject);
  const user = useSelector(selectUser);
  const { miscellaneous, setMiscellaneous, createMiscellaneous, deleteSupport, handleDrop } = props;
  const { filteredDeal } = useContext(UnitContext);
  const { isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: 'image/jpeg, image/png, application/pdf',
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const handleDelete = (numIndex: number, id: string) => {
    if (filteredDeal.miscellaneous.length && filteredDeal.miscellaneous[numIndex]._id) {
      deleteSupport({
        variables: { _id: filteredDeal._id, documentId: filteredDeal.miscellaneous[numIndex]._id, deleteFile: true, type: 'miscellaneous' },
      });
    }
    let filterMiscellaneous = miscellaneous.filter((miscellaneous: IMiscellaneous, index: number) => {
      return index !== numIndex;
    });
    setMiscellaneous(filterMiscellaneous);
  };

  const saveMiscellaneous = async () => {
    if (miscellaneous.length > 0) {
      await miscellaneous.forEach((file: any, index: number) => {
        if (file.file) {
          createMiscellaneous({
            variables: { id: filteredDeal._id, name: `misc${index + 1}-${file.file.name}`, file: file.file, type: 'miscellaneous' },
          });
        }
      });
    }
  };

  return (
    <div>
      <h2>Miscellaneous</h2>
      <Box sx={{ mb: 3 }}>Upload any documents that do not need to be sent out for signing here (Ex. Third Party Verification)</Box>
      <Box sx={{ mb: 2 }}>
        <Dropzone onDrop={(files) => handleDrop(files, 'miscellaneous')} accept="image/jpg, image/jpeg, image/png, application/pdf">
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <p>Drag and Drop or Upload Miscellaneous Documents</p>
              </div>
            </section>
          )}
        </Dropzone>
      </Box>
      <div>
        <Grid container spacing={2}>
          {miscellaneous.length > 0
            ? miscellaneous.map((attachment: any, index: number) => {
                return (
                  <Grid key={index} item lg={3} md={4} sm={6} xs={12}>
                    <PdfCard
                      file={attachment.getUrl ? attachment.getUrl : attachment.url}
                      title={attachment.name ? attachment.name : attachment.file.name}
                      id={index}
                      handleDelete={handleDelete}
                      download={true}
                      index={index}
                    />
                  </Grid>
                );
              })
            : null}
        </Grid>
        {accessAllowed(user, project._id, 'editDeals') ? (
          <Button sx={{ mt: 1 }} onClick={() => saveMiscellaneous()} name={'update'} color="success" variant="contained">
            Save Miscellaneous Documents
          </Button>
        ) : null}
      </div>
    </div>
  );
};

interface IMiscellaneous {
  url: string;
  file: any;
  name: string;
  putUrl: string;
  getUrl: string;
  size?: number;
}

interface ChildProps {
  miscellaneous: any;
  setMiscellaneous: any;
  createMiscellaneous: any;
  deleteSupport: any;
  handleDrop: any;
}

export default Miscellaneous;
