import { useState, useReducer } from 'react';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { Box, Divider, Grid, Paper, FormControlLabel, Checkbox, Button } from '@mui/material';
import { mainCategories } from '../../../utils/Constants';
import { FlexBetween } from '../../../commonStyles';
import { useSelector } from 'react-redux';
import { selectProject, setProject } from '../../../features/project/projectSlice';
import Dropdown from '../../common/formControls/Dropdown';
import { useAppDispatch } from '../../../app/hooks';
import { showSuccessSnackbar } from '../../../features/snackbar/snackbarSlice';

export const modelsReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'ADDALL': {
      return action.payload;
    }
    case 'UPDATEMODEL': {
      return state.map((state: any, index: number) => {
        if (state.modelType === action.payload.modelName) {
          return {
            modelType: state.modelType,
            allowed: action.payload.allowed,
          };
        } else return state;
      });
    }
    case 'UPDATEALL': {
      return state.map((state: any, index: number) => {
        let selectedSuite = action.payload.value.find((deal: any) => deal.unit.suite === state.unit.suite);
        if (selectedSuite) {
          return selectedSuite;
        } else return state;
      });
    }
    case 'UPDATE':
      return state.map((state: any, index: number) => {
        if (index === action.payload.index) {
          return {
            ...state,
            [action.payload.field]: action.payload.value,
          };
        } else return state;
      });
    default:
      throw new Error();
  }
};

const Models = (props: ChildProps) => {
  const storeDispatch = useAppDispatch();
  const { modelTypes } = props;
  const project = useSelector(selectProject);
  const [modelSelections, setModelSelections] = useState<IModelSelection[]>(project.decorModels);
  const [modelsState, modelsDispatch] = useReducer(modelsReducer, modelSelections);
  const [pageNumber, setPageNumber] = useState<number>(0);

  const [viewFloorplan] = useLazyQuery(GETMARKETINGFLOORPLAN, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (data.marketingFloorPlansOne.getUrl) {
        const previewUrl = data.marketingFloorPlansOne.getUrl;

        const element = document.createElement('a');
        element.href = previewUrl;
        element.target = '_blank';
        element.click();
      }
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [saveModelDecor] = useMutation(SAVEMODELDECOR, {
    onCompleted: (data) => {
      storeDispatch(setProject(data.projectUpdateById.record));
      storeDispatch(showSuccessSnackbar('Decor Model Settings have been saved!'));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const getFloorPlan = (modelType: string) => {
    viewFloorplan({ variables: { filter: { project: project._id, modelType: modelType } } });
  };

  const handleChange = (mainCategory: string, modelType: string) => {
    let model = modelsState.find((models: IModelSelection) => models.modelType === modelType);

    if (model) {
      let selectedCategory = model.allowed.find((name: string) => name === mainCategory);

      if (selectedCategory) {
        let checkModel = model.allowed.filter((name: string) => name !== mainCategory);
        modelsDispatch({ type: 'UPDATEMODEL', payload: { modelName: modelType, allowed: checkModel } });
      } else {
        modelsDispatch({ type: 'UPDATEMODEL', payload: { modelName: modelType, allowed: [...model.allowed, mainCategory] } });
      }
    } else {
      if (mainCategory) {
        modelsDispatch({
          type: 'ADDALL',
          payload: [
            ...modelsState,
            {
              modelType: modelType,
              allowed: [mainCategory],
            },
          ],
        });
      }
    }
  };

  const getModelCheck = (modelType: string, mainCategory: string) => {
    let modelSelection = modelsState.find((model: IModelSelection) => model.modelType === modelType);

    if (modelSelection) {
      if (modelSelection.allowed.includes(mainCategory)) {
        return true;
      } else return false;
    } else return false;
  };

  const handleModelTypes = (e: any) => {
    let index = modelTypes.indexOf(e.target.value);
    setPageNumber(index);
  };

  const saveModelSettings = () => {
    saveModelDecor({ variables: { _id: project._id, record: { decorModels: modelsState } } });
  };

  return (
    <Box>
      <FlexBetween sx={{ mb: 2 }}>
        <Box>
          <Dropdown
            id={'dropdown'}
            title={'Model Type'}
            menuList={modelTypes}
            name={'modelType'}
            handleSelect={(e: any) => handleModelTypes(e)}
            value={modelTypes[pageNumber]}
          />
        </Box>
        <Button color="success" variant="contained" onClick={() => saveModelSettings()}>
          Save Settings
        </Button>
      </FlexBetween>
      <Paper elevation={12} sx={{ p: 2, mb: 2 }}>
        <FlexBetween>
          <Box sx={{ alignSelf: 'center' }}>
            <strong>{modelTypes[pageNumber]}</strong>
            <Box sx={{ display: 'inline-block', ml: 1 }}>(Check off boxes that do not belong in the model type)</Box>
          </Box>
          <Box>
            <Button
              onClick={() => getFloorPlan(modelTypes[pageNumber])}
              color="primary"
              variant="contained"
              sx={{ mr: 1, fontSize: '0.7rem' }}
            >
              View Marketing Floor Plan
            </Button>
          </Box>
        </FlexBetween>
        <Divider sx={{ my: 1 }} />
        <Grid container spacing={2} sx={{ mb: 2 }}>
          {mainCategories.map((mainCategory: string, index: number) => {
            return (
              <Grid key={index} item xs={6} sm={3} md={2}>
                <FormControlLabel
                  style={{
                    marginTop: '10px',
                    marginBottom: '15px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  key={`${modelTypes[pageNumber]}${mainCategory}`}
                  control={
                    <Checkbox
                      checked={getModelCheck(modelTypes[pageNumber], mainCategory)}
                      onChange={(e: any) => handleChange(mainCategory, modelTypes[pageNumber])}
                    />
                  }
                  label={mainCategory}
                />
              </Grid>
            );
          })}
        </Grid>
      </Paper>
      <Box sx={{ textAlign: 'center', mt: 2 }}>
        <Button disabled={pageNumber === 0} onClick={() => setPageNumber(pageNumber - 1)}>
          {'<'}
        </Button>
        <span>{pageNumber + 1}</span>
        <Button disabled={pageNumber === modelTypes.length} onClick={() => setPageNumber(pageNumber + 1)}>
          {'>'}
        </Button>
      </Box>
    </Box>
  );
};

interface ChildProps {
  modelTypes: string[];
}

interface IModelSelection {
  modelType: string;
  allowed: string[];
}

const GETMARKETINGFLOORPLAN = gql`
  query marketingFloorPlansOne($filter: FilterFindOnemarketingFloorPlansInput) {
    marketingFloorPlansOne(filter: $filter) {
      _id
      getUrl
      project {
        _id
      }
      modelType
    }
  }
`;

const SAVEMODELDECOR = gql`
  mutation projectUpdateById($_id: MongoID!, $record: UpdateByIdProjectInput!) {
    projectUpdateById(_id: $_id, record: $record) {
      record {
        _id
        name
        developerName
        email
        trackingPhone
        addresses {
          streetAddress
          city
          province
          postalCode
        }
        options {
          name
          totalAvailable
          price
          allowedUnits
          type
        }
        depositStructures {
          name
          deposits {
            name
            type
            amount
            daysDue
            dateType
            dueDate
          }
          default
        }
        mergeTemplates {
          name
          mergeTemplate {
            _id
          }
          apsTemplates {
            name
            apsTemplate {
              _id
              name
            }
            pageNumber
            attachToAps
          }
        }
        status {
          name
          code
          color
        }
        executors {
          name
          email
        }
        mortgageLimit
        mortgageMinDay
        hideOccupancy
        defaultZeroValue
        lawyer
        tagLine
        salesOffice
        adjustments {
          name
          type
        }
        commissionIncludeOptions
        emailTemplates {
          _id
          name
          subject
          html
        }
        logoGetUrl
        logoPutUrl
        imageGetUrl
        imagePutUrl
        coopStructures {
          name
          coopRates {
            type
            days
            date
            description
            percentage
            fixedAmount
          }
        }
        firstTentativeOccupancy
        finalTentativeOccupancy
        firmOccupancy
        outsideOccupancy
        active
        decorModels {
          modelType
          allowed
        }
        portal {
          primaryColor
        }
        combinedProjects {
          _id
          name
        }
      }
    }
  }
`;

export default Models;
