import { useContext, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Box, Typography, Tabs, Tab } from '@mui/material';

import { IComment, ICommentArray } from '../../types/comment';
import CommentsTable from '../common/CommentsTable';
import { UnitContext } from '../../context/UnitContext';
import { useSelector } from 'react-redux';
import { selectProject } from '../../features/project/projectSlice';

const Comments = () => {
  const { filteredDeal, unit } = useContext(UnitContext);
  const project = useSelector(selectProject);
  const [comments, setComments] = useState<IComment[]>([]);
  const [value, setValue] = useState<number>(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useQuery<ICommentArray>(GETCOMMENTS, {
    variables: { filter: { unit: unit?._id, project: project._id } },
    onCompleted: (data) => {
      let sortedComments = [...data.commentMany].sort((a: any,b: any) => {
        if (b.pinned - a.pinned !== 0) {
          return b.pinned - a.pinned
        } else return new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
      })
      setComments(sortedComments);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <Box>
      <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
        {filteredDeal ? <Tab label="Deal" {...a11yProps(0)} /> : null}
        <Tab label="Unit" {...a11yProps(1)} />
      </Tabs>
      {filteredDeal && unit ? (
        <TabPanel value={value} index={0}>
          <CommentsTable
            title={'Deal Notes'}
            deal={filteredDeal}
            unit={unit}
            commentType={'deal'}
            comments={comments.filter((comment: IComment) => comment.deal && comment.deal._id === filteredDeal._id)}
            setComments={setComments}
          />
        </TabPanel>
      ) : null}
      <TabPanel value={value} index={filteredDeal ? 1 : 0}>
        <CommentsTable
          title={'Unit Notes'}
          unit={unit}
          commentType={'unit'}
          comments={comments.filter((comment: IComment) => !comment.deal)}
          setComments={setComments}
        />
      </TabPanel>
    </Box>
  );
};

const GETCOMMENTS = gql`
  query commentMany($filter: FilterFindManyCommentInput) {
    commentMany(filter: $filter) {
      _id
      user {
        _id
        fullName
      }
      deal {
        _id
      }
      comment
      type
      pinned
      createdAt
      updatedAt
    }
  }
`;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export default Comments;
