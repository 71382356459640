import { useState, useContext } from 'react';
import { Box, Modal, Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';

import { PriceGridContext } from '../../context/PriceGridContext';
import FloorplanModal from '../common/FloorplanModal';
import { selectProject } from '../../features/project/projectSlice';
import { IUnit } from '../../types/unit';
import { unitStatusTitles } from '../../utils/Constants';
import { IStatus } from '../../types/project';
import { numToCurrency } from '../../utils/Functions';
import { FlexBetween } from '../../commonStyles';
import './mediaPrint.css';
import { useAppDispatch } from '../../app/hooks';
import { handleModal } from '../../features/modal/modalSlice';

const UnitGridCard = (props: ChildProps) => {
  const storeDispatch = useAppDispatch();
  const project = useSelector(selectProject);
  const { unit, displayed, handleSelectedUnits } = props;
  const { selectingUnits, displayedInfo, selectedUnits, filter, setUnit, setModalType } = useContext(PriceGridContext);

  const handleOpenModal = (e: any, unit: IUnit, type: string) => {
    if (type === 'click') {
      if (!selectingUnits) {
        setUnit(unit)
        setModalType('floorPlan');
        storeDispatch(handleModal(true));
      } else {
        handleSelectedUnits(unit);
      }
    } else if (type === 'hover') {
      if (selectingUnits) {
        if (e.buttons === 1) {
          if (selectedUnits.includes(unit._id)) return;
          handleSelectedUnits(unit);
        }
      }
    }
  };

  const suiteStatus = () => {
    let unitStatusArray: IStatus[] = [...project.status, ...unitStatusTitles];
    let statusObject = unitStatusArray.find((status: IStatus) => unit.status === status.code);
    let bgColour = statusObject?.color;

    if (selectingUnits && !displayed) {
      bgColour = statusObject?.color + '30';
    }

    let statusStyle = {
      backgroundColor: bgColour,
      boxShadow: unit.flag ? '0px 0px 1px 1px red' : '',
    };
    return statusStyle;
  };

  const handleFontSize = (allocation: any) => {
    if (allocation) {
      if (allocation.fullName.length > 18) {
        return '10.5px';
      } else return '12px';
    } else return '12px';
  };

  const handleDisplay = () => {
    if (filter) {
      if (displayed) {
        return 'block';
      } else return 'none';
    } else {
      if (Object.keys(unit).length !== 0) {
        return 'block';
      } else return 'none';
    }
  };

  return (
    <div style={{ height: '100%' }}>
      <Box
        className="suiteCard"
        style={suiteStatus()}
        sx={{
          color: '#00142a',
          borderRadius: '8px',
          padding: '8px',
          transition: 'transform 0.2s ease-in-out',
          '&:hover': {
            transform: 'scale(1.1)',
          },
          cursor: 'pointer',
          boxShadow: '0px 0px 1px 1px grey',
          height: '100%',
          fontSize: '12px',
          display: handleDisplay(),
          userSelect: 'none',
        }}
        onClick={(e) => handleOpenModal(e, unit, 'click')}
        onMouseOver={(e) => handleOpenModal(e, unit, 'hover')}
      >
        {displayedInfo.unitNumber ? (
          <FlexBetween>
            <Tooltip title="Unit Number">
              <Box
                sx={{
                  opacity: selectingUnits && !displayed ? '40%' : '100%',
                }}
              >
                <strong>
                  {unit.suite} {unit.status ? `(${unit.status})` : null}
                </strong>
              </Box>
            </Tooltip>
            {unit.custom ? (
              <Box
                sx={{
                  fontSize: '10px',
                  fontWeight: 'bold',
                  opacity: selectingUnits && !displayed ? '40%' : '100%',
                }}
              >
                {unit.custom}
              </Box>
            ) : null}
          </FlexBetween>
        ) : null}
        {unit.tier ? (
          <Box
            sx={{
              fontSize: '10px',
              fontWeight: 'bold',
              opacity: selectingUnits && !displayed ? '40%' : '100%',
            }}
          >
            {unit.tier}
          </Box>
        ) : null}
        {unit.modelName ? (
          <Box
            sx={{
              fontSize: '10px',
              fontWeight: 'bold',
              opacity: selectingUnits && !displayed ? '40%' : '100%',
            }}
          >
            {unit.modelName}
          </Box>
        ) : null}
        {displayedInfo.unitType ? (
          <FlexBetween>
            <Tooltip title="Unit Type">
              <Box
                sx={{
                  opacity: selectingUnits && !displayed ? '40%' : '100%',
                }}
              >
                {unit.unitType}
              </Box>
            </Tooltip>
          </FlexBetween>
        ) : null}
        {displayedInfo.modelType ? (
          <Tooltip title="Model Type">
            <Box
              sx={{
                opacity: selectingUnits && !displayed ? '40%' : '100%',
              }}
            >
              {unit.modelType}
            </Box>
          </Tooltip>
        ) : null}
        {displayedInfo.bathrooms ? (
          <Tooltip title="Number of Bathrooms">
            <Box
              sx={{
                opacity: selectingUnits && !displayed ? '40%' : '100%',
              }}
            >
              {unit.bathroom}
            </Box>
          </Tooltip>
        ) : null}
        {displayedInfo.size ? (
          <Tooltip title="Unit Size (sqft)">
            <Box
              sx={{
                opacity: selectingUnits && !displayed ? '40%' : '100%',
              }}
            >
              {unit.size}
            </Box>
          </Tooltip>
        ) : null}
        {displayedInfo.outdoorSize ? (
          <Tooltip title="Outdoor Size (sqft)">
            <Box
              sx={{
                opacity: selectingUnits && !displayed ? '40%' : '100%',
              }}
            >
              {unit.outdoorSize}
            </Box>
          </Tooltip>
        ) : null}
        {displayedInfo.outdoorType ? (
          <Tooltip title="Outdoor Type">
            <Box
              sx={{
                opacity: selectingUnits && !displayed ? '40%' : '100%',
              }}
            >
              {unit.outdoorType}
            </Box>
          </Tooltip>
        ) : null}
        {displayedInfo.exposure ? (
          <Tooltip title="Exposure">
            <Box
              sx={{
                opacity: selectingUnits && !displayed ? '40%' : '100%',
              }}
            >
              {unit.exposure}
            </Box>
          </Tooltip>
        ) : null}
        {displayedInfo.level ? (
          <Tooltip title="Level">
            <Box
              sx={{
                opacity: selectingUnits && !displayed ? '40%' : '100%',
              }}
            >
              {unit.level}
            </Box>
          </Tooltip>
        ) : null}
        {displayedInfo.pricePerSquareFoot ? (
          <Tooltip title="Price Per Square Foot">
            <Box
              sx={{
                opacity: selectingUnits && !displayed ? '40%' : '100%',
              }}
            >
              {unit.basePrice ? numToCurrency.format(unit.basePrice / unit.size) : null}
            </Box>
          </Tooltip>
        ) : null}
        {displayedInfo.basePrice ? (
          <Tooltip title="Base Price">
            <Box
              sx={{
                opacity: selectingUnits && !displayed ? '40%' : '100%',
              }}
            >
              <strong>{unit.basePrice ? numToCurrency.format(unit.basePrice) : null}</strong>
            </Box>
          </Tooltip>
        ) : null}
        {unit.rental && unit.rental !== null ? (
          <Box
            sx={{
              fontStyle: 'em',
              color: '#000',
              fontSize: '9x',
              opacity: selectingUnits && !displayed ? '40%' : '100%',
            }}
          >
            {numToCurrency.format(unit.rental!)}
          </Box>
        ) : null}
        {displayedInfo.allocation ? (
          <Tooltip title="Allocation">
            <Box
              sx={{
                opacity: selectingUnits && !displayed ? '40%' : '100%',
                fontSize: handleFontSize(unit.allocation),
              }}
            >
              <strong>{unit.allocation ? unit.allocation?.fullName : unit.tempAllocation}</strong>
            </Box>
          </Tooltip>
        ) : null}
      </Box>
    </div>
  );
};

interface ChildProps {
  unit: IUnit;
  type: String;
  displayed: boolean | undefined;
  selectingUnits: boolean;
  handleSelectedUnits: any;
  draft: boolean;
}

export default UnitGridCard;
