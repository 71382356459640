import { useQuery, gql } from '@apollo/client';

import { useAppDispatch } from '../../app/hooks';
import { showErrorSnackbar } from '../snackbar/snackbarSlice';
import { setPortal, setPortalColor } from './portalSlice';

export const portalReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'UPDATEVIDEOS':
      return state.map((state: any, index: number) => {
        if (index === action.payload.index) {
          return {
            ...state,
            [action.payload.field]: action.payload.value,
          };
        } else return state;
      });
    case 'UPDATETEXT':
      return state.map((state: any, index: number) => {
        if (index === action.payload.index) {
          return {
            ...state,
            [action.payload.field]: action.payload.value,
          };
        } else return state;
      });
    case 'DELETE':
      return state.filter((state: any, index: number) => index !== action.payload.index);
    default:
      throw new Error();
  }
};

export const usePortalColorQuery = (id: string, project: string) => {
  const dispatch = useAppDispatch();

  return useQuery(GETPORTALCOLOR, {
    skip: id === project,
    fetchPolicy: 'cache-first',
    variables: { filter: { project: id } },
    onCompleted: (data) => {
      dispatch(setPortal(data.portalOne));
      dispatch(setPortalColor(data.portalOne.primaryColor));
    },
    onError: (err) => {
      dispatch(showErrorSnackbar(err.message));
    },
  });
};

const GETPORTALCOLOR = gql`
  query portalOne($filter: FilterFindOnePortalInput) {
    portalOne(filter: $filter) {
      _id
      project {
        _id
      }
      active
      vipActive
      showHotlist
      sortHotlist
      showCustom
      createWorksheets
      showOptionsPrice
      showUnitTypes
      showOptions
      password
      information
      promo
      renderings {
        _id
        getUrl
        name
      }
      photos {
        _id
        getUrl
        name
      }
      pdfs {
        _id
        getUrl
        name
      }
      logos {
        _id
        name
        getUrl
      }
      aerialPhotos {
        _id
        name
        getUrl
      }
      bRoll {
        _id
        name
        getUrl
      }
      officialSite
      instaUrl
      primaryColor
      secondaryColor
      brochure
      brochureGetUrl
      brochurePutUrl
      subText
      videos {
        name
        getUrl
      }
    }
  }
`;
