import { useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import { Box, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TextInput from '../common/formControls/TextInput';

const UnitStatus = (props: ChildProps) => {
  const { title, disabled, code, color, numberIndex, handleDelete, handleStatusInput, statusDispatch } = props;
  const [showColor, setShowColor] = useState(false);
  const [colorful, setColorful] = useState('');

  const handleColorPicker = () => {
    setShowColor(!showColor);
  };

  const handleColorPickerBlur = (e: any) => {
    setShowColor(!showColor);
  };

  const handleHexColor = (e: any) => {
    statusDispatch({ type: 'UPDATE', payload: { index: numberIndex, field: 'color', value: e } });
    setColorful(e)
  }
  
  return (
    <>
      <Grid sx={{ mt: 2 }} item xs={12} sm={4}>
        <TextInput
          title={'Status Title'}
          name={`name`}
          value={title}
          disabled={disabled}
          handleTextInput={(e: HTMLInputElement) => handleStatusInput(e, numberIndex)}
        />
      </Grid>
      <Grid sx={{ mt: 2 }} item xs={12} sm={4}>
        <TextInput
          title={'Status Code'}
          name={'code'}
          value={code}
          disabled={disabled}
          handleTextInput={(e: HTMLInputElement) => handleStatusInput(e, numberIndex)}
        />
      </Grid>
      <Grid
        sx={{
          position: 'relative',
          marginTop: '20px',
        }}
        item
        xs={12}
        sm={!disabled ? 3 : 4}
      >
        {showColor ? (
          <Box
            sx={{
              position: 'absolute',
              zIndex: 100,
              top: '80px',
            }}
          >
            <HexColorPicker color={colorful} onChange={(e) => handleHexColor(e)} />
          </Box>
        ) : null}
        <TextInput
          background={color}
          title={'Color'}
          handleTextInput={(e: HTMLInputElement) => handleStatusInput(e, numberIndex)}
          name={'color'}
          value={color}
          disabled={disabled}
          focus={true}
          blur={true}
          handleFocus={handleColorPicker}
          handleBlur={(e: any) => handleColorPickerBlur(e)}
        />
      </Grid>
      {!disabled ? (
        <Grid
          sx={{
            display: 'flex',
            alignSelf: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            mt: 2,
          }}
          item
          xs={12}
          sm={1}
        >
          <CloseIcon color="secondary" onClick={() => handleDelete(numberIndex)} />
        </Grid>
      ) : null}
    </>
  );
};

interface ChildProps {
  type: string;
  key: number;
  numberIndex: number;
  value: string;
  title: string;
  code: string;
  color: string;
  disabled: boolean;
  handleStatusInput?: any;
  handleDelete?: any;
  handleColorInput?: any;
  statusDispatch?: any;
}

export default UnitStatus;
