import { useContext } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import CustomStepper from '../common/CustomStepper';
import PersonIcon from '@mui/icons-material/Person';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import InfoIcon from '@mui/icons-material/Info';
import RateReviewIcon from '@mui/icons-material/RateReview';
import ReceiptIcon from '@mui/icons-material/Receipt';

import PurchaserStep from '../createDeal/createDealSteps/PurchaserStep';
import RealtorStep from './createDealSteps/realtor/RealtorStep';
import SuiteStep from '../createDeal/createDealSteps/SuiteStep';
import MergeStep from './createDealSteps/merge/MergeStep';
import ReviewStep from './createDealSteps/ReviewStep';
import { createDealSteps } from '../../utils/Constants';
import { CreateDealContext } from '../../context/CreateDealContext';
import FormSummary from './FormSummary';
import LoadingWrapper from '../common/LoadingWrapper';
import { useAppDispatch } from '../../app/hooks';
import { selectProject } from '../../features/project/projectSlice';
import { selectUser } from '../../features/auth/authSlice';
import { useCreateActivity } from '../../features/activity/activityHooks';
import { showErrorSnackbar, showSuccessSnackbar } from '../../features/snackbar/snackbarSlice';
import { subYears } from 'date-fns';

const CreateDealForm = () => {
  const createActivity = useCreateActivity();
  const navigate = useNavigate();
  const storeDispatch = useAppDispatch();
  const project = useSelector(selectProject);
  const user = useSelector(selectUser);
  const { activeStep, setActiveStep, selectedUnit, setPurchaserInfo } = useContext(CreateDealContext);

  const stepperIcon = {
    1: <PersonIcon />,
    2: <HomeWorkIcon />,
    3: <InfoIcon />,
    4: <ReceiptIcon />,
    5: <RateReviewIcon />,
  };

  function getStepContent(stepIndex: number) {
    switch (stepIndex) {
      case 0:
        return <PurchaserStep />;
      case 1:
        return <RealtorStep />;
      case 2:
        return <SuiteStep />;
      case 3:
        return <MergeStep />;
      case 4:
        return <ReviewStep createDeal={createDeal} />;
      default:
        return;
    }
  }

  const handleStep = (index: number) => {
    if (index > activeStep) {
      return;
    }
    if (index !== 4) {
      setActiveStep(index);
    } else return;
  };

  const [createDeal, { loading }] = useMutation(CREATE_DEAL, {
    onCompleted: (data) => {
      setPurchaserInfo([
        {
          project: project._id,
          corp: false,
          firstName: '',
          lastName: '',
          identifications: [],
          dob: subYears(new Date(), 18),
          streetAddress: '',
          city: '',
          province: '',
          country: '',
          postalCode: '',
          idType: "Driver's Licence",
          proofType: '',
          proofNumber: '',
          proofExpiry: null,
          idNumber: '',
          idJurisdiction: 'Ontario',
          idExpiry: new Date(),
          sin: '',
          primaryPhone: '',
          email: '',
          occupation: '',
          employer: '',
          directors: '',
          businessNumber: '',
          signingOfficers: [],
          purchaserType: 'Investor',
        },
      ]);
      if (data.dealCreateOne.message) {
        storeDispatch(
          showErrorSnackbar(`${data.dealCreateOne.test ? 'Test' : 'Deal'} has been successfully sent, ${data.dealCreateOne.message}`)
        );
      } else {
        storeDispatch(showSuccessSnackbar(`${data.dealCreateOne.test ? 'Test' : 'Deal'} has been successfully sent`));
      }
      if (!data.dealCreateOne.test) {
        createActivity({
          project: project._id,
          user: user._id,
          deal: data.dealCreateOne._id,
          title: `Suite ${selectedUnit.suite} has been sent to purchaser(s)`,
          content: `Status has been changed from P to O.`,
        });
        navigate(`/${project._id}/dashboard/${selectedUnit._id}`);
      } else {
        setActiveStep(0);
      }
    },
    onError: (err) => {
      navigate(`/${project._id}/dashboard/price-grid`);
      storeDispatch(showErrorSnackbar(err.toString()));
      console.log(err);
    },
  });

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
      }}
    >
      {loading ? (
        <LoadingWrapper title="Deal is being sent. Please wait a moment" modal={true} />
      ) : (
        <>
          <FormSummary />
          <Box sx={{ width: '100%' }}>
            <CustomStepper iconList={stepperIcon} stepList={createDealSteps} active={activeStep} handleStep={handleStep} />
            <Box sx={{ p: 3 }}>{getStepContent(activeStep)}</Box>
          </Box>
        </>
      )}
    </Box>
  );
};

const CREATE_DEAL = gql`
  mutation dealCreateOne(
    $basePrice: Float!
    $comments: String!
    $deposit: [NewDepositInput]!
    $depositName: String
    $documents: [NewDocumentInput]
    $unit: MongoID!
    $user: MongoID!
    $salesRep: MongoID
    $options: [NewOptionsInput]!
    $project: MongoID!
    $purchasers: [MongoID]!
    $realtor: MongoID
    $tags: [String]!
    $cc: [String]!
    $executor: String
    $autoDocuments: [AutoDocumentInput]
    $inPerson: Boolean!
    $coopRates: [NewCoopRatesInput]
    $rental: Float
    $test: Boolean!
  ) {
    dealCreateOne(
      user: $user
      basePrice: $basePrice
      comments: $comments
      deposit: $deposit
      depositName: $depositName
      documents: $documents
      unit: $unit
      options: $options
      project: $project
      purchasers: $purchasers
      realtor: $realtor
      salesRep: $salesRep
      tags: $tags
      cc: $cc
      executor: $executor
      autoDocuments: $autoDocuments
      inPerson: $inPerson
      coopRates: $coopRates
      rental: $rental
      test: $test
    ) {
      deal {
        _id
        unit {
          rental
        }
      }
      message
      test
    }
  }
`;

export default CreateDealForm;
