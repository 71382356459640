import { useContext, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { Box, Grid, Button, Divider, Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

import Coop from '../../../createProject/Coop';
import RealtorForm from '../../../common/forms/RealtorForm';
import { useAppDispatch } from '../../../../app/hooks';
import { selectProject } from '../../../../features/project/projectSlice';
import { selectUser } from '../../../../features/auth/authSlice';
import { showSuccessSnackbar } from '../../../../features/snackbar/snackbarSlice';
import { accessAllowed } from '../../../../features/project/projectHooks';
import { UnitContext } from '../../../../context/UnitContext';
import { ICoopRate, ICoopStructure } from '../../../../types/project';
import { Flex, FlexBetween } from '../../../../commonStyles';
import { useSelector } from 'react-redux';
import { IDealRealtor } from '../../../../types/CreateDealForm';
import RealtorDialog from './RealtorDialog';

const RealtorInfo = () => {
  const storeDispatch = useAppDispatch();
  const { filteredDeal, setFilteredDeal, getRealtors } = useContext(UnitContext);
  const user = useSelector(selectUser);
  const project = useSelector(selectProject);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [dialogType, setDialogType] = useState<string>('');
  const [realtorIndex, setRealtorIndex] = useState<number>();

  // Queries and Mutations

  const [updateRealtor] = useMutation(UPDATEREALTOR, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Realtor Information have been updated!'));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  // Functions

  const handleRealtorSubmit = (e: any) => {
    e.preventDefault();
    const newRealtors = filteredDeal.realtor.map(({ fullName, ...realtor }: any) => realtor);
    updateRealtor({ variables: { project: project._id, dealRealtors: newRealtors } });
  };

  const handleRealtorInput = (event: any, numIndex: number) => {
    let selectedRealtor = filteredDeal.realtor.map((realtor: IDealRealtor, index: number) => {
      if (numIndex === index) {
        return {
          ...realtor,
          [event.target.name]: event.target.value,
        };
      } else return realtor;
    });

    if (filteredDeal) {
      setFilteredDeal({
        ...filteredDeal,
        realtor: selectedRealtor,
      });
    }
  };

  const addCoopRate = (numIndex: number) => {
    let selectedRealtor = filteredDeal.realtor.map((realtor: IDealRealtor, index: number) => {
      if (numIndex === index) {
        return {
          ...realtor,
          coopRates: [
            ...realtor.coopRates,
            {
              type: '',
              description: '',
              date: null,
              days: 0,
              percentage: 1,
              fixedAmount: 0,
            },
          ],
        };
      } else return realtor;
    });

    setFilteredDeal({
      ...filteredDeal,
      realtor: selectedRealtor,
    });
  };

  const handleCoopDate = (newValue: any, numberIndex: number, realtorIndex: number) => {
    let realtorCoops = filteredDeal.realtor.map((realtor: IDealRealtor, index: number) => {
      if (realtorIndex === index) {
        let selectedCoopRate = realtor.coopRates.map((coopRate: ICoopRate, index: number) => {
          if (index === numberIndex) {
            return {
              ...coopRate,
              date: newValue,
            };
          } else return coopRate;
        });
        return {
          ...realtor,
          coopRates: selectedCoopRate,
        };
      } else return realtor;
    });
    setFilteredDeal({
      ...filteredDeal,
      realtor: realtorCoops,
    });
  };

  const handleCoopInput = (event: any, numberIndex: number, type: string, realtorIndex: number) => {
    let realtorCoops = filteredDeal.realtor.map((realtor: IDealRealtor, index: number) => {
      if (realtorIndex === index) {
        let selectedCoopRate = realtor.coopRates.map((coopRate: ICoopRate, index: number) => {
          if (index === numberIndex) {
            return {
              ...coopRate,
              [event.target.name]:
                event.target.name === 'days' || event.target.name === 'percentage' || event.target.name === 'fixedAmount'
                  ? parseFloat(event.target.value)
                  : event.target.value,
            };
          } else return coopRate;
        });
        return {
          ...realtor,
          coopRates: selectedCoopRate,
        };
      } else return realtor;
    });
    setFilteredDeal({
      ...filteredDeal,
      realtor: realtorCoops,
    });
  };

  const handleDeleteCoop = (numIndex: number, realtorIndex: number) => {
    let selectedRealtor = filteredDeal.realtor.map((realtor: IDealRealtor, index: number) => {
      if (realtorIndex === index) {
        return {
          ...realtor,
          coopRates: realtor.coopRates.filter((coopRate: ICoopRate, index: number) => index !== numIndex),
        };
      } else return realtor;
    });

    setFilteredDeal({
      ...filteredDeal,
      realtor: selectedRealtor,
    });
  };

  const handleCoopStructure = (event: React.ChangeEvent<HTMLInputElement>, numIndex: number) => {
    let selectedCoopStructure = project.coopStructures.find((coopStructure: ICoopStructure) => coopStructure.name === event.target.value);
    if (selectedCoopStructure) {
      let selectedRealtor = filteredDeal.realtor.map((realtor: IDealRealtor, index: number) => {
        if (numIndex === index) {
          return {
            ...realtor,
            coopRates: selectedCoopStructure?.coopRates,
          };
        } else return realtor;
      });
      setFilteredDeal({
        ...filteredDeal,
        realtor: selectedRealtor,
      });
    }
  };

  return (
    <form onSubmit={handleRealtorSubmit}>
      <RealtorDialog
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        dialogType={dialogType}
        setDialogType={setDialogType}
        realtorIndex={realtorIndex!}
      />
      {filteredDeal.realtor.length ? (
        filteredDeal.realtor.map((realtor: IDealRealtor, realtorIndex: number) => {
          return (
            <Box key={realtorIndex}>
              <Typography sx={{ mb: 2 }} variant={'h2'}>
                <strong>Realtor {realtorIndex + 1}</strong>
              </Typography>
              <Box sx={{ mb: 2 }}>
                <strong>
                  <em>To replace a realtor, Please remove then add the new realtor.*</em>
                </strong>
              </Box>
              <RealtorForm realtorInfo={realtor} handleTextInput={handleRealtorInput} index={realtorIndex} />
              <FlexBetween>
                <h2>Co-op Commission</h2>
                {realtor.coopRates.length && realtor.coopRates.every((coopRate: ICoopRate) => coopRate.percentage) ? (
                  <Box sx={{ alignSelf: 'center' }}>
                    <strong>
                      Total Commission:{' '}
                      {realtor.coopRates.reduce((a: any, b: any) => {
                        return a + b.percentage;
                      }, 0)}
                      %
                    </strong>
                  </Box>
                ) : null}
              </FlexBetween>
              {realtor.coopRates.length > 0 ? (
                <Grid container spacing={2}>
                  {realtor.coopRates.map((coopRate: ICoopRate, index: number) => {
                    return (
                      <Coop
                        key={index}
                        index={index}
                        structureIndex={realtorIndex}
                        coopRate={coopRate}
                        handleCoopType={handleCoopInput}
                        handleCoopInput={handleCoopInput}
                        handleDeleteCoop={handleDeleteCoop}
                        handleCoopDate={handleCoopDate}
                      />
                    );
                  })}
                </Grid>
              ) : (
                <Box sx={{ mb: 3 }}>
                  <FormControl sx={{ width: '500px' }}>
                    <InputLabel id="id-display-label">There are currently no co-op rates for this deal</InputLabel>
                    <Select label="There are currently no co-op rates for this deal" name="coopStructure" onChange={(e: any) => handleCoopStructure(e, realtorIndex)}>
                      {project.coopStructures.map((coopStructure: ICoopStructure, index: number) => {
                        return (
                          <MenuItem key={index} value={coopStructure.name}>
                            {coopStructure.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
              )}
              {accessAllowed(user, project._id, 'editDeals') &&
              !filteredDeal.cancelled.dateCancelled &&
              !filteredDeal.rescission.dateRescinded ? (
                <Flex sx={{ mt: 2 }}>
                  <Button variant="contained" color="primary" onClick={() => addCoopRate(realtorIndex)}>
                    Add Co-op Rate
                  </Button>
                  <Button sx={{ ml: 1 }} type="submit" name={'update'} color="success" variant="contained">
                    Update Realtor Information
                  </Button>
                  <Button
                    sx={{ ml: 1 }}
                    onClick={() => {
                      setRealtorIndex(realtorIndex);
                      setDialogType('deleteRealtor');
                      setDialogOpen(true);
                    }}
                    name={'delete'}
                    color="error"
                    variant="contained"
                  >
                    Remove Realtor
                  </Button>
                </Flex>
              ) : null}
              <Divider sx={{ my: 2 }} />
            </Box>
          );
        })
      ) : (
        <Box sx={{ mb: 2 }}>
          <em>There is no realtor for this deal.</em>
        </Box>
      )}
      <Button
        onClick={() => {
          getRealtors();
          setDialogType('addRealtor');
          setDialogOpen(true);
        }}
        name={'update'}
        color="success"
        variant="contained"
      >
        Add Realtor
      </Button>
    </form>
  );
};

const UPDATEREALTOR = gql`
  mutation updateDealRealtor($project: MongoID!, $dealRealtors: [FilterFindManyDealRealtorInput!]) {
    updateDealRealtor(project: $project, dealRealtors: $dealRealtors) {
      _id
      email
      firstName
      lastName
      streetAddress
      brokerage
      brokerageFax
      brokeragePhone
      city
      province
      postalCode
      directPhone
      coopRates {
        type
        days
        date
        description
        percentage
        fixedAmount
      }
    }
  }
`;

export default RealtorInfo;
