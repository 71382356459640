import { Dispatch, SetStateAction } from 'react';
import { Box, Typography, Grid, Paper, Button } from '@mui/material';

import { numToCurrency } from '../../utils/Functions';
import { IUnitMarketing } from './FloorplanContainer';
import { IOption } from '../../types/project';
import { IDealOption } from '../../types/CreateDealForm';
import { Flex, FlexBetween } from '../../commonStyles';
import { IProject } from '../../types/project';

const Options = (props: ChildProps) => {
  const { unit, options, setOptions, project } = props;

  const displayOptions = (option: IOption) => {
    let selectedOption = options.find((dealOption: IDealOption) => dealOption.name === option.name);

    return (
      <Box>
        <Box sx={{ display: 'flex' }}>
          <FlexBetween sx={{ mt: 1, width: '50%' }}>
            <Box sx={{ fontSize: '40px', alignSelf: 'center', mr: 2 }}>{selectedOption ? selectedOption.purchaseAmount : '0'}</Box>
            <Flex sx={{ flexDirection: 'column' }}>
              <Button onClick={() => handleAddOptions(option)} sx={{ p: 0.5, minWidth: '60px' }} color="success" variant="contained">
                +
              </Button>
              <Button
                disabled={!selectedOption || !selectedOption.purchaseAmount}
                onClick={() => handleDeleteOptions(option)}
                sx={{ p: 0.5, minWidth: '60px', mt: 1 }}
                color="error"
                variant="contained"
              >
                -
              </Button>
            </Flex>
          </FlexBetween>
        </Box>
        {selectedOption ? (
          <Box sx={{ mt: 1 }}>
            <strong>{numToCurrency.format(selectedOption?.amount!)}</strong>
          </Box>
        ) : null}
      </Box>
    );
  };

  const handleAddOptions = (option: IOption) => {
    let selectedOption = options.find((dealOption: IDealOption) => dealOption.name === option.name);

    if (selectedOption) {
      let updatedOptions = options.map((allOption: IDealOption) => {
        if (selectedOption?.name === allOption.name) {
          return {
            ...allOption,
            purchaseAmount: allOption?.purchaseAmount! + 1,
            amount: option?.price! * (allOption?.purchaseAmount! + 1),
          };
        } else return allOption;
      });
      setOptions(updatedOptions);
    } else {
      setOptions([
        ...options,
        {
          name: option.name,
          purchaseAmount: 1,
          amount: option.price,
        },
      ]);
    }
  };

  const handleDeleteOptions = (option: IOption) => {
    let selectedOption = options.find((dealOption: IDealOption) => dealOption.name === option.name);

    if (selectedOption) {
      let updatedOptions = options
        .map((allOption: IDealOption) => {
          if (selectedOption?.name === allOption.name && allOption.purchaseAmount! > 1) {
            return {
              ...allOption,
              purchaseAmount: allOption?.purchaseAmount! - 1,
              amount: option?.price! * (allOption?.purchaseAmount! - 1),
            };
          } else if (selectedOption?.name === allOption.name && allOption.purchaseAmount === 1) {
            return {
              ...allOption,
              purchaseAmount: 0,
              amount: 0,
            };
          } else {
            return allOption;
          }
        })
        .filter((allOption: IDealOption) => allOption.amount !== 0);

      setOptions(updatedOptions);
    } else {
      setOptions([
        ...options,
        {
          name: option.name,
          purchaseAmount: 1,
          amount: option.price,
        },
      ]);
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant={'h5'} gutterBottom>
        <strong>Options</strong>
      </Typography>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        {project.options
          .filter((option: IOption) => option.type === unit.type && option.allowedUnits.includes(unit.unitType))
          .map((option: IOption, index: number) => {
            return (
              <Grid key={index} item sm={4} md={3}>
                <Paper elevation={8} sx={{ p: 1, border: '1px solid #000', height: '100%' }}>
                  <Box sx={{ textAlign: 'center' }}>
                    <strong>
                      {option.name} - {numToCurrency.format(option.price)}
                    </strong>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>{displayOptions(option)}</Box>
                </Paper>
              </Grid>
            );
          })}
      </Grid>
    </Box>
  );
};

interface ChildProps {
  unit: IUnitMarketing;
  options: IDealOption[];
  setOptions: Dispatch<SetStateAction<IDealOption[]>>;
  project: IProject;
}

export default Options;
