import { useMutation, gql } from '@apollo/client';

import { useAppDispatch } from '../../app/hooks';
import { showErrorSnackbar } from '../snackbar/snackbarSlice';

export const useCreateActivity = () => {
  const dispatch = useAppDispatch();

  const [createActivity] = useMutation(CREATEACTIVITY, {
    onCompleted: (data) => {
    },
    onError: (err) => {
      dispatch(showErrorSnackbar(err.message));
    },
  });

  return (activityObject: any) => createActivity({ variables: { record: activityObject } });
};

const CREATEACTIVITY = gql`
  mutation activityCreateOne($record: CreateOneActivityInput!) {
    activityCreateOne(record: $record) {
      record {
        _id
        project {
          _id
        }
        user {
          firstName
          lastName
        }
        deal {
          unit {
            suite
          }
          purchasers {
            firstName
            lastName
          }
        }
        title
        content
        timestamp
      }
    }
  }
`;