import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../app/store';
import { IMerge } from '../../types/merge';

const initialState: IMergeState = {
  merges: [],
  count: 0,
  merge: {
    _id: '0',
    name: '',
    mergeFields: [],
    signFields: [],
    type: '',
    totalPages: 0,
    default: false,
  },
  pdf: '',
};

export const mergeSlice = createSlice({
  name: 'merge',
  initialState,
  reducers: {
    setMerges: (state, action) => {
      state.merges = action.payload.items
      state.count = action.payload.count
    },
    setMerge: (state, action) => {
      state.merge = action.payload;
    },
    setPdf: (state, action) => {
      state.pdf = action.payload;
    },
  },
});

export const { setMerges, setMerge, setPdf } = mergeSlice.actions;

export const selectMerges = (state: RootState) => state.merge.merges;
export const selectMerge = (state: RootState) => state.merge.merge;
export const selectPdf = (state: RootState) => state.merge.pdf;
export const selectCount =(state: RootState) => state.merge.count;

interface IMergeState {
  merges: IMerge[];
  merge: IMerge;
  pdf: string;
  count: number
}

export default mergeSlice.reducer;
