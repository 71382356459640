import { useContext } from 'react';
import { gql, useMutation } from '@apollo/client';
import {
  Box,
  Button,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  InputAdornment,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { UnitContext } from '../../context/UnitContext';
import { IDealConstruction } from '../../types/CreateDealForm';
import { constructionTypes } from '../../utils/Constants';
import { camelToTitle } from '../../utils/Functions';
import { FlexEnd } from '../../commonStyles';
import { showSuccessSnackbar } from '../../features/snackbar/snackbarSlice';
import { useAppDispatch } from '../../app/hooks';
import { useSelector } from 'react-redux';
import { selectUser } from '../../features/auth/authSlice';
import { selectProject } from '../../features/project/projectSlice';
import { useCreateActivity } from '../../features/activity/activityHooks';

const Construction = () => {
  const storeDispatch = useAppDispatch();
  const createActivity = useCreateActivity();
  const { filteredDeal, setFilteredDeal } = useContext(UnitContext);
  const user = useSelector(selectUser);
  const project = useSelector(selectProject);

  const [updateConstruction] = useMutation(UPDATEADJUSTMENTS, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Construction have been saved'));
      createActivity({
        project: project._id,
        user: user._id,
        deal: filteredDeal._id,
        title: `Construction Updated`,
        content: `Construction has been updated`,
      });
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const handleConstruction = (event: any, numIndex: number) => {
    let value = event.target.value;
    if (event.target.name === 'value') {
      const regex = /^[0-9\b]+$/;
      if (event.target.value === '' || regex.test(event.target.value)) {
        value = parseInt(event.target.value, 10);
      } else return;
    }
    let newConstruction = filteredDeal.construction.map((construction: any, index: number) => {
      if (index === numIndex) {
        return {
          ...construction,
          [event.target.name]: value,
        };
      } else return construction;
    });

    setFilteredDeal({
      ...filteredDeal,
      construction: newConstruction,
    });
  };

  const addConstruction = () => {
    setFilteredDeal({
      ...filteredDeal,
      construction: [
        ...filteredDeal.construction,
        {
          name: '',
          type: '',
          value: '',
          description: '',
          approval: '',
          requiresChange: false,
          completed: false,
          tbd: false,
        },
      ],
    });
  };

  const removeConstruction = (numIndex: number) => {
    setFilteredDeal({
      ...filteredDeal,
      construction: filteredDeal.construction.filter((construction: IDealConstruction, index: number) => numIndex !== index),
    });
  };

  const saveConstruction = (e: any) => {
    e.preventDefault();
    updateConstruction({ variables: { _id: filteredDeal._id, record: { construction: filteredDeal.construction } } });
  };

  const handleConstructionCheckbox = (e: any, numIndex: number) => {
    let newConstruction = filteredDeal.construction.map((construction: any, index: number) => {
      if (numIndex === index) {
        if (e.target.name === 'tbd') {
          return {
            ...construction,
            [e.target.name]: !construction[e.target.name],
            value: 0,
          };
        } else {
          return {
            ...construction,
            [e.target.name]: !construction[e.target.name],
          };
        }
      } else return construction;
    });

    setFilteredDeal({
      ...filteredDeal,
      construction: newConstruction,
    });
  };

  return (
    <div>
      {filteredDeal ? (
        <>
          <h2>Construction</h2>
          <form onSubmit={saveConstruction}>
            {filteredDeal.construction.length ? (
              filteredDeal.construction.map((construction: IDealConstruction, index: number) => {
                return (
                  <Grid container spacing={3} sx={{ mt: 1 }}>
                    <Grid sx={{ alignSelf: 'center' }} item xs={12} sm={4} md={2}>
                      <TextField
                        title={'Name'}
                        label="Name"
                        name={'name'}
                        onChange={(e: any) => handleConstruction(e, index)}
                        value={construction.name}
                        placeholder={'Name'}
                        sx={{ width: '100%' }}
                        required
                      />
                    </Grid>
                    <Grid sx={{ alignSelf: 'center' }} item xs={12} sm={4} md={2}>
                      <TextField
                        title={'Value'}
                        name={'value'}
                        label="Value"
                        type="number"
                        onChange={(e: any) => handleConstruction(e, index)}
                        value={construction.value}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        required={true}
                        placeholder={'Amount'}
                        sx={{
                          width: '100%',
                          input: {
                            color: construction.type === 'Charge' ? 'green' : construction.type === 'Credit' ? 'red' : '#000',
                          },
                        }}
                      />
                    </Grid>
                    <Grid sx={{ alignSelf: 'center' }} item xs={12} sm={4} md={2}>
                      <FormControl fullWidth>
                        <InputLabel id="id-display-label">Construction Type</InputLabel>
                        <Select
                          required
                          value={construction.type}
                          label="Construction Type"
                          name="type"
                          onChange={(e: any) => handleConstruction(e, index)}
                        >
                          {constructionTypes.map((display: string) => {
                            return <MenuItem value={display}>{camelToTitle(display)}</MenuItem>;
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid sx={{ alignSelf: 'center' }} item xs={12} sm={4} md={3}>
                      <TextField
                        title={'Approval'}
                        label="Approval"
                        name={'approval'}
                        onChange={(e: any) => handleConstruction(e, index)}
                        value={construction.approval}
                        required={true}
                        placeholder={'Construction Approval'}
                        sx={{ width: '100%' }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2} sx={{ textAlign: 'left' }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="requiresChange"
                            color="primary"
                            checked={construction.requiresChange}
                            onClick={(e) => handleConstructionCheckbox(e, index)}
                          />
                        }
                        label={'Requires Revision'}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="completed"
                            color="primary"
                            checked={construction.completed}
                            onClick={(e) => handleConstructionCheckbox(e, index)}
                          />
                        }
                        label={'Completed'}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="tbd"
                            color="primary"
                            checked={construction.tbd}
                            onClick={(e) => handleConstructionCheckbox(e, index)}
                          />
                        }
                        label={'Price To Be Determined'}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} md={1} sx={{ textAlign: 'center', alignSelf: 'center' }}>
                      <Button onClick={() => removeConstruction(index)} variant="contained" color="error">
                        Remove
                      </Button>
                    </Grid>
                    <Grid sx={{ alignSelf: 'center' }} item xs={12}>
                      <TextField
                        title={'Description'}
                        multiline
                        rows={4}
                        fullWidth
                        label="Description of Structural Change"
                        name={'description'}
                        onChange={(e: any) => handleConstruction(e, index)}
                        value={construction.description}
                        placeholder={'Description of Structural Change'}
                        sx={{ width: '100%' }}
                        required
                      />
                    </Grid>
                  </Grid>
                );
              })
            ) : (
              <Box>There are currently no structural changes for this deal.</Box>
            )}
            <FlexEnd sx={{ mt: 2 }}>
              <Button onClick={() => addConstruction()} variant="contained" color="primary">
                Add Construction Change
              </Button>
              <Button sx={{ ml: 1 }} type="submit" variant="contained" color="success">
                Save Construction Changes
              </Button>
            </FlexEnd>
          </form>
        </>
      ) : (
        <Box sx={{ textAlign: 'center', mt: 2 }}>
          <em>There are currently no construction details at the moment.</em>
        </Box>
      )}
    </div>
  );
};

const UPDATEADJUSTMENTS = gql`
  mutation dealUpdateById($_id: MongoID!, $record: UpdateByIdDealInput!) {
    dealUpdateById(_id: $_id, record: $record) {
      record {
        realtor {
          _id
        }
      }
    }
  }
`;

export default Construction;
