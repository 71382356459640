import { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';

import { selectProject } from '../../features/project/projectSlice';
import { selectUser } from '../../features/auth/authSlice';
import DashboardComponent from './DashboardComponent';
import DashboardTable from './DashboardTable';
import ChartComponent from './ChartComponent';
import Outstandings from '../outstandings/Outstandings';
import Packages from '../createProject/packages/Packages';
import LoadingLogo from '../common/LoadingLogo';

const DashboardPage = () => {
  const project = useSelector(selectProject);
  const user = useSelector(selectUser);

  const [statusData, setStatusData] = useState(null);
  const [allFirmedRows, setAllFirmedRows] = useState<any>([]);
  const [allFirmingRows, setAllFirmingRows] = useState<any>([]);

  const { loading } = useQuery(GETDEALSTATS, {
    skip: project && !project._id,
    fetchPolicy: 'cache-first',
    variables: { project: project._id },
    onCompleted: (data) => {
      setStatusData(data.getStatusCount);
    },
  });

  return (
    <>
      {loading ? (
        <Box
          sx={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            '-webkit-transform': 'translate(-50%, -50%)',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <LoadingLogo />
        </Box>
      ) : (
        <>
          {user.type !== 'Sales' ? (
            <>
              <DashboardComponent statusData={statusData} />
              <Packages editable={false} />
              <DashboardTable
                allFirmedRows={allFirmedRows}
                allFirmingRows={allFirmingRows}
                setAllFirmedRows={setAllFirmedRows}
                setAllFirmingRows={setAllFirmingRows}
              />
              <ChartComponent project={project} statusData={statusData} />
            </>
          ) : (
            <>
              <Packages editable={false} />
              <DashboardTable
                allFirmedRows={allFirmedRows}
                allFirmingRows={allFirmingRows}
                setAllFirmedRows={setAllFirmedRows}
                setAllFirmingRows={setAllFirmingRows}
              />
              <Outstandings userType="sales" />
            </>
          )}
        </>
      )}
    </>
  );
};

const GETDEALSTATS = gql`
  query getStatusCount($project: MongoID!) {
    getStatusCount(project: $project) {
      total
      mortgageTotal
      checkCommercial
      allTotals {
        _id
        count
        size
        revenue
        revenueNet
      }
      commercialTotals {
        _id
        count
        size
        revenue
        revenueNet
      }
      statusTotals {
        _id
        count
        size
        revenue
        revenueNet
      }
      statusTiers {
        _id
        name
        count
        size
        revenue
        revenueNet
      }
      commercialTiers {
        _id
        name
        count
        size
        revenue
        revenueNet
      }
    }
  }
`;

export default DashboardPage;
