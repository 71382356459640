import { useState, useReducer, SetStateAction, Dispatch, useContext, useEffect } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import subYears from 'date-fns/subYears';
import { Box, Paper, Button } from '@mui/material';
import { IAssignment } from '../../../types/assignment';
import AssignmentForm from './AssignmentForm';
import AssignmentInformation from './AssignmentInformation';
import { IPurchaserInfo, IRealtorInfo } from '../../../types/CreateDealForm';
import { useSelector } from 'react-redux';
import { selectProject } from '../../../features/project/projectSlice';
import { purchasersReducer, informationReducer, realtorReducer } from './reducers';
import AssignmentRealtor from './AssignmentRealtor';
import { FlexEnd } from '../../../commonStyles';
import { IRealtorsArray } from '../../../types/CreateDealForm';
import { UnitContext } from '../../../context/UnitContext';
import { ICoopRate } from '../../../types/project';
import { useAppDispatch } from '../../../app/hooks';
import { showSuccessSnackbar, showErrorSnackbar } from '../../../features/snackbar/snackbarSlice';

const Current = (props: ChildProps) => {
  const storeDispatch = useAppDispatch();
  const { unit, filteredDeal } = useContext(UnitContext);
  const project = useSelector(selectProject);
  const { assignment, setAssignment, newAssignment, setNewAssignment } = props;
  const [realtors, setRealtors] = useState<IRealtorInfo[]>([]);
  const [purchasers, setPurchasers] = useState<IPurchaserInfo[]>([
    {
      project: project._id,
      firstName: '',
      lastName: '',
      corp: false,
      primaryPhone: '',
      email: '',
      dob: subYears(new Date(), 18),
      unit: '',
      streetAddress: '',
      city: '',
      province: '',
      country: '',
      postalCode: '',
      idType: "Driver's Licence",
      idNumber: '',
      idJurisdiction: 'Ontario',
      idExpiry: new Date(),
      sin: '',
      occupation: '',
      employer: '',
      purchaserType: '',
      directors: '',
      businessNumber: '',
      signingOfficers: [],
      identifications: [],
    },
  ]);
  const [purchasersState, purchasersDispatch] = useReducer(purchasersReducer, purchasers);
  const [owners, setOwners] = useState<IPurchaserInfo[]>([
    {
      project: project._id,
      firstName: '',
      lastName: '',
      corp: false,
      primaryPhone: '',
      email: '',
      dob: subYears(new Date(), 18),
      unit: '',
      streetAddress: '',
      city: '',
      province: '',
      country: '',
      postalCode: '',
      idType: "Driver's Licence",
      idNumber: '',
      idJurisdiction: 'Ontario',
      idExpiry: new Date(),
      sin: '',
      occupation: '',
      employer: '',
      purchaserType: '',
      directors: '',
      businessNumber: '',
      signingOfficers: [],
      identifications: [],
    },
  ]);
  const [ownersState, ownersDispatch] = useReducer(purchasersReducer, owners);
  const [information, setInformation] = useState({
    _id: '',
    signDate: null,
    price: '',
    current: false,
    miscellaneous: [],
  });
  const [informationState, informationDispatch] = useReducer(informationReducer, information);
  const [ownersRealtor, setOwnersRealtor] = useState({
    _id: '',
    email: '',
    firstName: '',
    lastName: '',
    brokerage: '',
    streetAddress: '',
    city: '',
    province: '',
    country: '',
    postalCode: '',
    brokeragePhone: '',
    brokerageFax: '',
    directPhone: '',
    coopRates: [],
  });
  const [ownersRealtorState, ownersRealtorDispatch] = useReducer(realtorReducer, ownersRealtor);
  const [purchasersRealtor, setPurchasersRealtor] = useState({
    _id: '',
    email: '',
    firstName: '',
    lastName: '',
    brokerage: '',
    streetAddress: '',
    city: '',
    province: '',
    country: '',
    postalCode: '',
    brokeragePhone: '',
    brokerageFax: '',
    directPhone: '',
    coopRates: [],
  });
  const [purchasersRealtorState, purchasersRealtorDispatch] = useReducer(realtorReducer, purchasersRealtor);

  useEffect(() => {
    if (assignment) {
      ownersRealtorDispatch({
        type: 'ADD',
        payload: assignment.ownersRealtor
          ? assignment.ownersRealtor
          : {
              _id: '',
              email: '',
              firstName: '',
              lastName: '',
              brokerage: '',
              streetAddress: '',
              city: '',
              province: '',
              country: '',
              postalCode: '',
              brokeragePhone: '',
              brokerageFax: '',
              directPhone: '',
              coopRates: [],
            },
      });
      purchasersRealtorDispatch({
        type: 'ADD',
        payload: assignment.purchasersRealtor
          ? assignment.purchasersRealtor
          : {
              _id: '',
              email: '',
              firstName: '',
              lastName: '',
              brokerage: '',
              streetAddress: '',
              city: '',
              province: '',
              country: '',
              postalCode: '',
              brokeragePhone: '',
              brokerageFax: '',
              directPhone: '',
              coopRates: [],
            },
      });
      ownersDispatch({
        type: 'SWAP',
        payload: assignment.owners,
      });
      purchasersDispatch({
        type: 'SWAP',
        payload: assignment.purchasers,
      });
      informationDispatch({
        type: 'SWAP',
        payload: {
          signDate: assignment.signDate,
          miscellaneous: assignment.miscellaneous,
          price: assignment.price,
          current: assignment.current,
          _id: assignment._id,
        },
      });
    } else {
      ownersDispatch({
        type: 'SWAP',
        payload: filteredDeal.purchasers,
      });
    }
  }, [assignment]);

  useQuery<IRealtorsArray>(REALTORS, {
    variables: { filter: { disable: false } },
    onCompleted: (data) => setRealtors(data.realtorMany),
  });

  const [createAssignment] = useMutation(CREATEASSIGNMENT, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Assignment Saved!'));
      setAssignment(data.assignmentCreateOne);
      setNewAssignment(false);
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar('Error Saving Assignment'));
      console.log(err, 'err');
    },
  });

  const handleAssignment = (e: any) => {
    e.preventDefault();

    let formattedOwners = ownersState.map((owner: IPurchaserInfo) => {
      const formattedIds = owner.identifications.map(({ getUrl, ...keepAttrs }) => keepAttrs);
      return {
        _id: owner._id,
        city: owner.city,
        corp: owner.corp,
        country: owner.country,
        directors: owner.directors,
        businessNumber: owner.businessNumber,
        dob: owner.dob,
        email: owner.email,
        employer: owner.employer,
        firstName: owner.firstName,
        identifications: formattedIds,
        idExpiry: owner.idExpiry,
        idJurisdiction: owner.idJurisdiction,
        idNumber: owner.idNumber,
        idType: owner.idType,
        lastName: owner.lastName,
        occupation: owner.occupation,
        postalCode: owner.postalCode,
        primaryPhone: owner.primaryPhone,
        province: owner.province,
        purchaserType: owner.purchaserType,
        sin: owner.sin,
        streetAddress: owner.streetAddress,
        unit: owner.unit,
      };
    });

    let ownersCoop = ownersRealtorState.coopRates.map((coopRate: ICoopRate) => {
      return {
        ...coopRate,
        days: coopRate.days ? coopRate.days : 0,
        fixedAmount: coopRate.fixedAmount ? coopRate.fixedAmount : 0,
      };
    });

    let formattedOwnersRealtor = {
      ...ownersRealtorState,
      coopRates: ownersCoop,
    };

    let purchasersCoop = purchasersRealtorState.coopRates.map((coopRate: ICoopRate) => {
      return {
        ...coopRate,
        days: coopRate.days ? coopRate.days : 0,
        fixedAmount: coopRate.fixedAmount ? coopRate.fixedAmount : 0,
      };
    });

    let formattedPurchasersRealtor = {
      ...purchasersRealtorState,
      coopRates: purchasersCoop,
    };

    let formattedPurchasers = purchasersState.map((owner: IPurchaserInfo) => {
      const formattedIds = owner.identifications.map(({ getUrl, ...keepAttrs }) => keepAttrs);
      return {
        _id: owner._id,
        city: owner.city,
        corp: owner.corp,
        country: owner.country,
        directors: owner.directors,
        businessNumber: owner.businessNumber,
        dob: owner.dob,
        email: owner.email,
        employer: owner.employer,
        firstName: owner.firstName,
        identifications: formattedIds,
        idExpiry: owner.idExpiry,
        idJurisdiction: owner.idJurisdiction,
        idNumber: owner.idNumber,
        idType: owner.idType,
        lastName: owner.lastName,
        occupation: owner.occupation,
        postalCode: owner.postalCode,
        primaryPhone: owner.primaryPhone,
        province: owner.province,
        purchaserType: owner.purchaserType,
        sin: owner.sin,
        streetAddress: owner.streetAddress,
        unit: owner.unit ? owner.unit : '',
      };
    });

    let miscellaneous = informationState.miscellaneous;
    let formattedInformation = {
      ...informationState,
      price: information.price ? information.price : 0,
    };

    delete formattedInformation.miscellaneous;

    createAssignment({
      variables: {
        owners: formattedOwners,
        ownersRealtor: formattedOwnersRealtor,
        purchasers: formattedPurchasers,
        purchasersRealtor: formattedPurchasersRealtor,
        information: formattedInformation,
        project: project._id,
        unit: unit._id,
        files: miscellaneous
      },
    });
  };

  const handleNewAssignment = () => {
    ownersRealtorDispatch({
      type: 'ADD',
      payload: {
        _id: '',
        email: '',
        firstName: '',
        lastName: '',
        brokerage: '',
        streetAddress: '',
        city: '',
        province: '',
        country: '',
        postalCode: '',
        brokeragePhone: '',
        brokerageFax: '',
        directPhone: '',
        coopRates: [],
      },
    });
    purchasersRealtorDispatch({
      type: 'ADD',
      payload: {
        _id: '',
        email: '',
        firstName: '',
        lastName: '',
        brokerage: '',
        streetAddress: '',
        city: '',
        province: '',
        country: '',
        postalCode: '',
        brokeragePhone: '',
        brokerageFax: '',
        directPhone: '',
        coopRates: [],
      },
    });
    ownersDispatch({
      type: 'SWAP',
      payload: [
        {
          project: project._id,
          firstName: '',
          lastName: '',
          corp: false,
          primaryPhone: '',
          email: '',
          dob: subYears(new Date(), 18),
          unit: '',
          streetAddress: '',
          city: '',
          province: '',
          country: '',
          postalCode: '',
          idType: "Driver's Licence",
          idNumber: '',
          idJurisdiction: 'Ontario',
          idExpiry: new Date(),
          sin: '',
          occupation: '',
          employer: '',
          purchaserType: '',
          directors: '',
          businessNumber: '',
          signingOfficers: [],
          identifications: [],
        },
      ],
    });
    purchasersDispatch({
      type: 'SWAP',
      payload: [
        {
          project: project._id,
          firstName: '',
          lastName: '',
          corp: false,
          primaryPhone: '',
          email: '',
          dob: subYears(new Date(), 18),
          unit: '',
          streetAddress: '',
          city: '',
          province: '',
          country: '',
          postalCode: '',
          idType: "Driver's Licence",
          idNumber: '',
          idJurisdiction: 'Ontario',
          idExpiry: new Date(),
          sin: '',
          occupation: '',
          employer: '',
          purchaserType: '',
          directors: '',
          businessNumber: '',
          signingOfficers: [],
          identifications: [],
        },
      ],
    });
    informationDispatch({
      type: 'SWAP',
      payload: {
        _id: '',
        signDate: null,
        price: '',
        current: false,
        miscellaneous: [],
      },
    });
  };

  return (
    <Box sx={{ mt: 2 }}>
      {newAssignment || assignment?._id ? (
        <Box>
          {!newAssignment ? (
            <Box sx={{ mb: 2 }}>
              <Button onClick={() => handleNewAssignment()} variant="contained" color="primary">
                Create Assignment
              </Button>
            </Box>
          ) : null}
          <form id="assignmentForm" onSubmit={handleAssignment}>
            <AssignmentInformation assignment={assignment} information={informationState} informationDispatch={informationDispatch} />
            <Paper elevation={12}>
              <AssignmentForm
                purchasers={ownersState}
                purchasersDispatch={ownersDispatch}
                title={'Assignor'}
                newAssignment={newAssignment}
              />
              <AssignmentRealtor
                title={"Assignor's Realtor"}
                realtor={ownersRealtorState}
                realtorDispatch={ownersRealtorDispatch}
                newAssignment={newAssignment}
                realtors={realtors}
              />
            </Paper>
            <Paper elevation={12} sx={{ mt: 2 }}>
              <AssignmentForm
                purchasers={purchasersState}
                purchasersDispatch={purchasersDispatch}
                title={'Assignee'}
                newAssignment={newAssignment}
              />
              <AssignmentRealtor
                title={"Assignee's Realtor"}
                realtor={purchasersRealtorState}
                realtorDispatch={purchasersRealtorDispatch}
                newAssignment={newAssignment}
                realtors={realtors}
              />
            </Paper>
            <FlexEnd>
              <Button form="assignmentForm" type="submit" sx={{ mt: 2 }} variant="contained" color="success">
                {assignment?._id ? 'Update' : 'Upload Assignment'}
              </Button>
            </FlexEnd>
          </form>
        </Box>
      ) : (
        <Box>
          There is currently no assignment for this unit. Please click{' '}
          <strong style={{ cursor: 'pointer' }} onClick={() => setNewAssignment(true)}>
            here
          </strong>{' '}
          to create one.
        </Box>
      )}
    </Box>
  );
};

interface ChildProps {
  assignment: IAssignment | null;
  setAssignment: any;
  setNewAssignment: Dispatch<SetStateAction<boolean>>;
  newAssignment: boolean;
}

const CREATEASSIGNMENT = gql`
  mutation assignmentCreateOne(
    $owners: [AssignmentPurchaser]
    $ownersRealtor: AssignmentR
    $purchasers: [AssignmentPurchaser]
    $purchasersRealtor: AssignmentR
    $information: AssignmentInformation
    $project: MongoID!
    $unit: MongoID!
    $files: [AssignmentIdentifications]
  ) {
    assignmentCreateOne(
      owners: $owners
      ownersRealtor: $ownersRealtor
      purchasers: $purchasers
      purchasersRealtor: $purchasersRealtor
      information: $information
      project: $project
      unit: $unit
      files: $files
    ) {
      _id
      project {
        _id
      }
      unit {
        _id
      }
      purchasers {
        _id
        email
        firstName
        lastName
        corp
        sin
        dob
        identifications {
          _id
          name
          getUrl
        }
        unit
        streetAddress
        city
        province
        country
        postalCode
        occupation
        employer
        directors
        businessNumber
        signingOfficers {
          fullName
          dob
          sin
          primaryPhone
          streetAddress
          email
        }
        purchaserType
        primaryPhone
        idType
        idNumber
        idExpiry
        idJurisdiction
        getUrl
        putUrl
      }
      purchasersRealtor {
        _id
        realtor {
          _id
        }
        email
        firstName
        lastName
        brokerage
        streetAddress
        city
        province
        country
        postalCode
        brokeragePhone
        brokerageFax
        directPhone
        coopRates {
          type
          days
          date
          description
          percentage
          fixedAmount
        }
      }
      owners {
        _id
        email
        firstName
        lastName
        corp
        sin
        dob
        identifications {
          _id
          name
          getUrl
        }
        unit
        streetAddress
        city
        province
        country
        postalCode
        occupation
        employer
        directors
        businessNumber
        signingOfficers {
          fullName
          dob
          sin
          primaryPhone
          streetAddress
          email
        }
        purchaserType
        primaryPhone
        idType
        idNumber
        idExpiry
        idJurisdiction
        getUrl
        putUrl
      }
      ownersRealtor {
        _id
        realtor {
          _id
        }
        email
        firstName
        lastName
        brokerage
        streetAddress
        city
        province
        country
        postalCode
        brokeragePhone
        brokerageFax
        directPhone
        coopRates {
          type
          days
          date
          description
          percentage
          fixedAmount
        }
      }
      price
      signDate
      miscellaneous {
        _id
        getUrl
        name
      }
      deposits {
        _id
      }
      current
    }
  }
`;

const REALTORS = gql`
  query realtorMany($filter: FilterFindManyRealtorInput) {
    realtorMany(filter: $filter, limit: 10000) {
      _id
      email
      firstName
      lastName
      brokerage
      streetAddress
      city
      province
      country
      postalCode
      brokeragePhone
      brokerageFax
      directPhone
    }
  }
`;

export default Current;
