import { useEffect, useMemo, useState } from 'react';
import { TextField, Autocomplete, Grid, Typography } from '@mui/material';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';

function extractFromAddress(components: any, type: string) {
  for (var i = 0; i < components.length; i++)
    for (var j = 0; j < components[i].types.length; j++) if (components[i].types[j] == type) return components[i].long_name;
  return '';
}

const GooglePlaces = (props: ChildProps) => {
  const { handleDetails, address, type, handleStreetAddress, id } = props;
  const [value, setValue] = useState<any>(address);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<PlaceType[]>([]);
  let map = new google.maps.Map(document.createElement('div'));
  let service = new google.maps.places.PlacesService(map);
  const autocompleteService = { current: null };

  const fetch = useMemo(
    () =>
      throttle((request: { input: string }, callback: (results?: PlaceType[]) => void) => {
        (autocompleteService.current as any).getPlacePredictions(request, callback);
      }, 200),
    []
  );

  useEffect(() => {
    if (id) {
      setValue(address);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (window as any).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results?: PlaceType[]) => {
      if (active) {
        let newOptions: any[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
      }
    });

    if (value && value.place_id) {
      service.getDetails(
        {
          placeId: value.place_id,
        },
        function (place, status) {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            if (place && place.address_components && place.address_components.length > 0) {
              let postalCode = extractFromAddress(place.address_components, 'postal_code');
              let city = extractFromAddress(place.address_components, 'locality');
              let province = extractFromAddress(place.address_components, 'administrative_area_level_1');
              let country = extractFromAddress(place.address_components, 'country');
              let streetAddress = `${place.address_components[0].long_name!} ${place.address_components[1].long_name!}`;

              if (place.address_components[0].types.includes('subpremise')) {
                streetAddress = `${place.address_components[0].long_name!}-${place.address_components[1].long_name!} ${place
                  .address_components[2].long_name!}`;
              }

              if (inputValue !== value.structured_formatting.main_text) {
                handleStreetAddress(inputValue, type);
              } else {
                handleDetails(city, province, postalCode, country, streetAddress, type);
              }
            }
          }
        }
      );
    }

    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      id={'streetAddress'}
      sx={{
        '.MuiOutlinedInput-root>fieldset': {
          borderColor: 'rgba(0, 0, 0, 0.23)',
        },
      }}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.structured_formatting.main_text)}
      freeSolo
      disabled={props.disabled}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value ? value : address}
      onChange={(event: any, newValue: PlaceType | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setInputValue(newValue?.structured_formatting.main_text!);
        setValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
        handleStreetAddress(newInputValue, type);
      }}
      renderInput={(params) => <TextField required {...params} label="Address" fullWidth />}
      renderOption={(props, option: any) => {
        if (typeof option !== 'object') return;
        let parts: any[] = [];
        const matches = option.structured_formatting.main_text_matched_substrings;
        if (matches) {
          parts = parse(
            option.structured_formatting.main_text,
            matches.map((match: any) => [match.offset, match.offset + match.length])
          );
        }

        if (parts.length) {
          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item xs sx={{ p: 1 }}>
                  {parts.map((part, index) => (
                    <span
                      key={index}
                      style={{
                        fontWeight: part.highlight ? 700 : 400,
                      }}
                    >
                      {part.text}
                    </span>
                  ))}
                  <Typography variant="body2" color="textSecondary">
                    {option.structured_formatting.secondary_text}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }
      }}
    />
  );
};

interface ChildProps {
  id?: string;
  name?: string;
  handleDetails: any;
  handleStreetAddress: any;
  address: string;
  type?: number;
  disabled?: boolean;
}

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}
interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings: MainTextMatchedSubstrings[];
}
interface PlaceType {
  description: string;
  structured_formatting: StructuredFormatting;
}

export default GooglePlaces;
