import { useState, useReducer } from 'react';
import {
  Box,
  Skeleton,
  Paper,
  Tabs,
  Tab,
  Typography,
  Button,
  TextField,
  FormControl,
  Autocomplete,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
} from '@mui/material';
import { gql, useQuery, useLazyQuery, useMutation } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import Lightbox from 'react-18-image-lightbox';
import { useSelector } from 'react-redux';
import { subYears } from 'date-fns';

import { selectProject } from '../../features/project/projectSlice';
import { numToCurrency, convertAllDates } from '../../utils/Functions';
import { IUnitDataGridCard } from '../../types/unit';
import LoadingLogo from '../common/LoadingLogo';
import { IUnitMarketing } from './FloorplanContainer';
import { Flex, FlexBetween } from '../../commonStyles';
import UnitInfo from './UnitInfo';
import Options from './Options';
import Identification from './Identification';
import PurchaserInformation from './PurchaserInformation';
import { IDealOption, IPurchaserInfo, IRealtorInfo } from '../../types/CreateDealForm';
import { purchaserReducer } from './worksheetReducer';
import { showErrorSnackbar, showSuccessSnackbar } from '../../features/snackbar/snackbarSlice';
import { useAppDispatch } from '../../app/hooks';

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 2 }}>
          <Typography component={'div'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Worksheet = (props: ChildProps) => {
  const { selectedUnit, setSelectedUnit } = props;
  const storeDispatch = useAppDispatch();
  const project = useSelector(selectProject);
  const [unit, setUnit] = useState<IUnitMarketing | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [options, setOptions] = useState<IDealOption[]>([]);
  const [imageLoading, setImageLoading] = useState<boolean>(true);
  const [purchaserView, setPurchaserView] = useState<number>(0);
  const [purchasers, setPurchasers] = useState<IPurchaserInfo[]>([
    {
      project: project._id,
      firstName: '',
      lastName: '',
      corp: false,
      primaryPhone: '',
      email: '',
      dob: subYears(new Date(), 18),
      streetAddress: '',
      city: '',
      province: '',
      country: '',
      postalCode: '',
      idType: "Driver's Licence",
      idNumber: '',
      idJurisdiction: 'Ontario',
      proofExpiry: null,
      proofNumber: '',
      proofType: '',
      idExpiry: new Date(),
      sin: '',
      occupation: '',
      employer: '',
      purchaserType: '',
      directors: '',
      businessNumber: '',
      signingOfficers: [],
      identifications: [],
    },
  ]);
  const [purchaserState, purchaserDispatch] = useReducer(purchaserReducer, purchasers);
  const [realtorValue, setRealtorValue] = useState<string>('yes');
  const [realtor, setRealtor] = useState<IRealtorInfo | null>(null);
  const [realtors, setRealtors] = useState<IRealtorInfo[]>([]);
  const [notes, setNotes] = useState<string>('');
  const [searchOpen, setSearchOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const { loading } = useQuery<IUnitDataGridCard>(GETUNIT, {
    variables: { _id: selectedUnit?._id },
    onCompleted: (data) => {
      setUnit({
        ...data.unitById,
        marketingFloorPlan: selectedUnit?.marketingFloorPlan!,
      });
    },
  });

  const [createWorksheet, { loading: createLoading }] = useMutation(CREATEWORKSHEET, {
    onCompleted: (data) => {
      setSelectedUnit(null);
      storeDispatch(showSuccessSnackbar('Worksheet has been created!'));
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const [getRealtors] = useLazyQuery(REALTORS, {
    onCompleted: (data) => {
      setRealtors(data.realtorMany);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const handleSearch = (e: any, value: string) => {
    setInputValue(value);
    if (value) {
      getRealtors({ variables: { project: project._id, search: value } });
    }
  };

  const handleSearchInput = (event: any, values: any) => {
    setInputValue('');
    setSearchOpen(false);

    setRealtor(values);
  };

  const handlePurchaserView = (event: React.SyntheticEvent, newValue: number) => {
    setPurchaserView(newValue);
  };

  const addPurchaser = () => {
    purchaserDispatch({ type: 'ADD', payload: project._id });
    setPurchaserView(purchaserState.length);
  };

  const removePurchaser = (index: number) => {
    purchaserDispatch({ type: 'DELETE', payload: { index: index } });
    setPurchaserView(0);
  };

  const swapElements = (array: IPurchaserInfo[], index1: number, index2: number) => {
    let state = [...array];
    let temp = state[index1];
    state[index1] = state[index2];
    state[index2] = temp;

    purchaserDispatch({ type: 'SWAP', payload: state });
  };

  const switchButtons = (numIndex: number) => {
    let buttons = purchaserState.map((purchaser: IPurchaserInfo, index: number) => {
      if (index !== numIndex) {
        return (
          <Button key={index} sx={{ mr: 1 }} onClick={() => swapElements(purchaserState, numIndex, index)} variant="contained">
            Switch with P{index + 1}
          </Button>
        );
      }
    });
    return buttons;
  };

  const submitForm = (e: any) => {
    e.preventDefault();
    for (const purchaser of purchaserState) {
      if (!purchaser.identifications.length) return storeDispatch(showErrorSnackbar('Missing Purchaser Identification'));
    }

    let purchasersFormat = purchaserState.map((purchaser: IPurchaserInfo) => {
      return {
        ...purchaser,
        files: purchaser.identifications.map((identification: any) => identification.file),
        proof: null,
        identifications: purchaser.identifications.map((identification: any) => {
          return {
            name: identification.name,
          };
        }),
      };
    });

    createWorksheet({
      variables: {
        isPublic: true,
        inPerson: true,
        wishlist: false,
        project: project._id,
        unit: unit?._id,
        purchasers: purchasersFormat,
        realtor: realtor ? realtor : null,
        user: null,
        options: options,
        joinWaitlist: false,
        notes: notes,
        wishlistChoices: [],
        worksheetBasePrice: unit?.basePrice,
        chequeImages: [],
        chequeFiles: [],
      },
    });
  };

  return loading || createLoading ? (
    <Box
      sx={{
        position: 'absolute',
        left: '50%',
        top: '50%',
        '-webkit-transform': 'translate(-50%, -50%)',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <LoadingLogo />
    </Box>
  ) : unit ? (
    <Box sx={{ p: 2 }}>
      <form onSubmit={submitForm}>
        <Box>
          <Paper elevation={24}>
            <Flex sx={{ border: '1px solid #000' }}>
              {imageLoading ? (
                <Box sx={{ width: '100%', height: '100%' }}>
                  <Skeleton variant="rectangular" animation="wave" height={400} />
                </Box>
              ) : null}
              <Box onClick={() => setOpen(true)} sx={{ cursor: 'pointer', height: '400px', width: '50%', textAlign: 'center' }}>
                <img
                  style={{
                    objectFit: 'cover',
                    maxHeight: '100%',
                    maxWidth: '100%',
                  }}
                  onLoad={() => setImageLoading(false)}
                  src={unit.marketingFloorPlan}
                  alt={unit.suite}
                  loading="lazy"
                />
              </Box>
              <Box sx={{ width: '50%', borderLeft: '1px solid #000' }}>
                <UnitInfo unit={unit} />
              </Box>
            </Flex>
            {open && (
              <Lightbox
                mainSrc={unit.marketingFloorPlan}
                onCloseRequest={() => setOpen(false)}
                imageCaption={`Suite ${unit.suite} - ${numToCurrency.format(unit.basePrice)} - ${convertAllDates(new Date(), 'PPpp')}`}
              />
            )}
          </Paper>
        </Box>
        {project.options ? (
          <Paper sx={{ mt: 2 }} elevation={24}>
            <Box sx={{ border: '1px solid #000' }}>
              <Options project={project} unit={unit} options={options} setOptions={setOptions} />
            </Box>
          </Paper>
        ) : null}
        <Paper sx={{ mt: 2 }} elevation={24}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', position: 'relative' }}>
            <Tabs indicatorColor={'secondary'} value={purchaserView} onChange={handlePurchaserView} aria-label="basic tabs example">
              {purchaserState.map((purchaser: IPurchaserInfo, index: number) => {
                return (
                  <Tab
                    key={index}
                    label={`${
                      purchaser.firstName && purchaser.lastName
                        ? `P${index + 1}: ${purchaser.firstName} ${purchaser.lastName.slice(0, 1).toUpperCase()}.`
                        : `Purchaser ${index + 1}`
                    }`}
                    {...a11yProps(0)}
                  />
                );
              })}
            </Tabs>
            <Box sx={{ position: 'absolute', top: 10, right: 10, cursor: 'pointer' }}>
              <AddIcon onClick={() => addPurchaser()} sx={{ color: '#fff' }} />
            </Box>
          </Box>
          {purchaserState.map((purchaser: IPurchaserInfo, index: number) => {
            return (
              <TabPanel key={index} value={purchaserView} index={index}>
                <Box sx={{ border: '1px solid #000' }}>
                  <Identification dispatch={purchaserDispatch} purchaser={purchaser} unit={unit} index={index} />
                </Box>
                <Box sx={{ border: '1px solid #000', mt: 2 }}>
                  <PurchaserInformation dispatch={purchaserDispatch} purchaser={purchaser} unit={unit} index={index} />
                </Box>
                {purchaserState.length > 1 ? (
                  <FlexBetween sx={{ mt: 2 }}>
                    <Box>{switchButtons(index)}</Box>
                    <Box>
                      <Button variant="contained" color="error" onClick={() => removePurchaser(index)}>
                        Delete
                      </Button>
                    </Box>
                  </FlexBetween>
                ) : null}
              </TabPanel>
            );
          })}
        </Paper>
        <Paper sx={{ mt: 2 }} elevation={24}>
          <Box sx={{ border: '1px solid #000' }}>
            <Box sx={{ p: 2 }}>
              <Typography component={'div'} variant={'h5'} gutterBottom>
                <strong>Realtor</strong>
              </Typography>
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">Do you have a realtor?</FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={realtorValue}
                  name="radio-buttons-group"
                  sx={{ display: 'flex', flexDirection: 'row' }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRealtorValue(e.target.value)}
                >
                  <FormControlLabel value={'yes'} control={<Radio />} label="Yes" />
                  <FormControlLabel value={'no'} control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
              {realtorValue === 'yes' ? (
                <FormControl
                  sx={{
                    width: '100%',
                    '& .MuiFormLabel-asterisk': {
                      color: 'red',
                    },
                  }}
                >
                  <Autocomplete
                    sx={{
                      width: '100%',
                      '& .MuiFormLabel-asterisk': {
                        color: 'red',
                      },
                    }}
                    open={searchOpen}
                    onOpen={() => {
                      if (inputValue) {
                        setSearchOpen(true);
                      }
                    }}
                    id={'search'}
                    disableClearable
                    freeSolo={false}
                    options={realtors}
                    onInputChange={(e, value, reason) => {
                      if (reason === 'reset') {
                        setInputValue('');
                      } else {
                        handleSearch(e, value);
                      }
                      if (!value) {
                        setSearchOpen(false);
                      }
                    }}
                    getOptionLabel={(option: any) => `${option.firstName} ${option.lastName} ${option.email}`}
                    onChange={handleSearchInput}
                    renderOption={(props, option: any) => {
                      return (
                        <li {...props}>
                          <div>
                            {`${option.firstName} ${option.lastName}`}
                            <Box
                              sx={{
                                fontSize: '10px',
                              }}
                            >
                              <div>{option.email}</div>
                            </Box>
                          </div>
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField required={false} {...params} size="small" label={'Search By Name or Email'} margin="normal" />
                    )}
                  />
                </FormControl>
              ) : null}
            </Box>
          </Box>
        </Paper>
        <Paper sx={{ mt: 2 }} elevation={24}>
          <Box sx={{ border: '1px solid #000' }}>
            <Box sx={{ p: 2 }}>
              <Typography component={'div'} variant={'h5'} gutterBottom>
                <strong>Notes</strong>
              </Typography>
              <TextField
                placeholder="Please let us know of any details you would like to add"
                multiline
                variant={'outlined'}
                rows={4}
                value={notes}
                onChange={(e: any) => setNotes(e.target.value)}
                inputProps={{ maxLength: 150 }}
                sx={{ width: '100%' }}
              />
            </Box>
          </Box>
        </Paper>
        <Button sx={{ mt: 2 }} color="success" type="submit" variant="contained">
          Submit
        </Button>
      </form>
    </Box>
  ) : null;
};

interface ChildProps {
  selectedUnit: IUnitMarketing | null;
  setSelectedUnit: any;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const GETUNIT = gql`
  query unitById($_id: MongoID!) {
    unitById(_id: $_id) {
      _id
      suite
      unit
      level
      modelType
      basePrice
      size
      status
      bathroom
      exposure
      outdoorSize
      outdoorType
      unitType
      basePrice
      type
    }
  }
`;

const REALTORS = gql`
  query realtorMany($filter: FilterFindManyRealtorInput) {
    realtorMany(filter: $filter, limit: 10000) {
      email
      firstName
      lastName
      brokerage
      streetAddress
      city
      province
      country
      postalCode
      brokeragePhone
      brokerageFax
      directPhone
    }
  }
`;

const CREATEWORKSHEET = gql`
  mutation worksheetCreateOne(
    $isPublic: Boolean!
    $inPerson: Boolean!
    $wishlist: Boolean!
    $project: MongoID!
    $unit: MongoID
    $purchasers: [NewPurchaserInput]!
    $realtor: NewRealtorInput
    $user: MongoID
    $options: [NewOptionInput]
    $joinWaitlist: Boolean!
    $notes: String
    $wishlistChoices: [NewWishlistInput]
    $worksheetBasePrice: Float
    $chequeImages: [NewIdentificationField]
    $chequeFiles: [Upload]
  ) {
    worksheetCreateOne(
      isPublic: $isPublic
      inPerson: $inPerson
      wishlist: $wishlist
      project: $project
      unit: $unit
      purchasers: $purchasers
      realtor: $realtor
      user: $user
      options: $options
      joinWaitlist: $joinWaitlist
      notes: $notes
      wishlistChoices: $wishlistChoices
      worksheetBasePrice: $worksheetBasePrice
      chequeImages: $chequeImages
      chequeFiles: $chequeFiles
    ) {
      _id
      unit {
        _id
      }
    }
  }
`;

export default Worksheet;
