import { useState, useContext, useReducer } from 'react';
import { useQuery, gql } from '@apollo/client';
import { Box, Button } from '@mui/material';
import { ToggleButton, ToggleButtonGroup } from '@mui/lab';
import Current from './Current';
import { useSelector } from 'react-redux';
import { UnitContext } from '../../../context/UnitContext';
import { selectProject } from '../../../features/project/projectSlice';
import { IAssignment } from '../../../types/assignment';
import { FlexBetween } from '../../../commonStyles';
import LoadingWrapper from '../../common/LoadingWrapper';

const Assignment = () => {
  const { unit } = useContext(UnitContext);
  const project = useSelector(selectProject);
  const [tabValue, setTabValue] = useState<string>('current');
  const [assignment, setAssignment] = useState<IAssignment | null>(null);
  const [assignments, setAssignments] = useState<IAssignment[]>([]);
  const [newAssignment, setNewAssignment] = useState<boolean>(false);

  const { loading } = useQuery(GETASSIGNMENTS, {
    variables: { filter: { project: project._id, unit: unit._id, current: true } },
    onCompleted: (data) => {
      let selectedAssignment = data.assignmentMany.find((assignment: IAssignment) => assignment.current);
      setAssignment(selectedAssignment ? selectedAssignment : null);
      setAssignments(data.assignmentMany);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const handleTabChange = (e: any, value: string) => {
    if (value !== null) {
      setTabValue(value);
    }
  };

  return (
    <div>
      <h2>Assignment</h2>
      {!loading ? (
        <>
          <FlexBetween>
            {newAssignment ? (
              <Button variant="contained" color="primary" onClick={() => setNewAssignment(false)}>
                Go Back
              </Button>
            ) : (
              <ToggleButtonGroup
                value={tabValue}
                exclusive
                onChange={handleTabChange}
                aria-label="text alignment"
                sx={{
                  '& .MuiToggleButton-root.Mui-selected': {
                    backgroundColor: '#00142a',
                    color: '#fff',
                  },
                  '& .MuiToggleButton-root.Mui-selected:hover': {
                    backgroundColor: '#00142a',
                    color: '#fff',
                  },
                }}
              >
                <ToggleButton value="current" aria-label="centered">
                  Current
                </ToggleButton>
                <ToggleButton value="previous" aria-label="right aligned">
                  Previous
                </ToggleButton>
              </ToggleButtonGroup>
            )}
          </FlexBetween>
          {tabValue === 'current' ? (
            <Box>
              <Current
                setNewAssignment={setNewAssignment}
                newAssignment={newAssignment}
                assignment={assignment}
                setAssignment={setAssignment}
              />
            </Box>
          ) : null}
          {tabValue === 'previous' ? (
            <Box sx={{ mt: 2 }}>
              <em>There are no previous assignments</em>
            </Box>
          ) : null}
        </>
      ) : (
        <LoadingWrapper title="Loading..." modal={false} />
      )}
    </div>
  );
};

const GETASSIGNMENTS = gql`
  query assignmentMany($filter: FilterFindManyAssignmentInput) {
    assignmentMany(filter: $filter) {
      _id
      project {
        _id
      }
      unit {
        _id
      }
      purchasers {
        _id
        project {
          _id
        }
        email
        firstName
        lastName
        corp
        sin
        dob
        identifications {
          _id
          name
          getUrl
        }
        unit
        streetAddress
        city
        province
        country
        postalCode
        occupation
        employer
        directors
        businessNumber
        signingOfficers {
          fullName
          dob
          sin
          primaryPhone
          streetAddress
          email
        }
        purchaserType
        primaryPhone
        idType
        idNumber
        idExpiry
        idJurisdiction
        getUrl
        putUrl
      }
      purchasersRealtor {
        _id
        email
        firstName
        lastName
        brokerage
        streetAddress
        city
        province
        country
        postalCode
        brokeragePhone
        brokerageFax
        directPhone
        coopRates {
          type
          days
          date
          description
          percentage
          fixedAmount
        }
      }
      owners {
        _id
        project {
          _id
        }
        email
        firstName
        lastName
        corp
        sin
        dob
        identifications {
          _id
          name
          getUrl
        }
        unit
        streetAddress
        city
        province
        country
        postalCode
        occupation
        employer
        directors
        businessNumber
        signingOfficers {
          fullName
          dob
          sin
          primaryPhone
          streetAddress
          email
        }
        purchaserType
        primaryPhone
        idType
        idNumber
        idExpiry
        idJurisdiction
        getUrl
        putUrl
      }
      ownersRealtor {
        _id
        email
        firstName
        lastName
        brokerage
        streetAddress
        city
        province
        country
        postalCode
        brokeragePhone
        brokerageFax
        directPhone
        coopRates {
          type
          days
          date
          description
          percentage
          fixedAmount
        }
      }
      price
      signDate
      miscellaneous {
        _id
        getUrl
        name
      }
      deposits {
        _id
      }
      current
    }
  }
`;

export default Assignment;
