import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "../../app/store";

interface authState {
  activities: any;
}

const initialState: authState = {
  activities: [],
};

export const activitySlice = createSlice({
  name: "activity",
  initialState,
  reducers: {
    setActivities: (state, action: PayloadAction<any>) => {
      state.activities = action.payload;
    },
  },
});

export const { setActivities } = activitySlice.actions;

export const selectUser = (state: RootState) => state.activity.activities;

export default activitySlice.reducer;
