import { Fragment, useState, useMemo, useReducer } from 'react';
import { Buffer } from 'buffer';
import Dropzone, { useDropzone } from 'react-dropzone';
import { gql, useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { Box, Typography, Autocomplete, TextField, Grid, FormControl, FormControlLabel, Switch, Button } from '@mui/material';
import { IUnit } from '../../types/unit';
import { subYears } from 'date-fns';

import { sortSuites, numToCurrency, formatSin, formatPhoneNumber } from '../../utils/Functions';
import { useSelector } from 'react-redux';
import { selectProject } from '../../features/project/projectSlice';
import { scanner417Pdf } from './scanner';
import { baseStyle, activeStyle, acceptStyle, rejectStyle } from '../../utils/Constants';
import { useAppDispatch } from '../../app/hooks';
import { showErrorSnackbar } from '../../features/snackbar/snackbarSlice';
import PdfCard from '../common/PdfCard';
import { purchasersReducer } from '../unitMain/assignment/reducers';
import { IIdentification, IPurchaserInfo, IRealtorInfo, IDealOption } from '../../types/CreateDealForm';
import PurchaserForm from '../common/forms/PurchaserForm';
import { ISigningOfficer, IRealtorsArray } from '../../types/CreateDealForm';
import { Container, FlexEnd } from '../../commonStyles';
import { IOption } from '../../types/project';
import Dropdown from '../common/formControls/Dropdown';
import { showSuccessSnackbar } from '../../features/snackbar/snackbarSlice';
import { useNavigate } from 'react-router-dom';
import LoadingLogo from '../common/LoadingLogo';

const CreateWorksheet = () => {
  const project = useSelector(selectProject);
  const storeDispatch = useAppDispatch();
  const navigate = useNavigate();
  const [units, setUnits] = useState<IUnit[]>([]);
  const [unit, setUnit] = useState<IUnit>();
  const [errors, setErrors] = useState<any>(null);
  const [realtors, setRealtors] = useState<IRealtorInfo[]>([]);
  const [realtor, setRealtor] = useState<IRealtorInfo>();
  const [options, setOptions] = useState<IDealOption[]>([]);
  const [notes, setNotes] = useState<string>('');
  const [imageLoading, setImageLoading] = useState<boolean>(false);
  const [purchasersState, purchasersDispatch] = useReducer(purchasersReducer, [
    {
      project: project._id,
      corp: false,
      firstName: '',
      lastName: '',
      identifications: [],
      dob: subYears(new Date(), 18),
      streetAddress: '',
      city: '',
      province: '',
      country: '',
      postalCode: '',
      idType: "Driver's Licence",
      idNumber: '',
      idJurisdiction: 'Ontario',
      idExpiry: new Date(),
      proofType: '',
      proofNumber: '',
      proofExpiry: null,
      sin: '',
      primaryPhone: '',
      email: '',
      occupation: '',
      employer: '',
      directors: '',
      signingOfficers: [],
      purchaserType: 'Investor',
      businessNumber: '',
    },
  ]);

  const { isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: 'image/gif, image/jpeg, image/png, application/pdf',
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const { loading } = useQuery(UNITS, {
    variables: { filter: { project: project._id, statuses: ['HL', 'HL2', 'HL3', 'SHL', 'PL'] } },
    onCompleted: (data) => {
      setUnits(sortSuites([...data.unitMany], 'suite'));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [getRealtors, { loading: realtorLoading }] = useLazyQuery<IRealtorsArray>(REALTORS, {
    onCompleted: (data) => setRealtors(data.realtorMany),
  });

  const [createWorksheet] = useMutation(CREATEWORKSHEET, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Worksheet has been created!'));
      navigate(`/${project._id}/dashboard/worksheets`);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleDrop = (acceptedFiles: any, index: number) => {
    setImageLoading(true);
    if (acceptedFiles.length === 0) {
      storeDispatch(showErrorSnackbar('This file type is not allowed'));
      return;
    }
    const file = acceptedFiles[0];

    const fileReader: any = new FileReader();
    if (file) {
      fileReader.readAsDataURL(file);
    }
    fileReader.onloadend = async () => {
      let buffer = Buffer.from(fileReader.result.split(',')[1], 'base64');
      let results = file.type === 'image/jpeg' || file.type === 'image/jpg' ? scanner417Pdf(buffer) : null;
      let values: any = {
        identifications: [
          ...purchasersState[index].identifications,
          {
            name: acceptedFiles[0].name,
            file: acceptedFiles[0],
            result: fileReader.result,
          },
        ],
      };
      if (results) {
        values = {
          ...values,
          firstName: results.firstName,
          lastName: results.lastName,
          idExpiry: results.expiryDate,
          dob: results.dob,
          streetAddress: results.streetAddress,
          city: results.city,
          province: results.province,
          postalCode: results.postalCode,
          country: results.country,
          idJurisdiction: 'Ontario',
          idNumber: results.idNumber,
        };
      }

      purchasersDispatch({
        type: 'UPDATEMULTIPLE',
        payload: {
          index: index,
          values: [values],
        },
      });
      setImageLoading(false);
    };
  };

  const deleteImages = async (purchaserIndex: number, title: string, idIndex: number) => {
    let deleteId = purchasersState[purchaserIndex].identifications.filter(
      (identifications: IIdentification, index: number) => idIndex !== index
    );

    purchasersDispatch({ type: 'UPDATE', payload: { index: purchaserIndex, field: 'identifications', value: deleteId } });
  };

  const handleTextInput = async (event: any, index: number) => {
    if (event && event.target.id === 'streetAddress') {
      purchasersDispatch({ type: 'UPDATE', payload: { index: index, field: event.target.id, value: event.target.value } });
    }

    if (event && event.target.name) {
      let value = event.target.value;
      if (event.target.name === 'primaryPhone') {
        value = formatPhoneNumber(event.target.value);
      }
      if (event.target.name === 'sin') {
        value = formatSin(event.target.value);
      }
      purchasersDispatch({ type: 'UPDATE', payload: { index: index, field: event.target.name, value: value } });
    }
  };

  const handleSigningOfficerInput = async (event: any, signingOfficerIndex: number, purchaserIndex: number) => {
    if (event && event.target.name) {
      let signingOfficer = purchasersState[purchaserIndex].signingOfficers.map((signingOfficer: ISigningOfficer, index: number) => {
        if (index === signingOfficerIndex) {
          return {
            ...signingOfficer,
            [event.target.name]: event.target.value,
          };
        } else return signingOfficer;
      });

      purchasersDispatch({ type: 'UPDATE', payload: { index: purchaserIndex, field: 'signingOfficers', value: signingOfficer } });
    }
  };

  const handleDeleteSigningOfficer = (signingOfficerIndex: number, purchaserIndex: number) => {
    if (purchasersState[purchaserIndex].signingOfficers.length === 1) {
      storeDispatch(showErrorSnackbar('Must have at least 1 signing officer'));
      return;
    }
    let deleteSigningOfficer = purchasersState[purchaserIndex].signingOfficers.filter(
      (signingOfficer: ISigningOfficer, index: number) => signingOfficerIndex !== index
    );

    purchasersDispatch({ type: 'UPDATE', payload: { index: purchaserIndex, field: 'signingOfficers', value: deleteSigningOfficer } });
  };

  const addSigningOfficer = async (numIndex: number) => {
    let signingOfficers = [
      ...purchasersState[numIndex].signingOfficers,
      {
        fullName: '',
        dob: subYears(new Date(), 18),
        streetAddress: '',
        primaryPhone: '',
        email: '',
        sin: '',
      },
    ];

    purchasersDispatch({ type: 'UPDATE', payload: { index: numIndex, field: 'signingOfficers', value: signingOfficers } });
  };

  const handleDob = (newValue: any, signingOfficerIndex: number, purchaserIndex: number) => {
    if (newValue == 'Invalid Date') {
      setErrors('Invalid Date');
      return;
    } else {
      setErrors(null);
    }

    let signingOfficer = purchasersState[purchaserIndex].signingOfficers.map((signingOfficer: ISigningOfficer, index: number) => {
      if (index === signingOfficerIndex) {
        return {
          ...signingOfficer,
          dob: newValue,
        };
      } else return signingOfficer;
    });

    purchasersDispatch({ type: 'UPDATE', payload: { index: purchaserIndex, field: 'signingOfficers', value: signingOfficer } });
  };

  const handleDetails = async (
    city: string,
    province: string,
    postalCode: string,
    country: string,
    streetAddress: string,
    index: number
  ) => {
    purchasersDispatch({
      type: 'UPDATEMULTIPLE',
      payload: {
        index: index,
        values: [
          {
            city: city,
            province: province,
            postalCode: postalCode,
            country: country,
            streetAddress: streetAddress,
          },
        ],
      },
    });
  };

  const handleStreetAddress = async (value: string, index: number) => {
    purchasersDispatch({ type: 'UPDATE', payload: { index: index, field: 'streetAddress', value: value } });
  };

  const handleEndvestorInput = (event: any, numIndex: number) => {
    purchasersDispatch({ type: 'UPDATE', payload: { index: numIndex, field: 'purchaserType', value: event.target.innerText } });
  };

  const handleIdTypeInput = (event: any, idType: string, numIndex: number) => {
    purchasersDispatch({ type: 'UPDATE', payload: { index: numIndex, field: idType, value: event.target.innerText } });
  };

  const handleIdIssuedByInput = (event: any, numIndex: number) => {
    purchasersDispatch({ type: 'UPDATE', payload: { index: numIndex, field: 'idJurisdiction', value: event.target.innerText } });
  };

  const handleDateChange = (newValue: any, id: string, numIndex: number) => {
    if (newValue == 'Invalid Date') {
      setErrors('Invalid Date');
      return;
    } else {
      setErrors(null);
    }
    purchasersDispatch({ type: 'UPDATE', payload: { index: numIndex, field: id, value: newValue } });
  };

  const handleCorpChange = (e: any, numIndex: number) => {
    purchasersDispatch({
      type: 'UPDATEMULTIPLE',
      payload: {
        index: numIndex,
        values: [
          {
            corp: !purchasersState[numIndex].corp,
            signingOfficers: purchasersState[numIndex].corp
              ? []
              : [
                  {
                    fullName: '',
                    dob: subYears(new Date(), 18),
                    streetAddress: '',
                    email: '',
                    primaryPhone: '',
                    sin: '',
                  },
                ],
          },
        ],
      },
    });
  };

  const handleRealtor = (event: any, value: any) => {
    setRealtor(value);
  };

  const handleSearchRealtor = (event: any, value: any) => {
    getRealtors({ variables: { filter: { disable: false, search: value } } });
  };

  const handleOptionDropdown = (event: any, numIndex: number) => {
    let filteredOption = project.options.filter((option: IOption, index: number) => option.name === event.target.name);
    if (options.some((option: IDealOption) => option.name === event.target.name)) {
      if (event.target.value === '0') {
        let removeOption = options.filter((data: IDealOption, index: number) => data.name !== event.target.name);
        setOptions(removeOption);
      } else {
        let optionsArray = options.map((option: IDealOption, index: number) => {
          if (option.name === event.target.name) {
            return {
              ...option,
              purchaseAmount: parseFloat(event.target.value),
              amount: event.target.value * filteredOption[0].price,
            };
          } else return option;
        });
        setOptions(optionsArray);
      }
    } else {
      if (project.options) {
        setOptions([
          ...options,
          {
            name: event.target.name,
            purchaseAmount: parseFloat(event.target.value),
            amount: event.target.value * filteredOption[0].price,
          },
        ]);
      } else return;
    }
  };

  const optionValue = (name: string, type: string) => {
    let filteredOption: any = options.find((option: IDealOption, index: number) => option.name === name);
    if (filteredOption) {
      return filteredOption[type];
    } else {
      return 0;
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    let purchasersFormat = purchasersState.map((purchaser: IPurchaserInfo) => {
      return {
        ...purchaser,
        project: project._id,
        identifications: purchaser.identifications.map((identification: any) => {
          return {
            name: identification.file.name,
            file: identification.file,
          };
        }),
      };
    });

    createWorksheet({
      variables: {
        project: project._id,
        unit: unit?._id,
        purchasers: purchasersFormat,
        realtor: realtor ? realtor._id : null,
        options: options,
        notes: notes,
      },
    });
  };

  return (
    <Box sx={{ p: 3 }}>
      <form onSubmit={handleSubmit}>
        <Typography variant="h2">
          <strong>Create a Worksheet {unit ? `- Suite ${unit.suite}` : null}</strong>
        </Typography>
        <Box sx={{ mt: 2 }}>
          <Autocomplete
            options={units}
            getOptionLabel={(option: any) => option.suite}
            disableClearable={false}
            freeSolo={false}
            loading={loading}
            value={unit || null}
            isOptionEqualToValue={(option, value) => option === value}
            onChange={(event: any, newValue: any | null) => setUnit(newValue)}
            renderInput={(params) => <TextField required {...params} label="Select Suite" size="small" />}
          />
        </Box>
        {unit ? (
          <Container sx={{ mt: 2 }}>
            <Typography variant="h2">
              <strong>Suite Details</strong>
            </Typography>
            <Box sx={{ mt: 1 }}>
              <Box>
                Price: <strong>{numToCurrency.format(unit.basePrice)}</strong>
              </Box>
              <Box>
                Level: <strong>{unit.level}</strong>
              </Box>
              <Box>
                Unit Number: <strong>{unit.unit}</strong>
              </Box>
              <Box>
                Unit Type: <strong>{unit.unitType}</strong>
              </Box>
              <Box>
                Model Type: <strong>{unit.modelType}</strong>
              </Box>
              <Box>
                Exposure: <strong>{unit.exposure}</strong>
              </Box>
              <Box>
                Bathrooms: <strong>{unit.bathroom}</strong>
              </Box>
              <Box>
                Size: <strong>{unit.size}</strong>
              </Box>
              <Box>
                Outdoor Size: <strong>{unit.outdoorSize ? unit.outdoorSize : '0'}</strong>
              </Box>
              {unit.rental ? (
                <Box>
                  Rental Guarantee: <strong>{numToCurrency.format(unit.rental)}</strong>
                </Box>
              ) : null}
              {unit.tier ? (
                <Box>
                  Tier: <strong>{unit.tier}</strong>
                </Box>
              ) : null}
            </Box>
          </Container>
        ) : null}
        {purchasersState.map((pInfo: IPurchaserInfo, index: number) => {
          return (
            <Container sx={{ mt: 2 }} key={index}>
              <Typography variant="h2">
                <strong>Purchaser {index + 1}</strong>
              </Typography>
              <Box>
                <Dropzone onDrop={(files) => handleDrop(files, index)} accept="image/jpg, image/jpeg, image/png, application/pdf">
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps({ style })}>
                        <input {...getInputProps()} />
                        <p>Drag and Drop or Upload Images</p>
                      </div>
                    </section>
                  )}
                </Dropzone>
                {imageLoading ? (
                  <Box
                    sx={{
                      textAlign: 'center',
                    }}
                  >
                    <LoadingLogo />
                  </Box>
                ) : (
                  <Grid container spacing={2}>
                    {pInfo.identifications.length > 0
                      ? pInfo.identifications.map((attachment: any, numIndex: number) => {
                          return (
                            <Grid key={index} item lg={3} md={4} sm={6} xs={12}>
                              <PdfCard
                                file={attachment.result}
                                title={attachment.name ? attachment.name : attachment.file.name}
                                id={index}
                                handleDelete={deleteImages}
                                download={true}
                                index={numIndex}
                              />
                            </Grid>
                          );
                        })
                      : null}
                  </Grid>
                )}
              </Box>
              <Box sx={{ mb: 2 }}>
                <FormControlLabel
                  onClick={(e) => e.stopPropagation()}
                  control={<Switch checked={pInfo.corp} onChange={(e: any) => handleCorpChange(e, index)} />}
                  label={'Corp'}
                />
              </Box>
              <PurchaserForm
                pInfo={pInfo}
                handleTextInput={handleTextInput}
                handleDateChange={handleDateChange}
                handleEndvestorInput={handleEndvestorInput}
                handleIdTypeInput={handleIdTypeInput}
                handleIdIssuedByInput={handleIdIssuedByInput}
                handleStreetAddress={handleStreetAddress}
                handleDetails={handleDetails}
                handleDeleteSigningOfficer={handleDeleteSigningOfficer}
                handleSigningOfficerInput={handleSigningOfficerInput}
                addSigningOfficer={addSigningOfficer}
                handleDob={handleDob}
                type={index}
                controlled={true}
              />
            </Container>
          );
        })}
        <FlexEnd sx={{ my: 2 }}>
          <Button color="primary" variant="contained" onClick={() => purchasersDispatch({ type: 'ADDNEW', payload: project._id })}>
            Add Purchaser
          </Button>
        </FlexEnd>
        <Container>
          <Typography variant="h2">
            <strong>Realtor {realtor ? `- ${realtor.fullName}` : null}</strong>
          </Typography>
          <FormControl
            sx={{
              width: '100%',
              '& .MuiFormLabel-asterisk': {
                color: 'red',
              },
            }}
          >
            <Autocomplete
              sx={{
                width: '100%',
                '& .MuiFormLabel-asterisk': {
                  color: 'red',
                },
              }}
              id={'search-realtor'}
              disableClearable
              freeSolo={false}
              options={realtors}
              loading={realtorLoading}
              value={realtor}
              getOptionLabel={(option: any) => `${option.firstName} ${option.lastName} ${option.email}`}
              onInputChange={(e, value, reason) => {
                handleSearchRealtor(e, value);
              }}
              onChange={handleRealtor}
              renderInput={(params) => (
                <TextField required={false} {...params} size="small" label={'Search By Name or Email'} margin="normal" />
              )}
            />
          </FormControl>
        </Container>
        {project.options.length && unit ? (
          <Container sx={{ mt: 2 }}>
            <Typography variant="h2">
              <strong>Options</strong>
            </Typography>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              {project.options
                .filter((option: any) => option.type === unit.type && option.allowedUnits.includes(unit.unitType))
                .map((option: IOption, index: number) => {
                  return (
                    <Fragment key={index}>
                      <Grid item xs={12} sm={5}>
                        <Dropdown
                          title={`${option.name} - ${numToCurrency.format(option.price)} each`}
                          id={'purchaseAmount'}
                          menuList={['0', '1', '2', '3']}
                          name={option.name}
                          handleSelect={(e: any) => handleOptionDropdown(e, index)}
                          value={optionValue(option.name, 'purchaseAmount')}
                        />
                      </Grid>
                    </Fragment>
                  );
                })}
            </Grid>
          </Container>
        ) : null}
        <Container sx={{ mt: 2 }}>
          <Typography variant="h2">
            <strong>Notes</strong>
            <Box sx={{ mt: 2 }}>
              <TextField
                id="notes-multiline"
                label="Add Notes for Admin and Managers"
                multiline
                rows={4}
                fullWidth
                value={notes}
                onChange={(e: any) => setNotes(e.target.value)}
              />
            </Box>
          </Typography>
        </Container>
        <Container sx={{ mt: 2 }}>
          <Typography variant="h2">
            <strong>Summary</strong>
          </Typography>
          <Box sx={{ mt: 2 }}>
            {purchasersState.map((purchaser: IPurchaserInfo) => {
              return (
                <Box sx={{ mb: 2 }}>
                  <Box>
                    {purchaser.firstName} {purchaser.lastName}
                  </Box>
                  <Box>
                    <strong>{purchaser.email}</strong>
                  </Box>
                  <Box>
                    <strong>{purchaser.primaryPhone}</strong>
                  </Box>
                </Box>
              );
            })}
            {realtor ? (
              <Box sx={{ mb: 2 }}>
                <Box>{realtor.fullName}</Box>
                <Box>
                  <strong>{realtor.email}</strong>
                </Box>
              </Box>
            ) : null}
            {unit ? (
              <Box>
                Unit Price - {numToCurrency.format(unit?.basePrice)}
                {options.length ? (
                  <Box>
                    {options.map((option: IDealOption) => {
                      return (
                        <Box>
                          {option.name} x{option.purchaseAmount} - {numToCurrency.format(option.amount!)}
                        </Box>
                      );
                    })}
                  </Box>
                ) : null}
                <Box>
                  <strong>
                    Total Price -{' '}
                    {numToCurrency.format(
                      options.reduce((a: any, b: any) => {
                        return a + b.amount;
                      }, unit.basePrice)
                    )}
                  </strong>
                </Box>
              </Box>
            ) : null}
          </Box>
        </Container>
        <FlexEnd sx={{ mt: 2 }}>
          <Button type="submit" variant="contained" color="success">
            Create Worksheet
          </Button>
        </FlexEnd>
      </form>
    </Box>
  );
};

const REALTORS = gql`
  query realtorMany($filter: FilterFindManyRealtorInput) {
    realtorMany(filter: $filter, limit: 10000) {
      _id
      email
      firstName
      lastName
      fullName
      brokerage
      streetAddress
      city
      province
      country
      postalCode
      brokeragePhone
      brokerageFax
      directPhone
      disable
    }
  }
`;

const UNITS = gql`
  query unitMany($filter: FilterFindManyUnitInput) {
    unitMany(filter: $filter, limit: 10000) {
      _id
      suite
      unit
      level
      tier
      modelType
      unitType
      bathroom
      size
      outdoorSize
      basePrice
      rental
      status
      exposure
      type
    }
  }
`;

const CREATEWORKSHEET = gql`
  mutation createWorksheetSales(
    $project: MongoID!
    $unit: MongoID!
    $purchasers: [NewWorksheetPurchaserInput]
    $realtor: MongoID
    $options: [NewOptionInput]
    $notes: String
  ) {
    createWorksheetSales(project: $project, unit: $unit, purchasers: $purchasers, realtor: $realtor, options: $options, notes: $notes) {
      _id
    }
  }
`;

export default CreateWorksheet;
