import { useState, useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Box, Grid, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectProject } from '../../features/project/projectSlice';
import StandardTable from '../tables/StandardTable';
import { selectPortal } from '../../features/portal/portalSlice';
import { downloadExcel, downloadPdf, convertAllDates } from '../../utils/Functions';

const Worksheets = () => {
  const project = useSelector(selectProject);
  const portal = useSelector(selectPortal);
  const [worksheetType, setWorksheetType] = useState<string>('wishlist');
  const [wishlistSummary, setWishlistSummary] = useState<any>(null);
  const [duplicates, setDuplicates] = useState([]);

  const { loading } = useQuery(WORKSHEETS, {
    variables: { project: project._id },
    onCompleted: (data) => {
      setWishlistSummary(data.getWorksheetSummary);
    },
  });

  useQuery(DUPLICATES, {
    variables: { project: project._id },
    onCompleted: (data) => {
      setDuplicates(data.getDuplicates);
    },
  });

  let unitTypes = [
    'studio',
    'jrOneBr',
    'oneBr',
    'oneBrInbound',
    'oneBrMedia',
    'oneBrFlex',
    'oneBrDen',
    'oneBrDenInbound',
    'twoBr',
    'twoBrInbound',
    'twoBrMedia',
    'twoBrFlex',
    'twoBrDen',
    'twoBrDenInbound',
    'juniorTwo',
    'threeBr',
    'threeBrInbound',
    'threeBrMedia',
    'threeBrFlex',
    'threeBrDen',
    'th',
  ];

  const getUnitType = (unitType: string) => {
    switch (unitType) {
      case 'studio':
        return 'Studio';
      case 'jrOneBr':
        return 'JR1BR';
      case 'oneBr':
        return '1BR';
      case 'oneBrInbound':
        return '1BR(I)';
      case 'oneBrDen':
        return '1BR+D';
      case 'oneBrMedia':
        return '1BR+M';
      case 'oneBrFlex':
        return '1BR+F';
      case 'oneBrDenInbound':
        return '1BR+D(I)';
      case 'oneBrMediaInbound':
        return '1BR+M(I)';
      case 'twoBr':
        return '2BR';
      case 'twoBrInbound':
        return '2BR(I)';
      case 'twoBrDen':
        return '2BR+D';
      case 'twoBrMedia':
        return '2BR+M';
      case 'twoBrFlex':
        return '2BR+F';
      case 'juniorTwo':
        return 'JR2';
      case 'twoBrDenInbound':
        return '2BR+D(I)';
      case 'twoBrMediaInbound':
        return '2BR+M(I)';
      case 'threeBr':
        return '3BR';
      case 'threeBrInbound':
        return '3BR(I)';
      case 'threeBrDen':
        return '3BR+D';
      case 'threeBrMedia':
        return '3BR+M';
      case 'threeBrFlex':
        return '3BR+F';
      case 'th':
        return 'TH';
      default:
        return 'N/A';
    }
  };

  const handleWorksheetType = (event: any, worksheetType: string) => {
    // setWorksheets([]);
    if (worksheetType !== null) {
      setWorksheetType(worksheetType);
    }
  };

  const duplicateColumns = [
    {
      Header: 'Purchaser Full Name',
      accessor: (rowData: any) => {
        return rowData.purchaser.fullName;
      },
    },
    {
      Header: 'Purchaser Email',
      accessor: (rowData: any) => {
        return rowData.purchaser.email;
      },
    },
    {
      Header: 'Realtor 1',
      accessor: (rowData: any) => {
        if (rowData.worksheets[0].realtor) {
          return rowData.worksheets[0].realtor.fullName;
        } else return '-';
      },
    },
    {
      Header: 'Realtor 1 Email',
      accessor: (rowData: any) => {
        if (rowData.worksheets[0].realtor) {
          return rowData.worksheets[0].realtor.email;
        }
      },
    },
    {
      Header: 'Realtor 1 Unit',
      accessor: (rowData: any) => {
        return rowData.worksheets[0].unit ? rowData.worksheets[0].unit.suite : 'Not Assigned To Unit';
      },
    },
    {
      Header: 'Realtor 1 Created At',
      accessor: (rowData: any) => {
        return convertAllDates(rowData.worksheets[0].createdAt, 'PPpp');
      },
    },
    {
      Header: 'Realtor 2',
      accessor: (rowData: any) => {
        if (rowData.worksheets[1].realtor) {
          return rowData.worksheets[1].realtor.fullName;
        } else return '-';
      },
    },
    {
      Header: 'Realtor 2 Email',
      accessor: (rowData: any) => {
        if (rowData.worksheets[1].realtor) {
          return rowData.worksheets[1].realtor.email;
        } else return '-';
      },
    },
    {
      Header: 'Realtor 2 Unit',
      accessor: (rowData: any) => {
        return rowData.worksheets[1].unit ? rowData.worksheets[1].unit.suite : 'Not Assigned To Unit';
      },
    },
    {
      Header: 'Realtor 2 Created At',
      accessor: (rowData: any) => {
        return convertAllDates(rowData.worksheets[1].createdAt, 'PPpp');
      },
    },
  ];

  const columns = useMemo(() => {
    return [
      {
        Header: 'Rank',
        accessor: (rowData: any, index: number) => {
          if (index === 0) {
            return <Box sx={{ color: '#d1b313', fontSize: '20px' }}>{index + 1}st</Box>;
          } else if (index === 1) {
            return <Box sx={{ color: '#535156', fontSize: '20px' }}>{index + 1}nd</Box>;
          } else if (index === 2) {
            return <Box sx={{ color: '#CD7F32', fontSize: '20px' }}>{index + 1}rd</Box>;
          } else return index + 1;
        },
      },
      {
        Header: 'Type',
        accessor: (rowData: any) => rowData._id,
      },
      {
        Header: 'Count',
        accessor: (rowData: any) => rowData.count,
      },
    ];
  }, []);

  const realtorColumns = useMemo(() => {
    let unitColumns = [
      {
        Header: 'Realtor',
        accessor: (rowData: any) => {
          return <strong>{rowData._id.fullName}</strong>;
        },
      },
      {
        Header: 'Total Wishlist Worksheets',
        accessor: (rowData: any) => rowData.count,
      },
    ];

    if (wishlistSummary) {
      let checkUnitType = unitTypes
        .map((unitType: string) => {
          let allocationCheck = wishlistSummary.realtors.every((allocation: any) => allocation[unitType] === 0);

          if (!allocationCheck) return unitType;
        })
        .filter((unitType) => unitType);

      let selectedUnitTypes = checkUnitType.map((checkUnit: any) => {
        return {
          Header: getUnitType(checkUnit)!,
          accessor: (rowData: any) => {
            return (
              <Box>
                <strong style={{ cursor: 'pointer' }}>{rowData[checkUnit]}</strong>
              </Box>
            );
          },
        };
      });

      return [...unitColumns, ...selectedUnitTypes];
    } else return [];
  }, [wishlistSummary]);

  const download = (type: string, data: any) => {
    let checkUnitType = unitTypes
      .map((unitType: string) => {
        let allocationCheck = wishlistSummary.realtors.every((allocation: any) => allocation[unitType] === 0);

        if (!allocationCheck) return unitType;
      })
      .filter((unitType) => unitType);

    let allData = data.map((data: any) => {
      let dataObject = checkUnitType.reduce((obj: any, item: any) => ((obj[item] = data[item]), obj), {});

      return {
        realtor: data._id.fullName,
        totalWorksheets: data.count,
        ...dataObject,
      };
    });

    let excelObject = checkUnitType.reduce((obj: any, item: any) => ((obj[item] = 15), obj), {});
    let pdfObject = checkUnitType.reduce((obj: any, item: any) => ((obj[item] = 200), obj), {});

    let widths = {
      realtor: 15,
      totalWorksheets: 15,
    };

    let pdfWidth = {
      realtor: 200,
      totalWorksheets: 200,
    };

    let excelWidths = { ...widths, ...excelObject };
    let pdfWidths = { ...pdfWidth, ...pdfObject };

    let objectHeaders = checkUnitType.map((data: any) => {
      return {
        label: getUnitType(data),
        id: data,
      };
    });

    let headers = [
      {
        label: 'Realtor',
        id: 'realtor',
      },
      {
        label: 'Total Wishlist Worksheets',
        id: 'totalWorksheets',
      },
    ];

    let allHeaders = [...headers, ...objectHeaders];

    let sheetTitle = `${project.name} - Wishlist Summary`;

    if (type === 'excel') {
      downloadExcel([allData], [allHeaders], [], [[excelWidths]], [sheetTitle], sheetTitle);
    } else {
      downloadPdf([allData], [allHeaders], [], [pdfWidths], [sheetTitle], sheetTitle);
    }
  };

  const duplicateDownload = (type: string, data: any) => {
    let unitHeaders = [
      {
        label: 'Purchaser Full Name',
        id: 'purchaserFullName',
      },
      {
        label: 'Purchaser Email',
        id: 'purchaserEmail',
      },
      {
        label: 'Realtor 1',
        id: 'realtorOne',
      },
      {
        label: 'Realtor 1 Email',
        id: 'realtorOneEmail',
      },
      {
        label: 'Realtor 1 Unit',
        id: 'realtorOneUnit',
      },
      {
        label: 'Realtor 1 Created At',
        id: 'realtorOneCreatedAt',
      },
      {
        label: 'Realtor 2',
        id: 'realtorTwo',
      },
      {
        label: 'Realtor 2 Email',
        id: 'realtorTwoEmail',
      },
      {
        label: 'Realtor 2 Unit',
        id: 'realtorTwoUnit',
      },
      {
        label: 'Realtor 2 Created At',
        id: 'realtorTwoCreatedAt',
      },
    ];

    let widths = {
      purchaserFullName: 15,
      purchaserEmail: 15,
      realtorOne: 15,
      realtorOneEmail: 15,
      realtorOneUnit: 15,
      realtorOneCreatedAt: 15,
      realtorTwo: 15,
      realtorTwoEmail: 15,
      realtorTwoUnit: 15,
      realtorTwoCreatedAt: 15,
    };

    let pdfWidths = {
      purchaserFullName: 200,
      purchaserEmail: 200,
      realtorOne: 200,
      realtorOneEmail: 200,
      realtorOneUnit: 200,
      realtorOneCreatedAt: 200,
      realtorTwo: 200,
      realtorTwoEmail: 200,
      realtorTwoUnit: 200,
      realtorTwoCreatedAt: 200,
    };

    let updatedData = data.map((data: any) => {
      return {
        purchaserFullName: data.purchaser.fullName,
        purchaserEmail: data.purchaser.email,
        realtorOne: data.worksheets[0].realtor.fullName,
        realtorOneEmail: data.worksheets[0].realtor.email,
        realtorOneUnit: data.worksheets[0].unit ? data.worksheets[0].unit.suite : 'Not Assigned To Unit',
        realtorOneCreatedAt: convertAllDates(data.worksheets[0].createdAt, 'PPpp'),
        realtorTwo: data.worksheets[1].realtor.fullName,
        realtorTwoEmail: data.worksheets[1].realtor.email,
        realtorTwoUnit: data.worksheets[1].unit ? data.worksheets[1].unit.suite : 'Not Assigned To Unit',
        realtorTwoCreatedAt: convertAllDates(data.worksheets[1].createdAt, 'PPpp'),
      };
    });

    let sheetTitle = `${project.name} - Duplicate Worksheets`;

    if (type === 'excel') {
      downloadExcel([updatedData], [unitHeaders], [], [[widths]], [sheetTitle], sheetTitle);
    } else {
      downloadPdf([updatedData], [unitHeaders], [], [pdfWidths], [sheetTitle], sheetTitle);
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography gutterBottom variant="h2">
        <strong>Worksheets Report</strong>
      </Typography>
      <ToggleButtonGroup
        value={worksheetType}
        exclusive
        onChange={handleWorksheetType}
        aria-label="text alignment"
        sx={{
          marginBottom: '10px',
          '& .MuiToggleButton-root.Mui-selected': {
            backgroundColor: '#00142a',
            color: '#fff',
          },
          '& .MuiToggleButton-root.Mui-selected:hover': {
            backgroundColor: '#00142a',
            color: '#fff',
          },
        }}
      >
        <ToggleButton value="wishlist" aria-label="centered">
          Wishlist
        </ToggleButton>
      </ToggleButtonGroup>
      {!loading && wishlistSummary ? (
        <>
          <Box>
            <Grid container spacing={2}>
              <Grid item md={3}>
                <Box sx={{ p: 2, backgroundColor: portal.primaryColor, color: '#fff' }}>
                  <strong>Models</strong>
                </Box>
                <StandardTable columns={columns} data={wishlistSummary.modelTypes} minWidth={false} />
              </Grid>
              <Grid item md={3}>
                <Box sx={{ p: 2, backgroundColor: portal.primaryColor, color: '#fff' }}>
                  <strong>Unit Types</strong>
                </Box>
                <StandardTable columns={columns} data={wishlistSummary.unitTypes} minWidth={false} />
              </Grid>
              <Grid item md={3}>
                <Box sx={{ p: 2, backgroundColor: portal.primaryColor, color: '#fff' }}>
                  <strong>Level</strong>
                </Box>
                <StandardTable columns={columns} data={wishlistSummary.levels} minWidth={false} />
              </Grid>
              <Grid item md={3}>
                <Box sx={{ p: 2, backgroundColor: portal.primaryColor, color: '#fff' }}>
                  <strong>Exposures</strong>
                </Box>
                <StandardTable columns={columns} data={wishlistSummary.exposures} minWidth={false} />
              </Grid>
            </Grid>
          </Box>
          <Typography sx={{ mt: 10 }} variant={'h5'} gutterBottom>
            Worksheets
          </Typography>
          <Box>
            <StandardTable download={download} columns={realtorColumns} data={wishlistSummary.realtors} />
          </Box>
          <Typography sx={{ mt: 2 }} variant={'h5'} gutterBottom>
            Duplicate Worksheets
          </Typography>
          <Box>
            <StandardTable download={duplicateDownload} columns={duplicateColumns} data={duplicates} />
          </Box>
        </>
      ) : null}
    </Box>
  );
};

const DUPLICATES = gql`
  query getDuplicates($project: MongoID!) {
    getDuplicates(project: $project) {
      purchaser {
        fullName
        email
        primaryPhone
      }
      worksheets {
        realtor {
          fullName
          email
        }
        unit {
          suite
        }
        createdAt
      }
    }
  }
`;

const WORKSHEETS = gql`
  query getWorksheetSummary($project: MongoID!) {
    getWorksheetSummary(project: $project) {
      unitTypes {
        _id
        count
      }
      modelTypes {
        _id
        count
      }
      levels {
        _id
        count
      }
      exposures {
        _id
        count
      }
      realtors {
        _id {
          fullName
        }
        count
        studio
        oneBr
        oneBrInbound
        oneBrDen
        oneBrDenInbound
        juniorTwo
        twoBr
        twoBrInbound
        twoBrDen
        twoBrDenInbound
        threeBr
        threeBrInbound
        threeBrDen
        th
      }
    }
  }
`;

export default Worksheets;
