import React from 'react';
import { Box, CircularProgress } from '@mui/material';

const LoadingWrapper = (props: ChildProps) => {
  const { title, modal, height, width } = props;
  return (
    <Box
      sx={
        modal
          ? {
              height: height ? height : '100vh',
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              width: width ? width : '100%',
              backgroundColor: '#fff',
              zIndex: 1000,
            }
          : {
              display: 'flex',
              height: '500px',
              width: width ? width : '100%',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
            }
      }
    >
      <Box
        sx={{
          display: 'flex',
          height: '500px',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <CircularProgress color="primary" />
        <Box sx={{ display: 'inline', mt: 1 }}>
          <em>{title}</em>
        </Box>
      </Box>
    </Box>
  );
};

interface ChildProps {
  title: string;
  modal: boolean;
  height?: string;
  width?: string;
}

export default LoadingWrapper;
