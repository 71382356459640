import { useState } from 'react';
import { Box, Paper, Typography, List, ListItem, ListSubheader, Button, Tooltip, ClickAwayListener } from '@mui/material';

import { ICollection, IUpgradeTemplate } from '../../../types/colourSelection';
import { IPortal } from '../../../types/project';
import { useSelector } from 'react-redux';
import { selectProject } from '../../../features/project/projectSlice';
import { IDecorModels } from '../../../types/project';
import { IUnit } from '../../../types/unit';
import { mainCategories as categories } from '../../../utils/Constants';

const CollectionCard = (props: ChildProps) => {
  const project = useSelector(selectProject);
  const { collection, portal, handleCollection, upgradeTemplates, unit } = props;
  const [toolTip, setToolTip] = useState<number[]>([]);

  let collectionUpgradeTemplates = () => {
    const groupByKey = (data: any, key: any) =>
      Object.values(
        data.reduce((res: any, item: any) => {
          const value = item[key];
          const existing = res[value] || { [key]: value, data: [] };
          return {
            ...res,
            [value]: {
              ...existing,
              data: [...existing.data, item],
            },
          };
        }, {})
      );

    let selectedModelType = project.decorModels.find((decorModel: IDecorModels) => decorModel.modelType === unit.modelType);

    let groupedData = groupByKey(collection.upgradeTemplates, 'mainCategory');
    if (selectedModelType) {
      groupedData = groupedData.filter((data: any) => !selectedModelType?.allowed.includes(data.mainCategory));
    }

    let nestedGroupData = groupedData
      .map((data: any) => {
        return {
          mainCategory: data.mainCategory,
          subCategory: groupByKey(data.data, 'subCategory').sort((a: any, b: any) => {
            let mainCategoryA = a.subCategory.toLowerCase();
            let mainCategoryB = b.subCategory.toLowerCase();
            if (mainCategoryA < mainCategoryB) return -1;
            if (mainCategoryA > mainCategoryB) return 1;
            return 0;
          }),
        };
      })
      .sort(function (a, b) {
        return categories.indexOf(a.mainCategory) - categories.indexOf(b.mainCategory);
      });

    return nestedGroupData;
  };

  const toggleClick = (index: number, numIndex: number, numNumIndex: number) => {
    if (!toolTip.length) {
      setToolTip([index, numIndex, numNumIndex]);
    } else {
      setToolTip(index === toolTip[0] && numIndex === toolTip[1] && numIndex === toolTip[2] ? [] : [index, numIndex, numNumIndex]);
    }
  };

  const handleClickAway = (e: any) => {
    setToolTip([]);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Paper
        sx={{
          px: 10,
          pt: 2,
          pb: 4,
          height: '100%',
          borderRadius: '12px',
          backgroundColor: collection.primaryColor ? collection.primaryColor : portal.primaryColor,
          fontFamily: 'Abadi-Light',
          display: 'flex',
          flexDirection: 'column',
        }}
        elevation={12}
      >
        <Box sx={{ textAlign: 'center', borderRadius: '8px' }}>
          <Typography
            sx={{ fontFamily: 'Abadi-Bold', my: 2, color: collection.secondaryColor ? collection.secondaryColor : '#fff' }}
            variant="h1"
          >
            <strong>{collection.name}</strong>
          </Typography>
        </Box>
        <Box sx={{ px: 5, backgroundColor: '#fff', borderRadius: '12px', flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
          {collectionUpgradeTemplates().map((data: any, index: number) => {
            return (
              <Box sx={{ pt: 2, pb: 1, fontFamily: 'Abadi-Light' }} key={index}>
                <List
                  sx={{ listStyleType: 'disc' }}
                  subheader={
                    <ListSubheader
                      sx={{
                        fontFamily: 'Abadi-Bold',
                        fontSize: '1.3rem',
                        textDecoration: 'underline',
                        lineHeight: 1.5,
                        position: 'relative',
                      }}
                      component="div"
                      id="nested-list-subheader"
                    >
                      {data.mainCategory}
                    </ListSubheader>
                  }
                >
                  {data.subCategory.map((subCategory: any, numIndex: number) => {
                    return (
                      <ListItem sx={{ py: 0 }}>
                        <List sx={{ listStyleType: 'disc', py: 0 }}>
                          <ListSubheader
                            sx={{ py: 0, fontFamily: 'Abadi-Bold', fontSize: '1rem', lineHeight: 1.5, position: 'relative' }}
                            component="div"
                            id="nested-list-subheader"
                          >
                            {subCategory.subCategory}
                          </ListSubheader>
                          {subCategory.data.map((upgrade: IUpgradeTemplate, numNumIndex: number) => {
                            return (
                              <Tooltip
                                PopperProps={{
                                  disablePortal: true,
                                  modifiers: [
                                    {
                                      name: 'offset',
                                      options: {
                                        offset: [50, -15],
                                      },
                                    },
                                  ],
                                }}
                                arrow
                                componentsProps={{
                                  arrow: {
                                    sx: {
                                      color: collection.primaryColor ? collection.primaryColor : portal.primaryColor,
                                    },
                                  },
                                  tooltip: {
                                    sx: {
                                      bgcolor: collection.primaryColor ? collection.primaryColor : portal.primaryColor,
                                      color: collection.secondaryColor ? collection.secondaryColor : '#fff',
                                      mt: 0,
                                    },
                                  },
                                }}
                                disableFocusListener
                                disableHoverListener
                                open={
                                  upgrade.description !== '' &&
                                  toolTip[0] === index &&
                                  toolTip[1] === numIndex &&
                                  toolTip[2] === numNumIndex
                                }
                                onClose={() => setToolTip([])}
                                title={<Box>{upgrade.description}</Box>}
                              >
                                <ListItem
                                  onClick={() => toggleClick(index, numIndex, numNumIndex)}
                                  sx={{ fontSize: '1.2rem', ml: 4, pt: 0, display: 'list-item' }}
                                >
                                  {upgrade.name}
                                </ListItem>
                              </Tooltip>
                            );
                          })}
                        </List>
                      </ListItem>
                    );
                  })}
                </List>
              </Box>
            );
          })}
          <Box sx={{ marginTop: 'auto', mb: 2, textAlign: 'center' }}>
            <Button
              variant="contained"
              onClick={() => handleCollection(collection, upgradeTemplates, true)}
              sx={{
                backgroundColor: collection.primaryColor ? collection.primaryColor : portal.primaryColor,
                color: collection.secondaryColor ? collection.secondaryColor : '#fff',
                '&.MuiButtonBase-root:hover': {
                  bgcolor: collection.primaryColor ? collection.primaryColor : portal.primaryColor,
                },
                fontFamily: 'Abadi-Bold',
              }}
            >
              Select {collection.name}
            </Button>
          </Box>
        </Box>
      </Paper>
    </ClickAwayListener>
  );
};

interface ChildProps {
  collection: ICollection;
  selectedCollection: ICollection | null;
  portal: IPortal;
  index: number;
  handleCollection: any;
  upgradeTemplates: IUpgradeTemplate[];
  unit: IUnit;
}

export default CollectionCard;
