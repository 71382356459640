import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { gql, useMutation } from '@apollo/client';
import { Box, Button, TextField } from '@mui/material';
import Dropdown from '../../common/formControls/Dropdown';
import { useQuery } from '@apollo/client';
import { PriceGridContext } from '../../../context/PriceGridContext';
import { selectProject } from '../../../features/project/projectSlice';
import { IUnit, IPriceList } from '../../../types/unit';
import { FlexEnd } from '../../../commonStyles';
import { useAppDispatch } from '../../../app/hooks';
import { handleModal } from '../../../features/modal/modalSlice';
import { showSuccessSnackbar, showErrorSnackbar } from '../../../features/snackbar/snackbarSlice';

const Draft = () => {
  const storeDispatch = useAppDispatch();
  const project = useSelector(selectProject);
  const {
    priceLists,
    setPriceLists,
    selectedPriceList,
    setSelectedPriceList,
    setPriceListName,
    priceListName,
    units,
    setUnits,
    setDraft,
    setModalType,
  } = useContext(PriceGridContext);

  useQuery(GETPRICELISTS, {
    fetchPolicy: 'cache-and-network',
    variables: { filter: { project: project._id } },
    onCompleted: (data) => {
      setPriceLists(data.priceListMany);
    },
  });

  const [updatePriceList] = useMutation(UPDATEPRICELIST, {
    onCompleted: (data) => {
      setSelectedPriceList({
        ...selectedPriceList!,
        name: data.priceListUpdateById.record.name,
      });
    },
  });

  const [createPriceList] = useMutation(CREATEPRICELIST, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Price List Created!'));
      setUnits(data.priceListCreateOne.record.draft);
      setDraft(true);
      setSelectedPriceList(data.priceListCreateOne.record);
      setPriceLists([...priceLists, data.priceListCreateOne.record]);
      setPriceListName(data.priceListCreateOne.record.name);
      storeDispatch(handleModal(false));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [removePriceList] = useMutation(DELETEPRICELIST, {
    onCompleted: (data) => {
      setPriceLists(priceLists.filter((priceList: IPriceList) => priceList._id !== data.priceListRemoveById.record._id));
      setSelectedPriceList(null);
      setPriceListName('');
      storeDispatch(showSuccessSnackbar(`${data.priceListRemoveById.record.name} has been deleted`));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const handlePriceListDropdown = (e: any) => {
    let selectedPriceList = priceLists.find((priceList: IPriceList) => priceList.name === e.target.value);
    if (selectedPriceList) {
      setSelectedPriceList(selectedPriceList);
      setPriceListName(selectedPriceList.name);
    }
  };

  const viewSelected = async () => {
    storeDispatch(handleModal(false));
    if (priceListName !== selectedPriceList?.name) {
      await updatePriceList({ variables: { _id: selectedPriceList?._id, record: { name: priceListName } } });
    }
    setDraft(true)
    setUnits(selectedPriceList?.draft);
  };

  const createList = async () => {
    if (selectedPriceList) {
      let name = selectedPriceList.name === priceListName ? `${priceListName} Copy` : priceListName;
      await createPriceList({
        variables: {
          record: {
            name: `${name ? name : `Price List Draft ${priceLists.length + 1}`}`,
            project: project._id,
            draft: units,
          },
        },
      });
    } else {
      let priceListUnits = await units.map((unit: IUnit) => {
        return {
          status: unit.status,
          suite: unit.suite,
          unitId: unit._id,
          unit: unit.unit,
          level: unit.level,
          modelType: unit.modelType,
          unitType: unit.unitType,
          basePrice: unit.basePrice,
          size: unit.size,
          outdoorType: unit.outdoorType,
          outdoorSize: unit.outdoorSize,
          bathroom: unit.bathroom,
          exposure: unit.exposure,
          col: unit.col,
          row: unit.row,
          stackCol: unit.stackCol,
          stackRow: unit.stackRow,
        };
      });
      await createPriceList({
        variables: {
          record: {
            name: `${priceListName ? priceListName : `Price List Draft ${priceLists.length + 1}`}`,
            project: project._id,
            draft: priceListUnits,
          },
        },
      });
    }
  };

  return (
    <Box sx={{ my: 2 }}>
      <Box sx={{ mb: 2 }}>
        <strong>
          Select "Create New Price List" to create an editable draft of the default price list, or select from an existing draft from the
          dropdown to create an editable copy.
        </strong>
      </Box>
      <Dropdown
        title={'Price Lists'}
        menuList={priceLists.map((priceList: IPriceList) => priceList.name)}
        name={'priceLists'}
        handleSelect={(e: any) => handlePriceListDropdown(e)}
        value={selectedPriceList ? selectedPriceList.name : ''}
      />
      <Box sx={{ mt: 2 }}>
        <TextField
          title={'Name'}
          name={'priceListName'}
          fullWidth
          value={priceListName}
          label={'Name'}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPriceListName(e.target.value)}
        />
      </Box>
      <FlexEnd sx={{ mt: 2 }}>
        <Button
          variant="contained"
          color="info"
          sx={{ mr: 1 }}
          onClick={() => {
            if (selectedPriceList) {
              setSelectedPriceList(null);
            }
            setModalType('');
            storeDispatch(handleModal(false))
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="error"
          sx={{ mr: 1 }}
          onClick={() => {
            if (!selectedPriceList) return storeDispatch(showErrorSnackbar('No Price List Selected'));
            removePriceList({ variables: { _id: selectedPriceList?._id } });
          }}
        >
          Delete Selected Price List
        </Button>
        <Button sx={{ mr: 1 }} variant="contained" color="success" onClick={() => createList()}>
          Create New Price List
        </Button>
        <Button variant="contained" color="success" onClick={() => viewSelected()}>
          View Selected Price List
        </Button>
      </FlexEnd>
    </Box>
  );
};

const GETPRICELISTS = gql`
  query priceListMany($filter: FilterFindManyPriceListInput) {
    priceListMany(filter: $filter, limit: 10000) {
      _id
      name
      project {
        _id
      }
      draft {
        _id
        status
        suite
        unit
        unitId
        level
        modelType
        unitType
        basePrice
        size
        outdoorType
        outdoorSize
        bathroom
        exposure
        col
        row
        stackCol
        stackRow
      }
    }
  }
`;

const UPDATEPRICELIST = gql`
  mutation priceListUpdateById($_id: MongoID!, $record: UpdateByIdPriceListInput!) {
    priceListUpdateById(_id: $_id, record: $record) {
      record {
        _id
        name
        project {
          _id
        }
        draft {
          _id
          status
          suite
          unit
          unitId
          level
          modelType
          unitType
          basePrice
          size
          outdoorType
          outdoorSize
          bathroom
          exposure
          col
          row
          stackCol
          stackRow
        }
      }
    }
  }
`;

const DELETEPRICELIST = gql`
  mutation priceListRemoveById($_id: MongoID!) {
    priceListRemoveById(_id: $_id) {
      record {
        _id
        name
        project {
          _id
        }
        draft {
          _id
          status
          suite
          unit
          unitId
          level
          modelType
          unitType
          basePrice
          size
          outdoorType
          outdoorSize
          bathroom
          exposure
          col
          row
          stackCol
          stackRow
        }
      }
    }
  }
`;

const CREATEPRICELIST = gql`
  mutation priceListCreateOne($record: CreateOnePriceListInput!) {
    priceListCreateOne(record: $record) {
      record {
        _id
        name
        project {
          _id
        }
        draft {
          _id
          status
          suite
          unit
          unitId
          level
          modelType
          unitType
          basePrice
          size
          outdoorType
          outdoorSize
          bathroom
          exposure
          col
          row
          stackCol
          stackRow
        }
      }
    }
  }
`;

export default Draft;
