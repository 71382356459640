import React, { useMemo } from 'react';
import { Box, Paper } from '@mui/material';

import { numToCurrency, numToCurrencyDecimal, downloadExcel, downloadPdf } from '../../utils/Functions';
import StandardTable from '../tables/StandardTable';
import { useSelector } from 'react-redux';
import { selectProject } from '../../features/project/projectSlice';

const DecorEnhancedTable = (props: ChildProps) => {
  const project = useSelector(selectProject);
  const { decorData } = props;

  const download = (type: string, data: any) => {
    let headers = [
      {
        label: 'Name',
        id: 'name',
      },
      {
        label: 'Count',
        id: 'count',
      },
      {
        label: 'Total Revenue',
        id: 'totalRevenue',
      },
      {
        label: 'Total Revenue Net HST',
        id: 'totalRevenueNetHST',
      },
      {
        label: 'Total Cost',
        id: 'totalCost',
      },
    ];

    let widths = {
      name: 15,
      count: 15,
      totalRevenue: 15,
      totalRevenueNetHST: 15,
      totalCost: 15,
    };

    let pdfWidths = {
      name: 200,
      count: 200,
      totalRevenue: 200,
      totalRevenueNetHST: 200,
      totalCost: 200,
    };

    let newData = data.map((data: any) => {
      return {
        name: data.name,
        count: data.count,
        totalRevenue: numToCurrency.format(data.revenue),
        totalRevenueNetHST: numToCurrency.format(data.revenue / 1.13),
        totalCost: data.totalCost,
      };
    });

    let sheetTitle = `${project.name} - Decor Total`;

    if (type === 'excel') {
      downloadExcel([newData], [headers], [], [[widths]], [sheetTitle], sheetTitle);
    } else {
      downloadPdf([newData], [headers], [], [pdfWidths], [sheetTitle], sheetTitle);
    }
  };

  const columns = useMemo(() => {
    return [
      {
        Header: 'Name',
        accessor: (rowData: any) => <strong>{rowData.name}</strong>,
      },
      {
        Header: 'Count',
        accessor: (rowData: any) => <strong>{rowData.count}</strong>,
      },
      {
        Header: 'Total Revenue',
        accessor: (rowData: any) => {
          return (
            <Box sx={{ fontWeight: rowData.name === 'Total' ? 'bold' : 'normal' }}>{numToCurrency.format(rowData.revenue * 1.13)} </Box>
          );
        },
      },
      {
        Header: 'Average Revenue',
        accessor: (rowData: any) => <strong>{numToCurrencyDecimal.format(rowData.revenue / rowData.count)}</strong>,
      },
      {
        Header: 'Total Revenue Net HST',
        accessor: (rowData: any) => {
          let profit = ((rowData.revenue - rowData.totalCost) / rowData.revenue) * 100;
          return (
            <Box sx={{ fontWeight: rowData.name === 'Total' ? 'bold' : 'normal' }}>
              <Box>{numToCurrencyDecimal.format(rowData.revenue)} </Box>
              <Box sx={{ display: 'inline', color: profit >= 0 ? 'green' : 'red' }}>{`(${(
                ((rowData.revenue - rowData.totalCost) / rowData.revenue) *
                100
              ).toFixed(2)}%)`}</Box>
            </Box>
          );
        },
      },
      {
        Header: 'Total Cost',
        accessor: (rowData: any) => (
          <Box sx={{ fontWeight: rowData.name === 'Total' ? 'bold' : 'normal' }}>{numToCurrencyDecimal.format(rowData.totalCost)}</Box>
        ),
      },
    ];
  }, []);

  return (
    <Paper elevation={5}>
      <StandardTable download={download} data={decorData} columns={columns} />
    </Paper>
  );
};

interface ChildProps {
  decorData: any;
}

export default DecorEnhancedTable;
