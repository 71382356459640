import { useState, useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Box, Typography, TextField, Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../app/hooks';
import { showErrorSnackbar } from '../../features/snackbar/snackbarSlice';
import { useSelector } from 'react-redux';
import { selectProject } from '../../features/project/projectSlice';
import StandardTable from '../tables/StandardTable';
import { IDeal, IPurchaserInfo } from '../../types/CreateDealForm';
import { IUnit } from '../../types/unit';
import { sortNestedSuites } from '../../utils/Functions';
import { GlobalModal } from '../../features/modal/Modal';
import { FlexBetween } from '../../commonStyles';
import { handleModal } from '../../features/modal/modalSlice';

const Decor = () => {
  const location = useLocation();
  const storeDispatch = useAppDispatch();
  const navigate = useNavigate();
  const project = useSelector(selectProject);
  const [decorDeals, setDecorDeals] = useState<IDeal[]>([]);
  const [deal, setDeal] = useState<IDeal | null>(null);
  const [password, setPassword] = useState<string>('');

  useQuery(DECORDEALS, {
    variables: { project: project._id, type: 'decorDeals' },
    onCompleted: (data) => {
      let sorted = [...data.getDeals];
      sorted = sortNestedSuites(sorted, 'unit', 'suite');
      setDecorDeals(sorted);
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar(err.message));
    },
  });

  const handleDisplayModal = (deal: IDeal) => {
    setDeal(deal);
    storeDispatch(handleModal(true));
  };

  const handlePasswordSubmit = (e: any) => {
    e.preventDefault();
    if (deal) {
      if (password === `${deal?.unit.modelType}${deal.purchasers[0].lastName}`) {
        storeDispatch(handleModal(false));
        navigate(`/${project._id}/${deal.unit._id}`, {
          state: {
            dealId: deal._id
          },
        });
      } else {
        return storeDispatch(showErrorSnackbar('Password is incorrect'));
      }
    } else return storeDispatch(showErrorSnackbar('Error No Deal Found'));
  };

  const columns = useMemo(() => {
    return [
      {
        Header: 'Suite',
        accessor: (rowData: any) => {
          return (
            <Box onClick={() => handleDisplayModal(rowData)}>
              <strong style={{ cursor: 'pointer' }}>{rowData.unit.suite}</strong>
            </Box>
          );
        },
      },
      {
        Header: 'Purchaser Last Names',
        accessor: (rowData: any) => rowData.purchasers.map((purchaser: IPurchaserInfo) => purchaser.lastName).join(', '),
      },
      {
        Header: 'Model Type',
        accessor: (rowData: any) => rowData.unit.modelType,
      },
      {
        Header: 'Unit Type',
        accessor: (rowData: any) => rowData.unit.unitType,
      },
    ];
  }, []);

  return (
    <Box sx={{ p: 2 }}>
      <GlobalModal>
        <form onSubmit={handlePasswordSubmit} autoComplete="off">
          <Box>
            <Typography component={'div'} variant={'h5'} gutterBottom>
              Password Required
            </Typography>
            <TextField
              title={'Password'}
              name={'password'}
              fullWidth
              type="password"
              value={password}
              label={'Password'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
            />
          </Box>
          <FlexBetween sx={{ mt: 2 }}>
            <Button type="submit" variant="contained" color="success">
              Submit
            </Button>
            <Button onClick={() => storeDispatch(handleModal(false))} variant="contained" color="info">
              Cancel
            </Button>
          </FlexBetween>
        </form>
      </GlobalModal>
      <Typography component={'div'} variant={'h5'} gutterBottom>
        <strong>Decor</strong>
      </Typography>
      <Box>
        <StandardTable data={decorDeals} columns={columns} />
      </Box>
    </Box>
  );
};

const DECORDEALS = gql`
  query getDeals($project: MongoID!, $type: String!) {
    getDeals(project: $project, type: $type) {
      _id
      unit {
        _id
        suite
        modelType
        unitType
      }
      purchasers {
        lastName
      }
    }
  }
`;

export default Decor;
