import { useState, SetStateAction, Dispatch, useContext } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';
import { FormControl, Autocomplete, TextField } from '@mui/material';
import CustomDialog from '../../../common/CustomDialog';
import { useAppDispatch } from '../../../../app/hooks';
import { showErrorSnackbar, showSuccessSnackbar } from '../../../../features/snackbar/snackbarSlice';
import { UnitContext } from '../../../../context/UnitContext';
import { selectProject } from '../../../../features/project/projectSlice';
import { selectUser } from '../../../../features/auth/authSlice';
import { useCreateActivity } from '../../../../features/activity/activityHooks';
import { IRealtorInfo, IDealRealtor } from '../../../../types/CreateDealForm';
import LoadingWrapper from '../../../common/LoadingWrapper';

const RealtorDialog = (props: ChildProps) => {
  const project = useSelector(selectProject);
  const user = useSelector(selectUser);
  const storeDispatch = useAppDispatch();
  const createActivity = useCreateActivity();
  const { unit, filteredDeal, setFilteredDeal, realtors, realtorLoading } = useContext(UnitContext);
  const { dialogType, setDialogType, dialogOpen, setDialogOpen, realtorIndex } = props;
  const [selectedRealtor, setSelectedRealtor] = useState<IRealtorInfo>({});

  const [addRealtor] = useMutation(ADDREALTOR, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Realtor has been added to the deal'));
      createActivity({
        project: project._id,
        user: user._id,
        deal: filteredDeal._id,
        title: `Add Realtor`,
        content: `Realtor has been added to the deal`,
      });
      let dealRealtorIds = filteredDeal.realtor.map((realtor: IDealRealtor) => realtor._id);
      updateDealRealtor({
        variables: { _id: filteredDeal._id, record: { realtor: [...dealRealtorIds, data.dealRealtorCreateOne.record._id] } },
      });
      setFilteredDeal({
        ...filteredDeal,
        realtor: [
          ...filteredDeal.realtor,
          {
            _id: data.dealRealtorCreateOne.record._id,
            firstName: data.dealRealtorCreateOne.record.firstName,
            lastName: data.dealRealtorCreateOne.record.lastName,
            brokerage: data.dealRealtorCreateOne.record.brokerage,
            email: data.dealRealtorCreateOne.record.email,
            streetAddress: data.dealRealtorCreateOne.record.streetAddress,
            city: data.dealRealtorCreateOne.record.city,
            province: data.dealRealtorCreateOne.record.province,
            country: data.dealRealtorCreateOne.record.country,
            postalCode: data.dealRealtorCreateOne.record.postalCode,
            brokeragePhone: data.dealRealtorCreateOne.record.brokeragePhone,
            directPhone: data.dealRealtorCreateOne.record.directPhone,
            brokerageFax: data.dealRealtorCreateOne.record.brokerageFax,
            coopRates: data.dealRealtorCreateOne.record.coopRates,
          },
        ],
      });
      updateAllocation({
        variables: {
          _id: unit._id,
          record: {
            allocation: selectedRealtor._id,
          },
        },
      });
      setSelectedRealtor({});
      setDialogType('');
      setDialogOpen(false);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [updateDealRealtor] = useMutation(DELETEREALTORDEAL, {
    onCompleted: (data) => {},
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [updateAllocation] = useMutation(UPDATEUNIT, {
    onCompleted: (data) => {},
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [deleteRealtor] = useMutation(DELETEREALTOR, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Realtor has been removed from the deal'));
      createActivity({
        project: project._id,
        user: user._id,
        deal: filteredDeal._id,
        title: `Remove Realtor`,
        content: `Realtor has been removed from the deal`,
      });
      let dealRealtorIds = filteredDeal.realtor.filter((realtor: IDealRealtor) => realtor._id !== data.dealRealtorRemoveById.record._id);
      updateDealRealtor({
        variables: { _id: filteredDeal._id, record: { realtor: dealRealtorIds.map((realtor: IDealRealtor) => realtor._id) } },
      });
      updateAllocation({
        variables: {
          _id: unit._id,
          record: {
            allocation: null,
          },
        },
      });
      setFilteredDeal({
        ...filteredDeal,
        realtor: dealRealtorIds,
      });
      setDialogType('');
      setDialogOpen(false);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const handleCloseSuccess = async () => {
    if (dialogType === 'addRealtor') {
      const { _id, ...newObject } = selectedRealtor;
      newObject.deal = filteredDeal._id;
      newObject.project = project._id;
      newObject.realtor = _id;
      addRealtor({ variables: { record: newObject } });
    } else if (dialogType === 'deleteRealtor') {
      deleteRealtor({ variables: { _id: filteredDeal.realtor[realtorIndex]._id } });
    }
  };

  const dialogContent = () => {
    if (dialogType === 'addRealtor') {
      return realtorLoading ? (
        <LoadingWrapper title="Realtors is loading..." modal={false} />
      ) : (
        <div>
          <FormControl
            sx={{
              width: '100%',
              '& .MuiFormLabel-asterisk': {
                color: 'red',
              },
            }}
          >
            <Autocomplete
              sx={{
                width: '100%',
                '& .MuiFormLabel-asterisk': {
                  color: 'red',
                },
              }}
              id={'search'}
              disableClearable
              freeSolo={false}
              options={realtors}
              getOptionLabel={(option: any) => `${option.firstName} ${option.lastName} ${option.email}`}
              // getOptionSelected={(option, value) => option === value}
              onChange={(e, values) => setSelectedRealtor(values)}
              renderInput={(params) => (
                <TextField required={false} {...params} size="small" label={'Search By Name or Email'} margin="normal" />
              )}
            />
          </FormControl>
        </div>
      );
    }
  };

  return (
    <CustomDialog
      handleClose={() => {
        setDialogOpen(false);
        setDialogType('');
      }}
      handleCloseRemove={() => {
        setDialogOpen(false);
        setDialogType('');
      }}
      handleCloseSuccess={handleCloseSuccess}
      open={dialogOpen}
      removeButton={'Cancel'}
      successButton={dialogType === 'addRealtor' ? 'Yes, I would like to add this realtor' : 'Yes, delete this realtor'}
      dialogContent={dialogContent()}
      dialogTitle={dialogType === 'addRealtor' ? 'Please select a Realtor' : 'Are you sure you would like to remove this realtor?'}
      maxWidth={'xl'}
    />
  );
};

interface ChildProps {
  dialogOpen: boolean;
  dialogType: string;
  setDialogOpen: Dispatch<SetStateAction<boolean>>;
  setDialogType: Dispatch<SetStateAction<string>>;
  realtorIndex: number;
}

const UPDATEUNIT = gql`
  mutation unitUpdateById($_id: MongoID!, $record: UpdateByIdUnitInput!) {
    unitUpdateById(_id: $_id, record: $record) {
      record {
        _id
        suite
        unit
        level
        modelType
        basePrice
        size
        status
        getUrl
        putUrl
        bathroom
        exposure
        status
        outdoorSize
        outdoorType
        unitType
        originalPrice
        basePrice
        allocation {
          _id
          fullName
        }
        history {
          type
          description
          timestamp
          user {
            _id
            fullName
          }
          _id
        }
      }
    }
  }
`;

const ADDREALTOR = gql`
  mutation dealRealtorCreateOne($record: CreateOneDealRealtorInput!) {
    dealRealtorCreateOne(record: $record) {
      record {
        _id
        firstName
        lastName
        brokerage
        email
        streetAddress
        city
        province
        country
        postalCode
        brokeragePhone
        directPhone
        brokerageFax
        coopRates {
          type
          days
          date
          description
          percentage
          fixedAmount
        }
      }
    }
  }
`;

const DELETEREALTORDEAL = gql`
  mutation dealUpdateById($_id: MongoID!, $record: UpdateByIdDealInput!) {
    dealUpdateById(_id: $_id, record: $record) {
      record {
        realtor {
          _id
        }
      }
    }
  }
`;

const DELETEREALTOR = gql`
  mutation dealRealtorRemoveById($_id: MongoID!) {
    dealRealtorRemoveById(_id: $_id) {
      record {
        _id
      }
    }
  }
`;

export default RealtorDialog;
