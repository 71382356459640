import React from 'react';
import 'react-step-progress-bar/styles.css';
import { ProgressBar, Step } from 'react-step-progress-bar';
import { IStatus } from '../../types/project';
import { Box } from '@mui/material';

const StepProgressBar = (props: any) => {
  const { percent } = props;

  return (
    <Box
      sx={{
        marginTop: '20px',
        padding: '10px',
      }}
    >
      <ProgressBar percent={percent}>
        {props.steps.map((step: IStatus, index: number) => {
          return (
            <Step key={index}>
              {({ accomplished, index }: any) => (
                <Box
                  sx={{
                    backgroundColor: step.color,
                    color: '#000',
                    padding: '5px 7px',
                    border: '1px solid #000',
                    borderRadius: '10px',
                    fontSize: '20px',
                  }}
                  className={`indexedStep ${accomplished ? 'accomplished' : ''}`}
                >
                  {step.code}
                </Box>
              )}
            </Step>
          );
        })}
      </ProgressBar>
    </Box>
  );
};

export default StepProgressBar;
