import { useState, Dispatch, SetStateAction, useEffect } from 'react';
import { Box, Grid, Button, Typography, TextField, Autocomplete } from '@mui/material';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import ArticleIcon from '@mui/icons-material/Article';
import Lightbox from 'react-18-image-lightbox';

import { IUnit } from '../../types/unit';
import FloorplanCard from './FloorplanCard';
import { convertAllDates, numToCurrency } from '../../utils/Functions';
import Dropdown from '../common/formControls/Dropdown';
import { filter } from 'jszip';

const Floorplans = (props: ChildProps) => {
  const { units, favourites, setFavourites, setSelectedUnit, setUnits, allUnits } = props;
  const [floorplanIndex, setFloorplanIndex] = useState<number>(0);
  const [floorplanType, setFloorplanType] = useState<string>('all');
  const [open, setOpen] = useState<boolean>(false);
  const [unitType, setUnitType] = useState<string[]>([]);

  const unitTypes = [...new Set(allUnits.map((unit) => unit.unitType))];

  useEffect(() => {
    if (unitType.length) {
      setUnits(allUnits.filter((unit: IUnitMarketing) => unitType.includes(unit.unitType)));
    } else {
      setUnits(allUnits);
    }
  }, [unitType]);

  const handleOpen = (index: number, type: string) => {
    setFloorplanType(type);
    setFloorplanIndex(index);
    setOpen(true);
  };

  const handleFavourites = (unit: IUnitMarketing) => {
    setFavourites(favourites.filter((favourite: IUnitMarketing) => favourite !== unit));
  };

  const handleSelectedUnit = (unit: IUnitMarketing) => {
    setSelectedUnit(unit);
    setOpen(false);
  };

  return (
    <Box>
      {favourites.length ? (
        <Box>
          <Typography sx={{ px: 2 }} variant={'h5'} gutterBottom>
            <strong>Favourites</strong>
          </Typography>
          <Grid container spacing={2} sx={{ p: 2 }}>
            {favourites.map((unit: IUnitMarketing, index: number) => {
              return (
                <Grid key={index} item xs={12} sm={4} md={3}>
                  <FloorplanCard unit={unit} handleOpen={handleOpen} index={index} type={'favourite'} handleFavourites={handleFavourites} />
                </Grid>
              );
            })}
          </Grid>
        </Box>
      ) : null}
      <Typography sx={{ px: 2 }} variant={'h5'} gutterBottom>
        <strong>All Floorplans</strong>
      </Typography>
      <Grid container spacing={2} sx={{ p: 2 }}>
        <Grid item xs={12} sm={6} md={3}>
          <Autocomplete
            multiple
            options={unitTypes.sort()}
            getOptionLabel={(option: string) => option}
            disableClearable={false}
            freeSolo={false}
            value={unitType}
            onChange={(event: any, newValue: any | null) => {
              setUnitType(newValue.map((option: string) => option));
            }}
            renderInput={(params) => <TextField {...params} label="Filter Unit Types" size="small" />}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ p: 2 }}>
        {units.map((unit: IUnitMarketing, index: number) => {
          return (
            <Grid key={index} item xs={12} sm={4} md={3}>
              <FloorplanCard unit={unit} handleOpen={handleOpen} index={index} type={'all'} />
            </Grid>
          );
        })}
      </Grid>
      {open && (
        <Lightbox
          mainSrc={floorplanType === 'all' ? units[floorplanIndex].marketingFloorPlan : favourites[floorplanIndex].marketingFloorPlan}
          nextSrc={
            floorplanType === 'all'
              ? units[(floorplanIndex + 1) % units.length].marketingFloorPlan
              : favourites[(floorplanIndex + 1) % favourites.length].marketingFloorPlan
          }
          prevSrc={
            floorplanType === 'all'
              ? units[(floorplanIndex + units.length - 1) % units.length].marketingFloorPlan
              : favourites[(floorplanIndex + favourites.length - 1) % favourites.length].marketingFloorPlan
          }
          onCloseRequest={() => setOpen(false)}
          onMovePrevRequest={() =>
            floorplanType === 'all'
              ? setFloorplanIndex((floorplanIndex + units.length - 1) % units.length)
              : setFloorplanIndex((floorplanIndex + favourites.length - 1) % favourites.length)
          }
          onMoveNextRequest={() =>
            floorplanType === 'all'
              ? setFloorplanIndex((floorplanIndex + 1) % units.length)
              : setFloorplanIndex((floorplanIndex + 1) % favourites.length)
          }
          imageCaption={
            floorplanType === 'all'
              ? `Suite ${units[floorplanIndex].suite} - ${numToCurrency.format(units[floorplanIndex].basePrice)} - ${convertAllDates(
                  new Date(),
                  'PPpp'
                )}`
              : `Suite ${favourites[floorplanIndex].suite} - ${numToCurrency.format(
                  favourites[floorplanIndex].basePrice
                )} - ${convertAllDates(new Date(), 'PPpp')}`
          }
          toolbarButtons={[
            <Button sx={{ p: 0, minWidth: 0 }}>
              {floorplanType === 'all' ? (
                units.includes(units[floorplanIndex]) &&
                (!units[floorplanIndex].status ||
                  (units[floorplanIndex].status && units[floorplanIndex].status === 'HL') ||
                  units[floorplanIndex].status === 'SHL') ? (
                  <ArticleIcon
                    onClick={() => handleSelectedUnit(units[floorplanIndex])}
                    sx={{ color: '#fff', opacity: 0.7, alignSelf: 'center', mr: 2 }}
                  />
                ) : null
              ) : favourites.includes(favourites[floorplanIndex]) &&
                (!favourites[floorplanIndex].status ||
                  (favourites[floorplanIndex].status && favourites[floorplanIndex].status === 'HL') ||
                  favourites[floorplanIndex].status === 'SHL') ? (
                <ArticleIcon
                  onClick={() => handleSelectedUnit(favourites[floorplanIndex])}
                  sx={{ color: '#fff', opacity: 0.7, alignSelf: 'center', mr: 2 }}
                />
              ) : null}
            </Button>,
            <Button sx={{ p: 0, minWidth: 0 }}>
              {floorplanType === 'all' ? (
                favourites.includes(units[floorplanIndex]) ? (
                  <StarIcon
                    onClick={() => handleFavourites(units[floorplanIndex])}
                    sx={{ color: 'yellow', opacity: 0.7, alignSelf: 'center' }}
                  />
                ) : (
                  <StarBorderIcon
                    onClick={() => setFavourites([...favourites, units[floorplanIndex]])}
                    sx={{ opacity: 0.7, alignSelf: 'center', color: '#fff' }}
                  />
                )
              ) : null}
              ,
            </Button>,
          ]}
        />
      )}
    </Box>
  );
};

interface ChildProps {
  units: IUnitMarketing[];
  allUnits: IUnitMarketing[];
  favourites: IUnitMarketing[];
  setFavourites: any;
  setSelectedUnit: any;
  setUnits: Dispatch<SetStateAction<IUnitMarketing[]>>;
}

interface IUnitMarketing extends IUnit {
  marketingFloorPlan: string;
}

export default Floorplans;
