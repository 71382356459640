import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import { numToCurrency, downloadExcel, downloadPdf } from '../../../utils/Functions';
import { selectProject } from '../../../features/project/projectSlice';
import StandardTable from '../../tables/StandardTable';
import { IUnit } from '../../../types/unit';
import { customTierUnits } from '../../../utils/CustomSettings';

const RangeSummary = (props: ChildProps) => {
  const project = useSelector(selectProject);
  const { units, tierType } = props;

  const rangeColumns = useMemo(() => {
    return [
      {
        Header: 'Range',
        accessor: (rowData: any) => {
          return `${numToCurrency.format(rowData.minRange)} to ${numToCurrency.format(rowData.maxRange)}`;
        },
      },
      {
        Header: 'Total Units',
        accessor: (rowData: any) => `${rowData.units} (${((rowData.units / rowData.total) * 100).toFixed(2)}%)`,
      },
      {
        Header: 'Accumulative Unit Count',
        accessor: (rowData: any) => `${rowData.accumulativeUnit} (${((rowData.accumulativeUnit / rowData.total) * 100).toFixed(2)}%)`,
      },
      {
        Header: 'Sold',
        accessor: (rowData: any) => `${rowData.sold} (${((rowData.sold / rowData.total) * 100).toFixed(2)}%)`,
      },
      {
        Header: 'Accumulative Sold',
        accessor: (rowData: any) => `${rowData.accumulativeSold} (${((rowData.accumulativeSold / rowData.total) * 100).toFixed(2)}%)`,
      },
      {
        Header: 'Available',
        accessor: (rowData: any) => `${rowData.available} (${((rowData.available / rowData.total) * 100).toFixed(2)}%)`,
      },
      {
        Header: 'Accumulative Available',
        accessor: (rowData: any) =>
          `${rowData.accumulativeAvailable} (${((rowData.accumulativeAvailable / rowData.total) * 100).toFixed(2)}%)`,
      },
      {
        Header: 'Revenue',
        accessor: (rowData: any) => rowData.revenue,
      },
      {
        Header: 'Accumulative Revenue',
        accessor: (rowData: any) => rowData.accumulativeRevenue,
      },
    ];
  }, []);

  const rangeData = () => {
    if (!units.length) return [];
    let allUnits = units;

    if (tierType) {
      allUnits = units.filter((unit: IUnit) => customTierUnits(project._id, units, tierType).includes(unit.tier));
    }

    let min =
      Math.floor(allUnits.reduce((min: any, unit: any) => (unit.basePrice < min ? unit.basePrice : min), allUnits[0].basePrice) / 100000) *
      100000;
    let max = allUnits.reduce((max: any, unit: any) => (unit.basePrice > max ? unit.basePrice : max), allUnits[0].basePrice);

    let rangeArray: IRange[] = [];

    for (let i = min / 100000; i < max / 100000; i++) {
      let total = allUnits.filter((unit: IUnit) => i * 100000 <= unit.basePrice && unit.basePrice <= i * 100000 + 99999).length;
      let sold = allUnits.filter(
        (unit: IUnit) =>
          i * 100000 <= unit.basePrice && unit.basePrice <= i * 100000 + 99999 && (unit.status === 'C' || unit.status === 'F')
      ).length;
      let available = allUnits.filter(
        (unit: IUnit) => i * 100000 <= unit.basePrice && unit.basePrice <= i * 100000 + 99999 && unit.status !== 'C' && unit.status !== 'F'
      ).length;
      let revenue = allUnits
        .filter((unit: IUnit) => i * 100000 <= unit.basePrice && unit.basePrice <= i * 100000 + 99999)
        .reduce((a: any, b: any) => a + b.basePrice, 0);

      let accumulative = total;
      let accumulativeRevenue = revenue;
      let accumulativeSold = sold;
      let accumulativeAvailable = available;

      for (let j = min / 100000; j < i; j++) {
        let accumulativeAmount = allUnits.filter(
          (unit: IUnit) => j * 100000 <= unit.basePrice && unit.basePrice <= j * 100000 + 99999
        ).length;
        accumulative += accumulativeAmount;

        let sold = allUnits.filter(
          (unit: IUnit) =>
            j * 100000 <= unit.basePrice && unit.basePrice <= j * 100000 + 99999 && (unit.status === 'C' || unit.status === 'F')
        ).length;
        accumulativeSold += sold;

        let available = allUnits.filter(
          (unit: IUnit) =>
            j * 100000 <= unit.basePrice && unit.basePrice <= j * 100000 + 99999 && unit.status !== 'C' && unit.status !== 'F'
        ).length;
        accumulativeAvailable += available;

        let accumulativeRev = allUnits
          .filter((unit: IUnit) => j * 100000 <= unit.basePrice && unit.basePrice <= j * 100000 + 99999)
          .reduce((a: any, b: any) => a + b.basePrice, 0);
        accumulativeRevenue += accumulativeRev;
      }

      let rangeObject = {
        minRange: i * 100000,
        maxRange: i * 100000 + 99999,
        total: allUnits.length,
        units: total,
        accumulativeUnit: accumulative,
        sold: sold,
        accumulativeSold: accumulativeSold,
        available: available,
        accumulativeAvailable: accumulativeAvailable,
        revenue: numToCurrency.format(revenue),
        accumulativeRevenue: numToCurrency.format(accumulativeRevenue),
      };

      rangeArray.push(rangeObject);
    }

    return rangeArray;
  };

  const downloadRange = (type: string, data: any) => {
    let headers = [
      {
        label: 'Range',
        id: 'range',
      },
      {
        label: 'Unit Count',
        id: 'units',
      },
      {
        label: 'Accumulative Unit Count',
        id: 'accumulativeUnit',
      },
      {
        label: 'Sold',
        id: 'sold',
      },
      {
        label: 'Available',
        id: 'available',
      },
      {
        label: 'Revenue',
        id: 'revenue',
      },
      {
        label: 'Accumulative Revenue',
        id: 'accumulativeRevenue',
      },
    ];

    let widths = {
      range: 15,
      units: 15,
      accumulativeUnit: 15,
      sold: 15,
      available: 15,
      revenue: 15,
      accumulativeRevenue: 15,
    };

    let pdfWidths = {
      range: 200,
      units: 200,
      accumulativeUnit: 200,
      sold: 200,
      available: 200,
      revenue: 200,
      accumulativeRevenue: 200,
    };

    let sheetTitle = `${project.name} - Daily Summary`;

    let units = data.map((data: any) => {
      return {
        ...data,
        range: `${numToCurrency.format(data.minRange)} to ${numToCurrency.format(data.maxRange)}`,
      };
    });

    if (type === 'excel') {
      downloadExcel([units], [headers], [], [[widths]], [sheetTitle], sheetTitle);
    } else {
      downloadPdf([units], [headers], [], [pdfWidths], [sheetTitle], sheetTitle);
    }
  };

  return (
    <div>
      <Typography variant={'h5'} gutterBottom>
        Range Summary
      </Typography>
      <StandardTable download={downloadRange} columns={rangeColumns} data={rangeData()} />
    </div>
  );
};

interface ChildProps {
  units: IUnit[]
  tierType: string;
}

interface IRange {
  minRange: number;
  maxRange: number;
  total: number;
  sold: number;
  available: number;
  accumulativeUnit: number;
  revenue: string;
  accumulativeRevenue: string;
}

export default RangeSummary;
