import { useState } from 'react';
import { Box } from '@mui/material';
import { IProject } from '../../types/project';
import MergeTemplateList from './MergeTemplateList';
import Fintrac from './Fintrac';
import BasicTabs from '../common/BasicTabs';
import Dropdown from '../common/formControls/Dropdown';
import { useSelector } from 'react-redux';
import { selectUser } from '../../features/auth/authSlice';
import { Container } from '../../commonStyles';

const Project = () => {
  const user = useSelector(selectUser);
  const [selectedProject, setSelectedProject] = useState<IProject | null>(null);
  const [value, setValue] = useState(0);

  const tabs = [
    {
      label: 'Merge Templates',
      component: <MergeTemplateList id={selectedProject?._id} />,
    },
    {
      label: 'Fintrac',
      component: <Fintrac project={selectedProject!} />,
    },
  ];

  const handleDropdownInput = (event: any) => {
    let selectedProject = user.projectAccess.find((project: any) => {
      return project.project.name === event.target.value;
    });
    setSelectedProject(selectedProject.project);
  };

  const handleProjectNames = () => {
    let projectNames = user.projectAccess.map((project: any) => {
      return project.project.name;
    });
    return projectNames;
  };

  return (
    <Container>
      <Box sx={{ maxWidth: '500px', mb: 2 }}>
        <Dropdown
          id={'dropdown'}
          title={'Select Project'}
          menuList={handleProjectNames()}
          name={'projectType'}
          handleSelect={(e: any) => handleDropdownInput(e)}
          value={selectedProject ? selectedProject?.name : ''}
        />
      </Box>
      {selectedProject ? <BasicTabs tabs={tabs} sub={true} value={value} setValue={setValue} color={'#fff'} /> : null}
    </Container>
  );
};

export default Project;
