import { useState } from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../features/auth/authSlice';
import { accessAllowed } from '../../../features/project/projectHooks';
import { useAppDispatch } from '../../../app/hooks';
import { showErrorSnackbar } from '../../../features/snackbar/snackbarSlice';
import { selectProject } from '../../../features/project/projectSlice';
import '../mediaPrint.css';

const StackInfoCard = (props: ChildProps) => {
  const user = useSelector(selectUser);
  const project = useSelector(selectProject);
  const storeDispatch = useAppDispatch();
  const { unit, handleChange, handleBlur, oldStack } = props;
  const [exposure, setExposure] = useState(false);
  const [unitType, setUnitType] = useState(false);
  const [modelType, setModelType] = useState(false);
  const [bathroom, setBathroom] = useState(false);
  const [size, setSize] = useState(false);
  const [outdoorSize, setOutdoorSize] = useState(false);

  const unitTypes = () => {
    if (unit.unitType === '3BR' || unit.unitType === '3BR(I)' || unit.unitType === '3BR+M' || unit.unitType === '3BR+F') {
      return '#ff8100';
    } else if (unit.unitType === '2BR+D' || unit.unitType === '2BR+D(I)' || unit.unitType === '2BR+M(I)') {
      return '#ffc181';
    } else if (
      unit.unitType === '2BR' ||
      unit.unitType === '2BR(I)' ||
      unit.unitType === '2BR+M' ||
      unit.unitType === '2BR+F' ||
      unit.unitType == 'JR2'
    ) {
      return '#e7b589';
    } else if (unit.unitType === '1BR+D' || unit.unitType === '1BR+D(I)' || unit.unitType === '1BR+M(I)') {
      return '#91f18f';
    } else if (unit.unitType === '1BR' || unit.unitType === '1BR(I)' || unit.unitType === '1BR+M' || unit.unitType === '1BR+F') {
      return '#c9ffc8';
    } else if (unit.unitType === 'Studio' || unit.unitType === 'JR1BR') {
      return '#c4f1fb';
    } else if (unit.unitType === '4BR') {
      return '#35f7c2';
    } else if (unit.unitType === 'TH') {
      return '#35eef7';
    }
  };

  let unitFields = ['exposure', 'unitType', 'modelType', 'bathroom', 'size', 'outdoorSize'];

  const handleDoubleClick = (type: string) => {
    if (!accessAllowed(user, project._id!, 'editUnits')) return storeDispatch(showErrorSnackbar('You currently do not have permissions'));
    if (type === 'exposure') {
      setExposure(!exposure);
    } else if (type === 'unitType') {
      setUnitType(!unitType);
    } else if (type === 'modelType') {
      setModelType(!modelType);
    } else if (type === 'bathroom') {
      setBathroom(!bathroom);
    } else if (type === 'size') {
      setSize(!size);
    } else if (type === 'outdoorSize') {
      setOutdoorSize(!outdoorSize);
    }
  };

  const blur = (type: any) => {
    setExposure(false);
    setUnitType(false);
    setModelType(false);
    setBathroom(false);
    setSize(false);
    setOutdoorSize(false);
    handleBlur(unit._id, type);
  };

  const checkUnit = (unitField: string) => {
    if (unitField === 'exposure' && !exposure) {
      return true;
    } else if (unitField === 'unitType' && !unitType) {
      return true;
    } else if (unitField === 'modelType' && !modelType) {
      return true;
    } else if (unitField === 'bathroom' && !bathroom) {
      return true;
    } else if (unitField === 'size' && !size) {
      return true;
    } else if (unitField === 'outdoorSize' && !outdoorSize) {
      return true;
    }
  };

  return (
    <div style={{ height: '100%' }}>
      <Box
        style={{ backgroundColor: `${unitTypes()}`, textAlign: 'center' }}
        className="suiteCard"
        sx={{
          color: '#00142a',
          boxShadow: '0px 0px 1px 1px grey',
          height: '100%',
          fontSize: '12px',
          backgroundColor: Object.keys(unit).length !== 0 ? '#fff' : 'transparent',
          border: Object.keys(unit).length !== 0 ? '1px solid #000' : 'none',
          display: Object.keys(unit).length !== 0 ? 'block' : 'none',
        }}
      >
        <Box
          sx={{
            backgroundColor: '#000',
            color: '#fff',
          }}
        >
          {unit.unit}
        </Box>
        {unitFields.map((unitField: string, index: number) => {
          return (
            <div key={index} onDoubleClick={() => handleDoubleClick(unitField)}>
              {checkUnit(unitField) ? (
                <div>
                  <div style={{ color: oldStack && oldStack[unitField] !== unit[unitField] ? 'red' : '#000' }}>
                    {oldStack && oldStack[unitField] !== unit[unitField] ? (
                      <strong>
                        <span
                          style={{
                            textDecoration: 'line-through',
                            fontSize: '10px',
                            color: '#000',
                          }}
                        >
                          {oldStack[unitField]}
                        </span>
                        {unit[unitField]}
                      </strong>
                    ) : (
                      <div>{unit[unitField]}</div>
                    )}
                  </div>
                </div>
              ) : (
                <input
                  key={unit._id}
                  name={`${unitField}`}
                  style={{
                    width: '100%',
                    height: '16px',
                  }}
                  type={`${unitField === 'bathroom' || unitField === 'size' || unitField === 'outdoorSize' ? 'number' : 'text'}`}
                  value={unit[unitField]}
                  onChange={(e) => handleChange(e, unit._id, unitField)}
                  onBlur={() => blur(unitField)}
                />
              )}
            </div>
          );
        })}
        <div>{parseFloat(unit.outdoorSize) + parseFloat(unit.size)}</div>
      </Box>
    </div>
  );
};

interface ChildProps {
  unit: any;
  handleChange: (e: any, id: string, type: string) => void;
  handleBlur: any;
  oldStack: any;
}

export default StackInfoCard;
