import { useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { gql, useMutation } from '@apollo/client';
import { Box, Button, TextField } from '@mui/material';

// import { ActivityContext } from '../../../context/ActivityContext';
import { UnitContext } from '../../../../context/UnitContext';
import { IDeal, IDealOption } from '../../../../types/CreateDealForm';
import { numToCurrency, convertAllDates, dealContent } from '../../../../utils/Functions';
import { FlexBetween, FlexEnd } from '../../../../commonStyles';
import { useAppDispatch } from '../../../../app/hooks';
import { showSuccessSnackbar } from '../../../../features/snackbar/snackbarSlice';
import { useCreateActivity } from '../../../../features/activity/activityHooks';
import { IPurchaserInfo } from '../../../../types/CreateDealForm';
import DealSummaryForm from './DealSummaryForm';
import { selectUser } from '../../../../features/auth/authSlice';
import { selectProject } from '../../../../features/project/projectSlice';
import NewDeal from './NewDeal';
import { accessAllowed } from '../../../../features/project/projectHooks';
import VoidDialog from './VoidDialog';

const DealSummary = () => {
  const user = useSelector(selectUser);
  const project = useSelector(selectProject);
  const storeDispatch = useAppDispatch();
  const createActivity = useCreateActivity();
  const { filteredDeal, setFilteredDeal, setUnit, assignment, getRealtors } = useContext(UnitContext);

  const [oldDeal, setOldDeal] = useState<IDeal>(filteredDeal);
  const [open, setOpen] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [dialogType, setDialogType] = useState<string>('');
  const [dealPrice, setDealPrice] = useState<string>(filteredDeal && filteredDeal.basePrice ? filteredDeal.basePrice.toString() : '');

  const [updateDealSales] = useMutation(UPDATEDEALINFO, {
    onCompleted: (data) => {
      if (data.dealUpdateById.record.basePrice !== filteredDeal.basePrice) {
        updateUnit({
          variables: {
            _id: data.dealUpdateById.record.unit._id,
            record: {
              basePrice: data.dealUpdateById.record.basePrice,
            },
          },
        });
      }
      storeDispatch(showSuccessSnackbar('Deal Information Updated'));
      setOpen(false);
      createActivity({
        project: project._id,
        user: user._id,
        deal: filteredDeal._id,
        title: `Deal Information`,
        content: dealContent(data.dealUpdateById.record, oldDeal),
      });
      setFilteredDeal({
        ...filteredDeal,
        signDate: filteredDeal.signDate,
        executeDate: filteredDeal.executeDate,
        firmDate: filteredDeal.firmDate,
        basePrice: parseInt(dealPrice, 10),
      });
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [updateUnit] = useMutation(UPDATEUNIT, {
    onCompleted: (data) => {
      setUnit(data.unitUpdateById.record);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const handleDateChange = (newValue: any, type: string) => {
    if (type === 'signDate') {
      setFilteredDeal({
        ...filteredDeal,
        signDate: newValue,
      });
    } else if (type === 'executeDate') {
      setFilteredDeal({
        ...filteredDeal,
        executeDate: newValue,
      });
    } else if (type === 'firmDate') {
      setFilteredDeal({
        ...filteredDeal,
        firmDate: newValue,
      });
    }
  };

  const saveDealInfo = () => {
    updateDealSales({
      variables: {
        _id: filteredDeal._id,
        record: {
          tags: filteredDeal.tags,
          salesRep: filteredDeal.salesRep ? filteredDeal.salesRep._id : null,
          signDate: filteredDeal.signDate,
          executeDate: filteredDeal.executeDate,
          firmDate: filteredDeal.firmDate,
          basePrice: dealPrice ? parseInt(dealPrice, 10) : filteredDeal.basePrice,
        },
      },
    });
  };

  const getTotalPrice = () => {
    let total = filteredDeal.options.reduce((a: any, b: any) => {
      return a + b.amount;
    }, parseInt(dealPrice ? dealPrice : filteredDeal.basePrice, 10));
    return total;
  };

  return (
    <Box>
      {!filteredDeal ? (
        <NewDeal />
      ) : (
        <Box>
          <VoidDialog dialogType={dialogType} setDialogType={setDialogType} dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} />
          {assignment ? (
            <Box sx={{ mt: 2 }}>
              <Box>
                <strong>
                  Assignees:{' '}
                  {assignment.purchasers.map((purchaser: IPurchaserInfo) => `${purchaser.firstName} ${purchaser.lastName}`).join(', ')}
                </strong>
              </Box>
              <Box>
                <strong>Assignment Date: {assignment.signDate ? convertAllDates(assignment.signDate, 'PP') : 'No Date'}</strong>
              </Box>
            </Box>
          ) : null}
          {!filteredDeal.documents.length ||
          (filteredDeal.documents[0].type === 'APS' &&
            filteredDeal.documents[0].status === 'Completed' &&
            !filteredDeal.documents[0].dsEnvelopeId &&
            open) ? (
            <TextField
              sx={{ mt: 2 }}
              title={'Deal Base Price'}
              name={'basePrice'}
              type="number"
              fullWidth
              value={dealPrice}
              label={'Deal Base Price'}
              required
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDealPrice(e.target.value)}
            />
          ) : (
            <p>
              Deal Price: <strong>{numToCurrency.format(filteredDeal.basePrice)}</strong>
            </p>
          )}
          {filteredDeal &&
            filteredDeal.options.map((option: IDealOption, index: number) => {
              return (
                <Box key={index}>
                  <strong>
                    {option.name} ({option.purchaseAmount}x) - {numToCurrency.format(option.amount!)}
                  </strong>
                </Box>
              );
            })}
          <p>
            Total Price: <strong>{numToCurrency.format(getTotalPrice())}</strong>
          </p>
          {!open ? (
            <div>
              {filteredDeal.preparedDate ? (
                <p>
                  Date Prepared: <strong>{convertAllDates(filteredDeal.preparedDate, 'PPpp')}</strong>
                </p>
              ) : (
                <p>
                  Date Prepared: <strong>{convertAllDates(filteredDeal.createdAt, 'PPpp')}</strong>
                </p>
              )}
              <p>
                Date Signed:{' '}
                <strong>{filteredDeal.signDate ? convertAllDates(filteredDeal.signDate, 'PPpp') : 'Deal has not been signed'}</strong>
              </p>
              <p>
                Date Executed:{' '}
                <strong>
                  {filteredDeal.executeDate ? convertAllDates(filteredDeal.executeDate, 'PPpp') : 'Deal has not been executed'}
                </strong>
              </p>
              <p>
                Date Firm:{' '}
                <strong>{filteredDeal.firmDate ? convertAllDates(filteredDeal.firmDate, 'PPpp') : 'No current firm date'}</strong>
              </p>
              <p>
                Sales Representative: <strong>{filteredDeal.salesRep ? filteredDeal.salesRep.fullName : 'No Sales Rep'}</strong>
              </p>
              <p>
                Tags: <strong>{filteredDeal.tags.length ? filteredDeal.tags.join(', ') : 'No Tags'}</strong>
              </p>
            </div>
          ) : (
            <DealSummaryForm handleDateChange={handleDateChange} />
          )}
          {filteredDeal.cancelled.dateCancelled ? (
            <Box sx={{ mt: 2 }}>
              <div>
                <strong>This deal has been collapsed</strong>
              </div>
              <div>
                <strong>Cancellation Reason: {filteredDeal.cancelled.reason ? filteredDeal.cancelled.reason : 'No Reason'}</strong>
              </div>
              <div>
                <strong>
                  Cancelled Date:{' '}
                  {filteredDeal.cancelled.dateCancelled ? convertAllDates(filteredDeal.cancelled.dateCancelled, 'PP') : 'No Date'}
                </strong>
              </div>
            </Box>
          ) : null}
          {filteredDeal.rescission.dateRescinded ? (
            <Box
              sx={{
                mt: 2,
              }}
            >
              <div>
                <strong>This deal has been rescinded</strong>
              </div>
              <div>
                <strong>Rescinded Reason: {filteredDeal.rescission.reason ? filteredDeal.rescission.reason : 'No Reason'}</strong>
              </div>
              <div>
                <strong>
                  Rescinded Date:{' '}
                  {filteredDeal.rescission.dateRescinded ? convertAllDates(filteredDeal.rescission.dateRescinded, 'PP') : 'No Date'}
                </strong>
              </div>
            </Box>
          ) : null}
          {filteredDeal && !filteredDeal.rescission.dateRescinded && !filteredDeal.cancelled.dateCancelled ? (
            <FlexEnd sx={{ my: 3 }}>
              <FlexBetween sx={{ flexDirection: 'row-reverse' }} onClick={() => saveDealInfo()}>
                <Button sx={{ mr: 2 }} color="success" variant="contained">
                  Save
                </Button>
              </FlexBetween>
              <div>
                <Button onClick={() => setOpen(!open)} color="primary" variant="contained">
                  {!open ? 'Edit' : 'Close'}
                </Button>
              </div>
            </FlexEnd>
          ) : null}
          {filteredDeal && !filteredDeal.rescission.dateRescinded && !filteredDeal.cancelled.dateCancelled ? (
            accessAllowed(user, project._id!, 'editDeals') ? (
              <FlexEnd sx={{ mt: 2 }}>
                {filteredDeal.unit.status === 'C' || filteredDeal.unit.status === 'F' ? (
                  <Button
                    color="error"
                    onClick={() => {
                      getRealtors();
                      setDialogType('rescind');
                      setDialogOpen(true);
                    }}
                    variant="contained"
                  >
                    Rescind
                  </Button>
                ) : (
                  <Button
                    color="error"
                    onClick={() => {
                      getRealtors();
                      setDialogType('cancel');
                      setDialogOpen(true);
                    }}
                    variant="contained"
                  >
                    Void
                  </Button>
                )}
              </FlexEnd>
            ) : null
          ) : null}
        </Box>
      )}
    </Box>
  );
};

const UPDATEUNIT = gql`
  mutation unitUpdateById($_id: MongoID!, $record: UpdateByIdUnitInput!) {
    unitUpdateById(_id: $_id, record: $record) {
      record {
        _id
        suite
        unit
        level
        modelType
        basePrice
        size
        status
        getUrl
        putUrl
        bathroom
        rental
        exposure
        status
        outdoorSize
        outdoorType
        unitType
        originalPrice
        basePrice
        custom
        tier
        type
        firstTentativeOccupancy
        finalTentativeOccupancy
        firmOccupancy
        outsideOccupancy
        allocation {
          _id
          fullName
        }
        history {
          type
          description
          timestamp
          user {
            _id
            fullName
          }
          _id
        }
      }
    }
  }
`;

const UPDATEDEALINFO = gql`
  mutation dealUpdateById($_id: MongoID!, $record: UpdateByIdDealInput!) {
    dealUpdateById(_id: $_id, record: $record) {
      record {
        unit {
          _id
        }
        salesRep {
          _id
          fullName
        }
        preparedDate
        signDate
        executeDate
        firmDate
        tags
        basePrice
      }
    }
  }
`;

export default DealSummary;
