import React, { forwardRef } from 'react';
import { Box } from '@mui/material';
import classNames from 'classnames';
import type { DraggableSyntheticListeners } from '@dnd-kit/core';
import type { Transform } from '@dnd-kit/utilities';
import styles from './Draggable.module.css';

export const Draggable = forwardRef<HTMLButtonElement, Props>(function Draggable(
  { dragOverlay, dragging, label, listeners, transform, style, buttonStyle, mergeItem, reset, showWrap, ...props },
  ref
) {
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        transition: 'transform 250ms ease',
      }}
      className={classNames(styles.Draggable, dragOverlay && styles.dragOverlay, dragging && styles.dragging)}
      style={
        {
          ...style,
          '--translate-x': `${transform?.x ?? 0}px`,
          '--translate-y': `${transform?.y ?? 0}px`,
        } as React.CSSProperties
      }
    >
      <Box
        sx={{
          zIndex: 10,
          display: 'inline-block',
          alignItems: 'center',
          justifyContent: 'center',
          flexShrink: 1,
          appearance: 'none',
          outline: 'none',
          border: '0',
          borderRadius: '5px',
          transform: 'translate3d(var(--translate-x, 0), var(--translate-y, 0), 0) scale(var(--scale, 1))',
          transition: 'box-shadow 300ms ease',
          cursor: 'pointer',
          fontSize: '9px',
          color: reset ? '#000' : 'red',
          whiteSpace: 'pre-wrap',
        }}
        {...props}
        aria-label="Draggable"
        data-cypress="draggable-item"
        {...listeners}
        tabIndex={undefined}
        ref={ref}
        style={buttonStyle}
        onDoubleClick={() => showWrap(mergeItem)}
      >
        {mergeItem.name ? mergeItem.name : mergeItem.key}
      </Box>
    </Box>
  );
});

export enum Axis {
  All,
  Vertical,
  Horizontal,
}

interface Props {
  axis?: Axis;
  dragOverlay?: boolean;
  dragging?: boolean;
  handle?: boolean;
  label?: string;
  listeners?: DraggableSyntheticListeners;
  style?: React.CSSProperties;
  buttonStyle?: React.CSSProperties;
  transform?: Transform | null;
  mergeItem: any;
  showWrap: any;
  reset: boolean;
}
