import { withRouter } from '../../utils/WithRouter';
import { Paper, Button, Grid, Box } from '@mui/material';

import DoughnutChart from './DoughnutChart';
import ExecutionTable from './ExecutionTable';
// import OptionsComponent from './OptionsComponent';

const DashboardComponent = (props: ChildProps) => {
  const { statusData } = props;

  return (
    <Box>
      <Grid sx={{ p: 2 }} container>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Paper
            sx={{
              position: 'relative',
              height: '600px',
            }}
            elevation={5}
          >
            {statusData ? <DoughnutChart statusData={statusData} /> : null}
          </Paper>
        </Grid>
        <Grid
          sx={{
            height: '100%',
            pl: {
              sm: 0,
              lg: 2,
            },
            mt: {
              lg: 0,
              sm: 2,
            },
          }}
          item
          xs={12}
          sm={12}
          md={12}
          lg={6}
          xl={6}
        >
          <Paper
            sx={{
              height: '600px',
              position: 'relative',
            }}
            elevation={5}
          >
            <ExecutionTable />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

interface ChildProps {
  statusData: any;
}

export default withRouter(DashboardComponent);
