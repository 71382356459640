import { useMemo } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { Box, Typography, Grid } from '@mui/material';
import Dropzone, { useDropzone } from 'react-dropzone';
import { useAppDispatch } from '../../app/hooks';
import { showErrorSnackbar } from '../../features/snackbar/snackbarSlice';
import { IUnitMarketing } from './FloorplanContainer';
import { IIdentification, IPurchaserInfo } from '../../types/CreateDealForm';
import { baseStyle, activeStyle, acceptStyle, rejectStyle } from '../../utils/Constants';
import PdfCard from '../common/PdfCard';

const Identification = (props: ChildProps) => {
  const storeDispatch = useAppDispatch();
  const { purchaser, index, dispatch } = props;

  const { isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: 'image/jpeg, image/png, application/pdf',
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const [getOcrText, { loading }] = useLazyQuery(GETOCRTEXT, {
    onCompleted: (data) => {
      console.log(data, 'data');
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const handleDrop = (acceptedFiles: any) => {
    if (acceptedFiles.length === 0) {
      storeDispatch(showErrorSnackbar('This file type is not allowed'));
      return;
    }
    const file = acceptedFiles[0];
    const fileReader = new FileReader();
    if (file) {
      fileReader.readAsDataURL(file);
    }
    fileReader.onloadend = async () => {
      // getOcrText({ variables: { file: fileReader.result }})
      dispatch({
        type: 'UPDATE',
        payload: {
          index: index,
          field: 'identifications',
          value: [
            ...purchaser.identifications,
            {
              file: file,
              getUrl: fileReader.result,
              name: `id${purchaser.identifications.length}${file.name}`,
            },
          ],
        },
      });
    };
  };

  const deleteImage = (id: number, title: string, numIndex: number) => {
    let identifications = purchaser.identifications.filter((identification: IIdentification, index: number) => index !== numIndex);

    dispatch({
      type: 'UPDATE',
      payload: {
        index: index,
        field: 'identifications',
        value: identifications,
      },
    });
  };

  return (
    <Box sx={{ p: 2 }}>
      <Box>
        <Typography variant={'h5'} gutterBottom>
          <strong>Identification</strong>
        </Typography>
        <Dropzone onDrop={(files) => handleDrop(files)} accept="image/jpg, image/jpeg, image/png, application/pdf">
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <p>Upload your Identification</p>
              </div>
            </section>
          )}
        </Dropzone>
        {purchaser.identifications.length ? (
          <Grid container spacing={2}>
            {purchaser.identifications.map((identification: IIdentification, index: number) => {
              return (
                <Grid key={index} item xs={6} sm={4} md={3}>
                  <PdfCard
                    file={identification.getUrl}
                    title={identification.name}
                    handleDelete={deleteImage}
                    download={true}
                    index={index}
                  />
                </Grid>
              );
            })}
          </Grid>
        ) : null}
      </Box>
    </Box>
  );
};

interface ChildProps {
  unit: IUnitMarketing;
  purchaser: IPurchaserInfo;
  index: number;
  dispatch: any;
}

const GETOCRTEXT = gql`
  query getOcrText($file: String!) {
    getOcrText(file: $file)
  }
`;

export default Identification;
