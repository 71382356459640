import { useState, useMemo, useEffect } from 'react';
import { gql, useLazyQuery, useQuery, useMutation } from '@apollo/client';
import { Box, Button, AppBar, Tabs, Tab, ToggleButton, ToggleButtonGroup, Checkbox } from '@mui/material';
import { useParams, useSearchParams, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import StandardTable from '../tables/StandardTable';
import { convertAllDates } from '../../utils/Functions';
import { selectUser } from '../../features/auth/authSlice';
import LoadingWrapper from '../common/LoadingWrapper';
import { IProject } from '../../types/project';
import { IUser } from '../../types/user';
import { checklist } from '../../utils/Constants';
import { useAppDispatch } from '../../app/hooks';
import { showSuccessSnackbar, showErrorSnackbar } from '../../features/snackbar/snackbarSlice';
import Dropdown from '../common/formControls/Dropdown';

const ExecutionTable = () => {
  const storeDispatch = useAppDispatch();
  const user = useSelector(selectUser);

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [count, setCount] = useState<number>(0);
  const [documents, setDocuments] = useState([]);
  const [checklists, setChecklists] = useState<IChecklist[]>([]);
  const [selectedChecklist, setSelectedChecklist] = useState<IChecklist | null>(null);
  const [redirectLoading, setRedirectLoading] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const [value, setValue] = useState<string>(user.type === 'Developer' ? 'Verified' : 'Checklist');
  const { projectid } = useParams();

  const { loading: checklistLoading } = useQuery(GETCHECKLIST, {
    skip: user.type === 'Developer' || !projectid,
    variables: { filter: { project: projectid } },
    onCompleted: (data) => {
      let selected = data.checklistMany.find((checklist: IChecklist) => {
        return (
          new Date(checklist?.createdAt!).getMonth() === new Date().getMonth() &&
          new Date(checklist?.createdAt!).getDate() === new Date().getDate()
        );
      });
      let newChecklist = [
        ...data.checklistMany,
        {
          checklist: checklist,
          createdAt: new Date(),
        },
      ];
      if (selected) {
        newChecklist = data.checklistMany;
      }
      setChecklists(newChecklist);
      setSelectedChecklist(
        selected
          ? selected
          : {
              checklist: checklist,
              createdAt: new Date(),
            }
      );
    },
  });

  const [createChecklist] = useMutation(CREATECHECKLIST, {
    onCompleted: (data) => {
      setSelectedChecklist(data.checklistCreateOne.record);
      storeDispatch(showSuccessSnackbar('Completed Step!'));
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar('Error completing step'));
      console.log(err, 'err');
    },
  });

  const [updateChecklist] = useMutation(UPDATECHECKLIST, {
    onCompleted: (data) => {
      setSelectedChecklist(data.checklistUpdateById.record);

      storeDispatch(showSuccessSnackbar('Completed Step!'));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [getDocuments, { loading }] = useLazyQuery(GETDOCUMENTS, {
    onCompleted: (data) => {
      let documentId = searchParams.get('document');
      let documents = data.getDocuments.documents
        .filter((document: any) => document._id !== documentId)
        .sort((a: any, b: any) => {
          if (a.executor === user.email) {
            return -1;
          }
        });
      // Remove Dov's Documents
      documents = documents.filter(
        (document: any) => document.deal.unit._id !== '62ffa0bfec7c0557a0251c7f' && document.deal.unit._id !== '62ffa0b9ec7c0557a0251bf5'
      );
      setDocuments(documents);
      setCount(data.getDocuments.count);
    },
  });

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setPageNumber(1);
    setValue(newValue);
  };

  const [getDocumentUrl] = useLazyQuery(GETDOCUMENTURL, {
    onCompleted: (data) => {
      if (data) {
        window.location.href = data.getDocumentUrl;
      }
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  useEffect(() => {
    if (projectid && value !== 'Checklist') {
      getDocuments({
        variables: { project: projectid, type: value, page: pageNumber, perPage: 15, search: '' },
      });
    }
  }, [pageNumber, projectid, value]);

  const handleGlobalFilterValue = (textValue: string) => {
    setPageNumber(1);
    getDocuments({
      variables: { project: projectid, type: value, page: pageNumber, perPage: 15, search: textValue },
    });
  };

  const signedColumns = useMemo(() => {
    return [
      {
        Header: 'Suite',
        accessor: (rowData: any) => {
          return (
            <Link
              rel="noopener noreferrer"
              target="_blank"
              style={{ textDecoration: 'none', color: 'inherit' }}
              to={`/${projectid}/dashboard/${rowData.deal.unit._id}`}
            >
              <strong style={{ cursor: 'pointer' }}>{rowData.deal.unit.suite}</strong>
            </Link>
          );
        },
      },
      {
        Header: 'Name',
        accessor: (rowData: any) => {
          return rowData.name;
        },
      },
      {
        Header: 'Updated At',
        accessor: (rowData: any) => {
          return convertAllDates(rowData.updatedAt, 'PPpp');
        },
      },
    ];
  }, []);

  const columns = useMemo(() => {
    return [
      {
        Header: 'Suite',
        accessor: (rowData: any) => {
          return (
            <Link
              rel="noopener noreferrer"
              target="_blank"
              style={{ textDecoration: 'none', color: 'inherit' }}
              to={`/${projectid}/dashboard/${rowData.deal.unit._id}`}
            >
              <strong style={{ cursor: 'pointer' }}>{rowData.deal.unit.suite}</strong>
            </Link>
          );
        },
      },
      {
        Header: 'Name',
        accessor: (rowData: any) => {
          return rowData.name;
        },
      },
      {
        Header: 'Executor',
        accessor: (rowData: any) => {
          return rowData.executor;
        },
      },
      {
        Header: 'Updated At',
        accessor: (rowData: any) => {
          return convertAllDates(rowData.updatedAt, 'PPpp');
        },
      },
      {
        Header: 'Execute',
        accessor: (rowData: any) => {
          if (rowData.executor === user?.email) {
            return (
              <Button onClick={() => handleOnCellClick(rowData)} variant="contained" color="primary">
                Execute
              </Button>
            );
          } else return null;
        },
      },
    ];
  }, []);

  const checklistColumns = useMemo(() => {
    return [
      {
        Header: ' ',
        accessor: (rowData: IItem, index: number) => {
          return (
            <Box>
              <Checkbox
                disabled={
                  new Date(selectedChecklist?.createdAt!).getMonth() === new Date().getMonth() &&
                  new Date(selectedChecklist?.createdAt!).getDate() === new Date().getDate()
                    ? false
                    : true
                }
                onChange={(e) => handleCompleteStep(rowData, index)}
                checked={rowData.completed ? true : false}
              />
            </Box>
          );
        },
        disableSortBy: true,
      },
      {
        Header: 'Name',
        accessor: (rowData: any) => <strong>{rowData.name}</strong>,
        disableSortBy: true,
      },
      {
        Header: 'Date',
        accessor: (rowData: any) => (rowData.completed ? convertAllDates(rowData.completed, 'PPpp') : ''),
        disableSortBy: true,
      },
      {
        Header: 'User',
        accessor: (rowData: any) => (rowData.user ? rowData.user.fullName : ''),
        disableSortBy: true,
      },
    ];
  }, [selectedChecklist]);

  const handleChecklistType = (event: any) => {
    if (event.target.value !== null) {
      let selected = checklists.find((checklist: IChecklist) => {
        return (
          new Date(checklist?.createdAt!).getMonth() === new Date(event.target.value).getMonth() &&
          new Date(checklist?.createdAt!).getDate() === new Date(event.target.value).getDate()
        );
      });
      setSelectedChecklist(selected!);
    }
  };

  const handleCompleteStep = async (data: IItem, index: number) => {
    if (!selectedChecklist?._id) {
      let newChecklistItems = checklist.map((checklist: IItem) => {
        if (data.name === checklist.name) {
          return {
            name: checklist.name,
            completed: new Date(),
            user: user._id,
          };
        } else return checklist;
      });

      createChecklist({
        variables: {
          record: {
            project: projectid,
            checklist: newChecklistItems,
          },
        },
      });
    } else {
      let newChecklistItems = selectedChecklist.checklist.map((checklist: IItem) => {
        if (data.name === checklist.name) {
          return {
            name: checklist.name,
            completed: data.completed ? null : new Date(),
            user: data.user ? null : user._id,
          };
        } else
          return {
            ...checklist,
            user: checklist.user ? checklist.user._id : null,
          };
      });

      updateChecklist({
        variables: {
          _id: selectedChecklist._id,
          record: {
            checklist: newChecklistItems,
          },
        },
      });
    }
  };

  const handleOnCellClick = (params: any) => {
    if (params.executor === user?.email) {
      setRedirectLoading(true);
      getDocumentUrl({
        variables: {
          project: projectid,
          executor: params.executor,
          unit: params.deal.unit._id,
          deal: params.deal._id,
          envelope: params.dsEnvelopeId,
        },
      });
    }
  };

  return redirectLoading ? (
    <LoadingWrapper title="Redirecting to Docusign..." modal={false} />
  ) : (
    <>
      <Box
        sx={{
          textAlign: 'center',
        }}
      >
        <AppBar position="static" color="transparent" elevation={0}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            {user.type !== 'Developer' ? (
              <Tab
                sx={{
                  fontSize: {
                    sm: '12px',
                    xs: '10px',
                  },
                }}
                label="Checklist"
                value="Checklist"
              />
            ) : null}
            <Tab
              sx={{
                fontSize: {
                  sm: '12px',
                  xs: '10px',
                },
              }}
              label="To Be Executed"
              value="Verified"
            />
            {user.type !== 'Developer' ? (
              <Tab
                sx={{
                  fontSize: {
                    sm: '12px',
                    xs: '10px',
                  },
                }}
                label="Signed Documents"
                value="Signed"
              />
            ) : null}
            {user.type !== 'Developer' ? (
              <Tab
                sx={{
                  fontSize: {
                    sm: '12px',
                    xs: '10px',
                  },
                }}
                label="Sent Documents"
                value="Sent"
              />
            ) : null}
          </Tabs>
        </AppBar>
      </Box>
      <div style={{ height: '500px', padding: '8px 16px' }}>
        {value === 'Checklist' ? (
          <Box style={{ height: '450px' }}>
            {checklists.length ? (
              <Box sx={{ width: '200px', my: 1 }}>
                <Dropdown
                  id={'dropdown'}
                  title={'Select Date'}
                  menuList={checklists.map((checklist: IChecklist) => convertAllDates(checklist.createdAt, 'PP'))}
                  name={'date'}
                  handleSelect={(e: any) => handleChecklistType(e)}
                  value={selectedChecklist ? convertAllDates(selectedChecklist?.createdAt, 'PP') : convertAllDates(new Date(), 'PP')}
                />
              </Box>
            ) : null}
            <StandardTable
              data={selectedChecklist ? selectedChecklist.checklist : checklist}
              columns={checklistColumns}
              loading={checklistLoading}
            />
          </Box>
        ) : null}
        {value === 'Verified' ? (
          <Box sx={{ height: '500px' }}>
            <StandardTable
              data={documents}
              columns={columns}
              loading={loading}
              handleGlobalFilterValue={handleGlobalFilterValue}
              count={count}
            />
            <Box sx={{ textAlign: 'center' }}>
              <Button disabled={pageNumber === 1} onClick={() => setPageNumber(pageNumber - 1)}>
                {'<'}
              </Button>
              <span>{pageNumber}</span>
              <Button disabled={pageNumber === Math.ceil(count / 15)} onClick={() => setPageNumber(pageNumber + 1)}>
                {'>'}
              </Button>
            </Box>
          </Box>
        ) : null}
        {value === 'Sent' || value === 'Signed' ? (
          <Box sx={{ height: '500px' }}>
            <StandardTable
              data={documents}
              columns={signedColumns}
              loading={loading}
              count={count}
              handleGlobalFilterValue={handleGlobalFilterValue}
            />
            <Box sx={{ textAlign: 'center' }}>
              <Button disabled={pageNumber === 1} onClick={() => setPageNumber(pageNumber - 1)}>
                {'<'}
              </Button>
              <span>{pageNumber}</span>
              <Button disabled={pageNumber === Math.ceil(count / 15)} onClick={() => setPageNumber(pageNumber + 1)}>
                {'>'}
              </Button>
            </Box>
          </Box>
        ) : null}
      </div>
    </>
  );
};

export interface IChecklist {
  _id: string;
  project: IProject;
  checklist: IItem[];
  createdAt: Date;
  updatedAt: Date;
}

export interface IItem {
  name: string;
  completed: Date | null;
  user: IUser | null;
}

const GETCHECKLIST = gql`
  query checklistMany($filter: FilterFindManyChecklistInput) {
    checklistMany(filter: $filter) {
      _id
      project {
        _id
      }
      checklist {
        completed
        name
        user {
          _id
          fullName
        }
      }
      createdAt
    }
  }
`;

const CREATECHECKLIST = gql`
  mutation checklistCreateOne($record: CreateOneChecklistInput!) {
    checklistCreateOne(record: $record) {
      record {
        _id
        project {
          _id
        }
        checklist {
          completed
          name
          user {
            _id
            fullName
          }
        }
        createdAt
      }
    }
  }
`;

const UPDATECHECKLIST = gql`
  mutation checklistUpdateById($_id: MongoID!, $record: UpdateByIdChecklistInput!) {
    checklistUpdateById(_id: $_id, record: $record) {
      record {
        _id
        project {
          _id
        }
        checklist {
          completed
          name
          user {
            _id
            fullName
          }
        }
        createdAt
      }
    }
  }
`;

const GETDOCUMENTURL = gql`
  query getDocumentUrl($project: MongoID!, $executor: String!, $unit: MongoID!, $deal: MongoID!, $envelope: String!) {
    getDocumentUrl(project: $project, executor: $executor, unit: $unit, deal: $deal, envelope: $envelope)
  }
`;

const GETDOCUMENTS = gql`
  query getDocuments($project: MongoID!, $type: String!, $page: Float!, $perPage: Float!, $search: String) {
    getDocuments(project: $project, type: $type, page: $page, perPage: $perPage, search: $search) {
      documents {
        _id
        name
        status
        executor
        dsEnvelopeId
        updatedAt
        deal {
          _id
          unit {
            _id
            suite
            status
          }
          createdAt
        }
        updatedAt
      }
      count
    }
  }
`;

export default ExecutionTable;
