import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';

import { Flex } from '../../commonStyles';

const AllowedUnits = (props: ChildProps) => {
  const { option, handleDeleteUnit, optionsIndex, title } = props;

  return (
    <Flex sx={{ my: 1, flexWrap: 'wrap' }}>
      {option.allowedUnits.map((unit: string, index: number) => {
        return (
          <Box
            key={index}
            sx={{
              padding: '10px',
              border: '1px solid #00142a',
              borderRadius: '8px',
              display: 'flex',
              flexWrap: 'wrap',
              mr: 1,
              mt: 2,
            }}
          >
            <span>{unit}</span>
            <CloseIcon sx={{ cursor: 'pointer', ml: 1 }} color="secondary" onClick={() => handleDeleteUnit(optionsIndex, index, title)} />
          </Box>
        );
      })}
    </Flex>
  );
};

interface ChildProps {
  title?: string;
  optionsIndex: number;
  option: any;
  handleDeleteUnit: (optionsIndex: any, index: any, type: string | undefined) => void;
}

export default AllowedUnits;
