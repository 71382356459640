import { useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { gql, useLazyQuery } from '@apollo/client';
import { Box, Drawer, Button } from '@mui/material';

import { theme } from '../../theme';
import { PriceGridContext } from '../../context/PriceGridContext';
import { useAppDispatch } from '../../app/hooks';
import { showErrorSnackbar } from '../../features/snackbar/snackbarSlice';
import { IUnit } from '../../types/unit';
import { selectProject } from '../../features/project/projectSlice';
import { accessAllowed } from '../../features/project/projectHooks';
import { downloadImages } from '../../utils/Functions';
import { selectUser } from '../../features/auth/authSlice';
import FilterMenu from './popups/FilterMenu';
import { handleModal } from '../../features/modal/modalSlice';

const GridHeader = (props: any) => {
  const storeDispatch = useAppDispatch();
  const project = useSelector(selectProject);
  const user = useSelector(selectUser);
  const { setShowStack, download, handleBack, setLegend, legend } = props;
  const {
    units,
    selectingUnits,
    setSelectingUnits,
    selectedUnits,
    setSelectedUnits,
    filteredUnits,
    setFilteredUnits,
    noUnits,
    setNoUnits,
    selectedPriceList,
    setModalType,
    draft,
  } = useContext(PriceGridContext);

  const [open, setOpen] = useState<boolean>(false);

  const [marketingFloorPlans] = useLazyQuery(GETMARKETINGFLOORPLANS, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data.getMarketingFloorPlans.length > 0) {
        downloadImages(data.getMarketingFloorPlans, `${project.name} Floorplans`, project._id!, project.email, project.name);
      } else {
        storeDispatch(showErrorSnackbar('No Floor Plan to Share'));
      }
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const getMarketingFloorPlans = (unitIds: IUnit[] = []) => {
    let allSelected = unitIds.length ? unitIds : selectedUnits;

    if (!allSelected.length) {
      storeDispatch(showErrorSnackbar('No Units Selected'));
      return;
    }

    let marketingUnits = units
      .filter((unit: IUnit) => allSelected.some((selectedUnit: IUnit) => selectedUnit._id === unit._id))
      .map((unit: IUnit) => {
        return {
          _id: unit._id,
          modelType: unit.modelType,
          suite: unit.suite,
          unitType: unit.unitType,
          basePrice: unit.basePrice,
          rental: unit.rental ? unit.rental : null,
          size: unit.size,
        };
      });

    marketingFloorPlans({ variables: { units: marketingUnits, project: project._id } });
  };

  const getAllMarketingFloorPlans = () => {
    const shareableUnits = units.filter((unit: IUnit) => unit.status === 'HL');
    getMarketingFloorPlans(shareableUnits);
  };

  const handleSelectUnits = (selectingUnits: boolean) => {
    if (selectingUnits) {
      setSelectedUnits([]);
    }
    setSelectingUnits(!selectingUnits);
  };

  return (
    <div>
      <Drawer
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          '& .MuiDrawer-paper': {
            width: {
              sm: '450px',
              xs: '100%',
            },
          },
        }}
      >
        <FilterMenu handleClose={() => setOpen(false)} />
      </Drawer>
      <Box
        sx={{
          display: 'flex',
          marginRight: '20px',
          marginLeft: 'auto',
          [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap',
            marginRight: 0,
          },
        }}
      >
        {selectedUnits.length > 0 && selectingUnits && !draft ? (
          <Button
            sx={(theme) => ({
              fontSize: '11px',
              mr: 1,
              [theme.breakpoints.down('sm')]: {
                mb: 1,
              },
            })}
            variant="contained"
            color="primary"
            onClick={() => getMarketingFloorPlans()}
          >
            Share
          </Button>
        ) : null}
        {!noUnits && !draft ? (
          <Button
            sx={(theme) => ({
              [theme.breakpoints.down('sm')]: {
                mb: 1,
              },
              fontSize: '11px',
              mr: 1,
            })}
            variant="contained"
            onClick={() => getAllMarketingFloorPlans()}
          >
            Share All
          </Button>
        ) : null}
        <Button
          sx={(theme) => ({
            fontSize: '11px',
            mr: 1,
            [theme.breakpoints.down('sm')]: {
              mb: 1,
            },
          })}
          variant="contained"
          color="primary"
          onClick={() => setOpen(true)}
        >
          Filter
        </Button>
        {filteredUnits.length || noUnits ? (
          <>
            <Button
              sx={(theme) => ({
                fontSize: '11px',
                mr: 1,
                [theme.breakpoints.down('sm')]: {
                  mb: 1,
                },
              })}
              variant="contained"
              color="warning"
              onClick={() => {
                setSelectingUnits(false);
                setSelectedUnits([]);
                setFilteredUnits([]);
                setNoUnits(false);
              }}
            >
              Reset Filter
            </Button>
            {selectedUnits.length !== filteredUnits.length && !noUnits ? (
              <Button
                sx={(theme) => ({
                  fontSize: '11px',
                  mr: 1,
                  [theme.breakpoints.down('sm')]: {
                    mb: 1,
                  },
                  color: '#fff',
                  backgroundColor: '#2aa1b5',
                })}
                variant="contained"
                onClick={() => {
                  setSelectingUnits(!selectingUnits);
                  setSelectedUnits(filteredUnits.map((filteredUnit: IUnit) => filteredUnit));
                }}
              >
                Select All Units
              </Button>
            ) : null}
          </>
        ) : null}
        {user.type !== 'Sales' ? (
          <Button
            sx={(theme) => ({
              fontSize: '11px',
              mr: 1,
              [theme.breakpoints.down('sm')]: {
                display: 'none',
              },
              [theme.breakpoints.up('sm')]: {
                display: 'block',
              },
            })}
            variant="contained"
            color="primary"
            onClick={() => setLegend(!legend)}
          >
            Legend
          </Button>
        ) : null}
        <Button
          sx={(theme) => ({
            fontSize: '11px',
            mr: 1,
            [theme.breakpoints.down('sm')]: {
              mb: 1,
            },
          })}
          variant="contained"
          color={selectingUnits ? 'secondary' : 'primary'}
          onClick={() => handleSelectUnits(selectingUnits)}
        >
          Select Units
        </Button>
        {accessAllowed(user, project._id!, 'editUnits') ? (
          <Button
            sx={(theme) => ({
              fontSize: '11px',
              mr: 1,
              [theme.breakpoints.down('sm')]: {
                display: 'none',
              },
              [theme.breakpoints.up('sm')]: {
                display: 'block',
              },
            })}
            variant="contained"
            color="primary"
            onClick={() => {
              if (selectingUnits) {
                setModalType('editUnits');
                storeDispatch(handleModal(true));
              } else {
                storeDispatch(showErrorSnackbar('Please click Select Units then select a unit'));
              }
            }}
          >
            Edit Selected
          </Button>
        ) : null}
        {/* <Button
                        sx={(theme) => ({
                          fontSize: '11px',
                          mr: 1,
                          [theme.breakpoints.down('sm')]: {
                            display: 'none',
                          },
                          [theme.breakpoints.up('sm')]: {
                            display: 'block',
                          },
                        })}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          setModalType('editUnitDisplay');
                          storeDispatch(handleModal(true));
                        }}
                      >
                        Change View
                      </Button> */}
        {project._id !== '61535b00cb68b4620011d555' && user.type !== 'Sales' ? (
          <Button
            sx={(theme) => ({
              fontSize: '11px',
              mr: 1,
              [theme.breakpoints.down('sm')]: {
                mb: 1,
              },
            })}
            variant="contained"
            color="primary"
            onClick={() => setShowStack(true)}
          >
            Stack
          </Button>
        ) : null}
        {user.type === 'Manager' ? (
          <Button
            sx={(theme) => ({
              fontSize: '11px',
              mr: 1,
              [theme.breakpoints.down('sm')]: {
                mb: 1,
              },
            })}
            variant="contained"
            color="primary"
            onClick={() => {
              setModalType('priceList');
              storeDispatch(handleModal(true));
            }}
          >
            Drafts
          </Button>
        ) : null}
        {accessAllowed(user, project._id!, 'createUnits') ? (
          <Button
            sx={(theme) => ({
              fontSize: '11px',
              mr: 1,
              [theme.breakpoints.down('sm')]: {
                mb: 1,
              },
            })}
            variant="contained"
            color="primary"
            onClick={() => {
              setModalType('create');
              storeDispatch(handleModal(true));
            }}
          >
            Create Unit
          </Button>
        ) : null}
        {user.type === 'Manager' && draft ? (
          <Button
            sx={(theme) => ({
              fontSize: '11px',
              mr: 1,
              [theme.breakpoints.down('sm')]: {
                mb: 1,
              },
            })}
            variant="contained"
            color="primary"
            onClick={() => download(selectedPriceList)}
          >
            Download Reports
          </Button>
        ) : null}
        {user.type === 'Manager' && draft && selectedPriceList ? (
          <Button
            sx={(theme) => ({
              fontSize: '11px',
              mr: 1,
              [theme.breakpoints.down('sm')]: {
                mb: 1,
              },
            })}
            variant="contained"
            color="success"
            onClick={() => {
              setModalType('publishPriceList');
              storeDispatch(handleModal(true));
            }}
          >
            Publish
          </Button>
        ) : null}
        {user.type === 'Manager' && draft ? (
          <Button
            sx={(theme) => ({
              fontSize: '11px',
              mr: 1,
              [theme.breakpoints.down('sm')]: {
                mb: 1,
              },
            })}
            variant="contained"
            color="primary"
            onClick={() => handleBack()}
          >
            Back
          </Button>
        ) : null}
      </Box>
    </div>
  );
};

const GETMARKETINGFLOORPLANS = gql`
  query getMarketingFloorPlans($units: [NewMarketingInput!], $project: MongoID!) {
    getMarketingFloorPlans(units: $units, project: $project) {
      _id
      suite
      size
      modelType
      rental
      unitType
      marketingFloorPlan
      pdfFloorPlan
      basePrice
    }
  }
`;

export default GridHeader;
