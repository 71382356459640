import React from 'react';
import { InputLabel, MenuItem, FormControl, Select } from '@mui/material';

const Dropdown = (props: ChildProps) => {
  const { handleSelect, value, menuList, title, name, includeEmpty, required } = props;

  return (
    <div>
      <FormControl
        sx={{
          minWidth: 150,
          width: '100%',
        }}
      >
        <InputLabel id="demo-simple-select-label">
          {title ? (
            <>
              {title + ' '}
              {required && (
                <span
                  style={{
                    color: 'red',
                  }}
                >
                  *
                </span>
              )}
            </>
          ) : (
            ''
          )}
        </InputLabel>
        <Select
          defaultValue={''}
          value={value}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label={title}
          name={name}
          onChange={handleSelect}
          inputProps={{
            name: name,
          }}
        >
          {menuList.map((listItem: any, index: number) => {
            return (
              <MenuItem key={index} value={listItem} id={index.toString()}>
                {listItem}
              </MenuItem>
            );
          })}
          {includeEmpty ? <MenuItem value="None">None</MenuItem> : null}
        </Select>
      </FormControl>
    </div>
  );
};

interface ChildProps {
  title?: string;
  name?: string;
  id?: string;
  handleSelect?: (e: any, child?: any) => void;
  menuList: string[];
  value?: string;
  required?: boolean;
  includeEmpty?: boolean;
}

export default Dropdown;
