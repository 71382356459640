import { useContext, memo } from 'react';
import { FixedSizeGrid as Grid, areEqual } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Box } from '@mui/material';

import { PriceGridContext } from '../../context/PriceGridContext';
import { IUnit } from '../../types/unit';
import UnitGridCard from './UnitGridCard';

const GridComponent = (props: ChildProps) => {
  const { type, printState, draft } = props;
  const { units, selectingUnits, displayedInfo, setSelectedUnits, selectedUnits, filteredUnits } = useContext(PriceGridContext);

  const checkMaxRows = Math.max.apply(
    Math,
    filteredUnits.length ? filteredUnits.map((unit: IUnit) => unit.row) : units.map((unit: IUnit) => unit.row)
  );

  const columns = filteredUnits.length ? filteredUnits.map((unit: IUnit) => unit.col) : units.map((unit: IUnit) => unit.col);
  const checkMaxColumns = Math.max(...columns);

  let arr: any[] = [];
  for (let i = 0; i < checkMaxRows; i++) {
    if (!arr[i]) {
      arr[i] = [];
    }
    for (let j = 0; j < checkMaxColumns + 1; j++) {
      arr[i].push({});
      if (filteredUnits.length) {
        filteredUnits.forEach((unit: IUnit) => {
          if (unit.row === i + 1 && unit.col === j + 1) {
            arr[i][j] = unit;
          }
        });
      } else {
        units.forEach((unit: IUnit) => {
          if (unit.row === i + 1 && unit.col === j + 1) {
            arr[i][j] = unit;
          }
        });
      }
    }
  }

  const handleSelectedUnits = (unit: IUnit) => {
    if (selectedUnits.some((selectedUnit: IUnit) => selectedUnit._id === unit._id)) {
      const removeUnit = selectedUnits.filter((u: IUnit) => u._id !== unit._id);
      setSelectedUnits(removeUnit);
    } else {
      setSelectedUnits([...selectedUnits, unit]);
    }
  };

  // determines the numebr of attributes displayed on the unit grid card for a uint on the price grid
  // used to determine the height of the grid component
  const countChecked = () => {
    return (
      (displayedInfo.unitNumber ? 1 : 0) +
      (displayedInfo.unitType ? 1 : 0) +
      (displayedInfo.modelType ? 1 : 0) +
      (displayedInfo.modelName ? 1 : 0) +
      (displayedInfo.bathrooms ? 1 : 0) +
      (displayedInfo.basePrice ? 1 : 0) +
      (displayedInfo.allocation ? 1 : 0) +
      (displayedInfo.pricePerSquareFoot ? 1 : 0) +
      (displayedInfo.size ? 1 : 0) +
      (displayedInfo.outdoorType ? 1 : 0) +
      (displayedInfo.outdoorSize ? 1 : 0) +
      (displayedInfo.level ? 1 : 0) +
      (displayedInfo.exposure ? 1 : 0) +
      (displayedInfo.tier ? 1 : 0) +
      (displayedInfo.rental ? 1 : 0)
    );
  };

  let reverseArray = arr.reverse();

  const Cell = memo((props: any) => {
    const { rowIndex, columnIndex, style } = props;
    let printStyle = style;
    const unit = reverseArray[rowIndex][columnIndex];
    if (!unit) return null;
    const displayed = selectedUnits.some((selectedUnit: IUnit) => selectedUnit._id === unit._id);

    if (printState) {
      if (rowIndex <= 10) {
        printStyle = {
          ...style,
          top: style.top / 2.2,
          left: style.left / 2.5,
        };
      } else {
        if (rowIndex >= 11 && rowIndex <= 20) {
          if (rowIndex % 11 === 0) {
            printStyle = {
              ...style,
              top: style.top / 2.1,
              left: style.left / 2.5,
            };
          } else if (rowIndex % 11 > 0) {
            printStyle = {
              ...style,
              top: style.top / 2.1,
              left: style.left / 2.5,
            };
          }
        } else {
          printStyle = {
            ...style,
            top: style.top / 2.05,
            left: style.left / 2.5,
          };
        }
      }
    }

    return (
      <Box
        sx={{
          ...printStyle,
          p: 1,
          mb: 2,
        }}
      >
        <UnitGridCard
          unit={unit}
          type={type}
          handleSelectedUnits={handleSelectedUnits}
          displayed={displayed}
          selectingUnits={selectingUnits}
          draft={draft}
        />
      </Box>
    );
  }, areEqual);

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            height: '100px',
            flex: 1,
          }}
        >
          {units.length > 0 ? (
            <AutoSizer defaultHeight={1080} defaultWidth={1920}>
              {({ height, width }) => (
                <Grid
                  itemData={filteredUnits.length ? filteredUnits : units}
                  columnCount={checkMaxColumns}
                  columnWidth={120}
                  height={height}
                  rowCount={checkMaxRows}
                  rowHeight={24 * (0.7 * countChecked() + (2 - countChecked() * 0.03))}
                  width={width - 1}
                >
                  {Cell}
                </Grid>
              )}
            </AutoSizer>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

interface ChildProps {
  type: String;
  selectingUnits: boolean;
  printState: boolean;
  draft: boolean;
}

export default GridComponent;
