import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../app/store';
import { IProject } from '../../types/project';

const getCurrentDateString = () => new Date(Date.now()).toISOString();

const initialState: IProjectState = {
  projects: [],
  project: {
    _id: '',
    name: '',
    developerName: '',
    addresses: [],
    imageGetUrl: null,
    imagePutUrl: '',
    logoGetUrl: null,
    logoPutUrl: '',
    developerLogoGetUrl: null,
    developerLogoPutUrl: '',
    partnerLogoGetUrl: null,
    partnerLogoPutUrl: '',
    email: '',
    trackingPhone: '',
    salesTrackingPhone: '',
    options: [],
    depositStructures: [
      {
        name: '',
        deposits: [
          {
            name: '', // 1st Deposit, 2nd Deposit etc
            type: '', // Fixed or Percent
            amount: 0, // 5,000 or 2.5%
            daysDue: 0, // 30 days
            dateType: '',
            dueDate: getCurrentDateString(), // Specific Date
          },
        ],
        default: true,
      },
    ],
    mergeTemplates: [
      {
        name: 'floorplan',
        apsTemplates: [
          {
            name: 'Main APS',
            pageNumber: 0,
            attachToAps: '',
          },
        ],
      },
    ],
    executors: [],
    coopStructures: [],
    adjustments: [],
    commissionIncludeOptions: false,
    status: [],
    // emailTemplates: [],
    mortgageLimit: 2,
    hideOccupancy: true,
    defaultZeroValue: '$0',
    mortgageMinDay: 30,
    lawyer: '',
    tagLine: '',
    salesOffice: '',
    decorModels: [],
    combinedProjects: [],
    firstTentativeOccupancy: null,
    finalTentativeOccupancy: null,
    firmOccupancy: null,
    outsideOccupancy: null,
    active: false,
    acknowledgement: false,
    tags: [],
  },
};

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setProjects: (state, action) => {
      state.projects = action.payload;
    },
    setProject: (state, action) => {
      state.project = action.payload;
    },
    setMergeTemplate: (state, action) => {
      state.project.mergeTemplates = action.payload;
    },
    setOptions: (state, action) => {
      state.project.options = action.payload;
    },
    setDepositStructures: (state, action) => {
      state.project.depositStructures = action.payload;
    },
    reset: () => initialState,
  },
});

export const { setProjects, setProject, setMergeTemplate, setOptions, setDepositStructures, reset } = projectSlice.actions;

export const selectProjects = (state: RootState) => state.project.projects;
export const selectProject = (state: RootState) => state.project.project;

interface IProjectState {
  projects: IProject[];
  project: IProject;
}

export default projectSlice.reducer;
