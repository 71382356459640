import { useState, useMemo, useRef } from 'react';
import { gql, useMutation } from '@apollo/client';
import { Box, FormControlLabel, Checkbox, Button, TextField } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectProject } from '../../features/project/projectSlice';
import { selectUser } from '../../features/auth/authSlice';
import { useAppDispatch } from '../../app/hooks';
import { showSuccessSnackbar } from '../../features/snackbar/snackbarSlice';
import TextInput from '../common/formControls/TextInput';
import { IComment } from '../../types/comment';
import { IDeal } from '../../types/CreateDealForm';
import { IUnit } from '../../types/unit';
import { commentTypes } from '../../utils/Constants';
import { convertAllDates, capitalizeFirstLetter } from '../../utils/Functions';
import Dropdown from './formControls/Dropdown';
import StandardTable from '../tables/StandardTable';
import { Flex } from '../../commonStyles';
import { ITask } from '../../types/task';

const Comments = (props: ChildProps) => {
  const { title, task, deal, commentType, commentCategory, unit, comments, setComments, handleNewComment } = props;
  const storeDispatch = useAppDispatch();
  const project = useSelector(selectProject);
  const user = useSelector(selectUser);
  const [comment, setComment] = useState<string>('');
  const [newComments, setNewComments] = useState<IComment[]>(comments);
  const [selectedComment, setSelectedComment] = useState<boolean>(false);
  const [pinned, setPinned] = useState<boolean>(false);
  const [type, setType] = useState<string>('General');

  const [updateComment] = useMutation(UPDATECOMMENT, {
    onCompleted: (data) => {
      let newComments = comments.map((comment: IComment) => {
        if (comment._id === data.commentUpdateById.record._id) {
          return data.commentUpdateById.record;
        } else return comment;
      });
      setComments(newComments);
      setNewComments(newComments);
      storeDispatch(showSuccessSnackbar('Comment has been updated'));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [updatePins] = useMutation(UPDATEPINS, {
    onCompleted: (data) => {
      let updatedComments = comments.map((comment: IComment) => {
        if (comment._id === data.updatePins.id) {
          return {
            ...comment,
            pinned: true,
          };
        } else if (comment.type === data.updatePins.type) {
          return {
            ...comment,
            pinned: false,
          };
        } else return comment;
      });

      setComments(updatedComments);
      storeDispatch(showSuccessSnackbar('Comment has been pinned'));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [addComment] = useMutation(ADDCOMMENT, {
    onCompleted: (data) => {
      if (!data.commentCreateOne.record.pinned) {
        storeDispatch(showSuccessSnackbar('Comment has been added'));
      }
      setComments([data.commentCreateOne.record, ...comments], data.commentCreateOne.record._id);
      if (data.commentCreateOne.record.pinned) {
        updatePins({
          variables: {
            commentId: data.commentCreateOne.record._id,
            commentType: data.commentCreateOne.record.type,
            dealId: deal ? deal._id : '0',
            pin: true,
          },
        });
      }
      setPinned(false);
      setType('General');
      setComment('');
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const saveComment = (e: any) => {
    e.preventDefault();
    let newObject = {};
    if (commentType === 'task') {
      newObject = {
        project: project._id,
        deal: null,
        unit: null,
        task: task?._id,
        user: user._id,
        type: type.toLocaleLowerCase(),
        pinned: pinned,
        comment: comment,
        deleted: false,
      };
    } else if (commentType === 'deal') {
      newObject = {
        project: project._id,
        deal: deal?._id,
        unit: deal?.unit._id,
        user: user._id,
        type: commentCategory ? commentCategory.toLocaleLowerCase() : type.toLocaleLowerCase(),
        pinned: pinned,
        comment: comment,
        deleted: false,
      };
    } else {
      newObject = {
        project: project._id,
        unit: unit?._id,
        user: user._id,
        comment: comment,
        type: 'general',
        pinned: false,
        deleted: false,
      };
    }
    addComment({ variables: { record: newObject } });
  };

  const update = (e: any, id: string) => {
    e.preventDefault();
    let selectedComment = newComments.find((comment: IComment) => comment._id === id);
    if (selectedComment) {
      updateComment({ variables: { _id: selectedComment._id, record: { comment: selectedComment.comment } } });
    }
    setSelectedComment(false);
  };

  const handleComment = (e: any, id: string) => {
    let updatedComment = newComments.map((comment: IComment, index: number) => {
      if (id === comment._id) {
        return {
          ...comment,
          comment: e.target.value,
        };
      } else return comment;
    });
    setNewComments(updatedComment);
  };

  const pinComment = (data: any, pin: boolean) => {
    updatePins({ variables: { commentId: data._id, commentType: data.type.toLowerCase(), dealId: deal ? deal._id : '0', pin: pin } });
  };

  const handleDropdownInput = (event: any, index: number | null = null) => {
    if (index !== null) {
      updateComment({ variables: { _id: comments[index]._id, record: { type: event.target.value.toLocaleLowerCase() } } });
    } else {
      setType(event.target.value);
    }
  };

  let editableKeyToFocus: any = useRef(null);

  const columns = useMemo(() => {
    return [
      {
        Header: 'Comment',
        accessor: (rowData: any, index: number) => {
          if (rowData.user._id === user._id) {
            const key = `comment${index}`;
            return (
              <form onSubmit={(e) => update(e, rowData._id)}>
                <TextField
                  key={key}
                  fullWidth
                  multiline
                  variant={'outlined'}
                  rows={4}
                  required={true}
                  value={newComments[index].comment}
                  onChange={(e: any) => {
                    editableKeyToFocus.current = key;
                    handleComment(e, rowData._id);
                  }}
                  autoFocus={key === editableKeyToFocus.current}
                />
                <Flex
                  sx={{
                    mt: 1,
                  }}
                >
                  <Button sx={{ mr: 1 }} type={'submit'} color="success" variant="contained">
                    Save
                  </Button>
                </Flex>
              </form>
            );
          } else return rowData.comment;
        },
      },
      {
        Header: 'Type',
        accessor: (rowData: any, index: number) => {
          if (rowData.user._id === user._id) {
            const key = `comment${index}`;
            return (
              <Dropdown
                id={'type'}
                title={''}
                menuList={commentTypes}
                name={'type'}
                handleSelect={(e: any) => handleDropdownInput(e, index)}
                value={rowData.type ? capitalizeFirstLetter(rowData.type) : ''}
              />
            );
          } else return capitalizeFirstLetter(rowData.type);
        },
      },
      {
        Header: 'Pinned',
        accessor: (rowData: any) =>
          rowData._id === selectedComment && rowData.pinned ? (
            <Button color="primary" variant="contained" onClick={() => pinComment(rowData, false)}>
              Unpin
            </Button>
          ) : rowData.pinned ? (
            <strong>Pinned</strong>
          ) : (
            <Button color="primary" variant="contained" onClick={() => pinComment(rowData, true)}>
              Pin
            </Button>
          ),
      },
      {
        Header: 'User',
        accessor: (rowData: any) => rowData.user.fullName,
      },
      {
        Header: 'Date',
        accessor: (rowData: any) => {
          if (rowData.updatedAt > rowData.createdAt) {
            return `${convertAllDates(new Date(rowData.updatedAt), 'PPPP HH:mm:ss')} (Edited)`;
          } else return convertAllDates(new Date(rowData.createdAt), 'PPPP HH:mm:ss');
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newComments, comments]);

  return (
    <div>
      <h2>{title}</h2>
      <div>
        <form style={{ marginTop: '10px' }} onSubmit={saveComment}>
          {commentType === 'deal' ? (
            <Box
              sx={{
                display: 'flex',
                mt: 1,
                mb: 1,
                width: '200px',
              }}
            >
              {!commentCategory ? (
                <Box sx={{ mr: 1 }}>
                  <Dropdown
                    id={'type'}
                    title={''}
                    menuList={commentTypes}
                    name={'type'}
                    handleSelect={(e: any) => handleDropdownInput(e)}
                    value={type}
                  />
                </Box>
              ) : null}
              <div>
                <FormControlLabel
                  style={{
                    marginTop: '10px',
                    marginBottom: '15px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  control={<Checkbox checked={pinned} onChange={(e) => setPinned(!pinned)} />}
                  label={'Pin'}
                />
              </div>
            </Box>
          ) : null}
          <TextInput
            id="comments"
            placeholder="Add a note or comment (150 character limit)"
            multiline
            variant={'outlined'}
            rows={4}
            required={true}
            value={comment}
            rowsMax={Infinity}
            handleTextInput={(e: any) => setComment(e.target.value)}
            maxLength={300}
            controlled={true}
          />
          <Button sx={{ my: 2 }} type={'submit'} name={'update'} color="success" variant="contained">
            Save New Comment
          </Button>
        </form>
        <Box>
          <StandardTable columns={columns} data={comments} />
        </Box>
      </div>
    </div>
  );
};

interface ChildProps {
  title: string;
  commentType: string;
  deal?: IDeal;
  unit?: IUnit;
  task?: ITask;
  comments: IComment[];
  setComments?: any;
  height?: number;
  commentCategory?: string;
  handleNewComment?: any;
}

const ADDCOMMENT = gql`
  mutation commentCreateOne($record: CreateOneCommentInput!) {
    commentCreateOne(record: $record) {
      record {
        _id
        type
        pinned
        comment
        deal {
          _id
        }
        updatedAt
        createdAt
        user {
          fullName
        }
      }
    }
  }
`;

const UPDATECOMMENT = gql`
  mutation commentUpdateById($_id: MongoID!, $record: UpdateByIdCommentInput!) {
    commentUpdateById(_id: $_id, record: $record) {
      record {
        _id
        user {
          _id
          fullName
        }
        deal {
          _id
        }
        comment
        type
        pinned
        createdAt
        updatedAt
      }
    }
  }
`;

const UPDATEPINS = gql`
  mutation updatePins($commentId: MongoID!, $commentType: String!, $dealId: MongoID!, $pin: Boolean!) {
    updatePins(commentId: $commentId, commentType: $commentType, dealId: $dealId, pin: $pin) {
      id
      type
    }
  }
`;

export default Comments;
