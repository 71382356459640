import React, { useState } from 'react';
import {
  Box,
  Grid,
  Fade,
  Typography,
  Button,
  Drawer,
  Checkbox,
  FormControlLabel,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from '@mui/material';

import { mainCategories, subCategories } from '../../../utils/Constants';
import { FlexBetween } from '../../../commonStyles';
import { useAppDispatch } from '../../../app/hooks';
import { handleModal } from '../../../features/modal/modalSlice';
import { IDeal } from '../../../types/CreateDealForm';

const CustomUpgrade = (props: ChildProps) => {
  const { handleCreateUpgrade } = props;
  const storeDispatch = useAppDispatch();
  const [name, setName] = useState<string>('');
  const [price, setPrice] = useState<string>('');
  const [quantity, setQuantity] = useState<string>('');
  const [mainCategory, setMainCategory] = useState<string>('');
  const [subCategory, setSubCategory] = useState<string>('');

  const createUpgrade = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleCreateUpgrade(name, price, quantity, mainCategory, subCategory)
  }

  return (
    <form onSubmit={createUpgrade} autoComplete="off">
      <Typography variant="h2">
        <strong>Create Custom Upgrade</strong>
      </Typography>
      <TextField
        title={'Upgrade Name'}
        sx={{ mt: 1 }}
        name={'name'}
        fullWidth
        value={name}
        label={'Upgrade Name'}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
      />
      <TextField
        title={'Quantity'}
        type="number"
        sx={{ mt: 1 }}
        name={'quantity'}
        fullWidth
        value={quantity}
        label={'Quantity'}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setQuantity(e.target.value)}
      />
      <TextField
        title={'Total Price (of all quantities)'}
        type="number"
        sx={{ mt: 1 }}
        name={'price'}
        fullWidth
        value={price}
        label={'Total Price (of all quantities)'}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPrice(e.target.value)}
      />
      <FormControl sx={{ mt: 1 }} fullWidth>
        <InputLabel id="id-select-label">Main Category</InputLabel>
        <Select
          value={mainCategory}
          labelId="id-label"
          id="id-mainCategory"
          label="Main Category"
          name="mainCategory"
          onChange={(e) => setMainCategory(e.target.value)}
        >
          {mainCategories.map((mainCategories: string, index: number) => {
            return (
              <MenuItem key={index} value={mainCategories}>
                {mainCategories}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <FormControl sx={{ mt: 1 }} fullWidth>
        <InputLabel id="id-select-label">Sub Category</InputLabel>
        <Select
          value={subCategory}
          labelId="id-label"
          id="id-subCategory"
          label="Sub Category"
          name="subCategory"
          onChange={(e) => setSubCategory(e.target.value)}
        >
          {subCategories.map((mainCategories: string, index: number) => {
            return (
              <MenuItem key={index} value={mainCategories}>
                {mainCategories}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <FlexBetween sx={{ mt: 2 }}>
        <Button onClick={() => storeDispatch(handleModal(false))} variant="contained" color="info">
          Cancel
        </Button>
        <Button type="submit" variant="contained" color="success">
          Create
        </Button>
      </FlexBetween>
    </form>
  );
};

interface ChildProps {
  deal?: IDeal;
  handleCreateUpgrade: any;
}

export default CustomUpgrade;
