import React, { useMemo } from 'react';
import { Box, Paper } from '@mui/material';

import { numToCurrency } from '../../utils/Functions';
import StandardTable from '../tables/StandardTable';

const EnhancedTable = (props: ChildProps) => {
  const { optionsData } = props;

  const columns = useMemo(() => {
    return [
      {
        Header: 'Option',
        accessor: (rowData: any) => {
          return <Box sx={{ fontWeight: rowData.option === 'Total' ? 'bold' : 'normal' }}>{rowData.option}</Box>;
        },
      },
      {
        Header: 'Current Price',
        accessor: (rowData: any) => {
          return <Box sx={{ fontWeight: rowData.option === 'Total' ? 'bold' : 'normal' }}>{rowData.currentPrice}</Box>;
        },
      },
      {
        Header: 'Total Options',
        accessor: (rowData: any) => {
          return <Box sx={{ fontWeight: rowData.option === 'Total' ? 'bold' : 'normal' }}>{rowData.totalOptions}</Box>;
        },
      },
      {
        Header: 'Available',
        accessor: (rowData: any) => {
          return <Box sx={{ fontWeight: rowData.option === 'Total' ? 'bold' : 'normal' }}>{rowData.available}</Box>;
        },
      },
      {
        Header: 'Available Revenue',
        accessor: (rowData: any) => {
          return (
            <Box sx={{ fontWeight: rowData.option === 'Total' ? 'bold' : 'normal' }}>{numToCurrency.format(rowData.availableRevenue)}</Box>
          );
        },
      },
      {
        Header: 'Available Revenue Net HST',
        accessor: (rowData: any) => {
          return (
            <Box sx={{ fontWeight: rowData.option === 'Total' ? 'bold' : 'normal' }}>
              {numToCurrency.format(rowData.availableRevenueNetHst)}
            </Box>
          );
        },
      },
      {
        Header: 'Pending Options (O, S)',
        accessor: (rowData: any) => {
          return <Box sx={{ fontWeight: rowData.option === 'Total' ? 'bold' : 'normal' }}>{rowData.pendingOptions}</Box>;
        },
      },
      {
        Header: 'Sold Options (C, F)',
        accessor: (rowData: any) => {
          return <Box sx={{ fontWeight: rowData.option === 'Total' ? 'bold' : 'normal' }}>{rowData.soldOptions}</Box>;
        },
      },
      {
        Header: 'Combined Options (O, S, C, F)',
        accessor: (rowData: any) => {
          return <Box sx={{ fontWeight: rowData.option === 'Total' ? 'bold' : 'normal' }}>{rowData.combinedOptions}</Box>;
        },
      },
      {
        Header: 'Pending Revenue (O, S)',
        accessor: (rowData: any) => {
          return <Box sx={{ fontWeight: rowData.option === 'Total' ? 'bold' : 'normal' }}>{rowData.pendingRevenue}</Box>;
        },
      },
      {
        Header: 'Sold Revenue (C, F)',
        accessor: (rowData: any) => {
          return <Box sx={{ fontWeight: rowData.option === 'Total' ? 'bold' : 'normal' }}>{rowData.soldRevenue}</Box>;
        },
      },
      {
        Header: 'Combined Revenue (O, S, C, F)',
        accessor: (rowData: any) => {
          return <Box sx={{ fontWeight: rowData.option === 'Total' ? 'bold' : 'normal' }}>{rowData.combinedRevenue}</Box>;
        },
      },
      {
        Header: 'Total PPSF',
        accessor: (rowData: any) => {
          return <Box sx={{ fontWeight: rowData.option === 'Total' ? 'bold' : 'normal' }}>{numToCurrency.format(rowData.totalPPSF)}</Box>;
        },
      },
      {
        Header: 'Total Revenue',
        accessor: (rowData: any) => {
          return (
            <Box sx={{ fontWeight: rowData.option === 'Total' ? 'bold' : 'normal' }}>{numToCurrency.format(rowData.totalRevenue)}</Box>
          );
        },
      },
      {
        Header: 'Total Revenue Net HST',
        accessor: (rowData: any) => {
          return (
            <Box sx={{ fontWeight: rowData.option === 'Total' ? 'bold' : 'normal' }}>
              {numToCurrency.format(rowData.totalRevenueNetHst)}
            </Box>
          );
        },
      },
    ];
  }, []);

  return (
    <Paper elevation={5}>
      <StandardTable data={optionsData} columns={columns} />
    </Paper>
  );
};

interface ChildProps {
  optionsData: any;
}

export default EnhancedTable;
