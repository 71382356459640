import { useContext } from 'react';
import { Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { UnitContext } from '../../../../context/UnitContext';
import { useSelector } from 'react-redux';
import { selectProject } from '../../../../features/project/projectSlice';

const NewDeal = () => {
  const { unit } = useContext(UnitContext);
  const project = useSelector(selectProject);
  return (
    <Box
      sx={{
        textAlign: 'center',
      }}
    >
      <p>
        <em>There are currently no active deals for this unit.</em>
      </p>
      {unit.status === 'UT' ? (
        <p>
          <em>A purchaser from another unit is looking to transfer to this unit</em>
        </p>
      ) : (
        <div>
          <Box sx={{ mt: 2 }}>
            <Link to={`/${project._id}/dashboard/upload-deal/${unit._id}/form`}>
              <Button variant="contained" color="primary" sx={{ mr: 1 }}>
                Upload Deal
              </Button>
            </Link>
            <Link to={`/${project._id}/dashboard/create-deal/${unit._id}/form`}>
              <Button variant="contained" color="primary">
                Create Deal
              </Button>
            </Link>
          </Box>
        </div>
      )}
    </Box>
  );
};

export default NewDeal;
