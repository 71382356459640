import { useEffect, useState } from 'react';
import { Box, FormControlLabel, Switch, Grid, Button, Autocomplete, TextField, FormControl } from '@mui/material';

import { IRealtorInfo } from '../../../types/CreateDealForm';
import RealtorForm from '../../common/forms/RealtorForm';
import Coop from '../../createProject/Coop';
import { ICoopRate } from '../../../types/project';
import { FlexBetween } from '../../../commonStyles';
import { formatPhoneNumber } from '../../../utils/Functions';

const AssignmentRealtor = (props: ChildProps) => {
  const { realtor, realtorDispatch, title, realtors } = props;
  const [errors, setErrors] = useState<any>({});
  const [hasRealtor, setHasRealtor] = useState(false);
  const [hasCoops, setHasCoops] = useState(false);
  
  useEffect(() => {
    if (realtor && realtor.email) {
      setHasRealtor(true);
    }
    if (realtor && realtor.coopRates.length) {
      setHasCoops(true);
    }
  }, [realtor]);

  const handleTextInput = async (event: any) => {
    let value = event.target.value;
    if (event.target.name === 'directPhone' || event.target.name === 'brokeragePhone') {
      value = formatPhoneNumber(event.target.value);
    }
    realtorDispatch({ type: 'UPDATE', payload: { field: event.target.name, value: value } });
    if (event.target.value === '') {
      setErrors({
        ...errors,
        [event.target.id]: `${event.target.name} cannot be empty`,
      });
    } else {
      setErrors({
        ...errors,
        [event.target.id]: null,
      });
    }
  };

  const handleCoopInput = (event: any, numIndex: number) => {
    let coopRates = realtor.coopRates.map((coopRate: ICoopRate, index: number) => {
      if (index === numIndex) {
        return {
          ...coopRate,
          [event.target.name]:
            event.target.name === 'days' || event.target.name === 'percentage' || event.target.name === 'fixedAmount'
              ? parseFloat(event.target.value)
              : event.target.value,
        };
      } else return coopRate;
    });
    realtorDispatch({
      type: 'UPDATE',
      payload: {
        field: 'coopRates',
        value: coopRates,
      },
    });
  };

  const handleCoopDate = (newValue: any, numIndex: number) => {
    let coopRates = realtor.coopRates.map((coopRate: ICoopRate, index: number) => {
      if (index === numIndex) {
        return {
          ...coopRate,
          date: newValue,
        };
      } else return coopRate;
    });
    realtorDispatch({
      type: 'UPDATE',
      payload: {
        field: 'coopRates',
        value: coopRates,
      },
    });
  };

  const handleDeleteCoop = (numIndex: number) => {
    if (realtor.coopRates.length === 1) {
      setHasCoops(false);
    }
    realtorDispatch({ type: 'DELETECOOP', payload: { index: numIndex } });
  };

  const handleCoops = () => {
    if (!realtor.coopRates.length) {
      realtorDispatch({
        type: 'UPDATE',
        payload: {
          field: 'coopRates',
          value: [
            {
              type: '',
              days: 0,
              date: new Date(),
              description: '',
              percentage: 0,
              fixedAmount: 0,
            },
          ],
        },
      });
    }
    setHasCoops(!hasCoops);
  };

  const handleSearchInput = (event: any, values: any) => {
    realtorDispatch({
      type: 'ADD',
      payload: {
        ...values,
        coopRates: [],
      },
    });
  };

  return (
    <Box>
      <Box sx={{ p: 2 }}>
        <h3 style={{ margin: 0 }}>
          <strong>{title}</strong>
        </h3>
        <Box sx={{ mt: 2 }}>
          <Box>
            <FormControlLabel
              onClick={(e) => e.stopPropagation()}
              control={<Switch checked={hasRealtor} onChange={(e: any) => setHasRealtor(!hasRealtor)} />}
              label={'Realtor'}
            />
          </Box>
          {hasRealtor ? (
            <>
              <FormControl
                sx={{
                  width: '100%',
                  '& .MuiFormLabel-asterisk': {
                    color: 'red',
                  },
                }}
              >
                <Autocomplete
                  sx={{
                    width: '250px',
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    fontSize: '14px',
                    mb: 2,
                  }}
                  id={'search'}
                  disableClearable
                  freeSolo={false}
                  options={realtors}
                  getOptionLabel={(option: any) => `${option.firstName} ${option.lastName} ${option.email}`}
                  onChange={handleSearchInput}
                  renderInput={(params) => (
                    <TextField required={false} {...params} size="small" label={'Search By Name or Email'} margin="normal" />
                  )}
                />
              </FormControl>
              <RealtorForm realtorInfo={realtor} handleTextInput={handleTextInput} />
              <FlexBetween sx={{ my: 2 }}>
                <FormControlLabel
                  sx={{ mt: 1 }}
                  onClick={(e) => e.stopPropagation()}
                  control={<Switch checked={hasCoops} onChange={(e: any) => handleCoops()} />}
                  label={'Coops'}
                />
                {hasCoops ? (
                  <Box>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        realtorDispatch({
                          type: 'UPDATE',
                          payload: {
                            field: 'coopRates',
                            value: [
                              ...realtor.coopRates,
                              {
                                type: '',
                                days: 0,
                                date: new Date(),
                                description: '',
                                percentage: 0,
                                fixedAmount: 0,
                              },
                            ],
                          },
                        })
                      }
                    >
                      Add Coop Rate
                    </Button>
                  </Box>
                ) : null}
              </FlexBetween>
              {hasCoops ? (
                <Box>
                  <Grid container spacing={2}>
                    {realtor.coopRates.map((coopRate: ICoopRate, index: number) => {
                      return (
                        <Coop
                          key={index}
                          index={index}
                          structureIndex={0}
                          coopRate={coopRate}
                          handleCoopType={handleCoopInput}
                          handleCoopInput={handleCoopInput}
                          handleDeleteCoop={handleDeleteCoop}
                          handleCoopDate={handleCoopDate}
                        />
                      );
                    })}
                  </Grid>
                </Box>
              ) : null}
            </>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

interface ChildProps {
  realtor: any;
  realtorDispatch: any;
  newAssignment: boolean;
  title: string;
  realtors: IRealtorInfo[];
}

export default AssignmentRealtor;
