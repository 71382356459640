import { useState, useEffect, useMemo } from 'react';
import { gql, useLazyQuery, useSubscription } from '@apollo/client';
import { Box, Typography, Button } from '@mui/material';
import { IActivity } from '../../types/activity';
import { useSelector } from 'react-redux';
import { selectUser } from '../../features/auth/authSlice';
import { selectProject } from '../../features/project/projectSlice';
import StandardTable from '../tables/StandardTable';
import { convertAllDates } from '../../utils/Functions';

const Activity = () => {
  const project = useSelector(selectProject);

  const [activities, setActivities] = useState<IActivity[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [count, setCount] = useState<number>(0);

  const [getActivities, { loading }] = useLazyQuery(GETACTIVITIES, {
    onCompleted: (data) => {
      setActivities(data.activityPagination.items);
      setCount(data.activityPagination.count);
    },
  });

  useSubscription(ACTIVITYSUBSCRIPTION, {
    variables: { projectId: project._id },
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      setActivities([data.logActivity, ...activities]);
    },
  });

  useEffect(() => {
    getActivities({ variables: { filter: { project: project._id }, page: pageNumber, perPage: 25, sort: '_ID_DESC' } });
  }, [pageNumber, getActivities]);

  const handleGlobalFilterValue = (value: string) => {
    setPageNumber(1);
    getActivities({ variables: { filter: { project: project._id, search: value }, page: 1, perPage: 25, sort: '_ID_DESC' } });
  };

  const columns = useMemo(() => {
    return [
      {
        Header: 'Title',
        accessor: (rowData: any) => rowData.title,
      },
      {
        Header: 'Content',
        accessor: (rowData: any) => rowData.content,
      },
      {
        Header: 'Deal',
        accessor: (rowData: any) => (rowData.deal ? rowData.deal.unit.suite : 'N/A'),
      },
      {
        Header: 'User',
        accessor: (rowData: any) => rowData.user.fullName,
      },
      {
        Header: 'Date Created',
        accessor: (rowData: any) => convertAllDates(rowData.timestamp, 'PPpp'),
      },
    ];
  }, []);
  
  return (
    <Box sx={{ p: 3 }}>
      <Typography sx={{ mb: 2 }} variant={'h5'}>
        <strong>Activities</strong>
      </Typography>
      <>
        <StandardTable
          columns={columns}
          data={activities}
          handleGlobalFilterValue={handleGlobalFilterValue}
          loading={loading}
          count={count}
        />
        <Box sx={{ textAlign: 'center', mt: 2 }}>
          <Button disabled={pageNumber === 1} onClick={() => setPageNumber(pageNumber - 1)}>
            {'<'}
          </Button>
          <span>{pageNumber}</span>
          <Button disabled={pageNumber === Math.ceil(count / 15)} onClick={() => setPageNumber(pageNumber + 1)}>
            {'>'}
          </Button>
        </Box>
      </>
    </Box>
  );
};

const GETACTIVITIES = gql`
  query activityPagination($filter: FilterFindManyActivityInput, $page: Int!, $perPage: Int!, $sort: SortFindManyActivityInput) {
    activityPagination(filter: $filter, page: $page, perPage: $perPage, sort: $sort) {
      items {
        project {
          _id
        }
        user {
          fullName
        }
        deal {
          _id
          unit {
            suite
          }
        }
        title
        content
        timestamp
      }
      count
    }
  }
`;

const ACTIVITYSUBSCRIPTION = gql`
  subscription logActivity($projectId: String!) {
    logActivity(projectId: $projectId) {
      _id
      user {
        firstName
        lastName
      }
      deal {
        unit {
          suite
        }
        purchasers {
          firstName
          lastName
        }
      }
      title
      content
      timestamp
    }
  }
`;

export default Activity;
