import { useState } from 'react';
import { useSelector } from 'react-redux';
import { AppBar, Tabs, Tab, Box } from '@mui/material';

import PageTitle from '../common/PageTitle';
import GeneralInfo from './GeneralInfo';
import OptionsInfo from './OptionsInfo';
import MergeTemplateSettings from './MergeTemplateSettings';
import AppointmentSettings from './appointmentSettings/AppointmentSettings';
import Portal from './Portal';
import DepositInfo from './DepositInfo';
import ColourSelection from './colourSelection/ColourSelection';
import Adjustments from './adjustments/Adjustments';
import SupplementalDocuments from './SupplementalDocuments';
import { useProjectQuery } from '../../features/project/projectHooks';
import { selectProject } from '../../features/project/projectSlice';
import LoadingWrapper from '../common/LoadingWrapper';
import Packages from './packages/Packages';

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box px={2}>{children}</Box>}
    </div>
  );
}

const CreateProject = (props: ChildProps) => {
  const { type } = props;
  const project = useSelector(selectProject);
  const [tab, setTab] = useState<number>(0);
  const { loading } = useProjectQuery(project._id, '');

  const handleTabChange = (event: any, newValue: number) => {
    setTab(newValue);
  };

  function a11yProps(value: number) {
    return {
      id: `full-width-tab-${value}`,
      'aria-controls': `full-width-tabpanel-${value}`,
    };
  }

  return loading ? (
    <LoadingWrapper title="Loading..." modal={false} />
  ) : (
    <Box
      sx={{
        p: 2,
        height: '100vh',
        position: 'relative',
      }}
    >
      <PageTitle title={type === 'create' ? 'Create Project' : 'Edit Project'} />
      <AppBar position="static">
        <Tabs variant="scrollable" scrollButtons="auto" aria-label="scrollable auto tabs" value={tab} onChange={handleTabChange}>
          <Tab label="General Information" {...a11yProps(0)} />
          <Tab label="Options" {...a11yProps(1)} />
          <Tab label="Deposit Structure" {...a11yProps(2)} />
          <Tab label="Merge Templates" {...a11yProps(3)} />
          {/* <Tab label="Email Templates" {...a11yProps(4)} /> */}
          <Tab label="Broker Portal" {...a11yProps(4)} />
          <Tab label="Appointments" {...a11yProps(5)} />
          <Tab label="Colour Selection" {...a11yProps(6)} />
          <Tab label="Adjustments" {...a11yProps(7)} />
          <Tab label="Packages" {...a11yProps(8)} />
          <Tab label="Documents" {...a11yProps(9)} />
        </Tabs>
      </AppBar>
      <TabPanel value={tab} index={0}>
        <GeneralInfo type={type} title={'General Info'} />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <OptionsInfo />
      </TabPanel>
      <TabPanel value={tab} index={2}>
        <DepositInfo />
      </TabPanel>
      <TabPanel value={tab} index={3}>
        <MergeTemplateSettings title={'Merge Template Settings'} />
      </TabPanel>
      {type !== 'create' ? (
        <TabPanel value={tab} index={4}>
          <Portal title={'Broker Portal'} />
        </TabPanel>
      ) : null}
      {type !== 'create' ? (
        <TabPanel value={tab} index={5}>
          <AppointmentSettings />
        </TabPanel>
      ) : null}
      {type !== 'create' ? (
        <TabPanel value={tab} index={6}>
          <ColourSelection />
        </TabPanel>
      ) : null}
      {type !== 'create' ? (
        <TabPanel value={tab} index={7}>
          <Adjustments />
        </TabPanel>
      ) : null}
      {type !== 'create' ? (
        <TabPanel value={tab} index={8}>
          <Packages editable={true} />
        </TabPanel>
      ) : null}
      {type !== 'create' ? (
        <TabPanel value={tab} index={9}>
          <SupplementalDocuments />
        </TabPanel>
      ) : null}
    </Box>
  );
};

interface ChildProps {
  type: string;
  match?: any;
  location?: any;
  history?: any;
}

export default CreateProject;
