import { useState, useMemo } from 'react';
import { Box } from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import { numToCurrency } from '../../utils/Functions';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Flex } from '../../commonStyles';
import StandardTable from '../tables/StandardTable';

const ProjectsSummary = () => {
  const [projects, setProjects] = useState<any[]>([]);

  const { loading } = useQuery(GETPROJECTSSUMMARY, {
    onCompleted: (data) => {
      setProjects(data.getProjectsSummary);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const columns = useMemo(() => {
    return [
      {
        Header: 'Project',
        accessor: (rowData: any) => rowData._id.name,
      },
      {
        Header: 'Total Units',
        accessor: (rowData: any) => rowData.count,
      },
      {
        Header: 'Average Original Pricing',
        accessor: (rowData: any) => numToCurrency.format(rowData.originalRevenue / rowData.count),
      },
      {
        Header: 'Average Current Pricing',
        accessor: (rowData: any) => numToCurrency.format(rowData.totalRevenue / rowData.count),
      },
      {
        Header: 'Total Unit Original Revenue',
        accessor: (rowData: any) => numToCurrency.format(rowData.originalRevenue),
      },
      {
        Header: 'Total Unit Current Revenue',
        accessor: (rowData: any) => {
          if (rowData.totalRevenue > rowData.originalRevenue) {
            return (
              <Flex>
                {numToCurrency.format(rowData.totalRevenue)}
                <Flex>
                  <ArrowUpwardIcon sx={{ color: 'success.main' }} />
                  <Box sx={{ display: 'inline', color: 'success.main' }}>{`(${(
                    ((rowData.totalRevenue - rowData.originalRevenue) / rowData.originalRevenue) *
                    100
                  ).toFixed(2)}%)`}</Box>
                </Flex>
              </Flex>
            );
          } else return numToCurrency.format(rowData.totalRevenue);
        },
      },
      {
        Header: 'Average Original PPSF',
        accessor: (rowData: any) => numToCurrency.format(rowData.originalRevenue / rowData.size),
      },
      {
        Header: 'Average Current PPSF',
        accessor: (rowData: any) => numToCurrency.format(rowData.totalRevenue / rowData.size),
      },
      {
        Header: 'Average Sold PPSF',
        accessor: (rowData: any) =>
          rowData.dealInfo ? numToCurrency.format(rowData.dealInfo.totalRevenue / rowData.dealInfo.size) : 'N/A',
      },
      {
        Header: 'Average Blended PPSF',
        accessor: (rowData: any) => {
          if (rowData.dealInfo) {
            let averageSoldPpsf = rowData.dealInfo.totalRevenue / rowData.dealInfo.size;
            let suitesSold = rowData.conditional + rowData.firm;
            let averageUnsoldPpsf = rowData.unsoldRevenue / rowData.unsoldSize;
            let unsoldSuites = rowData.unsoldCount;

            return numToCurrency.format((averageSoldPpsf * suitesSold + averageUnsoldPpsf * unsoldSuites) / rowData.count);
          } else return 'N/A';
        },
      },
      {
        Header: '% Sold',
        accessor: (rowData: any) => `${(((rowData.conditional + rowData.firm) / rowData.count) * 100).toFixed(2)}%`,
      },
      {
        Header: 'Current Unit Sold Revenue',
        accessor: (rowData: any) => (rowData.dealInfo ? numToCurrency.format(rowData.dealInfo.totalRevenue) : 'N/A'),
      },
    ];
  }, []);

  return (
    <div>
      <StandardTable columns={columns} data={projects} loading={loading} />
    </div>
  );
};

const GETPROJECTSSUMMARY = gql`
  query getProjectsSummary {
    getProjectsSummary {
      _id {
        name
      }
      size
      unsoldSize
      unsoldRevenue
      unsoldCount
      originalRevenue
      totalRevenue
      conditional
      firm
      count
      dealInfo {
        _id
        size
        totalRevenue
      }
    }
  }
`;

export default ProjectsSummary;
