import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import LoadingWrapper from './LoadingWrapper';

const CustomDialog = (props: IDialog) => {
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          '& .MuiDialog-paper': {
            padding: '20px',
          },
        }}
        maxWidth={props.maxWidth}
      >
        {props.loading && props.loadingText ? (
          <LoadingWrapper title={props.loadingText!} modal={false} width={'400px'} />
        ) : (
          <>
            <DialogTitle sx={{ p: 0 }} id="alert-dialog-title">
              {props.dialogTitle}
            </DialogTitle>
            <DialogContent sx={{ p: 0 }}>{props.dialogContent}</DialogContent>
            <DialogActions sx={{ p: 0, mt: 1 }}>
              <Button variant="contained" onClick={props.handleCloseRemove} color="primary">
                {props.removeButton}
              </Button>
              {props.secondOption ? (
                <Button variant="contained" onClick={props.handleCloseSecond} color="primary" autoFocus>
                  {props.secondOption}
                </Button>
              ) : null}
              {props.thirdOption ? (
                <Button variant="contained" onClick={props.handleCloseThird} color="primary" autoFocus>
                  {props.thirdOption}
                </Button>
              ) : null}
              {props.fourthOption ? (
                <Button variant="contained" onClick={props.handleCloseFourth} color="primary" autoFocus>
                  {props.fourthOption}
                </Button>
              ) : null}
              {props.successButton ? (
                <Button variant="contained" onClick={props.handleCloseSuccess} disabled={props.successDisabled} color="success" autoFocus>
                  {props.successButton}
                </Button>
              ) : null}
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  );
};

interface IDialog {
  handleClose: () => void;
  handleCloseRemove: () => void;
  handleCloseSuccess: () => void;
  handleCloseSecond?: () => void;
  handleCloseThird?: () => void;
  handleCloseFourth?: () => void;
  open: boolean;
  successButton: string | null;
  removeButton: string;
  dialogContent: any;
  dialogTitle: string;
  checked?: boolean;
  secondOption?: string | null;
  thirdOption?: string | null;
  fourthOption?: string | null;
  maxWidth?: any;
  successDisabled?: boolean;
  loading?: boolean;
  loadingText?: string;
}

export default CustomDialog;
