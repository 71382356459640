import { useState, useMemo, useContext } from 'react';
import { Buffer } from 'buffer';
import { gql, useMutation } from '@apollo/client';
import axios from 'axios';
import { Box, Button, Divider, Card, CardActionArea, CardContent, Typography } from '@mui/material';
import Dropzone from 'react-dropzone';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useDropzone } from 'react-dropzone';
import { useSelector } from 'react-redux';

import { useCreateActivity } from '../../features/activity/activityHooks';
import { selectProject } from '../../features/project/projectSlice';
import { selectUser } from '../../features/auth/authSlice';
import { useAppDispatch } from '../../app/hooks';
import { showSuccessSnackbar, showErrorSnackbar } from '../../features/snackbar/snackbarSlice';
import { accessAllowed } from '../../features/project/projectHooks';
import { withRouter } from '../../utils/WithRouter';
import { UnitContext } from '../../context/UnitContext';
import PDFGenerator from '../common/PDFGenerator';
import CustomDialog from '../common/CustomDialog';
import { baseStyle, activeStyle, acceptStyle, rejectStyle } from '../../utils/Constants';

const Floorplan = (props: ChildProps) => {
  const [file, setFile] = useState<any>(null);
  const [fileImage, setFileImage] = useState<any>(null);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const createActivity = useCreateActivity();
  const storeDispatch = useAppDispatch();
  const project = useSelector(selectProject);
  const user = useSelector(selectUser);
  
  const { unit } = useContext(UnitContext);
  const { merge, history } = props;

  const { isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: 'image/jpeg, image/png',
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const [removeMergeTemplate, { loading }] = useMutation(REMOVEMERGETEMPLATE, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Floorplan has been replaced!'));
      createActivity({
        project: project._id,
        user: user._id,
        deal: null,
        title: `Update Unit ${unit.suite}`,
        content: 'Replaced Floorplan',
      });
      history.push(`/${project._id}/dashboard/create-merges`);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const handleDrop = (acceptedFiles: any) => {
    const file = acceptedFiles[0];
    const fileReader = new FileReader();
    if (file) {
      fileReader.readAsDataURL(file);
    }
    fileReader.onloadend = async () => {
      setFileImage(fileReader.result);
      setFile(acceptedFiles[0]);
    };
  };

  const dialogBox = () => {
    let removeButton = 'Cancel';
    let successButton = 'Update Floorplan';
    let title = 'Please drag or upload a floorplan into the box';
    return (
      <CustomDialog
        handleClose={() => setDialogOpen(false)}
        handleCloseRemove={() => setDialogOpen(false)}
        handleCloseSuccess={handleCloseSuccess}
        open={dialogOpen}
        removeButton={removeButton}
        successButton={successButton}
        dialogContent={dialogContent()}
        dialogTitle={title}
      />
    );
  };

  const handleDelete = () => {
    setFile(null);
    setFileImage(null);
  };

  const dialogContent = () => {
    return (
      <div>
        <p>Please confirm with management to replace the selected unit's floorplan.</p>
        {!file ? (
          <Dropzone onDrop={handleDrop} accept="application/pdf">
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps({ style })}>
                  <input {...getInputProps()} />
                  <p>Drag and Drop or Upload the suite's floorplan</p>
                </div>
              </section>
            )}
          </Dropzone>
        ) : (
          <div>
            <Card
              sx={{
                width: '100%',
                border: '1px solid #000',
                backgroundColor: '#f25963',
              }}
            >
              <CardActionArea>
                <Box
                  sx={{
                    height: '200px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    '& .MuiSvgIcon-root': {
                      color: '#fff',
                    },
                  }}
                >
                  <PictureAsPdfIcon fontSize="large" />
                </Box>
                <Divider />
              </CardActionArea>
              <CardContent
                sx={{
                  padding: '10px',
                  backgroundColor: '#fff',
                  '&:last-child': {
                    paddingBottom: '10px',
                  },
                }}
              >
                <Typography
                  sx={{
                    textAlign: 'center',
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    margin: '0 10px',
                  }}
                  component="h4"
                >
                  {file.name}
                  <DeleteForeverIcon sx={{ cursor: 'pointer' }} onClick={() => handleDelete()} />
                </Typography>
              </CardContent>
            </Card>
          </div>
        )}
      </div>
    );
  };

  const handleCloseSuccess = async () => {
    if (!file) {
      storeDispatch(showErrorSnackbar('PDF has not been uploaded'));
    } else {
      const buffer = Buffer.from(fileImage.replace(/^data:application\/\w+;base64,/, ''), 'base64');
      const options = {
        headers: { 'Content-Type': file.type },
        maxContentLength: Infinity,
        maxBodyLength: Infinity,
      };
      await axios.put(unit.putUrl, buffer, options);
      await removeMergeTemplate({ variables: { filter: { name: unit.suite } } });
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      {dialogBox()}
      {merge ? <PDFGenerator merge={fileImage ? fileImage : merge} type={'url'} /> : null}
      <Box
        sx={{
          mt: 1,
          textAlign: 'right',
        }}
      >
        <a
          style={{
            textDecoration: 'none',
            marginRight: '10px',
          }}
          download
          href={`${merge}`}
          target="_blank"
          rel="noreferrer"
        >
          <Button variant="contained" color="secondary">
            Download
          </Button>
        </a>
        {accessAllowed(user, project._id, 'editDeals') ? (
          <Button onClick={() => setDialogOpen(true)} variant="contained" color="primary">
            Replace Floorplan
          </Button>
        ) : null}
      </Box>
    </Box>
  );
};

interface ChildProps {
  merge: any;
  history: any;
}

const REMOVEMERGETEMPLATE = gql`
  mutation mergeTemplateRemoveOne($filter: FilterRemoveOneMergeTemplateInput) {
    mergeTemplateRemoveOne(filter: $filter) {
      record {
        _id
      }
    }
  }
`;

export default withRouter(Floorplan);
