import { BarcodeFormat, DecodeHintType, RGBLuminanceSource, BinaryBitmap, HybridBinarizer, MultiFormatReader } from '@zxing/library';
import { decode } from 'jpeg-js';
import { uppercaseToTitle } from '../../utils/Functions';

export const scanner417Pdf = (buffer: any) => {
  const barcodeScanner = new MultiFormatReader();
  const hints = new Map();
  const formats = [BarcodeFormat.PDF_417, BarcodeFormat.MAXICODE, BarcodeFormat.DATA_MATRIX];
  const rawFileData = decode(buffer, { useTArray: true });

  hints.set(DecodeHintType.POSSIBLE_FORMATS, formats);
  hints.set(DecodeHintType.TRY_HARDER, true);

  let height = rawFileData.height;

  let array = Array.from(Array(Math.ceil(height / 100)).keys());

  let result = [];

  for (const height of array) {
    const len = rawFileData.width * (height * 100);

    const luminancesUint8Array = new Uint8ClampedArray(len);

    for (let i = 0; i < len; i++) {
      luminancesUint8Array[i] = ((rawFileData.data[i * 4] + rawFileData.data[i * 4 + 1] * 2 + rawFileData.data[i * 4 + 2]) / 4) & 0xff;
    }

    const luminanceSource = new RGBLuminanceSource(luminancesUint8Array, rawFileData.width, height * 100);

    const binaryBitmap = new BinaryBitmap(new HybridBinarizer(luminanceSource));

    try {
      const results: any = barcodeScanner.decode(binaryBitmap);
      if (results && results.format === 10) {
        result.push(results);
      }
    } catch (err: any) {
      console.log(err, 'err');
    }
  }

  if (result.length) {
    let text = result[0].text;

    let expiryDate = text.split('DBA')[1].split('\nDCS')[0].trim();
    let dob = text.split('DBB')[1].split('\nDBC')[0].trim();

    const handleDateFormat = (dateString: string) => {
      let year: any = dateString.substring(0, 4);
      let month: any = dateString.substring(4, 6);
      let day: any = dateString.substring(6, 8);

      let date = new Date(year, month - 1, day);
      return date;
    };

    return {
      firstName: `${uppercaseToTitle(text.split('DAC')[1].split('\nDAD')[0].trim())} ${uppercaseToTitle(
        text.split('DAD')[1].split('\nDBD')[0].trim()
      )}`,
      lastName: uppercaseToTitle(text.split('DCS')[1].split('\nDAC')[0].trim()),
      expiryDate: handleDateFormat(expiryDate),
      dob: handleDateFormat(dob),
      streetAddress: uppercaseToTitle(text.split('DAG')[1].split('\nDAI')[0].trim()),
      city: uppercaseToTitle(text.split('DAI')[1].split('\nDAJ')[0].trim()),
      province: text.split('DAJ')[1].split('\nDAK')[0].trim(),
      postalCode: text.split('DAK')[1].split('\nDAQ')[0].trim(),
      country: uppercaseToTitle(text.split('DCG')[1].split('\nDDE')[0]),
      idJurisdiction: 'Ontario',
      idNumber: text.split('DAQ')[1].split('\nDCF')[0].trim(),
    };
  } else return;
};
