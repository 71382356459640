import { useState, useReducer } from 'react';
import { useMutation, gql } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Grid, TextField, FormControlLabel, FormControl, Radio, RadioGroup } from '@mui/material';
import { useSelector } from 'react-redux';

import { selectProject, setProject, setOptions as setProjectOptions } from '../../features/project/projectSlice';
import { useAppDispatch } from '../../app/hooks';
import { showSuccessSnackbar, showErrorSnackbar } from '../../features/snackbar/snackbarSlice';
import { optionsReducer } from '../../features/project/projectHooks';
import AllowedUnits from './AllowedUnits';
import { IOption } from '../../types/project';
import Dropdown from '../common/formControls/Dropdown';
import { unitTypes } from '../../utils/Constants';
import TextInput from '../common/formControls/TextInput';
import { Flex } from '../../commonStyles';

const OptionsInfo = () => {
  const storeDispatch = useAppDispatch();
  const project = useSelector(selectProject);

  const [options, setOptions] = useState(project.options);
  const [optionsState, optionsDispatch] = useReducer(optionsReducer, options);

  const [updateProject] = useMutation(UPDATEPROJECT, {
    onCompleted: (data) => {
      storeDispatch(setProject(data.projectUpdateById.record));
      storeDispatch(showSuccessSnackbar('Options has been updated!'));
      storeDispatch(setProjectOptions(data.projectUpdateById.record.options));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const handleDropdownInput = (event: React.ChangeEvent<{ name?: string; value: string }>, numIndex: number) => {
    if (optionsState[numIndex].allowedUnits.includes(event.target.value)) {
      storeDispatch(showErrorSnackbar('This unit has already been added!'));
      return;
    }

    let newAllowedUnits = [...optionsState[numIndex].allowedUnits, event.target.value];

    optionsDispatch({ type: 'UPDATE', payload: { index: numIndex, field: 'allowedUnits', value: newAllowedUnits } });
  };

  const handleDeleteUnit = async (unitsIndex: number, numIndex: number) => {
    let optionsArray = optionsState.map((option: IOption, index: number) => {
      let optionsIndex = index;
      if (optionsIndex === unitsIndex) {
        let depositStructureInfo = option.allowedUnits.filter((data, index: number) => numIndex !== index);
        return {
          ...option,
          allowedUnits: depositStructureInfo,
        };
      } else {
        return {
          ...option,
        };
      }
    });
    optionsDispatch({
      type: 'UPDATE',
      payload: { index: unitsIndex, field: 'allowedUnits', value: optionsArray[unitsIndex].allowedUnits },
    });
  };

  const addAllUnits = (numIndex: number) => {
    optionsDispatch({ type: 'UPDATE', payload: { index: numIndex, field: 'allowedUnits', value: unitTypes } });
  };

  const handleOptionInput = async (event: any, numIndex: number, type: string) => {
    let value = event.target.value;
    if (type === 'number') {
      value = parseFloat(event.target.value);
    }

    optionsDispatch({ type: 'UPDATE', payload: { index: numIndex, field: event.target.name, value: value } });
  };

  const addOption = () => {
    optionsDispatch({ type: 'ADD' });
  };

  const handleDelete = (arrayIndex: number) => {
    optionsDispatch({ type: 'DELETE', payload: { index: arrayIndex } });
  };

  const handleRadio = (event: React.ChangeEvent<HTMLInputElement>, numIndex: number) => {
    optionsDispatch({ type: 'UPDATE', payload: { index: numIndex, field: 'type', value: event.target.value } });
  };

  const saveOptions = (event: any) => {
    event.preventDefault();
    updateProject({
      variables: {
        _id: project?._id,
        record: {
          options: optionsState,
        },
      },
    });
  };

  return (
    <form onSubmit={saveOptions}>
      <h2>List of Options</h2>
      {optionsState.map((option: IOption, index: number) => {
        return (
          <Box
            key={index}
            sx={{
              padding: '20px',
              border: '1px solid #000',
              borderRadius: '8px',
              marginBottom: '20px',
              position: 'relative',
            }}
          >
            <CloseIcon
              sx={{
                cursor: 'pointer',
                marginLeft: '5px',
                position: 'absolute',
                top: 5,
                right: 5,
                color: '#412920',
              }}
              onClick={() => handleDelete(index)}
            />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl>
                  <RadioGroup
                    onChange={(e: any) => handleRadio(e, index)}
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={option.type}
                    name="radio-buttons-group"
                  >
                    <FormControlLabel value="residential" control={<Radio />} label="Residential" />
                    <FormControlLabel value="commercial" control={<Radio />} label="Commercial" />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  fullWidth
                  label={'Name of Option'}
                  title={'Name of Option'}
                  name={'name'}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOptionInput(e, index, 'string')}
                  value={option.name}
                  required={true}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextInput
                  name={'totalAvailable'}
                  type="number"
                  title={`Total Amount Available`}
                  handleTextInput={(e: HTMLInputElement) => handleOptionInput(e, index, 'number')}
                  value={option.totalAvailable}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextInput
                  name={'price'}
                  type="number"
                  title={'Price'}
                  handleTextInput={(e: HTMLInputElement) => handleOptionInput(e, index, 'number')}
                  value={option.price}
                  adornment={'$'}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextInput
                  name={'rental'}
                  type="number"
                  title={'Increase Rental Guarantee Amount'}
                  handleTextInput={(e: HTMLInputElement) => handleOptionInput(e, index, 'number')}
                  value={option.rental}
                  adornment={'$'}
                />
              </Grid>
            </Grid>
            <Flex sx={{ mt: 2 }}>
              <Box
                sx={{
                  flexGrow: 1,
                  marginRight: '10px',
                }}
              >
                <Dropdown
                  title={'Unit Types That Can Purchase This Option'}
                  menuList={unitTypes}
                  name={'options'}
                  handleSelect={(e: any) => handleDropdownInput(e, index)}
                  value={''}
                />
              </Box>
              <Button variant="contained" color="primary" onClick={() => addAllUnits(index)}>
                Add All Unit Types
              </Button>
            </Flex>
            <AllowedUnits optionsIndex={index} option={option} handleDeleteUnit={handleDeleteUnit} />
          </Box>
        );
      })}
      <div>
        <Button variant="contained" color="primary" onClick={() => addOption()}>
          Add an Option
        </Button>
      </div>
      <Box sx={{ my: 2 }}>
        <Button type="submit" variant="contained" color="success">
          Save Options
        </Button>
      </Box>
    </form>
  );
};

const UPDATEPROJECT = gql`
  mutation projectUpdateById($_id: MongoID!, $record: UpdateByIdProjectInput!) {
    projectUpdateById(_id: $_id, record: $record) {
      record {
        _id
        name
        developerName
        email
        trackingPhone
        addresses {
          streetAddress
          city
          province
          postalCode
        }
        options {
          name
          totalAvailable
          price
          allowedUnits
          type
          rental
        }
        depositStructures {
          name
          deposits {
            name
            type
            amount
            daysDue
            dateType
            dueDate
          }
          default
        }
        mergeTemplates {
          name
          mergeTemplate {
            _id
          }
          apsTemplates {
            name
            apsTemplate {
              _id
              name
            }
            pageNumber
            attachToAps
          }
        }
        status {
          name
          code
          color
        }
        executors {
          name
          email
        }
        mortgageLimit
        mortgageMinDay
        hideOccupancy
        defaultZeroValue
        lawyer
        tagLine
        salesOffice
        adjustments {
          name
          type
        }
        commissionIncludeOptions
        emailTemplates {
          _id
          name
          subject
          html
        }
        logoGetUrl
        logoPutUrl
        imageGetUrl
        imagePutUrl
        coopStructures {
          name
          coopRates {
            type
            days
            date
            description
            percentage
            fixedAmount
          }
        }
        firstTentativeOccupancy
        finalTentativeOccupancy
        firmOccupancy
        outsideOccupancy
        active
        decorModels {
          modelType
          allowed
        }
        portal {
          primaryColor
        }
        combinedProjects {
          _id
          name
        }
      }
    }
  }
`;

export default OptionsInfo;
