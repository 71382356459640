import React, { useState, useEffect, useMemo } from 'react';
import { IUnit, IUnitHistory } from '../../types/unit';
import { convertAllDates } from '../../utils/Functions';
import { Box } from '@mui/material';
import '../../css/datagrids.css';
import StandardTable from '../tables/StandardTable';

const UnitHistory = (props: ChildProps) => {
  const { unit } = props;
  const [history, setHistory] = useState<IUnitHistory[]>([]);

  useEffect(() => {
    convert();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const convert = () => {
    let unitHistory = unit
      .history!.map((history: IUnitHistory) => {
        return {
          _id: history._id,
          type: history.type,
          description: history.description,
          timestamp: convertAllDates(history.timestamp, "PPpp"),
          user: history.user
        };
      })
      .reverse();
    setHistory(unitHistory);
  };

  const columns = useMemo(() => {
    return [
      {
        Header: 'Type',
        accessor: (rowData: any) => rowData.type,
      },
      {
        Header: 'Description',
        accessor: (rowData: any) => rowData.description,
      },
      {
        Header: 'Created At',
        accessor: (rowData: any) => rowData.timestamp,
      },
      {
        Header: 'User',
        accessor: (rowData: any) => rowData.user ? rowData.user.fullName : '',
      },
    ];
  }, []);

  return (
    <Box
      sx={{
        padding: '20px',
        '& .MuiAccordion-root': {
          border: '1px solid #000',
          padding: '4px',
        },
        '& .MuiAccordionDetails-root': {
          display: 'block',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          '& .MuiDataGrid-root': {
            height: '100%',
            width: '100%',
          },
        }}
      >
        <StandardTable columns={columns} data={history} />
      </Box>
    </Box>
  );
};

interface ChildProps {
  unit: IUnit;
}

export default UnitHistory;
