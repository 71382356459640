import { useMemo } from 'react';
import Dropzone, { useDropzone } from 'react-dropzone';
import { Box, Button, Grid, List, ListItem, ListItemText, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAppDispatch } from '../../app/hooks';
import { showSuccessSnackbar } from '../../features/snackbar/snackbarSlice';
import { baseStyle, activeStyle, acceptStyle, rejectStyle } from '../../utils/Constants';

const VideoList = (props: ChildProps) => {
  const storeDispatch = useAppDispatch();
  const { handleDrop, saveMedia, videos, deleteVideos, title, type } = props;

  const { isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: 'video/mp4',
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const copyUrl = (url: string) => {
    navigator.clipboard.writeText(url);
    storeDispatch(showSuccessSnackbar('Url has been copied!'));
  };

  return (
    <Box
      sx={{
        border: '1px solid #000',
        padding: '30px 20px',
        position: 'relative',
        borderRadius: '8px',
        marginBottom: '20px',
      }}
    >
      <h3>{title}</h3>
      <Box sx={{ mb: 3 }}>Upload any Rendering Animations here</Box>
      <div>
        <Dropzone onDrop={(files) => handleDrop(files, type)} accept="video/mp4">
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <p>Drag and Drop or Upload Videos</p>
              </div>
            </section>
          )}
        </Dropzone>
      </div>
      <div>
        <Grid container spacing={2}>
          {videos.length > 0
            ? videos.map((attachment: any, index: number) => {
                return (
                  <Grid key={index} item lg={4} md={4} sm={6} xs={12}>
                    <List>
                      <ListItem
                        sx={{ backgroundColor: '#e5e5e5', borderRadius: '8px' }}
                        secondaryAction={
                          <IconButton edge="end" aria-label="delete">
                            <DeleteIcon color="error" onClick={() => deleteVideos(attachment._id, 'bRoll', index)} />
                          </IconButton>
                        }
                      >
                        <ListItemText
                          primary={attachment.file ? attachment.file.name : attachment.name}
                          secondary={
                            attachment.getUrl ? (
                              <Button color="primary" variant={'contained'} onClick={() => copyUrl(attachment.getUrl)}>
                                Copy Url
                              </Button>
                            ) : (
                              'Video Not Saved'
                            )
                          }
                        />
                      </ListItem>
                    </List>
                  </Grid>
                );
              })
            : null}
        </Grid>
        <Button sx={{ mt: 1 }} onClick={() => saveMedia(type)} name={'update'} color="success" variant="contained">
          Save {type}
        </Button>
      </div>
    </Box>
  );
};

interface ChildProps {
  title: string;
  type: string;
  handleDrop: any;
  saveMedia: (type: string) => void;
  videos: any;
  deleteVideos: any;
}

export default VideoList;
