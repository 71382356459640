import React from 'react';
import { Switch, FormGroup, FormControlLabel } from '@mui/material';

const SwitchButton = (props: ChildProps) => {
  return (
    <div style={props.style}>
      <FormGroup sx={
        {
          display: 'inline',
        }
      }>
        <FormControlLabel control={<Switch checked={props.state} onChange={props.handleChange} />} label={props.title} />
      </FormGroup>
    </div>
  );
};

interface ChildProps {
  state?: boolean;
  handleChange: () => void; 
  title?: string;
  style?: any;
}

export default SwitchButton;
