import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { selectUser } from '../../features/auth/authSlice';
import { Flex } from '../../commonStyles';
import { IStatus } from '../../types/project';
import { IUnit } from '../../types/unit';
import { selectProject } from '../../features/project/projectSlice';
import { unitStatusTitles } from '../../utils/Constants';

const Legend = (props: any) => {
  const { units, legend, setSelectingUnits, setSelectedUnits } = props;
  const user = useSelector(selectUser);
  const project = useSelector(selectProject);
  const [selectedStatus, setSelectedStatus] = useState<string>('');

  let allStatus: IStatus[] = [...project.status, ...unitStatusTitles];

  const countAllStatuses = (code: string) => {
    return units.filter((unit: IUnit) => unit.status === code).length;
  };

  const filterStatuses = (code: string) => {
    if (selectedStatus === code) {
      setSelectedStatus('');
      setSelectedUnits([]);
      setSelectingUnits(false);
    } else {
      setSelectingUnits(true);
      setSelectedStatus(code);
      setSelectedUnits(units.filter((unit: IUnit) => unit.status === code).map((unit: IUnit) => unit));
    }
  };

  return (
    <Box>
      {user.type !== 'Sales' && legend ? (
        <Flex
          sx={{
            '@media (max-width: 600px)': {
              display: 'none',
            },
            flexWrap: 'wrap',
            mr: 2,
            my: 2,
          }}
        >
          {allStatus.map((status, index) => {
            return (
              <Flex onClick={() => filterStatuses(status.code)} sx={{ mr: 2, mt: 1, cursor: 'pointer' }} key={index}>
                <Box
                  sx={{
                    width: '20px',
                    height: '10px',
                    border: '1px solid #000',
                    alignSelf: 'center',
                    mr: 1,
                    backgroundColor: status.color,
                  }}
                ></Box>
                <Box sx={{ alignSelf: 'center', fontWeight: selectedStatus === status.code ? 700 : 400 }}>{status.name}</Box>
                <Box sx={{ alignSelf: 'center', fontWeight: selectedStatus === status.code ? 700 : 400 }}>
                  ({countAllStatuses(status.code)})
                </Box>
              </Flex>
            );
          })}
        </Flex>
      ) : null}
    </Box>
  );
};

export default Legend;
