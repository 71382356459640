import { useState, useContext } from 'react';
import { Box, Card, FormControl, TextField, Grid, Button, Autocomplete } from '@mui/material';
import { useSelector } from 'react-redux';
import { gql, useQuery, useMutation } from '@apollo/client';
import PageTitle from '../../../common/PageTitle';
import { CreateDealContext } from '../../../../context/CreateDealContext';
import { IRealtorsArray } from '../../../../types/CreateDealForm';
import { noRealtorOptions } from '../../../../utils/Constants';
import RealtorForm from '../../../common/forms/RealtorForm';
import { FlexBetween } from '../../../../commonStyles';
import { useAppDispatch } from '../../../../app/hooks';
import { showSuccessSnackbar } from '../../../../features/snackbar/snackbarSlice';
import { formatPhoneNumber } from '../../../../utils/Functions';
import Commission from './Commission';
import { selectProject } from '../../../../features/project/projectSlice';

const RealtorStep = () => {
  const storeDispatch = useAppDispatch();
  const project = useSelector(selectProject);
  const [searchInfo, setSearchInfo] = useState<any>([]);
  const [errors, setErrors] = useState<any>({});
  const {
    realtorInfo,
    setRealtorInfo,
    noRealtor,
    setNoRealtor,
    setShowForm,
    showForm,
    activeStep,
    setActiveStep,
    realtorCount,
    setRealtorCount,
    setRealtorId,
    realtorId,
    coopRates,
    setCoopRates,
  } = useContext(CreateDealContext);

  useQuery<IRealtorsArray>(REALTORS, {
    variables: { filter: { disable: false } },
    onCompleted: (data) => setSearchInfo(data.realtorMany),
  });

  const [saveNewRealtor] = useMutation(CREATEREALTOR, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Realtor Information has been saved!'));
      setRealtorCount(realtorCount + 1);
      setRealtorId(data.realtorCreateOne.record._id);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [updateRealtor] = useMutation(UPDATEREALTOR, {
    variables: { _id: realtorId, record: realtorInfo },
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Realtor Information has been updated!'));
      setShowForm(!showForm);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const handleTextInput = async (event: any) => {
    let value = event.target.value;
    if (event.target.name === 'brokeragePhone' || event.target.name === 'directPhone' || event.target.name === 'brokerageFax') {
      value = formatPhoneNumber(event.target.value);
    }
    setRealtorInfo({
      ...realtorInfo,
      [event.target.id]: value,
    });
    if (event.target.value === '') {
      setErrors({
        ...errors,
        [event.target.id]: `${event.target.name} cannot be empty`,
      });
    } else {
      setErrors({
        ...errors,
        [event.target.id]: null,
      });
    }
  };

  const handleRealtorTypeChange = (event: any, value: string) => {
    setNoRealtor({
      ...noRealtor,
      noRealtorType: value,
    });
  };

  const handleSearchInput = (event: any, values: any) => {
    const { _id, ...newObject } = values;
    setRealtorInfo(newObject);
    setRealtorId(_id);
    setRealtorCount(realtorCount + 1);
    setNoRealtor({ ...noRealtor, noRealtor: false });
  };

  const deleteRealtor = () => {
    setRealtorInfo({
      firstName: '',
      lastName: '',
      brokerage: '',
      streetAddress: '',
      city: '',
      province: '',
      country: '',
      postalCode: '',
      brokeragePhone: '',
      brokerageFax: '',
      directPhone: '',
    });
    setCoopRates([]);
    setRealtorCount(0);
    setRealtorId(null);
  };

  const handlePublicButton = () => {
    setShowForm(true);
    setNoRealtor({ ...noRealtor, noRealtor: true });
  };

  const handlePreviousButton = () => {
    setShowForm(false);
    setNoRealtor({ ...noRealtor, noRealtor: false });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    let { _id, project, ...newObject } = realtorInfo;

    saveNewRealtor({ variables: { record: newObject } });
    setShowForm(!showForm);
  };

  return (
    <div>
      <PageTitle title={'Realtor/Brokerage Info'} />
      {!showForm ? (
        <div>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <FormControl
                sx={{
                  width: '100%',
                  '& .MuiFormLabel-asterisk': {
                    color: 'red',
                  },
                }}
              >
                <Autocomplete
                  sx={{
                    width: '100%',
                    '& .MuiFormLabel-asterisk': {
                      color: 'red',
                    },
                  }}
                  id={'search'}
                  disableClearable
                  freeSolo={false}
                  options={searchInfo}
                  getOptionLabel={(option: any) => `${option.firstName} ${option.lastName} ${option.email}`}
                  onChange={handleSearchInput}
                  renderInput={(params) => (
                    <TextField required={false} {...params} size="small" label={'Search By Name or Email'} margin="normal" />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
          <div>
            {realtorCount > 0 ? (
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
                    <Card
                      sx={{
                        width: '100%',
                        border: '1px solid #000',
                        p: 2,
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        fontSize: '14px',
                      }}
                    >
                      {noRealtor.noRealtor ? (
                        <div>No Realtor</div>
                      ) : (
                        <div>
                          <div>
                            <div>
                              Realtor Name:{' '}
                              <strong>
                                {realtorInfo.firstName} {realtorInfo.lastName}
                              </strong>
                            </div>
                            <Box sx={{ mt: 1 }}>
                              Realtor Address:{' '}
                              <strong>
                                {realtorInfo.streetAddress} {realtorInfo.city} {realtorInfo.province} {realtorInfo.country}{' '}
                                {realtorInfo.postalCode}
                              </strong>
                            </Box>
                            <Box sx={{ mt: 1 }}>
                              Realtor Email: <strong>{realtorInfo.email}</strong>
                            </Box>
                            <Box sx={{ mt: 1 }}>
                              Realtor Phone Number: <strong>{realtorInfo.directPhone}</strong>
                            </Box>
                            <Box sx={{ mt: 1 }}>
                              Brokerage: <strong>{realtorInfo.brokerage}</strong>
                            </Box>
                          </div>
                          <FlexBetween sx={{ mt: 2 }}>
                            <Button onClick={() => setShowForm(!showForm)} variant="contained" color="primary">
                              Edit
                            </Button>
                            <Button onClick={() => deleteRealtor()} variant="contained" color="error">
                              Delete
                            </Button>
                          </FlexBetween>
                        </div>
                      )}
                    </Card>
                  </Grid>
                </Grid>
                {project.coopStructures ? <Commission coopRates={coopRates} setCoopRates={setCoopRates} /> : null}
              </Box>
            ) : null}
          </div>
        </div>
      ) : (
        <div>
          {noRealtor.noRealtor ? (
            <div>
              <div>
                <Autocomplete
                  options={noRealtorOptions}
                  title={'No Realtor Type'}
                  freeSolo={false}
                  value={noRealtor.noRealtorType}
                  onChange={handleRealtorTypeChange}
                  renderInput={(params) => <TextField {...params} variant="standard" label="No Realtor Type" />}
                />
              </div>
              <FlexBetween sx={{ mt: 2 }}>
                <Button onClick={() => handlePreviousButton()} variant="contained" color="primary">
                  Go Back
                </Button>
                <Button variant="contained" color="primary" onClick={() => setActiveStep(activeStep + 1)}>
                  Next
                </Button>
              </FlexBetween>
            </div>
          ) : (
            <div>
              <div>
                <form onSubmit={handleSubmit} autoComplete="off">
                  <RealtorForm realtorInfo={realtorInfo} handleTextInput={handleTextInput} />
                  <FlexBetween sx={{ mt: 2 }}>
                    {realtorCount > 0 ? (
                      <Button name={'update'} onClick={() => updateRealtor()} color="success" variant="contained">
                        Update Realtor
                      </Button>
                    ) : (
                      <Button name={'save'} type="submit" color="success" variant="contained">
                        Save New Realtor
                      </Button>
                    )}
                    <Button onClick={() => setShowForm(!showForm)} variant="contained">
                      Cancel
                    </Button>
                  </FlexBetween>
                </form>
              </div>
            </div>
          )}
        </div>
      )}
      {!showForm ? (
        <div>
          {realtorCount > 0 ? (
            <FlexBetween sx={{ mt: 2 }}>
              <Button variant="contained" color="primary" onClick={() => setActiveStep(activeStep - 1)}>
                Back
              </Button>
              <Button variant="contained" color="primary" onClick={() => setActiveStep(activeStep + 1)}>
                Next
              </Button>
            </FlexBetween>
          ) : (
            <div>
              <Box sx={{ mt: 2 }}>
                <Button sx={{ mr: 1 }} variant="contained" color="primary" onClick={() => setActiveStep(activeStep - 1)}>
                  Back
                </Button>
                <Button sx={{ mr: 1 }} color="primary" onClick={() => setShowForm(true)} variant="contained">
                  Add New Realtor
                </Button>
                <Button color="primary" onClick={() => handlePublicButton()} variant="contained">
                  Public Deal
                </Button>
              </Box>
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

const REALTORS = gql`
  query realtorMany($filter: FilterFindManyRealtorInput) {
    realtorMany(filter: $filter, limit: 10000) {
      _id
      email
      firstName
      lastName
      brokerage
      streetAddress
      city
      province
      country
      postalCode
      brokeragePhone
      brokerageFax
      directPhone
      disable
    }
  }
`;

const CREATEREALTOR = gql`
  mutation realtorCreateOne($record: CreateOneRealtorInput!) {
    realtorCreateOne(record: $record) {
      record {
        _id
        email
        firstName
        lastName
        streetAddress
        brokerage
        brokerageFax
        brokeragePhone
        city
        province
        country
        postalCode
        directPhone
      }
    }
  }
`;

const UPDATEREALTOR = gql`
  mutation realtorUpdateById($_id: MongoID!, $record: UpdateByIdRealtorInput!) {
    realtorUpdateById(_id: $_id, record: $record) {
      record {
        email
        firstName
        lastName
        streetAddress
        brokerage
        brokerageFax
        brokeragePhone
        city
        province
        country
        postalCode
        directPhone
      }
    }
  }
`;

export default RealtorStep;
