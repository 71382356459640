import { Fragment, Dispatch, SetStateAction } from 'react';
import { Box, Typography, Grid, TextField, Button, InputAdornment } from '@mui/material';
import { IDate } from './CreateSchedule';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { addSeconds, differenceInSeconds } from 'date-fns';

import { FlexBetween } from '../../../commonStyles';
import { timeZoneDate } from '../../../utils/Functions';

const Calendar = (props: ChildProps) => {
  const { dates, datesDispatch } = props;
  const startTime = new Date();
  startTime.setHours(0, 0, 0, 0);

  const handleDateChange = (date: any, index: number) => {
    datesDispatch({ type: 'UPDATE', payload: { field: 'date', value: new Date(date).setHours(10,0,0,0), index: index } });
  };

  const handleTimeChange = (time: number, index: number, type: string) => {
    datesDispatch({ type: 'UPDATE', payload: { field: type, value: differenceInSeconds(time, startTime), index: index } });
  };

  const handleTextField = (e: any, index: number) => {
    datesDispatch({ type: 'UPDATE', payload: { field: e.target.name, value: e.target.value, index: index } });
  };

  return (
    <Box>
      <FlexBetween sx={{ mb: 2 }}>
        <Typography variant={'h5'} gutterBottom>
          <strong>Dates</strong>
        </Typography>
        <Button onClick={() => datesDispatch({ type: 'ADD' })} variant="contained" color="primary">
          Add Date
        </Button>
      </FlexBetween>
      <Grid container spacing={2}>
        {dates.map((date: IDate, index: number) => {
          return (
            <Fragment key={index}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Grid item sm={6} md={3} lg={2}>
                  <DatePicker
                    inputFormat="yyyy/MM/dd"
                    label={'YYYY/MM/DD'}
                    value={date.date}
                    onChange={(date: any) => handleDateChange(date, index)}
                    renderInput={(params) => <TextField sx={{ width: '100%' }} {...params} />}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2}>
                  <TimePicker
                    label="Start Time"
                    // style={{ width: "100%" }}
                    value={addSeconds(startTime, date.timeStart)}
                    onChange={(time: any) => handleTimeChange(time, index, 'timeStart')}
                    renderInput={(params: any) => <TextField sx={{ width: '100%' }} {...params} />}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2}>
                  <TimePicker
                    label="End Time"
                    // style={{ width: "100%" }}
                    value={addSeconds(startTime, date.timeEnd)}
                    onChange={(time: any) => handleTimeChange(time, index, 'timeEnd')}
                    renderInput={(params: any) => <TextField sx={{ width: '100%' }} {...params} />}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2}>
                  <TextField
                    fullWidth
                    label={'Time Between Appointments'}
                    name={'timeBetweenAppointments'}
                    type={'number'}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTextField(e, index)}
                    value={date.timeBetweenAppointments}
                    InputProps={{
                      endAdornment: <InputAdornment position="start">min</InputAdornment>,
                    }}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={1}>
                  <TextField
                    fullWidth
                    label={'# Per Timeslot'}
                    name={'numberOfAppointments'}
                    type={'number'}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTextField(e, index)}
                    value={date.numberOfAppointments}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={1}>
                  <TextField
                    fullWidth
                    label={'Length'}
                    name={'length'}
                    type={'number'}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTextField(e, index)}
                    value={date.length}
                    InputProps={{
                      endAdornment: <InputAdornment position="start">min</InputAdornment>,
                    }}
                    required
                  />
                </Grid>
                <Grid sx={{ alignSelf: 'center', textAlign: 'center' }} item xs={12} sm={6} md={6} lg={1}>
                  <Button
                    onClick={() =>
                      datesDispatch({
                        type: 'COPY',
                        payload: {
                          date: date.date,
                          length: date.length,
                          timeBetweenAppointments: date.timeBetweenAppointments,
                          timeStart: date.timeStart,
                          timeEnd: date.timeEnd,
                          numberOfAppointments: date.numberOfAppointments,
                        },
                      })
                    }
                    sx={{ width: '100%' }}
                    variant="contained"
                    color="primary"
                  >
                    Copy
                  </Button>
                </Grid>
                <Grid sx={{ alignSelf: 'center', textAlign: 'center' }} item xs={12} sm={6} md={6} lg={1}>
                  <Button
                    onClick={() => datesDispatch({ type: 'DELETE', payload: { index: index } })}
                    sx={{ width: '100%' }}
                    variant="contained"
                    color="error"
                  >
                    Delete
                  </Button>
                </Grid>
              </LocalizationProvider>
            </Fragment>
          );
        })}
      </Grid>
    </Box>
  );
};

interface ChildProps {
  dates: IDate[];
  datesDispatch: any;
}

export default Calendar;
