import React, { useContext, useState, useEffect, useMemo } from 'react';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { useDropzone } from 'react-dropzone';
import { Box, ToggleButton, ToggleButtonGroup, FormControl, Select, InputLabel, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';

import DealSummary from './dealSummary/DealSummary';
import { UnitContext } from '../../../context/UnitContext';
import { IDepositStructures } from '../../../types/project';
import { IDeal, IPurchaserInfo, IDealDeposit } from '../../../types/CreateDealForm';
import { IUnit } from '../../../types/unit';
import { convertAllDates } from '../../../utils/Functions';
import Dropdown from '../../common/formControls/Dropdown';
import PurchaserInfo from './purchaser/PurchaserInfo';
import RealtorInfo from './realtor/RealtorInfo';
import DepositInfo from './deposits/DepositInfo';
import OptionInfo from './options/OptionInfo';
import UnitTransfer from './unitTransfer/UnitTransfer';
import UnitInfo from '../UnitInfo';
import Envelopes from './envelopes/Envelopes';
import SupplementalDocuments from './supportingDocs/SupplementalDocuments';
import { FlexBetween, FlexEnd } from '../../../commonStyles';
import { useSelector } from 'react-redux';
import { selectProject } from '../../../features/project/projectSlice';
import { useAppDispatch } from '../../../app/hooks';
import { baseStyle, activeStyle, acceptStyle, rejectStyle } from '../../../utils/Constants';
import { showSuccessSnackbar, showErrorSnackbar } from '../../../features/snackbar/snackbarSlice';
import { DealProvider } from '../../../context/DealContext';

const DealInfo = (props: ChildProps) => {
  const storeDispatch = useAppDispatch();
  const project = useSelector(selectProject);
  const { openDialog, handleIndexChange } = props;
  const { unit, pastDeals, setPastDeals, filteredDeal, setFilteredDeal, getRealtors } = useContext(UnitContext);
  const [selectedDeposit, setSelectedDeposit] = useState<string>('');
  const [errors, setErrors] = useState<any>(null);
  const [fileImage, setFileImage] = useState<any>([]);
  const [depositImages, setDepositImages] = useState<any>(filteredDeal && filteredDeal.depositImages ? filteredDeal.depositImages : []);
  const [filter, setFilter] = useState<string>('Summary');

  const { isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: 'image/jpeg, image/png',
  });

  useEffect(() => {
    if (filteredDeal) {
      setDepositImages(filteredDeal.depositImages);
    }
  }, [filteredDeal]);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const depositList = () => {
    let depositNames = project.depositStructures.map((deposit: IDepositStructures, index: number) => {
      return deposit.name;
    });
    return depositNames;
  };

  const handleDepositSubmit = async (e: any) => {
    e.preventDefault();
    if (errors) {
      storeDispatch(showErrorSnackbar('Invalid Date'));
      return;
    }

    let newAdditionalDeposits: IDealDeposit[] = [];
    let newPendingDeposits: IDealDeposit[] = [];

    if (filteredDeal.pendingDeposits.length > 0) {
      newPendingDeposits = filteredDeal.pendingDeposits.map((deposit: IDealDeposit) => {
        return {
          accountNumber: deposit.accountNumber,
          amount: deposit.amount,
          chequeAmount: deposit.chequeAmount,
          chequeDate: deposit.chequeDate,
          chequeNumber: deposit.chequeNumber,
          chequeType: deposit.chequeType,
          dueDate: deposit.dueDate,
          name: deposit.name,
          _id: deposit._id,
        };
      });
    }

    if (filteredDeal.additionalDeposits.length > 0) {
      newAdditionalDeposits = filteredDeal.additionalDeposits.map((deposit: IDealDeposit) => {
        return {
          accountNumber: deposit.accountNumber,
          amount: deposit.amount,
          chequeAmount: deposit.chequeAmount,
          chequeDate: deposit.chequeDate,
          chequeNumber: deposit.chequeNumber,
          chequeType: deposit.chequeType,
          dueDate: deposit.dueDate,
          name: deposit.name,
          _id: deposit._id,
        };
      });
    }

    let newDeposits = filteredDeal.deposit.map((deposit: IDealDeposit) => {
      if (deposit.chequeType === 'cleared') {
        return {
          accountNumber: deposit.accountNumber,
          amount: deposit.amount,
          chequeAmount: deposit.chequeAmount,
          chequeDate: deposit.chequeDate,
          chequeNumber: deposit.chequeNumber,
          chequeType: deposit.chequeType,
          dueDate: deposit.dueDate,
          clearedDate: new Date(),
          name: deposit.name,
          _id: deposit._id,
        };
      } else {
        return {
          accountNumber: deposit.accountNumber,
          amount: deposit.amount,
          chequeAmount: deposit.chequeAmount,
          chequeDate: deposit.chequeDate,
          chequeNumber: deposit.chequeNumber,
          chequeType: deposit.chequeType,
          dueDate: deposit.dueDate,
          name: deposit.name,
          _id: deposit._id,
        };
      }
    });

    updateDealDeposit({
      variables: {
        depositArray: newDeposits,
        additionalDepositArray: newAdditionalDeposits,
        pendingDepositArray: newPendingDeposits,
        unitId: unit._id,
        dealId: filteredDeal._id,
      },
    });
  };

  const [getPastDeals] = useLazyQuery(GETPASTDEALS, {
    fetchPolicy: 'cache-first',
    variables: {
      filter: {
        unit: unit._id,
        project: project._id,
        cancelledDeals: true,
      },
    },
    onCompleted: (data) => {
      setPastDeals(data.dealMany);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [updateDealDeposit] = useMutation(UPDATEDEPOSIT, {
    onCompleted: (data) => {
      // Get Old Data to Compare with New Data
      setFilteredDeal({
        ...filteredDeal,
        deposit: data.updateDeposits,
      });
      storeDispatch(showSuccessSnackbar('Deposit(s) have been updated!'));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  useEffect(() => {
    handleImageState();
  }, []);

  // useEffect(() => {
  //   getDocument();
  // }, [filteredDeal.documents]);

  // Document

  // const getDocument = () => {
  //   if (filteredDeal.documents && filteredDeal.documents.length) {
  //     let selectedDocument = filteredDeal.documents.find((document: IDocuments) => {
  //       if (document.status !== 'Completed') {
  //         return document.type === 'UnitTransfer' || document.type === 'Deposit' || document.type === 'Options';
  //       }
  //     });
  //     if (selectedDocument) {
  //       setDocument(selectedDocument.type);
  //     }
  //   }
  // };

  const handleImageState = () => {
    let imageArray: any = [];
    let idArray: any = [];

    let purchasers = filteredDeal?.purchasers;
    if (purchasers) {
      for (let i = 0; i < purchasers.length; i++) {
        imageArray.push({
          url: purchasers[i]?.getUrl,
          file: '',
        });
        idArray.push(purchasers[i].identifications);
      }
      setFileImage(imageArray);
    }
  };

  const handleDrop = (acceptedFiles: any, numIndex: number, type: string) => {
    if (acceptedFiles.length === 0) {
      storeDispatch(showErrorSnackbar('This file type is not allowed'));
      return;
    }
    let imageArray: any = [];
    if (type === 'proof') {
      imageArray = fileImage;
    } else if (type === 'purchaser') {
    } else if (type === 'deposit') {
      imageArray = depositImages;
    }

    const file = acceptedFiles[0];
    const fileReader = new FileReader();
    if (file) {
      fileReader.readAsDataURL(file);
    }
    fileReader.onloadend = async () => {
      let newArray = imageArray.map((image: any, index: number) => {
        if (numIndex === index) {
          return { file: file, url: fileReader.result };
        } else return image;
      });

      if (type === 'proof') {
        setFileImage(newArray);
      } else if (type === 'purchaser') {
        let selectedPurchaser = filteredDeal.purchasers.map((purchaser: IPurchaserInfo, index: number) => {
          if (index === numIndex) {
            return {
              ...purchaser,
              identifications: [...purchaser.identifications, { file: file, url: fileReader.result, name: file.name }],
            };
          } else return purchaser;
        });
        setFilteredDeal({
          ...filteredDeal,
          purchasers: selectedPurchaser,
        });
      } else if (type === 'deposit') {
        setFilteredDeal({
          ...filteredDeal,
          depositImages: [
            ...filteredDeal.depositImages,
            {
              url: fileReader.result,
              file: file,
            },
          ],
        });
      }
    };
  };

  const handleDealDropdown = (event: React.ChangeEvent<{ name?: string; value: string }>) => {
    let deal = pastDeals.find((filteredDeal: IDeal) => filteredDeal._id === event.target.value);
    setFilteredDeal(deal);
  };

  const handleDialog = (type: string, index: number) => {
    handleIndexChange(index);
    if (type === 'addRealtor' || type === 'rescind' || type === 'cancel') {
      getRealtors();
    }
    openDialog(type);
  };

  const handleFilter = (event: React.MouseEvent<HTMLElement>, newFilter: string) => {
    if (newFilter !== null) {
      setFilter(newFilter);
    }
  };

  const tabList = ['Summary', 'Purchasers', 'Realtors', 'Deposits', 'Options', 'Unit Transfer', 'Documents', 'Supporting Docs'];

  const handleDropdownFilter = (e: any) => {
    setFilter(e.target.value);
  };

  return (
    <Box
      sx={{
        '& .MuiAccordion-root': {
          border: '1px solid #000',
          padding: '4px',
        },
        '& .MuiAccordionDetails-root': {
          display: 'block',
        },
      }}
    >
      <div>
        <FlexBetween
          sx={{
            flexWrap: 'wrap',
            mb: 2,
          }}
        >
          {filteredDeal ? (
            <>
              <ToggleButtonGroup
                sx={{
                  '& .Mui-selected': {
                    // <-- mixing the two classes
                    backgroundColor: '#002044 !important',
                    color: '#fff !important',
                  },
                  color: '#002044 !important',
                  '@media (max-width: 600px)': {
                    display: 'none',
                  },
                  marginBottom: '10px',
                }}
                value={filter}
                exclusive
                onChange={handleFilter}
              >
                {tabList.map((menu: string) => {
                  return (
                    <ToggleButton
                      sx={{
                        color: '#000',
                        border: '2px solid #002044',
                      }}
                      value={menu}
                    >
                      {menu}
                    </ToggleButton>
                  );
                })}
              </ToggleButtonGroup>
              <Box
                sx={{
                  display: 'none',
                  '@media (max-width: 600px)': {
                    display: 'block',
                  },
                }}
              >
                <Dropdown
                  title={'Select a Tab'}
                  menuList={tabList.map((tab: string) => tab)}
                  name={'Options'}
                  handleSelect={(e: any) => handleDropdownFilter(e)}
                  value={filter}
                />
              </Box>
            </>
          ) : null}
          <FlexEnd>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Past deals</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Past Deals"
                onOpen={() => getPastDeals()}
                onChange={(e: any) => handleDealDropdown(e)}
                sx={{ width: '150px' }}
              >
                {pastDeals.map((deal: IDeal, index: number) => {
                  let date = new Date();
                  if (deal && deal.rescission.dateRescinded) {
                    date = deal.rescission.dateRescinded;
                  } else if (deal && deal.cancelled.dateCancelled) {
                    date = deal.cancelled.dateCancelled;
                  }
                  return (
                    <MenuItem value={deal._id} key={index}>
                      {deal.purchasers[0].lastName} - {convertAllDates(date, 'P')}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </FlexEnd>
        </FlexBetween>
        <Box
          sx={{
            display: 'flex',
            '@media (max-width: 600px)': {
              flexDirection: 'column-reverse',
            },
          }}
        >
          <Box
            sx={{
              marginRight: '20px',
              '@media (max-width: 600px)': {
                marginRight: '0',
              },
            }}
          >
            <UnitInfo />
          </Box>
          <div style={{ flexGrow: 1 }}>
            <DealProvider>
              {filter === 'Summary' ? (
                <DealContainer>
                  <h2
                    style={{
                      margin: 0,
                    }}
                  >
                    Deal Information
                  </h2>

                  <DealSummary />
                </DealContainer>
              ) : null}
              {filter === 'Purchasers' ? (
                <DealContainer>
                  <PurchaserInfo handleDrop={handleDrop} style={style} openDialog={openDialog} handleDialog={handleDialog} />
                </DealContainer>
              ) : null}
              {filter === 'Realtors' ? (
                <DealContainer>
                  <RealtorInfo />
                </DealContainer>
              ) : null}
              {filter === 'Deposits' ? (
                <DealContainer>
                  <h2 style={{ margin: 0 }}>Deposits</h2>
                  <DepositInfo
                    style={style}
                    handleDrop={handleDrop}
                    openDialog={openDialog}
                    depositList={depositList}
                    handleDepositSubmit={handleDepositSubmit}
                    selectedDeposit={selectedDeposit}
                    setSelectedDeposit={setSelectedDeposit}
                  />
                </DealContainer>
              ) : null}
              {filter === 'Options' ? (
                <DealContainer>
                  <h2 style={{ margin: 0 }}>Options</h2>
                  <OptionInfo />
                </DealContainer>
              ) : null}
              {filter === 'Documents' ? (
                <DealContainer>
                  <Envelopes />
                </DealContainer>
              ) : null}
              {filter === 'Supporting Docs' ? <SupplementalDocuments /> : null}
              {filteredDeal &&
              !filteredDeal.rescission.dateRescinded &&
              !filteredDeal.cancelled.dateCancelled &&
              filter === 'Unit Transfer' ? (
                <DealContainer>
                  <h2 style={{ margin: 0 }}>Unit Transfer</h2>
                  <UnitTransfer />
                </DealContainer>
              ) : null}
            </DealProvider>
          </div>
        </Box>
      </div>
    </Box>
  );
};

export const DealContainer = styled(Box)`
  border: 1px solid #000;
  padding: 20px;
  marginbottom: 30px;
`;

interface ChildProps {
  openDialog: any;
  checked: boolean;
  handleCheckChange: any;
  handleIndexChange: (index: number) => void;
}

const UPDATEDEPOSIT = gql`
  mutation updateDeposits(
    $depositArray: [updateCurrentDepositInput!]
    $additionalDepositArray: [updateDepositInput!]
    $pendingDepositArray: [updateDepositInput!]
    $unitId: MongoID!
    $dealId: MongoID!
  ) {
    updateDeposits(
      depositArray: $depositArray
      additionalDepositArray: $additionalDepositArray
      pendingDepositArray: $pendingDepositArray
      unitId: $unitId
      dealId: $dealId
    ) {
      _id
      name
      amount
      dueDate
      accountNumber
      chequeNumber
      chequeDate
      chequeType
      chequeAmount
      deal {
        _id
      }
    }
  }
`;

const GETPASTDEALS = gql`
  query dealMany($filter: FilterFindManyDealInput) {
    dealMany(filter: $filter) {
      _id
      project {
        _id
        decorModels {
          modelType
          allowed
        }
        tags
      }
      unit {
        _id
        status
        rental
        modelType
        unitType
      }
      purchasers {
        _id
        email
        firstName
        lastName
        identifications {
          _id
          name
          getUrl
        }
        corp
        sin
        dob
        streetAddress
        city
        province
        country
        postalCode
        occupation
        employer
        directors
        businessNumber
        signingOfficers {
          fullName
          dob
          sin
          primaryPhone
          streetAddress
          email
        }
        purchaserType
        primaryPhone
        idType
        idNumber
        idExpiry
        proofExpiry
        proofNumber
        proofType
        unit
        idJurisdiction
        getUrl
        putUrl
      }
      realtor {
        _id
        email
        firstName
        lastName
        fullName
        brokerage
        streetAddress
        city
        province
        country
        postalCode
        brokeragePhone
        brokerageFax
        directPhone
        coopRates {
          type
          days
          date
          description
          percentage
          fixedAmount
        }
      }
      depositName
      deposit {
        _id
        name
        amount
        dueDate
        accountNumber
        chequeNumber
        clearedDate
        chequeDate
        chequeType
        chequeAmount
        deal {
          _id
        }
      }
      additionalDeposits {
        _id
        name
        amount
        dueDate
        accountNumber
        chequeNumber
        chequeDate
        chequeType
        chequeAmount
        deal {
          _id
        }
      }
      pendingDeposits {
        _id
        name
        amount
        dueDate
        accountNumber
        chequeNumber
        chequeAmount
        chequeDate
        chequeType
        deal {
          _id
        }
      }
      pendingOptions {
        name
        purchaseAmount
        amount
      }
      pendingUnit {
        basePrice
        suite
        _id
      }
      joinWaitlist
      options {
        name
        purchaseAmount
        amount
      }
      documents {
        _id
        name
        project {
          _id
        }
        status
        isAPS
        type
        dsEnvelopeId
        getUrl
        createdAt
        updatedAt
      }
      basePrice
      totalPrice
      tags
      mortgage {
        _id
        revisions
        lender
        amount
        getUrl
        putUrl
      }
      debit {
        name
        institution
        branch
        account
        accountHolders {
          fullName
          email
        }
      }
      solicitor {
        solicitor
        firm
        streetAddress
        city
        province
        postalCode
        email
        primaryPhone
      }
      miscellaneous {
        _id
        name
        getUrl
      }
      rescission {
        dateRescinded
        reason
      }
      cancelled {
        dateCancelled
        type
        reason
      }
      salesRep {
        _id
        fullName
      }
      depositImages {
        _id
        name
        getUrl
      }
      thirdParty {
        _id
        fullName
        streetAddress
        dob
        primaryPhone
        email
        occupation
        corpNumber
        relationship
      }
      worksheet {
        _id
        status
      }
      adjustments {
        name
        type
        percentage
        value
        description
        approval
      }
      construction {
        name
        type
        value
        description
        approval
        requiresChange
        tbd
        completed
      }
      upgradeSetOne {
        date
        salesRep {
          _id
          fullName
        }
        collectionTemplate {
          _id
          name
          upgradeTemplates {
            _id
            name
            price
            mainCategory
            subCategory
            lock
            hide
            schemes
            unitsAllowed
            modelsAllowed
            sessions
            freeUpgrades
            upgradeType
          }
          unitsAllowed
          modelsAllowed
        }
        upgradeSet {
          _id
          name
          price
          mainCategory
          subCategory
          quantity
          upgradeTemplate {
            _id
            name
            price
            mainCategory
            subCategory
            lock
            hide
            unitsAllowed
            modelsAllowed
            freeUpgrades
            upgradeType
            getUrl
          }
        }
      }
      upgradeSetTwo {
        date
        salesRep {
          _id
          fullName
        }
        collectionTemplate {
          _id
          name
          upgradeTemplates {
            _id
            name
            price
            mainCategory
            subCategory
            lock
            hide
            schemes
            unitsAllowed
            modelsAllowed
            sessions
            freeUpgrades
            upgradeType
          }
          unitsAllowed
          modelsAllowed
        }
        upgradeSet {
          _id
          name
          price
          mainCategory
          subCategory
          quantity
          upgradeTemplate {
            _id
            name
            price
            mainCategory
            subCategory
            lock
            hide
            unitsAllowed
            modelsAllowed
            freeUpgrades
            upgradeType
            getUrl
          }
        }
      }
      upgradeSetThree {
        date
        salesRep {
          _id
          fullName
        }
        collectionTemplate {
          _id
          name
          upgradeTemplates {
            _id
            name
            price
            mainCategory
            subCategory
            lock
            hide
            schemes
            unitsAllowed
            modelsAllowed
            sessions
            freeUpgrades
            upgradeType
          }
          unitsAllowed
          modelsAllowed
        }
        upgradeSet {
          _id
          name
          price
          mainCategory
          subCategory
          quantity
          upgradeTemplate {
            _id
            name
            price
            mainCategory
            subCategory
            lock
            hide
            unitsAllowed
            modelsAllowed
            freeUpgrades
            upgradeType
            getUrl
          }
        }
      }
      draft
      preparedDate
      signDate
      executeDate
      firmDate
      auditor {
        _id
        fullName
      }
      auditDate
      createdAt
    }
  }
`;

export default DealInfo;
