import { useState, useMemo } from 'react';
import subYears from 'date-fns/subYears';
import Dropzone, { useDropzone } from 'react-dropzone';
import { gql, useLazyQuery } from '@apollo/client';
import { Autocomplete, TextField, Box, Grid, Switch, FormControlLabel, Button, Divider } from '@mui/material';
import { IPurchaserInfo, IPurchasersArray } from '../../../types/CreateDealForm';
import { useSelector } from 'react-redux';
import { selectProject } from '../../../features/project/projectSlice';
import PurchaserForm from '../../common/forms/PurchaserForm';
import { useAppDispatch } from '../../../app/hooks';
import { showErrorSnackbar } from '../../../features/snackbar/snackbarSlice';
import { ISigningOfficer } from '../../../types/CreateDealForm';
import PdfCard from '../../common/PdfCard';
import { IIdentification } from '../../../types/CreateDealForm';
import { formatSin, formatPhoneNumber } from '../../../utils/Functions';
import { FlexBetween, FlexEnd } from '../../../commonStyles';
import { baseStyle, activeStyle, acceptStyle, rejectStyle } from '../../../utils/Constants';

const AssignmentForm = (props: ChildProps) => {
  const { purchasers, purchasersDispatch, title, newAssignment } = props;
  const storeDispatch = useAppDispatch();
  const project = useSelector(selectProject);
  const [random, setRandom] = useState<number>(Math.floor(Math.random() * 1000000 + 1));
  const [searchInfo, setSearchInfo] = useState<IPurchaserInfo[]>([]);
  const [errors, setErrors] = useState<any>(null);

  const { isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: 'image/jpeg, image/png, application/pdf',
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const [getAllPurchasers, { loading: purchaserLoading }] = useLazyQuery<IPurchasersArray>(PURCHASERS, {
    variables: { filter: { project: project._id } },
    onCompleted: (data) => {
      setSearchInfo(data.purchaserMany);
    },
  });

  const [getPurchaser] = useLazyQuery(GETPURCHASER, {
    onCompleted: (data) => {
      setRandom(Math.floor(Math.random() * 1000000 + 1));
      purchasersDispatch({
        type: 'ADD',
        payload: data.purchaserById,
      });
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const handleSearchInput = (event: any, values: IPurchaserInfo) => {
    getPurchaser({ variables: { _id: values._id } });
  };

  // Purchaser

  const handleDrop = (acceptedFiles: any, numIndex: number) => {
    if (acceptedFiles.length === 0) {
      storeDispatch(showErrorSnackbar('This file type is not allowed'));
      return;
    }
    const file = acceptedFiles[0];
    const fileReader = new FileReader();
    if (file) {
      fileReader.readAsDataURL(file);
    }
    fileReader.onloadend = async () => {
      let idArray = purchasers.map((purchaser: IPurchaserInfo, index: number) => {
        if (index === numIndex) {
          return {
            ...purchaser,
            identifications: [
              ...purchaser.identifications,
              {
                url: fileReader.result,
                file: file,
                name: file.name,
              },
            ],
          };
        } else {
          return purchaser;
        }
      });
      purchasersDispatch({
        type: 'UPDATE',
        payload: { index: numIndex, field: 'identifications', value: idArray[numIndex].identifications },
      });
    };
  };

  const handleDelete = async (idIndex: number, title: string, numIndex: number) => {
    if (purchasers[numIndex].identifications[idIndex]._id) {
      storeDispatch(showErrorSnackbar('Cannot Delete'));
    } else {
      let newIdentification = purchasers[numIndex].identifications.filter(
        (identification: IIdentification, index: number) => index !== numIndex
      );
      let idArray = purchasers.map((purchaser: IPurchaserInfo, index: number) => {
        if (index === numIndex) {
          return { ...purchaser, identifications: newIdentification };
        } else {
          return purchaser;
        }
      });
      purchasersDispatch({
        type: 'UPDATE',
        payload: { index: numIndex, field: 'identifications', value: idArray[numIndex].identifications },
      });
    }
  };

  const handleCorpChange = (e: any, numIndex: number) => {
    purchasersDispatch({
      type: 'UPDATEMULTIPLE',
      payload: {
        index: numIndex,
        values: [
          {
            corp: !purchasers[numIndex].corp,
            signingOfficers: purchasers[numIndex].corp
              ? []
              : [
                  {
                    fullName: '',
                    dob: subYears(new Date(), 18),
                    streetAddress: '',
                    email: '',
                    primaryPhone: '',
                    sin: '',
                  },
                ],
          },
        ],
      },
    });
  };

  const handleTextInput = async (event: any, numIndex: number) => {
    if (event && event.target.id === 'streetAddress') {
      purchasersDispatch({ type: 'UPDATE', payload: { index: numIndex, field: 'streetAddress', value: event.target.value } });
    }
    if (event && event.target.name) {
      let value = event.target.value;
      if (event.target.name === 'primaryPhone') {
        value = formatPhoneNumber(event.target.value);
      }
      if (event.target.name === 'sin') {
        value = formatSin(event.target.value);
      }
      purchasersDispatch({ type: 'UPDATE', payload: { index: numIndex, field: event.target.name, value: value } });
    }
  };

  const handleDateChange = (newValue: any, id: string, numIndex: number) => {
    if (newValue == 'Invalid Date') {
      setErrors('Invalid Date');
      return;
    } else {
      setErrors(null);
    }
    purchasersDispatch({ type: 'UPDATE', payload: { index: numIndex, field: id, value: newValue } });
  };

  const handleEndvestorInput = (event: any, numIndex: number) => {
    purchasersDispatch({ type: 'UPDATE', payload: { index: numIndex, field: 'purchaserType', value: event.target.innerText } });
  };

  const handleIdTypeInput = (event: any, idType: string, numIndex: number) => {
    purchasersDispatch({ type: 'UPDATE', payload: { index: numIndex, field: idType, value: event.target.innerText } });
  };

  const handleIdIssuedByInput = (event: any, numIndex: number) => {
    purchasersDispatch({ type: 'UPDATE', payload: { index: numIndex, field: 'idJurisdiction', value: event.target.innerText } });
  };

  const handleStreetAddress = async (value: string, numIndex: number) => {
    purchasersDispatch({ type: 'UPDATE', payload: { index: numIndex, field: 'streetAddress', value: value } });
  };

  const handleDetails = async (
    city: string,
    province: string,
    postalCode: string,
    country: string,
    streetAddress: string,
    numIndex: number
  ) => {
    purchasersDispatch({
      type: 'UPDATEMULTIPLE',
      payload: {
        index: numIndex,
        values: [
          {
            city: city,
            province: province,
            postalCode: postalCode,
            country: country,
            streetAddress: streetAddress,
          },
        ],
      },
    });
  };

  const handleDeleteSigningOfficer = (officerIndex: number, numIndex: number) => {
    let purchaserArray = purchasers.map((purchaser: IPurchaserInfo, index: number) => {
      if (purchaser.signingOfficers.length === 1) {
        storeDispatch(showErrorSnackbar('Must have at least 1 signing officer'));
        return purchaser;
      } else if (index === numIndex) {
        let deleteSigningOfficer = purchaser.signingOfficers.filter(
          (signingOfficer: ISigningOfficer, index: number) => officerIndex !== index
        );
        return {
          ...purchaser,
          signingOfficers: deleteSigningOfficer,
        };
      } else {
        return purchaser;
      }
    });
    purchasersDispatch({
      type: 'UPDATE',
      payload: { index: numIndex, field: 'signingOfficers', value: purchaserArray[numIndex].signingOfficers },
    });
  };

  const handleSigningOfficerInput = async (event: any, officerIndex: number, numIndex: number) => {
    if (event && event.target.name) {
      let purchaserArray = purchasers.map((purchaser: IPurchaserInfo, index: number) => {
        if (index === numIndex) {
          let newSigningOfficer = purchaser.signingOfficers.map((signingOfficer: ISigningOfficer, index: number) => {
            if (index === officerIndex) {
              return {
                ...signingOfficer,
                [event.target.name]: event.target.value,
              };
            } else return signingOfficer;
          });
          return {
            ...purchaser,
            signingOfficers: newSigningOfficer,
          };
        } else {
          return purchaser;
        }
      });
      purchasersDispatch({
        type: 'UPDATE',
        payload: { index: numIndex, field: 'signingOfficers', value: purchaserArray[numIndex].signingOfficers },
      });
    }
  };

  const addSigningOfficer = async (numIndex: number) => {
    let purchaserArray = purchasers.map((purchaser: IPurchaserInfo, index: number) => {
      if (index === numIndex) {
        return {
          ...purchaser,
          signingOfficers: [
            ...purchaser.signingOfficers,
            {
              fullName: '',
              dob: subYears(new Date(), 18),
              streetAddress: '',
              primaryPhone: '',
              email: '',
              sin: '',
            },
          ],
        };
      } else return purchaser;
    });
    purchasersDispatch({
      type: 'UPDATE',
      payload: { index: numIndex, field: 'signingOfficers', value: purchaserArray[numIndex].signingOfficers },
    });
  };

  const handleDob = (id: number, numIndex: number) => (event: any, date: Date | null) => {
    if (event == 'Invalid Date') {
      setErrors(event);
      return;
    } else {
      setErrors(null);
    }
    let datePickerArray = purchasers.map((purchaser: IPurchaserInfo, index: number) => {
      if (index === numIndex) {
        let newSigningOfficer = purchaser.signingOfficers.map((signingOfficer: ISigningOfficer, index: number) => {
          if (index === id) {
            return {
              ...signingOfficer,
              dob: date,
            };
          } else return signingOfficer;
        });
        return {
          ...purchaser,
          signingOfficers: newSigningOfficer,
        };
      } else {
        return purchaser;
      }
    });
    purchasersDispatch({
      type: 'UPDATE',
      payload: { index: numIndex, field: 'signingOfficers', value: datePickerArray[numIndex].signingOfficers },
    });
  };

  return (
    <Box sx={{ p: 2 }}>
      <h2 style={{ margin: 0 }}>
        <strong>{title}</strong>
      </h2>
      <Autocomplete
        key={random}
        sx={{
          width: '250px',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          fontSize: '14px',
        }}
        id={'search'}
        disableClearable
        noOptionsText={purchaserLoading ? `Loading...` : 'No Options'}
        freeSolo={false}
        options={searchInfo}
        getOptionLabel={(option: any) => `${option.firstName} ${option.lastName} ${option.email}`}
        onChange={handleSearchInput}
        renderInput={(params) => (
          <TextField
            onFocus={() => (!purchaserLoading ? getAllPurchasers() : null)}
            required={false}
            {...params}
            size="small"
            label={'Search By Name or Email'}
            margin="normal"
          />
        )}
      />
      {purchasers.map((purchaser: IPurchaserInfo, index: number) => {
        return (
          <Box key={index}>
            <FlexBetween sx={{ mt: 2 }}>
              <h3 style={{ margin: 0, alignSelf: 'center' }}>
                <strong>
                  {title} {index + 1}
                </strong>
              </h3>
              {purchasers.length > 1 ? (
                <Box sx={{ alignSelf: 'center' }}>
                  <Button
                    onClick={() =>
                      purchasersDispatch({
                        type: 'DELETE',
                        payload: { index: index },
                      })
                    }
                    variant="contained"
                    color="error"
                  >
                    Delete
                  </Button>
                </Box>
              ) : null}
            </FlexBetween>
            <Dropzone onDrop={(files) => handleDrop(files, index)} accept="image/jpg, image/jpeg, image/png, application/pdf">
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps({ style })}>
                    <input {...getInputProps()} />
                    <p>Drag and Drop or Upload Driver's Licence/Corporation Documents</p>
                  </div>
                </section>
              )}
            </Dropzone>
            <div>
              <Grid
                container
                spacing={2}
                style={{
                  margin: 0,
                  width: '100%',
                }}
              >
                {purchaser.identifications.length > 0
                  ? purchaser.identifications.map((attachment: any, numIndex: number) => {
                      return (
                        <Grid key={index} item lg={3} md={4} sm={6} xs={12}>
                          <PdfCard
                            file={attachment.getUrl ? attachment.getUrl : attachment.url}
                            title={attachment.name ? attachment.name : attachment.file.name}
                            id={numIndex}
                            handleDelete={handleDelete}
                            download={true}
                            index={index}
                          />
                        </Grid>
                      );
                    })
                  : null}
              </Grid>
            </div>
            <Box>
              <FormControlLabel
                onClick={(e) => e.stopPropagation()}
                control={<Switch checked={purchaser.corp} onChange={(e: any) => handleCorpChange(e, index)} />}
                label={'Corp'}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <PurchaserForm
                pInfo={purchaser}
                handleTextInput={handleTextInput}
                handleDateChange={handleDateChange}
                handleEndvestorInput={handleEndvestorInput}
                handleIdTypeInput={handleIdTypeInput}
                handleIdIssuedByInput={handleIdIssuedByInput}
                handleStreetAddress={handleStreetAddress}
                handleDetails={handleDetails}
                handleDeleteSigningOfficer={handleDeleteSigningOfficer}
                handleSigningOfficerInput={handleSigningOfficerInput}
                addSigningOfficer={addSigningOfficer}
                handleDob={handleDob}
                type={index}
                controlled={true}
                required={true}
              />
            </Box>
            <Divider sx={{ mt: 3 }} />
          </Box>
        );
      })}
      <FlexEnd sx={{ mt: 2 }}>
        <Button
          sx={{ mr: 1 }}
          onClick={() =>
            purchasersDispatch({
              type: 'ADDNEW',
              payload: project._id,
            })
          }
          variant="contained"
          color="primary"
        >
          Add Another
        </Button>
      </FlexEnd>
    </Box>
  );
};

interface ChildProps {
  purchasers: IPurchaserInfo[];
  purchasersDispatch: any;
  title: string;
  newAssignment: boolean;
}

const GETPURCHASER = gql`
  query purchaserById($_id: MongoID!) {
    purchaserById(_id: $_id) {
      _id
      project {
        _id
      }
      email
      firstName
      lastName
      corp
      sin
      dob
      identifications {
        _id
        name
        getUrl
      }
      unit
      streetAddress
      city
      province
      country
      postalCode
      occupation
      employer
      directors
      businessNumber
      signingOfficers {
        fullName
        dob
        sin
        primaryPhone
        streetAddress
        email
      }
      purchaserType
      primaryPhone
      idType
      idNumber
      idExpiry
      idJurisdiction
    }
  }
`;

const PURCHASERS = gql`
  query purchaserMany($filter: FilterFindManyPurchaserInput) {
    purchaserMany(filter: $filter, limit: 10000) {
      _id
      email
      firstName
      lastName
      corp
    }
  }
`;

const UPDATEPURCHASER = gql`
  mutation purchaserUpdateById($_id: MongoID!, $record: UpdateByIdPurchaserInput!) {
    purchaserUpdateById(_id: $_id, record: $record) {
      record {
        email
        firstName
        lastName
        sin
        dob
        identifications {
          _id
          name
          getUrl
        }
        streetAddress
        city
        province
        country
        postalCode
        occupation
        employer
        directors
        businessNumber
        signingOfficers {
          fullName
          dob
          sin
          primaryPhone
          streetAddress
          email
        }
        purchaserType
        primaryPhone
        idType
        idNumber
        idExpiry
        idJurisdiction
      }
    }
  }
`;

export default AssignmentForm;
