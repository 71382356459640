import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { gql, useSubscription, useMutation, useLazyQuery } from '@apollo/client';
import { Box, Modal, Drawer, Button, Typography, TextField, Checkbox, FormControlLabel } from '@mui/material';
import Dropdown from '../../common/formControls/Dropdown';
import { useQuery } from '@apollo/client';
import { PriceGridContext } from '../../../context/PriceGridContext';
import { selectProject } from '../../../features/project/projectSlice';
import { IUnit, IPriceList } from '../../../types/unit';
import { FlexEnd } from '../../../commonStyles';
import { useAppDispatch } from '../../../app/hooks';
import { handleModal } from '../../../features/modal/modalSlice';
import { showSuccessSnackbar, showErrorSnackbar } from '../../../features/snackbar/snackbarSlice';
import { numToCurrency } from '../../../utils/Functions';
import { selectUser } from '../../../features/auth/authSlice';

const SaveStack = () => {
  const storeDispatch = useAppDispatch();
  const project = useSelector(selectProject);
  const user = useSelector(selectUser);
  const { selectedPriceList, units, setModalType, editedStack, setEditedStack, editedStackInfo, setEditedStackInfo, oldStack } =
    useContext(PriceGridContext);

  const [updateStackPriceList] = useMutation(UPDATEPRICELIST, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Units Successfully Updated'));
      setEditedStack([]);
    },
  });

  const [updateUnit] = useMutation(UPDATEUNIT, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Units Successfully Updated'));
      setEditedStack([]);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [updateStack] = useMutation(UPDATESTACK, {
    onCompleted: (data) => {
      setEditedStackInfo([]);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const handleSave = () => {
    if (editedStack.length === 0) {
      return;
    }

    if (selectedPriceList) {
      let updatedUnits = editedStack.map((updatedUnit: any, index: number) => {
        return {
          status: updatedUnit.status,
          suite: updatedUnit.suite,
          unit: updatedUnit.unit,
          level: updatedUnit.level,
          modelType: updatedUnit.modelType,
          unitType: updatedUnit.unitType,
          basePrice: updatedUnit.basePrice,
          size: updatedUnit.size,
          outdoorType: updatedUnit.outdoorType,
          outdoorSize: updatedUnit.outdoorSize,
          bathroom: updatedUnit.bathroom,
          exposure: updatedUnit.exposure,
          col: updatedUnit.col,
          row: updatedUnit.row,
          stackCol: updatedUnit.stackCol,
          stackRow: updatedUnit.stackRow,
        };
      });

      let allUnits = units.map((unit: IUnit) => {
        let selectedUnit = updatedUnits.find((updatedUnit: IUnit) => updatedUnit.suite === unit.suite);

        if (selectedUnit) {
          return selectedUnit;
        } else return unit;
      });

      updateStackPriceList({ variables: { _id: selectedPriceList._id, record: { draft: allUnits } } });
      setModalType('');
      storeDispatch(handleModal(false));
    } else {
      let newStack = editedStack.map((unit: any) => {
        let oldUnit = units.find((oldUnit: IUnit) => unit._id === oldUnit._id);
        let history = {
          type: '',
          description: '',
          timestamp: new Date(),
          user: user._id,
        };
        let historyDescription = [];
        let historyType = [];
        let removeAllocations = false;
        let allocatedDate = oldUnit?.allocatedDate;
        if (oldUnit && oldUnit.basePrice !== unit.basePrice) {
          historyType.push('Price');
          historyDescription.push(
            `Price has been changed to from ${numToCurrency.format(oldUnit.basePrice)} to ${numToCurrency.format(unit.basePrice)}`
          );
        }
        if (oldUnit && oldUnit.status !== unit.status) {
          historyType.push('Status');
          historyDescription.push(`Status has been changed from ${oldUnit.status} to ${unit.status}`);
        }
        if (oldUnit && oldUnit.tempAllocation !== unit.tempAllocation) {
          if (
            (oldUnit.tempAllocation && unit.tempAllocation) ||
            (!oldUnit.tempAllocation && unit.tempAllocation) ||
            (oldUnit.tempAllocation && !unit.tempAllocation)
          ) {
            historyType.push('Temporary Allocation');
            historyDescription.push(`Temporary Allocation has been changed from ${oldUnit.tempAllocation} to ${unit.tempAllocation}`);
          }
        }
        if (oldUnit && oldUnit.exposure !== unit.exposure) {
          historyType.push('Exposure');
          historyDescription.push(`Exposure has been changed from ${oldUnit.exposure} to ${unit.exposure}`);
        }
        if (oldUnit && oldUnit.bathroom !== unit.bathroom) {
          historyType.push('Bathroom');
          historyDescription.push(`Bathroom has been changed from ${oldUnit.bathroom} to ${unit.bathroom}`);
        }
        if (oldUnit && oldUnit.unitType !== unit.unitType) {
          historyType.push('Unit Type');
          historyDescription.push(`Unit Type has been changed from ${oldUnit.unitType} to ${unit.unitType}`);
        }
        if (oldUnit && oldUnit.modelType !== unit.modelType) {
          historyType.push('Model Type');
          historyDescription.push(`Model Type has been changed from ${oldUnit.modelType} to ${unit.modelType}`);
        }
        if (oldUnit && oldUnit.size !== unit.size) {
          historyType.push('Size');
          historyDescription.push(`Size has been changed from ${oldUnit.size} to ${unit.size}`);
        }
        if (oldUnit && oldUnit.outdoorSize !== unit.outdoorSize) {
          historyType.push('Outdoor Size');
          historyDescription.push(`Outdoor Size has been changed from ${oldUnit.outdoorSize} to ${unit.outdoorSize}`);
        }
        if (oldUnit && oldUnit.allocation && unit && unit.allocation) {
          if (oldUnit.allocation._id !== unit.allocation._id) {
            historyType.push('Allocation');
            historyDescription.push(
              `Allocation has been changed from ${oldUnit.allocation ? oldUnit.allocation.fullName : 'None'} to ${
                unit.allocation ? unit.allocation.fullName : 'None'
              }`
            );
            allocatedDate = new Date();
            removeAllocations = true;
          }
        } else if (oldUnit && oldUnit.allocation && !unit.allocation) {
          historyType.push('Allocation');
          historyDescription.push(
            `Allocation has been changed from ${oldUnit.allocation ? oldUnit.allocation.fullName : 'None'} to ${
              unit.allocation ? unit.allocation.fullName : 'None'
            }`
          );
          allocatedDate = new Date();
          removeAllocations = true;
        } else if (unit && unit.allocation && !oldUnit?.allocation) {
          historyType.push('Allocation');
          historyDescription.push(
            `Allocation has been changed from ${oldUnit?.allocation ? oldUnit?.allocation.fullName : 'None'} to ${
              unit.allocation ? unit.allocation.fullName : 'None'
            }`
          );
          allocatedDate = new Date();
          removeAllocations = true;
        }

        history.type = historyType.join(', ');
        history.description = historyDescription.join(', ');

        return {
          _id: unit._id,
          allocatedDate: allocatedDate,
          allocation: unit.allocation ? unit.allocation._id : null,
          tempAllocation: unit.tempAllocation,
          basePrice: unit.basePrice,
          status: unit.status,
          history: history,
          modelType: unit.modelType,
          unitType: unit.unitType,
          size: parseFloat(unit.size),
          exposure: unit.exposure,
          bathroom: parseFloat(unit.bathroom),
          outdoorSize: parseFloat(unit.outdoorSize),
          removeAllocations: removeAllocations,
        };
      });

      let newStackInfo = editedStackInfo.map((unit: any) => {
        let oldUnit = oldStack.find((oldUnit: IUnit) => unit._id === oldUnit._id);
        let stringArray: string[] = [];

        if (oldUnit && oldUnit.exposure !== unit.exposure) {
          stringArray.push(`Exposure ${oldUnit.exposure} -> ${unit.exposure}`);
        }
        if (oldUnit && oldUnit.unitType !== unit.unitType) {
          stringArray.push(`Unit Type ${oldUnit.unitType} -> ${unit.unitType}`);
        }
        if (oldUnit && oldUnit.modelType !== unit.modelType) {
          stringArray.push(`Model Type ${oldUnit.modelType} -> ${unit.modelType}`);
        }
        if (oldUnit && oldUnit.size !== unit.size) {
          stringArray.push(`Size ${oldUnit.size} -> ${unit.size}`);
        }
        if (oldUnit && oldUnit.outdoorSize !== unit.outdoorSize) {
          stringArray.push(`Outdoor Size${oldUnit.outdoorSize} -> ${unit.outdoorSize}`);
        }
        if (oldUnit && oldUnit.bathroom !== unit.bathroom) {
          stringArray.push(`Bathroom ${oldUnit.bathroom} -> ${unit.bathroom}`);
        }

        let string = stringArray.join(', ');

        return {
          _id: unit._id,
          exposure: unit.exposure,
          unitType: unit.unitType,
          modelType: unit.modelType,
          size: parseFloat(unit.size),
          outdoorSize: parseFloat(unit.outdoorSize),
          bathroom: parseFloat(unit.bathroom),
          difference: string,
        };
      });

      if (editedStackInfo.length > 0) {
        updateStack({ variables: { stackArray: newStackInfo, project: project._id } });
      }
      updateUnit({ variables: { unitArray: newStack, project: project._id } });
      setModalType('');
      storeDispatch(handleModal(false));
    }
  };

  return (
    <div>
      <Typography variant="h2">
        <strong>List of Changes</strong>
      </Typography>
      {editedStack.length === 0 && editedStackInfo.length === 0 ? <Box sx={{ my: 2 }}>No changes were made</Box> : null}
      {editedStackInfo.length > 0 ? (
        <div style={{ marginRight: '50px' }}>
          <p>
            <strong>Stack Info Changes</strong>
          </p>
          {editedStackInfo.map((editedStack: any) => {
            let oldUnit = oldStack.find((oldUnit: IUnit) => editedStack._id === oldUnit._id);
            let stringArray: string[] = [];
            if (oldUnit && oldUnit.exposure !== editedStack.exposure) {
              stringArray.push(`Exposure ${oldUnit.exposure} -> ${editedStack.exposure}`);
            }
            if (oldUnit && oldUnit.unitType !== editedStack.unitType) {
              stringArray.push(`Unit Type ${oldUnit.unitType} -> ${editedStack.unitType}`);
            }
            if (oldUnit && oldUnit.modelType !== editedStack.modelType) {
              stringArray.push(`Model Type ${oldUnit.modelType} -> ${editedStack.modelType}`);
            }
            if (oldUnit && oldUnit.size !== editedStack.size) {
              stringArray.push(`Size ${oldUnit.size} -> ${editedStack.size}`);
            }
            if (oldUnit && oldUnit.outdoorSize !== editedStack.outdoorSize) {
              stringArray.push(`Outdoor Size ${oldUnit.outdoorSize} -> ${editedStack.outdoorSize}`);
            }
            if (oldUnit && oldUnit.bathroom !== editedStack.bathroom) {
              stringArray.push(`Bathroom ${oldUnit.bathroom} -> ${editedStack.bathroom}`);
            }
            return (
              <Box sx={{ mb: 1 }}>
                <strong>Unit {editedStack.unit}</strong>
                {stringArray.map((string: string, index: number) => {
                  return <div key={index}>{string}</div>;
                })}
              </Box>
            );
          })}
        </div>
      ) : null}
      {editedStack.length > 0 ? (
        <div>
          <p>
            <strong>Unit Info Changes</strong>
          </p>
          {editedStack.map((editedStack: any) => {
            let oldUnit = units.find((oldUnit: IUnit) => editedStack._id === oldUnit._id);
            let stringArray: string[] = [];
            if (oldUnit && oldUnit.basePrice !== editedStack.basePrice) {
              stringArray.push(`Price ${numToCurrency.format(oldUnit.basePrice)} -> ${numToCurrency.format(editedStack.basePrice)}`);
            }
            if (oldUnit && oldUnit.status !== editedStack.status) {
              stringArray.push(`Status ${oldUnit.status} -> ${editedStack.status}`);
            }
            if (oldUnit && oldUnit.tempAllocation !== editedStack.tempAllocation) {
              if (
                (oldUnit.tempAllocation && editedStack.tempAllocation) ||
                (!oldUnit.tempAllocation && editedStack.tempAllocation) ||
                (oldUnit.tempAllocation && !editedStack.tempAllocation)
              ) {
                stringArray.push(`Temp Allocation ${oldUnit.tempAllocation} -> ${editedStack.tempAllocation}`);
              }
            }
            if (oldUnit && oldUnit.allocation && editedStack && editedStack.allocation) {
              if (oldUnit.allocation._id !== editedStack.allocation._id) {
                stringArray.push(
                  `Allocation ${oldUnit.allocation ? oldUnit.allocation.fullName : 'None'} -> ${
                    editedStack.allocation ? editedStack.allocation.fullName : 'None'
                  }`
                );
              }
            } else if (oldUnit && oldUnit.allocation && !editedStack.allocation) {
              stringArray.push(
                `Allocation has been changed from ${oldUnit.allocation ? oldUnit.allocation.fullName : 'None'} to ${
                  editedStack.allocation ? editedStack.allocation.fullName : 'None'
                }`
              );
            } else if (editedStack && editedStack.allocation && !oldUnit?.allocation) {
              stringArray.push(
                `Allocation has been changed from ${oldUnit?.allocation ? oldUnit?.allocation.fullName : 'None'} to ${
                  editedStack.allocation ? editedStack.allocation.fullName : 'None'
                }`
              );
            }
            if (oldUnit && oldUnit.exposure !== editedStack.exposure) {
              stringArray.push(`Exposure ${oldUnit.exposure} -> ${editedStack.exposure}`);
            }
            if (oldUnit && oldUnit.unitType !== editedStack.unitType) {
              stringArray.push(`Unit Type${oldUnit.unitType} -> ${editedStack.unitType}`);
            }
            if (oldUnit && oldUnit.modelType !== editedStack.modelType) {
              stringArray.push(`Model Type${oldUnit.modelType} -> ${editedStack.modelType}`);
            }
            if (oldUnit && oldUnit.size !== editedStack.size) {
              stringArray.push(`Size ${oldUnit.size} -> ${editedStack.size}`);
            }
            if (oldUnit && oldUnit.outdoorSize !== editedStack.outdoorSize) {
              stringArray.push(`Outdoor Size ${oldUnit.outdoorSize} -> ${editedStack.outdoorSize}`);
            }
            if (oldUnit && oldUnit.bathroom !== editedStack.bathroom) {
              stringArray.push(`Bathroom ${oldUnit.bathroom} -> ${editedStack.bathroom}`);
            }
            return (
              <Box sx={{ mb: 1 }}>
                <strong>{editedStack.suite}</strong>
                {stringArray.map((string: string, index: number) => {
                  return <div key={index}>{string}</div>;
                })}
              </Box>
            );
          })}
        </div>
      ) : null}
      <FlexEnd sx={{ mt: 2 }}>
        <Button
          variant="contained"
          color="info"
          sx={{ mr: 1 }}
          onClick={() => {
            setModalType('');
            storeDispatch(handleModal(false));
          }}
        >
          Cancel
        </Button>
        <Button variant="contained" color="success" onClick={() => handleSave()}>
          Confirm Changes
        </Button>
      </FlexEnd>
    </div>
  );
};

const UPDATEPRICELIST = gql`
  mutation priceListUpdateById($_id: MongoID!, $record: UpdateByIdPriceListInput!) {
    priceListUpdateById(_id: $_id, record: $record) {
      record {
        _id
        name
        project {
          _id
        }
        draft {
          _id
          status
          suite
          unit
          unitId
          level
          modelType
          unitType
          basePrice
          size
          outdoorType
          outdoorSize
          bathroom
          exposure
          col
          row
          stackCol
          stackRow
        }
      }
    }
  }
`;

const UPDATEUNIT = gql`
  mutation unitUpdateManyInfo($unitArray: [NewUnitInput!], $project: MongoID!) {
    unitUpdateManyInfo(unitArray: $unitArray, project: $project) {
      _id
      allocation {
        fullName
        _id
      }
      allocatedDate
      tempAllocation
      basePrice
      status
      modelType
      unitType
      flag
      size
      exposure
      bathroom
      outdoorSize
      worksheets {
        _id
      }
    }
  }
`;

const UPDATESTACK = gql`
  mutation updateManyStack($stackArray: [NewStackInput!], $project: MongoID!) {
    updateManyStack(stackArray: $stackArray, project: $project) {
      _id
      modelType
      unitType
      bathroom
      size
      exposure
      outdoorSize
      stackRow
      stackCol
    }
  }
`;

export default SaveStack;
