import React, { useContext, useEffect, useState, useRef } from 'react';
import { Buffer } from 'buffer';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { Page, Document, pdfjs } from 'react-pdf';
import { rgb, PDFDocument, StandardFonts } from 'pdf-lib';
import MergeButtons from '../../components/createMerge/MergeButtons';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Box, Button, Paper } from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import uniqueId from 'lodash.uniqueid';

import { IMergeArray } from '../../types/merge';
import { MergeContext } from '../../context/MergeContext';
import LoadingWrapper from '../common/LoadingWrapper';
import { useAppDispatch } from '../../app/hooks';
import { showSuccessSnackbar, showErrorSnackbar } from '../../features/snackbar/snackbarSlice';
import { DraggableField } from '../common/dnd/Context';
import MergeFieldList from '../../components/createMerge/MergeFieldList';
import TextField from '@mui/material/TextField';
import { FlexBetween } from '../../commonStyles';
import { IDocuments } from '../../types/docusign';
import "pdfjs-dist/build/pdf.worker.entry";
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();

const EditDocument = () => {
  const { setRef, pageNum, setDocumentLoaded, totalPages, setTotalPages, mergeFields } = useContext(MergeContext);
  const { documentid } = useParams();
  const navigate = useNavigate();
  const storeDispatch = useAppDispatch();

  const [pdf, setPdf] = useState<any>(null);
  const [text, setText] = useState<string>('');
  const [mergeList, setMergeList] = useState<any[]>([]);
  const [document, setDocument] = useState<IDocuments | null>(null);

  const ref = useRef<any>(null);
  const widthRef = useRef<any>(null);
  let context = ref.current?.getContext('2d');
  let rect = ref.current?.getBoundingClientRect();

  const [mergeCoordinates, setMergeCoordinates] = useState<IMergeArray[]>([]);

  const { loading: previewLoading } = useQuery(GETPREVIEWDATA, {
    variables: { _id: documentid },
    fetchPolicy: 'no-cache',
    onCompleted: (data: any) => {
      setDocument(data.documentById);
      const binaryData = Buffer.from(data.documentById.previewData, 'binary');
      const blob = new Blob([binaryData], { type: 'application/pdf' });
      const previewUrl = URL.createObjectURL(blob);

      setPdf(previewUrl);
    },
    onError: (err: any) => {
      console.log(err, 'err');
    },
  });

  useEffect(() => {
    if (pdf) {
      mergePDFDocuments(pdf);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdf, ref]);

  async function mergePDFDocuments(documents: any) {
    const url = pdf;
    const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    const pageCount = await pdfDoc.getPageCount();
    setTotalPages(pageCount);

    const pdfBytes: any = await pdfDoc.save();

    await setRef(ref);
    return;
  }

  const mergeFieldCoordinates = (
    mergeTitle: string,
    mergeSize: number,
    x: number,
    docuSignY: number,
    mergeIndex: number,
    mergeFormat: string
  ) => {
    setMergeCoordinates([
      ...mergeCoordinates,
      {
        mergeId: uniqueId(),
        pageNumber: pageNum - 1,
        key: mergeTitle,
        format: mergeFormat,
        fontSize: mergeSize,
        index: mergeIndex,
        x: x,
        y: docuSignY,
      },
    ]);
  };

  const handleMergeCoordinates = (value: string, numIndex: number) => {
    let newMergeCoordinates = mergeCoordinates.map((mergeCoords: any, index: number) => {
      if (index === numIndex) {
        return {
          ...mergeCoords,
          wrap: value,
        };
      } else return mergeCoords;
    });
    setMergeCoordinates(newMergeCoordinates);
  };

  const handleBlurWrap = (e: any, numIndex: number) => {
    let newMergeCoordinates = mergeCoordinates.map((mergeCoords: any, index: number) => {
      if (index === numIndex) {
        let wrapNumber = ' '.repeat(parseInt(mergeCoords.wrap, 10));
        let trimmedKey = mergeCoords.key.replace(/\s/g, '');
        let string = trimmedKey.substring(0, trimmedKey.length - 1) + wrapNumber + trimmedKey.substring(trimmedKey.length - 1);
        return {
          ...mergeCoords,
          key: string,
          displayWrap: !mergeCoords.displayWrap,
        };
      } else return mergeCoords;
    });
    setMergeCoordinates(newMergeCoordinates);
  };

  const showWrap = (mergeField: any) => {
    let newMergeCoordinates = mergeCoordinates.map((mergeCoords: any, index: number) => {
      if (mergeCoords.mergeId === mergeField.mergeId) {
        return {
          ...mergeCoords,
          displayWrap: !mergeCoords.displayWrap,
        };
      } else return mergeCoords;
    });
    setMergeCoordinates(newMergeCoordinates);
  };

  const getCursorPosition = (
    canvas: any,
    event: any,
    mergeTitle: string,
    mergeType: string,
    mergeIndex: number,
    mergeSize: number = 12,
    format: string,
    moveElement: boolean
  ) => {
    if (!canvas) {
      return;
    }
    let rect = canvas.current?.getBoundingClientRect();
    let x = event.activatorEvent.x - rect.left;
    let y = event.activatorEvent.y - rect.top - 1;

    if (!moveElement) {
      if (
        event.delta.x + event.activatorEvent.x > rect.left &&
        event.delta.x + event.activatorEvent.x < rect.right &&
        event.delta.y + event.activatorEvent.y < rect.bottom &&
        event.delta.y + event.activatorEvent.y > rect.top
      ) {
        x = event.delta.x - rect.left + event.activatorEvent.x;

        if (rect.top !== 64) {
          if (rect.top < 0) {
            if (event.delta.y < 0) {
              y = event.activatorEvent.y + Math.abs(rect.top) - Math.abs(event.delta.y)
            } else {
              y = event.activatorEvent.y + Math.abs(rect.top) + Math.abs(event.delta.y)
            }
          } else if (rect.top > 0) {
            if (event.delta.y < 0) {
              y = event.activatorEvent.y - Math.abs(rect.top) - Math.abs(event.delta.y)
            } else {
              y = event.activatorEvent.y - Math.abs(rect.top) + Math.abs(event.delta.y)
            }
          }
        } else if (event.delta.y < 0) {
          y = event.activatorEvent.y - rect.top - Math.abs(event.delta.y)
        } else y = event.activatorEvent.y - rect.top + Math.abs(event.delta.y)
      } else {
        // removed merge field from pdf
        let filteredMergeCoordinates = mergeCoordinates.filter((merge: any) => merge.uniqueId !== event.active.data.current.merge.mergeId);
        setMergeCoordinates(filteredMergeCoordinates);
      }

      if (x > context.canvas.clientWidth || x < 0) {
        return;
      }
      if (y > context.canvas.clientHeight || y < 0) {
        return;
      }
      if (mergeType === 'merge') {
        mergeFieldCoordinates(mergeTitle, mergeSize, x, y, mergeIndex, format);
      }
    } else {
      // Moving an element again or element created from database
      let x = event.active.data.current.merge.x;
      let y = event.active.data.current.merge.y;

      if (
        event.delta.x + event.activatorEvent.x > rect.left &&
        event.delta.x + event.activatorEvent.x < rect.right &&
        event.delta.y + event.activatorEvent.y < rect.bottom &&
        event.delta.y + event.activatorEvent.y > rect.top
      ) {
        if (event.delta.x > 0) {
          x = event.active.data.current.merge.x + event.delta.x;
        } else if (event.delta.x < 0) {
          x = event.active.data.current.merge.x + event.delta.x;
        }

        if (event.delta.y > 0) {
          y = event.active.data.current.merge.y + event.delta.y;
        } else if (event.delta.y < 0) {
          y = event.active.data.current.merge.y + event.delta.y;
        }

        if (mergeType === 'merge') {
          let newMergeCoordinates = mergeCoordinates.map((mergeCoord: any) => {
            if (mergeCoord.mergeId === event.active.id) {
              return {
                ...mergeCoord,
                x,
                y,
              };
            } else return mergeCoord;
          });
          setMergeCoordinates(newMergeCoordinates);
        }
      } else {
        let filteredMergeCoordinates = mergeCoordinates.filter((merge: any) => merge.mergeId !== event.active.data.current.merge.mergeId);
        setMergeCoordinates(filteredMergeCoordinates);
      }
    }
  };

  const handleDragEnd = (event: any) => {
    getCursorPosition(
      ref,
      event,
      event.active.data.current.merge.name ? event.active.data.current.merge.name : event.active.data.current.merge.key,
      event.active.data.current.merge.hasOwnProperty('format') ? 'merge' : 'sign',
      event.active.data.current.merge.index,
      event.active.data.current.merge.fontSize,
      event.active.data.current.merge.format ? event.active.data.current.merge.format : '',
      true
    );
  };

  function onDocumentLoadSuccess({ numPages: nextNumPages }: { numPages: any }) {
    // Only set coordinates on initial load
    if (mergeFields.length && !mergeCoordinates.length) {
      setMergeCoordinates(
        mergeFields.map((merge: any) => {
          return { ...merge, mergeId: uniqueId() };
        })
      );
    }

    setDocumentLoaded(true);
  }

  const createMergeField = () => {
    setMergeList([
      ...mergeList,
      {
        name: text,
        fontSize: 9,
        index: 0,
        type: 'merge',
      },
    ]);
    setText('');
  };

  const saveMerge = async () => {
    if (!mergeCoordinates.length) return storeDispatch(showErrorSnackbar(`No Changes were made`));

    let newMergeCoordinates = mergeCoordinates.map((mergeCoords: any) => {
      return {
        format: mergeCoords.format,
        key: mergeCoords.key,
        index: mergeCoords.index,
        pageNumber: mergeCoords.pageNumber,
        x: mergeCoords.x,
        y: mergeCoords.y,
        fontSize: mergeCoords.fontSize,
      };
    });

    const mergedPdf = await PDFDocument.create();

    // Draw APS first

    let aps = await fetch(pdf).then(async (res) => await res.arrayBuffer());
    const apsDoc = await PDFDocument.load(aps);
    let helveticaFont = await apsDoc.embedFont(StandardFonts.Helvetica);

    let pages = apsDoc.getPages();

    await newMergeCoordinates.forEach(async (mergeField: any) => {
      let mergePages = pages[mergeField.pageNumber];
      mergePages.drawText(mergeField.key, {
        x: mergeField.x,
        y: mergePages.getHeight() - mergeField.y - 3,
        size: mergeField.fontSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      });
    });

    let copiedPages = await mergedPdf.copyPages(apsDoc, apsDoc.getPageIndices());
    copiedPages.forEach((page) => mergedPdf.addPage(page));

    const documentPdf = await mergedPdf.save();

    const options = {
      headers: { 'Content-Type': 'application/pdf' },
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
    };
    await axios.put(document?.putUrl!, documentPdf, options);

    storeDispatch(showSuccessSnackbar(`Document has been updated!`));
    navigate(-1);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        alignSelf: 'flex-start',
        backgroundColor: '#f5f5f5',
      }}
    >
      <Paper
        elevation={12}
        sx={{
          width: '30%',
          p: 2,
          position: 'sticky',
          top: 0,
          bottom: 0,
          left: 0,
          height: '100%',
          zIndex: 20,
        }}
      >
        <Box sx={{ height: '500px', zIndex: 20 }}>
          <TextField
            title={'Text'}
            name={'text'}
            fullWidth
            value={text}
            label={'Text'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setText(e.target.value)}
          />
          {text.length ? (
            <Button sx={{ mt: 2 }} variant="contained" color="primary" onClick={() => createMergeField()}>
              Create Merge Field
            </Button>
          ) : null}
          <MergeFieldList
            fieldName={'Merge Fields'}
            start={0}
            end={mergeList.length}
            getCursorPosition={getCursorPosition}
            mergeItems={mergeList}
          />
        </Box>
        <FlexBetween sx={{ mt: 2, textAlign: 'left' }}>
          <Button variant="contained" color="primary" size="small" onClick={() => navigate(-1)}>
            Back to Unit
          </Button>
          <Button variant="contained" color="success" size="small" startIcon={<CloudUploadIcon />} onClick={saveMerge}>
            Save
          </Button>
        </FlexBetween>
      </Paper>
      {previewLoading || pdf ? (
        <Box
          ref={widthRef}
          sx={{
            zIndex: 1,
            width: '70%',
            height: '100%',
            position: 'relative',
            '& .react-pdf__Page__canvas': {
              margin: '0 auto',
            },
            '& .react-pdf__Page, .react-pdf__Document': {
              height: '100%',
            },
            '& .react-pdf__message--loading': {
              display: 'flex',
              justifyContent: 'center',
              alignSelf: 'center',
            },
            '& .react-pdf__message': {
              marginTop: '20px',
              textAlign: 'center',
            },
          }}
        >
          <Document
            style={{ position: 'relative' }}
            error={'An error has occurred or this pdf does not have that many pages.'}
            loading={<LoadingWrapper title="PDF is loading..." modal={false} />}
            file={pdf}
          >
            {widthRef.current && rect ? (
              <>
                {mergeCoordinates.map((element: any, index: number) => {
                return (
                  <Box
                    key={index}
                    sx={{
                      position: 'absolute',
                      zIndex: '10000',
                      top: element.y - 7,
                      left: (widthRef?.current?.offsetWidth! - rect.width) / 2 + element.x,
                      fontSize: element.fontSize,
                      display: element.pageNumber !== pageNum - 1 ? 'none' : 'inline-block',
                    }}
                  >
                    {element.displayWrap ? (
                      <Box>
                        <TextField
                          sx={{
                            width: '100px',
                            position: 'absolute',
                            top: '-40px',
                            '& .MuiOutlinedInput-input': { p: 1 },
                            backgroundColor: '#f5f5f5',
                          }}
                          type="number"
                          title={'Wrap Length'}
                          name={'wrap'}
                          onChange={(e) => handleMergeCoordinates(e.target.value, index)}
                          fullWidth
                          value={element.wrap}
                          label={'Wrap Length'}
                          onBlur={() => handleBlurWrap(element, index)}
                        />
                      </Box>
                    ) : null}
                    <DraggableField reset={false} mergeItem={element} handleDragEnd={handleDragEnd} showWrap={showWrap} />
                  </Box>
                );
              })}
              </>
            ) : null}
            <Page canvasRef={ref} scale={1} loading={''} pageNumber={pageNum} onLoadSuccess={onDocumentLoadSuccess} />
          </Document>
          {totalPages > 1 ? <MergeButtons totalPages={totalPages} /> : null}
        </Box>
      ) : null}
    </Box>
  );
};

const GETPREVIEWDATA = gql`
  query documentById($_id: MongoID!) {
    documentById(_id: $_id) {
      _id
      previewData
      project {
        _id
      }
      putUrl
    }
  }
`;

export default EditDocument;
