import React, { useContext, useEffect, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { Box, Grid, Button, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '../../../../app/hooks';
import { selectProject } from '../../../../features/project/projectSlice';
import DealOptions from '../../DealOptions';
import { UnitContext } from '../../../../context/UnitContext';
import { IOption } from '../../../../types/project';
import { numToCurrency } from '../../../../utils/Functions';
import { FlexEnd } from '../../../../commonStyles';
import { selectMerges } from '../../../../features/merge/mergeSlice';
import { selectUser } from '../../../../features/auth/authSlice';
import { useCreateActivity } from '../../../../features/activity/activityHooks';
import { showSuccessSnackbar } from '../../../../features/snackbar/snackbarSlice';
import { DealContext } from '../../../../context/DealContext';
import PendingOptions from './PendingOptions';

const OptionInfo = (props: any) => {
  const { unit, filteredDeal, setFilteredDeal } = useContext(UnitContext);
  const { document, setDocument, pendingOptions, setPendingOptions } = useContext(DealContext);

  const storeDispatch = useAppDispatch();

  const user = useSelector(selectUser);
  const createActivity = useCreateActivity();
  const project = useSelector(selectProject);
  const merges = useSelector(selectMerges);
  const [waitlistChoices, setWaitlistChoices] = useState<string[]>([]);
  const [errors, setErrors] = useState<any>(null);

  // Queries and Mutations

  const [saveWaitlist] = useMutation(SAVEWAITLIST, {
    onCompleted: (data) => {
      createActivity({
        project: project._id,
        user: user._id,
        deal: filteredDeal._id,
        title: `Waitlist`,
        content: `Waitlist has been updated`,
      });
      storeDispatch(showSuccessSnackbar(`Waitlist has been updated`));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [saveOptions] = useMutation(SAVEWAITLIST, {
    onCompleted: (data) => {
      createActivity({
        project: project._id,
        user: user._id,
        deal: filteredDeal._id,
        title: `Option`,
        content: `Options has been manually updated`,
      });
      storeDispatch(showSuccessSnackbar(`Options has been updated`));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  useEffect(() => {
    let projectOptions = project.options.map((option: IOption) => option.name);
    let combinedWaitlist = [...new Set([...filteredDeal.joinWaitlist, ...projectOptions])];
    setWaitlistChoices(combinedWaitlist);
  }, [merges]);

  // Functions

  const totalOptionsPrice = (type: string) => {
    let totalOptions = unit.basePrice;
    if (type === 'options') {
      totalOptions = 0;
    }

    if (type === 'total') {
      if (filteredDeal.pendingOptions.length > 0) {
        totalOptions = filteredDeal.pendingOptions.reduce((a: any, b: any) => {
          return a + b.amount;
        }, unit.basePrice);
      } else if (pendingOptions.length > 0) {
        totalOptions = pendingOptions.reduce((a: any, b: any) => {
          return a + b.amount;
        }, unit.basePrice);
      }
      return totalOptions;
    } else if (type === 'options') {
      if (filteredDeal.pendingOptions.length > 0) {
        totalOptions = filteredDeal.pendingOptions.reduce((a: any, b: any) => {
          return a + b.amount;
        }, 0);
      } else if (pendingOptions.length > 0) {
        totalOptions = pendingOptions.reduce((a: any, b: any) => {
          return a + b.amount;
        }, 0);
      }
      return totalOptions;
    }
  };

  const handleOptions = (type: string = '') => {
    let allOptions = project.options.map((option: any) => {
      return {
        name: option.name,
        purchaseAmount: 0,
        amount: 0,
        rental: option.rental,
      };
    });
    if (type) {
      setDocument('Options');
    }
    setPendingOptions(allOptions);
  };

  const handleJoinWaitlist = (title: string) => {
    let waitlist = filteredDeal.joinWaitlist.find((waitlist: string) => waitlist === title);

    if (waitlist) {
      setFilteredDeal({
        ...filteredDeal,
        joinWaitlist: filteredDeal.joinWaitlist.filter((waitlist: string) => title !== waitlist),
      });
    } else {
      setFilteredDeal({
        ...filteredDeal,
        joinWaitlist: [...filteredDeal.joinWaitlist, title],
      });
    }
  };

  return (
    <div>
      <>
        <h3>
          <em>Waitlist Options</em>
        </h3>
        {waitlistChoices.length ? (
          <Box>
            <Grid container spacing={2}>
              {waitlistChoices.map((waitlist: string) => {
                return (
                  <Grid item sm={6} md={4} lg={3}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={filteredDeal.joinWaitlist.find((selected: string) => waitlist === selected) ? true : false}
                            onClick={() => handleJoinWaitlist(waitlist)}
                          />
                        }
                        label={waitlist}
                      />
                    </FormGroup>
                  </Grid>
                );
              })}
            </Grid>
            <FlexEnd>
              <Button
                sx={{ mr: 1 }}
                onClick={() => saveWaitlist({ variables: { _id: filteredDeal._id, record: { joinWaitlist: filteredDeal.joinWaitlist } } })}
                color="success"
                variant="contained"
              >
                Save Waitlist
              </Button>
            </FlexEnd>
          </Box>
        ) : (
          <Box>There are currently no options for this project</Box>
        )}
      </>
      <h3>
        <em>Current Options</em>
      </h3>
      <DealOptions options={filteredDeal.options} disabled={false} />
      {!filteredDeal.cancelled.dateCancelled && !filteredDeal.rescission.dateRescinded ? (
        <FlexEnd sx={{ mt: 2 }}>
          {!filteredDeal.documents.length ||
          (filteredDeal.documents[0].type === 'APS' &&
            filteredDeal.documents[0].status === 'Completed' &&
            !filteredDeal.documents[0].dsEnvelopeId) ? (
            <Button
              sx={{ mr: 1 }}
              onClick={() => saveOptions({ variables: { _id: filteredDeal._id, record: { options: filteredDeal.options } } })}
              color="success"
              variant="contained"
            >
              Save Options
            </Button>
          ) : null}
        </FlexEnd>
      ) : null}
      {filteredDeal.pendingOptions.length > 0 ? (
        <div>
          <h3
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <em>Pending Options</em>
          </h3>
          <em>
            These options are currently pending until the amendment has been executed. After the amendment has been executed, it will
            replace the options above. If you would like to remove these options, please delete the Option/Unit Transfer Amendment in the
            amendments section
          </em>
          {filteredDeal.pendingRental ? (
            <Box sx={{ mt: 2 }}>
              Pending Rental Change: <strong>{numToCurrency.format(filteredDeal.pendingRental)}</strong>
            </Box>
          ) : null}
          <Box sx={{ mt: 2 }}>
            Total Price for Pending Options: <strong>{numToCurrency.format(totalOptionsPrice('total'))}</strong>
          </Box>
          <DealOptions options={filteredDeal.pendingOptions} disabled={true} />
        </div>
      ) : null}
      {pendingOptions.length > 0 && document === 'Options' ? (
        <PendingOptions errors={errors} setErrors={setErrors} totalOptionsPrice={totalOptionsPrice} />
      ) : filteredDeal.pendingOptions.length === 0 && !document ? (
        <FlexEnd>
          <Button
            onClick={() => {
              handleOptions('Options');
            }}
            name={'update'}
            color="primary"
            variant="contained"
          >
            Create Option Amendment
          </Button>
        </FlexEnd>
      ) : null}
    </div>
  );
};

const SAVEWAITLIST = gql`
  mutation dealUpdateById($_id: MongoID!, $record: UpdateByIdDealInput!) {
    dealUpdateById(_id: $_id, record: $record) {
      record {
        purchasers {
          _id
          firstName
          lastName
        }
      }
    }
  }
`;

export default OptionInfo;
