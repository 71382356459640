import { useState, useMemo, useReducer } from 'react';
import { Buffer } from 'buffer';
import { gql, useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { Box, Typography, Checkbox, Button, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useSelector } from 'react-redux';
import Lightbox from 'react-18-image-lightbox';

import { selectProject } from '../../features/project/projectSlice';
import StandardTable from '../tables/StandardTable';
import { Link } from 'react-router-dom';
import { IIdentification, IPurchaserInfo } from '../../types/CreateDealForm';
import { convertAllDates, downloadExcel, downloadDistributionFiles, sortNestedSuites } from '../../utils/Functions';
import { IDeposits } from '../../types/project';
import { IDocuments } from '../../types/docusign';
import 'react-18-image-lightbox/style.css';
import { FlexBetween } from '../../commonStyles';
import { GlobalModal } from '../../features/modal/Modal';
import { useAppDispatch } from '../../app/hooks';
import { handleModal } from '../../features/modal/modalSlice';
import { showErrorSnackbar } from '../../features/snackbar/snackbarSlice';
import LoadingWrapper from '../common/LoadingWrapper';
import { selectUser } from '../../features/auth/authSlice';

export const dealsReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'ADDALL': {
      return action.payload;
    }
    case 'UPDATEROW': {
      return state.map((state: any, index: number) => {
        if (index === action.payload.index) {
          return action.payload.value;
        } else return state;
      });
    }
    case 'UPDATEALL': {
      return state.map((state: any, index: number) => {
        let selectedSuite = action.payload.value.find((deal: any) => deal.unit.suite === state.unit.suite);
        if (selectedSuite) {
          return selectedSuite;
        } else return state;
      });
    }
    case 'UPDATE':
      return state.map((state: any, index: number) => {
        if (index === action.payload.index) {
          return {
            ...state,
            [action.payload.field]: action.payload.value,
          };
        } else return state;
      });
    default:
      throw new Error();
  }
};

const Distribution = () => {
  const project = useSelector(selectProject);
  const user = useSelector(selectUser);
  const [allDeals, setAllDeals] = useState<any[]>([]);
  const [dealsState, dealsDispatch] = useReducer(dealsReducer, []);
  const [open, setOpen] = useState<boolean>(false);
  const [image, setImage] = useState<string>('');
  const [downloading, setDownloading] = useState<boolean>(false);
  const [value, setValue] = useState<string>('all');
  const storeDispatch = useAppDispatch();

  const { loading } = useQuery(DISTRIBUTION, {
    variables: { project: project._id },
    onCompleted: (data: any) => {
      let sorted = [...data.getDistribution];
      sorted = sortNestedSuites(sorted, 'unit', 'suite');
      setAllDeals([...sorted]);
      dealsDispatch({ type: 'ADDALL', payload: [...sorted] });
    },
  });

  const { refetch: getDocument } = useQuery(GETPREVIEWDATA, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {},
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [getPreviewData, { loading: previewLoading }] = useLazyQuery(GETPREVIEWDATA, {
    fetchPolicy: 'network-only',
    onCompleted: (data: any) => {
      const binaryData = Buffer.from(data.documentById.previewData, 'binary');
      const blob = new Blob([binaryData], { type: 'application/pdf' });
      const previewUrl = URL.createObjectURL(blob);

      const element = document.createElement('a');
      element.href = previewUrl;
      element.target = '_blank';
      element.click();
    },
    onError: (err: any) => {
      console.log(err, 'err');
    },
  });

  const [downloadPdfDistribution, { loading: distributionLoading }] = useMutation(DOWNLOADDISTRIBUTION, {
    fetchPolicy: 'no-cache',
    onCompleted: (data: any) => {
      storeDispatch(handleModal(false));
    },
    onError: (err: any) => {
      console.log(err, 'err');
    },
  });

  const columns = useMemo(() => {
    return [
      {
        Header: 'Suite',
        accessor: (rowData: any) => {
          return (
            <Link
              rel="noopener noreferrer"
              target="_blank"
              to={`/${project._id}/dashboard/${rowData.unit._id}`}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <strong>{rowData.unit.suite}</strong>
            </Link>
          );
        },
      },
      {
        Header: 'Purchaser IDs',
        accessor: (rowData: any, index: number) => {
          return rowData.purchasers.map((purchaser: IPurchaserInfo, typeIndex: number) => {
            return (
              <Box sx={{ mt: 1 }} key={typeIndex}>
                <Box>{purchaser.fullName}</Box>
                <div>
                  {purchaser.identifications.map((identification: IIdentificationChecked, numIndex: number) => {
                    return (
                      <Box key={numIndex}>
                        <Box
                          sx={{ display: 'inline-block', cursor: 'pointer', mr: 1 }}
                          onClick={() => handleOpen('purchasers', index, typeIndex, numIndex)}
                        >
                          <strong>{`ID ${numIndex + 1}`}:</strong>
                        </Box>
                        {identification.distribution ? (
                          convertAllDates(identification.distribution, 'PP')
                        ) : (
                          <Checkbox
                            sx={{ color: 'red' }}
                            checked={identification.checked ? identification.checked : false}
                            onChange={(e: any) => handleChange(e, 'purchasers', index, typeIndex, numIndex)}
                          />
                        )}
                      </Box>
                    );
                  })}
                </div>
              </Box>
            );
          });
        },
      },
      {
        Header: 'Deposit Images',
        accessor: (rowData: any, index: number) => {
          if (rowData.depositImages.length) {
            return rowData.depositImages.map((deposit: IDepositsChecked, typeIndex: number) => {
              return (
                <Box key={typeIndex}>
                  <Box
                    sx={{ display: 'inline-block', cursor: 'pointer', mr: 1 }}
                    onClick={() => handleOpen('depositImages', index, typeIndex)}
                  >
                    <strong>{`${deposit.name}`}: </strong>
                  </Box>
                  {deposit.distribution ? (
                    convertAllDates(deposit.distribution, 'PP')
                  ) : (
                    <Checkbox
                      sx={{ p: 0.5, color: 'red' }}
                      checked={deposit.checked ? deposit.checked : false}
                      onChange={(e: any) => handleChange(e, 'depositImages', index, typeIndex)}
                    />
                  )}
                </Box>
              );
            });
          } else return 'No Deposit Images';
        },
      },
      {
        Header: 'Mortgage Letter',
        accessor: (rowData: any, index: number) => {
          if (rowData.mortgage.revisions.includes('No MPA Required')) {
            return 'No MPA Required';
          } else {
            if (rowData.mortgage.amount && rowData.mortgage.lender && rowData.mortgage.getUrl && !rowData.mortgage.revisions.length) {
              return (
                <Box>
                  <Box sx={{ display: 'inline-block', cursor: 'pointer', mr: 1 }} onClick={() => handleOpen('mortgage', index)}>
                    <strong>{'Mortgage Image'}: </strong>
                  </Box>
                  {rowData.mortgage.distribution ? (
                    convertAllDates(rowData.mortgage.distribution, 'PP')
                  ) : (
                    <Checkbox
                      sx={{ p: 0.5, color: 'red' }}
                      checked={rowData.mortgage.checked ? rowData.mortgage.checked : false}
                      onChange={(e: any) => handleChange(e, 'mortgage', index)}
                    />
                  )}
                </Box>
              );
            } else {
              return 'Missing Mortgage Information';
            }
          }
        },
      },
      {
        Header: 'Documents',
        accessor: (rowData: any, index: number) => {
          return rowData.documents.map((document: IDocumentsChecked, typeIndex: number) => {
            return (
              <Box key={typeIndex}>
                <Box sx={{ display: 'inline-block', cursor: 'pointer', mr: 1 }} onClick={() => handleOpen('documents', index, typeIndex)}>
                  <strong>{document.name}:</strong>
                </Box>
                {document.distribution ? (
                  convertAllDates(document.distribution, 'PP')
                ) : document.status === 'Completed' ? (
                  <Checkbox
                    checked={document.checked ? document.checked : false}
                    sx={{ p: 0.5, color: 'red' }}
                    onChange={(e: any) => handleChange(e, 'documents', index, typeIndex)}
                  />
                ) : (
                  document.status
                )}
              </Box>
            );
          });
        },
      },
      {
        Header: 'Check Row',
        accessor: (rowData: any, index: number) => {
          return (
            <Button onClick={() => checkRow(index)} variant="contained" color="primary">
              Check Row
            </Button>
          );
        },
      },
    ];
  }, [dealsState]);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: string,
    index: number,
    typeIndex: number = 0,
    numIndex: number = 0
  ) => {
    let value: any;
    if (type === 'purchasers') {
      value = dealsState[index].purchasers.map((purchaser: IPurchaserInfo, index: number) => {
        if (index === typeIndex) {
          return {
            ...purchaser,
            identifications: purchaser.identifications.map((identification: IIdentificationChecked, index: number) => {
              if (numIndex === index) {
                return {
                  ...identification,
                  checked: event.target.checked,
                };
              } else return identification;
            }),
          };
        } else return purchaser;
      });
    }

    if (type === 'depositImages') {
      value = dealsState[index].depositImages.map((depositImage: IDepositsChecked, numIndex: number) => {
        if (numIndex === typeIndex) {
          return {
            ...depositImage,
            checked: event.target.checked,
          };
        } else return depositImage;
      });
    }

    if (type === 'mortgage') {
      value = dealsState[index].mortgage;
      value = {
        ...value,
        checked: event.target.checked,
      };
    }

    if (type === 'documents') {
      value = dealsState[index].documents.map((document: IDocumentsChecked, numIndex: number) => {
        if (numIndex === typeIndex) {
          return {
            ...document,
            checked: event.target.checked,
          };
        } else return document;
      });
    }

    dealsDispatch({ type: 'UPDATE', payload: { index: index, field: type, value: value } });
  };

  const checkRow = (index: number) => {
    let selectedDeal = dealsState[index];

    let depositImages = selectedDeal.depositImages.map((depositImage: IDeposits) => {
      if (!depositImage.distribution) {
        return {
          ...depositImage,
          checked: true,
        };
      } else return depositImage;
    });

    let documents = selectedDeal.documents.map((document: IDocuments) => {
      if (!document.distribution && document.status === 'Completed') {
        return {
          ...document,
          checked: true,
        };
      } else return document;
    });

    let mortgage =
      !selectedDeal.mortgage.distribution && selectedDeal.mortgage.getUrl && selectedDeal.mortgage.amount && selectedDeal.mortgage.lender
        ? {
            ...selectedDeal.mortgage,
            checked: true,
          }
        : selectedDeal.mortgage;

    let purchasers = selectedDeal.purchasers.map((purchaser: IPurchaserInfo) => {
      return {
        ...purchaser,
        identifications: purchaser.identifications.map((identification: IIdentification) => {
          if (!identification.distribution) {
            return {
              ...identification,
              checked: true,
            };
          } else return identification;
        }),
      };
    });

    selectedDeal = {
      _id: selectedDeal._id,
      unit: selectedDeal.unit,
      depositImages: depositImages,
      documents: documents,
      mortgage: mortgage,
      purchasers: purchasers,
    };

    dealsDispatch({ type: 'UPDATEROW', payload: { index: index, value: selectedDeal } });
  };

  const handleOpen = (type: string, index: number, typeIndex: number = 0, numIndex: number = 0) => {
    let value: any;

    if (type === 'purchasers') {
      value = dealsState[index].purchasers[typeIndex].identifications[numIndex].getUrl;
    }

    if (type === 'depositImages') {
      value = dealsState[index].depositImages[typeIndex].getUrl;
    }

    if (type === 'mortgage') {
      value = dealsState[index].mortgage.getUrl;
    }

    if (type === 'documents') {
      getPreviewData({
        variables: {
          _id: dealsState[index].documents[typeIndex]._id,
        },
      });
      return;
    }

    if (value?.includes('.pdf') || value?.includes('/pdf')) {
      window.open(value, '_blank');
      return;
    }

    setOpen(true);
    setImage(value);
  };

  const downloadPreview = () => {
    let selected = dealsState.filter((deal: any) => {
      let identifications = deal.purchasers.some((purchaser: any) =>
        purchaser.identifications.some((identification: any) => identification.checked)
      );
      let mortgage = deal.mortgage.checked ? deal.mortgage.checked : false;
      let depositImages = deal.depositImages.find((depositImage: any) => depositImage.checked);
      let documents = deal.documents.find((document: any) => document.checked);

      if (identifications || mortgage || depositImages || documents) {
        return deal;
      }
    });

    if (!selected.length) {
      return storeDispatch(showErrorSnackbar('No deals to preview'));
    }

    let unitHeaders = [
      {
        label: 'Suite',
        id: 'suite',
      },
      {
        label: 'Purchaser IDs',
        id: 'purchaserIds',
      },
      {
        label: 'Deposit Images',
        id: 'depositImages',
      },
      {
        label: 'Mortgage',
        id: 'mortgage',
      },
      {
        label: 'Documents',
        id: 'documents',
      },
    ];

    let widths = {
      suite: 15,
      purchaserIds: 15,
      depositImages: 15,
      mortgage: 15,
      documents: 15,
    };

    let updatedData = selected.map((data: any) => {
      let purchasers = data.purchasers
        .map((purchaser: any) => {
          let identifications = purchaser.identifications.map((identification: any, index: number) => {
            if (identification.checked) {
              return `${purchaser.fullName} ID ${index + 1}`;
            } else return null;
          });

          return identifications;
        })
        .flat()
        .filter((purchaser: any) => purchaser !== null)
        .join(', ');

      return {
        suite: data.unit.suite,
        purchaserIds: purchasers ? purchasers : '',
        depositImages: data.depositImages
          .map((depositImage: any, index: number) => {
            return {
              text: `${depositImage.name}`,
              checked: depositImage.checked ? depositImage.checked : false,
            };
          })
          .filter((depositImage: any) => depositImage.checked)
          .map((depositImage: any) => depositImage.text)
          .join(', '),
        mortgage: data.mortgage.checked ? 'Distributed' : '',
        documents: data.documents
          .filter((document: any) => document.checked)
          .map((document: any) => `${document.name}`)
          .join(', '),
      };
    });

    let sheetTitle = `${project.name} - Distribution-${convertAllDates(new Date(), 'PP')}`;

    downloadExcel([updatedData], [unitHeaders], [], [[widths]], [sheetTitle], sheetTitle);
  };

  const downloadDistribution = async () => {
    let selected = dealsState.filter((deal: any) => {
      let identifications = deal.purchasers.some((purchaser: any) =>
        purchaser.identifications.some((identification: any) => identification.checked)
      );
      let mortgage = deal.mortgage.checked ? deal.mortgage.checked : false;
      let depositImages = deal.depositImages.find((depositImage: any) => depositImage.checked);
      let documents = deal.documents.find((document: any) => document.checked);

      if (identifications || mortgage || depositImages || documents) {
        return deal;
      }
    });

    if (selected.length) {
      setDownloading(true);
      selected = selected.map((deals: any, index: number) => {
        let depositImages = deals.depositImages
          .filter((depositImage: any) => depositImage.checked)
          .map((depositImage: any) => {
            return {
              checked: depositImage.checked,
              getUrl: depositImage.getUrl,
              name: depositImage.name,
            };
          });

        let documents = deals.documents
          .filter((document: any) => document.checked && document.status === 'Completed')
          .map((document: any) => {
            return {
              _id: document._id,
              name: document.name,
              dsEnvelopeId: document.dsEnvelopeId,
            };
          });

        let purchasers = deals.purchasers
          .map((purchaser: any, numIndex: number) => {
            let identifications = purchaser.identifications
              .map((identification: any, index: number) => {
                if (identification.checked) {
                  return {
                    name: identification.name,
                    getUrl: identification.getUrl,
                  };
                } else return null;
              })
              .filter((identification: any) => identification !== null);

            return {
              _id: purchaser._id,
              identifications: identifications,
            };
          })
          .filter((purchaser: any) => purchaser !== null);

        let mortgage = {
          getUrl: deals.mortgage.getUrl ? deals.mortgage.getUrl : null,
          checked: deals.mortgage.checked ? true : false,
        };

        return {
          _id: deals._id,
          suite: deals.unit.suite,
          mortgage: mortgage,
          depositImages: depositImages,
          documents: documents,
          purchasers: purchasers,
        };
      });

      let files: any[] = await Promise.all(
        selected.map(async (selected: any) => {
          let documents: any[] = [];
          let depositImages: any[] = [];
          let mortgage;
          let purchasers: any[] = [];

          if (selected.mortgage.checked) {
            let response = await fetch(selected.mortgage.getUrl); // will fetch the data from s3 blob
            let data = await response.blob();

            mortgage = {
              pdf: data,
              name: 'Mortgage',
            };
          }

          if (selected.depositImages.length) {
            depositImages = await Promise.all(
              selected.depositImages.map(async (depositImage: any, index: number) => {
                let response = await fetch(depositImage.getUrl); // will fetch the data from s3 blob
                let data = await response.blob();

                return {
                  pdf: data,
                  name: `${depositImage.name}`,
                };
              })
            );
          }

          if (selected.documents.length) {
            documents = await Promise.all(
              selected.documents.map(async (document: any) => {
                let blob = await getDocument({ _id: document._id }).then((resp) => {
                  if (resp.data.documentById.previewData) {
                    const binaryData = Buffer.from(resp.data.documentById.previewData, 'binary');
                    const blob = new Blob([binaryData], { type: 'application/pdf' });
                    return blob;
                  }
                });

                return {
                  pdf: blob ? blob : null,
                  name: document.name,
                  dsEnvelopeId: document.dsEnvelopeId,
                };
              })
            );
          }

          purchasers = await Promise.all(
            selected.purchasers
              .map((purchaser: any, numIndex: number) => {
                let identifications = purchaser.identifications
                  .map(async (identification: any, index: number) => {
                    let response = await fetch(identification.getUrl); // will fetch the data from s3 blob
                    let data = await response.blob();
                    return {
                      name: `Purchaser ${numIndex + 1} - ID ${index + 1}`,
                      pdf: data,
                    };
                  })
                  .filter((identification: any) => identification !== null);

                return identifications;
              })
              .flat()
              .filter((purchaser: any) => purchaser !== null)
          );

          return {
            suite: selected.suite,
            mortgage: mortgage,
            documents: documents,
            depositImages: depositImages,
            purchasers: purchasers,
          };
        })
      );

      let searchedFiles = files.map((file: any) => {
        let emptyDsEnvelopeId = file.documents.find((document: any) => !document.pdf);
        if (emptyDsEnvelopeId && emptyDsEnvelopeId.dsEnvelopeId) {
          let result: any = null;
          for (const file of files) {
            result = file.documents.find((document: any) => {
              if (emptyDsEnvelopeId.dsEnvelopeId === document.dsEnvelopeId) {
                return document;
              }
            });
          }
          if (result) {
            let documents = file.documents.map((document: any) => {
              if (emptyDsEnvelopeId.name === result.name) {
                return {
                  ...document,
                  pdf: result ? result.pdf : null,
                };
              } else return document;
            });
            return {
              ...file,
              documents: documents,
            };
          } else return file;
        } else return file;
      });

      await downloadDistributionFiles(searchedFiles, `${project.name} - ${convertAllDates(new Date(), 'PP')} - Distribution`);

      await downloadPdfDistribution({
        variables: {
          project: project._id,
          distribution: selected,
        },
      }).then(async (res: any) => {
        if (!res.data || res.errors) {
          setDownloading(false);
          return storeDispatch(showErrorSnackbar('Issue Downloading Documents'));
        }
        storeDispatch(showErrorSnackbar('Distribution has been downloaded'));
        dealsDispatch({ type: 'UPDATEALL', payload: { value: res.data.downloadDistribution } });
        setDownloading(false);
      });
    } else return storeDispatch(showErrorSnackbar('No deals to distribute'));
  };

  const handleTab = (event: any, value: string) => {
    if (value !== null) {
      if (value === 'all') {
        dealsDispatch({ type: 'ADDALL', payload: allDeals });
      } else if (value === 'sent') {
        let toBeSent = allDeals.filter((deal: any) => {
          let deposits = false;
          let documents = false;
          let mortgage = false;
          let purchasers = 0;
          if (deal.depositImages.length) {
            deposits = deal.depositImages.every((deal: any) => deal.distribution);
          } else {
            deposits = true;
          }

          if (deal.documents.length) {
            documents = deal.documents.every(
              (deal: any) => (deal.distribution && deal.status === 'Completed') || deal.status !== 'Completed'
            );
          } else {
            documents = true;
          }

          if (deal.mortgage.amount && deal.mortgage.lender && deal.mortgage.getUrl && !deal.mortgage.revisions.length) {
            if (deal.mortgage.distribution) {
              mortgage = true;
            } else {
              mortgage = false;
            }
          } else {
            mortgage = true;
          }

          for (let i = 0; i < deal.purchasers.length; i++) {
            for (let j = 0; j < deal.purchasers[i].identifications.length; j++) {
              if (!deal.purchasers[i].identifications[j].distribution) {
                purchasers++;
              }
            }
          }

          return !deposits || !documents || !mortgage || purchasers > 0;
        });

        dealsDispatch({ type: 'ADDALL', payload: toBeSent });
      }
      setValue(value);
    }
  };

  return previewLoading || distributionLoading || downloading ? (
    <LoadingWrapper title="Loading..." modal={true} />
  ) : (
    <Box sx={{ p: 2 }}>
      <GlobalModal>
        <Box>
          <Typography variant={'h5'}>Selected Deals</Typography>
          <p>Please click below for a preview of the distribution list.</p>
          <Button onClick={() => downloadPreview()} variant="contained" color="success">
            Export Preview
          </Button>
          <p>Please note that after clicking the download distribution button, you will no longer be able to undistribute from the list.</p>
          <FlexBetween>
            <Button onClick={() => storeDispatch(handleModal(false))} variant="contained" color="info">
              Cancel
            </Button>
            <Button onClick={() => downloadDistribution()} variant="contained" color="success">
              Download Distribution
            </Button>
          </FlexBetween>
        </Box>
      </GlobalModal>
      <FlexBetween>
        <Typography variant={'h5'}>
          <strong>Distribution</strong>
        </Typography>
        {user.type !== 'Developer' ? (
          <Button onClick={() => storeDispatch(handleModal(true))} variant="contained" color="success">
            Download Distribution
          </Button>
        ) : null}
      </FlexBetween>
      {!loading ? (
        <Box sx={{ mt: 1 }}>
          <ToggleButtonGroup
            value={value}
            exclusive
            onChange={handleTab}
            aria-label="text alignment"
            sx={{
              '& .MuiToggleButton-root.Mui-selected': {
                backgroundColor: '#00142a',
                color: '#fff',
              },
              '& .MuiToggleButton-root.Mui-selected:hover': {
                backgroundColor: '#00142a',
                color: '#fff',
              },
            }}
          >
            <ToggleButton value="all" aria-label="centered">
              All
            </ToggleButton>
            <ToggleButton value="sent" aria-label="right aligned">
              To Be Distributed
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      ) : null}
      <Box sx={{ mt: 2 }}>
        <StandardTable loading={loading} columns={columns} data={dealsState} />
      </Box>
      {open ? <Lightbox mainSrc={image} onCloseRequest={() => setOpen(false)} /> : null}
    </Box>
  );
};

interface IIdentificationChecked extends IIdentification {
  checked?: boolean;
}

interface IDepositsChecked extends IDeposits {
  checked?: boolean;
}

interface IDocumentsChecked extends IDocuments {
  checked?: boolean;
}

const GETPREVIEWDATA = gql`
  query documentById($_id: MongoID!) {
    documentById(_id: $_id) {
      _id
      previewData
    }
  }
`;

const DISTRIBUTION = gql`
  query getDistribution($project: MongoID!) {
    getDistribution(project: $project) {
      _id
      unit {
        _id
        suite
      }
      purchasers {
        _id
        fullName
        project {
          _id
        }
        identifications {
          getUrl
          name
          distribution
        }
      }
      deposit {
        name
        chequeDate
        amount
        chequeNumber
        chequeType
      }
      depositImages {
        getUrl
        name
        distribution
      }
      mortgage {
        amount
        lender
        getUrl
        distribution
        revisions
      }
      documents {
        _id
        name
        status
        distribution
        dsEnvelopeId
      }
    }
  }
`;

const DOWNLOADDISTRIBUTION = gql`
  mutation downloadDistribution($project: MongoID!, $distribution: [NewDistributionInput]) {
    downloadDistribution(project: $project, distribution: $distribution) {
      _id
      unit {
        _id
        suite
      }
      purchasers {
        _id
        fullName
        project {
          _id
        }
        identifications {
          getUrl
          name
          distribution
        }
      }
      depositImages {
        getUrl
        name
        distribution
      }
      mortgage {
        amount
        lender
        getUrl
        distribution
        revisions
      }
      documents {
        _id
        name
        status
        distribution
        dsEnvelopeId
      }
    }
  }
`;

export default Distribution;
