import React, { useState, useEffect, useContext } from 'react';
import { IProject } from '../types/project';
import { IProjectAccess } from '../types/user';
import { UserContext } from './UserContext';

const ProjectContext = React.createContext<any>(null);

const ProjectProvider = (props: any) => {
  const { user } = useContext(UserContext);
  let projectId = '';
  if (props.id) {
    projectId = props.id.projectid;
  }

  useEffect(() => {
    if (user && Object.keys(user).length !== 0) {
      if (props.children.props.type === "create") {
        return;
      }
      let projectAccess = user.projectAccess.find((projectAccess: IProjectAccess) => projectAccess.project._id === projectId)
      if (!projectAccess) {
        props.history.push('/');
      } 
    }
  }, [user])
  
  const [project, setProject] = useState<IProject | {}>({
    name: '',
    developerName: '',
    addresses: [],
    email: '',
    trackingPhone: '',
    salesTrackingPhone: '',
    options: [],
    depositStructures: [
      {
        name: '',
        deposits: [
          {
            name: '', // 1st Deposit, 2nd Deposit etc
            type: '', // Fixed or Percent
            amount: 0, // 5,000 or 2.5%
            daysDue: 0, // 30 days
            dateType: '',
            dueDate: new Date(), // Specific Date
          },
        ],
        default: true,
      },
    ],
    mergeTemplates: [
      {
        name: 'floorplan',
        apsTemplates: {
          name: 'Main APS',
          pageNumber: 0
        }
      },
    ],
    executors: [],
    coopRates: [],
    adjustments: [],
    commissionIncludeOptions: false,
    status: [],
    emailTemplates: [],
    mortgageLimit: 2,
    hideOccupancy: true,
    defaultZeroValue: '$0',
    mortgageMinDay: 30,
    lawyer: '',
    tagLine: '',
    salesOffice: '',
    combinedProjects: [],
    portal: {
      active: false,
      vipActive: false,
      showHotlist: false,
      showOptionsPrice: false,
      showUnitTypes: false,
      showOptions: true,
      showCustom: true,
      sortHotlist: 'low',
      createWorksheets: false,
      password: null,
      information: [],
      promo: '',
      renderings: [],
      photos: [],
      pdfs: [],
      logos: [],
      aerialPhotos: [],
      bRoll: [],
      officialSite: '',
      instaUrl: '',
      subText: '',
      videos: []
    }
  });

  const [newEmail, setNewEmail] = useState({
    project: projectId,
    name: "",
    subject: "",
    html: "<p>Email Template</p>",
    attachments: []
  })

  const accessAllowed = (restriction: string) => {
    let project = user.projectAccess.find((projectAccess: IProjectAccess) => {
      return projectAccess.project._id === projectId;
    })
  
    if (project?.access.includes(restriction)) {
      return true
    } else return false
  }

  return <ProjectContext.Provider value={{ project, setProject, projectId, newEmail, setNewEmail, accessAllowed }}>{props.children}</ProjectContext.Provider>;
};

export { ProjectContext, ProjectProvider };
