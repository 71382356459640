import { useState, useEffect, FormEvent, ChangeEvent } from 'react';
import { useQuery, gql, useMutation } from '@apollo/client';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { IUserToken, ITokens } from '../../types/user';
import TextInput from '../common/formControls/TextInput';
import LoadingWrapper from '../common/LoadingWrapper';
import { useAppDispatch } from '../../app/hooks';
import { showSuccessSnackbar } from '../../features/snackbar/snackbarSlice';

const ResetPassword = () => {
  const storeDispatch = useAppDispatch();
  const navigate = useNavigate();
  const [user, setUser] = useState<IUserToken | null>(null);
  const [passwordText, setPasswordText] = useState<string>('');
  const [password, setPassword] = useState<IPassword>({
    errorText: '',
    error: false,
  });
  const [confirmPasswordText, setConfirmPasswordText] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<IPassword>({
    errorText: '',
    error: false,
  });
  const { token } = useParams();

  // Query to get the user by passing in the token from the URL

  const { loading } = useQuery<ITokens>(RESETTOKEN, {
    variables: { resetToken: token },
    onCompleted: (data) => {
      setUser(data.checkResetToken);
    },
    onError: (err) => {
      navigate('/');
      console.log(err, 'err');
    },
  });

  // Mutation to change password

  const [resetPassword] = useMutation(SETNEWPASSWORD, {
    variables: { token: token, id: user?._id, password: passwordText },
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar(data.resetPassword));
      navigate('/');
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  useEffect(() => {
    passwordValidation();
  }, [passwordText, confirmPasswordText]);

  // Password Validation

  const passwordValidation = () => {
    if (confirmPasswordText.length === 0 && passwordText.length === 0) {
      return;
    }
    if (passwordText.length < 8) {
      setPassword({
        ...password,
        error: true,
        errorText: 'Password is too short!',
      });
    } else if (confirmPasswordText !== passwordText) {
      setConfirmPassword({
        ...password,
        error: true,
        errorText: 'Passwords do not match!',
      });
    } else {
      setPassword({
        ...password,
        error: false,
        errorText: '',
      });
      setConfirmPassword({
        ...confirmPassword,
        error: false,
        errorText: '',
      });
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'password') {
      setPasswordText(e.target.value);
    } else if (e.target.name === 'confirmPassword') {
      setConfirmPasswordText(e.target.value);
    }
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (password.error || confirmPassword.error) {
      return;
    }
    if (!passwordText || !confirmPasswordText) {
      return;
    }
    resetPassword();
  };

  return (
    <Box
      sx={{
        backgroundColor: '#00142a',
        height: '100vh',
      }}
    >
      {loading ? (
        <LoadingWrapper title="Loading..." modal={false} />
      ) : (
        <Box
          sx={{
            top: '50%',
            left: '50%',
            width: '500px',
            display: 'flex',
            padding: '30px 20px',
            position: 'fixed',
            transform: 'translate(-50%, -50%)',
            borderRadius: '5px',
            flexDirection: 'column',
            paddingBottom: '30px',
            backgroundColor: '#ffffff',
            // '@media (max-width: 500px)': {
            //   width: 'auto',
            // },
          }}
        >
          <Box
            sx={{
              maxWidth: '200px',
              margin: '0 auto',
            }}
          >
            <img
              style={{
                maxWidth: '200px',
              }}
              src="https://s3.ca-central-1.amazonaws.com/app.rdsre.ca/logo.png"
              alt="logo"
            />
          </Box>
          <h3>Please Enter Your New Password</h3>
          <p>
            <em>Your password must be 8 characters long</em>
          </p>
          <div>
            <form onSubmit={handleSubmit} noValidate autoComplete="off">
              <Box
                sx={{
                  mb: 1,
                }}
              >
                <TextInput
                  error={password.error}
                  type="password"
                  required
                  name="password"
                  label="Please enter a new password"
                  placeholder="Please enter a new password"
                  helperText={password.errorText}
                  value={passwordText}
                  handleTextInput={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
                />
              </Box>
              <Box
                sx={{
                  mb: 1,
                }}
              >
                <TextInput
                  error={confirmPassword.error}
                  type="password"
                  required
                  name="confirmPassword"
                  label="Confirm new password"
                  placeholder="Confirm new password"
                  value={confirmPasswordText}
                  handleTextInput={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
                  helperText={confirmPassword.errorText}
                />
              </Box>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </form>
          </div>
        </Box>
      )}
    </Box>
  );
};

interface IPassword {
  error: boolean;
  errorText: string;
}

const RESETTOKEN = gql`
  query checkResetToken($resetToken: String!) {
    checkResetToken(resetToken: $resetToken) {
      _id
      resetToken
      expireToken
    }
  }
`;

const SETNEWPASSWORD = gql`
  mutation resetPassword($token: String!, $id: String!, $password: String!) {
    resetPassword(token: $token, id: $id, password: $password)
  }
`;

export default ResetPassword;
