import { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Typography, Box, Grid, TextField, Button } from '@mui/material';
import { numToCurrency, downloadExcel, downloadPdf } from '../../../utils/Functions';
import { selectProject } from '../../../features/project/projectSlice';
import StandardTable from '../../tables/StandardTable';
import { IUnit } from '../../../types/unit';
import { customTierUnits } from '../../../utils/CustomSettings';
import { DeleteForever } from '@mui/icons-material';

const LevelSummary = (props: ChildProps) => {
  const project = useSelector(selectProject);
  const { units, tierType } = props;
  const [levels, setLevels] = useState<ILevel[]>([
    {
      start: '',
      end: '',
    },
  ]);

  const columns = useMemo(() => {
    let dataColumns = [
      {
        Header: 'Levels',
        accessor: (rowData: any) => rowData.unitType,
      },
      {
        Header: 'Average PPSF',
        accessor: (rowData: any) => rowData.averagePpsf,
      },
      {
        Header: 'Average Price',
        accessor: (rowData: any) => rowData.averagePrice,
      },
      {
        Header: 'Average Size',
        accessor: (rowData: any) => rowData.averageSize,
      },
      {
        Header: 'Lowest Price',
        accessor: (rowData: any) => rowData.lowestPrice,
      },
      {
        Header: 'Highest Price',
        accessor: (rowData: any) => rowData.highestPrice,
      },
      {
        Header: 'Median',
        accessor: (rowData: any) => rowData.median,
      },
      {
        Header: 'Units Total',
        accessor: (rowData: any) => `${rowData.unitsTotal} (${Math.round((rowData.unitsTotal / units.length) * 100)}%)`,
      },
      {
        Header: 'Units Available',
        accessor: (rowData: any) => rowData.unitsAvailable,
      },
      {
        Header: 'Units Pending',
        accessor: (rowData: any) => rowData.unitsPending,
      },
      {
        Header: 'Units Sold (C, F)',
        accessor: (rowData: any) =>
          `${rowData.unitsSold} (${rowData.unitsTotal ? Math.round((rowData.unitsSold / rowData.unitsTotal) * 10000) / 100 : '0'}%)`,
      },
      {
        Header: 'Sold Revenue',
        accessor: (rowData: any) => `${rowData.soldRevenue}`,
      },
      {
        Header: 'Total Revenue',
        accessor: (rowData: any) => `${rowData.totalRevenue} (${rowData.totalRevenuePercentage})`,
      },
    ];

    return dataColumns;
  }, [levels]);

  const handleLevel = (e: React.ChangeEvent<HTMLInputElement>, levelIndex: number) => {
    let allLevels = levels.map((level: ILevel, index: number) => {
      if (index === levelIndex) {
        return {
          ...level,
          [e.target.name]: e.target.value,
        };
      } else return level;
    });
    setLevels(allLevels);
  };

  const downloadLevels = (type: string, data: any) => {
    let unitColumns = [
      {
        label: 'Levels',
        id: 'unitType',
      },
      {
        label: 'Average Price',
        id: 'averagePrice',
      },
      {
        label: 'Average Size',
        id: 'averageSize',
      },
      {
        label: 'Average PPSF',
        id: 'averagePpsf',
      },
      {
        label: 'Lowest Price',
        id: 'lowestPrice',
      },
      {
        label: 'Highest Price',
        id: 'highestPrice',
      },
      {
        label: 'Median',
        id: 'median',
      },
      {
        label: 'Units Total',
        id: 'unitsTotal',
      },
      {
        label: 'Units Available',
        id: 'unitsAvailable',
      },
      {
        label: 'Units Pending',
        id: 'unitsPending',
      },
      {
        label: 'Units Sold (C, F)',
        id: 'unitsSold',
      },
      {
        label: 'Sold Revenue',
        id: 'soldRevenue',
      },
      {
        label: 'Total Revenue',
        id: 'totalRevenue',
      },
    ];

    let widths = {
      unitType: 15,
      averagePrice: 15,
      averageSize: 15,
      averagePpsf: 15,
      lowestPrice: 15,
      highestPrice: 15,
      median: 15,
      unitsTotal: 15,
      unitsAvailable: 15,
      unitsPending: 15,
      unitsSold: 15,
      soldRevenue: 15,
      totalRevenue: 15,
    };

    let pdfWidths = {
      unitType: 200,
      averagePrice: 200,
      averageSize: 200,
      averagePpsf: 200,
      lowestPrice: 200,
      highestPrice: 200,
      median: 200,
      unitsTotal: 200,
      unitsAvailable: 200,
      unitsPending: 200,
      unitsSold: 200,
      soldRevenue: 15,
      totalRevenue: 15,
    };

    let sheetTitle = `${project.name} - Level Summary`;

    if (type === 'excel') {
      downloadExcel([data], [unitColumns], [], [[widths]], [sheetTitle], sheetTitle);
    } else {
      downloadPdf([data], [unitColumns], [], [pdfWidths], [sheetTitle], sheetTitle, project.logoGetUrl);
    }
  };

  let data = () => {
    if (!units.length) return [];
    let allUnits = units;

    if (tierType) {
      allUnits = units.filter((unit: IUnit) => customTierUnits(project._id, units, tierType).includes(unit.tier));
    }

    let unitData: any[] = levels.map((level, index: number) => {
      let validUnits = allUnits.filter(
        (unit) => parseInt(level.start, 10) <= parseInt(unit.level, 10) && parseInt(level.end, 10) >= parseInt(unit.level, 10)
      );

      const totalRevenueAllUnits: number = allUnits.map((unit) => unit.basePrice).reduce((a, b) => a + b, 0);
      const validUnitPrices = validUnits.map((unit) => unit.basePrice);
      const totalPrice = validUnits.map((unit) => unit.basePrice).reduce((a, b) => a + b, 0);
      const totalSize = validUnits.map((unit) => unit.size).reduce((a, b) => a + b, 0);
      const mid = Math.floor(validUnitPrices.length / 2);
      const nums = [...validUnitPrices].sort((a, b) => a - b);
      const typeRevenue = validUnits.map((unit) => unit.basePrice).reduce((a, b) => a + b, 0);
      const soldRevenue = validUnits
        .filter((unit) => unit.status === 'F' || unit.status === 'C')
        .map((unit) => unit.basePrice)
        .reduce((a, b) => a + b, 0);
      const totalUnits = validUnits.length;
      const availableUnits = validUnits.filter(
        (unit) =>
          unit.status !== 'F' &&
          unit.status !== 'C' &&
          unit.status !== 'S' &&
          unit.status !== 'O' &&
          unit.status !== 'IP' &&
          unit.status !== 'P'
      ).length;
      const pendingUnits = validUnits.filter((unit) => unit.status === 'P' || unit.status === 'O' || unit.status === 'S').length;
      const soldUnits = validUnits.filter((unit) => unit.status === 'F' || unit.status === 'C').length;

      return {
        units: validUnits,
        unitType: level.start && level.end ? `Levels ${level.start} - ${level.end}` : '',
        averagePrice: validUnits.length ? numToCurrency.format(totalPrice / validUnits.length) : 'N/A',
        averageSize: validUnits.length ? (totalSize / validUnits.length).toFixed(2) : 'N/A',
        averagePpsf: totalSize ? numToCurrency.format(totalPrice / totalSize) : 'N/A',
        lowestPrice: validUnitPrices.length > 0 ? numToCurrency.format(Math.min(...validUnitPrices)) : 'N/A',
        highestPrice: validUnitPrices.length > 0 ? numToCurrency.format(Math.max(...validUnitPrices)) : 'N/A',
        median:
          validUnitPrices.length === 0
            ? 'N/A'
            : validUnitPrices.length % 2 !== 0
            ? numToCurrency.format(nums[mid])
            : numToCurrency.format((nums[mid - 1] + nums[mid]) / 2),
        soldRevenue: numToCurrency.format(soldRevenue),
        soldRevenuePercentage: isNaN(Math.round((soldRevenue / typeRevenue) * 10000) / 100)
          ? '$0'
          : Math.round((soldRevenue / totalRevenueAllUnits) * 10000) / 100 + '%',
        totalRevenue: numToCurrency.format(typeRevenue),
        totalRevenuePercentage: isNaN(Math.round((typeRevenue / totalRevenueAllUnits) * 10000) / 100)
          ? '$0'
          : Math.round((typeRevenue / totalRevenueAllUnits) * 10000) / 100 + '%',
        unitsTotal: totalUnits,
        unitsAvailable: availableUnits,
        unitsPending: pendingUnits,
        unitsSold: soldUnits,
      };
    });

    let allValidUnits = unitData.map((units: any) => units.units).flat();

    const allUnitPrices = allValidUnits.map((unit) => unit.basePrice);
    const totalRevenue = allValidUnits.map((unit) => unit.basePrice).reduce((a, b) => a + b, 0);
    const totalSize = allValidUnits.map((unit) => unit.size).reduce((a, b) => a + b, 0);
    const totalSoldRevenue = allValidUnits
      .filter((unit) => unit.status === 'F' || unit.status === 'C')
      .map((unit) => unit.basePrice)
      .reduce((a, b) => a + b, 0);
    const totalAvailableUnits = allValidUnits.filter(
      (unit) =>
        unit.status !== 'F' &&
        unit.status !== 'C' &&
        unit.status !== 'S' &&
        unit.status !== 'O' &&
        unit.status !== 'IP' &&
        unit.status !== 'P'
    ).length;
    const totalPendingUnits = allValidUnits.filter((unit) => unit.status === 'P' || unit.status === 'O' || unit.status === 'S').length;
    const totalSoldUnits = allValidUnits.filter((unit) => unit.status === 'F' || unit.status === 'C').length;
    const midAll = Math.floor(allUnitPrices.length / 2);
    const numsAll = [...allUnitPrices].sort((a, b) => a - b);

    let totalUnitData = {
      unitType: 'Total',
      outdoorType: 'N/A',
      averagePrice: allValidUnits.length ? numToCurrency.format(totalRevenue / units.length) : 'N/A',
      averageSize: allValidUnits.length ? (totalSize / units.length).toFixed(2) : 'N/A',
      averagePpsf: allValidUnits.length ? numToCurrency.format(totalRevenue / totalSize) : 'N/A',
      lowestPrice: allUnitPrices.length > 0 ? numToCurrency.format(Math.min(...allUnitPrices)) : 'N/A',
      highestPrice: allUnitPrices.length > 0 ? numToCurrency.format(Math.max(...allUnitPrices)) : 'N/A',
      median: allValidUnits
        ? allUnitPrices.length === 0
          ? 'N/A'
          : allUnitPrices.length % 2 !== 0
          ? numToCurrency.format(numsAll[midAll])
          : numToCurrency.format((numsAll[midAll - 1] + numsAll[midAll]) / 2)
        : 'N/A',
      soldRevenue: allValidUnits ? numToCurrency.format(totalSoldRevenue) : 'N/A',
      soldRevenuePercentage: allValidUnits
        ? isNaN(Math.round((totalSoldRevenue / totalRevenue) * 10000) / 100)
          ? '$0'
          : Math.round((totalSoldRevenue / totalRevenue) * 10000) / 100 + '%'
        : 'N/A',
      totalRevenue: allValidUnits ? numToCurrency.format(totalRevenue) : 'N/A',
      totalRevenuePercentage: allValidUnits ? '100%' : 'N/A',
      unitsTotal: allValidUnits.length,
      unitsAvailable: allValidUnits ? totalAvailableUnits : 'N/A',
      unitsPending: allValidUnits ? totalPendingUnits : 'N/A',
      unitsSold: allValidUnits ? totalSoldUnits : 'N/A',
    };

    return [...unitData, totalUnitData];
  };

  return (
    <div>
      <Typography variant={'h5'} gutterBottom>
        Level Summary
      </Typography>
      <Box>
        {levels.map((level: ILevel, index: number) => {
          return (
            <Grid container spacing={2} key={index} sx={{ mb: 2 }}>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  title={'Level Start'}
                  name={'start'}
                  fullWidth
                  type="number"
                  onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
                  value={level.start}
                  label={'Level Start'}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleLevel(e, index)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  title={'Level End'}
                  name={'end'}
                  fullWidth
                  type="number"
                  onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
                  value={level.end}
                  label={'Level End'}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleLevel(e, index)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} sx={{ alignSelf: 'center' }}>
                <DeleteForever
                  color="error"
                  sx={{ alignSelf: 'center', cursor: 'pointer' }}
                  onClick={() => setLevels(levels.filter((level, levelIndex: number) => levelIndex !== index))}
                />
              </Grid>
            </Grid>
          );
        })}
        <Button
          onClick={() =>
            setLevels([
              ...levels,
              {
                start: '',
                end: '',
              },
            ])
          }
          sx={{ mb: 2 }}
          variant="contained"
          color="primary"
        >
          Add Level
        </Button>
        <StandardTable download={downloadLevels} columns={columns} data={data()} />
      </Box>
    </div>
  );
};

interface ChildProps {
  units: IUnit[];
  tierType: string;
}

interface ILevel {
  start: string;
  end: string;
}

export default LevelSummary;
