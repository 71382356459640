import React, { useState, useEffect, useContext } from 'react';
import { addDays, addMonths, addYears, subDays } from 'date-fns';
import { gql, useMutation, useLazyQuery } from '@apollo/client';

import { IUnit } from '../types/unit';
import { IPurchaserInfo, IRealtorsArray, IRealtorInfo, IDealOption } from '../types/CreateDealForm';
import { useSelector } from 'react-redux';
import { selectProject } from '../features/project/projectSlice';
import { ordinal_suffix_of } from '../utils/Functions';
import { IDepositStructures, IDeposits } from '../types/project';
import { UnitContext } from './UnitContext';
import { useCreateActivity } from '../features/activity/activityHooks';
import { useAppDispatch } from '../app/hooks';
import { selectUser } from '../features/auth/authSlice';
import { showSuccessSnackbar } from '../features/snackbar/snackbarSlice';
import { IOption } from '../types/project';

const DealContext = React.createContext<any>(null);

const DealProvider = (props: any) => {
  const project = useSelector(selectProject);
  const user = useSelector(selectUser);
  const createActivity = useCreateActivity();
  const storeDispatch = useAppDispatch();
  const { unit, filteredDeal, setFilteredDeal } = useContext(UnitContext);
  const [document, setDocument] = useState<string | null>(null);
  const [originalPurchasers, setOriginalPurchasers] = useState<IPurchaserInfo[]>([]);
  const [realtors, setRealtors] = useState<IRealtorInfo | {}>({});
  const [rental, setRental] = useState<number>();
  const [selectedDeposit, setSelectedDeposit] = useState<string>('');
  const [optionsOnly, setOptionsOnly] = useState<boolean>(false);
  const [keepDeposits, setKeepDeposits] = useState<boolean>(false);
  const [unitTransfer, setUnitTransfer] = useState<IUnit | null>(null);
  const [unitTransferTotal, setUnitTransferTotal] = useState<number>(unitTransfer?.basePrice);

  // Pending
  const [pendingDeposits, setPendingDeposits] = useState<any[]>([]);
  const [pendingBasePrice, setPendingBasePrice] = useState<string>('');
  const [pendingOptions, setPendingOptions] = useState<IDealOption[]>([]);

  const [getRealtors, { loading: realtorLoading }] = useLazyQuery<IRealtorsArray>(REALTORS, {
    onCompleted: (data) => setRealtors(data.realtorMany),
  });

  const [updatePendingDeposit] = useMutation(UPDATEPENDING, {
    onCompleted: (data) => {
      setFilteredDeal({
        ...filteredDeal,
        pendingDeposits: data.dealUpdateById.record.pendingDeposits,
        pendingOptions: data.dealUpdateById.record.pendingOptions,
        pendingUnit: data.dealUpdateById.record.pendingUnit,
      });
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [updateDealDocuments, { loading: dealUpdateLoading }] = useMutation(UPDATEDEALDOCUMENTS, {
    onCompleted: (data) => {
      if (data.dealUpdateById.record.pendingDeposits.length) {
        setFilteredDeal({
          ...filteredDeal,
          pendingDeposits: data.dealUpdateById.record.pendingDeposits,
          pendingOptions: data.dealUpdateById.record.pendingOptions,
          pendingUnit: data.dealUpdateById.record.pendingUnit,
        });
      }
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [removeDeposits] = useMutation(REMOVEDEPOSITS, {
    onCompleted: (data) => {},
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [createDepositAmendment] = useMutation(CREATEDEPOSITAMENDMENT, {
    onCompleted: (data) => {
      setFilteredDeal({
        ...filteredDeal,
        pendingDeposits: data.createDepositAmendment.deposits,
        documents: [...filteredDeal.documents, data.createDepositAmendment.document],
        pendingOptions: data.createDepositAmendment.options,
      });
      createActivity({
        project: project._id,
        user: user._id,
        deal: filteredDeal._id,
        title: `Deposit Amendment`,
        content: 'Deposit Amendment has been created',
      });
      setDocument(null);
      storeDispatch(showSuccessSnackbar('Deposit Amendment Created'));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  useEffect(() => {
    getDepositStructure();
  }, [selectedDeposit, unitTransferTotal, optionsOnly, keepDeposits, pendingBasePrice]);

  const getDepositStructure = () => {
    if (selectedDeposit) {
      let selectedDepositStructure = project.depositStructures.filter(
        (depositStructure: IDepositStructures) => selectedDeposit === depositStructure.name
      );

      let depositData = selectedDepositStructure[0].deposits.map((data: IDeposits, index: number) => {
        let dealTotalPrice = unitTransfer ? unitTransferTotal : filteredDeal.basePrice;
        if (optionsOnly) {
          if (filteredDeal.options.length > 0) {
            let filteredPending = pendingOptions.map((option: IDealOption, index: number) => {
              let duplicateOption = filteredDeal.options.find((filteredOption: IDealOption) => filteredOption.name === option.name);
              if (duplicateOption) {
                if (option.purchaseAmount !== duplicateOption.purchaseAmount) {
                  return {
                    name: option.name,
                    purchaseAmount: option?.purchaseAmount! - duplicateOption.purchaseAmount,
                    amount: option?.amount! - duplicateOption.amount,
                  };
                } else
                  return {
                    name: option.name,
                    purchaseAmount: 0,
                    amount: 0,
                  };
              } else return option;
            });
            dealTotalPrice = filteredPending.reduce((a: any, b: any) => {
              return a + b.amount;
            }, 0);
          } else {
            dealTotalPrice = pendingOptions.reduce((a: any, b: any) => {
              return a + b.amount;
            }, 0);
          }
        }

        // Balance
        const balanceFunction = (index: number) => {
          let sum = 0;
          for (let i = 0; i < index; i++) {
            if (selectedDepositStructure[0].deposits[i].type === 'Fixed') {
              sum += selectedDepositStructure[0].deposits[i].amount;
            }
          }
          return sum;
        };

        if (document === 'Deposits') {
          dealTotalPrice = filteredDeal.options.reduce(
            (a: any, b: any) => {
              return a + b.amount;
            },
            pendingBasePrice ? parseInt(pendingBasePrice, 10) : filteredDeal.basePrice
          );
        }

        if (document === 'Options') {
          dealTotalPrice = pendingOptions.reduce((a: any, b: any) => {
            return a + b.amount;
          }, filteredDeal.basePrice);
        }

        if (keepDeposits) {
          return {
            ...data,
            amount: filteredDeal.deposit[index].amount,
            dueDate: filteredDeal.deposit[index].dueDate,
          };
        } else {
          let dueDate =
            filteredDeal.createdAt && data.dateType !== 'Specific Date' && filteredDeal.deposit.length
              ? addDays(new Date(filteredDeal.deposit[0].dueDate), data.daysDue)
              : data.dueDate;

          if (data.dateType === 'Floating Date') {
            dueDate = unit.firmOccupancy
              ? new Date(unit.firmOccupancy)
              : project.firmOccupancy
              ? new Date(project.firmOccupancy)
              : new Date(addYears(new Date(), 5));
          }

          if (data.dateType === '90 Days Before Firm Occupancy') {
            dueDate = unit.firmOccupancy
              ? new Date(subDays(new Date(unit.firmOccupancy), 90))
              : project.firmOccupancy
              ? new Date(subDays(new Date(project.firmOccupancy), 90))
              : new Date(addYears(new Date(), 5));
          }

          if (data.dateType === '120 Days Before Firm Occupancy') {
            dueDate = unit.firmOccupancy
              ? new Date(subDays(new Date(unit.firmOccupancy), 120))
              : project.firmOccupancy
              ? new Date(subDays(new Date(project.firmOccupancy), 120))
              : new Date(addYears(new Date(), 5));
          }

          if (data.dateType === '180 Days Before Firm Occupancy') {
            dueDate = unit.firmOccupancy
              ? new Date(subDays(new Date(unit.firmOccupancy), 180))
              : project.firmOccupancy
              ? new Date(subDays(new Date(project.firmOccupancy), 180))
              : new Date(addYears(new Date(), 5));
          }

          if (data.type === 'Fixed') {
            if (data.daysDue) {
              return {
                ...data,
                dueDate: dueDate,
                percentage: ((data.amount / dealTotalPrice) * 100).toFixed(2),
              };
            } else {
              return {
                ...data,
                dueDate:
                  filteredDeal.createdAt && data.dateType !== 'Specific Date' && filteredDeal.deposit.length
                    ? new Date(filteredDeal.deposit[0].dueDate)
                    : data.dueDate,
                percentage: ((data.amount / dealTotalPrice) * 100).toFixed(2),
              };
            }
          } else if (data.type === 'Balance to 1.5%') {
            return {
              ...data,
              dueDate: dueDate,
              percentage: ((Math.round(dealTotalPrice * 0.015 - balanceFunction(index)) / dealTotalPrice) * 100).toFixed(2),
              amount: Math.round(dealTotalPrice * 0.015 - balanceFunction(index)),
            };
          } else if (data.type === 'Balance to 2.5%') {
            return {
              ...data,
              dueDate: dueDate,
              percentage: ((Math.round(dealTotalPrice * 0.025 - balanceFunction(index)) / dealTotalPrice) * 100).toFixed(2),
              amount: Math.round(dealTotalPrice * 0.025 - balanceFunction(index)),
            };
          } else if (data.type === 'Balance to 3%') {
            return {
              ...data,
              dueDate: dueDate,
              percentage: ((Math.round(dealTotalPrice * 0.03 - balanceFunction(index)) / dealTotalPrice) * 100).toFixed(2),
              amount: Math.round(dealTotalPrice * 0.03 - balanceFunction(index)),
            };
          } else if (data.type === 'Balance to 5%') {
            return {
              ...data,
              dueDate: dueDate,
              percentage: ((Math.round(dealTotalPrice * 0.05 - balanceFunction(index)) / dealTotalPrice) * 100).toFixed(2),
              amount: Math.round(dealTotalPrice * 0.05 - balanceFunction(index)),
            };
          } else if (data.type === 'Balance to 7.5%') {
            return {
              ...data,
              dueDate: dueDate,
              percentage: ((Math.round(dealTotalPrice * 0.075 - balanceFunction(index)) / dealTotalPrice) * 100).toFixed(2),
              amount: Math.round(dealTotalPrice * 0.075 - balanceFunction(index)),
            };
          } else if (data.type === 'Balance to 10%') {
            return {
              ...data,
              dueDate: dueDate,
              percentage: ((Math.round(dealTotalPrice * 0.1 - balanceFunction(index)) / dealTotalPrice) * 100).toFixed(2),
              amount: Math.round(dealTotalPrice * 0.1 - balanceFunction(index)),
            };
          } else if (data.type === 'Balance to 15%') {
            return {
              ...data,
              dueDate: dueDate,
              percentage: ((Math.round(dealTotalPrice * 0.15 - balanceFunction(index)) / dealTotalPrice) * 100).toFixed(2),
              amount: Math.round(dealTotalPrice * 0.15 - balanceFunction(index)),
            };
          } else if (data.type === 'Balance to 10% Monthly') {
            let balance = Math.round(dealTotalPrice * 0.1 - balanceFunction(index));
            let months = Math.ceil((dealTotalPrice * 0.1 - balanceFunction(index)) / data.amount);
            let dueDate = new Date();
            let monthlyArray = [];
            if (data.dateType === 'Every Month after 1st Deposit') {
              dueDate =
                filteredDeal.createdAt && selectedDepositStructure[0].deposits[1].dateType !== 'Specific Date'
                  ? addDays(new Date(addDays(new Date(filteredDeal.createdAt), selectedDepositStructure[0].deposits[0].daysDue)), 30)
                  : new Date(selectedDepositStructure[0].deposits[0].dueDate);
            } else if (data.dateType === 'Every Month after 2nd Deposit') {
              dueDate =
                filteredDeal.createdAt && selectedDepositStructure[0].deposits[1].dateType !== 'Specific Date'
                  ? addDays(new Date(addDays(new Date(filteredDeal.createdAt), selectedDepositStructure[0].deposits[1].daysDue)), 30)
                  : new Date(selectedDepositStructure[0].deposits[1].dueDate);
            } else if (data.dateType === 'Every Month after 3rd Deposit') {
              dueDate =
                filteredDeal.createdAt && selectedDepositStructure[0].deposits[1].dateType !== 'Specific Date'
                  ? addDays(new Date(addDays(new Date(filteredDeal.createdAt), selectedDepositStructure[0].deposits[2].daysDue)), 30)
                  : new Date(selectedDepositStructure[0].deposits[2].dueDate);
            } else if (data.dateType === '1st of Every Month after 1st Deposit') {
              dueDate =
                filteredDeal.createdAt && selectedDepositStructure[0].deposits[1].dateType !== 'Specific Date'
                  ? addDays(new Date(addDays(new Date(filteredDeal.createdAt), selectedDepositStructure[0].deposits[0].daysDue)), 30)
                  : new Date(selectedDepositStructure[0].deposits[2].dueDate);

              dueDate = addMonths(dueDate, 1);
              // Add 1 month Prorated
            } else if (data.dateType === '1st of Every Month after 2nd Deposit') {
              dueDate =
                filteredDeal.createdAt && selectedDepositStructure[0].deposits[0].dateType !== 'Specific Date'
                  ? new Date(addDays(new Date(filteredDeal.createdAt), selectedDepositStructure[0].deposits[1].daysDue))
                  : new Date(selectedDepositStructure[0].deposits[2].dueDate);

              dueDate = addMonths(dueDate, 1);
            } else if (data.dateType === '1st of Every Month after 3rd Deposit') {
              dueDate =
                filteredDeal.createdAt && selectedDepositStructure[0].deposits[1].dateType !== 'Specific Date'
                  ? addDays(new Date(addDays(new Date(filteredDeal.createdAt), selectedDepositStructure[0].deposits[2].daysDue)), 30)
                  : new Date(selectedDepositStructure[0].deposits[2].dueDate);

              dueDate = addMonths(dueDate, 1);
            }

            for (let i = 0; i < months; i++) {
              let newDueDate;
              if (
                data.dateType === 'Every Month after 1st Deposit' ||
                data.dateType === 'Every Month after 2nd Deposit' ||
                data.dateType === 'Every Month after 3rd Deposit'
              ) {
                newDueDate = addDays(dueDate, i * 30);
              } else {
                newDueDate = new Date(dueDate.setMonth(dueDate.getMonth() + 1, 1));
              }
              monthlyArray.push({
                ...data,
                name: `${ordinal_suffix_of(index + (i + 1))} Deposit`,
                dueDate: newDueDate,
                percentage: ((data.amount / dealTotalPrice) * 100).toFixed(2),
                amount: i === months - 1 ? balance - data.amount * i : data.amount,
              });
            }

            return monthlyArray;
          } else if (data.type === 'Balance to 15% Monthly') {
            let balance = Math.round(dealTotalPrice * 0.15 - balanceFunction(index));
            let months = Math.ceil((dealTotalPrice * 0.15 - balanceFunction(index)) / data.amount);
            let dueDate = new Date();
            let monthlyArray = [];
            if (data.dateType === 'Every Month after 1st Deposit') {
              dueDate = new Date(filteredDeal.deposit[0].dueDate);
            } else if (data.dateType === 'Every Month after 2nd Deposit') {
              dueDate =
                filteredDeal.createdAt && selectedDepositStructure[0].deposits[1].dateType !== 'Specific Date'
                  ? addDays(new Date(addDays(new Date(filteredDeal.createdAt), selectedDepositStructure[0].deposits[1].daysDue)), 30)
                  : new Date(selectedDepositStructure[0].deposits[1].dueDate);
            } else if (data.dateType === 'Every Month after 3rd Deposit') {
              dueDate =
                filteredDeal.createdAt && selectedDepositStructure[0].deposits[1].dateType !== 'Specific Date'
                  ? addDays(new Date(addDays(new Date(filteredDeal.createdAt), selectedDepositStructure[0].deposits[2].daysDue)), 30)
                  : new Date(selectedDepositStructure[0].deposits[2].dueDate);
            } else if (data.dateType === '1st of Every Month after 1st Deposit') {
              dueDate = new Date(filteredDeal.deposit[0].dueDate);
              dueDate = addMonths(dueDate, 1);
              // Add 1 month Prorated
            } else if (data.dateType === '1st of Every Month after 2nd Deposit') {
              dueDate =
                filteredDeal.createdAt && selectedDepositStructure[0].deposits[0].dateType !== 'Specific Date'
                  ? new Date(addDays(new Date(filteredDeal.createdAt), selectedDepositStructure[0].deposits[1].daysDue))
                  : new Date(selectedDepositStructure[0].deposits[1].dueDate);

              dueDate = addMonths(dueDate, 1);
            } else if (data.dateType === '1st of Every Month after 3rd Deposit') {
              dueDate =
                filteredDeal.createdAt && selectedDepositStructure[0].deposits[1].dateType !== 'Specific Date'
                  ? addDays(new Date(addDays(new Date(filteredDeal.createdAt), selectedDepositStructure[0].deposits[2].daysDue)), 30)
                  : new Date(selectedDepositStructure[0].deposits[2].dueDate);

              dueDate = addMonths(dueDate, 1);
            }

            for (let i = 0; i < months; i++) {
              let newDueDate;
              if (
                data.dateType === 'Every Month after 1st Deposit' ||
                data.dateType === 'Every Month after 2nd Deposit' ||
                data.dateType === 'Every Month after 3rd Deposit'
              ) {
                newDueDate = addDays(dueDate, i * 30);
              } else {
                newDueDate = new Date(dueDate.setMonth(dueDate.getMonth() + 1, 1));
              }
              monthlyArray.push({
                ...data,
                name: `${ordinal_suffix_of(index + (i + 1))} Deposit`,
                dueDate: newDueDate,
                percentage: ((data.amount / dealTotalPrice) * 100).toFixed(2),
                amount: i === months - 1 ? balance - data.amount * i : data.amount,
              });
            }

            return monthlyArray;
          } else if (data.type === 'Balance to 15% Monthly (36 Months)') {
            let months = 36;
            let monthlyBalance = Math.ceil((dealTotalPrice * 0.15 - balanceFunction(index)) / 36);
            let dueDate = new Date();
            let monthlyArray = [];
            if (data.dateType === 'Every Month after 1st Deposit') {
              dueDate = new Date(filteredDeal.deposit[0].dueDate);
            } else if (data.dateType === 'Every Month after 2nd Deposit') {
              dueDate =
                filteredDeal.createdAt && selectedDepositStructure[0].deposits[1].dateType !== 'Specific Date'
                  ? addDays(new Date(addDays(new Date(filteredDeal.createdAt), selectedDepositStructure[0].deposits[1].daysDue)), 30)
                  : new Date(selectedDepositStructure[0].deposits[1].dueDate);
            } else if (data.dateType === 'Every Month after 3rd Deposit') {
              dueDate =
                filteredDeal.createdAt && selectedDepositStructure[0].deposits[1].dateType !== 'Specific Date'
                  ? addDays(new Date(addDays(new Date(filteredDeal.createdAt), selectedDepositStructure[0].deposits[2].daysDue)), 30)
                  : new Date(selectedDepositStructure[0].deposits[2].dueDate);
            } else if (data.dateType === '1st of Every Month after 1st Deposit') {
              dueDate = new Date(filteredDeal.deposit[0].dueDate);
              dueDate = addMonths(dueDate, 1);
              // Add 1 month Prorated
            } else if (data.dateType === '1st of Every Month after 2nd Deposit') {
              dueDate =
                filteredDeal.createdAt && selectedDepositStructure[0].deposits[0].dateType !== 'Specific Date'
                  ? addDays(new Date(addDays(new Date(filteredDeal.createdAt), selectedDepositStructure[0].deposits[1].daysDue)), 30)
                  : new Date(selectedDepositStructure[0].deposits[1].dueDate);

              dueDate = addMonths(dueDate, 1);
            } else if (data.dateType === '1st of Every Month after 3rd Deposit') {
              dueDate =
                filteredDeal.createdAt && selectedDepositStructure[0].deposits[1].dateType !== 'Specific Date'
                  ? addDays(new Date(addDays(new Date(filteredDeal.createdAt), selectedDepositStructure[0].deposits[2].daysDue)), 30)
                  : new Date(selectedDepositStructure[0].deposits[2].dueDate);

              dueDate = addMonths(dueDate, 1);
            }

            for (let i = 0; i < months; i++) {
              let newDueDate;
              if (
                data.dateType === 'Every Month after 1st Deposit' ||
                data.dateType === 'Every Month after 2nd Deposit' ||
                data.dateType === 'Every Month after 3rd Deposit'
              ) {
                newDueDate = addDays(dueDate, i * 30);
              } else {
                newDueDate = new Date(dueDate.setMonth(dueDate.getMonth() + 1, 1));
              }
              monthlyArray.push({
                ...data,
                name: `${ordinal_suffix_of(index + (i + 1))} Deposit`,
                dueDate: newDueDate,
                percentage: ((monthlyBalance / dealTotalPrice) * 100).toFixed(2),
                amount: monthlyBalance,
              });
            }

            return monthlyArray;
          } else {
            return {
              ...data,
              dueDate: dueDate,
              percentage: data.amount,
              amount: Math.round((dealTotalPrice * data.amount) / 100),
            };
          }
        }
      });
      setPendingDeposits(depositData.flat());
    } else return;
  };

  const optionValue = (name: string, type: string, numIndex: number) => {
    let selectedOption: any = pendingOptions.find((option: IDealOption, index: number) => {
      return index === numIndex;
    });
    if (selectedOption) {
      return selectedOption[type];
    }
  };

  const handleOptionDropdown = (event: any, numIndex: number, optionName: string) => {
    let filteredOption = project.options.find((option: IOption, index: number) => option.name == optionName);
    let selectedOption = pendingOptions.map((option: IDealOption) => {
      if (optionName === option.name) {
        return {
          ...option,
          purchaseAmount: parseFloat(event.target.value),
          amount: event.target.value * filteredOption?.price!,
        };
      } else return option;
    });

    let startingPrice = filteredDeal.basePrice;
    if (unitTransfer) {
      startingPrice = unitTransfer?.basePrice;
    }
    let totalOptions = selectedOption.reduce((a: any, b: any) => {
      return a + b.amount;
    }, startingPrice);

    if (totalOptions === startingPrice) {
      setUnitTransferTotal(startingPrice);
    } else {
      setUnitTransferTotal(totalOptions);
    }
    setPendingOptions(selectedOption);
  };

  const handleOptionInput = (event: any, optionName: string) => {
    let selectedOption = pendingOptions.map((option: IDealOption) => {
      if (optionName === option.name) {
        return {
          ...option,
          amount: parseFloat(event.target.value),
        };
      } else return option;
    });

    let startingPrice = filteredDeal.basePrice;
    if (unitTransfer) {
      startingPrice = unitTransfer?.basePrice;
    }
    let totalOptions = selectedOption.reduce((a: any, b: any) => {
      return a + b.amount;
    }, startingPrice);

    if (totalOptions === startingPrice) {
      setUnitTransferTotal(startingPrice);
    } else {
      setUnitTransferTotal(totalOptions);
    }

    setPendingOptions(selectedOption);
  };

  const depositList = () => {
    let depositNames = project.depositStructures.map((deposit: IDepositStructures, index: number) => {
      return deposit.name;
    });
    return depositNames;
  };

  const addPendingDeposits = (type: string = '') => {
    setPendingDeposits([
      ...pendingDeposits,
      {
        name: 'Initial',
        accountNumber: '',
        amount: 0,
        chequeDate: null,
        chequeType: 'replacement',
        deal: filteredDeal._id,
        dueDate: new Date(),
      },
    ]);
  };

  return (
    <DealContext.Provider
      value={{
        pendingDeposits,
        setPendingDeposits,
        pendingOptions,
        setPendingOptions,
        updatePendingDeposit,
        selectedDeposit,
        setSelectedDeposit,
        updateDealDocuments,
        dealUpdateLoading,
        removeDeposits,
        document,
        setDocument,
        originalPurchasers,
        realtors,
        getRealtors,
        realtorLoading,
        pendingBasePrice,
        setPendingBasePrice,
        rental,
        setRental,
        unitTransferTotal,
        setUnitTransferTotal,
        createDepositAmendment,
        optionValue,
        handleOptionDropdown,
        handleOptionInput,
        depositList,
        addPendingDeposits,
        keepDeposits,
        setKeepDeposits,
        unitTransfer,
        setUnitTransfer,
      }}
    >
      {props.children}
    </DealContext.Provider>
  );
};

const UPDATEPENDING = gql`
  mutation dealUpdateById($_id: MongoID!, $record: UpdateByIdDealInput!) {
    dealUpdateById(_id: $_id, record: $record) {
      record {
        pendingDeposits {
          _id
          name
          amount
          dueDate
          accountNumber
          chequeNumber
          chequeAmount
          chequeDate
          chequeType
          deal {
            _id
          }
        }
        pendingOptions {
          name
          purchaseAmount
          amount
        }
        pendingUnit {
          _id
          suite
          basePrice
          status
        }
      }
    }
  }
`;

const UPDATEDEALDOCUMENTS = gql`
  mutation dealUpdateById($_id: MongoID!, $record: UpdateByIdDealInput!) {
    dealUpdateById(_id: $_id, record: $record) {
      record {
        pendingDeposits {
          _id
          name
          amount
          dueDate
          accountNumber
          chequeNumber
          chequeAmount
          chequeDate
          chequeType
          deal {
            _id
          }
        }
        pendingOptions {
          name
          purchaseAmount
          amount
        }
        pendingUnit {
          _id
          suite
          basePrice
          status
        }
        documents {
          _id
        }
      }
    }
  }
`;

const REMOVEDEPOSITS = gql`
  mutation depositRemoveMany($filter: FilterRemoveManyDepositInput!) {
    depositRemoveMany(filter: $filter) {
      numAffected
    }
  }
`;

const REALTORS = gql`
  query realtorMany($filter: FilterFindManyRealtorInput) {
    realtorMany(filter: $filter, limit: 10000) {
      _id
      email
      firstName
      lastName
      brokerage
      streetAddress
      city
      province
      country
      postalCode
      brokeragePhone
      brokerageFax
      directPhone
    }
  }
`;

const CREATEDEPOSITAMENDMENT = gql`
  mutation createDepositAmendment(
    $type: String!
    $dealId: MongoID!
    $records: [CreateManyDepositInput!]!
    $record: CreateOneDocumentInput
    $options: [NewOptionsInput]
    $file: Upload!
    $pendingBasePrice: Int
    $pendingRental: Int
    $depositName: String
    $unitId: MongoID
    $unitTransferId: MongoID
  ) {
    createDepositAmendment(
      type: $type
      dealId: $dealId
      records: $records
      record: $record
      options: $options
      file: $file
      pendingBasePrice: $pendingBasePrice
      pendingRental: $pendingRental
      depositName: $depositName
      unitId: $unitId
      unitTransferId: $unitTransferId
    ) {
      deposits {
        _id
        name
        amount
        dueDate
        accountNumber
        chequeNumber
        chequeAmount
        chequeDate
        chequeType
        deal {
          _id
        }
      }
      document {
        _id
        name
        project {
          _id
        }
        status
        isAPS
        type
        dsEnvelopeId
      }
      options {
        name
        purchaseAmount
        amount
      }
    }
  }
`;

export { DealContext, DealProvider };
