import { Box } from "@mui/material";
import { numToCurrency } from "./Functions";

export const getStackPosition = (stack: any, style: any, stackLength: any, index: number, value: number, projectId: string) => {
  if (projectId === '61d506dbaa581c623018e244' || projectId === '61d4f685a7ade3198492a3ea') {
    // TekTower
    if (!stack.stack) {
      let top = style.top - 90;
      if (stackLength - 1 !== stack.stackRow && stack.stackRow !== value - 1) {
        if (index === 0) {
          top = style.top - 300 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 1) {
          top = style.top - 240 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 2) {
          top = style.top - 180 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 3) {
          top = style.top - 120 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 4) {
          top = style.top - 60 - 90 * ((stackLength - stack.stackRow) / 3.2);
        }
      } else {
        top = style.top - 90;
      }

      return {
        ...style,
        top: top,
        left: style.left / 1.5,
        width: 60,
      };
    } else {
      let top = style.top;
      if (stackLength !== stack.stackRow) {
        top = style.top;
      }
      if (stack.stackRow === value) {
        if (index === 0) {
          top = style.top - 300 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 1) {
          top = style.top - 240 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 2) {
          top = style.top - 180 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 3) {
          top = style.top - 120 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 4) {
          top = style.top - 60 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 5) {
          top = style.top;
        }
      }

      return {
        ...style,
        top: top,
        left: style.left / 1.5,
        width: 60,
      };
    }
  } else if (projectId === '61227d4e6b715258b0e44c97') {
    if (!stack.stack) {
      let top = style.top - 90;
      if (stackLength - 1 !== stack.stackRow && stack.stackRow !== value - 1) {
        if (index === 0) {
          top = style.top - 480 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 1) {
          top = style.top - 420 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 2) {
          top = style.top - 360 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 3) {
          top = style.top - 300 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 4) {
          top = style.top - 240 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 5) {
          top = style.top - 180 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 6) {
          top = style.top - 120 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 7) {
          top = style.top - 60 - 90 * ((stackLength - stack.stackRow) / 3.2);
        }
      } else {
        top = style.top - 90;
      }

      return {
        ...style,
        top: top,
        left: style.left / 1.5,
        width: 60,
      };
    } else {
      let top = style.top;
      if (stackLength !== stack.stackRow) {
        top = style.top;
      }
      if (stack.stackRow === value) {
        if (index === 0) {
          top = style.top - 480 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 1) {
          top = style.top - 420 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 2) {
          top = style.top - 360 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 3) {
          top = style.top - 300 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 4) {
          top = style.top - 240 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 5) {
          top = style.top - 180 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 6) {
          top = style.top - 120 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 7) {
          top = style.top - 60 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 8) {
          top = style.top;
        }
      }

      return {
        ...style,
        top: top,
        left: style.left / 1.5,
        width: 60,
      };
    }
  } else if (projectId === '62df06402e0f41a93c3cd81a') {
    if (!stack.stack) {
      let top = style.top - 90;

      // Moves Suite Info Box
      // Lower the number, the lower the box goes

      if (stackLength - 1 !== stack.stackRow && stack.stackRow !== value - 1) {
        if (index === 0) {
          top = style.top - 200 - 90 * ((stackLength - stack.stackRow) / 3.2); // Lowest Suite Info Box
        } else if (index === 1) {
          top = style.top - 160 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 2) {
          top = style.top - 370 - 60 * ((stackLength - stack.stackRow) / 4.2);
        } else if (index === 3) {
          top = style.top - 120 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 4) {
          top = style.top - 120 - 60 * ((stackLength - stack.stackRow) / 4.2);
        } else if (index === 5) {
          top = style.top - 60 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 6) {
          top = style.top - 0 - 90 * ((stackLength - stack.stackRow) / 3.2);
        }
      } else {
        top = style.top - 90;
      }

      return {
        ...style,
        top: top,
        left: style.left / 1.5,
        width: 60,
      };
    } else {
      let top = style.top;
      if (stackLength !== stack.stackRow) {
        top = style.top;
      }

      // Moves Unit Info Box

      if (stack.stackRow === value) {
        if (index === 0) {
          top = style.top - 420 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 1) {
          top = style.top - 140 - 90 * ((stackLength - stack.stackRow) / 3.2); // lowest Info Box
        } else if (index === 2) {
          top = style.top - 100 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 3) {
          top = style.top - 100 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 4) {
          top = style.top - 60 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 5) {
          top = style.top - -10 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 6) {
          top = style.top - 0 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 7) {
          top = style.top - 90 * ((stackLength - stack.stackRow) / 3.2);
        }
      }

      return {
        ...style,
        top: top,
        left: style.left / 1.5,
        width: 60,
      };
    }
  } else if (projectId === '61f9934d3ace1c59fcb6c5de') {
    if (!stack.stack) {
      let top = style.top - 90;
      if (stackLength - 1 !== stack.stackRow && stack.stackRow !== value - 1) {
        if (index === 0) {
          top = style.top - 600 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 1) {
          top = style.top - 540 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 2) {
          top = style.top - 480 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 3) {
          top = style.top - 420 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 4) {
          top = style.top - 360 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 5) {
          top = style.top - 300 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 6) {
          top = style.top - 240 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 7) {
          top = style.top - 180 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 8) {
          top = style.top - 120 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 9) {
          top = style.top - 60 - 90 * ((stackLength - stack.stackRow) / 3.2);
        }
      } else {
        top = style.top - 90;
      }

      return {
        ...style,
        top: top,
        left: style.left / 1.5,
        width: 60,
      };
    } else {
      let top = style.top;
      if (stackLength !== stack.stackRow) {
        top = style.top;
      }
      if (stack.stackRow === value) {
        if (index === 0) {
          top = style.top - 600 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 1) {
          top = style.top - 540 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 2) {
          top = style.top - 480 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 3) {
          top = style.top - 420 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 4) {
          top = style.top - 360 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 5) {
          top = style.top - 300 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 6) {
          top = style.top - 240 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 7) {
          top = style.top - 180 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 8) {
          top = style.top - 120 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 9) {
          top = style.top - 60 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 10) {
          top = style.top;
        }
      }

      return {
        ...style,
        top: top,
        left: style.left / 1.5,
        width: 60,
      };
    }
  } else if (projectId === '646e965a0be1b1b0f611190d') {
    if (!stack.stack) {
      let top = style.top - 90;

      // Moves Suite Info Box
      // Lower the number, the lower the box goes

      if (stackLength - 1 !== stack.stackRow && stack.stackRow !== value - 1) {
        if (index === 0) {
          top = style.top - 200 - 90 * ((stackLength - stack.stackRow) / 3.2); // Lowest Suite Info Box
        } else if (index === 1) {
          top = style.top - 160 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 2) {
          top = style.top - 370 - 60 * ((stackLength - stack.stackRow) / 4.2);
        } else if (index === 3) {
          top = style.top - 120 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 4) {
          top = style.top - 120 - 60 * ((stackLength - stack.stackRow) / 4.2);
        } else if (index === 5) {
          top = style.top - 60 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 6) {
          top = style.top - 0 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 7) {
          top = style.top - 0 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 8) {
          top = style.top - 0 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 9) {
          top = style.top - 0 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 10) {
          top = style.top - 0 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 11) {
          top = style.top - 0 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 12) {
          top = style.top - 0 - 90 * ((stackLength - stack.stackRow) / 3.2);
        }
      } else {
        top = style.top - 90;
      }

      return {
        ...style,
        top: top,
        left: style.left / 1.5,
        width: 60,
      };
    } else {
      let top = style.top;
      if (stackLength !== stack.stackRow) {
        top = style.top;
      }

      // Moves Unit Info Box

      if (stack.stackRow === value) {
        if (index === 0) {
          top = style.top - 420 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 1) {
          top = style.top - 140 - 90 * ((stackLength - stack.stackRow) / 3.2); // lowest Info Box
        } else if (index === 2) {
          top = style.top - 100 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 3) {
          top = style.top - 100 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 4) {
          top = style.top - 60 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 5) {
          top = style.top - -10 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 6) {
          top = style.top - 0 - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 7) {
          top = style.top - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 8) {
          top = style.top - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 9) {
          top = style.top - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 10) {
          top = style.top - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 11) {
          top = style.top - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 12) {
          top = style.top - 90 * ((stackLength - stack.stackRow) / 3.2);
        } else if (index === 13) {
          top = style.top - 90 * ((stackLength - stack.stackRow) / 3.2);
        }
      }

      return {
        ...style,
        top: top,
        left: style.left / 1.5,
        width: 60,
      };
    }
  }
};

export const customTiers = (project: string, units: any[]) => {
  if (project === '646e965a0be1b1b0f611190d') {
    if (units.length) {
      return ['Overall', 'East', 'Park', 'City', 'Fairways', 'Plaza'];
    } else return []
  } else return []
};

export const customTierUnits = (project: string, units: any[], tier: string) => {
  if (tier === 'Overall') {
    return [...new Set(units.map((item: any) => item.tier))];
  }
  if (project === '646e965a0be1b1b0f611190d') {
    if (tier === 'East') {
      return ['Plaza', 'Fairways', 'City'];
    } else return [tier];
  } else return []
};

export const allocationSummaryColumn = (project: string) => {
  if (project === "62df06402e0f41a93c3cd81a") {
    return [
      {
      Header: 'Trip Revenue',
      accessor: (rowData: any) => {
        return (
          <Box>
            <strong style={{ cursor: 'pointer' }}>{numToCurrency.format(rowData.tripRevenue)}</strong>
          </Box>
        );
      },
    }
    ];
  } else return []
}
