/* Dependency Imports */
import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  TextareaAutosize,
  Tooltip,
} from "@mui/material";
import { Close, DriveFolderUpload, Lock, LockOpen } from "@mui/icons-material";
import { Editor } from "@tiptap/react";

import { FlexBetween } from "../../../commonStyles";
import { useAppDispatch } from "../../../app/hooks";
import { showSuccessSnackbar } from "../../../features/snackbar/snackbarSlice";

const CloseButton = ({ setOpen }: { setOpen: React.Dispatch<React.SetStateAction<boolean>> }) => (
  <IconButton
    aria-label="close"
    onClick={() => setOpen(false)}
    sx={{
      position: "absolute",
      right: 8,
      top: 8,
      color: (theme) => theme.palette.grey[500],
    }}
  >
    <Close />
  </IconButton>
);

export const LinkDialog = ({ editor, setOpen, type, imgQuery }: ILinkProps & { imgQuery: any }) => {
  const link = editor.getAttributes("link").href;
  if (link) editor.commands.extendMarkRange("link");
  const from = editor.view.state.selection.$from.pos;
  const to = editor.view.state.selection.$to.pos;

  const [url, setUrl] = useState(link || "");
  const [imageUrl, setImageUrl] = useState("");
  const [text, setText] = useState(editor.view.state.doc.textBetween(from, to));
  const [target, setTarget] = useState(editor.getAttributes("link").target || "_blank");
  const [showUpload, setShowUpload] = useState<boolean>(false);
  const [uploadType, setUploadType] = useState<string>("");
  // Image
  const [alt, setAlt] = useState("");
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [ratio, setRatio] = useState(0);
  const [constrain, setConstrain] = useState(true);

  const handleRatio = () => {
    if (width && height && height !== "0") {
      setRatio(Number(width) / Number(height));
    } else {
      setRatio(0);
    }
  };

  const img = new Image();

  img.onload = () => {
    setWidth(img.naturalWidth.toString());
    setHeight(img.naturalHeight.toString());
    if (img.naturalHeight !== 0) {
      setRatio(img.naturalWidth / img.naturalHeight);
    } else setRatio(0);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (type === "text" && url === "") {
      editor.chain().focus().unsetLink().run();
    } else {
      if (type === "text") {
        editor
          .chain()
          .focus()
          .insertContent(text)
          .setTextSelection({ from: from, to: from + text.length })
          .setLink({ href: url, target: target })
          .run();
      } else if (type === "image") {
        editor
          .chain()
          .focus()
          .insertContent(
            `<img src=${imageUrl.replace(/ /g, "+")} alt=${alt} width=${parseInt(width, 10)} height=${parseInt(height, 10)} />`
          )
          .setNodeSelection(from)
          .setLink({ href: url, target: target })
          .run();
      }
    }
    setOpen(false);
  };

  const handleUploadType = (showUpload: boolean, uploadType: string) => {
    setShowUpload(showUpload);
    setUploadType(uploadType);
  };

  const handleUrl = (src: string, type: string) => {
    if (type === "link") {
      setUrl(src);
    } else setImageUrl(src);
  };

  return (
    <Dialog open onClose={() => setOpen(false)}>
      <Box component="form" onSubmit={handleSubmit}>
        <DialogTitle>
          {link ? "Edit" : "Insert"} Link
          <CloseButton setOpen={setOpen} />
        </DialogTitle>
        <DialogContent>
          <FlexBetween>
            <TextField
              margin="dense"
              size="small"
              label="Link URL"
              fullWidth
              value={url}
              onChange={(e) => {
                setUrl(e.target.value);
                if (!text || text === url) {
                  setText(e.target.value);
                }
              }}
            />
            <Tooltip title="Source">
              <IconButton aria-label="upload" onClick={() => handleUploadType(true, "link")}>
                <DriveFolderUpload />
              </IconButton>
            </Tooltip>
          </FlexBetween>
          {type === "image" ? (
            <>
              <FlexBetween>
                <TextField
                  margin="dense"
                  size="small"
                  label="Image URL"
                  fullWidth
                  value={imageUrl}
                  onChange={(e) => {
                    setImageUrl(e.target.value);
                    if (!text || text === imageUrl) {
                      setText(e.target.value);
                    }
                  }}
                />
                <Tooltip title="Source">
                  <IconButton aria-label="upload" onClick={() => handleUploadType(true, "image")}>
                    <DriveFolderUpload />
                  </IconButton>
                </Tooltip>
              </FlexBetween>
              <TextField
                margin="dense"
                size="small"
                label="Alternative Description"
                fullWidth
                value={alt}
                onChange={(e) => setAlt(e.target.value)}
              />
              <FlexBetween>
                <TextField
                  margin="dense"
                  fullWidth
                  size="small"
                  label="Width"
                  value={width}
                  onBlur={handleRatio}
                  onChange={(e) => {
                    if (constrain && ratio) {
                      setHeight(Math.round(Number(e.target.value) / ratio).toString());
                      img.height = Math.round(Number(e.target.value) * ratio);
                    }
                    setWidth(e.target.value);
                    img.width = parseInt(e.target.value, 10);
                  }}
                />
                <TextField
                  margin="dense"
                  fullWidth
                  sx={{ mx: 1 }}
                  size="small"
                  label="Height"
                  value={height}
                  onBlur={handleRatio}
                  onChange={(e) => {
                    if (constrain && ratio) {
                      setWidth(Math.round(Number(e.target.value) * ratio).toString());
                      img.width = Math.round(Number(e.target.value) * ratio);
                    }
                    setHeight(e.target.value);
                    img.height = parseInt(e.target.value, 10);
                  }}
                />
                <Tooltip title="Constrain Proportions">
                  <IconButton
                    aria-label="constrain proportions"
                    onClick={() => {
                      if (!constrain) {
                        handleRatio();
                      }
                      setConstrain(!constrain);
                    }}
                  >
                    {constrain ? <Lock /> : <LockOpen />}
                  </IconButton>
                </Tooltip>
              </FlexBetween>
            </>
          ) : null}
          {type === "text" ? (
            <TextField
              margin="dense"
              size="small"
              label="Text to display"
              fullWidth
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
          ) : null}
          <FormControl size="small" fullWidth margin="dense">
            <InputLabel>Open link in...</InputLabel>
            <Select value={target} onChange={(e) => setTarget(e.target.value)} label="Open link in...">
              <MenuItem value="_blank">New Window</MenuItem>
              <MenuItem value="_self">Current Window</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions sx={{ py: 2, px: 3 }}>
          <FlexBetween sx={{ width: "100%" }}>
            <Button color="success" variant="contained" type="submit">
              Save
            </Button>
            <Button color="info" variant="contained" onClick={() => setOpen(false)}>
              Cancel
            </Button>
          </FlexBetween>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export const SourceDialog = ({ editor, setOpen }: IDialogProps) => {
  const [code, setCode] = useState(editor.getHTML());

  return (
    <Dialog open fullWidth maxWidth="lg">
      <DialogTitle>
        Source Code
        <CloseButton setOpen={setOpen} />
      </DialogTitle>
      <DialogContent sx={{ display: "flex", height: "85vh" }}>
        <textarea
          value={code}
          spellCheck="false"
          onChange={(e) => setCode(e.target.value)}
          style={{ resize: "none", overflow: "auto", width: "100%", height: "100%" }}
        />
      </DialogContent>
      <DialogActions>
        <FlexBetween sx={{ width: "100%" }}>
          <Button
            color="success"
            variant="contained"
            onClick={() => {
              editor.commands.setContent(code);
              setOpen(false);
            }}
          >
            Save
          </Button>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
        </FlexBetween>
      </DialogActions>
    </Dialog>
  );
};

export const FormatHtmlDialog = ({ formatHtml, setFormatHtml }: IFormatDialogProps) => {
  const [pastedHtml, setPastedHtml] = useState<string>("");

  const dispatch = useAppDispatch();

  return (
    <Dialog open={formatHtml} onClose={() => setFormatHtml(false)} fullWidth>
      <DialogTitle>Format Html</DialogTitle>
      <DialogContent sx={{ width: "100%" }}>
        <TextareaAutosize
          style={{ width: "100%" }}
          minRows={10}
          onChange={(e) => {
            setPastedHtml(e.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <FlexBetween sx={{ width: "100%" }}>
          <Button color="warning" variant="contained" onClick={() => setFormatHtml(false)}>
            Cancel
          </Button>
          <Button
            color="success"
            variant="contained"
            onClick={() => {
              const temp = document.createElement("div");
              temp.innerHTML = pastedHtml;
              const imageNodes = temp.querySelectorAll("img");
              for (let imageNode of imageNodes) {
                if (imageNode.style.width.length === 0) imageNode.style.width = `${imageNode.width}px`;
                if (imageNode.style.height.length === 0) imageNode.style.height = `${imageNode.height}px`;
                if (imageNode.parentElement?.tagName === "TD" && imageNode.parentElement.children.length === 1) {
                  imageNode.parentElement.style.lineHeight = "0px";
                  imageNode.parentElement.style.fontSize = "0px";
                  imageNode.style.display = "block";
                }
                if (
                  imageNode.parentElement?.parentElement?.tagName === "TD" &&
                  imageNode.parentElement.parentElement.children.length === 1 &&
                  imageNode.parentElement.tagName === "A" &&
                  imageNode.parentElement.children.length === 1
                ) {
                  imageNode.parentElement.parentElement.style.lineHeight = "0px";
                  imageNode.parentElement.parentElement.style.fontSize = "0px";
                  imageNode.style.display = "block";
                }
              }
              navigator.clipboard.writeText(temp.innerHTML);
              setFormatHtml(false);
              dispatch(showSuccessSnackbar("Formatted and copied!"));
            }}
          >
            Format and Copy
          </Button>
        </FlexBetween>
      </DialogActions>
    </Dialog>
  );
};

interface IFormatDialogProps {
  formatHtml: boolean;
  setFormatHtml: (newFormat: boolean) => void;
}

interface IDialogProps {
  editor: Editor;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ILinkProps {
  editor: Editor;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  type: string;
  imgQuery: any;
}

interface IImageUploadDialogProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setUrl?: any;
  img: HTMLImageElement;
  imgQuery: any;
  type: string;
}
