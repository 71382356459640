import { useState, Fragment } from 'react';
import { useQuery, gql } from '@apollo/client';
import { Box, Button, Typography, Grid, TextField, FormControl, Select, InputLabel, MenuItem } from '@mui/material';

import { Flex, FlexBetween } from '../../../commonStyles';
import { IQuestion } from '../../../types/question';
import { questionTypes, displayTypes } from '../../../utils/Constants';
import { camelToTitle } from '../../../utils/Functions';

const Questions = (props: ChildProps) => {
  const { questions, questionsDispatch, parentQuestions } = props;

  const handleQuestionDropdown = (e: any) => {
    let selectedQuestion = parentQuestions.find((question: IQuestion) => question._id === e.target.value);

    if (selectedQuestion) {
      questionsDispatch({
        type: 'ADD',
        payload: {
          _id: selectedQuestion._id,
          name: selectedQuestion.name,
          question: selectedQuestion.question,
          type: selectedQuestion.type,
          subQuestion: selectedQuestion.subQuestion,
          display: selectedQuestion.display,
          choices: selectedQuestion.choices,
        },
      });
    }
  };

  const handleTextField = (e: any, index: number) => {
    questionsDispatch({ type: 'UPDATE', payload: { field: e.target.name, value: e.target.value, index: index } });
  };

  return (
    <Box>
      <FlexBetween sx={{ mb: 2 }}>
        <Typography variant={'h5'} gutterBottom>
          <strong>Questions</strong>
        </Typography>
        <Flex>
          {/* <Button sx={{ mr: 1, width: '300px' }} onClick={() => questionsDispatch({ type: 'ADDNEW' })} variant="contained" color="primary">
            Add Question
          </Button> */}
          <Box sx={{ width: '300px' }}>
            <FormControl fullWidth>
              <InputLabel id="id-select-label">Question</InputLabel>
              <Select labelId="id-label" id="id-select" label="ID Type" name="idType" onChange={handleQuestionDropdown}>
                {parentQuestions.map((question: IQuestion) => {
                  return <MenuItem value={question._id}>{question.question}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Box>
        </Flex>
      </FlexBetween>
      <Grid container spacing={2}>
        {questions.map((question: IQuestion, index: number) => {
          return (
            <Fragment key={index}>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <TextField
                  fullWidth
                  label={'Name'}
                  name={'name'}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTextField(e, index)}
                  value={question.name}
                  sx={{ opacity: 0.5 }}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <TextField
                  fullWidth
                  label={'Question'}
                  name={'question'}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTextField(e, index)}
                  value={question.question}
                  disabled
                  sx={{ opacity: 0.5 }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <FormControl fullWidth>
                  <InputLabel id="id-question-label">Question Type</InputLabel>
                  <Select
                    value={question.type}
                    labelId="id-question-label"
                    id="id-select-question"
                    label="Question Type"
                    name="idType"
                    onChange={handleQuestionDropdown}
                    disabled
                    sx={{ opacity: 0.5 }}
                  >
                    {questionTypes.map((question: string) => {
                      return <MenuItem value={question}>{camelToTitle(question)}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={2}>
                <FormControl fullWidth>
                  <InputLabel id="id-display-label">Display Type</InputLabel>
                  <Select
                    value={question.display}
                    labelId="id-display-label"
                    id="id-select-display"
                    label="Display Type"
                    name="idType"
                    onChange={handleQuestionDropdown}
                    disabled
                    sx={{ opacity: 0.5 }}
                  >
                    {displayTypes.map((display: string) => {
                      return <MenuItem value={display}>{camelToTitle(display)}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid sx={{ alignSelf: 'center', textAlign: 'center' }} item xs={12} sm={6} md={6} lg={1}>
                <Button
                  onClick={() => questionsDispatch({ type: 'DELETE', payload: { index: index } })}
                  sx={{ width: '100%' }}
                  variant="contained"
                  color="error"
                >
                  Delete
                </Button>
              </Grid>
            </Fragment>
          );
        })}
      </Grid>
    </Box>
  );
};

interface ChildProps {
  questions: IQuestion[];
  questionsDispatch: any;
  parentQuestions: IQuestion[];
}
export default Questions;
