import { useState } from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/lab';

const Leasing = () => {
  const [tabValue, setTabValue] = useState<string>('current');

  const handleTabChange = (e: any, value: string) => {
    if (value !== null) {
      setTabValue(value);
    }
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <strong>COMING SOON!</strong>
      {/* <h2>Leasing</h2>
      <ToggleButtonGroup
        value={tabValue}
        exclusive
        onChange={handleTabChange}
        aria-label="text alignment"
        sx={{
          '& .MuiToggleButton-root.Mui-selected': {
            backgroundColor: '#00142a',
            color: '#fff',
          },
          '& .MuiToggleButton-root.Mui-selected:hover': {
            backgroundColor: '#00142a',
            color: '#fff',
          },
        }}
      >
        <ToggleButton value="current" aria-label="centered">
          Current
        </ToggleButton>
        <ToggleButton value="previous" aria-label="right aligned">
          Previous
        </ToggleButton>
      </ToggleButtonGroup> */}
    </div>
  );
};

export default Leasing;
