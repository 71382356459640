import { useState, useMemo, useEffect } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { TextField, Box, Button, Typography } from '@mui/material';
import { numToCurrency, downloadExcel, downloadPdf, convertAllDates } from '../../utils/Functions';
import { IPurchaserInfo } from '../../types/CreateDealForm';
import LoadingLogo from '../common/LoadingLogo';
import { Flex } from '../../commonStyles';
import { useSelector } from 'react-redux';
import { selectProject } from '../../features/project/projectSlice';
import StandardTable from '../tables/StandardTable';
import { GlobalModal } from '../../features/modal/Modal';
import { useAppDispatch } from '../../app/hooks';
import { handleModal } from '../../features/modal/modalSlice';

const DailySummary = () => {
  const storeDispatch = useAppDispatch();
  const project = useSelector(selectProject);
  const [units, setUnits] = useState<any[]>([]);
  const [statuses, setStatuses] = useState<any[]>([]);
  const [rescission, setRescission] = useState<number>(0);
  const [cancelled, setCancelled] = useState<number>(0);
  const [newSales, setNewSales] = useState<number>(0);
  const [newDeals, setNewDeals] = useState<number>(0);
  const [deposits, setDeposits] = useState<IDailyDeposit[]>([]);
  const [dateStart, setDateStart] = useState<Date | number | null>(new Date().setHours(0, 0, 0, 0));
  const [dateEnd, setDateEnd] = useState<Date | null>(new Date());
  const [title, setTitle] = useState<string>('');

  // Daily Summary

  const [getDeals, { loading: unitLoading }] = useLazyQuery(STATUSDEALS, {
    onCompleted: (data: any) => {
      setUnits(data.getDateDeals);
    },
  });

  const [getDailySummary, { loading }] = useLazyQuery(DEALS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    onCompleted: (data) => {
      setStatuses(data.getDailySummary.deals.length ? data.getDailySummary.deals[0].statuses : []);
      setDeposits(data.getDailySummary.deposits);
      setRescission(data.getDailySummary.deals.length ? data.getDailySummary.deals[0].rescission : 0);
      setCancelled(data.getDailySummary.deals.length ? data.getDailySummary.deals[0].cancelled : 0);
      setNewSales(data.getDailySummary.deals.length ? data.getDailySummary.deals[0].newSales : 0);
      setNewDeals(data.getDailySummary.deals.length ? data.getDailySummary.deals[0].newDeals : 0);
    },
  });

  useEffect(() => {
    getDailySummary({ variables: { projects: [project._id], dateStart, dateEnd } });
  }, []);

  const columns = useMemo(() => {
    return [
      {
        Header: 'Status',
        accessor: (rowData: any) => {
          return (
            <Box onClick={() => handleUnitModal(rowData._id, false, false)}>
              <strong>{rowData._id}</strong>
            </Box>
          );
        },
      },
      {
        Header: 'Number of Units',
        accessor: (rowData: any) => rowData.count,
      },
      {
        Header: 'Size',
        accessor: (rowData: any) => rowData.size,
      },
      {
        Header: 'Revenue',
        accessor: (rowData: any) => numToCurrency.format(rowData.revenue),
      },
      {
        Header: 'Revenue Net HST',
        accessor: (rowData: any) => numToCurrency.format(rowData.revenueNet),
      },
      {
        Header: 'PPSF',
        accessor: (rowData: any) => numToCurrency.format(rowData.revenue / rowData.size),
      },
      {
        Header: 'PPSF Net HST',
        accessor: (rowData: any) => numToCurrency.format(rowData.revenueNet / rowData.size),
      },
    ];
  }, [dateStart, dateEnd, statuses]);

  const depositColumns = useMemo(() => {
    return [
      {
        Header: 'Deposit',
        accessor: (rowData: any) => {
          return <strong>{rowData._id}</strong>;
        },
      },
      {
        Header: 'Total Amount',
        accessor: (rowData: any) => numToCurrency.format(rowData.total),
      },
      {
        Header: 'Number of Deposits',
        accessor: (rowData: any) => rowData.count,
      },
    ];
  }, []);

  const handleDailySummary = () => {
    getDailySummary({ variables: { projects: [project._id], dateStart, dateEnd } });
  };

  const unitModalColumns = useMemo(() => {
    let columns = [
      {
        Header: 'Suite',
        accessor: (rowData: any) => {
          return (
            <Link style={{ textDecoration: 'none', color: '#000' }} to={`/${project._id}/dashboard/${rowData.unit._id}`}>
              <strong>{rowData.unit.suite}</strong>
            </Link>
          );
        },
      },
      {
        Header: 'Base Price',
        accessor: (rowData: any) => numToCurrency.format(rowData.unit.basePrice),
      },
      {
        Header: 'Unit Type',
        accessor: (rowData: any) => rowData.unit.unitType,
      },
      {
        Header: 'Exposure',
        accessor: (rowData: any) => rowData.unit.exposure,
      },
      {
        Header: 'Model Type',
        accessor: (rowData: any) => rowData.unit.modelType,
      },
      {
        Header: 'Size',
        accessor: (rowData: any) => rowData.unit.size,
      },
      {
        Header: 'PPSF',
        accessor: (rowData: any) => numToCurrency.format(rowData.unit.basePrice / rowData.unit.size),
      },
      {
        Header: 'Purchaser Full Name',
        accessor: (rowData: any) =>
          rowData.purchasers.map((purchaser: IPurchaserInfo) => `${purchaser.firstName} ${purchaser.lastName}`).join(', '),
      },
      {
        Header: 'Purchaser Email',
        accessor: (rowData: any) => rowData.purchasers.map((purchaser: IPurchaserInfo) => `${purchaser.email}`).join(', '),
      },
      {
        Header: 'Purchaser Phone',
        accessor: (rowData: any) => rowData.purchasers.map((purchaser: IPurchaserInfo) => `${purchaser.primaryPhone}`).join(', '),
      },
    ];

    if (title !== 'New Sales') {
      columns.push({
        Header: `${title ? title : 'Rescission/Cancel'}`,
        accessor: (rowData: any) =>
          title === 'Rescissions'
            ? convertAllDates(rowData.rescission.dateRescinded, 'PPpp')
            : convertAllDates(rowData.cancelled.dateCancelled, 'PPpp'),
      });
    }

    return columns;
  }, [title]);

  const handleUnitModal = (status: string, cancelled: boolean, rescission: boolean) => {
    if (!rescission && !cancelled && status === 'sales') {
      setTitle('New Sales');
    } else if (status) {
      setTitle(`Status ${status}`);
    } else if (cancelled) {
      setTitle('Cancellations');
    } else if (rescission) {
      setTitle('Rescissions');
    }

    getDeals({ variables: { project: project._id, status: status, dateStart, dateEnd, cancelled, rescission } });
    storeDispatch(handleModal(true));
  };

  const unitDownload = (type: string, data: any) => {
    let headers = [
      {
        label: 'Suite',
        id: 'suite',
      },
      {
        label: 'Base Price',
        id: 'basePrice',
      },
      {
        label: 'Unit Type',
        id: 'unitType',
      },
      {
        label: 'Exposure',
        id: 'exposure',
      },
      {
        label: 'Model Type',
        id: 'modelType',
      },
      {
        label: 'Size',
        id: 'size',
      },
      {
        label: 'Purchaser Full Names',
        id: 'purchaserFullNames',
      },
      {
        label: 'Purchaser Emails',
        id: 'purchaserEmails',
      },
      {
        label: 'Purchaser Phone',
        id: 'purchaserPhones',
      },
      {
        label: 'PPSF',
        id: 'ppsf',
      },
    ];

    let widths = {
      suite: 15,
      basePrice: 15,
      unitType: 15,
      exposure: 15,
      modelType: 15,
      size: 15,
      purchaserFullNames: 15,
      purchaserEmails: 15,
      purchaserPhones: 15,
      ppsf: 15,
    };

    let pdfWidths = {
      suite: 200,
      basePrice: 200,
      unitType: 200,
      exposure: 200,
      modelType: 200,
      size: 200,
      purchaserFullNames: 200,
      purchaserEmails: 200,
      purchaserPhones: 200,
      ppsf: 200,
    };

    let newData = data.map((data: any) => {
      return {
        ...data,
        suite: data.unit.suite,
        basePrice: data.basePrice ? numToCurrency.format(data.basePrice) : numToCurrency.format(data.unit.basePrice),
        unitType: data.unit.unitType,
        exposure: data.unit.exposure,
        modelType: data.unit.modelType,
        size: data.unit.size,
        purchaserFullNames: data.purchasers.map((purchaser: IPurchaserInfo) => `${purchaser.firstName} ${purchaser.lastName}`).join(', '),
        purchaserEmails: data.purchasers.map((purchaser: IPurchaserInfo) => `${purchaser.email}`).join(', '),
        purchaserPhones: data.purchasers.map((purchaser: IPurchaserInfo) => `${purchaser.primaryPhone}`).join(', '),
        ppsf: numToCurrency.format(data.basePrice / data.unit.size),
      };
    });

    let sheetTitle = `${project.name} - ${title}`;

    if (type === 'excel') {
      downloadExcel([newData], [headers], [], [[widths]], [sheetTitle], sheetTitle);
    } else {
      downloadPdf([newData], [headers], [], [pdfWidths], [sheetTitle], sheetTitle);
    }
  };

  const download = (type: string, data: any) => {
    let headers = [
      {
        label: 'Status',
        id: 'status',
      },
      {
        label: 'Number Of Units',
        id: 'numberOfUnits',
      },
      {
        label: 'Size',
        id: 'size',
      },
      {
        label: 'Revenue',
        id: 'revenue',
      },
      {
        label: 'Revenue Net HST',
        id: 'revenueNetHst',
      },
      {
        label: 'PPSF',
        id: 'ppsf',
      },
      {
        label: 'PPSF Net HST',
        id: 'ppsfNetHst',
      },
    ];

    let widths = {
      status: 15,
      numberOfUnits: 15,
      size: 15,
      revenue: 15,
      revenueNetHst: 15,
      ppsf: 15,
      ppsfNetHst: 15,
    };

    let pdfWidths = {
      status: 200,
      numberOfUnits: 200,
      size: 200,
      revenue: 200,
      revenueNetHst: 200,
      ppsf: 200,
      ppsfNetHst: 200,
    };

    let newData = data.map((data: any) => {
      return {
        status: data._id,
        numberOfUnits: data.count,
        size: data.size,
        revenue: numToCurrency.format(data.revenue),
        revenueNetHst: numToCurrency.format(data.revenueNet),
        ppsf: numToCurrency.format(data.revenue / data.size),
        ppsfNetHst: numToCurrency.format(data.revenueNet / data.size),
      };
    });

    let sheetTitle = `${project.name} - Daily Summary`;

    if (type === 'excel') {
      downloadExcel([newData], [headers], [], [[widths]], [sheetTitle], sheetTitle);
    } else {
      downloadPdf([newData], [headers], [], [pdfWidths], [sheetTitle], sheetTitle);
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      {loading ? (
        <Box
          sx={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            '-webkit-transform': 'translate(-50%, -50%)',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <LoadingLogo />
        </Box>
      ) : (
        <>
          <Flex sx={{ mb: 2 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Box sx={{ mr: 2 }}>
                <DateTimePicker
                  label={'Start Date (YYYY/MM/DD)'}
                  value={dateStart}
                  onChange={(newValue) => {
                    setDateStart(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
              <Box sx={{ mr: 2 }}>
                <DateTimePicker
                  label={'End Date (YYYY/MM/DD)'}
                  value={dateEnd}
                  onChange={(newValue) => {
                    setDateEnd(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
              <Button variant="contained" color="primary" onClick={() => handleDailySummary()}>
                Search
              </Button>
            </LocalizationProvider>
          </Flex>
          <Box sx={{ mb: 2 }}>
            <div style={{ maxWidth: '100%' }}>
              <StandardTable
                download={download}
                data={statuses.length ? statuses : []}
                columns={columns}
                text={'No Activity During this Date Range!'}
              />
            </div>
          </Box>
          <Box>
            <Box sx={{ display: 'inline', cursor: 'pointer' }} onClick={() => handleUnitModal('sales', false, false)}>
              Sales: <strong>{newSales}</strong>
            </Box>
          </Box>
          <Box>
            <Box sx={{ display: 'inline', cursor: 'pointer' }} onClick={() => handleUnitModal('sales', false, false)}>
              Sales & Presales: <strong>{newDeals}</strong>
            </Box>
          </Box>
          <Box>
            <Box sx={{ display: 'inline', cursor: 'pointer' }} onClick={() => handleUnitModal('', false, true)}>
              Rescission: <strong>{rescission}</strong>
            </Box>
          </Box>
          <Box>
            <Box sx={{ display: 'inline', cursor: 'pointer' }} onClick={() => handleUnitModal('', true, false)}>
              Cancelled: <strong>{cancelled}</strong>
            </Box>
          </Box>
          <Box sx={{ my: 2 }}>
            <Typography variant={'h5'} gutterBottom>
              Daily Deposits
            </Typography>
            <StandardTable data={deposits} columns={depositColumns} />
          </Box>
          <GlobalModal>
            <Typography variant={'h5'} gutterBottom>
              {title}
            </Typography>
            <StandardTable columns={unitModalColumns} data={units} loading={unitLoading} download={unitDownload} />
          </GlobalModal>
        </>
      )}
    </Box>
  );
};

interface IDailyDeposit {
  total: number;
  count: number;
}

const DEALS = gql`
  query getDailySummary($projects: [MongoID], $dateStart: Date!, $dateEnd: Date!) {
    getDailySummary(projects: $projects, dateStart: $dateStart, dateEnd: $dateEnd) {
      deals {
        project {
          _id
        }
        cancelled
        rescission
        newSales
        newDeals
        statuses {
          _id
          count
          size
          revenue
          revenueNet
        }
      }
      deposits {
        _id
        total
        count
      }
    }
  }
`;

const STATUSDEALS = gql`
  query getDateDeals($project: MongoID!, $dateStart: Date!, $dateEnd: Date!, $rescission: Boolean!, $cancelled: Boolean!, $status: String) {
    getDateDeals(
      project: $project
      dateStart: $dateStart
      dateEnd: $dateEnd
      rescission: $rescission
      cancelled: $cancelled
      status: $status
    ) {
      unit {
        _id
        suite
        modelType
        unitType
        bathroom
        size
        outdoorType
        basePrice
        exposure
      }
      basePrice
      rescission {
        dateRescinded
      }
      cancelled {
        dateCancelled
      }
      purchasers {
        firstName
        lastName
        email
        primaryPhone
      }
    }
  }
`;

export default DailySummary;
