import { useContext, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { withRouter } from '../../../../utils/WithRouter';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { Button, FormControl, Select, InputLabel, MenuItem, TextField } from '@mui/material';

import TextInput from '../../../common/formControls/TextInput';
import PageTitle from '../../../common/PageTitle';
import { CreateDealContext } from '../../../../context/CreateDealContext';
import { IMerge } from '../../../../types/merge';
import { IUserArray, IUser } from '../../../../types/user';
import Dropdown from '../../../common/formControls/Dropdown';
import { IExecutor } from '../../../../types/project';
import { getMergeValues, pageRotate, signField, wrapText } from '../../../../utils/Functions';
import { validateMultipleEmails } from '../../../../utils/Validations';
import { IAutoDocuments } from '../../../../types/CreateDealForm';
import { FlexBetween } from '../../../../commonStyles';
import { useSelector } from 'react-redux';
import { selectProject } from '../../../../features/project/projectSlice';
import { selectMerges } from '../../../../features/merge/mergeSlice';
import { useAppDispatch } from '../../../../app/hooks';
import { showErrorSnackbar } from '../../../../features/snackbar/snackbarSlice';
import { useMergeQuery } from '../../../../features/merge/mergeHooks';
import { mergeType } from '../../../../utils/Constants';
import UploadForm from './UploadForm';
import CreateForm from './CreateForm';

const MergeStep = (props: any) => {
  const {
    purchaserInfo,
    realtorInfo,
    suiteInfo,
    setSuiteInfo,
    setActiveStep,
    activeStep,
    selectedUnit,
    setSelectedUnit,
    setApsPdf,
    setTotalPdf,
    setDisplayPdf,
    selectedMergeTemplates,
    setSelectedMergeTemplates,
    uploadNewDeal,
    urlType,
    setValidEmails,
    setAutoDocuments,
    executor,
    setExecutor,
    autoDocuments,
    setPdfBlob,
    selectedAps,
    rental,
  } = useContext(CreateDealContext);
  const storeDispatch = useAppDispatch();
  const project = useSelector(selectProject);
  useMergeQuery(project._id, mergeType, 0, 50000);
  const merges = useSelector(selectMerges);

  const [users, setUsers] = useState<IUser[]>([]);
  const [username, setUsername] = useState<string>(suiteInfo.salesRep ? suiteInfo.salesRep.fullName : '');
  const [cc, setCc] = useState<string>('');

  useQuery<IUserArray>(GETSALES, {
    variables: { filter: { OR: [{ type: 'Sales' }, { type: 'Manager' }], locked: false }, sort: 'NAME' },
    onCompleted: (data) => {
      setUsers(data.userMany);
    },
  });

  const handleTextInput = async (event: any) => {
    setSuiteInfo({
      ...suiteInfo,
      [event.target.id]: event.target.value,
    });
  };

  const handleSalesDropdown = (event: React.ChangeEvent<{ name?: string; value: string }>) => {
    let selectedUser = users.find((user: IUser) => user.fullName === event.target.value);
    setSuiteInfo({
      ...suiteInfo,
      salesRep: selectedUser?._id,
    });
    setUsername(event.target.value);
  };

  const modifyPdf = async () => {
    let validEmails: string[] = [];
    if (project._id === '62df06402e0f41a93c3cd81a') {
      if (!selectedMergeTemplates.length) {
        storeDispatch(showErrorSnackbar('YOU NEED A SCHEDULE C'));
        return;
      }
    }
    if (cc) {
      validEmails = validateMultipleEmails(cc);
      if (validEmails.length === 0) {
        storeDispatch(showErrorSnackbar('There is an error with email formatting'));
      }
      setValidEmails(validEmails);
    }
    if (!selectedUnit.floorPlan) {
      storeDispatch(showErrorSnackbar('There is no Floorplan Template for this unit.'));
      return;
    }
    if (selectedUnit.floorPlan.signFields.length === 0) {
      storeDispatch(showErrorSnackbar('There are no sign fields for the floorplan'));
      return;
    }
    if (!username) {
      storeDispatch(showErrorSnackbar('Please add a Sales Representative to the deal'));
      return;
    }
    if (project.acknowledgement && !executor) {
      storeDispatch(showErrorSnackbar('Please add an Executor'));
      return;
    }

    let arrayOfPdfs: Array<any> = [];
    const mergedPdf = await PDFDocument.create();

    // Draw APS first

    let aps = await fetch(selectedAps!.getUrl!).then(async (res) => await res.arrayBuffer());
    const apsDoc = await PDFDocument.load(aps);
    let helveticaFont = await apsDoc.embedFont(StandardFonts.Helvetica);

    let pages = apsDoc.getPages();
    await selectedAps.mergeFields.forEach(async (mergeField: any) => {
      let mergePages = pages[mergeField.pageNumber];
      let mergeValue = getMergeValues(
        mergeField,
        project,
        { ...selectedUnit, rental: rental ? rental : selectedUnit.rental },
        null,
        purchaserInfo,
        suiteInfo.deposit,
        suiteInfo.options,
        realtorInfo,
        null,
        null,
        null,
        selectedMergeTemplates
      );
      if (
        mergeField.key === 'P1Check' ||
        (mergeField.key === 'P2Check' && purchaserInfo.length > 1) ||
        (mergeField.key === 'P3Check' && purchaserInfo.length > 2) ||
        (mergeField.key === 'P4Check' && purchaserInfo.length > 3)
      ) {
        mergePages.drawLine({
          start: { x: mergeField.x + 3, y: mergePages.getHeight() - mergeField.y - 3 },
          end: { x: mergeField.x + 8, y: mergePages.getHeight() - mergeField.y + 4 },
          thickness: 1,
          color: rgb(0, 0, 0),
          opacity: 0.75,
        });
        mergePages.drawLine({
          start: { x: mergeField.x, y: mergePages.getHeight() - mergeField.y + 1 },
          end: { x: mergeField.x + 3, y: mergePages.getHeight() - mergeField.y - 3 },
          thickness: 1,
          color: rgb(0, 0, 0),
          opacity: 0.75,
        });
      } else if (
        mergeField.key !== 'P1Check' &&
        mergeField.key !== 'P2Check' &&
        mergeField.key !== 'P3Check' &&
        mergeField.key !== 'P4Check'
      ) {
        let textProperties: any = {
          x: mergeField.x,
          y: mergePages.getHeight() - mergeField.y - 3,
          size: mergeField.fontSize,
          font: helveticaFont,
          color: rgb(0, 0, 0),
        };

        if (mergeField.wrap) {
          mergeValue = wrapText(mergeValue, mergeField.wrap * 2 + 75, helveticaFont, 9);
          textProperties.lineHeight = 10;
        }

        mergePages.drawText(mergeValue, textProperties);
      }
    });

    let copiedPages = await mergedPdf.copyPages(apsDoc, apsDoc.getPageIndices());
    copiedPages.forEach((page) => mergedPdf.addPage(page));

    // Draw Floorplan After

    let floorPlan = await fetch(selectedUnit!.getUrl!).then(async (res) => await res.arrayBuffer());
    const floorPlanDoc = await PDFDocument.load(floorPlan);
    helveticaFont = await floorPlanDoc.embedFont(StandardFonts.Helvetica);

    let floorPlanPages = floorPlanDoc.getPages();
    selectedUnit.floorPlan.mergeFields.forEach(async (mergeField: any) => {
      let mergePages = floorPlanPages[mergeField.pageNumber];

      let pageType = mergePages.getRotation();

      let pageRotationValue = pageRotate(pageType, mergeField.x, mergeField.y, 1, mergePages.getSize());

      let pageRotation = mergePages.getRotation();
      if (pageRotation.angle === 0) {
        pageRotationValue.y = mergePages.getHeight() - mergeField.y - 3;
      }

      let checkMediaBox = mergePages.getMediaBox();
      let x = pageRotationValue.x;
      let y = pageRotationValue.y;

      if (checkMediaBox && (checkMediaBox.x !== 0 || checkMediaBox.y !== 0)) {
        x = pageRotationValue.x - Math.abs(checkMediaBox.x);
        y = pageRotationValue.y - Math.abs(checkMediaBox.y);
      }

      let mergeValue = await getMergeValues(mergeField, project, selectedUnit, null, purchaserInfo, suiteInfo.deposit, suiteInfo.options);
      mergePages.drawText(mergeValue!, {
        x: x,
        y: y,
        size: mergeField.fontSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
        rotate: mergePages.getRotation(),
      });
    });

    copiedPages = await mergedPdf.copyPages(floorPlanDoc, floorPlanDoc.getPageIndices());

    let floorplanMerge = project.mergeTemplates.find((merge: any) => merge.name === 'floorplan');

    let floorplanAPS = floorplanMerge?.apsTemplates.find(
      (aps: any) => selectedAps.name === aps.name || selectedAps._id === aps.apsTemplate._id
    );

    copiedPages.reverse().forEach((merge: any, index) => {
      mergedPdf.insertPage(floorplanAPS?.pageNumber!, copiedPages[index]);
    });

    // Draw Amendments After

    let newSelectedMerges: any[] = [];

    if (selectedMergeTemplates.length > 0) {
      newSelectedMerges = selectedMergeTemplates
        .map((mergeTemplate: any) => {
          // get page number placement for selected merge template then sort from smallest to biggest
          let apsTemplate = project.mergeTemplates.find((merge: any) => mergeTemplate._id === merge._id);
          let pageNumbers = apsTemplate?.apsTemplates.find(
            (mergeTemplate: any) => mergeTemplate.name === selectedAps.name || mergeTemplate._id === selectedAps._id
          );
          return {
            ...mergeTemplate,
            page: pageNumbers?.pageNumber,
          };
        })
        .sort((a: any, b: any) => parseFloat(a.page) - parseFloat(b.page));
    }

    for (let file of newSelectedMerges) {
      let templateUrl = await fetch(file!.getUrl!).then(async (res) => await res.arrayBuffer());
      arrayOfPdfs.push(templateUrl);
    }

    for (let [index, document] of arrayOfPdfs.entries()) {
      document = await PDFDocument.load(document);
      const helveticaFont = await document.embedFont(StandardFonts.Helvetica);

      let pages = document.getPages();
      await newSelectedMerges[index].mergeFields.forEach(async (mergeField: any) => {
        let mergePages = pages[mergeField.pageNumber];

        let mergeValue = getMergeValues(
          mergeField,
          project,
          selectedUnit,
          null,
          purchaserInfo,
          suiteInfo.deposit,
          suiteInfo.options,
          realtorInfo
        );

        let textProperties: any = {
          x: mergeField.x,
          y: mergePages.getHeight() - mergeField.y - 3,
          size: mergeField.fontSize,
          font: helveticaFont,
          color: rgb(0, 0, 0),
        };

        if (mergeField.wrap) {
          mergeValue = wrapText(mergeValue, mergeField.wrap * 2 + 75, helveticaFont, 9);
          textProperties.lineHeight = 10;
        }

        mergePages.drawText(mergeValue, textProperties);
      });

      let mergeTemplatePages = await mergedPdf.copyPages(document, document.getPageIndices());

      let mergeTemplate = await project.mergeTemplates.find(
        (merge: any) =>
          merge.name === newSelectedMerges[index].name || (merge.mergeTemplate && merge.mergeTemplate._id === newSelectedMerges[index]._id)
      );

      let mergeTemplateAPS = mergeTemplate?.apsTemplates.find(
        (aps: any) => selectedAps.name === aps.name || selectedAps._id === aps.apsTemplate._id
      );

      let addPages = 0;

      for (let i = 0; i < index; i++) {
        addPages += newSelectedMerges[i].totalPages;
      }
      if (mergeTemplateAPS?.pageNumber! >= floorplanAPS?.pageNumber!) {
        addPages += 1;
      }

      newSelectedMerges[index].page = mergeTemplateAPS?.pageNumber! + addPages;

      mergeTemplatePages.reverse().forEach((merge: any, index) => {
        mergedPdf.insertPage(mergeTemplateAPS?.pageNumber! + addPages, mergeTemplatePages[index]);
      });
    }

    setSelectedMergeTemplates(newSelectedMerges);

    let pdfMergeDoc = await mergedPdf.save();
    await setApsPdf(pdfMergeDoc);
    await setPdfBlob(new Blob([pdfMergeDoc]));

    if (autoDocuments.length > 0) {
      let documents = await Promise.all(
        autoDocuments.map(async (document: IAutoDocuments) => {
          let autoMergeTemplate = merges.find((mergeTemplate: IMerge) => mergeTemplate.name === document.name);

          let autoDocument = await fetch(autoMergeTemplate?.getUrl!).then(async (res) => await res.arrayBuffer());
          const documentPdf = await PDFDocument.load(autoDocument);
          let helveticaFont = await documentPdf.embedFont(StandardFonts.Helvetica);

          let pages = documentPdf.getPages();
          await autoMergeTemplate?.mergeFields.forEach(async (mergeField: any) => {
            let mergePages = pages[mergeField.pageNumber];
            let mergeValue = getMergeValues(
              mergeField,
              project,
              { ...selectedUnit, rental: rental ? rental : selectedUnit.rental },
              null,
              purchaserInfo,
              suiteInfo.deposit,
              suiteInfo.options,
              realtorInfo,
              null,
              null,
              null,
              null
            );

            if (
              mergeField.key === 'P1Check' ||
              (mergeField.key === 'P2Check' && purchaserInfo.length > 1) ||
              (mergeField.key === 'P3Check' && purchaserInfo.length > 2) ||
              (mergeField.key === 'P4Check' && purchaserInfo.length > 3)
            ) {
              mergePages.drawLine({
                start: { x: mergeField.x + 3, y: mergePages.getHeight() - mergeField.y - 3 },
                end: { x: mergeField.x + 8, y: mergePages.getHeight() - mergeField.y + 4 },
                thickness: 1,
                color: rgb(0, 0, 0),
                opacity: 0.75,
              });
              mergePages.drawLine({
                start: { x: mergeField.x, y: mergePages.getHeight() - mergeField.y + 1 },
                end: { x: mergeField.x + 3, y: mergePages.getHeight() - mergeField.y - 3 },
                thickness: 1,
                color: rgb(0, 0, 0),
                opacity: 0.75,
              });
            } else if (
              mergeField.key !== 'P1Check' &&
              mergeField.key !== 'P2Check' &&
              mergeField.key !== 'P3Check' &&
              mergeField.key !== 'P4Check'
            ) {
              mergePages.drawText(mergeValue, {
                x: mergeField.x,
                y: mergePages.getHeight() - mergeField.y - 3,
                size: mergeField.fontSize,
                font: helveticaFont,
                color: rgb(0, 0, 0),
              });
            }
          });

          let copiedPages = await mergedPdf.copyPages(documentPdf, documentPdf.getPageIndices());
          copiedPages.forEach((page: any) => mergedPdf.addPage(page));

          const documentDoc = await documentPdf.save();

          await autoMergeTemplate?.signFields
            .filter((signField: any) => {
              if (purchaserInfo.length === 1) {
                return signField.index < 1;
              } else return signField;
            })
            .forEach(async (mergeField: any) => {
              let pages = copiedPages[mergeField.pageNumber];
              let mergeValue = getMergeValues(
                mergeField,
                project,
                selectedUnit,
                null,
                purchaserInfo,
                suiteInfo.deposit,
                suiteInfo.options,
                realtorInfo,
                null,
                null,
                null,
                null
              );

              pages.drawText(`${signField(mergeField.index)}${mergeValue}`, {
                x: mergeField.x,
                y: pages.getHeight() - mergeField.y - 3,
                size: 8,
                font: helveticaFont,
                color: rgb(1, 0, 0),
              });
            });

          return {
            file: documentDoc,
            name: document.name,
            mergeTemplate: document.mergeTemplate,
            signFields: autoMergeTemplate?.signFields!,
          };
        })
      );
      setAutoDocuments(documents);
    }

    pdfMergeDoc = await mergedPdf.save();

    await setTotalPdf(pdfMergeDoc);

    // Draw Sign Fields
    await selectedAps.signFields
      .filter((signField: any) => {
        if (purchaserInfo.length === 1) {
          return signField.index < 1;
        } else return signField;
      })
      .forEach(async (mergeField: any) => {
        let mergePages = mergedPdf.getPages();
        let mergeValue = getMergeValues(
          mergeField,
          project,
          selectedUnit,
          null,
          purchaserInfo,
          suiteInfo.deposit,
          suiteInfo.options,
          realtorInfo,
          null,
          null,
          null,
          selectedMergeTemplates
        );

        let textProperties: any = {
          x: mergeField.x,
          y: mergePages[mergeField.pageNumber].getHeight() - mergeField.y - 3,
          size: 8,
          font: helveticaFont,
          color: rgb(1, 0, 0),
        };

        if (mergeField.wrap) {
          mergeValue = wrapText(mergeValue, mergeField.wrap * 2 + 75, helveticaFont, 9);
          textProperties.lineHeight = 10;
        }

        mergePages[mergeField.pageNumber].drawText(`${signField(mergeField.index)}${mergeValue}`, textProperties);
      });

    // Draw Floor Plan Sign Fields
    selectedUnit.floorPlan.signFields
      .filter((signField: any) => {
        if (purchaserInfo.length === 1) {
          return signField.index < 1;
        } else return signField;
      })
      .forEach(async (mergeField: any) => {
        let mergePages = mergedPdf.getPages();
        let pages = mergePages[floorplanAPS?.pageNumber!];

        let pageType = pages.getRotation();

        let pageRotationValue = pageRotate(pageType, mergeField.x, mergeField.y, 1, pages.getSize());

        let pageRotation = pages.getRotation();
        if (pageRotation.angle === 0) {
          pageRotationValue.y = pages.getHeight() - mergeField.y - 3;
        }

        let checkMediaBox = pages.getMediaBox();
        let x = pageRotationValue.x;
        let y = pageRotationValue.y;

        if (checkMediaBox && (checkMediaBox.x !== 0 || checkMediaBox.y !== 0)) {
          x = pageRotationValue.x - Math.abs(checkMediaBox.x);
          y = pageRotationValue.y - Math.abs(checkMediaBox.y);
        }

        let mergeValue = await getMergeValues(mergeField, project, selectedUnit, null, purchaserInfo, suiteInfo.deposit, suiteInfo.options);
        pages.drawText(`${signField(mergeField.index)}${mergeValue!}`, {
          x: x,
          y: y,
          size: 8,
          font: helveticaFont,
          color: rgb(1, 0, 0),
          rotate: pages.getRotation(),
        });
      });

    let displayPdfMergeDoc = await mergedPdf.save();

    setDisplayPdf(displayPdfMergeDoc);

    setActiveStep(activeStep + 1);
  };

  return (
    <div>
      <PageTitle title={'Review'} />
      {urlType === 'upload-deal' ? <UploadForm /> : <CreateForm />}
      <h2>RDS Sales Representative</h2>
      <div>
        <Dropdown
          title={'Select A Sales Representative'}
          menuList={users.map((user: IUser) => user.fullName)}
          name={'options'}
          handleSelect={(e: any) => handleSalesDropdown(e)}
          value={username}
        />
      </div>
      {project.acknowledgement ? (
        <>
          <h2>Executor</h2>
          <div>
            <FormControl fullWidth>
              <InputLabel id="id-question-label">Executor</InputLabel>
              <Select
                value={executor}
                labelId="id-executor-label"
                id="id-executor"
                label="Executor"
                name="executor"
                onChange={(e) => setExecutor(e.target.value)}
              >
                {project.executors.map((executor: IExecutor) => {
                  return (
                    <MenuItem value={executor.email}>
                      {executor.name} - {executor.email}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        </>
      ) : null}
      <h2>CC to additional email addresses</h2>
      <div>
        <TextInput
          id="cc"
          placeholder="Please add additional emails to cc. (ex, johndoe@gmail.com, test2@gmail.com)"
          value={cc}
          handleTextInput={(e: any) => setCc(e.target.value)}
        />
      </div>
      {project._id === '65fc6ef32c574aea51c28500' || project._id === '646e965a0be1b1b0f611190d' ? (
        <>
          <h2>Number of Months of Free Maintenance</h2>
          <TextField
            title={'Number of Months'}
            name={'days'}
            fullWidth
            type="number"
            onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
            value={selectedUnit.days}
            label={'Number of Months'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSelectedUnit({ ...selectedUnit, days: e.target.value })}
          />
        </>
      ) : null}
      <h2>Comments</h2>
      <div>
        <TextInput
          id="comments"
          placeholder="Please enter any notes or comments for this deal if necessary"
          multiline
          variant={'outlined'}
          rows={2}
          value={suiteInfo.comments}
          rowsMax={Infinity}
          handleTextInput={(e: any) => handleTextInput(e)}
        />
      </div>
      <FlexBetween sx={{ mt: 1 }}>
        <Button variant="contained" color="primary" onClick={() => setActiveStep(activeStep - 1)}>
          Back
        </Button>
        {urlType === 'upload-deal' ? (
          <Button color="success" variant="contained" onClick={() => uploadNewDeal()}>
            Upload Deal
          </Button>
        ) : (
          <Button color="success" variant="contained" onClick={() => modifyPdf()}>
            Generate APS
          </Button>
        )}
      </FlexBetween>
    </div>
  );
};

const GETSALES = gql`
  query userMany($filter: FilterFindManyUserInput, $sort: SortFindManyUserInput) {
    userMany(filter: $filter, sort: $sort) {
      _id
      fullName
    }
  }
`;

export default withRouter(MergeStep);
