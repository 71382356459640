import { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Button, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { IWishList, IWorksheet } from '../../types/worksheet';
import { convertAllDates, capitalizeFirstLetter, numToCurrency } from '../../utils/Functions';
import { IPurchaserInfo } from '../../types/CreateDealForm';
import { IOption } from '../../types/project';
import { useSelector } from 'react-redux';
import { selectProject } from '../../features/project/projectSlice';
import { showSuccessSnackbar } from '../../features/snackbar/snackbarSlice';
import { useAppDispatch } from '../../app/hooks';
import StandardTable from '../tables/StandardTable';
import { downloadExcel, downloadPdf } from '../../utils/Functions';

const WorksheetList = (props: any) => {
  const storeDispatch = useAppDispatch();
  const project = useSelector(selectProject);

  const [worksheets, setWorksheets] = useState<IWorksheet[]>([]);
  const [count, setCount] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [worksheetType, setWorksheetType] = useState<string>('units');
  const [globalValue, setGlobalValue] = useState<string>('');
  const navigate = useNavigate();

  const [getWorksheets, { loading }] = useLazyQuery(GETWORKSHEETS, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      let worksheets = data.getWorksheets.worksheets;
      if (worksheetType === 'units') {
        worksheets = data.getWorksheets.worksheets.slice().sort((a: any, b: any) => a.status.localeCompare(b.status));
      }

      setWorksheets(worksheets);
      setCount(data.getWorksheets.count);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  useEffect(() => {
    getWorksheets({ variables: { search: '', project: project._id, type: worksheetType, page: pageNumber, perPage: 25 } });
  }, [project._id, pageNumber, worksheetType]);

  const handleGlobalFilterValue = (value: string) => {
    setGlobalValue(value);
    setPageNumber(1);
    getWorksheets({ variables: { search: value, project: project._id, type: worksheetType, page: pageNumber, perPage: 25 } });
  };

  const [updateUnit] = useMutation(UPDATEUNIT, {
    onCompleted: (data) => {},
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [updateArchive] = useMutation(UPDATEWORKSHEET, {
    onCompleted: (data) => {
      if (data.worksheetUpdateById.record.status === 'archived') {
        storeDispatch(showSuccessSnackbar(`Worksheet has been archived`));
      } else {
        storeDispatch(showSuccessSnackbar(`Worksheet has been restored`));
      }
      let newWorksheet = worksheets.filter((worksheet: IWorksheet) => worksheet._id !== data.worksheetUpdateById.record._id);
      setWorksheets(newWorksheet);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const unitColumns = useMemo(() => {
    return [
      {
        Header: 'Suite',
        accessor: (rowData: any) => {
          return (
            <span style={{ cursor: 'pointer' }} onClick={() => navigate(`/${project._id}/dashboard/worksheet/${rowData._id}`)}>
              <strong>{rowData.unit.suite}</strong>
            </span>
          );
        },
      },
      {
        Header: 'Status',
        accessor: (rowData: any) => {
          return <Box sx={{ color: rowData.status === 'accepted' ? 'green' : '#000' }}>{capitalizeFirstLetter(rowData.status)}</Box>;
        },
      },
      {
        Header: 'Unit Price',
        accessor: (rowData: any) => numToCurrency.format(rowData.unit.basePrice),
      },
      {
        Header: 'Worksheet Price',
        accessor: (rowData: any) => numToCurrency.format(rowData.worksheetBasePrice),
      },
      {
        Header: 'Realtor Full Name',
        accessor: (rowData: any) => {
          if (rowData.realtor) {
            return rowData.realtor.fullName;
          } else return 'Public';
        },
      },
      {
        Header: 'Purchaser(s) Full Name',
        accessor: (rowData: any) => {
          let purchasers = rowData.purchasers.map((purchaser: IPurchaserInfo, index: number) => {
            return (
              <div key={index}>
                <div>
                  {purchaser.firstName} {purchaser.lastName}
                </div>
              </div>
            );
          });
          return purchasers;
        },
      },
      {
        Header: 'Options',
        accessor: (rowData: any) => {
          let options = rowData.options.map((option: IOption, index: number) => {
            return (
              <div key={index}>
                <div>
                  {option.name} - x{option.purchaseAmount}
                </div>
              </div>
            );
          });
          return options;
        },
      },
      {
        Header: 'Manager Approval',
        accessor: (rowData: any) => {
          if (rowData.managerApproval) {
            return (
              <div>
                <div>{rowData.managerApproval.user.fullName}</div>
                <div>{convertAllDates(rowData.managerApproval.timestamp, 'PPpp')}</div>
              </div>
            );
          } else return <HighlightOffIcon style={{ color: 'red' }} />;
        },
      },
      {
        Header: 'Worksheet Created Date',
        accessor: (rowData: any) => convertAllDates(rowData.createdAt, 'PPpp'),
      },
    ];
  }, [worksheets]);

  const wishlistColumns = useMemo(() => {
    return [
      {
        Header: 'Unit Type - Models - Level - Exposure',
        accessor: (rowData: any) => {
          let wishlistChoices = rowData.wishlistChoices.map((wishlist: IWishList, index: number) => {
            let models = wishlist.modelTypes.map((item: string, index: number) => item).join(', ');
            return (
              <div key={index}>
                <div>
                  {wishlist.unitType}&nbsp;&nbsp;-&nbsp;&nbsp;{models}&nbsp;&nbsp;-&nbsp;&nbsp;{wishlist.level}&nbsp;&nbsp;-&nbsp;&nbsp;
                  {wishlist.exposure}
                </div>
              </div>
            );
          });
          return wishlistChoices;
        },
      },
      {
        Header: 'Realtor',
        accessor: (rowData: any) => {
          if (rowData.realtor) {
            return rowData.realtor.fullName;
          } else return 'Public';
        },
      },
      {
        Header: 'Realtor Phone',
        accessor: (rowData: any) => (rowData.realtor ? rowData.realtor.directPhone : ''),
      },
      {
        Header: 'Purchasers',
        accessor: (rowData: any) => {
          let purchasers = rowData.purchasers.map((purchaser: IPurchaserInfo, index: number) => {
            return (
              <div key={index}>
                <div>
                  {purchaser.firstName} {purchaser.lastName}
                </div>
              </div>
            );
          });
          return purchasers;
        },
      },
      {
        Header: 'Options',
        accessor: (rowData: any) => {
          let options = rowData.options.map((option: IOption, index: number) => {
            return (
              <div key={index}>
                <div>
                  {option.name} - x{option.purchaseAmount}
                </div>
              </div>
            );
          });
          return options;
        },
      },
      {
        Header: 'Options Waitlist',
        accessor: (rowData: any) => {
          if (rowData.joinWaitlist.length) {
            return rowData.joinWaitlist.map((waitlist: string) => {
              return <Box>{waitlist}</Box>;
            });
          } else return <HighlightOffIcon style={{ color: 'red' }} />;
        },
      },
      {
        Header: 'Bank Draft / Cheque',
        accessor: (rowData: any) => {
          if (rowData.chequeImages.length) {
            return <CheckCircleIcon style={{ color: 'green' }} />;
          } else return <HighlightOffIcon style={{ color: 'red' }} />;
        },
      },
      {
        Header: 'Notes',
        accessor: (rowData: any) => rowData.notes,
      },
      {
        Header: 'Created At',
        accessor: (rowData: any) => convertAllDates(rowData.createdAt, 'PPpp'),
      },
      {
        Header: 'View',
        accessor: (rowData: any) => (
          <Button onClick={() => navigate(`/${project._id}/dashboard/worksheet/${rowData._id}`)} variant="contained" color="primary">
            View
          </Button>
        ),
      },
      {
        Header: 'Archive',
        accessor: (rowData: any) => (
          <Button onClick={() => handleArchived(rowData._id, rowData.status, 'archive')} variant="contained" color="primary">
            Archive
          </Button>
        ),
      },
    ];
  }, []);

  const dealColumns = useMemo(() => {
    return [
      {
        Header: 'Suite',
        accessor: (rowData: any) => {
          if (rowData.unit) {
            return (
              <span style={{ cursor: 'pointer' }} onClick={() => navigate(`/${project._id}/dashboard/worksheet/${rowData._id}`)}>
                <strong>{rowData.unit.suite}</strong>
              </span>
            );
          } else return 'N/A';
        },
      },
      {
        Header: 'Status',
        accessor: (rowData: any) => (rowData.unit ? rowData.unit.status : ''),
      },
      {
        Header: 'Realtor',
        accessor: (rowData: any) => {
          if (rowData.realtor) {
            return rowData.realtor.fullName;
          } else return 'Public';
        },
      },
      {
        Header: 'Purchasers',
        accessor: (rowData: any) => {
          let purchasers = rowData.purchasers.map((purchaser: IPurchaserInfo, index: number) => {
            return (
              <div key={index}>
                <div>
                  {purchaser.firstName} {purchaser.lastName}
                </div>
              </div>
            );
          });
          return purchasers;
        },
      },
      {
        Header: 'Options',
        accessor: (rowData: any) => {
          let options = rowData.options.map((option: IOption, index: number) => {
            return (
              <div key={index}>
                <div>
                  {option.name} - x{option.purchaseAmount}
                </div>
              </div>
            );
          });
          return options;
        },
      },
      {
        Header: 'Options Waitlist',
        accessor: (rowData: any) => {
          if (rowData.joinWaitlist.length) {
            return rowData.joinWaitlist.map((waitlist: string) => {
              return <Box>{waitlist}</Box>;
            });
          } else return <HighlightOffIcon style={{ color: 'red' }} />;
        },
      },
      {
        Header: 'Deal Created At',
        accessor: (rowData: any) => convertAllDates(rowData.deal.createdAt, 'PPpp'),
      },
      {
        Header: 'Worksheet Created At',
        accessor: (rowData: any) => convertAllDates(rowData.createdAt, 'PPpp'),
      },
    ];
  }, []);

  const archivedColumns = useMemo(() => {
    return [
      {
        Header: 'Suite',
        accessor: (rowData: any) => {
          if (rowData.unit) {
            return (
              <span style={{ cursor: 'pointer' }} onClick={() => navigate(`/${project._id}/dashboard/worksheet/${rowData._id}`)}>
                <strong>{rowData.unit.suite}</strong>
              </span>
            );
          } else return 'N/A';
        },
      },
      {
        Header: 'Unit Type - Model(s) - Level - Exposure',
        accessor: (rowData: any) => {
          if (rowData.unit) {
            return (
              <div>
                {rowData.unit.unitType}&nbsp;&nbsp;-&nbsp;&nbsp;{rowData.unit.modelType}&nbsp;&nbsp;-&nbsp;&nbsp;{rowData.unit.level}
                &nbsp;&nbsp;-&nbsp;&nbsp;
                {rowData.unit.exposure}
              </div>
            );
          } else {
            let wishlistChoices = rowData.wishlistChoices.map((wishlist: IWishList, index: number) => {
              let models = wishlist.modelTypes.map((item: string, index: number) => item).join(', ');
              return (
                <div key={index}>
                  <div>
                    {wishlist.unitType}&nbsp;&nbsp;-&nbsp;&nbsp;{models}&nbsp;&nbsp;-&nbsp;&nbsp;{wishlist.level}&nbsp;&nbsp;-&nbsp;&nbsp;
                    {wishlist.exposure}
                  </div>
                </div>
              );
            });
            return wishlistChoices;
          }
        },
      },
      {
        Header: 'Realtor',
        accessor: (rowData: any) => {
          if (rowData.realtor) {
            return rowData.realtor.fullName;
          } else return 'Public';
        },
      },
      {
        Header: 'Purchaser(s)',
        accessor: (rowData: any) => {
          let purchasers = rowData.purchasers.map((purchaser: IPurchaserInfo, index: number) => {
            return (
              <div key={index}>
                <div>
                  {purchaser.firstName} {purchaser.lastName}
                </div>
              </div>
            );
          });
          return purchasers;
        },
      },
      {
        Header: 'Options',
        accessor: (rowData: any) => {
          let options = rowData.options.map((option: IOption, index: number) => {
            return (
              <div key={index}>
                <div>
                  {option.name} - x{option.purchaseAmount}
                </div>
              </div>
            );
          });
          return options;
        },
      },
      {
        Header: 'Options Waitlist',
        accessor: (rowData: any) => {
          if (rowData.joinWaitlist.length) {
            return rowData.joinWaitlist.map((waitlist: string) => {
              return <Box>{waitlist}</Box>;
            });
          } else return <HighlightOffIcon style={{ color: 'red' }} />;
        },
      },
      {
        Header: 'Notes',
        accessor: (rowData: any) => rowData.notes,
      },
      {
        Header: 'Reason',
        accessor: (rowData: any) => (rowData.reason ? rowData.reason : ''),
      },
      {
        Header: 'Return to Wishlist',
        accessor: (rowData: any) => (
          <Button
            sx={{ fontSize: '11px' }}
            onClick={() => handleArchived(rowData._id, rowData.status, 'wishlist')}
            variant="contained"
            color="primary"
          >
            Restore To Wishlist
          </Button>
        ),
      },
      {
        Header: 'Return to Unit',
        accessor: (rowData: any) => {
          if (rowData.unit) {
            return (
              <Button
                sx={{ fontSize: '11px' }}
                onClick={() => handleArchived(rowData._id, rowData.status, 'unit')}
                variant="contained"
                color="primary"
              >
                Restore To Unit
              </Button>
            );
          }
        },
      },
    ];
  }, []);

  const handleWorksheetType = (event: any, worksheetType: string) => {
    setWorksheets([]);
    if (worksheetType !== null) {
      setPageNumber(1);
      setGlobalValue('');
      setWorksheetType(worksheetType);
    }
  };

  const handleArchived = (id: string, status: string, type: string) => {
    // Unit -> Archive
    if (status !== 'archived') {
      updateArchive({
        variables: { _id: id, record: { status: 'archived', reason: 'Clients are not interested in the unit', managerApproval: null } },
      }).then((res) => {
        if (res.data.worksheetUpdateById.record.status === 'pending') {
          updateUnit({ variables: { _id: res.data.worksheetUpdateById.record.unit._id, record: { status: 'W' } } });
        }
      });
    } else {
      if (type === 'unit') {
        // Archive to Unit
        updateArchive({
          variables: { _id: id, record: { status: 'pending', reason: '', managerApproval: null } },
        }).then((res) => {
          if (res.data.worksheetUpdateById.record.status === 'pending') {
            updateUnit({ variables: { _id: res.data.worksheetUpdateById.record.unit._id, record: { status: 'W' } } });
          }
        });
      } else if (type === 'wishlist') {
        // Archive to Wishlist
        updateArchive({
          variables: { _id: id, record: { status: 'pending', reason: '', managerApproval: null, unit: null } },
        });
      }
    }
  };

  const download = async (type: string, data: any) => {
    let newData = await getWorksheets({
      variables: { search: globalValue, project: project._id, type: worksheetType, page: 1, perPage: 10000 },
    });

    if (worksheetType === 'wishlist') {
      let worksheetHeaders = [
        {
          label: 'Unit Type',
          id: 'unitType',
        },
        {
          label: 'Model Type',
          id: 'modelType',
        },
        {
          label: 'Level',
          id: 'level',
        },
        {
          label: 'Exposure',
          id: 'exposure',
        },
        {
          label: 'Realtor',
          id: 'realtor',
        },
        {
          label: 'Realtor Phone',
          id: 'realtorPhone',
        },
        {
          label: 'Purchasers',
          id: 'purchasers',
        },
        {
          label: 'Purchasers Email',
          id: 'purchasersEmail',
        },
        {
          label: 'Purchasers Phone',
          id: 'purchasersPhone',
        },
        {
          label: 'Purchasers Occupation',
          id: 'purchasersOccupation',
        },
        {
          label: 'Purchasers Employer',
          id: 'purchasersEmployer',
        },
        {
          label: 'Options',
          id: 'options',
        },
        {
          label: 'Options Waitlist',
          id: 'optionsWaitlist',
        },
        {
          label: 'Bank Draft / Cheque',
          id: 'bankDraft',
        },
        {
          label: 'Notes',
          id: 'notes',
        },
        {
          label: 'Created At',
          id: 'createdAt',
        },
      ];

      let widths = {
        unitType: 15,
        modelType: 15,
        level: 15,
        exposure: 15,
        realtor: 15,
        realtorPhone: 15,
        purchasers: 15,
        purchasersEmail: 15,
        purchasersPhone: 15,
        purchasersOccupation: 15,
        purchasersEmployer: 15,
        options: 15,
        optionsWaitlist: 15,
        bankDraft: 15,
        notes: 15,
        createdAt: 15,
      };

      let pdfWidths = {
        unitType: 200,
        modelType: 200,
        level: 200,
        exposure: 200,
        realtor: 200,
        realtorPhone: 200,
        purchasers: 200,
        purchasersEmail: 200,
        purchasersPhone: 200,
        purchasersOccupation: 200,
        purchasersEmployer: 200,
        options: 200,
        optionsWaitlist: 200,
        bankDraft: 200,
        notes: 200,
        createdAt: 200,
      };

      let allData = newData.data.getWorksheets.worksheets.map((data: any) => {
        return {
          unitType: `${data.wishlistChoices.map((choice: any) => `${choice.unitType}`)}`,
          modelType: `${data.wishlistChoices.map((choice: any) => `${choice.modelTypes.join(',')}`)}`,
          level: `${data.wishlistChoices.map((choice: any) => `${choice.level}`)}`,
          exposure: `${data.wishlistChoices.map((choice: any) => `${choice.exposure}`)}`,
          realtor: data.realtor ? data.realtor.fullName : '',
          realtorPhone: data.realtor ? data.realtor.directPhone : '',
          purchasers: `${data.purchasers.map((purchaser: any) => `${purchaser.firstName} ${purchaser.lastName}`)}`,
          purchasersEmail: `${data.purchasers.map((purchaser: any) => `${purchaser.email}`)}`,
          purchasersPhone: `${data.purchasers.map((purchaser: any) => `${purchaser.primaryPhone}`)}`,
          purchasersOccupation: `${data.purchasers.map((purchaser: any) => `${purchaser.occupation}`)}`,
          purchasersEmployer: `${data.purchasers.map((purchaser: any) => `${purchaser.employer}`)}`,
          options: data.options.length ? `${data.options.map((option: any) => `${option.name}`)}` : 'None',
          optionsWaitlist: `${data.joinWaitlist.map((waitlist: string) => `${waitlist}`).join(', ')}`,
          bankDraft: data.chequeImages.length ? 'True' : 'False',
          notes: data.notes,
          createdAt: convertAllDates(data.createdAt, 'PPpp'),
        };
      });

      let sheetTitle = `${project.name} - ${capitalizeFirstLetter(worksheetType)}`;

      if (type === 'excel') {
        downloadExcel([allData], [worksheetHeaders], [], [[widths]], [sheetTitle], sheetTitle);
      } else {
        downloadPdf([allData], [worksheetHeaders], [], [pdfWidths], [sheetTitle], sheetTitle);
      }
    } else if (worksheetType === 'units') {
      let worksheetHeaders = [
        {
          label: 'Suite',
          id: 'suite',
        },
        {
          label: 'Status',
          id: 'status',
        },
        {
          label: 'Unit Price',
          id: 'unitPrice',
        },
        {
          label: 'Worksheet Price',
          id: 'worksheetPrice',
        },
        {
          label: 'Realtor',
          id: 'realtor',
        },
        {
          label: 'Realtor Phone',
          id: 'realtorPhone',
        },
        {
          label: 'Purchasers',
          id: 'purchasers',
        },
        {
          label: 'Purchasers Email',
          id: 'purchasersEmail',
        },
        {
          label: 'Purchasers Phone',
          id: 'purchasersPhone',
        },
        {
          label: 'Purchasers Occupation',
          id: 'purchasersOccupation',
        },
        {
          label: 'Purchasers Employer',
          id: 'purchasersEmployer',
        },
        {
          label: 'Options',
          id: 'options',
        },
        {
          label: 'Manager Approval',
          id: 'managerApproval',
        },
        {
          label: 'Created At',
          id: 'createdAt',
        },
      ];

      let widths = {
        suite: 15,
        status: 15,
        unitPrice: 15,
        worksheetPrice: 15,
        realtor: 15,
        realtorPhone: 15,
        purchasers: 15,
        purchasersEmail: 15,
        purchasersPhone: 15,
        purchasersOccupation: 15,
        purchasersEmployer: 15,
        options: 15,
        managerApproval: 15,
        createdAt: 15,
      };

      let pdfWidths = {
        suite: 15,
        status: 15,
        unitPrice: 15,
        worksheetPrice: 15,
        realtor: 15,
        realtorPhone: 15,
        purchasers: 15,
        purchasersEmail: 15,
        purchasersPhone: 15,
        purchasersOccupation: 15,
        purchasersEmployer: 15,
        options: 15,
        managerApproval: 15,
        createdAt: 15,
      };

      let allData = newData.data.getWorksheets.worksheets.map((data: any) => {
        return {
          suite: data.unit.suite,
          status: data.status,
          unitPrice: numToCurrency.format(data.unit.basePrice),
          worksheetPrice: numToCurrency.format(data.worksheetBasePrice),
          realtor: `${data.realtor.fullName}`,
          realtorPhone: `${data.realtor.directPhone}`,
          purchasers: `${data.purchasers.map((purchaser: any) => `${purchaser.firstName} ${purchaser.lastName}`)}`,
          purchasersEmail: `${data.purchasers.map((purchaser: any) => `${purchaser.email}`)}`,
          purchasersPhone: `${data.purchasers.map((purchaser: any) => `${purchaser.primaryPhone}`)}`,
          purchasersOccupation: `${data.purchasers.map((purchaser: any) => `${purchaser.occupation}`)}`,
          purchasersEmployer: `${data.purchasers.map((purchaser: any) => `${purchaser.employer}`)}`,
          options: data.options.length ? `${data.options.map((option: any) => `${option.name}`)}` : 'None',
          managerApproval: data.managerApproval ? 'True' : 'False',
          createdAt: convertAllDates(data.createdAt, 'PPpp'),
        };
      });

      let sheetTitle = `${project.name} - ${capitalizeFirstLetter(worksheetType)}`;

      if (type === 'excel') {
        downloadExcel([allData], [worksheetHeaders], [], [[widths]], [sheetTitle], sheetTitle);
      } else {
        downloadPdf([allData], [worksheetHeaders], [], [pdfWidths], [sheetTitle], sheetTitle);
      }
    } else if (worksheetType === 'deal') {
      let worksheetHeaders = [
        {
          label: 'Suite',
          id: 'suite',
        },
        {
          label: 'Status',
          id: 'status',
        },
        {
          label: 'Realtor',
          id: 'realtor',
        },
        {
          label: 'Realtor Email',
          id: 'realtorEmail',
        },
        {
          label: 'Purchasers',
          id: 'purchasers',
        },
        {
          label: 'Purchasers Email',
          id: 'purchasersEmail',
        },
        {
          label: 'Purchasers Phone',
          id: 'purchasersPhone',
        },
        {
          label: 'Purchasers Occupation',
          id: 'purchasersOccupation',
        },
        {
          label: 'Purchasers Employer',
          id: 'purchasersEmployer',
        },
        {
          label: 'Options',
          id: 'options',
        },
        {
          label: 'Options Waitlist',
          id: 'optionsWaitlist',
        },
        {
          label: 'Deal Created At',
          id: 'dealCreatedAt',
        },
        {
          label: 'Worksheet Created At',
          id: 'worksheetCreatedAt',
        },
      ];

      let widths = {
        suite: 15,
        status: 15,
        realtor: 15,
        realtorEmail: 15,
        purchasers: 15,
        purchasersEmail: 15,
        purchasersPhone: 15,
        purchasersOccupation: 15,
        purchasersEmployer: 15,
        options: 15,
        optionsWaitlist: 15,
        dealCreatedAt: 15,
        worksheetCreatedAt: 15,
      };

      let pdfWidths = {
        suite: 200,
        status: 200,
        realtor: 200,
        realtorEmail: 200,
        purchasers: 200,
        purchasersEmail: 200,
        purchasersPhone: 200,
        purchasersOccupation: 200,
        purchasersEmployer: 200,
        options: 200,
        optionsWaitlist: 200,
        dealCreatedAt: 200,
        worksheetCreatedAt: 200,
      };

      let allData = newData.data.getWorksheets.worksheets.map((data: any) => {
        return {
          suite: data.unit.suite,
          status: data.status,
          realtor: data.realtor ? data.realtor.fullName : '',
          realtorEmail: `${data.realtor.email}`,
          purchasers: `${data.purchasers.map((purchaser: any) => `${purchaser.firstName} ${purchaser.lastName}`)}`,
          purchasersEmail: `${data.purchasers.map((purchaser: any) => `${purchaser.email}`)}`,
          purchasersPhone: `${data.purchasers.map((purchaser: any) => `${purchaser.primaryPhone}`)}`,
          purchasersOccupation: `${data.purchasers.map((purchaser: any) => `${purchaser.occupation}`)}`,
          purchasersEmployer: `${data.purchasers.map((purchaser: any) => `${purchaser.employer}`)}`,
          options: data.options.length ? `${data.options.map((option: any) => `${option.name}`)}` : 'None',
          optionsWaitlist: `${data.joinWaitlist.map((waitlist: string) => `${waitlist}`).join(', ')}`,
          dealCreatedAt: convertAllDates(data.deal.createdAt, 'PPpp'),
          worksheetCreatedAt: convertAllDates(data.createdAt, 'PPpp'),
        };
      });

      let sheetTitle = `${project.name} - ${capitalizeFirstLetter(worksheetType)}`;

      if (type === 'excel') {
        downloadExcel([allData], [worksheetHeaders], [], [[widths]], [sheetTitle], sheetTitle);
      } else {
        downloadPdf([allData], [worksheetHeaders], [], [pdfWidths], [sheetTitle], sheetTitle);
      }
    } else {
      let worksheetHeaders = [
        {
          label: 'Unit Type',
          id: 'unitType',
        },
        {
          label: 'Model Type',
          id: 'modelType',
        },
        {
          label: 'Level',
          id: 'level',
        },
        {
          label: 'Exposure',
          id: 'exposure',
        },
        {
          label: 'Realtor',
          id: 'realtor',
        },
        {
          label: 'Realtor Phone',
          id: 'realtorPhone',
        },
        {
          label: 'Purchasers',
          id: 'purchasers',
        },
        {
          label: 'Purchasers Email',
          id: 'purchasersEmail',
        },
        {
          label: 'Purchasers Phone',
          id: 'purchasersPhone',
        },
        {
          label: 'Purchasers Occupation',
          id: 'purchasersOccupation',
        },
        {
          label: 'Purchasers Employer',
          id: 'purchasersEmployer',
        },
        {
          label: 'Options',
          id: 'options',
        },
        {
          label: 'Options Waitlist',
          id: 'optionsWaitlist',
        },
        {
          label: 'Notes',
          id: 'notes',
        },
        {
          label: 'Created At',
          id: 'createdAt',
        },
      ];

      let widths = {
        unitType: 15,
        modelType: 15,
        level: 15,
        exposure: 15,
        realtor: 15,
        realtorPhone: 15,
        purchasers: 15,
        purchasersEmail: 15,
        purchasersPhone: 15,
        purchasersOccupation: 15,
        purchasersEmployer: 15,
        options: 15,
        optionsWaitlist: 15,
        notes: 15,
        createdAt: 15,
      };

      let pdfWidths = {
        unitType: 200,
        modelType: 200,
        level: 200,
        exposure: 200,
        realtor: 200,
        realtorPhone: 200,
        purchasers: 200,
        purchasersEmail: 200,
        purchasersPhone: 200,
        purchasersOccupation: 200,
        purchasersEmployer: 200,
        options: 200,
        optionsWaitlist: 200,
        notes: 200,
        createdAt: 200,
      };

      let allData = newData.data.getWorksheets.worksheets.map((data: any) => {
        return {
          unitType: `${data.wishlistChoices.map((choice: any) => `${choice.unitType}`)}`,
          modelType: `${data.wishlistChoices.map((choice: any) => `${choice.modelTypes.join(',')}`)}`,
          level: `${data.wishlistChoices.map((choice: any) => `${choice.level}`)}`,
          exposure: `${data.wishlistChoices.map((choice: any) => `${choice.exposure}`)}`,
          realtor: data.realtor ? data.realtor.fullName : '',
          realtorPhone: data.realtor ? data.realtor.directPhone : '',
          purchasers: `${data.purchasers.map((purchaser: any) => `${purchaser.firstName} ${purchaser.lastName}`)}`,
          purchasersEmail: `${data.purchasers.map((purchaser: any) => `${purchaser.email}`)}`,
          purchasersPhone: `${data.purchasers.map((purchaser: any) => `${purchaser.primaryPhone}`)}`,
          purchasersOccupation: `${data.purchasers.map((purchaser: any) => `${purchaser.occupation}`)}`,
          purchasersEmployer: `${data.purchasers.map((purchaser: any) => `${purchaser.employer}`)}`,
          options: data.options.length ? `${data.options.map((option: any) => `${option.name}`)}` : 'None',
          optionsWaitlist: `${data.joinWaitlist.map((waitlist: string) => `${waitlist}`).join(', ')}`,
          notes: data.notes,
          createdAt: convertAllDates(data.createdAt, 'PPpp'),
        };
      });

      let sheetTitle = `${project.name} - ${capitalizeFirstLetter(worksheetType)}`;

      if (type === 'excel') {
        downloadExcel([allData], [worksheetHeaders], [], [[widths]], [sheetTitle], sheetTitle);
      } else {
        downloadPdf([allData], [worksheetHeaders], [], [pdfWidths], [sheetTitle], sheetTitle);
      }
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography sx={{ mb: 2 }} variant={'h5'}>
        <strong>Worksheets</strong>
      </Typography>
      <>
        <ToggleButtonGroup
          value={worksheetType}
          exclusive
          onChange={handleWorksheetType}
          aria-label="text alignment"
          sx={{
            marginBottom: '10px',
            '& .MuiToggleButton-root.Mui-selected': {
              backgroundColor: '#00142a',
              color: '#fff',
            },
            '& .MuiToggleButton-root.Mui-selected:hover': {
              backgroundColor: '#00142a',
              color: '#fff',
            },
          }}
        >
          <ToggleButton value="units" aria-label="centered">
            Units
          </ToggleButton>
          <ToggleButton value="wishlist" aria-label="right aligned">
            Wishlist
          </ToggleButton>
          <ToggleButton value="archived" aria-label="right aligned">
            Archived
          </ToggleButton>
          <ToggleButton value="deal" aria-label="right aligned">
            Worksheets with Deals
          </ToggleButton>
        </ToggleButtonGroup>
        <Box>
          {worksheetType === 'units' ? (
            <StandardTable
              handleGlobalFilterValue={handleGlobalFilterValue}
              count={count}
              download={download}
              columns={unitColumns}
              data={worksheets}
              loading={loading}
            />
          ) : null}
          {worksheetType === 'wishlist' ? (
            <StandardTable
              handleGlobalFilterValue={handleGlobalFilterValue}
              count={count}
              download={download}
              columns={wishlistColumns}
              data={worksheets}
              loading={loading}
            />
          ) : null}
          {worksheetType === 'archived' ? (
            <StandardTable
              handleGlobalFilterValue={handleGlobalFilterValue}
              count={count}
              download={download}
              columns={archivedColumns}
              data={worksheets}
              loading={loading}
            />
          ) : null}
          {worksheetType === 'deal' ? (
            <StandardTable
              handleGlobalFilterValue={handleGlobalFilterValue}
              count={count}
              download={download}
              columns={dealColumns}
              data={worksheets}
              loading={loading}
            />
          ) : null}
        </Box>
        {count > 25 ? (
          <Box sx={{ textAlign: 'center', mt: 1 }}>
            <Button disabled={pageNumber === 1} onClick={() => setPageNumber(pageNumber - 1)}>
              {'<'}
            </Button>
            <span>{pageNumber}</span>
            <Button disabled={pageNumber === Math.ceil(count / 25)} onClick={() => setPageNumber(pageNumber + 1)}>
              {'>'}
            </Button>
          </Box>
        ) : null}
      </>
    </Box>
  );
};

const GETWORKSHEETS = gql`
  query getWorksheets($search: String, $project: MongoID!, $type: String!, $page: Int!, $perPage: Int!) {
    getWorksheets(search: $search, project: $project, type: $type, page: $page, perPage: $perPage) {
      worksheets {
        _id
        project {
          _id
        }
        deal {
          _id
          createdAt
        }
        status
        unit {
          _id
          suite
          basePrice
          unit
          level
          modelType
          unitType
          exposure
          bathroom
          size
          status
          worksheets {
            _id
          }
          allocation {
            _id
            fullName
          }
        }
        salesRep {
          fullName
        }
        realtor {
          _id
          fullName
          email
          directPhone
        }
        purchasers {
          _id
          project {
            _id
          }
          firstName
          lastName
          email
          streetAddress
          city
          province
          country
          postalCode
          purchaserType
          employer
          occupation
          primaryPhone
          dob
          sin
          idType
          unit
          idJurisdiction
          identifications {
            name
            getUrl
          }
        }
        wishlist
        joinWaitlist
        wishlistChoices {
          modelTypes
          unitType
          level
          exposure
        }
        options {
          name
          amount
          purchaseAmount
        }
        notes
        managerApproval {
          user {
            fullName
          }
          timestamp
        }
        chequeImages {
          name
          _id
          getUrl
        }
        totalPrice
        reason
        worksheetBasePrice
        notes
        createdAt
      }
      count
    }
  }
`;

const UPDATEWORKSHEET = gql`
  mutation worksheetUpdateById($_id: MongoID!, $record: UpdateByIdWorksheetInput!) {
    worksheetUpdateById(_id: $_id, record: $record) {
      record {
        _id
        project {
          _id
        }
        status
        unit {
          _id
          suite
          basePrice
          unit
          level
          modelType
          unitType
          bathroom
          size
          status
          worksheets {
            _id
          }
          allocation {
            _id
            fullName
          }
        }
        salesRep {
          fullName
        }
        realtor {
          _id
          fullName
          directPhone
          email
        }
        purchasers {
          _id
          project {
            _id
          }
          firstName
          lastName
          email
          streetAddress
          city
          province
          country
          postalCode
          purchaserType
          employer
          occupation
          primaryPhone
          dob
          sin
          idType
          unit
          idJurisdiction
          identifications {
            name
            getUrl
          }
        }
        wishlist
        joinWaitlist
        wishlistChoices {
          modelTypes
          unitType
          level
          exposure
        }
        options {
          name
          amount
          purchaseAmount
        }
        notes
        managerApproval {
          user {
            fullName
          }
          timestamp
        }
        chequeImages {
          name
          _id
          getUrl
        }
        totalPrice
        reason
        worksheetBasePrice
        notes
        createdAt
      }
    }
  }
`;

const UPDATEUNIT = gql`
  mutation unitUpdateById($_id: MongoID!, $record: UpdateByIdUnitInput!) {
    unitUpdateById(_id: $_id, record: $record) {
      record {
        _id
      }
    }
  }
`;

export default WorksheetList;
