import React from 'react';
import { Grid, TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import subYears from 'date-fns/subYears';

import TextInput from '../../common/formControls/TextInput';

const SigningOfficerForm = (props: ChildProps) => {
  const { signingOfficer, handleSigningOfficerInput, handleDob, index, type } = props;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <TextInput
            title={'Signing Officer Full Name'}
            id={'fullName'}
            name={'fullName'}
            handleTextInput={(e: any) => handleSigningOfficerInput(e, index, type)}
            value={signingOfficer.fullName}
            required={true}
            controlled={true}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label={'DOB (YYYY/MM/DD)'}
              inputFormat="yyyy/MM/dd"
              value={signingOfficer.dob}
              maxDate={!signingOfficer.corp ? subYears(new Date(), 18) : new Date()}
              onChange={(newValue) => handleDob(newValue, index, type)}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <TextInput
            title={'Phone Number'}
            name={'primaryPhone'}
            handleTextInput={(e: any) => handleSigningOfficerInput(e, index, type)}
            value={signingOfficer.primaryPhone}
            required={true}
            controlled={true}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <TextInput
            title={'Address'}
            name={'streetAddress'}
            handleTextInput={(e: any) => handleSigningOfficerInput(e, index, type)}
            value={signingOfficer.streetAddress}
            required={true}
            controlled={true}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <TextInput
            title={'Email'}
            type={'email'}
            name={'email'}
            handleTextInput={(e: any) => handleSigningOfficerInput(e, index, type)}
            value={signingOfficer.email}
            required={true}
            controlled={true}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <TextInput
            title={'SIN'}
            name={'sin'}
            handleTextInput={(e: any) => handleSigningOfficerInput(e, index, type)}
            value={signingOfficer.sin}
            controlled={true}
          />
        </Grid>
      </Grid>
    </>
  );
};

interface ChildProps {
  handleSigningOfficerInput: any;
  handleDob: any;
  signingOfficer: any;
  index: number;
  type?: number;
}

export default SigningOfficerForm;
