import { Node } from "@tiptap/core";

export default Node.create({
  name: "iframe",
  group: "block",
  addOptions() {
    return {
      inline: true,
      allowFullscreen: true,
      HTMLAttributes: {
        class: "iframe-wrapper",
        style: "display: flex; justify-content: center; width: 100%",
      },
    };
  },
  addAttributes() {
    return {
      src: {
        default: null,
      },
      class: {
        default: "pasted-iframe",
      },
      srcdoc: {
        default: null,
      },
      frameborder: {
        default: 0,
      },
      onload: {
        default: "this.width=this.contentWindow.document.body.scrollWidth;this.height=this.contentWindow.document.body.scrollHeight;",
      },
      width: {
        default: "800px",
      },
      name: {
        default: "pasted-iframe",
      },
      allowfullscreen: {
        default: this.options.allowFullscreen,
      },
    };
  },
  parseHTML() {
    return [
      {
        tag: "iframe",
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return ["div", this.options.HTMLAttributes, ["iframe", HTMLAttributes]];
  },
});
