import { Extension } from "@tiptap/core";
import { TextSelection, AllSelection } from "prosemirror-state";
import { clamp } from "./indent";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    textIndent: {
      textIndent: () => ReturnType;
      textOutdent: () => ReturnType;
    };
  }
}

const TextIndentProps = {
  min: 0,
  max: 180,
  more: 36,
  less: -36,
};

function setNodeTextIndentMarkup(tr: any, pos: any, delta: any) {
  if (!tr.doc) return tr;

  const node = tr.doc.nodeAt(pos);
  if (!node) return tr;

  const minIndent = TextIndentProps.min;
  const maxIndent = TextIndentProps.max;

  const textIndent = clamp((node.attrs.textIndent || 0) + delta, minIndent, maxIndent);

  if (textIndent === node.attrs.textIndent) return tr;

  const nodeAttrs = {
    ...node.attrs,
    textIndent,
  };

  return tr.setNodeMarkup(pos, node.type, nodeAttrs, node.marks);
}

const updateTextIndentLevel = (tr: any, delta: any) => {
  const { doc, selection } = tr;

  if (!doc || !selection) return tr;

  if (!(selection instanceof TextSelection || selection instanceof AllSelection)) {
    return tr;
  }

  const { from, to } = selection;

  doc.nodesBetween(from, to, (node: any, pos: any) => {
    const nodeType = node.type;

    if (nodeType.name === "paragraph" || nodeType.name === "heading") {
      tr = setNodeTextIndentMarkup(tr, pos, delta);
      return false;
    }

    return true;
  });

  return tr;
};

export const TextIndent = Extension.create({
  name: "textIndent",

  addOptions() {
    return {
      types: ["heading", "paragraph"],
      textIndentLevels: [0, 36, 72, 108, 144, 180],
      defaultTextIndentLevel: 0,
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          textIndent: {
            default: this.options.defaultTextIndentLevel,
            renderHTML: (attributes) => ({
              style: `text-indent: ${attributes.textIndent}pt;`,
            }),
            parseHTML: (element) => parseInt(element.style.textIndent) || this.options.defaultTextIndentLevel,
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      textIndent:
        () =>
        ({ tr, state, dispatch, editor }: any) => {
          const { selection } = state;
          tr = tr.setSelection(selection);
          tr = updateTextIndentLevel(tr, TextIndentProps.more);

          if (tr.docChanged) {
            dispatch && dispatch(tr);
            return true;
          }

          editor.chain().focus().run();

          return false;
        },
      textOutdent:
        () =>
        ({ tr, state, dispatch, editor }: any) => {
          const { selection } = state;
          tr = tr.setSelection(selection);
          tr = updateTextIndentLevel(tr, TextIndentProps.less);

          if (tr.docChanged) {
            dispatch && dispatch(tr);
            return true;
          }

          editor.chain().focus().run();

          return false;
        },
    };
  },
  addKeyboardShortcuts() {
    return {
      Tab: () => {
        return this.editor.commands.textIndent();
      },
      "Backspace": () => {
        return this.editor.commands.textOutdent();
      },
    };
  },
});
