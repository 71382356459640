import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Buffer } from 'buffer';
import { Box, CircularProgress, Button } from '@mui/material';
import { gql, useMutation, useLazyQuery, useSubscription } from '@apollo/client';
import { FlexBetween, FlexEnd } from '../../../../commonStyles';
import { ISigner, IEnvelopeData, IDocuments } from '../../../../types/docusign';
import { capitalizeFirstLetter, convertAllDates } from '../../../../utils/Functions';
import { UnitContext } from '../../../../context/UnitContext';
import { useAppDispatch } from '../../../../app/hooks';
import { showSuccessSnackbar, showErrorSnackbar } from '../../../../features/snackbar/snackbarSlice';
import { accessAllowed } from '../../../../features/project/projectHooks';
import { selectUser } from '../../../../features/auth/authSlice';
import { selectProject } from '../../../../features/project/projectSlice';

const EnvelopeAccordion = (props: ChildProps) => {
  const storeDispatch = useAppDispatch();
  const { doc, sendingDocumentToExecutor, openDialog } = props;
  const user = useSelector(selectUser);
  const project = useSelector(selectProject);
  const { setFilteredDeal, filteredDeal } = useContext(UnitContext);

  const [resendEnvelope, { loading: resendLoading }] = useLazyQuery(RESENDENVELOPE, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar(`${data.resendEnvelope}`));
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar(`${err}`));
      console.log(err, 'err');
    },
  });

  const [getEnvelopeData, { loading: envelopeLoading }] = useLazyQuery(GETENVELOPEDATA, {
    onCompleted: (data) => {
      let envelopeArray = filteredDeal.documents.map((documents: IDocuments) => {
        if (documents._id === data.documentById._id) {
          return {
            ...documents,
            envelopeData: data.documentById.envelopeData,
          };
        } else return documents;
      });
      setFilteredDeal({
        ...filteredDeal,
        documents: envelopeArray,
      });
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [getPreviewData, { loading: previewLoading }] = useLazyQuery(GETPREVIEWDATA, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const binaryData = Buffer.from(data.documentById.previewData, 'binary');
      const blob = new Blob([binaryData], { type: 'application/pdf' });
      const previewUrl = URL.createObjectURL(blob);

      const element = document.createElement('a');
      element.href = previewUrl;
      element.target = '_blank';
      element.click();
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [getSigners, { loading: getSignersLoading }] = useLazyQuery(GETSIGNERS, {
    onCompleted: (data) => {
      let signerArray = filteredDeal.documents.map((documents: IDocuments) => {
        if (documents._id === data.documentById._id) {
          return {
            ...documents,
            signers: data.documentById.signers,
          };
        } else return documents;
      });
      setFilteredDeal({
        ...filteredDeal,
        documents: signerArray,
      });
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const envelopeData = (doc: any) => {
    getEnvelopeData({
      variables: {
        _id: doc._id,
      },
    });
  };

  const resend = (doc: any) => {
    resendEnvelope({
      variables: {
        _id: doc._id,
      },
    });
  };

  const previewDocument = (doc: any) => {
    getPreviewData({
      variables: {
        _id: doc._id,
      },
    });
  };

  const messageSigners = (dsEnvelopeId: string, documentId: string, signers: ISigner[]) => {
    const signerStatus = (signer: string) => {
      if (signer === 'completed') {
        return <strong>Signed</strong>;
      } else if (signer === 'autoresponded') {
        return <strong>The email could not be delivered to the recipient</strong>;
      } else return <strong>Awaiting Signature</strong>;
    };

    if (dsEnvelopeId) {
      if (signers) {
        return signers.map((signer: ISigner, index: number) => {
          return (
            <div key={index}>
              <div>
                {capitalizeFirstLetter(signer.type)} {signer.name} - {signerStatus(signer.status)}
              </div>
            </div>
          );
        });
      }
    }
  };

  const messageEnvelope = (dsEnvelopeId: string, documentId: string, envelopeData: IEnvelopeData) => {
    if (dsEnvelopeId) {
      if (envelopeData) {
        return (
          <div>
            <div>
              Initial Sent Date: <strong>{convertAllDates(envelopeData.initialSentDateTime, 'PPpp')}</strong>
            </div>
            <div>
              Recent Sent Date: <strong>{convertAllDates(envelopeData.sentDateTime, 'PPpp')}</strong>
            </div>
            <div>
              Created Date: <strong>{convertAllDates(envelopeData.createdDateTime, 'PPpp')}</strong>
            </div>
            <div>
              Last Modified Date: <strong>{convertAllDates(envelopeData.lastModifiedDateTime, 'PPpp')}</strong>
            </div>
            <div>
              Delivered Date:{' '}
              <strong>{envelopeData.deliveredDateTime ? convertAllDates(envelopeData.deliveredDateTime, 'PPpp') : 'N/A'}</strong>
            </div>
            <div>
              Status Change Date: <strong>{convertAllDates(envelopeData.statusChangedDateTime, 'PPpp')}</strong>
            </div>
            <div>
              Completed Date:{' '}
              <strong>{envelopeData.completedDateTime ? convertAllDates(envelopeData.completedDateTime, 'PPpp') : 'N/A'}</strong>
            </div>
          </div>
        );
      }
    }
  };

  return (
    <div>
      {doc.status !== 'Prepared' ? (
        <Box
          sx={{
            mb: 2,
            width: '100%',
          }}
        >
          <Box
            sx={{
              mb: 3,
              '& .MuiAccordionSummary-content': {
                display: 'block',
              },
            }}
          >
            {messageSigners(doc.dsEnvelopeId, doc?._id!, doc.signers)}
          </Box>
          {messageEnvelope(doc.dsEnvelopeId, doc?._id!, doc.envelopeData)}
          <FlexBetween sx={{ mt: 2 }}>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
              }}
            >
              <Button sx={{ mr: 1, fontSize: '0.7rem' }} onClick={() => previewDocument(doc)} color="primary" variant="contained">
                {previewLoading ? <CircularProgress size={30} color="secondary" /> : 'Preview Document'}
              </Button>
              {!doc.signers && doc.dsEnvelopeId ? (
                <Button
                  sx={{ mr: 1, fontSize: '0.7rem' }}
                  onClick={() => getSigners({ variables: { _id: doc?._id } })}
                  color="primary"
                  variant="contained"
                >
                  {getSignersLoading ? <CircularProgress size={30} color="secondary" /> : 'Get Status of Signers'}
                </Button>
              ) : null}
              {!doc.envelopeData && doc.dsEnvelopeId ? (
                <Button sx={{ mr: 1, fontSize: '0.7rem' }} onClick={() => envelopeData(doc)} color="primary" variant="contained">
                  {envelopeLoading ? <CircularProgress size={30} color="secondary" /> : 'Envelope Data'}
                </Button>
              ) : null}
              {doc.status !== 'Completed' && doc.dsEnvelopeId ? (
                <Button sx={{ fontSize: '0.7rem' }} onClick={() => resend(doc)} color="primary" variant="contained">
                  {resendLoading ? <CircularProgress size={30} color="secondary" /> : 'Resend Envelope'}
                </Button>
              ) : null}
            </Box>
            {!filteredDeal.cancelled.dateCancelled && !filteredDeal.draft && !filteredDeal.rescission.datedRescinded ? (
              <FlexEnd>
                {(doc.status === 'Signed' || doc.status === 'Verified') &&
                accessAllowed(user, project._id, 'editDeals') &&
                !filteredDeal.cancelled.dateCancelled &&
                !filteredDeal.rescission.dateRescinded ? (
                  <Button sx={{ mr: 1, fontSize: '0.7rem' }} onClick={() => openDialog('execute', doc)} color="success" variant="contained">
                    {sendingDocumentToExecutor ? (
                      <CircularProgress size={24} color="secondary" />
                    ) : (
                      `Send to ${doc.status === 'Verified' ? 'Different' : ''} Executor`
                    )}
                  </Button>
                ) : null}
                {accessAllowed(user, project._id, 'editDeals') &&
                !filteredDeal.cancelled.dateCancelled &&
                !filteredDeal.rescission.dateRescinded ? (
                  <>
                    {!doc.dsEnvelopeId ||
                    (doc.status === 'Completed' && !doc.dsEnvelopeId) ||
                    (!doc.isAPS && doc.status !== 'Completed') ? (
                      <Button
                        sx={{ fontSize: '0.7rem' }}
                        onClick={() => openDialog('deleteDocument', doc)}
                        color="error"
                        variant="contained"
                      >
                        {'Void and Delete Document'}
                      </Button>
                    ) : null}
                  </>
                ) : null}
              </FlexEnd>
            ) : null}
          </FlexBetween>
        </Box>
      ) : (
        <Box
          sx={{
            mb: 2,
            width: '100%',
          }}
        >
          <div>
            <div>This document has not been sent out yet</div>
          </div>
          <FlexBetween sx={{ mt: 2 }}>
            <div>
              <Button sx={{ fontSize: '0.7rem' }} onClick={() => previewDocument(doc)} color="primary" variant="contained">
                {previewLoading ? <CircularProgress size={30} color="secondary" /> : 'Preview Document'}
              </Button>
            </div>
            {accessAllowed(user, project._id, 'editDeals') &&
            !filteredDeal.cancelled.dateCancelled &&
            !filteredDeal.rescission.dateRescinded ? (
              <FlexEnd>
                <Button
                  component={Link}
                  to={`/${project._id}/dashboard/edit-document/${doc._id}`}
                  color="primary"
                  variant="contained"
                  sx={{ mr: 1, fontSize: '0.7rem' }}
                >
                  Edit Document
                </Button>
                <FlexBetween>
                  <Button
                    sx={{ mr: 1, fontSize: '0.7rem' }}
                    onClick={() => openDialog('deleteDocument', doc)}
                    color="error"
                    variant="contained"
                  >
                    Delete Document
                  </Button>
                </FlexBetween>
                <Button sx={{ fontSize: '0.7rem' }} onClick={() => openDialog('sendEnvelope', doc)} color="success" variant="contained">
                  {doc.type === 'Coop' ? 'Send to Realtor' : 'Send to Purchaser(s)'}
                </Button>
              </FlexEnd>
            ) : null}
          </FlexBetween>
        </Box>
      )}
    </div>
  );
};

const GETPREVIEWDATA = gql`
  query documentById($_id: MongoID!) {
    documentById(_id: $_id) {
      _id
      previewData
    }
  }
`;

const GETSIGNERS = gql`
  query documentById($_id: MongoID!) {
    documentById(_id: $_id) {
      _id
      signers {
        name
        type
        status
      }
    }
  }
`;

const GETENVELOPEDATA = gql`
  query documentById($_id: MongoID!) {
    documentById(_id: $_id) {
      _id
      signers {
        name
        type
        status
      }
      envelopeData {
        createdDateTime
        lastModifiedDateTime
        initialSentDateTime
        sentDateTime
        statusChangedDateTime
        completedDateTime
        deliveredDateTime
      }
    }
  }
`;

const RESENDENVELOPE = gql`
  query resendEnvelope($_id: MongoID!) {
    resendEnvelope(_id: $_id)
  }
`;

interface ChildProps {
  doc: any;
  sendingDocumentToExecutor: boolean;
  openDialog: any;
}

export default EnvelopeAccordion;
