import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box } from '@mui/material';
import { numToCurrency } from '../../utils/Functions';

const InventoryModal = (props: ChildProps) => {
  const { tableData, tableTitles, pageTitle } = props;

  return (
    <Box
      sx={{
        backgroundColor: '#fff',
        border: '3px solid #000',
        pt: 1,
        pb: 4,
        px: 4,
        height: '670px',
        width: '1000px',
        // [theme.breakpoints.down("sm")]: {
        //   width: '90%',
        //   justifyContent: 'flex-start',
        //   marginLeft: 0,
        //   height: '600px'
        // },
        // '@media (max-height: 600px)': {
        //   height: '560px',
        // },
      }}
    >
      <Box
        sx={{
          justifyContent: 'center',
          display: 'flex',
          mb: 2,
        }}
      >
        <h1
          style={{
            textAlign: 'center',
          }}
        >
          {pageTitle}
        </h1>
      </Box>
      <TableContainer
        component={Paper}
        sx={{
          height: '75%',
        }}
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Suite Number</TableCell>
              <TableCell align="right">Unit Type</TableCell>
              <TableCell align="right">Purchaser Name</TableCell>
              {tableTitles.map((title) => {
                return <TableCell align="right">{title}</TableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableTitles.length === 1
              ? tableData.map((row) => (
                  <TableRow key={row.suite}>
                    <TableCell component="th" scope="row">
                      {row.suite}
                    </TableCell>
                    <TableCell align="right">{row.unitType}</TableCell>
                    <TableCell align="right">{row.purchaserName}</TableCell>
                    <TableCell align="right">{row.datePurchased}</TableCell>
                  </TableRow>
                ))
              : tableData.map((row) => (
                  <TableRow key={row.suite}>
                    <TableCell component="th" scope="row">
                      {row.suite}
                    </TableCell>
                    <TableCell align="right">{row.unitType}</TableCell>
                    <TableCell align="right">{row.purchaserName}</TableCell>
                    <TableCell align="right">{row.amount}</TableCell>
                    <TableCell align="right">{numToCurrency.format(row.totalAmount)}</TableCell>
                    <TableCell align="right">{row.datePurchased}</TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

interface ChildProps {
  pageTitle: string;
  tableData: any[];
  tableTitles: string[];
}

export default InventoryModal;
